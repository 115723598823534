export enum StepperTypes {
  registration,
  onboarding,
  onboardingLimitedAccessPerson,
  partnerRegistration,
}

export enum RegistrationSteps {
  preAssessment = 'preAssessment',
  authorizedPersons = 'authorizedPersons',
  directors = 'directors',
  controllingPersons = 'controllingPersons',
  contacts = 'Contacts',
  review = 'review',
}
export const RegistrationStepper: { [key in RegistrationSteps]: string } = {
  [RegistrationSteps.preAssessment]: 'Business Details',
  [RegistrationSteps.authorizedPersons]: 'Authorized Persons',
  [RegistrationSteps.directors]: 'Directors',
  [RegistrationSteps.controllingPersons]: 'Controlling Persons',
  [RegistrationSteps.contacts]: 'Contacts',
  [RegistrationSteps.review]: 'Review',
}

export enum OnboardingStepsLimitedAccessPerson {
  profileDetails = 'profileDetails',
  phoneVerification = 'phoneVerification',
}
export enum videoVerificationOnboardingStep {
  videoVerification = 'videoVerification',
}
type OnboardingStepsType = OnboardingStepsLimitedAccessPerson | videoVerificationOnboardingStep
export const OnboardingSteps = {
  ...OnboardingStepsLimitedAccessPerson,
  ...videoVerificationOnboardingStep,
}

export const OnboardingStepperLimitedAccessPerson: {
  [key in OnboardingStepsLimitedAccessPerson]: string
} = {
  [OnboardingSteps.profileDetails]: 'Profile Details',
  [OnboardingSteps.phoneVerification]: 'Phone Verification',
}

export const OnboardingStepper: { [key in OnboardingStepsType]: string } = {
  [OnboardingSteps.profileDetails]: 'Profile Details',
  [OnboardingSteps.phoneVerification]: 'Phone Verification',
  [OnboardingSteps.videoVerification]: 'Video Verification',
}

export enum PartnerRegistrationSteps {
  preAssessment = 'preAssessment',
  controllingPersons = 'controllingPersons',
  review = 'review',
}
export const PartnerRegistrationStepper: { [key in PartnerRegistrationSteps]: string } = {
  [RegistrationSteps.preAssessment]: 'Business Details',
  [RegistrationSteps.controllingPersons]: 'Controlling Persons',
  [RegistrationSteps.review]: 'Review',
}

export const Steppers: {
  [key in StepperTypes]:
    | typeof RegistrationStepper
    | typeof OnboardingStepper
    | typeof OnboardingStepperLimitedAccessPerson
    | typeof PartnerRegistrationStepper
} = {
  [StepperTypes.registration]: RegistrationStepper,
  [StepperTypes.onboarding]: OnboardingStepper,
  [StepperTypes.onboardingLimitedAccessPerson]: OnboardingStepperLimitedAccessPerson,
  [StepperTypes.partnerRegistration]: PartnerRegistrationStepper,
}
