import { Box, Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ActionSignatureStatus, StandingOrder } from '../../graphql'
import IconDot from '../../assets/images/icons/Dot.svg?react'
import { useDetermineUserRights } from '../../hooks'
import { PATH_PARAMS } from '../../routes/paths'
import { OperationNames } from '../../types'

const useStyles = makeStyles((theme) => ({
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
}))

export const StandingOrderStatus: FC<{
  orderData: StandingOrder
  handleSign: (data: StandingOrder, action: OperationNames) => void
}> = ({ orderData, handleSign }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isLimitedAccessRight = !!userRights?.limitedAccessRight

  const isSomeoneReject = orderData?.activationAction?.signatures?.some(
    (signature) => signature?.status === ActionSignatureStatus.Reject,
  )

  const signable =
    !isSomeoneReject &&
    orderData?.activationAction?.signatures?.find((s) => s?.isMine)?.status ===
      ActionSignatureStatus.Pending

  return orderData.deletedAt || isSomeoneReject ? (
    <Box component="span" className={clsx(`roundedPill cancelled`)}>
      {t('cancelled', 'Cancelled')}
    </Box>
  ) : orderData.active ? (
    orderData.cancellationAction ? (
      <Box component="span" className={clsx(`roundedPill deactivateStatus`)}>
        {t('pendingCancellation', 'Pending cancellation')}
      </Box>
    ) : (
      <Box component="span" className={clsx(`roundedPill activeStatus`)}>
        {t('active', 'Active')}
      </Box>
    )
  ) : signable ? (
    !isLimitedAccessRight ? (
      <Box className={classes.buttonsBox}>
        <Button
          aria-label="sign"
          type="button"
          onClick={(e) => {
            e.stopPropagation()
            handleSign(orderData, OperationNames.sign)
          }}
        >
          {t('sign', 'Sign')}
        </Button>
        <IconDot />
        <Button
          color="secondary"
          aria-label="reject"
          type="button"
          onClick={(e) => {
            e.stopPropagation()
            handleSign(orderData, OperationNames.reject)
          }}
        >
          {t('reject', 'Reject')}
        </Button>
      </Box>
    ) : (
      <Box component="span" className={clsx(`roundedPill pendingSignature`)}>
        {t('pendingSignature', 'Pending signature')}
      </Box>
    )
  ) : (
    <Box component="span" className={clsx(`roundedPill processing`)}>
      {t('processing', 'Processing')}
    </Box>
  )
}
