import * as yup from 'yup'
import { isEmpty } from 'lodash'
import i18n from '../../i18n'
import {
  NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
  NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS,
} from '../../constants/validations'

export const DocTaxIdInputSchema = yup.object().shape({
  requiredTaxId: yup.mixed().notRequired(),
  filesForUploadTaxId: yup.mixed().notRequired(),
  documentTypeTaxId: yup
    .string()
    .test(
      'documentTypeTaxIdCheck',
      i18n.t('pleaseSelectTheDocumentType', 'Please select the document type'),
      function () {
        return !this.parent.requiredTaxId || !isEmpty(this.parent.documentTypeTaxId)
      },
    )
    .test(
      'mandatoryTaxID',
      i18n.t('proofTaxIdMandatory', 'Proof of the Tax ID is mandatory'),
      function () {
        return !this.parent.requiredTaxId || !isEmpty(this?.parent?.filesForUploadTaxId)
      },
    ),
  documentTypeTaxIdCustomVal: yup
    .string()
    .test(
      'documentTypeLegalNameCustomVal',
      NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
      (val) => isEmpty(val) || !!val?.match(NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS),
    ),
})

export type DocLegalNameInput = yup.Asserts<typeof DocTaxIdInputSchema>
