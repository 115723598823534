/* eslint-disable i18next/no-literal-string */
import 'date-fns'
import { includes, isEqual } from 'lodash'
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker, { CalendarContainer } from 'react-datepicker'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, ClickAwayListener, Typography } from '@material-ui/core'
import IconCalendar from '../../../assets/images/icons/calendar-dates.svg?react'
import { initialTransactionPageState } from '../../../constants'

const useStyles = (label: string | undefined) =>
  makeStyles((theme) => ({
    container: {
      padding: '10px',
      background: '#FFFFFF',
      // width: '260px',
      // color: '#fff',
      filter: 'drop-shadow(0px 3.5px 14px rgba(0, 0, 0, 0.2))',
      '& > div > div > div > div > div > div': {
        '&.react-datepicker__day--today, &.react-datepicker__day--keyboard-selected': {
          color: '#000000',
          background: 'none',
        },
        '&.react-datepicker__day--in-range, &.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range)': {
          borderRadius: '0.3rem',
          backgroundColor: '#216ba5',
          color: '#fff',
        },
      },
    },
    labelWrap: {
      display: 'block',
      width: '100%',
    },
    picker: {
      width: '100%',
      padding: '11px 44px 11px 12px',
      ...(label
        ? { border: 'none', borderBottom: '1px solid #c4c4c4' }
        : { border: '1px solid #c4c4c4' }),
      borderRadius: '0.05em',
      outline: 'none',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      color: '#000000',
      caretColor: 'transparent',
      background: 'transparent',
      cursor: 'pointer',
      '&::placeholder': {
        opacity: 1,
        color: '#000000',
      },
    },
    iconCalendar: {
      position: 'absolute',
      right: '14px',
      top: '12px',
      zIndex: 0,
      cursor: 'pointer',
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
      '& .MuiButton-root': {
        minWidth: 50,
      },
    },
    cancelBut: {
      padding: '8px 16px',
    },
    selectBut: {
      padding: '8px 16px',
      marginRight: 10,
    },
    label: {
      position: 'absolute',
      top: -3,
      left: theme.spacing(1.5),
      color: '#999999',
    },
  }))()

const DateRangeFilterPickerComponent: FC<{
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
  label?: string
  allowFutureDates?: boolean
}> = ({ dateRange, setDateRange, label, allowFutureDates }) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [showPrevMonths, setShowPrevMonths] = useState(true)
  const classes = useStyles(label)
  const { t } = useTranslation()

  const handleCancel = useCallback(() => {
    setDateRange(initialTransactionPageState.period)
    setStartDate(null)
    setEndDate(null)
    setShowPrevMonths(true)
    setOpenDatePicker(false)
  }, [setDateRange, setStartDate, setEndDate, setShowPrevMonths, setOpenDatePicker])

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleSelect = () => {
    setDateRange([startDate, endDate])
    setOpenDatePicker(false)
  }

  const handleCancelCalendar = () => {
    if (!openDatePicker) return

    includes(dateRange, null) && setShowPrevMonths(true)
    onChange(dateRange)
    setOpenDatePicker(false)
  }

  const calendarContainer: FC<{ className: string | undefined; children: ReactNode[] }> = ({
    className,
    children,
  }) => {
    return (
      <div className={classes.container}>
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>

        <Box className={classes.modalFooter}>
          <Button
            variant="contained"
            className={classes.selectBut}
            color="primary"
            onClick={handleSelect}
            disableElevation
            disabled={!endDate}
            data-test="autotest-pickerSelectDate"
          >
            {t('select', 'Select')}
          </Button>
          <Button
            className={classes.cancelBut}
            variant="contained"
            onClick={handleCancel}
            disableElevation
            data-test="autotest-pickerCancel"
          >
            {t('cancel', 'Cancel')}
          </Button>
        </Box>
      </div>
    )
  }

  useEffect(() => {
    if ((!!dateRange[0] && !!dateRange[1]) || (!dateRange[0] && !dateRange[1])) {
      setStartDate(dateRange[0])
      setEndDate(dateRange[1])
    }
  }, [dateRange])

  return (
    <ClickAwayListener onClickAway={handleCancelCalendar}>
      <Box
        style={{ position: 'relative' }}
        className={classes.labelWrap}
        data-test="autotest-rangeDate"
      >
        <label htmlFor="rangeDate" className={classes.labelWrap}>
          {label && (
            <Typography variant="subtitle2" className={classes.label}>
              {label}
            </Typography>
          )}
          <span className={classes.iconCalendar}>
            <IconCalendar />
          </span>
        </label>
        <DatePicker
          key={startDate?.toDateString()}
          onChangeRaw={() => {
            setShowPrevMonths(false)
          }}
          onInputClick={() => {
            setOpenDatePicker((openDatePicker) => !openDatePicker)
          }}
          className={classes.picker}
          open={openDatePicker}
          monthsShown={2}
          id={'rangeDate'}
          popperPlacement="bottom-end"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          placeholderText={`${t('allPeriod', 'All period')}`}
          onChange={onChange}
          dateFormat="MMM d, yyyy"
          shouldCloseOnSelect={false}
          calendarContainer={calendarContainer}
          readOnly
          showPreviousMonths={showPrevMonths}
          maxDate={allowFutureDates ? undefined : new Date()}
        />
      </Box>
    </ClickAwayListener>
  )
}

export const DateRangeFilterPicker = React.memo(DateRangeFilterPickerComponent, isEqual)
