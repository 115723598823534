import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import * as DateFns from 'date-fns'

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'

import DatePickerIcon from '../../../assets/images/icons/date_picker.svg?react'
import { DEFAULT_VIEW_DATE_FORMAT, formatDate } from '../../../utils/dateUtils'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import clsx from 'clsx'

const tryParseISO = (value?: Date | string | null) => {
  try {
    return !!value ? DateFns.parseISO(value as string) : null
  } catch {
    //
  }
  return value
}

const useStyles = makeStyles((theme) => ({
  pickerWrap: {
    position: 'relative',
  },
  picker: {
    width: '100%',
    '& #executionDate-helper-text': {
      color: theme.palette.error.main,
    },
    '& input': {
      paddingRight: '5px',
    },
  },
  redError: {
    left: 0,
    color: '#EF2828',
    top: '80%',
    [theme.breakpoints.down('md')]: {
      lineHeight: 1.2,
    },
  },
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: '#F5F5F5',
      pointerEvents: 'none',
      color: '#999999',
    },
  },
  whiteBackground: {
    background: '#FFFFFF',
  },
}))

export const FormDatePickerField: FC<{
  name: string
  label: string
  defaultValue?: string
  readOnly?: boolean
  noPast?: boolean
  noFuture?: boolean
  isShiftTimezone?: boolean
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean
  maxDate?: Date
  whiteBackground?: boolean
  classNames?: string
}> = ({
  name = 'date-picker-inline',
  label = 'Date',
  readOnly = false,
  noPast = false,
  noFuture = false,
  isShiftTimezone = true,
  shouldDisableDate = (_) => false,
  maxDate,
  whiteBackground,
  classNames = '',
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | string>(null)
  const { register, setValue, errors, clearErrors, watch, trigger } = useFormContext()
  const error = errors ? errors[name] : null
  const classes = useStyles()

  const dateFormValue = watch(name)

  const handleDateChange = useCallback(
    (date: Date | null, value?: string | null) => {
      const formattedDate = formatDate(date, isShiftTimezone)
      if (!value?.includes('_') && formattedDate) {
        setValue(name, formattedDate, {
          shouldValidate: false,
          shouldDirty: true,
        })
        if (formattedDate) {
          setSelectedDate(formattedDate)
        } else {
          setSelectedDate(null)
        }
      }
    },
    [setValue, name, trigger],
  )

  const handleDateBlur = (blurValue: React.FocusEvent<HTMLInputElement>) => {
    if (!blurValue.target.value) {
      setSelectedDate(null)
      setValue(name, undefined, {
        shouldValidate: true,
        shouldDirty: true,
      })
    } else if (!!dateFormValue) {
      trigger(name).then()
    }
  }

  const handleAccept = () => {
    clearErrors(name)
    trigger(name).then()
  }

  useEffect(() => {
    register(name)
  }, [register])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        justifyContent="space-around"
        className={clsx(classes.pickerWrap, classNames)}
        onClick={(e) => readOnly && e.preventDefault()}
        data-test="companyDetails-registrationDate"
      >
        <KeyboardDatePicker
          className={`${classes.picker} ${readOnly ? classes.readOnly : ''} ${
            whiteBackground ? classes.whiteBackground : ''
          }`}
          format={DEFAULT_VIEW_DATE_FORMAT}
          margin="normal"
          name={name}
          id={name}
          label={label}
          value={tryParseISO(!!selectedDate ? selectedDate : dateFormValue)}
          onChange={(date) => handleDateChange(date)}
          onBlur={handleDateBlur}
          invalidDateMessage={''}
          error={!!error}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDateMessage={''}
          maxDateMessage={''}
          maxDate={maxDate}
          okLabel="Select"
          readOnly={readOnly}
          disablePast={noPast}
          disableFuture={noFuture}
          data-test={`autotest-${name}`}
          keyboardIcon={<DatePickerIcon />}
          InputLabelProps={{ ...(selectedDate && { shrink: true }) }}
          onAccept={handleAccept}
          shouldDisableDate={shouldDisableDate}
        />
        {error && <FormHelperText className={classes.redError}>{error.message}</FormHelperText>}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
