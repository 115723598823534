import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload } from 'react-use'

import { Box, Button, Typography } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { useReactiveVar } from '@apollo/client'

import { goBackPathVar, goNextPathVar, poiNeeded, totalShare } from '../../../graphql/local'
import {
  CommonTipItem,
  CustomPrompt,
  FormControlledTextField,
  GridRow,
  Loader,
  LoadingButton,
} from '../../Common'
import {
  GetUbosListDocument,
  UboStakeType,
  useAddContractExistingUboMutation,
} from '../../../graphql'
import InformationIcon from '../../../assets/images/icons/info_icon.svg?react'
import { OwnerTypeFormPropsType } from '../../../types'
import { focusKeyPressNext, scrollToInputElement } from '../../../utils'
import { APP_PATHS } from '../../../routes/paths'
import { useCurrentUser } from '../../../hooks'
import { makeStyles, Theme } from '@material-ui/core/styles'
import NavigationPrompt from 'react-router-navigation-prompt'
import { OwnerShipPercentInputSchema } from '../../../schemes/common'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(2, 0),
  },
  info: {
    marginTop: 20,
    border: '1px solid #D4E2FC',
    fontSize: 14,
    '&>div': {
      padding: '5px 10px',
    },
  },
}))

export const ExistingUboPersonDataForm: FC<OwnerTypeFormPropsType> = ({
  isDomiciliary,
  isNewRecord,
  applicationId,
  uboId,
  type,
  children,
  contractData,
}) => {
  const user = useCurrentUser()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const goBackPath = useReactiveVar(goBackPathVar)
  const goNextPath = useReactiveVar(goNextPathVar)

  const [allowNavigation, setAllowNavigation] = useState(false)

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(OwnerShipPercentInputSchema),
    defaultValues: {
      ownershipPercent: undefined,
    },
  })
  const { formState, watch } = methods

  const addAnother = watch('addAnotherPerson')

  const [addContractExistingUbo] = useAddContractExistingUboMutation()

  const shareSizeSummary = useMemo(() => {
    return (
      contractData?.contract?.ubos?.reduce(
        (acc, val) => acc + (val?.id !== uboId ? val?.shareSize ?? 0 : 0),
        0,
      ) || 0
    )
  }, [contractData])

  const handleBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (goBackPath) {
        history.push(goBackPath)
        goBackPathVar('')
      } else {
        history.goBack()
      }
    },
    [formState.isDirty, goBackPath],
  )

  const onSubmit = useCallback(
    async (formData) => {
      if (!formData.controllingPerson) return

      setAllowNavigation(true)

      await addContractExistingUbo({
        variables: {
          contractId: applicationId,
          uboId: formData.controllingPerson,
          ownershipType: type,
          shareSize: formData.ownershipPercent,
        },
        refetchQueries: [
          {
            query: GetUbosListDocument,
            variables: { id: +applicationId },
          },
        ],
        awaitRefetchQueries: true,
      }).catch(() => {
        setAllowNavigation(false)
      })
      if (addAnother) {
        history.push(generatePath(APP_PATHS.application.beneficialOwners.add, { applicationId }))
      } else if (goNextPath) {
        history.push(goNextPath)
      } else {
        goBackPathVar('')
        history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
      }
    },
    [addAnother, applicationId, type],
  )

  useBeforeUnload(
    methods.formState.isDirty,
    t('youHaveUnsavedChangesAreYouSure', 'You have unsaved changes, are you sure?'),
  )

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  //mounts total Share already Contract have when new ubo add
  useEffect(() => {
    if (isNewRecord && !uboId) {
      totalShare(shareSizeSummary)
    }
  }, [contractData])

  useEffect(() => {
    poiNeeded(false)
  }, [])

  if (!user || !type) return <Loader />

  return (
    <>
      <NavigationPrompt when={formState.isDirty && !allowNavigation}>
        {({ onCancel, onConfirm }) => (
          <CustomPrompt open={true} onCancel={onCancel} onConfirm={onConfirm} />
        )}
      </NavigationPrompt>
      <FormProvider {...methods}>
        {children}
        <Box className={'form'}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            id="forNextFocus"
            onKeyDown={focusKeyPressNext}
          >
            <Box className={'group'}>
              <Box mt={4}>
                <Typography variant={'h3'} className={'title'}>
                  {t('ownershipDetails', 'Ownership details')}
                </Typography>
              </Box>
              <GridRow>
                <FormControlledTextField
                  label={t('percentageOfOwnership', 'Percentage of ownership')}
                  name="ownershipPercent"
                  type="text"
                  fullWidth
                  required={false}
                  className={'inputWithSymbol'}
                  data-test="ownershipPercent"
                />
              </GridRow>

              {type === UboStakeType.Percent25OrMoreViaPoA && (
                <GridRow>
                  <Box className={classes.info}>
                    <CommonTipItem
                      value={t(
                        'weWillSendYouAStandardForm',
                        'After the application is completed we will send you a standard form to specify the details of the Trust or Power of attorney.',
                      )}
                      iconComponent={<InformationIcon />}
                    />
                  </Box>
                </GridRow>
              )}
            </Box>
            {!isDomiciliary && type === UboStakeType.Percent25OrMore && totalShare() > 75.01 && (
              <CommonTipItem
                value={t(
                  'controlShareMessage',
                  'You have added beneficial owners of 75% of the shares. You don`t need to add more owners',
                )}
                iconComponent={<InformationIcon />}
              />
            )}
            <Box className={'buttonsBox'}>
              <Box className={'secondaryButton'}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={handleBack}
                >
                  {t('back', 'Back')}
                </Button>
              </Box>

              <LoadingButton
                className={'button'}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                data-test={isNewRecord ? 'submitAndProceed' : 'saveChanges'}
              >
                {isNewRecord
                  ? t('submitAndProceed', 'Submit and proceed')
                  : t('saveChanges', 'Save changes')}
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </FormProvider>
    </>
  )
}
