import React, { FC } from 'react'
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useParams } from 'react-router-dom'
import { useDetermineUserRights } from '../../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'

const useStyles = makeStyles(() => ({
  itemControlPanel: {
    width: '100%',
    position: 'relative',
    margin: '0 0 20px 0',
    '& .MuiTab-labelIcon': {
      minHeight: '40px',
    },
  },
  titleTabs: {
    fontSize: '16px',
    textTransform: 'initial',
    fontWeight: 'bold',
    padding: 0,
    margin: '0 32px 0 0',
    maxWidth: 'auto',
    minWidth: 'auto',
    color: '#434343',
    opacity: 1,
    '&.Mui-selected': {
      opacity: 1,
      color: '#000000',
      pointerEvents: 'none',
    },
  },
  badge: {
    '& .MuiBadge-root': {
      paddingRight: 35,
    },
  },
}))

export enum PeopleTabName {
  Team = 'team',
  Contacts = 'contacts',
  Directors = 'directors',
}

export const CmPeopleTabs: FC<{
  localCurrentStep: string
  handleChange: (_: React.SyntheticEvent<EventTarget>, newValue: number) => void
}> = ({ localCurrentStep, handleChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)

  return (
    <Box className={classes.itemControlPanel}>
      <Tabs
        value={localCurrentStep}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        data-test="tabBar"
      >
        {!userRights?.isIntroducerContractOwner && !userRights?.isIndividualContractOwner && (
          <Tab
            value={PeopleTabName.Team}
            label={t('team', 'Team')}
            className={classes.titleTabs}
            component={Link}
            to={generatePath(APP_PATHS.dashboard.contractManagement.people, {
              applicationId,
              [PATH_PARAMS.peopleTab]: PeopleTabName.Team,
            })}
            data-test="teamTab"
          />
        )}
        {!userRights?.isIndividualContractOwner && !userRights?.isIntroducerContractOwner && (
          <Tab
            value={PeopleTabName.Contacts}
            label={t('contacts', 'Contacts')}
            className={classes.titleTabs}
            component={Link}
            to={generatePath(APP_PATHS.dashboard.contractManagement.people, {
              applicationId,
              [PATH_PARAMS.peopleTab]: PeopleTabName.Contacts,
            })}
            data-test="contactsTab"
          />
        )}
        {!userRights?.isIndividualContractOwner && !userRights?.isIntroducerContractOwner && (
          <Tab
            value={PeopleTabName.Directors}
            label={t('directors', 'Directors')}
            className={classes.titleTabs}
            component={Link}
            to={generatePath(APP_PATHS.dashboard.contractManagement.people, {
              applicationId,
              [PATH_PARAMS.peopleTab]: PeopleTabName.Directors,
            })}
            data-test="directorsTab"
          />
        )}
      </Tabs>
    </Box>
  )
}
