import { makeVar } from '@apollo/client'

// Payment Tab
export const currentStepVar = makeVar<number>(0)
export const currentStepVarPay = makeVar<number>(0)
export const accTypeVar = makeVar<number>(1)

// Contract Management Tab
export const currentStepContractManagementVar = makeVar<number>(0)

// Card alerts

export const cardAlertHeader = makeVar<string | undefined>(undefined)

export const cardAlert = makeVar<string | undefined>(undefined)

// Vban alerts

export const vbanAlert = makeVar<string | undefined>(undefined)

// Batch payments

export const validPaymentsCount = makeVar<number>(0)
export const isBatchFileUploaded = makeVar<boolean>(false)

// Personal settings two-factor authentication tabs

export const personalProfileSettingsTab = makeVar<number>(0)

export const walletTrxVarPay = makeVar<number>(0)
