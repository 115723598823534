import TextField from '@material-ui/core/TextField'
import React, { FC, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AlertTipItem, LoadingButton } from '../../components'
import WarningIcon from '../../assets/images/icons/icon-info2.svg?react'
import { GetTransactionDetailsDocument, useCancelTransactionMutation } from '../../graphql'

export const CancelScheduledTrxModal: FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  trxId: string | number
}> = ({ open, setOpen, trxId }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [reason, setReason] = useState<string>('')

  const [cancelTransaction, { loading }] = useCancelTransactionMutation({
    variables: {
      transactionId: trxId.toString(),
      reason,
    },
    refetchQueries: [
      {
        query: GetTransactionDetailsDocument,
        variables: { id: trxId },
      },
    ],
  })

  const handleCancelTrx = async () => {
    try {
      await cancelTransaction()
      setOpen(false)
    } catch (e) {
      toast.error((e as Error).message)
    }
  }

  const handleClose = () => {
    setReason('')
    setOpen(false)
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography variant="h3">{t('cancelPayment', 'Cancel payment')}</Typography>
          </Grid>

          <Grid item>
            <IconButton aria-label="close section" edge="end" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
          <AlertTipItem
            value={t(
              'youWillCancelNextAndAllConsequentPayments',
              'You will cancel the next and all the consequent payments',
            )}
            iconComponent={<WarningIcon />}
            type="warning"
          />
          <TextField
            label={t('cancellationReason', 'Cancellation reason')}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            type="text"
            fullWidth
            autoFocus
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box
          mr={2}
          mb={1}
          mt={2}
          width="100%"
          display="flex"
          justifyContent="flex-end"
          gridGap={theme.spacing(3)}
        >
          <Button variant="contained" color="default" disableElevation onClick={handleClose}>
            {t('dontCancel', 'Don’t cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleCancelTrx}
            loading={loading}
            disabled={reason.length < 2}
          >
            {t('confirmCancellation', 'Confirm cancellation')}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
