import React, { FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import clsx from 'clsx'

import { Box, Collapse, Grid, Link, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import IconX from '../../assets/images/icons/icon-x.svg?react'
import IconDevice from '../../assets/images/icons/2faIcon.svg?react'
import IconDeviceWarning from '../../assets/images/icons/iconDeviceWarning.svg?react'

import {
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  ActionSigningCodeResponse,
  GetActionsDocument,
  GetStandingOrderDocument,
  GetTransactionsDocument,
  Maybe,
  OrderDirection,
  TransactionOrderBy,
  TwoFaMethod,
  useGetActionSigningCodeMutation,
  useSignActionWithChallengeCodeMutation,
  useSignMultipleActionsWithChallengeCodeMutation,
} from '../../graphql'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { resendDelay } from '../../graphql/local'
import { InputCodeField } from './InputCode'
import { checkFullCode } from '../../utils'
import { useCurrentUser } from '../../hooks'
import { MODAL_WRAPPER_ZINDEX, TOTP_STRING_PARAM } from '../../constants'
import { TwoFAModalRenderButton } from './TwoFAModalRenderButton'
import { generatePath, useHistory } from 'react-router-dom'
import { personalProfileSettingsTab } from '../../graphql/local/dashboard'
import { PaymentResult, SettingsTabsEnum } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    overflow: 'auto',
    maxHeight: '100vh',
    minWidth: 328,
    maxWidth: 552,
    padding: theme.spacing(8, 6, 3.5, 6),
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 14, 3),
    },
    '&:focus': {
      outline: 'none',
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
      position: 'absolute',
      left: '0',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
  btnChange2FA: {
    textDecoration: 'underline',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    margin: theme.spacing(2, 0, 0, 0),
  },
  btnChange: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export type SignableTransactionLike = {
  id?: Maybe<string | number> | undefined
  executionAction?:
    | Maybe<{
        id?: Maybe<string | number> | undefined
        signatures?:
          | (
              | { id: Maybe<string | number> | undefined; isMine?: Maybe<boolean> | undefined }
              | undefined
              | null
            )[]
          | undefined
          | null
      }>
    | undefined
}

export const Transaction2FAModal: FC<{
  openAction: string | undefined | null
  handleClose: (success?: boolean) => void
  actionData: SignableTransactionLike | undefined
  children?: React.ReactNode
  accountId?: string | number
  walletId?: string
  itemsPerPage?: number
  page?: number
  direction?: OrderDirection
  setTransferSign?: React.Dispatch<SetStateAction<boolean | null>>
  batchTrxSign?: (string | number | undefined)[]
  setPaymentResult?: React.Dispatch<SetStateAction<PaymentResult | undefined>>
}> = ({
  openAction,
  handleClose,
  actionData,
  children,
  accountId,
  walletId,
  itemsPerPage = 10,
  page = 1,
  direction,
  setTransferSign,
  batchTrxSign,
  setPaymentResult,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const initialValue = useMemo(() => ({ d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }), [])
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [attemptsLeft, setAttemptsLeft] = useState(0)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMassage, setValidMassage] = useState<string>('')
  const [successClose, setSuccessClose] = useState(false)

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.standingOrderId]: standingOrderId,
  } = useParams() as Record<string, string>

  const startInput = useRef<HTMLInputElement>(null)
  const resultCheckFullCode = checkFullCode(code)
  const currentUser = useCurrentUser()

  const [
    getActionSigningCodeMutation,
    { data: actionServiceData },
  ] = useGetActionSigningCodeMutation()

  const [
    signActionWithChallengeCodeMutation,
    { loading },
  ] = useSignActionWithChallengeCodeMutation()

  const refetchQueriesArr = useMemo(
    () =>
      [
        {
          query: GetActionsDocument,
          variables: {
            contractId: applicationId,
            pendingFirst: true,
          },
        },
        {
          query: ActionSignatureRequestsCountDocument,
          variables: {
            contractId: applicationId,
            statuses: [ActionSignatureStatus.Pending],
          },
        },
        {
          query: GetTransactionsDocument,
          variables: {
            contractId: applicationId,
            accountId,
            walletId,
            limit: itemsPerPage,
            offset: (page - 1) * itemsPerPage,
            orderBy: TransactionOrderBy.CreatedAt,
            orderDirection: direction,
          },
        },
        standingOrderId && {
          query: GetStandingOrderDocument,
          variables: {
            standingOrderId,
          },
        },
      ].filter(Boolean),
    [accountId, applicationId, direction, itemsPerPage, page, walletId],
  )
  const [signMultipleActionsWithChallengeCode] = useSignMultipleActionsWithChallengeCodeMutation()

  const handleActionServiseData = useCallback(async () => {
    try {
      const { data } = await getActionSigningCodeMutation()
      if (data) {
        const {
          resendTimeout,
          attemptsLeft,
        } = data?.getActionSigningCode as ActionSigningCodeResponse
        resendDelay((resendTimeout as number) * 1000)
        setAttemptsLeft(attemptsLeft as number)
      }
    } catch (e) {
      toast.error((e as Error).message)
      handleClose()
    }
  }, [getActionSigningCodeMutation, handleClose])

  const onReSend = useCallback(async () => {
    if (actionData) {
      setCode(initialValue)
      setIsError(false)
      setBtnDisable(true)
      setSecondsLeft(millisecondsToSeconds(resendDelay()))
      setValidMassage('')
      handleActionServiseData().then()
    }
  }, [actionData, initialValue, handleActionServiseData])

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  const onSendCode = useCallback(
    async (_, code) => {
      try {
        const signatureId = (
          actionData?.executionAction?.signatures?.find((s) => s?.isMine) ||
          actionData?.executionAction?.signatures?.[0]
        )?.id as string | number
        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        await signActionWithChallengeCodeMutation({
          variables: {
            actionSignatureId: signatureId,
            code: code,
            challengeId: `${challengeId}`,
            status:
              openAction?.toLowerCase() === 'sign'
                ? ActionSignatureStatus.Accept
                : ActionSignatureStatus.Reject,
          },
          refetchQueries: refetchQueriesArr,
        })
        setIsError(false)
        setValidMassage(t('codeCorrect', 'Code is correct'))
        setPaymentResult && setPaymentResult(PaymentResult.submitted)
        setSuccessClose(true)
      } catch (e) {
        setIsError(true)
        setPaymentResult && setPaymentResult(PaymentResult.pending)
        setValidMassage(t('codeIncorrect', 'Code is incorrect'))
      }
    },
    [
      actionData?.executionAction?.signatures,
      actionServiceData,
      openAction,
      refetchQueriesArr,
      signActionWithChallengeCodeMutation,
      t,
      currentUser?.primaryTwoFAMethod,
    ],
  )

  const onSendCodeSignMultiple = useCallback(
    async (code) => {
      try {
        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        await signMultipleActionsWithChallengeCode({
          variables: {
            actionSignatureIds: batchTrxSign as (number | string)[],
            status:
              openAction === 'sign' ? ActionSignatureStatus.Accept : ActionSignatureStatus.Reject,
            challengeId: `${challengeId}`,
            code: code,
          },
          refetchQueries: refetchQueriesArr,
          awaitRefetchQueries: true,
        })
        setValidMassage(t('codeCorrect', 'Code is correct'))
        setSuccessClose(true)
      } catch (e) {
        setIsError(true)
        setValidMassage(t('codeIncorrect', 'Code is incorrect'))
      }
    },
    [actionServiceData, batchTrxSign, refetchQueriesArr, currentUser?.primaryTwoFAMethod],
  )

  useEffect(() => {
    ;(async () => {
      if (
        ((openAction && actionData) || !isEmpty(batchTrxSign)) &&
        currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
      ) {
        await handleActionServiseData()
      }
    })()
  }, [actionData, handleActionServiseData, openAction, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    if (successClose) {
      const timeoutClose = setTimeout(() => {
        handleClose(true)
        setTransferSign && setTransferSign(true)
      }, 2000)

      return () => {
        clearTimeout(timeoutClose)
        // refreshPage()
      }
    }
  }, [successClose])

  useEffect(() => {
    ;(async () => {
      const enteredCode = Object.values(code).join('')
      if (
        enteredCode.length === 6 &&
        (!isEmpty(actionServiceData) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp)
      ) {
        if (actionData) {
          await onSendCode(actionData.id, enteredCode)
        }

        if (!isEmpty(batchTrxSign)) {
          await onSendCodeSignMultiple(enteredCode)
        }
      }
    })()
  }, [actionData, actionServiceData, code, onSendCode, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMassage('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  const confirmSignatureMessage = useMemo(() => {
    return currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>
          {!isEmpty(actionServiceData)
            ? `${actionServiceData?.getActionSigningCode?.phoneLastFourDigits?.slice(2)}`
            : ''}
        </b>
        . {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </Typography>
    )
  }, [actionServiceData, currentUser?.primaryTwoFAMethod])

  return (
    <div>
      <Modal
        style={{ zIndex: MODAL_WRAPPER_ZINDEX }}
        open={!!openAction}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={clsx(
            classes.paper,
            resultCheckFullCode && isError && 'error',
            validMassage && !isError && 'success',
          )}
        >
          <IconButton
            className={classes.btnClose}
            color="primary"
            aria-label="close modal"
            component="span"
            onClick={() => handleClose()}
          >
            <IconX />
          </IconButton>
          <Grid className="iconContainer">{!isError ? <IconDevice /> : <IconDeviceWarning />}</Grid>

          {openAction === 'sign' ? (
            <Box textAlign="center" mb={1}>
              <Typography variant={'h2'}>{t('confirmSignature', 'Confirm signature')}</Typography>
            </Box>
          ) : (
            <Box textAlign="center" mb={1}>
              <Typography variant={'h2'}> {t('rejectSignature', 'Reject signature')}</Typography>
            </Box>
          )}

          {children}

          <Box height={48}>
            <Collapse
              in={
                !isEmpty(actionServiceData) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp
              }
            >
              <Box textAlign="left">{confirmSignatureMessage}</Box>
            </Collapse>
          </Box>

          <InputCodeField
            startInput={startInput}
            currencyValue={code}
            setCode={handleSetCode}
            loading={loading}
          />

          <Grid container justifyContent="center" className="infoText">
            {!!validMassage && resultCheckFullCode && <span>{validMassage}</span>}
          </Grid>

          <TwoFAModalRenderButton
            isNotShow={currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp}
            secondsLeft={secondsLeft}
            btnDisable={btnDisable}
            onReSend={onReSend}
            isShowContactSupportLink={attemptsLeft < 1}
          />
          <Grid item xs={12} className={classes.btnChange}>
            <Link
              component="button"
              onClick={() => {
                personalProfileSettingsTab(2)
                history.push(
                  generatePath(APP_PATHS.dashboard.settings, {
                    [PATH_PARAMS.applicationId]: applicationId,
                  }) + `?tabId=${SettingsTabsEnum.twoFactor}`,
                )
              }}
              className={classes.btnChange2FA}
              underline="always"
            >
              {t('changeVerificationMethod', 'Change verification method')}
            </Link>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}
