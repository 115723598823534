import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { isEmpty, toNumber } from 'lodash'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import clsx from 'clsx'

import { Box, Collapse, Grid, Link, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import IconDeviceWarning from '../../../assets/images/icons/iconDeviceWarning.svg?react'
import { OperationNames, SettingsTabsEnum } from '../../../types'
import { docDescriptionOrActionType } from '../contract'
import IconDevice from '../../../assets/images/icons/2faIcon.svg?react'
import IconX from '../../../assets/images/icons/icon-x.svg?react'
import { checkFullCode, currencyFormat, getCurrencySign } from '../../../utils'
import { resendDelay } from '../../../graphql/local'
import { APP_PATHS, PATH_PARAMS, ROOT_PATHS } from '../../../routes/paths'
import { InputCodeField } from '../../../components/Common/InputCode'
import {
  Action,
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  ActionType,
  CardsCountDocument,
  CardsListDocument,
  DebitTransaction,
  GetAccountsDocument,
  GetActionDetailsDocument,
  Maybe,
  OrderBy,
  OrderDirection,
  Scalars,
  TransactionAction,
  TwoFaCodeDispatchMode,
  TwoFaMethod,
  useGetActionSigningCodeMutation,
  useSignActionWithChallengeCodeMutation,
  useSignMultipleActionsWithChallengeCodeMutation,
} from '../../../graphql'
import { useCurrentUser } from '../../../hooks'
import { MODAL_WRAPPER_ZINDEX, TOTP_STRING_PARAM } from '../../../constants'
import { TwoFAModalRenderButton } from '../../../components'
import { generatePath, useHistory } from 'react-router-dom'
import { personalProfileSettingsTab } from '../../../graphql/local/dashboard'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    overflow: 'auto',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    width: '100%',
    minWidth: 328,
    maxWidth: 552,
    padding: theme.spacing(8, 6, 3.5),
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 4.5),
    },
    '&:focus': {
      outline: 'none',
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
        position: 'absolute',
        left: '0',
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: 'rgb(239, 40, 40)',
        position: 'absolute',
        left: '0',
      },
    },
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
  actionName: {
    margin: theme.spacing(1.5, 0),
    padding: theme.spacing(0, 2),
    textDecoration: 'underline',
    fontSize: 14,
    lineHeight: '24px',
  },
  btnChange2FA: {
    textDecoration: 'underline',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    margin: theme.spacing(2, 0, 0, 0),
  },
  btnChange: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const initialValue = { d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }

export const ActionsSignModal: FC<{
  openAction: string | undefined
  handleClose: (success?: boolean) => void
  actionData: Action | undefined
  children?: React.ReactNode
  multipleActionsData: Action[]
  setAddAccountSuccess?: (value: boolean) => void
  refetchActions?: () => void
}> = ({
  openAction,
  setAddAccountSuccess,
  handleClose,
  actionData,
  multipleActionsData,
  refetchActions,
  children,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [attemptsLeft, setAttemptsLeft] = useState(0)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMassage, setValidMassage] = useState<string>('')
  const [successClose, setSuccessClose] = useState(false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const startInput = useRef<HTMLInputElement>(null)
  const resultCheckFullCode = checkFullCode(code)
  const currentUser = useCurrentUser()

  const isApplicationRout = history.location.pathname.includes(ROOT_PATHS.application)

  const [
    getActionSigningCodeMutation,
    { data: actionServiceData, loading: actionServDataLoading },
  ] = useGetActionSigningCodeMutation()

  const [
    signActionWithChallengeCodeMutation,
    { loading },
  ] = useSignActionWithChallengeCodeMutation()

  const [signMultipleActionsWithChallengeCode] = useSignMultipleActionsWithChallengeCodeMutation()

  const refetchQueriesArr = useMemo(() => {
    const singleActionRefetch = [
      {
        query: GetActionDetailsDocument,
        variables: {
          id: actionData?.id,
        },
      },
    ]
    const standartRefetchArr = [
      {
        query: GetAccountsDocument,
        variables: {
          contractId: applicationId,
          orderBy: OrderBy.CreatedAt,
          orderDirection: OrderDirection.Descending,
        },
      },
      {
        query: CardsListDocument,
        variables: {
          contractId: applicationId,
          limit: 10,
          orderBy: OrderBy.CreatedAt,
          orderDirection: OrderDirection.Descending,
          offset: 0,
        },
      },
      {
        query: CardsCountDocument,
        variables: {
          contractId: applicationId,
        },
      },
      {
        query: ActionSignatureRequestsCountDocument,
        variables: {
          contractId: applicationId,
          statuses: [ActionSignatureStatus.Pending],
        },
      },
    ]

    return isEmpty(actionData)
      ? standartRefetchArr
      : [...standartRefetchArr, ...singleActionRefetch]
  }, [applicationId])

  const confirmSignatureMessage = useMemo(() => {
    return currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>
          {!isEmpty(actionServiceData)
            ? `${actionServiceData?.getActionSigningCode?.phoneLastFourDigits?.slice(2)}`
            : ''}
        </b>
        . {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </Typography>
    )
  }, [actionServiceData, currentUser?.primaryTwoFAMethod])

  const handleActionServiceData = useCallback(
    async (mode?: TwoFaCodeDispatchMode) => {
      try {
        const { data } = await getActionSigningCodeMutation({ variables: { mode } })
        if (data?.getActionSigningCode) {
          const { resendTimeout, attemptsLeft } = data.getActionSigningCode
          resendDelay((resendTimeout as number) * 1000)
          setAttemptsLeft(attemptsLeft as number)
        }
      } catch (e) {
        toast.error((e as Error).message)
        handleClose(false)
        // refreshPage()
      }
    },
    [getActionSigningCodeMutation, handleClose],
  )

  const onReSend = useCallback(
    async (mode?: TwoFaCodeDispatchMode) => {
      if (actionData) {
        setCode(initialValue)
        setIsError(false)
        setBtnDisable(true)
        setSecondsLeft(millisecondsToSeconds(resendDelay()))
        setValidMassage('')
        handleActionServiceData(mode).then()
      }
    },
    [actionData, handleActionServiceData],
  )

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => {
      return {
        ...prevState,
        [id]: value,
      }
    })
  }

  const handleSuccessCode = () => {
    setIsError(false)
    setValidMassage(t('codeCorrect', 'Code is correct'))
    setSuccessClose(true)
  }

  const onSendCode = useCallback(
    async (id, code) => {
      try {
        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        await signActionWithChallengeCodeMutation({
          variables: {
            actionSignatureId: id,
            code: code,
            challengeId: `${challengeId}`,
            status:
              openAction === OperationNames.sign
                ? ActionSignatureStatus.Accept
                : ActionSignatureStatus.Reject,
          },
          refetchQueries: refetchQueriesArr,
        })

        handleSuccessCode()
        setAddAccountSuccess && setAddAccountSuccess(true)
        // handleClose()
      } catch (e) {
        setIsError(true)
        if (e.response?.data?.tokenExpired || e.response?.data?.code === 'EXPIRED_2FA') {
          setValidMassage(t('codeHasExpired', 'Code has expired'))
        } else {
          setValidMassage(t('codeIncorrect', 'Code is incorrect'))
        }
      } finally {
        setTimeout(() => {
          history.push(history.location.pathname)
        }, 5000)
      }
    },
    [
      actionServiceData,
      openAction,
      refetchQueriesArr,
      signActionWithChallengeCodeMutation,
      t,
      currentUser?.primaryTwoFAMethod,
    ],
  )

  const onSendCodeSignMultiple = useCallback(
    async (code) => {
      try {
        const signActionIds = multipleActionsData.map(
          (action) => action?.signatures?.find((signature) => signature?.isMine)?.id,
        )

        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        await signMultipleActionsWithChallengeCode({
          variables: {
            actionSignatureIds: !isEmpty(signActionIds)
              ? (signActionIds as Maybe<Scalars['ID']['input']>[] | string | number | null)
              : null,
            status:
              openAction === OperationNames.sign
                ? ActionSignatureStatus.Accept
                : ActionSignatureStatus.Reject,
            challengeId: `${challengeId}`,
            code: code,
          },
          refetchQueries: refetchQueriesArr,
        })
        handleSuccessCode()
        // handleClose()
      } catch (e) {
        setIsError(true)
        setValidMassage(t('codeIncorrect', 'Code is incorrect'))
      }
    },
    [
      actionServiceData,
      multipleActionsData,
      refetchQueriesArr,
      signMultipleActionsWithChallengeCode,
      t,
      currentUser?.primaryTwoFAMethod,
    ],
  )

  const isMultipleActions = !actionData

  const [actionAmount, actionName, actionCurrency] = useMemo(() => {
    if (isMultipleActions || !actionData) return ['-', '-', '']

    const { transaction } = actionData as TransactionAction
    switch (actionData?.__typename) {
      case 'AccountAction':
        return [
          actionData.account?.balance?.available,
          actionData.account?.alias,
          actionData.account?.currency,
        ]
      case 'TransactionAction':
        return [
          actionData.transaction?.value,
          (transaction as DebitTransaction)?.to?.name,
          actionData.transaction?.currency,
        ]
      default:
        return ['-', '-', '']
    }
  }, [actionData])

  const handleRedirect = () => {
    personalProfileSettingsTab(2)
    const path = isApplicationRout ? APP_PATHS.application.settings : APP_PATHS.dashboard.settings
    history.push(
      generatePath(path, {
        [PATH_PARAMS.applicationId]: applicationId,
      }) + `?tabId=${SettingsTabsEnum.twoFactor}`,
    )
  }

  useEffect(() => {
    if (
      openAction &&
      (actionData || !isEmpty(multipleActionsData)) &&
      currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
    ) {
      handleActionServiceData().then()
    }
  }, [openAction, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    if (successClose) {
      const timeoutClose = setTimeout(() => {
        handleClose(true)
        refetchActions && refetchActions()
        setAddAccountSuccess && setAddAccountSuccess(false)
      }, 2000)

      return () => {
        clearTimeout(timeoutClose)
      }
    }
  }, [successClose])

  useEffect(() => {
    const enteredCode = Object.values(code).join('')
    if (
      enteredCode.length === 6 &&
      (!isEmpty(actionServiceData) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp)
    ) {
      if (actionData) {
        const signatureId = actionData?.signatures?.find((signature) => signature?.isMine)?.id
        onSendCode(signatureId, enteredCode).then()
      } else if (!isEmpty(multipleActionsData)) {
        onSendCodeSignMultiple(enteredCode).then()
      }
    }
  }, [code, currentUser?.primaryTwoFAMethod])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMassage('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  return (
    <div>
      <Modal
        style={{ zIndex: MODAL_WRAPPER_ZINDEX }}
        open={!!openAction}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test="actionsSignModal"
      >
        <div
          className={clsx(
            classes.paper,
            resultCheckFullCode && isError && 'error',
            validMassage && !isError && 'success',
          )}
        >
          <IconButton
            className={classes.btnClose}
            color="primary"
            aria-label="close modal"
            component="span"
            onClick={() => handleClose(false)}
          >
            <IconX />
          </IconButton>

          <Grid className="iconContainer">{!isError ? <IconDevice /> : <IconDeviceWarning />}</Grid>

          {openAction === OperationNames.sign ? (
            <Box textAlign="center" mb={1}>
              <Typography variant={'h2'}>{t('confirmSignature', 'Confirm signature')}</Typography>
            </Box>
          ) : (
            <Box textAlign="center" mb={1}>
              <Typography variant={'h2'}> {t('rejectSignature', 'Reject signature')}</Typography>
            </Box>
          )}

          {!!children && <Box textAlign="left">{children}</Box>}

          {actionData && actionData.type === ActionType.TransactionSigning && !isMultipleActions && (
            <Box textAlign="left">
              <Typography>
                {t(
                  'paymentSingle2FALabel',
                  'You are about to sign the {{amount}} payment to {{name}}. You won’t be able to revoke this action.',
                  {
                    name: actionName,
                    amount: `${getCurrencySign(actionCurrency)} ${currencyFormat(
                      Math.abs(toNumber(actionAmount)),
                    )}`,
                  },
                )}
              </Typography>
            </Box>
          )}

          {actionData && (actionData.type !== ActionType.TransactionSigning || isMultipleActions) && (
            <Box textAlign="left">
              <Typography>
                {t(
                  'signDocs2FA',
                  'Please approve the selected action. You won’t be able to revoke this action.',
                )}
              </Typography>
            </Box>
          )}
          <ul className={classes.actionName}>
            {isMultipleActions &&
              !actionData &&
              multipleActionsData.map((action) => {
                return <li key={action?.id}>{docDescriptionOrActionType(action)}</li>
              })}
          </ul>

          <Box height={48}>
            <Collapse
              in={
                !isEmpty(actionServiceData) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp
              }
            >
              <Box textAlign="left">{confirmSignatureMessage}</Box>
            </Collapse>
          </Box>

          <InputCodeField
            startInput={startInput}
            currencyValue={code}
            setCode={handleSetCode}
            loading={loading}
          />

          <Grid container justifyContent="center" className="infoText" data-test="validMassege">
            {!!validMassage && resultCheckFullCode && <span>{validMassage}</span>}
          </Grid>

          <TwoFAModalRenderButton
            isNotShow={currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp}
            secondsLeft={secondsLeft}
            btnDisable={btnDisable}
            onReSend={onReSend}
            isShowContactSupportLink={attemptsLeft < 1 && !actionServDataLoading}
          />
          <Grid item xs={12} className={classes.btnChange}>
            <Link
              component="button"
              onClick={handleRedirect}
              className={classes.btnChange2FA}
              underline="always"
            >
              {t('changeVerificationMethod', 'Change verification method')}
            </Link>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}
