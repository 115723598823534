import React, { FC, useCallback, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Fade, Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { isEmpty, isNil, last, omitBy } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SuccessIcon from '../../assets/images/icons/confirmed.svg?react'
import WarningIcon from '../../assets/images/icons/icon-info2.svg?react'

import {
  AlertTipItem,
  GoBackButton,
  HandleAwayFromPage,
  isBatchPaymentFile,
  isDirtyForm,
  isNavMenuRoute,
  Loader,
  LoadingButton,
} from '../../components'
import {
  Individual,
  Maybe,
  ProfileChange,
  ProfileChangeStatus,
  useGetProfileChangesQuery,
} from '../../graphql'
import { PersonProfileInputSchema } from '../../schemes'
import { TaxIdSchema } from '../../schemes/common'
import { scrollToInputElement } from '../../utils'
import { PersonProfileForm } from './forms'
import { EditRequestModal } from './modals'

const useStyles = makeStyles((theme) => ({
  wrap: {
    overflow: 'hidden',
    padding: theme.spacing(10, 1, 1, 1),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      marginTop: 0,
      marginLeft: 0,
    },
  },
  form: {
    width: '100%',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.125rem',
    maxWidth: 265,
    width: '100%',
    height: 48,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '580px',
    position: 'fixed',
    top: 8,
    zIndex: 1500,
    left: 0,
    right: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    '&>div': {
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  loader: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  noPadding: {
    padding: 0,
  },
}))

const PersonProfile: FC<{ hideBack?: boolean; taxIdRequired?: boolean }> = ({
  hideBack = false,
  taxIdRequired = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      taxIdRequired ? PersonProfileInputSchema.concat(TaxIdSchema) : PersonProfileInputSchema,
    ),
  })
  const { reset, errors, handleSubmit, formState } = methods
  const isDirtyForms = useReactiveVar(isDirtyForm)
  const batchPaymentModalBack = useReactiveVar(isBatchPaymentFile)

  const [personProfileFormData, setPersonProfileFormData] = useState<Individual | null>()
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  const [editRequestModalFormData, setEditRequestModalFormData] = useState<
    Individual | undefined | null
  >(undefined)
  const [textAlert, setTextAlert] = useState<string>('')
  const [typeAlert, setTypeAlert] = useState<'warning' | 'primary' | 'success' | undefined>()
  const [iconAlert, setIconAlert] = useState<React.ReactElement<HTMLElement, string> | null>(null)

  const { data: viewerData, loading: viewerLoading, refetch } = useGetProfileChangesQuery()

  const fillForm = () => {
    if (!isEmpty(personProfileFormData)) {
      reset({
        firstName: personProfileFormData?.firstName || '',
        lastName: personProfileFormData?.lastName || '',
        email: personProfileFormData?.email || '',
        phoneNumber: personProfileFormData?.phone || '',
        // phoneNumber: phoneNumber || personProfileFormData?.phone || '',
        birthday: personProfileFormData?.birthday || '',
        streetAddress: personProfileFormData?.address?.line1 || '',
        city: personProfileFormData?.address?.city || '',
        postalCode: personProfileFormData?.address?.zip || '',
        country: personProfileFormData?.address?.country || '',
        nationality: personProfileFormData?.nationality || '',
        additionalDetailsOptional: personProfileFormData?.address?.additionalDetails || '',
        taxId: personProfileFormData?.taxId || '',
      })
    }
  }

  const onCloseModal = () => {
    setOpenModal(false)
    fillForm()
  }

  const onSubmit = () => {
    setOpenModal(true)
  }

  const handleCloseModal = useCallback(() => {
    setTextAlert('')
  }, [])

  const handleStatus = (newStatus: Maybe<ProfileChangeStatus> | undefined) => {
    if (!newStatus || (textAlert && newStatus !== ProfileChangeStatus.Approved)) {
      return
    }
    switch (newStatus) {
      case ProfileChangeStatus.Approved:
        setTextAlert(
          t('successStatusFormPersonalProfile', 'Your personal profile is updated successfully'),
        )
        setTypeAlert('success')
        setIconAlert(<SuccessIcon />)
        break
      case ProfileChangeStatus.Pending:
        setTextAlert(t('pendingStatusFormPersonalProfile', { val1: 'Bivial' }))
        setTypeAlert('warning')
        setIconAlert(<WarningIcon />)
        break
      default:
        setTextAlert('')
        setTypeAlert(undefined)
        setIconAlert(null)
    }
  }

  useEffect(() => {
    refetch().then()
    return () => {
      isNavMenuRoute(false)
    }
  }, [])

  useEffect(() => {
    if (viewerData && !isOpenModal) {
      const viewer = viewerData?.viewer as Individual
      const profileChanges = viewer.profileChanges as ProfileChange[]
      const lastChanges = last(profileChanges)
      const isInProcessing =
        !isEmpty(profileChanges) && lastChanges?.status === ProfileChangeStatus.Pending
      handleStatus(lastChanges?.status)
      if (isInProcessing) {
        const answers = { ...lastChanges?.answers }

        answers.address = Object.assign(
          { ...answers?.address },
          lastChanges?.individual?.address,
          omitBy(answers?.address, isNil),
        )
        const result = Object.assign({}, lastChanges?.individual, omitBy(answers, isNil))

        setPersonProfileFormData(result)
      } else {
        setPersonProfileFormData(viewer)
      }
    }
  }, [viewerLoading, viewerData, isOpenModal])

  useEffect(() => {
    if (errors) {
      return scrollToInputElement(errors, 104)
    }
  }, [errors])

  useEffect(() => {
    isDirtyForm(formState.isDirty)
  }, [formState.isDirty])

  useEffect(() => {
    if (batchPaymentModalBack) {
      reset()
      ;(async () => {
        await refetch()
      })()
    }
  }, [batchPaymentModalBack, reset])

  if (viewerLoading) {
    return (
      <Box className={classes.loader}>
        <Loader />
      </Box>
    )
  }

  return (
    <HandleAwayFromPage when={isDirtyForms}>
      <Grid container className={clsx(classes.wrap, hideBack ? classes.noPadding : '')}>
        <Box className={classes.alertContainer}>
          {textAlert && (
            <AlertTipItem
              value={textAlert}
              iconComponent={iconAlert}
              type={typeAlert}
              isOpenAlert={!!textAlert}
              isCloseButton={true}
              setIsOpenAlert={handleCloseModal}
            />
          )}
        </Box>
        {!hideBack && (
          <Grid item xs={12} container className={classes.head}>
            <Grid item>
              <GoBackButton />
            </Grid>
            <Grid item>
              <Typography variant="h1" className={classes.title}>
                {t('personalProfile', 'Personal profile')}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} container>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <Grid item xs={12}>
                <PersonProfileForm
                  setEditRequestModalFormData={setEditRequestModalFormData}
                  personProfileFormData={personProfileFormData}
                  fillForm={fillForm}
                  taxIdRequired={taxIdRequired}
                />
              </Grid>
              <Fade in={!isEmpty(formState.dirtyFields)}>
                <Grid item className={classes.buttonGroup}>
                  <Box>
                    <LoadingButton
                      className={classes.button}
                      loading={false}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disableElevation
                      data-test="saveButton"
                    >
                      {t('saveChanges', 'Save changes')}
                    </LoadingButton>
                  </Box>
                  <Box>
                    <LoadingButton
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      onClick={onCloseModal}
                    >
                      {t('cancel', 'Cancel')}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Fade>
              {isOpenModal && (
                <EditRequestModal
                  isOpen={isOpenModal}
                  formData={editRequestModalFormData}
                  personProfileFormData={personProfileFormData}
                  closeModal={onCloseModal}
                  handleStatus={handleStatus}
                />
              )}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </HandleAwayFromPage>
  )
}

export default PersonProfile
