import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Box, Icon } from '@material-ui/core'
import { getTranslateFooterName } from '../../utils'

const useStyles = makeStyles((theme) => ({
  copyright: {
    padding: theme.spacing(1),
  },
  mb1: {
    marginBottom: theme.spacing(1.5),
  },
  mt1: {
    marginTop: theme.spacing(1.5),
  },
  dotContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  changeMargin: {
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      fontSize: '11px',
    },
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      '& span': {
        marginRight: '5px',
      },
    },
  },
}))

export const FooterNonAuthenticated: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const footerName = getTranslateFooterName(t)

  return (
    <Box>
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignContent="flex-start"
        alignItems="flex-start"
        className={`${classes.copyright}`}
      >
        <Grid item container className={classes.mb1}>
          <Grid item>
            <Typography variant="body2">
              <b>{footerName}</b>
            </Typography>
          </Grid>
          <Grid item className={classes.dotContainer}>
            <Box component="span" display="flex">
              <Icon style={{ fontSize: '5px' }}>fiber_manual_record</Icon>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <b>CHE-402.707.584</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.changeMargin}>
          <Typography variant="body2" align="left" className={classes.address}>
            <span>{t('industriestrasse246300', 'Industriestrasse 24, 6300')}</span>
            <span>
              {t('zugSwitzerlandAllRightsReserved', ' Zug, Switzerland. All rights reserved.')}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {t(
              'regulatedByTheSwissFederalBankingAct',
              'Regulated by the Swiss Federal Banking Act.',
            )}
          </Typography>
        </Grid>
        <Grid item container spacing={1} className={classes.mt1}>
          <Grid item>
            <img src={'/img/Flag_of_Switzerland 1.png'} alt={'Flag_of_Switzerland'} />
          </Grid>
          <Grid item>{t('madeInSwitzerland', 'Made in Switzerland')}</Grid>
        </Grid>
        {import.meta.env.REACT_APP_VERSION && (
          <Grid item container spacing={1} className={classes.mt1}>
            <Grid item>{`Version: ${import.meta.env.REACT_APP_VERSION}`}</Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
