import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import {
  CreditTransaction,
  DebitTransaction,
  TransactionEntryType,
  TransactionType,
} from '../../graphql'
import { dateFormat, transactionType } from '../../utils'
import HOME_CREDIT from '../../assets/images/icons/home-credit.svg?react'
import HOME_DEBIT from '../../assets/images/icons/home-debit.svg?react'
import FEE_ICON from '../../assets/images/icons/fee_icon.svg?react'
import TRANSFER_ICON from '../../assets/images/icons/transfer_icon.svg?react'

const useStyles = makeStyles(() => ({
  dateAccount: {
    fontSize: '12px',
    lineHeight: '24px',
    color: '#999999',
  },
}))

export const TransactionTypeSell: FC<{
  data: CreditTransaction | DebitTransaction
}> = ({ data }) => {
  const classes = useStyles()

  const { isInterContract, entryType, createdAt, type } = data

  const transactionTypeName = useMemo(() => {
    if (type === TransactionType.Fee && !isInterContract) {
      return transactionType(type)
    } else {
      return type === TransactionType.TransferInternal && !isInterContract
        ? transactionType(type)
        : transactionType(entryType as TransactionEntryType)
    }
  }, [entryType, isInterContract, type])

  const getIcon = (transactionTypeName: string) => {
    switch (transactionTypeName.toLocaleLowerCase()) {
      case 'outbound':
        return <HOME_CREDIT />
      case 'inbound':
      case 'credit':
        return <HOME_DEBIT />
      case 'transfer':
        return <TRANSFER_ICON />
      case 'fee':
        return <FEE_ICON />
      default:
        return null
    }
  }

  return (
    <Box display="flex" alignItems="center" gridGap={8}>
      <Box>{getIcon(transactionTypeName)}</Box>
      <Box>
        <Typography>{transactionTypeName}</Typography>
        <Typography className={classes.dateAccount}>{dateFormat(createdAt)}</Typography>
      </Box>
    </Box>
  )
}
