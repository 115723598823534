import { GUARANTEED_TAB } from '../../../constants'
import { useQuery, useUpdateUrlParams } from '../../../hooks'
import { TransactionsTabs } from '../../../types'

export const useManageGuaranteedTabsUrlParams = () => {
  const tabValue = Number(
    useQuery().get(GUARANTEED_TAB) ?? TransactionsTabs.all,
  ) as TransactionsTabs
  const setTabValue = useUpdateUrlParams(GUARANTEED_TAB)

  return { tabValue, setTabValue }
}
