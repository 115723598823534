import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, makeStyles, Theme } from '@material-ui/core'
import { RegistrationSteps, StepperTypes } from '../../../constants'
import { useStepper } from '../../../hooks'
import { accessRights as acessRightsVar } from '../../../graphql/local'
import { generatePath, useHistory } from 'react-router'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { DirectorsTable } from '../../Directors/DirectorsTable'

const useStyles = makeStyles((theme: Theme) => ({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 40,
      },
    },
    '& .MuiButton-outlinedSizeSmall': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.75, 2),
      },
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      marginBottom: theme.spacing(2),
    },
  },
  btnWrap: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const DirectorsTab: React.FC<{
  contractId: number | string
  companyName?: string
  fromReview: boolean
}> = ({ contractId, companyName = '', fromReview }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { currentStep } = useStepper(StepperTypes.registration)

  const onAdd = useCallback(() => {
    acessRightsVar('none')
    history.push(
      generatePath(APP_PATHS.application.directors.new, {
        [PATH_PARAMS.applicationId]: contractId,
      }),
    )
  }, [])

  return (
    <Box mt={3}>
      <Box className={classes.head}>
        <Box>
          <Typography variant="h2" className={classes.title} data-test="directors">
            {t('directors', 'Directors')}
          </Typography>
          {!fromReview && (
            <Typography variant={'body1'} style={{ marginTop: 10 }}>
              {t('addDirectors', 'Please add all directors of {{company}}', {
                company: companyName,
              })}
              :
            </Typography>
          )}
        </Box>
        {currentStep !== RegistrationSteps.directors && (
          <Box className={classes.btnWrap}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={onAdd}
              disableElevation
              size="small"
              data-test="addNewAccount-btn"
            >
              {t('addNew', 'Add new')}
            </Button>
          </Box>
        )}
      </Box>
      <DirectorsTable contractId={contractId} />
    </Box>
  )
}
