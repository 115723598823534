import React, { FC, useMemo } from 'react'
import { intersection, isNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { DssCompliance } from '../../../../graphql'
import { PATH_PARAMS } from '../../../../routes/paths'
import { PreAssessmentOnboardingInput } from '../../../../schemes'
import { ManagementBusinessDetailsInput } from '../../../../schemes/ManagementBusinessDetails'
import { YesNoOptions } from '../../../../utils/Data'
import { ProgressStep } from '../../Onboarding/Progress'
import { getPercentage } from '../helpers/functions'

export const PaymentProcessingPoint: FC<{
  filledFields: string[]
  ErrorList: string[]
  formValues: PreAssessmentOnboardingInput | ManagementBusinessDetailsInput
}> = ({ filledFields, ErrorList, formValues }) => {
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const additionalApmFields = useMemo(
    () =>
      Object.keys(formValues).filter(
        (item) =>
          item !== 'apmCurrentlyInp10' &&
          item !== 'apmCurrentlyInp20' &&
          item !== 'apmRequiredInp10' &&
          item !== 'apmRequiredInp20' &&
          (item.includes('apmCurrentlyInp') || item.includes('apmRequiredInp')),
      ) || [],
    [formValues],
  )

  const cardValuesFields = [
    'Visadomestic',
    'Visaeea',
    'VisanonEea',
    'Mastercarddomestic',
    'Mastercardeea',
    'MastercardnonEea',
  ]

  const sumOfCardValues = useMemo(
    () =>
      cardValuesFields.reduce((acc, field) => {
        const value: unknown = formValues[field]
        if (!value) return acc

        const normalizedValue = Number(value.toString().replace(/,/g, ''))
        return isNumber(normalizedValue) ? acc + normalizedValue : acc
      }, 0),
    [cardValuesFields, formValues],
  )

  const requiredPaymentProcessingFields = [
    'businessRegions',
    'trxCurrencies',
    'settlementCurrencies',
    ...(applicationId ? [] : ['processedHistory']),
    'apmCurrentlyInp10',
    'apmCurrentlyInp20',
    'apmRequiredInp10',
    'apmRequiredInp20',
    ...(applicationId ? [] : ['selAffiliatesTraffic']),
    ...(applicationId ? [] : ['selCredentialBillingMethod']),
    'merchantWebsites',
    'integrationTypeSelect',
    ...(formValues.processedHistory === YesNoOptions[0].key
      ? ['currentProcessor', 'periodWithProcessor']
      : []),
    ...(formValues.selAffiliatesTraffic === YesNoOptions[0].key ? ['affiliatesList'] : []),
    ...(formValues.selCredentialBillingMethod === YesNoOptions[0].key
      ? ['credentialDetailsExplanation']
      : []),
    ...(formValues.pciCompliance === DssCompliance.From3rdParty ? ['pciProvidersName'] : []),
  ]

  const optionalPaymentProcessingFields = [
    ...(formValues.processedHistory === YesNoOptions[0].key ? ['reasonLeavingProcessor'] : []),
  ]

  const hasErrors = useMemo(() => {
    const allFields = [
      ...requiredPaymentProcessingFields,
      ...additionalApmFields,
      ...optionalPaymentProcessingFields,
    ]

    return !!intersection(allFields, ErrorList).length
  }, [
    optionalPaymentProcessingFields,
    requiredPaymentProcessingFields,
    ErrorList,
    additionalApmFields,
  ])

  const isPaymentProcessingCompleted = useMemo(() => {
    return (
      requiredPaymentProcessingFields.every((field) => filledFields.includes(field)) &&
      !hasErrors &&
      sumOfCardValues > 0
    )
  }, [requiredPaymentProcessingFields, sumOfCardValues, hasErrors])

  return (
    <ProgressStep
      stage={
        isPaymentProcessingCompleted
          ? 'done'
          : getPercentage(
              ['cardFields', ...requiredPaymentProcessingFields],
              [...filledFields, ...(sumOfCardValues > 0 ? ['cardFields'] : [])],
            )
      }
      error={hasErrors}
      label={t('paymentProcessing', 'Payment processing')}
    />
  )
}
