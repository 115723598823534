import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  ClickAwayListener,
  Collapse,
  FormControl,
  Grid,
  Link,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { head } from 'lodash'
import React, { FC, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'
import WarnIcon from '../../../assets/images/icons/icon-info2.svg?react'
import InfoIcon from '../../../assets/images/icons/info_icon.svg?react'

import { AlertTipItem, ButtonWithTooltip, FormTextField, goBackPathVar } from '../../../components'
import { ActionStatus, ActionType, useGetActionsQuery } from '../../../graphql'
import { useIsLimitedContract } from '../../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { TerminateContractInputSchema } from '../../../schemes'
import { TerminateContractModal } from './TerminateContractModal'
import { TerminateContractResult } from './TerminateContractResult'

const useStyles = (isLimitedContract: boolean) =>
  makeStyles((theme: Theme) => ({
    button: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
    },
    btnContainer: {
      width: 'fit-content',
    },
    wrap: {
      overflow: 'hidden',
      padding: theme.spacing(10, 1, 1, 1),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(4),
      },
      '&.MuiGrid-container': {
        display: 'block',
        height: '100%',
      },
    },
    formControl: {
      pointerEvents: isLimitedContract ? 'none' : 'unset',
      width: '100%',
      '& .MuiInputBase-input:-webkit-autofill': {
        boxShadow: '0 0 0 42px white inset !important',
        webkitBoxShadow: '0 0 0 42px white inset !important',
      },
      '& .MuiInputBase-input:-webkit-autofill:hover': {
        boxShadow: '0 0 0 42px white inset !important',
        webkitBoxShadow: '0 0 0 42px white inset !important',
      },
      '& .MuiInputBase-input:-webkit-autofill:focus': {
        boxShadow: '0 0 0 42px white inset !important',
        webkitBoxShadow: '0 0 0 42px white inset !important',
      },
      '& .MuiInputBase-input:-webkit-autofill:active': {
        boxShadow: '0 0 0 42px white inset !important',
        webkitBoxShadow: '0 0 0 42px white inset !important',
      },
      '& .MuiInputBase-input:-internal-autofill-selected': {
        webkitBoxShadow: '0 0 0 42px white inset !important',
        boxShadow: '0 0 0 42px white inset !important',
      },
    },
    alertItem: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2.5),
      },
    },
    link: {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  }))()

export const TerminateContractForm: FC<{
  setShowTabsMenu: React.Dispatch<React.SetStateAction<boolean>>
  showTabsMenu: boolean
}> = ({ setShowTabsMenu, showTabsMenu = true }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const history = useHistory()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const [isOpen, setIsOpen] = useState(false)
  const [signatureIdToSign, setSignatureIdToSign] = useState<string | number | undefined>(undefined)
  const [showTooltip, setShowTooltip] = useState(false)

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(TerminateContractInputSchema),
  })

  const { isLimitedContract, contractStatusLabel, loading } = useIsLimitedContract()
  const classes = useStyles(isLimitedContract)

  const tooltipMessage = t(
    'isNotAvailable',
    `Terminating contract is not available, because the contract is ${contractStatusLabel}`,
    {
      option: 'Terminating contract',
      contractStatusLabel,
    },
  )

  const { data: actionsData, loading: actionsLoading } = useGetActionsQuery({
    variables: {
      contractId: applicationId,
      types: ActionType.ContractTerminationRequest,
      status: ActionStatus.PendingSignatures,
    },
    fetchPolicy: 'cache-and-network',
  })

  const terminateActionId = head(actionsData?.actions)?.id

  const onSubmit = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleShowTooltip = () => {
    isLimitedContract && setShowTooltip(true)
  }
  const handleHideTooltip = () => {
    isLimitedContract && setShowTooltip(false)
  }

  const handlePrevent = (e: React.MouseEvent) => {
    if (!isLimitedContract) return
    e.preventDefault()
    e.stopPropagation()
  }

  const redirectToActionDetails = () => {
    if (terminateActionId) {
      goBackPathVar(history.location.pathname)
      history.push(
        generatePath(APP_PATHS.dashboard.actionDetails, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.signatureId]: terminateActionId,
        }),
      )
    }
  }

  return (
    <>
      {!showTabsMenu ? (
        <Grid container className={classes.wrap}>
          <TerminateContractResult
            signatureIdToSign={signatureIdToSign}
            setShowTabsMenu={setShowTabsMenu}
          />
        </Grid>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container direction={'column'} spacing={3}>
              <Grid item xs={12} md={9} lg={7}>
                <Box className={classes.alertItem}>
                  <Collapse in={!actionsLoading}>
                    {terminateActionId ? (
                      <AlertTipItem
                        value={
                          <Typography align={isMobileScreen ? 'left' : 'center'}>
                            {t('terminateContract', 'Terminate contract')}{' '}
                            <Link
                              onClick={redirectToActionDetails}
                              underline="always"
                              className={classes.link}
                            >
                              {t('action', 'action')}
                            </Link>{' '}
                            {t('isAlreadyCreated', 'is already created')}
                          </Typography>
                        }
                        iconComponent={<InfoIcon />}
                        type={'primary'}
                      />
                    ) : (
                      <AlertTipItem
                        value={`${t('terminateContractText')}`}
                        iconComponent={<WarnIcon />}
                        type={'warning'}
                      />
                    )}
                  </Collapse>
                </Box>
              </Grid>

              <Grid item xs={12} md={9} lg={7}>
                <ClickAwayListener onClickAway={handleHideTooltip}>
                  <Tooltip
                    title={tooltipMessage}
                    disableHoverListener={!isLimitedContract}
                    disableFocusListener={!isLimitedContract}
                    disableTouchListener={!isLimitedContract}
                    open={showTooltip}
                    arrow
                  >
                    <Box
                      onTouchStart={handleShowTooltip}
                      onMouseEnter={handleShowTooltip}
                      onMouseLeave={handleHideTooltip}
                      onClick={handlePrevent}
                    >
                      <FormControl className={classes.formControl}>
                        <FormTextField
                          autoFocus={!isLimitedContract && !loading}
                          label={t('terminationReason')}
                          name="terminationReasonOptional"
                          type="text"
                          defaultValue={''}
                          minLength={2}
                          required={false}
                          onClick={handlePrevent}
                        />
                      </FormControl>
                    </Box>
                  </Tooltip>
                </ClickAwayListener>
              </Grid>

              <Grid item className={classes.btnContainer}>
                <ButtonWithTooltip
                  title={tooltipMessage}
                  disabled={isLimitedContract || actionsLoading || Boolean(terminateActionId)}
                  type="submit"
                  color="secondary"
                  className={classes.button}
                  hiddeTooltip={Boolean(terminateActionId)}
                >
                  <u>{t('terminateContract', 'Terminate contract')}</u>
                </ButtonWithTooltip>
              </Grid>
              <TerminateContractModal
                isOpen={isOpen}
                closeModal={handleCloseModal}
                setShowTabsMenu={setShowTabsMenu}
                setSignatureIdToSign={setSignatureIdToSign}
              />
            </Grid>
          </form>
        </FormProvider>
      )}
    </>
  )
}
