import React, { FC, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'
import { BatchPaymentProps } from '../contract'
import { SignaturesTable } from './helpers'
import clsx from 'clsx'
import { BeneficiariesListBatch } from '../../../components/BatchPayment/BeneficiariesListBatch'
import { BatchPaymentActionType, BatchPaymentStatusType } from '../../../graphql'
import { batchStatusNormalize, currencyFormat } from '../../../utils'
import { AlertTipItem } from '../../../components'
import AlertIcon from '../../../assets/images/icons/alert_icon.svg?react'

export const BatchTab: FC<{ data: BatchPaymentProps; signedCount: number }> = ({
  data,
  signedCount,
}) => {
  const { t } = useTranslation()
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const classes = useStyles()
  const totalAmount =
    (data?.rawData as BatchPaymentActionType)?.batchPayment?.calculatedValidTotal || 0
  const accCurrency = (data?.rawData as BatchPaymentActionType)?.batchPayment?.account?.currency
  const batchId = (data?.rawData as BatchPaymentActionType)?.batchPayment?.id

  useEffect(() => {
    if (data?.status === BatchPaymentStatusType.ExecutedWithFailure) {
      setIsOpenAlert(true)
    }
  }, [data?.status])

  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          <TableRow className={classes.borderTop}>
            <TableCell width="200">
              <Box component="span" className={classes.label}>
                {t('transactionStatus', 'Transaction status')}
              </Box>
            </TableCell>
            <TableCell>
              <Box
                component="span"
                className={clsx(`roundedPill ${data.status && batchStatusNormalize(data.status)}`)}
              >
                {data.status && t(`${batchStatusNormalize(data.status)}`)}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow className={classes.borderTop}>
            <TableCell width="200">
              <Box component="span" className={classes.label}>
                {t('account', 'Account')}
              </Box>
            </TableCell>
            <TableCell>
              <Box component="span" className={classes.capitalize}>
                {`${data.account} (${data.iban})`}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow className={classes.borderTop}>
            <TableCell width="200">
              <Box component="span" className={classes.label}>
                {t('paymentNumber', 'Payment number')}
              </Box>
            </TableCell>
            <TableCell>
              <Box component="span" className={classes.capitalize}>
                {data.accountNumber}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow className={classes.borderTop}>
            <TableCell width="200">
              <Box component="span" className={classes.label}>
                {t('totalAmount', 'Total amount')}
              </Box>
            </TableCell>
            <TableCell>
              <Box component="span" className={classes.capitalize}>
                {`${currencyFormat(totalAmount)} ${accCurrency}`}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow className={classes.borderTop}>
            <TableCell width="200">
              <Box component="span" className={classes.label}>
                {t('createdBy', 'Created by')}
              </Box>
            </TableCell>
            <TableCell>
              <Box component="span" className={classes.capitalize}>
                {`${data?.creator}`}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {isOpenAlert && (
        <Box mt={4}>
          <AlertTipItem
            value={t(
              'somethingWrongTextMsg',
              'Something went wrong while creating payments from this batch. Please create a new one.',
            )}
            iconComponent={<AlertIcon style={{ fill: 'red' }} />}
            type={'danger'}
          />
        </Box>
      )}

      {batchId && <BeneficiariesListBatch batchId={batchId} />}

      {/*<UploadDocument accountId={data.accId} trxId={''} isTransaction={true} />*/}

      <SignaturesTable signatures={data.signatures} signedCount={signedCount} />
    </>
  )
}
