import { useReactiveVar } from '@apollo/client'
import { ROW_PER_PAGE } from '../constants'
import { itemPerPage } from '../graphql/local'

export const useRowPerPage = () => {
  const itemsPerPage = useReactiveVar(itemPerPage)

  const setItemPerPage = (item: string | number | null) => {
    if (item) {
      itemPerPage(Number(item))
      localStorage.setItem(ROW_PER_PAGE, item.toString())
    }
  }

  const itemPerPageInit = () => {
    const storedItemsPerPage = Number(localStorage.getItem(ROW_PER_PAGE))
    if (storedItemsPerPage) {
      itemPerPage(Number(storedItemsPerPage))
    }
  }

  return { itemsPerPage, setItemPerPage, itemPerPageInit }
}
