import React, { FC, useCallback, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { Box, FormControl, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { CountriesUnknownList, uboLegalEntityTypesListFiltered } from '../../../utils/Data'
import {
  ContactSupportLink,
  FormAutocompleteSelect,
  FormControlledTextField,
  FormDatePickerField,
  Toggler,
} from '../../Common'
import ControlledIconTooltip from '../../Common/Tooltips/ControlledIconTooltip'
import { CountryAutocompleteSelect } from '../../Common/Selectors/CountryAutocompleteSelect'
import { useCommonStyles } from './helpers/functions'
import { makeStyles } from '@material-ui/styles'
import { useFormContext } from 'react-hook-form'
import { ContractProfileChangeAnswers } from '../../../graphql'
import { getLabelByKey } from '../../../utils'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'
import { ControlledCountryAutoSelect } from '../../Common/Selectors/ControlledCountryAutoSelect'

const useStyles = makeStyles((theme: Theme) => ({
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: 'rgb(245 245 245)',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
  dirtyControl: {
    position: 'relative',
  },
  moveDown: {
    transform: 'translate(0, 14px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  rightSpace: {
    '& .MuiInputBase-input': {
      [theme.breakpoints.down(386)]: {
        marginRight: theme.spacing(2),
      },
    },
  },
  icon: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      color: '#999999',
      '&:hover': {
        color: '#000000',
      },
    },
  },
  link: {
    '& a': {
      textDecoration: 'revert',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      '& svg': {
        display: 'none',
      },
    },
  },
}))

const CompanyInformationComponent: FC<{
  isFromBusinessDetails?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ isFromBusinessDetails = false, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useStyles(), ...useCommonStyles() }
  const methods = useFormContext()
  const { formState, control, setValue, watch } = methods

  const countryOfRegistration = watch('country')
  const countryOfTaxResidence = watch('countryOfTaxResidence')

  const [checked, setChecked] = useState(true)

  const handleCheck = () => {
    setChecked((prevState) => {
      if (prevState) {
        setValue('countryOfTaxResidence', '', { shouldDirty: true })
      } else {
        setValue('countryOfTaxResidence', countryOfRegistration, { shouldDirty: true })
      }
      return !prevState
    })
  }

  const handleCountryChange = (county: string) => {
    if (checked) {
      setValue('countryOfTaxResidence', county)
    }
  }

  const clearFields = useCallback(() => {
    const names = ['postalCode', 'city', 'streetAddress']
    names.forEach((field) => {
      setValue(field, '')
    })
  }, [setValue])

  useEffect(() => {
    setChecked(countryOfRegistration === countryOfTaxResidence)
  }, [countryOfRegistration, countryOfTaxResidence])

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        {t('companyInformation', 'Company Information')}
      </Typography>
      <FormControl
        className={clsx(
          classes.formControl,
          classes.formControlMultiSelect,
          !isFromBusinessDetails ? classes.rightSpace : '',
        )}
      >
        <FormAutocompleteSelect
          name="entityType"
          label={t('entityType', 'Entity Type')}
          readOnly={isFromBusinessDetails}
          data={uboLegalEntityTypesListFiltered}
          data-test="entityType"
          className={classes.whiteBackground}
          disablePortal
        />
        {!isFromBusinessDetails && (
          <Box className={classes.iconTooltip}>
            <ControlledIconTooltip
              Icon={HelpOutlineIcon}
              placement="top"
              title={
                <Box>
                  <Typography className={classes.titleTooltip} color="inherit">
                    <b>{t('informationAboutEntityTypes')}</b>
                  </Typography>
                  <Typography className={classes.titleTooltip} color="inherit">
                    <b> {t('domiciliaryHolding')}</b>
                  </Typography>
                  <Box component="div" className={classes.textTooltip}>
                    {t('domiciliaryHoldingDescription')}
                  </Box>
                  <Box component="div" className={classes.textTooltip}>
                    {t('domiciliaryHoldingDescription2')}
                  </Box>
                  <Typography className={classes.titleTooltip} color="inherit">
                    <b>{t('operatingNotQuoted')}</b>
                  </Typography>
                  <Box component="div" className={classes.textTooltip}>
                    {t('operatingNotQuotedDescription')}
                  </Box>
                  <Typography className={classes.titleTooltip} color="inherit">
                    <b>{t('operatingQuoted')}</b>
                  </Typography>
                  <Box component="div" className={classes.textTooltip}>
                    {t('operatingQuotedDescription')}
                  </Box>
                  <Typography className={classes.titleTooltip} color="inherit">
                    <b>{t('foundation')}</b>
                  </Typography>
                  <Box component="div" className={classes.textTooltip}>
                    {t('foundationDescription')}
                  </Box>
                  <Typography className={classes.titleTooltip} color="inherit">
                    <b>{t('trust')}</b>
                  </Typography>
                  <Box component="span" className={classes.textTooltip}>
                    {t('trustDescription')}
                  </Box>
                </Box>
              }
            />
          </Box>
        )}
      </FormControl>
      <Box>
        <FormControlledTextField
          label={t('legalName', 'Legal Name')}
          name="companyName"
          type="text"
          multiline
          fullWidth={true}
          required={false}
          data-test="companyName"
          className={classes.whiteBackground}
        />
        {changedContractProfileData?.companyName && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.companyName}
            title={changedContractProfileData.companyName}
          />
        )}
      </Box>
      <Box>
        <Box position="relative">
          <FormControlledTextField
            label={t('tradingNameDetails', 'Trading Name (optional)')}
            className={classes.whiteBackground}
            name="tradingName"
            type="text"
            fullWidth={true}
            multiline
            required={false}
            data-test="tradingName"
            helpText={t(
              'onlyNecessaryIfTradingNameIs Different',
              'Only necessary if the Trading Name is  different than the Legal Name',
            )}
          />
          <Box className={classes.icon}>
            <ControlledIconTooltip
              Icon={HelpOutlineIcon}
              placement="top"
              title={
                <Box component="div" className={classes.textTooltip}>
                  <Typography>
                    {t(
                      'tradingNameDescription',
                      "A trading name is the name under which your company conducts business and is known to your customers. It may differ from your company's registered legal name. The trading name is used for signage, marketing, and branding purposes, helping to establish your company's identity in the marketplace. Please ensure the trading name you provide aligns with how your business is recognized in your day-to-day operations.",
                    )}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>
        {changedContractProfileData?.tradingName && (
          <ControlledTooltipWrapped
            wrapperClass={clsx(classes.dirtyControl, classes.moveDown)}
            hidden={formState.errors.tradingName}
            title={changedContractProfileData.tradingName}
          />
        )}
      </Box>
      <FormControlledTextField
        className={clsx(classes.whiteBackground, isFromBusinessDetails ? classes.readOnly : '')}
        label={t('commercialRegistrationNumber', 'Commercial Registration Number')}
        name="registryNumber"
        type="text"
        fullWidth={true}
        required={false}
        readOnly={isFromBusinessDetails}
        inputProps={{
          readOnly: isFromBusinessDetails,
        }}
        data-test="commercialRegistrationNumber"
      />
      <FormDatePickerField
        name="registrationDate"
        label={t('dateOfRegistration', 'Date of Registration')}
        isShiftTimezone={false}
        noFuture
        readOnly={isFromBusinessDetails}
      />
      <Box>
        <FormControl className={`${classes.formControl}`}>
          <CountryAutocompleteSelect
            name="country"
            label={t('countryOfRegistration', 'Country of Registration')}
            data-test="countryOfRegistration"
            readOnly={isFromBusinessDetails}
            className={classes.whiteBackground}
            handleChange={handleCountryChange}
            filterByAny
            clearFields={clearFields}
          />
          {!isFromBusinessDetails && (
            <Box display="flex" flexWrap="wrap" gridGap={5}>
              <Typography>
                {t('ifYouDontSeeYourCountryHere, ', 'If you don’t see your country here, ')}
              </Typography>
              <Typography className={classes.link} component={'span'}>
                <ContactSupportLink newTab />
              </Typography>
            </Box>
          )}
        </FormControl>
      </Box>
      <Box>
        <Toggler
          name="checkCompanyTaxResidencyCountry"
          checked={checked}
          label={t('companyTaxResidencyCountryIsSame', 'Company tax residency country is the same')}
          onChange={handleCheck}
        />
        <Box hidden={checked}>
          <ControlledCountryAutoSelect
            name="countryOfTaxResidence"
            label={t('companyTaxResidencyCountry', 'Company tax residency country')}
            data-test="countryOfTaxResidence"
            control={control}
            className={classes.whiteBackground}
          />
        </Box>
        {changedContractProfileData?.preAssessment?.countryOfTaxResidence && (
          <ControlledTooltipWrapped
            wrapperClass={clsx(classes.dirtyControl, checked && classes.moveDown)}
            title={getLabelByKey(
              changedContractProfileData?.preAssessment?.countryOfTaxResidence,
              CountriesUnknownList,
            )}
          />
        )}
      </Box>
      <Box>
        <FormControlledTextField
          label={t('taxIdentificationNumberTIN', 'Tax identification number (TIN)')}
          name="taxId"
          type="text"
          fullWidth={true}
          required={false}
          data-test="taxId"
          className={classes.whiteBackground}
        />
        {changedContractProfileData?.taxId && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.taxId}
            title={changedContractProfileData.taxId}
          />
        )}
      </Box>
    </>
  )
}

export const CompanyInformation = React.memo(CompanyInformationComponent, isEqual)
