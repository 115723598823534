import { useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Collapse, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { useBeforeUnload } from 'react-use'

import InformationIcon from '../../../assets/images/icons/info_icon.svg?react'
import { AppActions, RegistrationSteps, statusActions, StepperTypes } from '../../../constants'
import {
  AddContractUboInputType,
  ContractStatusType,
  ContractType,
  Document,
  DocumentType,
  GetUbosListDocument,
  Individual,
  LegalEntity,
  Maybe,
  UboOwner,
  UboStakeType,
  UboType,
  useAddContractUboMutation,
  useAddUboOwnerMutation,
  useCreateOwnDocumentMutation,
  useCreateOwnDocumentTwoSidedMutation,
  useGetContractDetailsQuery,
  useGetUboDetailsLazyQuery,
  useGetUboLazyQuery,
  useGetUboOwnersDetailsLazyQuery,
  useGetUserDataQuery,
  useRemoveDocumentMutation,
  useUpdateContractUboMutation,
  useUpdateUboOwnerMutation,
} from '../../../graphql'
import {
  goBackPathVar,
  goNextPathVar,
  isModalDialogOpen,
  isUnsaveFormData,
  poiNeeded,
} from '../../../graphql/local'
import { useStepper } from '../../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { ManagingDirectorValidationSchema } from '../../../schemes'
import { registeredAddressEmptyProps } from '../../../stubs'
import {
  AuthPersonsSelectType,
  ControllingPersonsTypeEnum,
  CreateOwnDocMutationType,
  NoYes,
} from '../../../types'
import {
  createOwnDoc,
  extractDateValue,
  focusKeyPressNext,
  isEditingPoiNeeded,
  scrollToInputElement,
} from '../../../utils'
import { PoiDocTypesMandatoryTwoSided, YesNoOptions } from '../../../utils/Data'

import {
  AddressAutocomplete,
  AlertTipItem,
  ConfirmationModal,
  FormAutocompleteSelect,
  FormControlledTextField,
  FormDatePickerField,
  GridRow,
  Loader,
  LoadingButton,
  nationalitiesArray,
  UboRadioComboSelect,
} from '../../Common'
import { ProofOfIdentityControllingPerson } from './ProofOfIdentityControllingPerson'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(2, 0),
  },
}))

export const ManagingDirectorForm: FC<{
  companyName?: Maybe<string> | undefined
}> = ({ companyName }) => {
  const classes = useStyles()

  const [ownersList, setOwnersList] = useState<AuthPersonsSelectType[]>([])
  const [formMode, setFormMode] = useState('new')

  const { t } = useTranslation()
  const history = useHistory()
  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.uboId]: uboId,
    [PATH_PARAMS.ownedUboId]: ownedUboId,
  } = useParams() as Record<string, string>
  const location = useLocation()
  const newRecord = !!location.pathname.match('/new')
  const isEditingForm = !!location.pathname.match('/edit')
  const goBackPath = useReactiveVar(goBackPathVar)
  const goNextPath = useReactiveVar(goNextPathVar)
  const poiNeededVar = useReactiveVar(poiNeeded)

  const { data: userData } = useGetUserDataQuery()
  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const [GetUboDetailsQuery, { data: contractUboData }] = useGetUboDetailsLazyQuery({
    variables: { contractUboId: uboId },
    fetchPolicy: 'network-only',
  })
  const [GetUboOwnersDetailsQuery, { data: uboOwnersDetails }] = useGetUboOwnersDetailsLazyQuery({
    variables: {
      id: ownedUboId,
    },
  })
  const [GetOwnerQuery, { data: ownerData }] = useGetUboLazyQuery({
    variables: { id: ownedUboId },
  })
  const [GetUboQuery, { data: uboData }] = useGetUboLazyQuery({
    variables: { id: uboId },
  })
  const [addContractUBO] = useAddContractUboMutation()
  const [addUboOwnerMutation] = useAddUboOwnerMutation()
  const [updateContractUboMutation] = useUpdateContractUboMutation()
  const [updateUboOwnerMutation] = useUpdateUboOwnerMutation()
  const [createOwnDocument] = useCreateOwnDocumentTwoSidedMutation({})
  const [createOwnDocumentOneSide] = useCreateOwnDocumentMutation()
  const [removeDocument] = useRemoveDocumentMutation({})

  const { setHeaderText, setCurrentStep } = useStepper(
    contractData?.contract?.type === ContractType.IntroducerReference
      ? StepperTypes.partnerRegistration
      : StepperTypes.registration,
  )
  useEffect(() => {
    setCurrentStep(RegistrationSteps.controllingPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t(
            contractData?.contract?.type === ContractType.IntroducerReference
              ? 'companyPartnerApplication'
              : 'companyBusinessApplication',
            {
              companyName: contractOwner.companyName,
            },
          ),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [applicationId, contractData, contractLoading, history, setCurrentStep, setHeaderText, t])

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(ManagingDirectorValidationSchema),
  })

  const { watch, setValue, register, getValues, trigger, formState, reset } = methods
  const authorizedPerson = watch('authorizedPerson')
  const chosenPerson = watch('choosePerson') ?? 'new'
  const addAnother = watch('addAnotherPerson')
  const filesForUpload = watch(`filesForUpload`)
  const usReportablePerson = watch('usReportablePerson')

  useBeforeUnload(
    methods.formState.isDirty,
    t('youHaveUnsavedChangesAreYouSure', 'You have unsaved changes, are you sure?'),
  )

  const uboDocs = useMemo(() => {
    return ((contractUboData?.getContractUbo?.ubo?.entity as Individual)?.documents as Document[])
      ?.filter((doc) => PoiDocTypesMandatoryTwoSided.includes(doc?.type as DocumentType))
      .sort((a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0))[0]
  }, [contractUboData?.getContractUbo?.ubo?.entity as Individual])

  const uboOwnersDocs = useMemo(() => {
    return (((uboOwnersDetails?.getUboOwners?.find((data) => data?.id === uboId) as UboOwner)?.ubo
      ?.entity as Individual)?.documents as Document[])
      ?.filter((doc) => PoiDocTypesMandatoryTwoSided.includes(doc?.type as DocumentType))
      .sort((a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0))[0]
  }, [uboOwnersDetails?.getUboOwners])

  const onSubmit = useCallback(
    async (formData) => {
      const preparedInput: AddContractUboInputType = {
        ubo: {
          uboType: UboType.NaturalPerson,
          ownershipType: UboStakeType.ManagingDirector,
          shareSize: 0,
          naturalPerson: {
            ...(authorizedPerson ? { id: authorizedPerson } : {}),
            firstName: formData.firstName,
            lastName: formData.lastName,
            birthday: extractDateValue(formData.birthday),
            nationality: formData.nationality,
            address: {
              line1: formData.streetAddress,
              city: formData.city,
              zip: formData.postalCode,
              country: formData.country,
              additionalDetails: formData.additionalDetailsOptional,
            },
            taxId: formData.taxId,
            isPep: formData.politicallyExposedPerson === 'Yes',
            usReportablePerson: formData.usReportablePerson === 'Yes',
          },
        },
      }

      if (uboId) {
        await addUboOwnerMutation({
          variables: {
            contractId: applicationId,
            uboId,
            ubo: preparedInput.ubo,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        }).then((addUboOwnerData) => {
          createOwnDoc(
            uboId,
            ownedUboId,
            CreateOwnDocMutationType.addUboOwner,
            formData,
            createOwnDocument,
            createOwnDocumentOneSide,
            +applicationId,
            addUboOwnerData?.data?.addUboOwner?.ubo?.entity as Individual,
            removeDocument,
          )
        })
      } else {
        await addContractUBO({
          variables: {
            id: applicationId,
            input: preparedInput,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        }).then((addContractUBOData) => {
          createOwnDoc(
            uboId,
            ownedUboId,
            CreateOwnDocMutationType.addContractUbo,
            formData,
            createOwnDocument,
            createOwnDocumentOneSide,
            +applicationId,
            addContractUBOData?.data?.addContractUbo?.ubo?.entity as Individual,
            removeDocument,
          )
        })
      }
      isUnsaveFormData(false)
      if (newRecord && !uboId) {
        history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
      } else if (addAnother) {
        history.push(
          generatePath(APP_PATHS.application.beneficialOwners.add, {
            applicationId,
            [PATH_PARAMS.controllingPerson]: ControllingPersonsTypeEnum.personOwner,
          }),
        )
      } else if (goNextPath) {
        history.push(goNextPath)
      } else {
        goBackPathVar('')
        history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
      }
    },
    [
      addAnother,
      addContractUBO,
      addUboOwnerMutation,
      applicationId,
      authorizedPerson,
      createOwnDocument,
      createOwnDocumentOneSide,
      goNextPath,
      history,
      newRecord,
      ownedUboId,
      removeDocument,
      uboId,
    ],
  )

  const handleGoBack = useCallback(() => {
    if (goBackPath) {
      history.push(`${goBackPath}?fromDirectorForm=yes`)
      goBackPathVar('')
      goNextPathVar(location.pathname)
    } else {
      history.goBack()
    }
  }, [methods.formState.isDirty, goBackPath, history])

  const handleBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()

      if (methods.formState.isDirty) {
        isModalDialogOpen(true)
        return
      }
      handleGoBack()
    },
    [methods.formState.isDirty, handleGoBack],
  )

  const onEditSubmit = useCallback(
    async (formData) => {
      const id = uboId
      const preparedInput: AddContractUboInputType = {
        ubo: {
          uboType: UboType.NaturalPerson,
          ownershipType: UboStakeType.ManagingDirector,
          shareSize: 0,
          naturalPerson: {
            ...(authorizedPerson ? { id: authorizedPerson } : {}),
            firstName: formData.firstName,
            lastName: formData.lastName,
            birthday: extractDateValue(formData.birthday),
            isPep: formData.politicallyExposedPerson === 'Yes',
            usReportablePerson: formData.usReportablePerson === 'Yes',
            taxId: formData.taxId,
            nationality: formData.nationality,
            address: {
              line1: formData.streetAddress,
              city: formData.city,
              zip: formData.postalCode,
              country: formData.country,
              additionalDetails: formData.additionalDetailsOptional,
            },
          },
        },
      }

      if (uboId && ownedUboId) {
        await updateUboOwnerMutation({
          variables: {
            contractId: applicationId,
            ownedUboId: ownedUboId,
            uboOwnerId: uboId,
            ubo: preparedInput.ubo,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        }).then((updateUboOwnerData) => {
          createOwnDoc(
            uboId,
            ownedUboId,
            CreateOwnDocMutationType.updateUboOwner,
            formData,
            createOwnDocument,
            createOwnDocumentOneSide,
            +applicationId,
            updateUboOwnerData?.data?.updateUboOwner?.ubo?.entity as Individual,
            removeDocument,
          )
        })
      } else {
        await updateContractUboMutation({
          variables: {
            contractUboId: id,
            input: preparedInput,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        }).then((updateContractUboData) => {
          createOwnDoc(
            uboId,
            ownedUboId,
            CreateOwnDocMutationType.updateContractUbo,
            formData,
            createOwnDocument,
            createOwnDocumentOneSide,
            +applicationId,
            updateContractUboData?.data?.updateContractUbo?.ubo?.entity as Individual,
            removeDocument,
          )
        })
      }
      isUnsaveFormData(false)
      if (goNextPath) {
        history.push(goNextPath)
      } else {
        goBackPathVar('')
        history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
      }
    },
    [
      applicationId,
      authorizedPerson,
      createOwnDocument,
      createOwnDocumentOneSide,
      goNextPath,
      history,
      ownedUboId,
      removeDocument,
      uboId,
      updateContractUboMutation,
      updateUboOwnerMutation,
    ],
  )

  const renderTip = useMemo(() => {
    const body = (
      <GridRow>
        <AlertTipItem
          value={t(
            'NeedPersonsProofIdentity',
            'You will need to add this person’s Proof of identity. Make sure you have the necessary documents: Passport, ID or Driver’s license.',
          )}
          iconComponent={<InformationIcon />}
          type={'primary'}
        />
      </GridRow>
    )
    if (poiNeededVar) {
      return body
    }
  }, [poiNeededVar, t])

  useEffect(() => {
    register(`filesForUpload`)
    if (getValues(`filesForUpload`) === undefined) {
      setValue(`filesForUpload`, [])
    }
    register(`documentAlreadyUploaded`)
  }, [getValues, register, setValue])

  useEffect(() => {
    isEditingPoiNeeded(
      authorizedPerson,
      ownersList as AuthPersonsSelectType[],
      filesForUpload,
      chosenPerson,
      trigger,
    )
  }, [authorizedPerson, filesForUpload, ownersList, chosenPerson, trigger, trigger])

  // mounts data when edit existing owner
  useEffect(() => {
    if (!contractUboData && uboId && !ownedUboId && !newRecord) {
      ;(async () => {
        await GetUboDetailsQuery()
      })()
    }

    if (contractUboData?.getContractUbo && !newRecord) {
      const { ubo } = contractUboData.getContractUbo
      const individualUbo = ubo?.entity as Individual

      setValue('firstName', individualUbo.firstName)
      setValue('lastName', individualUbo.lastName)
      setValue('country', individualUbo.address?.country)
      // setValue('dateOfBirth', individualUbo.birthday)
      setValue('birthday', individualUbo.birthday)
      setValue('streetAddress', individualUbo.address?.line1)
      setValue('additionalDetailsOptional', individualUbo.address?.additionalDetails)
      setValue('postalCode', individualUbo.address?.zip)
      setValue('city', individualUbo.address?.city)
      setValue('nationality', individualUbo.nationality)
      setValue(`documentAlreadyUploaded`, uboDocs)
      setValue(`documentType`, uboDocs?.type)
      setValue(
        'politicallyExposedPerson',
        individualUbo?.isPep ? YesNoOptions[0].key : YesNoOptions[1].key,
      )
      setValue(
        'usReportablePerson',
        individualUbo?.usReportablePerson ? YesNoOptions[0].key : YesNoOptions[1].key,
      )
      setValue('taxId', individualUbo?.taxId)
    }
  }, [
    GetUboDetailsQuery,
    contractUboData,
    newRecord,
    ownedUboId,
    setValue,
    uboId,
    PoiDocTypesMandatoryTwoSided,
    formState.isDirty,
  ])

  // mounts data when edit existing contract Ubo Owner
  useEffect(() => {
    if (!uboOwnersDetails && uboId && ownedUboId) {
      ;(async () => {
        await GetUboOwnersDetailsQuery({
          fetchPolicy: 'network-only',
        })
      })()
    }

    if (uboOwnersDetails?.getUboOwners) {
      const uboData = uboOwnersDetails?.getUboOwners.find((data) => data?.id === uboId)
      const individualUbo = uboData?.ubo?.entity as Individual

      if (!individualUbo) return

      setValue('firstName', individualUbo.firstName)
      setValue('lastName', individualUbo.lastName)
      setValue('country', individualUbo.address?.country)
      // setValue('dateOfBirth', individualUbo.birthday)
      setValue('birthday', individualUbo.birthday)
      setValue('streetAddress', individualUbo.address?.line1)
      setValue('additionalDetailsOptional', individualUbo.address?.additionalDetails)
      setValue('postalCode', individualUbo.address?.zip)
      setValue('city', individualUbo.address?.city)
      setValue('nationality', individualUbo.nationality)
      setValue(`documentAlreadyUploaded`, uboOwnersDocs)
      setValue(`documentType`, uboOwnersDocs?.type)
      setValue('taxId', individualUbo?.taxId)
    }
  }, [
    GetUboOwnersDetailsQuery,
    ownedUboId,
    setValue,
    uboId,
    uboOwnersDetails,
    PoiDocTypesMandatoryTwoSided,
    formState.isDirty,
  ])

  useEffect(() => {
    if (uboOwnersDetails?.getUboOwners && isEditingForm) {
      const uboData = uboOwnersDetails?.getUboOwners.find((data) => data?.id === uboId)
      const individualUbo = uboData?.ubo?.entity as Individual

      if (!individualUbo) return
      const filesForUpload = []

      if (uboOwnersDocs && uboOwnersDocs?.firstSide) {
        filesForUpload.push({
          id: uboOwnersDocs.id,
          fileName: uboOwnersDocs.firstSide.fileName,
          size: uboOwnersDocs.firstSide.size,
          docType: uboOwnersDocs.type,
          plainUrl: uboOwnersDocs.firstSide.url,
          metaType: uboOwnersDocs.firstSide.mimeType,
          isAlreadyUploaded: true,
          isRemoved: false,
          isFirstSide: true,
          expiration: uboOwnersDocs.expiration,
        })
      }

      if (uboOwnersDocs && uboOwnersDocs?.secondSide) {
        filesForUpload.push({
          id: uboOwnersDocs.id,
          fileName: uboOwnersDocs.secondSide.fileName,
          size: uboOwnersDocs.secondSide.size,
          docType: uboOwnersDocs.type,
          plainUrl: uboOwnersDocs.secondSide.url,
          metaType: uboOwnersDocs.secondSide.mimeType,
          isAlreadyUploaded: true,
          isRemoved: false,
          isFirstSide: false,
          expiration: uboOwnersDocs.expiration,
        })
      }

      reset({
        firstName: individualUbo.firstName,
        lastName: individualUbo.lastName,
        country: individualUbo.address?.country,
        birthday: individualUbo.birthday,
        streetAddress: individualUbo.address?.line1,
        additionalDetailsOptional: individualUbo.address?.additionalDetails,
        postalCode: individualUbo.address?.zip,
        city: individualUbo.address?.city,
        politicallyExposedPerson: individualUbo?.isPep ? NoYes.yes : NoYes.no,
        usReportablePerson: individualUbo?.usReportablePerson ? NoYes.yes : NoYes.no,
        taxId: individualUbo?.taxId,
        nationality: individualUbo.nationality,
        documentType: uboOwnersDocs?.type,
        documentAlreadyUploaded: uboOwnersDocs,
        filesForUpload,
      })
    }
  }, [uboOwnersDetails, uboId, uboOwnersDocs, isEditingForm])

  useEffect(() => {
    if (ownedUboId) {
      ;(async () => {
        await GetOwnerQuery()
      })()
    }
  }, [GetOwnerQuery, ownedUboId])

  useEffect(() => {
    if (uboId && newRecord) {
      ;(async () => {
        await GetUboQuery()
      })()
    }
  }, [GetUboQuery, newRecord, uboId])

  useEffect(() => {
    if (methods.formState.isDirty) {
      isUnsaveFormData(true)
    }
  }, [methods.formState.isDirty])

  useEffect(() => {
    poiNeeded(true)
  }, [])

  useEffect(() => {
    if (formMode === 'myself' && userData?.viewer) {
      const {
        firstName,
        lastName,
        address,
        birthday,
        nationality,
        taxId,
      } = userData?.viewer as Individual

      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('country', address?.country)
      setValue('birthday', birthday)
      setValue('streetAddress', address?.line1)
      setValue(
        'additionalDetailsOptional',
        address?.additionalDetails ? address?.additionalDetails : '',
      )
      setValue('postalCode', address?.zip)
      setValue('city', address?.city)
      setValue('nationality', nationality)
      setValue('taxId', taxId)
    }
  }, [formMode, setValue, userData?.viewer, formState.isDirty])

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  if (!contractData || !userData) return <Loader />

  return (
    <>
      <ConfirmationModal isGoBack={false} handleHelpReset={handleGoBack} />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(!newRecord ? onEditSubmit : onSubmit)}
          id="forNextFocus"
          onKeyDown={focusKeyPressNext}
        >
          <Box data-test="directorForm">
            <Box className={'titleWrap'}>
              <Typography variant={'h2'} className={'title'}>
                {newRecord ? (
                  <>
                    {!uboId && t(`managingDirectorOfCompany`, { name: companyName })}

                    {uboId &&
                      !isEmpty(uboData?.getUbo) &&
                      t(`managingDirectorOfCompany`, {
                        name: (uboData?.getUbo?.entity as LegalEntity).companyName,
                      })}
                  </>
                ) : (
                  <>
                    {!ownedUboId && t(`managingDirectorOfCompany`, { name: companyName })}

                    {ownedUboId &&
                      !isEmpty(ownerData?.getUbo) &&
                      t(`managingDirectorOfCompany`, {
                        name: (ownerData?.getUbo?.entity as LegalEntity).companyName,
                      })}
                  </>
                )}
              </Typography>
            </Box>

            <Box hidden={!newRecord}>
              <UboRadioComboSelect
                ownersList={ownersList}
                setOwnersList={setOwnersList}
                formMode={formMode}
                setFormMode={setFormMode}
                user={userData?.viewer as Individual}
              />
            </Box>

            {renderTip}

            <GridRow>
              <FormControlledTextField
                label={t('firstNames', 'First Name(s)')}
                name="firstName"
                type="text"
                fullWidth
                required={false}
                data-test="firstName"
              />
            </GridRow>

            <GridRow>
              <FormControlledTextField
                label={t('lastName', 'Last Name')}
                name="lastName"
                type="text"
                fullWidth
                required={false}
                data-test="lastName"
              />
            </GridRow>

            <GridRow>
              <FormDatePickerField name="birthday" label={t('dateOfBirth', 'Date of Birth')} />
            </GridRow>

            <GridRow>
              <FormAutocompleteSelect
                name="nationality"
                label={t('nationality', 'Nationality')}
                data={nationalitiesArray}
                data-test="nationality"
              />
            </GridRow>

            <AddressAutocomplete
              customCountryLabel={t('countryOfResidence', 'Country of Residence')}
              props={registeredAddressEmptyProps}
            />

            <GridRow>
              <FormAutocompleteSelect
                className={classes.formField}
                name="usReportablePerson"
                label={t('USReportablePerson', 'US reportable person')}
                data={YesNoOptions}
              />
            </GridRow>

            <Collapse in={usReportablePerson === YesNoOptions[0].key}>
              <GridRow>
                <FormControlledTextField
                  label={t('tinTaxID', 'TIN/Tax ID')}
                  name="taxId"
                  type="text"
                  fullWidth
                  multiline
                />
              </GridRow>
            </Collapse>

            <GridRow>
              <FormAutocompleteSelect
                className={classes.formField}
                name="politicallyExposedPerson"
                label={t('politicallyExposedPerson', 'Politically exposed person')}
                data={YesNoOptions}
              />
            </GridRow>

            <GridRow>
              <ProofOfIdentityControllingPerson
                infoLabel={t(
                  'ownerIdentityLabelManaging',
                  'Please upload a copy of a document to prove the Managing director’s identity.',
                )}
                nameLabel={t('proofOfIdentity', 'Proof of identity')}
              />
            </GridRow>

            <Box mt={'40px'} className={'buttonsBox'}>
              <Box className={'secondaryButton'}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={handleBack}
                >
                  {t('back', 'Back')}
                </Button>
              </Box>

              <LoadingButton
                className={'button'}
                // loading={mutationLoading}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                data-test="submitAndProceed"
              >
                {t('submitAndProceed', 'Submit and proceed')}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  )
}
