import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  intersection,
  isEmpty,
  isNumber,
  isString,
  isObjectLike,
  isNull,
  set,
  isNil,
  isBoolean,
} from 'lodash'
import ReactDOM from 'react-dom'
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CONTRACT_MANAGEMENT_ALERT_TIMEOUT } from '../../../../constants/alerts'
import {
  BankableEntityContractInputType,
  GetContractDetailsDocument,
  GetUserContractsDocument,
  useCreateContractMutation,
  useUpdateContractDetailsMutation,
} from '../../../../graphql'
import { goBackPathVar, isUnsaveFormData as isUnsaveFormDataVar } from '../../../../graphql/local'
import { APP_PATHS, PATH_PARAMS } from '../../../../routes/paths'
import { PreAssessmentOnboardingInput } from '../../../../schemes'
import { CardPaymentsSelectOptions } from '../../../../types'
import { parsePreassessementValues, reportAppError } from '../../../../utils'
import { AlertTipItem, ButtonWithTooltip, Loader } from '../../../Common'
import { CompanyProfileStepPoint } from './CompanyProfileStepPoint'
import { EconomicProfilePoint } from './EconomicProfilePoint'
import { PaymentProcessingPoint } from './PaymentProcessingPoint'
import { ProductInfoStepPoint } from './ProductInfoStepPoint'
import { RegulatoryInformationPoint } from './RegulatoryInformationPoint'
import SuccessIcon from '../../../../assets/images/icons/confirmed.svg?react'

const useStyles = (isLimited: boolean) =>
  makeStyles((theme) => ({
    root: {
      height: 'fit-content',
      width: theme.spacing(26),
      minWidth: theme.spacing(23),
      position: 'sticky',
      top: isLimited ? theme.spacing(7) : 0,
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        zIndex: 9,
        borderTop: '1px solid rgba(0,0,0,.1)',
        transform: 'translate(0, 16px)',
        backgroundColor: theme.palette.background.paper,
        bottom: 0,
        top: 'unset',
        marginTop: 0,
        width: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
      },
    },
    link: {
      '& p': {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
          color: '#000000',
        },
      },
    },
    vLine: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      '&::before': {
        position: 'absolute',
        content: '""',
        height: '100%',
        left: '10px',
        borderLeft: `2px dashed ${theme.palette.secondary.main}`,
      },
      [theme.breakpoints.down('xs')]: {
        height: theme.spacing(6),
        flexDirection: 'row',
        alignItems: 'center',
        gap: 38,
        '&::before': {
          content: 'none',
        },
        '& > *:not(:last-child)': {
          marginRight: '10px',
          position: 'relative',
          '&::after': {
            content: '"· · ·"',
            color: '#CCCCCC',
            fontSize: '1rem',
            position: 'absolute',
            top: 0,
            right: '-35px',
          },
        },
      },
    },
    saveButton: {
      [theme.breakpoints.down('xs')]: {
        minWidth: theme.spacing(15),
        zIndex: theme.zIndex.drawer + 105,
        '& .MuiButtonBase-root': {
          padding: theme.spacing(1, 1.5),
        },
        '& .MuiButton-label': {
          fontWeight: 'bold',
        },
      },
    },
    alertContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '580px',
      position: 'fixed',
      top: theme.spacing(1),
      zIndex: theme.zIndex.drawer + 1,
      left: 0,
      right: 0,
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        top: theme.spacing(8),
      },
      '&>div': {
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
      },
    },
  }))()

export const BusinessDetailsStepper: FC<{
  cardPaymentsApmRequired: boolean
  formValues: PreAssessmentOnboardingInput
  errors: Record<string, Record<string, string>>
  correspondenceNeeded: boolean
  isIntroducer?: boolean
  isLimited?: boolean
}> = ({
  cardPaymentsApmRequired,
  formValues,
  errors,
  correspondenceNeeded,
  isIntroducer,
  isLimited = false,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(isLimited)
  const history = useHistory()
  const initialAlertState =
    history.location.state && (history.location.state as { showAlert: boolean }).showAlert

  const [showSuccessSavedAlert, setShowSuccessSavedAlert] = useState(Boolean(initialAlertState))

  const containerEll = document.getElementById('stepperId')
  const headerLogoSectionEl = document.getElementById('headerLogoSectionId')

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const [createContractMutation, { loading: mutationCreateLoading }] = useCreateContractMutation()
  const [
    updateContractDetailsMutation,
    { loading: mutationUpdateLoading },
  ] = useUpdateContractDetailsMutation()

  const goBackPath = goBackPathVar()
  const isSaveButtonHidden = (goBackPath && goBackPath !== APP_PATHS.root) || isLimited
  const isLoading = mutationCreateLoading || mutationUpdateLoading
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const filledFields = Object.keys(formValues).filter((field) => {
    const value = formValues[field]

    if (isNil(value)) return false
    if (field === 'cardPaymentsApm') return false
    if (field === 'partnersIncoming' || field === 'partnersOutgoing') return !isEmpty(value)

    if (isString(value)) {
      return !!(value as string).trim()
    } else if (isNumber(value)) {
      return value
    } else if (isBoolean(value)) {
      return value
    } else if (Array.isArray(value)) {
      if (isEmpty(value)) return false

      return (value as Array<string | Record<string, string | number>>).every(
        (item: string | Record<string, string | number>) => {
          if (typeof item === 'object') {
            return Object.values(item).every((val) => !!val)
          } else if (isString(item)) {
            return !!item.trim()
          }
          return false
        },
      )
    }

    return false
  })

  const emptyFieldsErrors = [
    'This field is required',
    'City is required',
    'Email is required',
    'Providing controlling persons countries of residence is mandatory',
    'Sum of percents should be more than 0',
    'Street Address is required',
  ]

  const errorsList = Object.keys(errors)

  const nonEmptyFieldErrorList = useMemo(
    () =>
      errorsList.reduce((acc: string[], error) => {
        if (!emptyFieldsErrors.includes(errors[error].message)) {
          acc.push(error)
        }

        return acc
      }, []),
    [errorsList],
  )

  const isSaveProgressDisabled = useMemo(
    () =>
      !formValues.companyName ||
      !formValues.entityType ||
      !formValues.registrationDate ||
      !formValues.registryNumber ||
      !!intersection(
        ['companyName', 'entityType', 'registrationDate', 'registryNumber'],
        errorsList,
      ).length,
    [
      errorsList,
      formValues.entityType,
      formValues.registrationDate,
      formValues.registryNumber,
      formValues.companyName,
    ],
  )

  const getOnlyFilled = (values: BankableEntityContractInputType): unknown => {
    if (isString(values)) {
      const trimmedValue = values.trim()
      return trimmedValue && trimmedValue !== 'undefined' ? trimmedValue : null
    }

    if (isNumber(values)) {
      return values
    }

    if (isBoolean(values)) {
      return values
    }

    if (Array.isArray(values)) {
      const filteredArray = values
        .map(getOnlyFilled)
        .filter(
          (v) => !!v && !(isString(v) && (!v.trim() || v.trim() === 'undefined')) && !isEmpty(v),
        )
      return filteredArray.length > 0 ? filteredArray : null
    }

    if (isObjectLike(values)) {
      const filteredObject: BankableEntityContractInputType = {}

      for (const [key, value] of Object.entries(values)) {
        const filteredValue = getOnlyFilled(value)

        if (
          !isNull(filteredValue) &&
          (isNumber(filteredValue) ||
            (isBoolean(filteredValue) && filteredValue) ||
            !isEmpty(filteredValue)) &&
          (!isString(filteredValue) ||
            (filteredValue.trim() && filteredValue.trim() !== 'undefined'))
        ) {
          filteredObject[key as keyof typeof filteredObject] = filteredValue
        }
      }

      return !isEmpty(filteredObject) ? filteredObject : null
    }

    return null
  }

  const handleSaveProgress = async () => {
    try {
      const parsedFormValues = parsePreassessementValues(
        formValues,
        correspondenceNeeded,
        isIntroducer,
      )
      const onlyFiledValues = getOnlyFilled(parsedFormValues) as BankableEntityContractInputType
      set(
        onlyFiledValues,
        'preAssessment.cardPayment.isCardPayment',
        formValues.cardPaymentsApm === CardPaymentsSelectOptions.required,
      )

      if (applicationId) {
        await updateContractDetailsMutation({
          variables: { id: applicationId, updates: parsedFormValues },
          refetchQueries: [
            {
              query: GetContractDetailsDocument,
              variables: { id: +applicationId },
            },
            {
              query: GetUserContractsDocument,
            },
          ],
          awaitRefetchQueries: true,
        })
        isUnsaveFormDataVar(false)
      } else {
        const { data } = await createContractMutation({
          variables: { input: onlyFiledValues, version: 2 },
          refetchQueries: [
            {
              query: GetUserContractsDocument,
            },
          ],
          awaitRefetchQueries: true,
        })

        isUnsaveFormDataVar(false)
        const id = data?.createContract?.id
        if (id) {
          history.push(generatePath(APP_PATHS.application.edit, { applicationId: id }), {
            showAlert: true,
          })
        }
      }
      setShowSuccessSavedAlert(true)
    } catch (e) {
      reportAppError(e)
      toast.error(e.message)
    }
  }

  const scrollToParagraph = (paragraphId: string) => {
    const element = document.getElementById(paragraphId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      showSuccessSavedAlert && setShowSuccessSavedAlert(false)
    }, CONTRACT_MANAGEMENT_ALERT_TIMEOUT)
    return () => clearTimeout(timer)
  }, [showSuccessSavedAlert])

  return (
    <Box className={classes.root} id="stepperId">
      <Box className={classes.vLine}>
        <Box className={classes.link} onClick={() => scrollToParagraph('companyProfileId')}>
          <CompanyProfileStepPoint
            filledFields={filledFields}
            ErrorList={isLimited ? errorsList : nonEmptyFieldErrorList}
            correspondenceRequired={correspondenceNeeded}
            isLimited={isLimited}
          />
        </Box>

        <Box className={classes.link} onClick={() => scrollToParagraph('regulatoryInformationId')}>
          <RegulatoryInformationPoint
            filledFields={filledFields}
            ErrorList={isLimited ? errorsList : nonEmptyFieldErrorList}
            isBusinessRelationshipWithCompany={formValues.businessRelationshipWithCompany === 'Yes'}
            businessSector={formValues.businessSector as string}
            isLicenseRequired={formValues.licenseRequired}
            isLimited={isLimited}
          />
        </Box>

        <Box className={classes.link} onClick={() => scrollToParagraph('economicProfileId')}>
          <EconomicProfilePoint
            filledFields={filledFields}
            ErrorList={isLimited ? errorsList : nonEmptyFieldErrorList}
          />
        </Box>
        <Box className={classes.link} onClick={() => scrollToParagraph('productInformationId')}>
          <ProductInfoStepPoint filledFields={filledFields} errorsList={errorsList} />
        </Box>

        {cardPaymentsApmRequired && (
          <Box className={classes.link} onClick={() => scrollToParagraph('paymentProcessingId')}>
            <PaymentProcessingPoint
              filledFields={filledFields}
              ErrorList={isLimited ? errorsList : nonEmptyFieldErrorList}
              formValues={formValues}
            />
          </Box>
        )}
      </Box>
      {containerEll &&
        ReactDOM.createPortal(
          <Box hidden={isSaveButtonHidden} className={classes.saveButton}>
            <ButtonWithTooltip
              title={t(
                'requiredFieldsForSaveProgress',
                'Legal name, Entity Type, Commercial Registration Number and  Date of Registration are mandatory fields for saving the progress',
              )}
              hiddeTooltip={!isSaveProgressDisabled}
              disabled={isSaveProgressDisabled || isLoading || !isEmpty(nonEmptyFieldErrorList)}
              onClick={handleSaveProgress}
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
              size={isSmallScreen ? 'small' : 'medium'}
            >
              {isLoading ? <Loader size={24} /> : t('saveProgress', 'Save progress')}
            </ButtonWithTooltip>
          </Box>,
          isSmallScreen ? headerLogoSectionEl || document.body : containerEll,
        )}

      {ReactDOM.createPortal(
        <Box className={classes.alertContainer}>
          <AlertTipItem
            value={t(
              'progressWithBusinessAppSavedSuccessfully',
              'Progress with the business application saved successfully!',
            )}
            iconComponent={<SuccessIcon />}
            type="success"
            isOpenAlert={showSuccessSavedAlert}
            isCloseButton={true}
            setIsOpenAlert={setShowSuccessSavedAlert}
          />
        </Box>,
        document.body,
      )}
    </Box>
  )
}
