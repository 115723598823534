import * as yup from 'yup'
import {
  COMPANY_NAME_REGEX,
  EMAIL_REGEXP,
  INCORRECT_EMAIL_FORMAT_MESSAGE,
  NUMBER_AND_LATINICA_AND_SYMBOLS,
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import i18n from '../../i18n'

export const ContractPersonInputSchema = yup.object().shape({
  fullName: yup
    .string()
    .nullable()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(COMPANY_NAME_REGEX, NUMBER_AND_LATINICA_AND_SYMBOLS)
    .test('fullNameMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    }),
  email: yup
    .string()
    .nullable()
    .required(i18n.t('emailIsRequired', 'Email is required'))
    .test('emailMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .matches(EMAIL_REGEXP, INCORRECT_EMAIL_FORMAT_MESSAGE),
  contactPersonPhoneNumber: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .nullable()
    .test('contactPersonPhoneNumberMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    }),
})
