import React, { FC, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractProfileChangeAnswers, Products } from '../../../graphql'
import { CardPaymentsSelectOptions } from '../../../types'
import { getLabelsSeparatedByCommas } from '../../../utils'
import { productsListValues } from '../../../utils/Data'
import { FormAutocompleteMultiSelect } from '../../Common'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'
import { useCommonStyles } from './helpers/functions'

const useStyles = makeStyles((theme) => ({
  dirtyControl: {
    position: 'relative',
  },
  dirtySpaceTop: {
    '& .MuiFormHelperText-root': {
      top: -3,
    },
  },
  whiteBackground: {
    backgroundColor: theme.palette.background.paper,
  },
}))

export const ProductInformation: FC<{
  readOnly?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ readOnly = false, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const { formState, register, watch, setValue } = useFormContext()

  const products = watch('products')
  const cardPaymentsApmRequired = products?.includes(Products.PaymentProcessing)

  useEffect(() => {
    register('cardPaymentsApm')
  }, [register])

  useEffect(() => {
    setValue(
      'cardPaymentsApm',
      cardPaymentsApmRequired
        ? CardPaymentsSelectOptions.required
        : CardPaymentsSelectOptions.notRequired,
    )
  }, [cardPaymentsApmRequired])

  return (
    <Box>
      <FormAutocompleteMultiSelect
        className={clsx(classes.boxMultiSelect, classes.whiteBackground)}
        name="products"
        label={t('productsRequested', 'Products Requested')}
        data={productsListValues}
        readOnly={readOnly}
        data-test="products"
      />
      {changedContractProfileData?.economicProfile?.products && (
        <ControlledTooltipWrapped
          wrapperClass={clsx(classes.dirtyControl, classes.dirtySpaceTop)}
          hidden={formState.errors.products}
          title={getLabelsSeparatedByCommas(
            changedContractProfileData.economicProfile.products,
            productsListValues,
          )}
          arrowHorizontalPosition={'left'}
        />
      )}
    </Box>
  )
}
