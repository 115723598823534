import React, { FC, useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { ButtonWithTooltip } from '../../Common'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { ContactSupportLink } from '../../Common'
import config from '../../../config'
import Unchecked from '../../../assets/images/icons/unchecked_icon.svg?react'
import Checked from '../../../assets/images/icons/cheked_icon.svg?react'

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    lineHeight: '1.5rem',
    display: 'inline-block',
    alignItems: 'center',
    '& a': {
      textDecoration: 'revert',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      '& svg': {
        display: 'none',
      },
    },
  },
  btn: {
    width: '100%',
  },
  mb: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  checkbox: {
    marginLeft: -5,
  },
}))

type ControlProps = {
  initCalendly: (() => void) | undefined
  handleNext: (() => void) | undefined
  isDisabled: boolean
  text: string | undefined
}

export const ControlVideoVerification: FC<ControlProps> = ({
  initCalendly,
  isDisabled,
  text,
  handleNext,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [checked, setChecked] = useState(false)

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Typography variant={'body1'} className={`${classes.text} ${classes.mb}`}>
            {t(
              'videoVerificationWillTakePlace,',
              'Please click the button below to proceed. If you are unable to participate in a video verification and require an alternative verification method, please ',
            )}
            <ContactSupportLink pointInline={true} />
          </Typography>
        </Grid>
        {!isDisabled && (
          <Grid item xs={12}>
            <FormControlLabel
              className={`${classes.checkbox} ${classes.mb}`}
              control={
                <Checkbox
                  checked={checked}
                  icon={<Unchecked />}
                  checkedIcon={<Checked />}
                  onClick={() => setChecked(!checked)}
                />
              }
              label={t(
                'videoConsent',
                'By proceeding to the video verification, you consent to {{val1}} recording the audio and video of the video verification session.',
                {
                  val1: config.brand,
                },
              )}
              labelPlacement="end"
            />
          </Grid>
        )}
      </Grid>
      <Grid container alignItems="center" spacing={3} className={classes.mb}>
        <Grid item lg={3} sm={5} xs={12}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className={classes.btn}
            onClick={initCalendly}
            // disabled={isDisabled}
          >
            {t('bookTime', 'Book time')}
          </Button>
        </Grid>
        <Grid item lg={3} sm={5} xs={12}>
          <ButtonWithTooltip
            onClick={handleNext}
            disabledOperationName="Video verifications"
            disabled={isDisabled || !checked}
            variant="contained"
            color="default"
            hiddeTooltip={checked}
            title={
              !isDisabled
                ? t(
                    'consentRequired',
                    'Your consent is required to proceed to the video verification, check the tick box above to consent',
                  )
                : text
                ? t(text)
                : t('appointmentMissed', 'Appointment missed!')
            }
            fullWidth
          >
            {t('goToVerification', 'Go to verification')}
          </ButtonWithTooltip>
        </Grid>
      </Grid>
    </>
  )
}
