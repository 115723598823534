import React, { createContext, useCallback, useEffect, useState } from 'react'
import { Chain, useAccount, useNetwork, useDisconnect } from 'wagmi'
import {
  GetAccountsDocument,
  OrderBy,
  OrderDirection,
  useCreateWalletMutation,
  useDisconnectCryptoWalletFromContractMutation,
  Wallet,
  WalletStatus,
} from '../../graphql'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { toLower } from 'lodash'
import { getTrimmedWalletAddress } from '../../utils'
import { ConfirmationAnyModal } from '../../components'
import Info from '../../assets/images/icons/info_icon.svg?react'
import { Typography } from '@material-ui/core'
import { generatePath, useHistory } from 'react-router'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'

type Web3ContextType = {
  address: `0x${string}` | undefined
  isConnected: boolean
  isDisconnected: boolean
  chain?: Chain & {
    unsupported?: boolean
  }
  cryptoWallet: Wallet | undefined
  disconnect: () => void
  isPendingCheck: boolean
  setApplicationId: React.Dispatch<React.SetStateAction<string>>
  setShouldShowChangePrompt: React.Dispatch<React.SetStateAction<boolean>>
}

export const Web3Context = createContext<Web3ContextType>({} as Web3ContextType)

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const { address, isConnected, isDisconnected } = useAccount()
  const history = useHistory()
  const { chain } = useNetwork()
  const { disconnect } = useDisconnect()

  const [isPendingCheck, setIsPendingCheck] = useState<boolean>(true)
  const [cryptoWallet, setCryptoWallet] = useState<Wallet>()
  const [applicationId, setApplicationId] = useState<string>('')
  const [shouldShowChangePrompt, setShouldShowChangePrompt] = useState<boolean>(false)
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false)

  const [createWalletMutation] = useCreateWalletMutation()
  const [
    disconnectCryptoWalletFromContractMutation,
  ] = useDisconnectCryptoWalletFromContractMutation()

  useEffect(() => {
    ;(async () => {
      try {
        if (isConnected && chain && address && !cryptoWallet && applicationId) {
          const { data } = await createWalletMutation({
            variables: {
              contractId: applicationId,
              address,
              chainId: chain.id,
            },
            refetchQueries: [
              {
                query: GetAccountsDocument,
                variables: {
                  contractId: applicationId,
                  orderBy: OrderBy.CreatedAt,
                  orderDirection: OrderDirection.Ascending,
                  pinned: true,
                },
              },
            ],
            awaitRefetchQueries: true,
          })
          setCryptoWallet(data?.createWallet as Wallet)
          setIsPendingCheck(false)
        } else if (!isConnected || isDisconnected) {
          setCryptoWallet(undefined)
          setIsPendingCheck(false)
        }
      } catch (e) {
        if (!/Something went wrong/.test(e.message)) {
          toast.error(
            t('unableToConnectWallet', "Wallet can't be connected. Please contact support."),
          )
        }
        disconnect()
      }
    })()
  }, [isConnected, address, chain, isDisconnected, cryptoWallet, applicationId])

  const onConfirmDisconnection = useCallback(async () => {
    await disconnectCryptoWalletFromContractMutation({
      variables: {
        walletId: cryptoWallet?.id as string,
      },
      refetchQueries: [
        {
          query: GetAccountsDocument,
          variables: {
            contractId: applicationId,
            orderBy: OrderBy.CreatedAt,
            orderDirection: OrderDirection.Ascending,
            pinned: true,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
    setCryptoWallet(undefined)
    if (shouldShowChangePrompt) {
      history.push(
        generatePath(APP_PATHS.dashboard.home, { [PATH_PARAMS.applicationId]: applicationId }),
      )
      setIsPromptOpen(false)
    }
  }, [cryptoWallet, applicationId, shouldShowChangePrompt])

  const onContinue = useCallback(async () => {
    if (toLower(cryptoWallet?.address || '') === toLower(address)) {
      setIsPromptOpen(false)
    }
  }, [cryptoWallet, applicationId, address])

  useEffect(() => {
    ;(async () => {
      if (
        cryptoWallet &&
        ((cryptoWallet.status !== WalletStatus.Disconnected && isDisconnected) ||
          (address && toLower(address) !== toLower(cryptoWallet.address || '')))
      ) {
        if (shouldShowChangePrompt) {
          setIsPromptOpen(true)
        } else {
          await onConfirmDisconnection()
        }
      }
    })()
  }, [cryptoWallet, applicationId, isDisconnected, address])

  useEffect(() => {
    if (applicationId && cryptoWallet && String(cryptoWallet.contract?.id) !== applicationId) {
      disconnect()
    }
  }, [applicationId, cryptoWallet])

  return (
    <Web3Context.Provider
      value={{
        address,
        isConnected,
        isDisconnected,
        chain,
        cryptoWallet,
        disconnect,
        isPendingCheck,
        setApplicationId,
        setShouldShowChangePrompt,
      }}
    >
      {children}
      <ConfirmationAnyModal
        title={t('newWalletConnected', 'New Wallet Connected')}
        color="primary"
        icon={<Info />}
        name="newWalletConnected"
        handleClose={onConfirmDisconnection}
        handleConfirm={onContinue}
        isOpen={isPromptOpen}
        labelCancel={t('restart', 'Restart')}
        labelConfirm={t('continue', 'Continue')}
      >
        <Typography>
          {t(
            'newWalletConnectedExpl',
            'You are now connected to a New Wallet {{address}}. To return to your previous wallet, reconnect it in your connector app and click "Continue". Or, click "Restart" to return to the dashboard and start using the New Wallet.',
            { address: getTrimmedWalletAddress(address || '') },
          )}
        </Typography>
      </ConfirmationAnyModal>
    </Web3Context.Provider>
  )
}
