import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DocumentStatus, useBackOfficeNewDocsCountQuery } from '../graphql'
import { PATH_PARAMS } from '../routes/paths'

export const useTotalUnreadDocCount = (contractId?: number) => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const appId = contractId || applicationId

  const { data: docsCountPending, refetch: refetchDocsCount } = useBackOfficeNewDocsCountQuery({
    variables: {
      contractId: Number(appId),
      statuses: [
        DocumentStatus.Accepted,
        DocumentStatus.PendingSignatures,
        DocumentStatus.Signed,
        DocumentStatus.Pending,
      ],
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    void refetchDocsCount()
  }, [appId])

  return {
    docsCount: docsCountPending?.unreadDocumentsCount || 0,
    refetchDocsCount,
  }
}
