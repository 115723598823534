import i18n from 'i18next'
import { EventsType } from 'react-idle-timer'
import {
  ActionsOrderBy,
  BusinessRegion,
  CardsOrderBy,
  ContractAuthPersonOrderBy,
  ContractPaymentRecipientOrderByType,
  ContractStatusType,
  DocumentOrderBy,
  OrderBy,
  OrderDirection,
  PaymentStatus,
  PaymentsOrderBy,
  SuccessFeeOrderBy,
  TransactionOrderBy,
  AccountStatus,
} from '../graphql'
import { StandingOrdersOrderBy } from '../pages/StandingOrdersPage'
import {
  AccountsPageStateType,
  ActionsPageStateType,
  BeneficiaryOverviewPageStateType,
  BeneficiaryPageStateType,
  CardsPageStateType,
  ContactMenuOptions,
  ContactsFilterTypes,
  CreateOptionEnum,
  DebitExecutionsStateType,
  DirectDebitPageStateType,
  DocumentPageStateType,
  GuaranteeEventPageStateType,
  PaymentsPageStateType,
  StandingOrdersPageStateType,
  StatusSelectorType,
  SuccessFeesPageStateType,
  TeamPageStateType,
  TrxPageStateType,
  VbansPageStateType,
  VbansTabsEnum,
  WalletInboundTrxPageStateType,
  WalletTrxPageStateType,
} from '../types'

export const DIGITS_COUNT_OF_LONG_BALANCE = 10
export const DEFAULT_COUNTRY_CALLING_CODE = 'CH'
export const IDLE_SESSION_TIMEOUT = 1000 * 60 * 25
export const LOGOUT_AFTER_TIME = 1000 * 60 * 2
export const MAX_FILE_SIZE_BYTES = 15728640
export const limitAge = 18
export const ITEM_PER_PAGE = 10
export const ITEM_PER_PAGE_TABLET = 3
export const MAX_ITEM_PER_PAGE = 25
export const MAX_ITEM_PER_PAGE_TABLET = 15
export const SEARCH_ITEM_PER_LIST = 10
export const TABLET_SCREEN_SIZE = 960

export const ACTIVE_SESSION_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
]
export const EMPTY_IMAGE = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'
export const TOTP_STRING_PARAM = 'totp'

export enum delaysForRefetch {
  delay = 2000,
  interval = 10000,
}

export const initialActionsPageState: ActionsPageStateType = {
  searchValue: '',
  type: StatusSelectorType.AllTypes,
  status: StatusSelectorType.AllStatuses,
  sortBy: ActionsOrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const initialAccountsPageState: AccountsPageStateType = {
  searchValue: '',
  currency: 'allCurrencies',
  status: StatusSelectorType.AllStatuses,
  page: 1,
}

export const initialTransactionPageState: TrxPageStateType = {
  searchValue: '',
  direction: 'allDirections',
  cardsFilter: '',
  status: 'allStatuses',
  notStatus: '',
  period: [null, null],
  cardId: 'allMethods',
  sortBy: TransactionOrderBy.CreatedAt,
  sortDirection: OrderDirection.Descending,
  page: 1,
}

export const initialStandingOrdersPageState: StandingOrdersPageStateType = {
  sortBy: StandingOrdersOrderBy.NextPayment,
  direction: OrderDirection.Ascending,
  beneficiary: StatusSelectorType.AllBeneficiary,
  page: 1,
}

export const initClientsPageState: BeneficiaryPageStateType = {
  searchValue: '',
  sortBy: ContractPaymentRecipientOrderByType.RecipientName,
  direction: OrderDirection.Ascending,
  page: 1,
}

export const initClientsOverviewPageState: BeneficiaryOverviewPageStateType = {
  sortBy: TransactionOrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const initCardsPageState: CardsPageStateType = {
  searchValue: '',
  sortBy: CardsOrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const initialTeamPageState: TeamPageStateType = {
  searchValue: '',
  role: StatusSelectorType.AllRoles,
  status: StatusSelectorType.AllStatuses,
  direction: OrderDirection.Descending,
  sortBy: ContractAuthPersonOrderBy.CreatedAt,
  page: 1,
}
export const initialDocsPageState: DocumentPageStateType = {
  searchValue: '',
  documentType: 'allTypes',
  period: [null, null],
  direction: OrderDirection.Descending,
  sortBy: DocumentOrderBy.CreatedAt,
  page: 1,
}

export const initVbansPageState: VbansPageStateType = {
  searchValue: '',
  page: 1,
  currentTab: VbansTabsEnum.individual,
}

export const initGuaranteeEventsPageState: GuaranteeEventPageStateType = {
  sortBy: OrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const initialDirectDebitPageState: DirectDebitPageStateType = {
  sortBy: OrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  status: StatusSelectorType.AllStatuses,
  page: 1,
}

export const initialDirectDebitExecutionPageState: DebitExecutionsStateType = {
  sortBy: OrderBy.CreatedAt,
  direction: OrderDirection.Descending,
  status: StatusSelectorType.AllStatuses,
  period: [null, null],
  page: 1,
}

export const initialWalletTransactionPageState: WalletTrxPageStateType = {
  status: 'allStatuses',
  sortBy: OrderBy.CreatedAt,
  sortDirection: OrderDirection.Descending,
  page: 1,
}

export const BUSINESS_APP_STATUS_KEY = (applicationId: string): string =>
  `businessAppStatus_${applicationId}`

export const initialSuccessFeePageState: SuccessFeesPageStateType = {
  sortBy: SuccessFeeOrderBy.ExecutedAt,
  sortDirection: OrderDirection.Descending,
  page: 1,
}

export const ExtendedAccessContractsTypesList = [
  ContractStatusType.Suspended,
  ContractStatusType.TerminationPending,
]

export const ContractTypeLabels: Partial<Record<ContractStatusType, string>> = {
  [ContractStatusType.Suspended]: 'suspended',
  [ContractStatusType.TerminationPending]: 'termination pending',
} as const

export const initialCardsVolumes = { domestic: 0, eea: 0, nonEea: 0 }

export const cardPaymentsRegions = [
  { key: BusinessRegion.Eea, label: 'EEA' },
  { key: BusinessRegion.Uk, label: 'UK' },
  { key: BusinessRegion.NorthAmerica, label: 'North America' },
  { key: BusinessRegion.SouthAmerica, label: 'South America' },
  { key: BusinessRegion.Asia, label: 'Asia' },
  { key: BusinessRegion.Africa, label: 'Africa' },
  { key: BusinessRegion.Australia, label: 'Australia' },
  { key: BusinessRegion.Cis, label: 'CIS' },
]

export const defaultDisplayAccountsLimit = 3

export const initialPaymentsPageState: PaymentsPageStateType = {
  searchValue: '',
  statuses: [PaymentStatus.Completed, PaymentStatus.Failed, PaymentStatus.Pending],
  methods: [],
  period: [null, null],
  sortBy: PaymentsOrderBy.ExecutedAt,
  direction: OrderDirection.Descending,
  page: 1,
}

export const accountMenuOptions = Object.freeze({
  sendPayment: i18n.t('sendPayment', 'Send Payment'),
  internalTransfer: i18n.t('internalTransfer', 'Internal transfer'),
  transactions: i18n.t('transactions', 'Transactions'),
  allTransactions: i18n.t('allTransactions', 'All transactions'),
  viewVBANs: i18n.t('viewVBANs', 'View VIBANs'),
  receivePayment: i18n.t('receivePayment', 'Receive payment'),
  standingOrders: i18n.t('standingOrders', 'Standing Orders'),
  changeAltAccount: i18n.t('changeAltAccount', 'Change Alternative Account'),
  pendingTransactions: i18n.t('pendingTransactions', 'Pending transactions'),
  depositGuaranteedInfo: i18n.t('depositGuaranteedInfo', 'Deposit guaranteed info'),
  rename: i18n.t('rename', 'Rename'),
  directDebit: i18n.t('directDebit', 'Direct Debit'),
  topupWithCard: i18n.t('topupWithCard', 'Top-up with card'),
  goToAccountPage: i18n.t('goToAccountPage', 'Go to account page'),
  ibanCertification: i18n.t('ibanCertification', 'Generate IBAN certificate'),
  buySellCrypto: i18n.t('buySellCrypto', 'Buy or sell crypto'),
  viewWallet: i18n.t('viewWallet', 'View wallet'),
  disconnect: i18n.t('disconnect', 'Disconnect'),
})

export const contactTypeLabelMap = Object.freeze({
  contractChanges: i18n.t('contractChanges', 'Contract changes'),
  general: i18n.t('general', 'General'),
  transactions: i18n.t('transactions', 'Transactions'),
})

export const initialContactsPageState: ContactsFilterTypes = {
  searchValue: '',
  page: 1,
}

export const ACCOUNT_INFO_STATUS = 'accountInfoStatus'
export const REBRANDING_INFO_STATUS = 'rendingInfoStatus'
export const SELECTED_ACCOUNT_ID = 'selectedAccountId'
export const CURRENT_USER_ID = 'currentUserId'
export const GUARANTEED_TAB = 'guaranteedTab'
export const CLIENT_MODAL = 'clientModal'
export const DIRECTOR_FORM_OPEN = 'directorFormOpen'
export const ROW_PER_PAGE = 'rowPerPage'
export const LAST_PAYOUT_CURRENCIES = 'lastPayoutCurrencies'

export const contactMenuOptions: ContactMenuOptions[] = [
  {
    key: 'remove',
    label: i18n.t('remove', 'Remove'),
  },
]

export const createOptions = Object.freeze({
  [CreateOptionEnum.payment]: i18n.t('payment', 'Payment'),
  [CreateOptionEnum.internal]: i18n.t('internal', 'Internal'),
  [CreateOptionEnum.batch]: i18n.t('batch', 'Batch'),
  [CreateOptionEnum.topUp]: i18n.t('topUp', 'Top up'),
  [CreateOptionEnum.cryptoTransfer]: i18n.t('cryptoTransfer', 'Crypto transfer'),
  [CreateOptionEnum.standingOrder]: i18n.t('standingOrder', 'Standing order'),
  [CreateOptionEnum.account]: i18n.t('accounts', 'Accounts'),
  [CreateOptionEnum.card]: i18n.t('card', 'Card'),
  [CreateOptionEnum.beneficiary]: i18n.t('beneficiary', 'Beneficiary'),
})

export const specialAccountStatuses = Object.freeze([
  AccountStatus.PendingApproval,
  AccountStatus.PendingOpeningSignatures,
  AccountStatus.PendingIncomingPayment,
  AccountStatus.PendingClosureSignatures,
  AccountStatus.Rejected,
])

export const initialWalletInboundTrxPageState: WalletInboundTrxPageStateType = {
  sortBy: TransactionOrderBy.CreatedAt,
  sortDirection: OrderDirection.Descending,
  page: 1,
}
