import { FormControlLabel, Switch as ToggleElement, SwitchProps } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: theme.spacing(1, 1, 1, 2),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.common.black,
        opacity: 1,
        border: `none`,
      },
    },
    '&:not($checked) $thumb': {
      backgroundColor: theme.palette.common.black,
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    margin: 1,
    width: 12,
    height: 12,
  },
  track: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.common.white,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))

interface TogglerProps extends SwitchProps {
  label: string
}

export const Toggler: FC<TogglerProps> = ({ label, ...props }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      control={
        <ToggleElement
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
          data-test={`autotest-${props.name}`}
        />
      }
      label={label}
    />
  )
}
