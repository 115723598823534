import { makeStyles } from '@material-ui/core/styles'

export const useDetailsStyles = makeStyles((theme) => ({
  wrap: {
    overflow: 'hidden',
    padding: theme.spacing(10, 1, 1, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1, 4, 1),
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
    },
  },
  headPayment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    margin: theme.spacing(2, 0, 4, 0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
  },
  button: {
    height: 48,
    margin: theme.spacing(4, 0, 3),
    padding: theme.spacing(1, 2),
    minWidth: 264,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  fullName: {
    textTransform: 'capitalize',
  },
  alert: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
  wrapDocuments: {
    maxWidth: '650px',
  },
  btn: {
    minWidth: 160,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  closeBtn: {
    minWidth: 216,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  control: {
    marginTop: theme.spacing(2.5),
  },
  innerControl: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flex: '0 0 100%',
    },
  },
  radioGroup: {
    paddingLeft: 15,
    marginTop: 32,
  },
  radioLabel: {
    color: 'black',
    marginBottom: 12,
  },
  radio: {
    '& .MuiSvgIcon-root': {
      color: 'black',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      opacity: '.5',
    },
  },
  table: {
    '& td': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
  },
  label: {
    fontWeight: 'bold',
  },
  borderTop: {
    '& .MuiTableCell-root': {
      borderTop: '1px solid rgba(0,0,0,.1)',
    },
  },
  cell: {
    paddingTop: theme.spacing(3),
    height: 68,
  },
  backWrapp: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 0),
    '& .MuiFormControl-root': {
      width: '100%',
      minHeight: 'auto',
    },
    '& .MuiFormHelperText-root': {
      position: 'relative',
      bottom: 5,
      margin: 0,
      paddingLeft: 10,
    },
  },
  backItem: {
    width: '100%',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    '& .MuiTypography-body1': {
      lineHeight: '40px',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#999',
      '& .black': {
        backgroundColor: '#000',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
    },
  },
  backIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
    border: 0,
    borderRadius: '50%',
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    '& .MuiSvgIcon-root': {
      transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      width: 'auto',
      height: 32,
      minHeight: 32,
      flex: '0 0 auto',
      borderRadius: 48,
      padding: theme.spacing(1, 2, 1, 1),
      minWidth: 82,
      marginBottom: theme.spacing(1),
    },
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: '12px',
    width: '100%',
    '& .MuiAutocomplete-listbox': {
      maxHeight: '120px',
    },
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
  },
  fileLeftInfo: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  doc: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 16px',
  },
  fileIcon: {
    padding: theme.spacing(0.5, 0, 0, 0),
  },
  fileBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileInfoName: {
    padding: theme.spacing(0, 1, 0, 1.5),
    //color: errorUpload ? '#e11900' : '#000000',
  },
  boAndDocType: {
    alignSelf: 'flex-start',
    '& .MuiChip-root': {
      width: '100%',
    },
  },
  fileInfoSize: {
    padding: theme.spacing(0, 1, 0, 1.5),
    color: '#999',
  },
  lowerCase: {
    textTransform: 'lowercase',
  },
}))
