import clsx from 'clsx'
import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { isEqual } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractProfileChangeAnswers } from '../../../../graphql'
import PreAssessmentExpectedVolumes from '../../../../pages/LegacyPreAssessmentPage/PreAssessmentExpectedVolumes'
import { PartnersInformationEnum } from '../../../../types'
import { getLabelByKey, selectCurrenciesLabelsArray } from '../../../../utils'
import {
  economicProfileAverageSizeTypesList,
  economicProfileTransferDescriptionTypesList,
  economicProfileTransferPayrollTypesList,
} from '../../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField } from '../../../Common'
import ControlledTooltipWrapped from '../../../Common/Tooltips/ControlledTooltipWrapped'
import { EntitiesInfo } from './EntitiesInfo'
import { useCommonStyles } from '../helpers/functions'

const useStyles = makeStyles((theme: Theme) => ({
  rowWithCurrency: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiInputBase-input': {
      transform: 'translate(0, 8px)',
    },
  },
  currency: {
    flexBasis: '200px',
    marginLeft: theme.spacing(2.5),
  },
  dirtyControl: {
    position: 'relative',
  },
  whiteBackground: {
    backgroundColor: theme.palette.background.paper,
  },
  moveDown: {
    transform: 'translate(0, 18px)',
  },
}))

const EconomicProfileComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ readOnly = false, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const { formState } = useFormContext()

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box>
        <Typography variant="h6" className={classes.descriptionForField}>
          {t(
            'companyAnnualIncomeAsPerTheLastAvailableAuditedFinancialStatements',
            'Company’s annual income as per the last available audited financial statements or equivalent',
          )}
          .
        </Typography>
        <Box className={classes.rowWithCurrency}>
          <FormControlledTextField
            className={classes.whiteBackground}
            label={t('amount', 'Amount')}
            name="companyAnnualIncome"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            fullWidth
            required={false}
            disabled={readOnly}
          />
          <Box className={classes.currency}>
            <FormAutocompleteSelect
              className={classes.whiteBackground}
              label={t('currency', 'Currency')}
              name="companyAnnualIncomeCurrency"
              data={selectCurrenciesLabelsArray}
              disabled={readOnly}
              data-test="currencyForm"
            />
          </Box>
        </Box>
        {changedContractProfileData?.preAssessment?.companyAnnualIncome && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.companyAnnualIncome}
            title={changedContractProfileData.preAssessment.companyAnnualIncome}
          />
        )}
      </Box>

      <PreAssessmentExpectedVolumes
        changedContractProfileData={changedContractProfileData}
        readOnly={readOnly}
        isOnboarding
      />

      <Typography variant="h5">{t('inboundTransactions', 'Inbound transactions')}</Typography>

      <Typography variant="h6" className={classes.descriptionForField}>
        {t(
          'estimatedMonthlyTurnoverForInboundTransactions',
          'Estimated monthly turnover for inbound transactions',
        )}
      </Typography>
      <Box>
        <FormAutocompleteSelect
          className={classes.whiteBackground}
          name="estimatedIncomingTransactions"
          label={t('inboundTurnover', 'Inbound turnover')}
          data={economicProfileAverageSizeTypesList}
          disabled={readOnly}
          data-test="incomingMonthlyTurnover"
          onlyLatinLetters={false}
        />

        {changedContractProfileData?.preAssessment?.incomingMonthlyTurnover && (
          <ControlledTooltipWrapped
            wrapperClass={clsx(classes.dirtyControl, classes.moveDown)}
            hidden={formState.errors.estimatedIncomingTransactions}
            title={getLabelByKey(
              changedContractProfileData.preAssessment.incomingMonthlyTurnover,
              economicProfileAverageSizeTypesList,
            )}
          />
        )}
      </Box>

      <Box>
        <Typography variant="h6" className={classes.titleSubBox}>
          {t(
            'estimatedNumberOfInboundTransactionsPerMonth',
            'Estimated number of inbound transactions per month',
          )}
        </Typography>
        <FormControlledTextField
          className={classes.whiteBackground}
          label={t('numberOfInboundTransactions', 'Number of inbound transactions')}
          name="estimatedIncomingTransactionsAmount"
          type="text"
          fullWidth
          required={false}
          multiline
          disabled={readOnly}
          data-test="estimatedIncomingTransactionsAmount"
        />
        {changedContractProfileData?.economicProfile?.estimateInbound && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.estimatedIncomingTransactionsAmount}
            title={changedContractProfileData.economicProfile.estimateInbound}
          />
        )}
      </Box>

      <EntitiesInfo
        type={PartnersInformationEnum.Incoming}
        paymentPurposeData={economicProfileTransferDescriptionTypesList}
        readOnly={readOnly}
        changedContractProfileData={changedContractProfileData}
      />

      <Typography variant="h5">{t('outboundTransactions', 'Outbound transactions')}</Typography>

      <Typography variant="h6" className={classes.descriptionForField}>
        {t(
          'estimatedMonthlyTurnoverForOutboundTransactions',
          'Estimated monthly turnover for outbound transactions',
        )}
      </Typography>
      <Box>
        <FormAutocompleteSelect
          className={classes.whiteBackground}
          name="estimatedOutgoingTransactions"
          label={t('outboundTurnover', 'Outbound turnover')}
          data={economicProfileAverageSizeTypesList}
          readOnly={readOnly}
          onlyLatinLetters={false}
          data-test="estimatedOutgoingTransactions"
        />

        {changedContractProfileData?.preAssessment?.outgoingMonthlyTurnover && (
          <ControlledTooltipWrapped
            wrapperClass={clsx(classes.dirtyControl, classes.moveDown)}
            hidden={formState.errors.estimatedOutgoingTransactions}
            title={getLabelByKey(
              changedContractProfileData.preAssessment.outgoingMonthlyTurnover,
              economicProfileAverageSizeTypesList,
            )}
          />
        )}
      </Box>

      <Box>
        <Typography variant="h6" className={classes.titleSubBox}>
          {t(
            'estimatedNumberOfOutboundTransactionsPerMonth',
            'Estimated number of outbound transactions per month',
          )}
        </Typography>
        <FormControlledTextField
          className={classes.whiteBackground}
          label={t('numberOfOutboundTransactions', 'Number of outbound transactions')}
          name="estimatedOutgoingTransactionsAmount"
          type="text"
          fullWidth
          required={false}
          multiline
          disabled={readOnly}
          data-test="autotest-estimatedOutboundTransactions"
        />
        {changedContractProfileData?.economicProfile?.estimateOutbound && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.estimatedOutgoingTransactionsAmount}
            title={changedContractProfileData.economicProfile.estimateOutbound}
          />
        )}
      </Box>

      <EntitiesInfo
        type={PartnersInformationEnum.Outgoing}
        paymentPurposeData={economicProfileTransferPayrollTypesList}
        readOnly={readOnly}
        changedContractProfileData={changedContractProfileData}
      />
    </Box>
  )
}

export const EconomicProfile = React.memo(EconomicProfileComponent, isEqual)
