import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import { WebsiteInput } from '../../Common/WebsiteInput'
import { CardPaymentsAndAmp } from '../../../graphql'
import { makeTooltipTitleTextWebsites, useCommonStyles } from './helpers/functions'

const MerchantWebsitesComponent: FC<{
  isViewOnly?: boolean
  changedContractProfileData?: CardPaymentsAndAmp | null
}> = ({ isViewOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = useCommonStyles()

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5">{t('merchantWebsites', 'Merchant websites')}</Typography>
      </Box>
      <WebsiteInput
        name="merchantWebsites"
        label={t('website', 'Website')}
        addBtnText={t('addWebsite', 'Add Website')}
        isViewOnly={isViewOnly}
        tooltipTitle={makeTooltipTitleTextWebsites(changedContractProfileData?.merchantWebsites, {
          divider: classes.lightDivider,
          listItem: classes.listItem,
        })}
      />
    </>
  )
}
export const MerchantWebsites = React.memo(MerchantWebsitesComponent)
