import React, { FC, ReactNode, useCallback, useState } from 'react'
import { Box, IconButton, makeStyles, Menu, MenuList } from '@material-ui/core'
import { isEqual } from 'lodash'
import IconDots from '../../../assets/images/icons/icon_dots_vertical.svg?react'

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: theme.spacing(5),
  },
  disabledMenu: {
    opacity: 0.4,
  },
}))

const ThreeDotMenuComponent: FC<{
  disabled?: boolean
  children: ReactNode
}> = ({ disabled, children }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(null)
    },
    [setAnchorEl],
  )

  return (
    <Box className={classes.menu}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        className={`${!!anchorEl ? 'open' : ''}`}
      >
        <IconDots className={`${disabled && classes.disabledMenu}`} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: -48, horizontal: 'right' }}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        PaperProps={{
          square: false,
        }}
      >
        <MenuList onClick={handleClose}>{children}</MenuList>
      </Menu>
    </Box>
  )
}

export const ThreeDotMenu = React.memo(ThreeDotMenuComponent, isEqual)
