import { isEmpty } from 'lodash'
import * as yup from 'yup'
import i18n from '../../i18n'

export const CountriesOfOperationSchema = yup.object({
  countriesOfOperatingAndConductingBusiness: yup
    .mixed()
    .test(
      'businessSectorNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
})
