import React, { FC, useEffect, useMemo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { useCompanySelectUnionStyles } from './CompanySelect'
import { CompanySelectAccordionSubgroupProps } from './types'
import { ContractAlertDot } from './ContractAlertDot'
import { useTotalUnreadDocCount } from '../../../hooks'

const useStyles = makeStyles((theme) => ({
  subGroupAccordion: {
    backgroundColor: 'transparent',
    '&.MuiAccordion-root': {
      padding: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  subGroupAccordionHeader: {
    paddingLeft: theme.spacing(6.625),
    '& .MuiAccordionSummary-content': {
      position: 'relative',
    },
    '& .MuiTypography-root': {
      paddingLeft: 0,
    },
    '&:hover': {
      color: '#434343',
      backgroundColor: '#f5f5f5',
    },
    [theme.breakpoints.down('sm')]: {
      '&.MuiAccordionSummary-root': {
        paddingLeft: theme.spacing(6.75),
      },
    },
  },
  accordionSubgroupBody: {
    padding: 0,
  },
  accordionSubgroupHeader: {
    paddingLeft: theme.spacing(5.25),
  },
  accordionSubgroupItem: {
    '&.MuiListItem-root': {
      paddingLeft: theme.spacing(8.75),
    },
  },
  accordionSubSummaryBox: {
    position: 'relative',
    textAlign: 'left',
    fontSize: 16,
    width: 'auto',
    padding: theme.spacing(0.25, 1.65, 0, 0),
    '& .MuiFormHelperText-root': {
      bottom: 0,
      margin: 0,
      position: 'relative',
      padding: 0,
      color: '#999999',
    },
    '& .MuiTypography-root': {
      padding: 0,
    },
  },
}))

export const CompanySelectAccordionSubgroup: FC<CompanySelectAccordionSubgroupProps> = ({
  title,
  details,
}) => {
  const classes = { ...useCompanySelectUnionStyles(), ...useStyles() }
  const history = useHistory()
  const { docsCount, refetchDocsCount } = useTotalUnreadDocCount()

  const handleClick = (e: React.ChangeEvent<unknown>) => {
    e.stopPropagation()
  }

  const handleClickItem = (path: string) => {
    history.push(path)
  }

  const hideSummaryDot = useMemo(() => {
    return !details.some((item) => item?.haveAlertPoint) || !docsCount
  }, [details, docsCount])

  useEffect(() => {
    refetchDocsCount().then()
  }, [])

  return (
    <Accordion square className={clsx(classes.subGroupAccordion, classes.contractsSelectWrap)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        IconButtonProps={{ disableRipple: true }}
        className={clsx(classes.subGroupAccordionHeader, classes.accordionHeader)}
        onClick={handleClick}
      >
        <Box className={classes.accordionSubSummaryBox}>
          <ContractAlertDot size={8} hidden={hideSummaryDot} />
          <Typography className={classes.item}>{title}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails className={clsx(classes.accordionSubgroupBody, classes.accordionBody)}>
        <List>
          {details.map(({ name, href, haveAlertPoint }) => (
            <ListItem
              key={name}
              button
              className={clsx(
                classes.item,
                classes.accordionSubgroupItem,
                history.location.pathname === href && classes.activeContractItem,
              )}
              onClick={() => handleClickItem(href)}
            >
              <Typography className={classes.companyName}>
                <ContractAlertDot size={8} top={3} hidden={!haveAlertPoint || !docsCount} />
                {name}
              </Typography>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
