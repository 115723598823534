import * as yup from 'yup'
import {
  MAX_SYMBOLS_LIMIT_MESSAGE,
  MAX_TAXID_LENGTH,
  MIN_TAXID_DIGITS_LENGTH,
  MIN_TWO_DIGITS_REGEXP,
  MUST_HAVE_AT_LEAST_NUMBERS_MESSAGE,
  NUMBER_AND_LATINICA_AND_DOTS_AND_DASHES_REGEXP,
  NUMBER_AND_LATINICA_ONLY,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../constants/validations'

export const PersonAdditionalDataWithIdSchema = yup.object().shape({
  usReportablePerson: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  taxId: yup.mixed().when('usReportablePerson', {
    is: 'Yes',
    then: yup
      .string()
      .nullable()
      .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
      .matches(MIN_TWO_DIGITS_REGEXP, MUST_HAVE_AT_LEAST_NUMBERS_MESSAGE(MIN_TAXID_DIGITS_LENGTH))
      .max(MAX_TAXID_LENGTH, MAX_SYMBOLS_LIMIT_MESSAGE(MAX_TAXID_LENGTH))
      .matches(NUMBER_AND_LATINICA_AND_DOTS_AND_DASHES_REGEXP, NUMBER_AND_LATINICA_ONLY),
  }),
  politicallyExposedPerson: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
})
