import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { isEmpty, isEqual } from 'lodash'
import Box from '@material-ui/core/Grid'
import {
  Button,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Check } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import { TransactionsMethodsList } from '../../../types'

const useStyles = (filterLabel: string | undefined) =>
  makeStyles((theme) => ({
    popper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      padding: '0px 0px 0px',
      width: '100%',
      left: 0,
      zIndex: 300,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    paper: {
      position: 'absolute',
      width: '100%',
      maxHeight: '65vh',
      marginRight: 0,
      maxWidth: '100%',
      background: '#FFFFFF',
      borderRadius: 0,
      overflowX: 'hidden',
      boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
    },
    cardSelector: {
      marginLeft: 'auto',
      position: 'relative',
      background: '#FFFFFF',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        padding: 0,
        margin: 0,
      },
    },
    cardLabel: {
      width: '100%',
      textAlign: 'start',
      padding: '16px 24px 16px 12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '& :first-letter': {
        textTransform: 'capitalize',
      },
    },
    cardButton: {
      display: 'flex',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      width: '100%',
      height: 48,
      padding: 0,
      minHeight: 32,
      background: 'none',
      ...(filterLabel ? { borderBottom: '1px solid #c4c4c4' } : { border: '1px solid #c4c4c4' }),
      boxSizing: 'border-box',
      position: 'relative',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: 5,
      },
      '&.open': {
        ...(filterLabel ? { borderBottom: '1px solid #000000' } : { border: '1px solid #000000' }),
        backgroundColor: 'transparent',
        '& .MuiSvgIcon-root': {
          color: '#000000',
          transform: 'rotate(180deg)',
        },
      },
      '& .MuiTypography-root:first-letter': {
        textTransform: 'capitalize',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    item: {
      '& .MuiTypography-displayBlock': {
        marginBottom: '0px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '0.5em',
        marginRight: theme.spacing(2),
      },
      '& .flag-icon-lg': {
        fontSize: '0.8em',
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      alignContent: 'start',
      paddingRight: '0, 12px',
      position: 'static',
      width: '100%',
      left: '0px',
      top: '48px',
      background: '#FFFFFF',
      marginTop: '5px',
      justifyContent: 'space-between',
    },
    listItemTextLabel: {
      flexGrow: 17,
      flex: '0 0 auto',
      width: '92%',
    },
    listItemCheck: {
      flexGrow: 0,
      color: '#000000',
    },
    list: {
      boxShadow: '5px 5px 4px 0px rgba(34, 60, 80, 0.2)',
      backgroundColor: 'white',
      '& :first-letter': {
        textTransform: 'capitalize',
      },
    },
    arrowDropDown: {
      color: '#999999',
    },
    selected: {
      width: '100%',
      position: 'relative',
    },
    label: {
      position: 'absolute',
      top: 0,
      left: theme.spacing(1.5),
      color: '#999999',
    },
  }))()
const FilterSelectorComponent: FC<{
  optionsList: TransactionsMethodsList[] | undefined
  setValue: (value: string) => void
  currentValue: string
  filterLabel?: string
}> = ({ optionsList, setValue, currentValue, filterLabel }) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState<boolean>(false)

  const history = useHistory()

  const selectedItem = useMemo(
    () => optionsList?.find(({ key }) => key.toString() === currentValue),
    [optionsList, currentValue],
  )
  const classes = useStyles(filterLabel)

  const handleChange = useCallback(
    (transactMethod) => {
      const payload = transactMethod.key as string
      setValue(payload)
      setOpen(false)
      // for resetting page pagination to 1 when account changed
      const currentState = history.location.state || {}
      history.replace({ ...history.location, state: { ...currentState, page: 1 } })
    },
    [history],
  )

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Box className={classes.cardSelector} data-test="filter">
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={`${classes.cardButton} ${open ? 'open' : ''}`}
          data-test="autotest-filterBtn"
          disableRipple
        >
          <Box className={classes.selected}>
            {filterLabel && (
              <Typography variant="subtitle2" className={classes.label}>
                {filterLabel}
              </Typography>
            )}
            <Typography component="div" className={classes.cardLabel}>
              {selectedItem?.label || optionsList?.[0].label || '-'}
            </Typography>
          </Box>
          {open ? <ExpandMoreIcon /> : <ExpandMoreIcon className={classes.arrowDropDown} />}
        </Button>

        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          // role={undefined}
          transition
          disablePortal
          className={classes.popper}
          placement="bottom-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <List id="menu-list-grow" className={classes.list}>
                    {!isEmpty(optionsList) &&
                      (optionsList as TransactionsMethodsList[])?.map((method) => {
                        const { key, label } = method as TransactionsMethodsList
                        return (
                          <ListItem
                            key={key}
                            button
                            className={classes.item}
                            onClick={() => handleChange(method as TransactionsMethodsList)}
                            data-test={`autotest-${key}`}
                          >
                            <ListItemText className={classes.listItemTextLabel}>
                              <Trans i18nKey={label as string} defaults={label as string} />
                            </ListItemText>
                            {selectedItem?.key === key && (
                              <Check className={classes.listItemCheck} fontSize={'small'} />
                            )}
                          </ListItem>
                        )
                      })}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </>
  )
}

export const FilterSelector = React.memo(FilterSelectorComponent, isEqual)
