import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { millisecondsToSeconds } from 'date-fns'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import { Box, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { rxiosLogin } from '../../resources'
import { TwoFAModalRenderButton } from './index'
import IconDevice from '../../assets/images/icons/2faIcon.svg?react'
import IconX from '../../assets/images/icons/icon-x.svg?react'
import IconDeviceWarning from '../../assets/images/icons/iconDeviceWarning.svg?react'
import clsx from 'clsx'
import { resendDelay } from '../../graphql/local'
import { InputCodeField } from './InputCode'
import { checkFullCode } from '../../utils'
import { TwoFaCodeDispatchMode, TwoFaMethod } from '../../graphql'
import { useReactiveVar } from '@apollo/client'
import { useFormContext } from 'react-hook-form'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    minWidth: 328,
    maxWidth: 552,
    padding: theme.spacing(8, 6, 3.5),
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
      width: '90%',
      padding: theme.spacing(4.5, 3, 4.5),
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
  mrgTop: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  btn_resend: {
    fontSize: theme.spacing(2.25),
    height: 47.5,
    maxWidth: 264,
    backgroundColor: 'black',
    color: 'white',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}))

export const AuthoriseModal: FC<{
  open: boolean
  handleClose: (success?: boolean) => void
  id: string
  phoneDigits?: string
  setPswdChanged?: React.Dispatch<React.SetStateAction<boolean>> | undefined
  children?: React.ReactNode
  title: string
  twoFaType?: string
  get2faCodeRequest?: (newPassword: string, mode?: TwoFaCodeDispatchMode) => void
}> = ({
  open,
  handleClose,
  id,
  phoneDigits,
  setPswdChanged,
  children,
  title,
  twoFaType = 'sms',
  get2faCodeRequest,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const newPassword = watch('password')
  // const history = useHistory()
  const launchRef = useRef(true)
  const initialValue = { d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }
  const resendCodeDelayMs = useReactiveVar(resendDelay)
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [attemptsSend, setAttemptsSend] = useState(1)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(millisecondsToSeconds(resendCodeDelayMs))
  const [validMessage, setValidMessage] = useState<string>('')

  const startInput = useRef<HTMLInputElement>(null)
  const enteredCode = Object.values(code).join('')
  const resultCheckFullCode = checkFullCode(code)

  const sendCode = useCallback(async () => {
    if (enteredCode.length === 6) {
      //sending data to api

      setLoading(true)
      rxiosLogin
        .post('verify-2fa', {
          code: enteredCode,
          challengeId: id,
          twoFaType: twoFaType,
        })
        .subscribe(
          () => {
            setIsError(false)
            setValidMessage('')
          },
          (e) => {
            setIsError(true)
            if (e.response?.data?.tokenExpired || e.response?.data?.code === 'EXPIRED_2FA') {
              setValidMessage(t('codeHasExpired', 'Code has expired'))
            } else {
              setValidMessage(t('codeIncorrect', 'Code is incorrect'))
            }
            setLoading(false)
            // setCode(initialValue)
            if (null !== startInput.current) {
              startInput.current.focus()
            }
          },
          () => {
            setValidMessage(t('codeCorrect', 'Code is correct'))
            setLoading(false)
            handleClose(true)
            !!setPswdChanged && setPswdChanged(true)
          },
        )
    }
  }, [code])

  const onReSend = useCallback(
    (mode?: TwoFaCodeDispatchMode) => {
      launchRef.current = true
      setBtnDisable(true)
      setSecondsLeft(millisecondsToSeconds(resendCodeDelayMs))
      setAttemptsSend((prev) => prev + 1)
      get2faCodeRequest && get2faCodeRequest(newPassword, mode)
    },
    [
      setAttemptsSend,
      setBtnDisable,
      setSecondsLeft,
      resendCodeDelayMs,
      launchRef.current,
      get2faCodeRequest,
      newPassword,
    ],
  )

  const renderButton = useMemo(() => {
    if ((validMessage && !isError) || twoFaType === TwoFaMethod.Totp) {
      return null
    } else {
      return (
        <TwoFAModalRenderButton
          isNotShow={twoFaType === TwoFaMethod.Totp}
          secondsLeft={secondsLeft}
          btnDisable={btnDisable}
          onReSend={onReSend}
          isShowContactSupportLink={attemptsSend > 5}
        />
      )
    }
  }, [validMessage, isError, twoFaType, secondsLeft, btnDisable])

  useEffect(() => {
    code.d6 && sendCode()
  }, [code])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendCodeDelayMs)
    if (!launchRef.current) return
    setSecondsLeft(millisecondToSecond)

    const timer = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => {
        if (prevSecondsLeft === 1) {
          clearInterval(timer)
          setBtnDisable(false)
          launchRef.current = false
          return 0
        }

        return prevSecondsLeft - 1
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [btnDisable, launchRef.current])

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => {
      return {
        ...prevState,
        [id]: value,
      }
    })
  }

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMessage('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  const confirmSignatureMessage = useMemo(() => {
    return twoFaType === TwoFaMethod.Sms ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>{phoneDigits ? `${phoneDigits}` : ''}</b>.{' '}
        {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography>
        {t(
          'openYourAuthenticatorAppAndTypeCode',
          'Open your authenticator app and type in the code to the field below.',
        )}
      </Typography>
    )
  }, [phoneDigits, twoFaType])

  const body = (
    <div
      className={clsx(
        classes.paper,
        resultCheckFullCode && isError && 'error',
        validMessage && !isError && 'success',
      )}
      data-test="confirmResetPassword"
    >
      <IconButton
        className={classes.btnClose}
        color="primary"
        aria-label="close modal"
        component="span"
        onClick={() => handleClose(false)}
      >
        <IconX />
      </IconButton>

      <Grid className="iconContainer">
        {resultCheckFullCode ? !isError ? <IconDevice /> : <IconDeviceWarning /> : null}
      </Grid>

      {!!title && (
        <Box textAlign="center" mb={1}>
          <Typography variant={'h2'}>{title}</Typography>
        </Box>
      )}

      {!!children && <Box textAlign="left">{children}</Box>}

      <Box textAlign="center">{confirmSignatureMessage}</Box>

      <InputCodeField
        startInput={startInput}
        currencyValue={code}
        setCode={handleSetCode}
        loading={loading}
      />

      <Grid container className="infoText">
        {!!validMessage && resultCheckFullCode && <span>{validMessage}</span>}
      </Grid>

      <Grid item xs={12} className={classes.mrgTop}>
        {renderButton}
      </Grid>
    </div>
  )

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}
