import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Box, Button, Grid, IconButton, Grow } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useCommonStyles } from '../NewBusinessApp/PreAssessmentQuestionnaire/helpers/functions'
import { LinkButton } from './Buttons'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import { FormControlledTextField, GridRow } from './Fields'
import { ExpectedVolumes } from '../../types'
import { cloneDeep, isEmpty, isEqual, set } from 'lodash'
import { AmpInUse } from '../../graphql'
import { FormNumericFormat } from './Fields/FormNumericFormat'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
  },
  twoRows: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(5),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  volume: {
    '& .MuiInputAdornment-positionEnd': {
      paddingTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& label': {
      [theme.breakpoints.down(500)]: {
        transform: 'translate(0, 8px) scale(1)',
        '&[data-shrink="true"]': {
          transform: 'translate(0, 6px) scale(0.857)',
        },
      },
    },
  },
  addButton: {
    width: 132,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      marginLeft: '51%',
      transform: 'translate(0, -15px)',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  buttonsBox: {
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      color: '#EF2828',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
  volumeContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

const DoubleTextInputComponent: FC<{
  name: string
  labels: [string, string]
  addBtnText: string
  isViewOnly?: boolean
  tooltipTitle?: NonNullable<React.ReactNode>
  isOnboarding?: boolean
}> = ({ name, labels, addBtnText, isViewOnly, tooltipTitle, isOnboarding }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const [label1, label2] = labels
  const methods = useFormContext()
  const { watch, register, setValue, getValues, errors, unregister, formState } = methods
  const inputsSelectNamesArr = ['input1'].map((_, i) => `${name + 'Inp1' + i}`)
  const inputsFieldsNamesArr = ['input2'].map((_, i) => `${name + 'Inp2' + i}`)
  const inputsValuesArr = watch(name)
  const inputsSelect = watch(inputsSelectNamesArr)
  const inputsField = watch(inputsFieldsNamesArr)

  const [activeRow, setActiveRow] = useState<number | null>(null)

  const error = errors ? errors[name] : null

  const onAdd = useCallback(() => {
    const valuesArr = getValues(name)
    register(inputsSelectNamesArr[valuesArr?.length ?? 0])
    register(inputsFieldsNamesArr[valuesArr?.length ?? 0])
    const newArrVal = [
      ...(valuesArr || []),
      { ampInUseTypeCurrentlyInUse: '', monthlyVolumeInEuro: '' },
    ]

    setValue(name, newArrVal, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [...Object.values(inputsSelect), ...Object.values(inputsField), getValues, setValue])

  useEffect(() => {
    register(inputsSelectNamesArr[0])
    register(inputsFieldsNamesArr[0])
    register(name)
  }, [])

  useEffect(() => {
    if (isEmpty(inputsValuesArr)) {
      onAdd()
    }
  }, [inputsValuesArr])

  const onRemove = useCallback(
    (indexToRemove: number) => {
      const tempArray: ExpectedVolumes[] = []
      inputsValuesArr?.forEach((itemValue: ExpectedVolumes, i: number) => {
        if (i !== indexToRemove) {
          tempArray.push(itemValue)
        } else {
          setValue(`${name + 'Inp1' + i}`, undefined)
          setValue(`${name + 'Inp2' + i}`, undefined)
          unregister([`${name + 'Inp1' + i}`, `${name + 'Inp2' + i}`])
        }
      })
      setValue(name, tempArray, {
        shouldValidate: true,
        shouldDirty: true,
      })
    },
    [inputsValuesArr, setValue],
  )

  const onBlurMonthlyVolumeInEuro = useCallback(
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedArray = cloneDeep(inputsValuesArr)
      set(updatedArray, `[${i}].monthlyVolumeInEuro`, e.target.value)
      setValue(name, updatedArray)
    },
    [inputsValuesArr],
  )

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          <GridRow ignore={isOnboarding}>
            {inputsValuesArr &&
              inputsValuesArr.map((value: AmpInUse, i: number) => {
                return (
                  <Box
                    className={classes.container}
                    key={`${i}${value.monthlyVolumeInEuro}`}
                    onMouseEnter={() => setActiveRow(i)}
                    onMouseLeave={() => setActiveRow(null)}
                  >
                    <Box className={classes.twoRows}>
                      <FormControlledTextField
                        className={clsx(classes.volume, classes.whiteBackground)}
                        label={label1}
                        name={`${name + 'Inp1' + i}`}
                        fullWidth={true}
                        required={false}
                        type="text"
                        inputProps={{
                          step: '1',
                          style: { textAlign: 'right' },
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(`${name}.${i}.ampInUseTypeCurrentlyInUse`, e.target.value)
                          },
                        }}
                        defaultValue={value?.ampInUseTypeCurrentlyInUse}
                        disabled={isViewOnly}
                      />

                      <Box className={classes.volumeContainer}>
                        <FormNumericFormat
                          name={`${name + 'Inp2' + i}`}
                          label={label2}
                          className={clsx(classes.volume, classes.whiteBackground)}
                          disabled={isViewOnly}
                          fullWidth={true}
                          textAlignRight
                          defaultValue={value?.monthlyVolumeInEuro}
                          onBlur={onBlurMonthlyVolumeInEuro(i)}
                        />
                        {activeRow === i && !isViewOnly && (
                          <Grow in timeout={500}>
                            <Box className={classes.buttonsBox}>
                              <IconButton
                                color="inherit"
                                aria-label="delete"
                                onClick={() => onRemove(i)}
                                disabled={isViewOnly}
                                data-test={`autotest-remove${name}Btn`}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Box>
                          </Grow>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            {!!error && (
              <Grid container className={classes.errorMessage}>
                <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
              </Grid>
            )}
            {tooltipTitle && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors[name]}
                title={tooltipTitle}
              />
            )}
            {isOnboarding ? (
              <LinkButton
                disableRipple
                onClick={onAdd}
                disabled={isViewOnly}
                data-test={`autotest-${name}Btn`}
              >
                + {t('addApm', 'Add APM')}
              </LinkButton>
            ) : (
              <Grid item className={classes.addButton}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth={true}
                  disableElevation
                  onClick={onAdd}
                  disabled={isViewOnly}
                >
                  {addBtnText}
                </Button>
              </Grid>
            )}
          </GridRow>
        </Box>
      </FormProvider>
    </>
  )
}
export const DoubleTextInput = React.memo(DoubleTextInputComponent, isEqual)
