import React, { FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { find, forEach, isEmpty, isEqual } from 'lodash'
import { useParams } from 'react-router-dom'

import { Button, Fab, FormControl, Grid, makeStyles, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Loader from '../../components/Common/Loader'

import { FilesForSavingAndShowType, TransactionFullNameType } from '../../types'
import {
  CreateOwnDocumentRelatedMutationVariables,
  Document,
  DocumentType,
  DocumentUserFilter,
  GetGetUploadedDocsCountDocument,
  GetTransactionDocumentsDocument,
  GetUploadedDocsDocument,
  OrderBy,
  OrderDirection,
  useCreateOwnDocumentRelatedMutation,
  useGetTransactionDetailsLazyQuery,
  useGetTransactionDocumentsLazyQuery,
} from '../../graphql'
import { CreditTransactionDocumentOptions, DebitTransactionDocumentOptions } from '../../utils/Data'
import { PoiInput } from '../../schemes/common'
import {
  ConfirmationModal,
  isModalDialogOpen as isModalDialogOpenVar,
  isModalPromptOpen,
} from '../../components'
import { ProofOfIdentity } from '../../components/NewPerson/Forms/ProofOfIdentity'
import { PATH_PARAMS } from '../../routes/paths'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 512,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      margin: 0,
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ccc',
  },
  bodyModal: {
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'auto',
  },
  footerModal: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  formGroup: {
    width: '100%',
    marginBottom: theme.spacing(5),
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
}))

type DocsUploadModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSupportingDocuments: React.Dispatch<React.SetStateAction<Document[] | undefined>>
  setPaymentReviewDocs?: React.Dispatch<
    React.SetStateAction<CreateOwnDocumentRelatedMutationVariables[] | undefined>
  >
  paymentReviewDocs?: CreateOwnDocumentRelatedMutationVariables[] | undefined
  setFilesForSaving?: React.Dispatch<SetStateAction<FilesForSavingAndShowType[] | undefined>>
  filesForSaving?: FilesForSavingAndShowType[] | undefined
  accountId?: string | number | undefined
  trxId?: string | number | undefined
  relatedStandingOrderId?: string | number | undefined
}

const DocumentUploadComponent: FC<DocsUploadModalProps> = ({
  open,
  setOpen,
  setSupportingDocuments,
  setPaymentReviewDocs,
  paymentReviewDocs,
  setFilesForSaving,
  filesForSaving,
  accountId,
  trxId,
  relatedStandingOrderId,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [showTypeErr, setShowTypeErr] = useState(false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const defaultVal = {
    documentTypeTransaction: undefined,
    filesForUpload: [],
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultVal,
  })

  const { watch, register, getValues, setValue, reset, formState, handleSubmit } = methods

  const filesForUpload = watch(`filesForUpload`)

  const [createOwnDocumentRelated, { loading }] = useCreateOwnDocumentRelatedMutation({})
  const [
    GetTransactionDocumentsQuery,
    { data: documentsData },
  ] = useGetTransactionDocumentsLazyQuery()

  const [
    GetTransactionDetailsQuery,
    { data: transactionData },
  ] = useGetTransactionDetailsLazyQuery()

  useEffect(() => {
    if (trxId && !setPaymentReviewDocs) {
      ;(async () => {
        await GetTransactionDetailsQuery({
          variables: {
            id: trxId,
          },
        })
      })()
    }
  }, [trxId, GetTransactionDetailsQuery])

  const optionsForSelect = useMemo(
    () =>
      transactionData?.transaction?.__typename === TransactionFullNameType.credit
        ? CreditTransactionDocumentOptions
        : DebitTransactionDocumentOptions,
    [
      transactionData,
      TransactionFullNameType,
      CreditTransactionDocumentOptions,
      DebitTransactionDocumentOptions,
    ],
  )

  const handleHelpReset = useCallback(() => {
    setOpen(false)
    reset(defaultVal)
  }, [setOpen, defaultVal, reset])

  const handleClose = useCallback(() => {
    if (formState.isDirty) {
      isModalPromptOpen(false)
      isModalDialogOpenVar(true)
      return
    }
    handleHelpReset()
  }, [formState.isDirty])

  useEffect(() => {
    if (getValues(`documentTypeTransaction`) !== undefined) {
      setValue(`documentTypeTransaction`, ``)
    }
    setShowTypeErr(false)
  }, [accountId, getValues, open, setValue, trxId])

  useEffect(() => {
    if (documentsData) {
      setSupportingDocuments(documentsData.documents as Document[])
    }
  }, [documentsData, setSupportingDocuments])

  useEffect(() => {
    if (!!setPaymentReviewDocs) return
    if (trxId) {
      ;(async () => {
        await GetTransactionDocumentsQuery({
          variables: {
            transactionId: trxId as string,
          },
        })
      })()
    } else if (relatedStandingOrderId) {
      ;(async () => {
        await GetTransactionDocumentsQuery({
          variables: {
            standingOrderId: relatedStandingOrderId,
          },
        })
      })()
    }
  }, [trxId, relatedStandingOrderId, setPaymentReviewDocs, GetTransactionDocumentsQuery])

  useEffect(() => {
    register(`filesForUpload`)
    if (getValues(`filesForUpload`) === undefined || getValues(`filesForUpload`)?.length) {
      setValue(`filesForUpload`, [])
    }
  }, [register, setValue, getValues, open])

  const sendChangeRequest = useCallback(
    async (formData: PoiInput) => {
      const hasNoType = find(formData.filesForUpload, { docType: undefined })
      if (!!hasNoType) {
        return setShowTypeErr(true)
      }
      try {
        async function processUploadedDocs(filesForUpload: FilesForSavingAndShowType[]) {
          if (filesForUpload && filesForUpload.length > 0) {
            for (const fileForSaving of filesForUpload) {
              const fileDescription = find(
                optionsForSelect,
                ({ key }) => key === fileForSaving.docType,
              )?.label
              const mutationParams: CreateOwnDocumentRelatedMutationVariables = {
                contractId: +applicationId,
                type: fileForSaving.docType as DocumentType,
                fileName: fileForSaving.fileName,
                size: fileForSaving.size,
                plainUrl: fileForSaving.plainUrl ?? '',
                fileDescription,
              }
              mutationParams.relatedAccountId = accountId
              relatedStandingOrderId
                ? (mutationParams.relatedStandingOrderId = relatedStandingOrderId)
                : (mutationParams.relatedTransactionId = trxId)
              const refetchQueriesArr = trxId
                ? [
                    {
                      query: GetTransactionDocumentsDocument,
                      variables: { transactionId: trxId as string },
                    },
                  ]
                : relatedStandingOrderId
                ? [
                    {
                      query: GetTransactionDocumentsDocument,
                      variables: { standingOrderId: relatedStandingOrderId },
                    },
                  ]
                : [
                    {
                      query: GetUploadedDocsDocument,
                      variables: {
                        contractId: +applicationId,
                        userFilter: DocumentUserFilter.UploadedByIndividual,
                        limit: 10,
                        offset: 0,
                        orderBy: OrderBy.CreatedAt,
                        orderDirection: OrderDirection.Descending,
                      },
                    },
                    {
                      query: GetGetUploadedDocsCountDocument,
                      variables: {
                        contractId: +applicationId,
                        userFilter: DocumentUserFilter.UploadedByIndividual,
                      },
                    },
                  ]
              if (!setPaymentReviewDocs) {
                await createOwnDocumentRelated({
                  variables: mutationParams,
                  refetchQueries: refetchQueriesArr,
                  awaitRefetchQueries: true,
                })
              } else {
                const filesForUploadParamsA: CreateOwnDocumentRelatedMutationVariables[] = []
                const tempArrayParams: CreateOwnDocumentRelatedMutationVariables[] = []
                forEach(paymentReviewDocs, (paramItem) => tempArrayParams.push(paramItem))
                forEach(filesForUpload, (paramItem) => {
                  const description = find(optionsForSelect, ({ key }) => key === paramItem.docType)
                    ?.label
                  filesForUploadParamsA.push({
                    contractId: +applicationId,
                    type: paramItem?.docType as DocumentType,
                    fileName: paramItem?.fileName,
                    size: paramItem?.size,
                    plainUrl: paramItem.plainUrl ?? '',
                    fileDescription: description,
                  })
                })
                setPaymentReviewDocs &&
                  setPaymentReviewDocs(tempArrayParams.concat(filesForUploadParamsA))

                setFilesForSaving &&
                  setFilesForSaving((prevState) => {
                    return prevState ? [...prevState, fileForSaving] : [fileForSaving]
                  })
              }
            }
          }
        }
        await processUploadedDocs(filesForUpload)
      } catch (e) {
        toast.error((e as Error).message)
        toast.error(t('savingError', 'Error saving data'))
      } finally {
        setValue(`filesForUpload`, [])
        setOpen(false)
      }
    },
    [
      accountId,
      applicationId,
      createOwnDocumentRelated,
      filesForUpload,
      setPaymentReviewDocs,
      paymentReviewDocs,
      setValue,
      t,
      trxId,
      filesForSaving,
      setFilesForSaving,
      relatedStandingOrderId,
    ],
  )

  return (
    <>
      {loading ? (
        <Loader withBackdrop={true} />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Grid
            container
            spacing={5}
            style={modalStyle}
            className={classes.modal}
            data-test="uploadADocument"
          >
            <Grid item xs={12} className={classes.headerModal}>
              <Typography variant={'h5'}>{t('addDocumentsSmall', 'Add documents')}</Typography>
              <Fab
                color="default"
                aria-label="close"
                className={classes.btnCloseModal}
                onClick={handleClose}
                data-test="btnCloseModal"
              >
                <CloseIcon />
              </Fab>
            </Grid>
            <Grid item xs={12} className={classes.bodyModal}>
              <FormProvider {...methods}>
                <ConfirmationModal
                  handleHelpReset={handleHelpReset}
                  setOpen={setOpen}
                  isGoBack={false}
                />
                <form onSubmit={handleSubmit(sendChangeRequest)}>
                  <FormControl className={`${classes.formGroup} ${classes.mb3}`}>
                    <ProofOfIdentity
                      poiDocTypesArr={[]}
                      isProfile={true}
                      nameLabel={''}
                      infoLabel={''}
                      documentTypeRegName={'documentTypeTransaction'}
                      optionalIdentityList={optionsForSelect}
                      isTransaction={true}
                      showTypeErr={showTypeErr}
                      setShowTypeErr={setShowTypeErr}
                    />
                  </FormControl>
                  <Grid item xs={12} className={classes.footerModal}>
                    <Grid container item spacing={2}>
                      <Grid item data-test="cancel">
                        <Button onClick={handleClose} variant="contained">
                          {t('cancel', 'Cancel')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isEmpty(filesForUpload)}
                          data-test="upload"
                        >
                          {t('uploadSelected', 'Upload selected')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  )
}

export const DocsUploadModalTransactions = React.memo(DocumentUploadComponent, isEqual)
