import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload } from 'react-use'
import { generatePath, useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Typography, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { useReactiveVar } from '@apollo/client'

import {
  isModalDialogOpen,
  isUnsaveFormData,
  totalShare,
  goBackPathVar,
  goNextPathVar,
} from '../../../graphql/local'
import {
  AddressAutocomplete,
  CommonTipItem,
  ConfirmationModal,
  FormControlledTextField,
  FormDatePickerField,
  GridRow,
  LoadingButton,
  FormAutocompleteSelect,
} from '../../Common'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { DomLegalEntityValidationSchema, LegalEntityValidationSchema } from '../../../schemes'
import InformationIcon from '../../../assets/images/icons/info_icon.svg?react'
import { uboLegalEntityTypesList } from '../../../utils/Data'
import {
  AddContractUboInputType,
  GetUbosListDocument,
  LegalEntity,
  UboOwner,
  UboStakeType,
  UboType,
  useAddContractUboMutation,
  useAddUboOwnerMutation,
  useGetUboDetailsLazyQuery,
  useGetUboOwnersDetailsLazyQuery,
  useUpdateContractUboMutation,
  useUpdateUboOwnerMutation,
  useGetContractDetailsQuery,
  ContractStatusType,
  ContractType,
  UboLegalEntityType,
} from '../../../graphql'
import { StepperTypes, RegistrationSteps, AppActions, statusActions } from '../../../constants'
import { useQuery, useStepper } from '../../../hooks'
import { extractDateValue, focusKeyPressNext, scrollToInputElement } from '../../../utils'
import { registeredAddressEmptyProps } from '../../../stubs'
import { ControllingPersonsTypeEnum } from '../../../types'

const useStyles = makeStyles(() => ({
  info: {
    marginBottom: 40,
    border: '1px solid #D4E2FC',
    fontSize: 14,
    '&>div': {
      padding: '5px 10px',
    },
  },
}))

export const LegalEntityOwnerForm: FC<{
  isDomiciliary: boolean
  newRecord: boolean
}> = ({ isDomiciliary, newRecord, children }) => {
  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.uboId]: uboId,
    [PATH_PARAMS.ownedUboId]: ownedUboId,
    [PATH_PARAMS.controllingPerson]: controllingPerson,
  } = useParams() as Record<string, string>

  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const goBackPath = useReactiveVar(goBackPathVar)
  const goNextPath = useReactiveVar(goNextPathVar)
  const isFromDirectorForm = useQuery().get('fromDirectorForm') === 'yes'

  const { data: contractData, loading: contractLoading } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })
  const [GetUboDetailsQuery, { data: contractUboData }] = useGetUboDetailsLazyQuery({
    variables: { contractUboId: uboId },
  })

  const [GetUboOwnersDetailsQuery, { data: uboOwnersDetails }] = useGetUboOwnersDetailsLazyQuery({
    variables: {
      id: ownedUboId,
    },
  })
  const [addContractUboMutation] = useAddContractUboMutation()
  const [addUboOwnerMutation] = useAddUboOwnerMutation()
  const [updateContractUboMutation] = useUpdateContractUboMutation()
  const [updateUboOwnerMutation] = useUpdateUboOwnerMutation()

  const shareSizeSummary = useMemo(() => {
    return (
      contractData?.contract?.ubos?.reduce(
        (acc, val) => acc + (val?.id !== uboId ? val?.shareSize ?? 0 : 0),
        0,
      ) || 0
    )
  }, [contractData])

  const { setHeaderText, setCurrentStep } = useStepper(
    contractData?.contract?.type === ContractType.IntroducerReference
      ? StepperTypes.partnerRegistration
      : StepperTypes.registration,
  )
  useEffect(() => {
    setCurrentStep(RegistrationSteps.controllingPersons)
    if (!contractLoading) {
      const contractOwner = contractData?.contract?.owner as LegalEntity
      const contractActions = contractData?.contract?.status
        ? statusActions[contractData?.contract?.status as ContractStatusType] || []
        : []
      if (!contractActions.includes(AppActions.Edit))
        history.push(generatePath(APP_PATHS.application.status, { applicationId }))
      if (contractOwner) {
        setHeaderText(
          t(
            contractData?.contract?.type === ContractType.IntroducerReference
              ? 'companyPartnerApplication'
              : 'companyBusinessApplication',
            {
              companyName: contractOwner.companyName,
            },
          ),
        )
      }
    } else {
      setHeaderText(t('newBusinessApplication', 'New business application'))
    }
  }, [applicationId, contractData, contractLoading, history])

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      isDomiciliary ? DomLegalEntityValidationSchema : LegalEntityValidationSchema,
    ),
  })
  const { formState, watch, setValue } = methods
  const firmaName = watch('name')
  const entityType = watch('entityType')

  useBeforeUnload(methods.formState.isDirty, t('You have unsaved changes, are you sure?'))

  const handleBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (isFromDirectorForm) {
        return history.push(
          generatePath(APP_PATHS.application.beneficialOwners.list, {
            [PATH_PARAMS.applicationId]: +applicationId,
          }),
        )
      }
      if (formState.isDirty) {
        isModalDialogOpen(true)
        return
      }
      if (goBackPath) {
        history.push(goBackPath)
        goBackPathVar('')
      } else {
        history.goBack()
      }
    },
    [formState.isDirty, goBackPath, isFromDirectorForm],
  )

  const onSubmit = useCallback(
    async (formData) => {
      const preparedInput: AddContractUboInputType = {
        ubo: {
          uboType: UboType.LegalEntity,
          ownershipType: UboStakeType.Percent25OrMore,
          shareSize: formData.ownershipPercent,
          legalEntity: {
            legalEntityType: formData.entityType,
            companyName: formData.name,
            companyRegistrationId: formData.registartionNumber,
            companyRegistrationDate: extractDateValue(formData.registrationDate),
            address: {
              line1: formData.streetAddress,
              city: formData.city,
              zip: formData.postalCode,
              country: formData.country,
              additionalDetails: formData.additionalDetailsOptional,
            },
          },
        },
      }

      if (
        [
          UboLegalEntityType.OperatingQuotedStockExchange,
          UboLegalEntityType.ActiveHolding,
          UboLegalEntityType.AssociationCharity,
          UboLegalEntityType.Bank,
          UboLegalEntityType.SoleProprietor,
          UboLegalEntityType.Foundation,
          UboLegalEntityType.Unknown,
          UboLegalEntityType.Trust,
        ].includes(entityType)
      ) {
        if (uboId) {
          // case when we adding owner for Legal Entity
          const result = await addUboOwnerMutation({
            variables: {
              contractId: applicationId,
              uboId,
              ubo: preparedInput.ubo,
            },
            refetchQueries: [
              {
                query: GetUbosListDocument,
                variables: { id: +applicationId },
              },
            ],
            awaitRefetchQueries: true,
          })
          isUnsaveFormData(false)
          history.push(
            generatePath(APP_PATHS.application.beneficialOwners.newUboOwner, {
              applicationId,
              [PATH_PARAMS.uboId]: result?.data?.addUboOwner?.id as string,
              [PATH_PARAMS.controllingPerson]: ControllingPersonsTypeEnum.managingDirector,
            }),
          )
        } else {
          // case when we adding owner 1-st level contract owner as Legal Entity
          const result = await addContractUboMutation({
            variables: {
              id: applicationId,
              input: preparedInput,
            },
            refetchQueries: [
              {
                query: GetUbosListDocument,
                variables: { id: +applicationId },
              },
            ],
            awaitRefetchQueries: true,
          })
          isUnsaveFormData(false)
          goBackPathVar(
            generatePath(APP_PATHS.application.beneficialOwners.edit, {
              applicationId,
              uboId: result?.data?.addContractUbo?.id as string,
              [PATH_PARAMS.controllingPerson]: controllingPerson,
            }),
          )
          history.push(
            generatePath(APP_PATHS.application.beneficialOwners.newUboOwner, {
              applicationId,
              uboId: result?.data?.addContractUbo?.ubo?.id as string,
              [PATH_PARAMS.controllingPerson]: ControllingPersonsTypeEnum.managingDirector,
            }),
          )
        }
        return
      } else if (uboId) {
        // case when we adding owner for Legal Entity
        const result = await addUboOwnerMutation({
          variables: {
            contractId: applicationId,
            uboId,
            ubo: preparedInput.ubo,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        })
        isUnsaveFormData(false)
        history.push(
          generatePath(APP_PATHS.application.beneficialOwners.uboList, {
            applicationId,
            uboId: result?.data?.addUboOwner?.id as string,
          }),
        )
      } else {
        // case when we adding owner 1-st level contract owner as Legal Entity
        const result = await addContractUboMutation({
          variables: {
            id: applicationId,
            input: preparedInput,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        })
        isUnsaveFormData(false)
        history.push(
          generatePath(APP_PATHS.application.beneficialOwners.uboList, {
            applicationId,
            uboId: result?.data?.addContractUbo?.ubo?.id,
          }),
        )
      }
    },
    [entityType, applicationId, uboId, controllingPerson],
  )

  const onEditSubmit = useCallback(
    async (formData) => {
      const preparedInput: AddContractUboInputType = {
        ubo: {
          uboType: UboType.LegalEntity,
          ownershipType: UboStakeType.Percent25OrMore,
          shareSize: formData.ownershipPercent,
          legalEntity: {
            legalEntityType: formData.entityType,
            companyName: formData.name,
            companyRegistrationId: formData.registartionNumber,
            companyRegistrationDate: extractDateValue(formData.registrationDate),
            address: {
              line1: formData.streetAddress,
              city: formData.city,
              zip: formData.postalCode,
              country: formData.country,
              additionalDetails: formData.additionalDetailsOptional,
            },
          },
        },
      }

      if (uboId && ownedUboId) {
        await updateUboOwnerMutation({
          variables: {
            contractId: applicationId,
            ownedUboId: ownedUboId,
            uboOwnerId: uboId,
            ubo: preparedInput.ubo,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        })
      } else {
        await updateContractUboMutation({
          variables: {
            contractUboId: uboId,
            input: preparedInput,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        })
      }
      isUnsaveFormData(false)
      if (goNextPath) {
        if (isFromDirectorForm) {
          goBackPathVar(history.location.pathname)
          goNextPathVar('')
        }
        history.push(goNextPath)
      } else {
        goBackPathVar('')
        history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
      }
    },
    [uboId, ownedUboId, applicationId, history, goNextPath, isFromDirectorForm],
  )

  useEffect(() => {
    if (formState.isDirty) {
      isUnsaveFormData(true)
    }
  }, [formState.isDirty])

  //mounts total Share already Contract have when new ubo add
  useEffect(() => {
    if (newRecord && !uboId) {
      totalShare(shareSizeSummary)
    }
  }, [contractData])

  // mounts data when edit existing owner
  useEffect(() => {
    if (!contractUboData && uboId && !ownedUboId && !newRecord) {
      ;(async () => {
        await GetUboDetailsQuery()
      })()
    }
    if (contractUboData?.getContractUbo && !newRecord && contractData) {
      const shareSizeSummary =
        contractData?.contract?.ubos?.reduce(
          (acc, val) => acc + (val?.id !== uboId ? val?.shareSize ?? 0 : 0),
          0,
        ) || 0
      totalShare(shareSizeSummary)
      const { shareSize, ubo } = contractUboData.getContractUbo
      const legalUbo = ubo?.entity as LegalEntity

      setValue('entityType', legalUbo.legalEntityType)
      setValue('name', legalUbo.companyName)
      setValue('country', legalUbo.address?.country)
      setValue('streetAddress', legalUbo.address?.line1)
      setValue('additionalDetailsOptional', legalUbo.address?.additionalDetails)
      setValue('postalCode', legalUbo.address?.zip)
      setValue('city', legalUbo.address?.city)
      setValue('registartionNumber', legalUbo.companyRegistrationId)
      // setValue('registrationDate', legalUbo.companyRegistrationDate)
      setValue('registrationDate', legalUbo.companyRegistrationDate)
      setValue('ownershipPercent', shareSize)
    }
  }, [contractUboData, contractData, formState.isDirty])

  // mounts data when edit existing contract Ubo Owner
  useEffect(() => {
    if (!uboOwnersDetails && uboId && ownedUboId) {
      ;(async () => {
        await GetUboOwnersDetailsQuery()
      })()
    }

    if (uboOwnersDetails?.getUboOwners && ownedUboId) {
      const uboData = uboOwnersDetails?.getUboOwners.find((data) => data?.id === uboId)

      if (!uboData) return
      const { ubo, shareSize, ownedUboId } = uboData as UboOwner
      const shareSizeSummary =
        uboOwnersDetails?.getUboOwners?.reduce(
          (acc, val) =>
            acc + (val?.ownedUboId === ownedUboId && val?.id !== uboId ? val?.shareSize ?? 0 : 0),
          0,
        ) || 0
      totalShare(shareSizeSummary)
      const legalUbo = ubo?.entity as LegalEntity

      setValue('entityType', legalUbo.legalEntityType)
      setValue('name', legalUbo.companyName)
      setValue('country', legalUbo.address?.country)
      setValue('streetAddress', legalUbo.address?.line1)
      setValue('additionalDetailsOptional', legalUbo.address?.additionalDetails)
      setValue('postalCode', legalUbo.address?.zip)
      setValue('city', legalUbo.address?.city)
      setValue('registartionNumber', legalUbo.companyRegistrationId)
      // setValue('registrationDate', legalUbo.companyRegistrationDate)
      setValue('registrationDate', legalUbo.companyRegistrationDate)
      setValue('ownershipPercent', shareSize)
    }
  }, [uboOwnersDetails, formState.isDirty])

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <ConfirmationModal />
      <FormProvider {...methods}>
        <Box className={'form'}>
          <form
            onSubmit={methods.handleSubmit(!newRecord ? onEditSubmit : onSubmit)}
            id="forNextFocus"
            onKeyDown={focusKeyPressNext}
          >
            <Box hidden={!newRecord}>{children}</Box>

            <GridRow>
              <FormAutocompleteSelect
                name="entityType"
                label={t('entityType', 'Entity Type')}
                data={uboLegalEntityTypesList}
              />
            </GridRow>

            <Box mt={3}>
              <Typography variant={'h3'} className={'title'}>
                {t('entityDetails', 'Entity details')}
              </Typography>
            </Box>

            <Box>
              <Box className={'group'}>
                <Box>
                  <GridRow>
                    <FormControlledTextField
                      label={t('name', 'Name')}
                      name="name"
                      type="text"
                      fullWidth
                      required={false}
                      data-test="legalEntityName"
                    />
                  </GridRow>

                  <AddressAutocomplete props={registeredAddressEmptyProps} />

                  <GridRow>
                    <FormControlledTextField
                      label={t('commercialNumber', 'Commercial Registration Number')}
                      name="registartionNumber"
                      type="text"
                      fullWidth
                      required={false}
                      data-test="commercialNumber"
                    />
                  </GridRow>

                  <GridRow>
                    <FormDatePickerField
                      name="registrationDate"
                      label={t('registrationDate', 'Date of Registration')}
                      noFuture
                    />
                  </GridRow>
                </Box>

                <Box mt={4}>
                  <Typography variant={'h3'} className={'title'}>
                    {t('ownershipDetails', 'Ownership details')}
                  </Typography>
                </Box>

                <GridRow>
                  <FormControlledTextField
                    label={t('percentageOfOwnership', 'Percentage of ownership')}
                    name="ownershipPercent"
                    type="text"
                    fullWidth
                    required={false}
                    className={'inputWithSymbol'}
                    data-test="ownershipPercent"
                  />
                </GridRow>
              </Box>
            </Box>

            <GridRow>
              <Box className={classes.info} hidden={!!uboId || !entityType}>
                {entityType !== 'operatingNotQuotedStockExchange' &&
                  entityType !== 'domiciliaryHolding' && (
                    <CommonTipItem
                      value={
                        firmaName
                          ? t('addDirectorEntityFirmName', {
                              name: firmaName,
                            })
                          : t('addDirectorEntity')
                      }
                      iconComponent={<InformationIcon />}
                    />
                  )}

                {entityType === 'domiciliaryHolding' && (
                  <CommonTipItem
                    value={
                      firmaName
                        ? t('addOwnersEntityFirmName', {
                            name: firmaName,
                          })
                        : t('addOwnersEntity')
                    }
                    iconComponent={<InformationIcon />}
                  />
                )}

                {entityType === 'operatingNotQuotedStockExchange' && (
                  <CommonTipItem
                    value={
                      firmaName
                        ? t('addOwnersOrDirectorEntityFirmName', { name: firmaName })
                        : t('addOwnersOrDirectorEntity')
                    }
                    iconComponent={<InformationIcon />}
                  />
                )}
              </Box>
            </GridRow>

            <Box className={'buttonsBox'}>
              <Box className={'secondaryButton'}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={handleBack}
                >
                  {t('back', 'Back')}
                </Button>
              </Box>

              <LoadingButton
                className={'button'}
                // loading={mutationLoading}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                data-test={newRecord ? 'submitAndProceed' : 'saveChanges'}
              >
                {newRecord
                  ? t('submitAndProceed', 'Submit and proceed')
                  : t('saveChanges', 'Save changes')}
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </FormProvider>
    </>
  )
}
