import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { isUndefined } from 'lodash'
import React, { FC, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NoYes } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    ...theme.typography.h6,
    flex: '0 0 50%',
    color: '#000',
    maxWidth: '100%',
    textTransform: 'none',
    minHeight: 32,
    padding: theme.spacing(0.5),
    '&.Mui-selected': {
      backgroundColor: 'white',
    },
  },
  hiddenTab: {
    display: 'none',
  },
  tabBar: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#F0F0F0',
    boxShadow: 'none',
    zIndex: 8,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-root': {
      minHeight: 32,
    },
  },
  error: {
    position: 'relative',
    top: '-10px',
    marginBottom: '-10px',
    fontSize: '0.75rem',
    color: '#EF2828',
    paddingLeft: theme.spacing(1.5),
  },
}))

export const YesNoSelector: FC<{
  name: string
  disabled?: boolean
  label: string
  mt?: number
  mb?: number
  yesOption?: NoYes
  noOption?: NoYes
}> = ({ name, disabled = false, label, mt = 0, mb = 0, yesOption, noOption }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { register, watch, setValue, errors } = useFormContext()

  const value = watch(name)
  const error = errors[name]

  const handleChange = useCallback(
    (_: React.ChangeEvent<unknown>, value: number) => {
      setValue(name, value, {
        shouldValidate: true,
        shouldDirty: true,
      })
    },
    [name, setValue],
  )

  useEffect(() => {
    register(name)
  }, [register])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={16}
      mt={mt}
      mb={mb}
      data-test={`autotest-${name}`}
    >
      <Typography>{label}</Typography>
      <AppBar position="static" className={classes.tabBar}>
        <Tabs
          name={name}
          value={isUndefined(value) ? null : value}
          onChange={handleChange}
          indicatorColor="secondary"
        >
          <Tab
            value={noOption || false}
            label={t('no', 'No')}
            className={classes.tab}
            disabled={disabled}
          />
          <Tab
            value={yesOption || true}
            label={t('yes', 'Yes')}
            className={classes.tab}
            disabled={disabled}
          />
          <Tab value={null} className={classes.hiddenTab} />
        </Tabs>
      </AppBar>
      {error && (
        <Typography variant="body1" className={classes.error}>
          {error.message}
        </Typography>
      )}
    </Box>
  )
}
