import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  ALL_ARABIC_REGEXP,
  CYRILIC_REGEXP,
  MAX_ZIPCODE_LENGTH,
  MIN_LENGTH,
  MIN_ZIPCODE_LENGTH,
  MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE,
  NUMBER_AND_LATINICA_ONLY_REGEXP,
  POSTAL_CODE_MATCH,
  POSTAL_CODE_REQUIRED,
  SPECIAL_SYMBOLS_REGEXP,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import i18n from '../../i18n'
import {
  AdditionalDetailsOptionalCorrespondenceInputSchema,
  AdditionalDetailsOptionalInputSchema,
  AddressCheckForbiddenCountriesInputSchema,
  CountriesOfOperationSchema,
  PhoneNumberInputSchema,
  PostalCodeInputSchema,
  StreetAddressInputSchema,
} from '../common'
import { CompanyInformationInputSchema } from './CompanyInformationInputSchema'
import { ContractPersonInputSchema } from './ContractPersonInputSchema'
import { GeneralQuestionsInputSchema } from './GeneralQuestionsInputSchema'
import { PartnersInputSchema } from './PartnersInputSchema'
import { RegulatorInformationImputSchema } from './RegulatorInformationImputSchema'
import { TransactionalQuestionsInputSchema } from './TransactionalQuestionsInputSchema'
import { UBOResidencyInputSchema } from './UBOResidencyInputSchema'
import { CardPaymentAndApmSchema } from './CardPaymentAndApmSchema'
import { ExpectedVolumesInputSchema } from './ExpectedVolumesSchema'

export const PreAssessmentOnboardingInputSchema = yup.lazy((virtualFormFields) =>
  yup
    .object()
    .shape({
      correspondenceRequired: yup.boolean().notRequired(),
      streetAddressCorrespondence: yup
        .string()
        .test(
          'notEmptyStreetAddressCorrespondence',
          i18n.t('streetAddressIsRequired', 'Street Address is required'),
          function () {
            return (
              !isEmpty(this.parent.streetAddressCorrespondence) ||
              !this.parent.correspondenceRequired
            )
          },
        ),
      postalCodeCorrespondence: yup
        .string()
        .when('correspondenceRequired', {
          is: true,
          then: yup
            .string()
            .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
            .test(
              'minLength',
              POSTAL_CODE_REQUIRED,
              (val) => !!val && val?.length >= MIN_ZIPCODE_LENGTH,
            ),
        })
        .when('correspondenceRequired', {
          is: true,
          then: yup.string().test('maxLength', POSTAL_CODE_REQUIRED, (val) => {
            return !!val && val?.length <= MAX_ZIPCODE_LENGTH
          }),
        })
        .when('correspondenceRequired', {
          is: true,
          then: yup
            .string()
            .test('notEmptyPostalCodeCorrespondence', POSTAL_CODE_REQUIRED, function () {
              return (
                !isEmpty(this.parent.postalCodeCorrespondence) ||
                !this.parent.correspondenceRequired
              )
            }),
        })
        .when('correspondenceRequired', {
          is: true,
          then: yup.string().matches(NUMBER_AND_LATINICA_ONLY_REGEXP, POSTAL_CODE_MATCH),
        }),
      cityCorrespondence: yup
        .string()
        .when('correspondenceRequired', {
          is: true,
          then: yup.string().required(i18n.t('cityIsRequired', 'City is required')),
        })
        .when('correspondenceRequired', {
          is: true,
          then: yup
            .string()
            .test(
              'minSymbols',
              MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE(MIN_LENGTH),
              (valueInput) => !!valueInput && valueInput?.length > 1,
            )
            .test('cityInvalid', i18n.t('invalidCity', 'Invalid city'), (inputValue) => {
              const cyrillicValidationRegex = RegExp(CYRILIC_REGEXP)
              const allArabicValidationRegexp = RegExp(ALL_ARABIC_REGEXP)
              const specialSymbols = RegExp(SPECIAL_SYMBOLS_REGEXP)

              let testResult = true
              if (typeof inputValue === 'string') {
                testResult =
                  !cyrillicValidationRegex.test(inputValue) &&
                  !allArabicValidationRegexp.test(inputValue) &&
                  !specialSymbols.test(inputValue)
              }
              return testResult
            }),
        }),
    })
    .concat(CompanyInformationInputSchema)
    .concat(RegulatorInformationImputSchema)
    .concat(ContractPersonInputSchema)
    .concat(AddressCheckForbiddenCountriesInputSchema)
    .concat(PostalCodeInputSchema)
    .concat(StreetAddressInputSchema)
    .concat(AdditionalDetailsOptionalInputSchema)
    .concat(PhoneNumberInputSchema)
    .concat(GeneralQuestionsInputSchema)
    .concat(TransactionalQuestionsInputSchema)
    .concat(ExpectedVolumesInputSchema)
    .concat(CardPaymentAndApmSchema(virtualFormFields))
    .concat(PartnersInputSchema)
    .concat(UBOResidencyInputSchema)
    .concat(AdditionalDetailsOptionalCorrespondenceInputSchema)
    .concat(CountriesOfOperationSchema),
)

export type PreAssessmentOnboardingInput = yup.Asserts<typeof PreAssessmentOnboardingInputSchema>
