import { addDays, addMonths, format, isBefore, nextDay, parse } from 'date-fns'
import { StandingOrder } from '../../graphql'
import i18n from '../../i18n'
import { currencyFormat, getCurrencySign } from '../../utils'
import { head, toNumber } from 'lodash'
import { TFunction } from 'react-i18next'
import { StandingOrdersOrderBy } from './types'

export const standingOrder2FADescription = (
  t: TFunction<'translation', undefined>,
  standingOrder: StandingOrder,
) =>
  standingOrder.amount || standingOrder.payoutAmount
    ? t('standingOrder2FALabel', {
        cronDescription: standingOrder.cronDescription,
        name: standingOrder.template?.beneficiary.name,
        amount: standingOrder.amount
          ? `${getCurrencySign(standingOrder.account.currency)} ${currencyFormat(
              toNumber(standingOrder.amount),
            )}`
          : `${getCurrencySign(standingOrder.payoutCurrency)} ${currencyFormat(
              toNumber(standingOrder.payoutAmount),
            )}`,
      })
    : t('sweepingOrder2FALabel', {
        cronDescription: standingOrder.cronDescription,
        name: standingOrder.template?.beneficiary.name,
        amount: standingOrder.amount
          ? `${getCurrencySign(standingOrder.account.currency)} ${currencyFormat(
              toNumber(standingOrder.amount),
            )}`
          : `${getCurrencySign(standingOrder.payoutCurrency)} ${currencyFormat(
              toNumber(standingOrder.payoutAmount),
            )}`,
      })

export const getNextDate = (data: StandingOrder | undefined | null, withTime = false) => {
  const cronDescription = data && data.cronDescription
  const firstPaymentDate = data && new Date(data.nextExecutionAt)

  const timeRegex = /(\d{2}:\d{2} [APap][Mm])/
  const dayRegex = /\b(sunday|monday|tuesday|wednesday|thursday|friday|saturday)\b/i
  const dayOfMonthRegex = /\bday (\d{1,2}) of the month\b/i

  if (!cronDescription) return '-'

  const extractedTime = cronDescription.match(timeRegex)
  const executionTimeStr = extractedTime && withTime ? ` at ${extractedTime[0]}` : ''

  if (firstPaymentDate && firstPaymentDate >= new Date()) {
    return format(firstPaymentDate, 'dd.MM.yyyy') + executionTimeStr
  }

  const today = new Date()

  if (cronDescription.includes('every day')) {
    const tomorrow = addDays(today, 1)
    const parsedTime = extractedTime && parse(extractedTime[1], 'hh:mm a', today)

    return parsedTime && isBefore(parsedTime, today)
      ? format(tomorrow, 'dd.MM.yyyy') + executionTimeStr
      : format(today, 'dd.MM.yyyy') + executionTimeStr
  } else if (cronDescription.match(dayRegex)) {
    const dayOfWeak = head(cronDescription.match(dayRegex))?.toLowerCase()
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const dayIndex = days.indexOf(dayOfWeak as string)

    return format(nextDay(today, dayIndex as Day), 'dd.MM.yyyy') + executionTimeStr
  } else if (cronDescription.match(dayOfMonthRegex)) {
    const targetDay = new Date(data?.nextExecutionAt).getDate()
    const nextDate = addMonths(new Date(), today.getDate() <= targetDay ? 1 : 0)

    return format(nextDate.setDate(targetDay), 'dd.MM.yyyy') + executionTimeStr
  }
  return '-'
}

export const getOrderIterate = (orderBy: StandingOrdersOrderBy) => {
  const weekDaysOrder = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  }

  switch (orderBy) {
    case StandingOrdersOrderBy.Beneficiary:
      return ['template.beneficiary.name']

    case StandingOrdersOrderBy.Amount:
      return ['amount']

    case StandingOrdersOrderBy.Frequency:
      return [
        // for sorting groups "daily", "weekly" and "monthly"
        (item: StandingOrder) => {
          if (item?.cronDescription?.includes('every day')) return 0
          if (item?.cronDescription?.includes('only on')) return 1
          if (item?.cronDescription?.includes('of the month')) return 2
          return 3
        },
        // for sorting "weekly" group
        (item: StandingOrder) => {
          if (item?.cronDescription?.includes('only on')) {
            const day = item?.cronDescription?.split(' ').pop()
            return weekDaysOrder[day as keyof typeof weekDaysOrder] ?? 7
          }
        },
        // for sorting "monthly" group
        (item: StandingOrder) => {
          if (item?.cronDescription?.includes('of the month')) {
            return item.cronDescription
          }
        },
      ]

    case StandingOrdersOrderBy.NextPayment:
      return (order: StandingOrder) => {
        const dateString = getNextDate(order as StandingOrder)
        const dateObject = parse(dateString, 'dd.MM.yyyy', new Date())

        return dateObject.getTime()
      }

    case StandingOrdersOrderBy.Status:
      return (order: StandingOrder) => {
        if (order?.active && !Boolean(order?.cancellationAction)) return 0
        if (Boolean(order?.cancellationAction)) return 1
        return 2
      }
  }
}

export const getAmmount = (standingOrderData: StandingOrder, accountCurrency: string) =>
  standingOrderData.amount
    ? currencyFormat(standingOrderData.amount, accountCurrency)
    : standingOrderData.payoutAmount
    ? currencyFormat(standingOrderData.payoutAmount, standingOrderData.payoutCurrency)
    : i18n.t('sweepAccount', 'Sweep account')
