import React, { FC, memo } from 'react'
import { isEqual } from 'lodash'
import { List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Address, Individual } from '../../../graphql'
import { getProperty } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  listAddress: {
    padding: 0,
    marginTop: '10px',
  },
  listItem: {
    flexWrap: 'wrap',
    padding: 0,
    '& .MuiListItemText-primary': {
      ...theme.typography.subtitle2,
      color: '#595959',
    },
    '& .MuiListItemText-secondary': {
      ...theme.typography.body1,
      color: '#000',
    },
  },
  listLabel: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
}))

type RenderAddressProps = {
  data: Individual
  oldCountryValue: string | undefined
  formPerson: Individual
}

const RenderAddressComponent: FC<RenderAddressProps> = ({ data, formPerson, oldCountryValue }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const addressData = data.address
  return (
    <>
      {addressData &&
        Object.keys(addressData)
          .filter((key) => key !== 'id' && getProperty(addressData, key as keyof Address))
          .map((key, i) => (
            <List dense={false} className={i ? classes.listAddress : ''} key={`${key}${i}`}>
              <ListItem className={classes.listItem} key={1}>
                <Typography variant="h3" className={classes.listLabel}>
                  {t(
                    key === 'country' ? 'countryOfResidenceSmallLetter' : (key as keyof Individual),
                  )}
                </Typography>
                <ListItemText
                  primary={`${t('currentValue')}:`}
                  secondary={
                    key === 'country'
                      ? oldCountryValue
                      : (formPerson.address && formPerson?.address[key as keyof Address]) || ''
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem} key={2}>
                <ListItemText
                  primary={`${t('newValue', 'New value')}:`}
                  secondary={getProperty(addressData, key as keyof Address) || ''}
                />
              </ListItem>
            </List>
          ))}
    </>
  )
}

export const RenderAddress = memo(RenderAddressComponent, isEqual)
