import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  COMPANY_NAME_REGEX,
  MAX_LEGAL_NAME_LENGTH,
  MAX_SYMBOLS_LIMIT_MESSAGE,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
  NUMBER_AND_LATINICA_AND_SYMBOLS,
  NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
  NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
  URL_IS_REQUIRED_MESSAGE,
  URL_WEB_SITE_REGEXP,
} from '../../constants/validations'
import i18n from '../../i18n'

const PartnerSchema = yup.object().shape({
  name: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .min(MIN_LENGTH, NUMBER_AND_LATINICA_AND_SYMBOLS)
    .max(MAX_LEGAL_NAME_LENGTH, MAX_SYMBOLS_LIMIT_MESSAGE(MAX_LEGAL_NAME_LENGTH))
    .matches(COMPANY_NAME_REGEX, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS),
  country: yup
    .mixed()
    .test(
      'countryNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  bankCountry: yup
    .mixed()
    .test(
      'countryNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  businessSector: yup
    .mixed()
    .test(
      'businessSectorNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  paymentPurpose: yup
    .mixed()
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  paymentPurposeCustomVal: yup
    .string()
    .test(
      'outboundTransfersCustomValid',
      NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
      function () {
        return (
          this.parent.outboundTransfersDescriptionCustomVal?.match(
            NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS,
          ) || !this.parent.isOtherSelected
        )
      },
    ),
  website: yup
    .string()
    .required(URL_IS_REQUIRED_MESSAGE)
    .matches(URL_WEB_SITE_REGEXP, URL_IS_REQUIRED_MESSAGE),
})

export const PartnersInputSchema = yup.object().shape({
  partnersIncoming: yup
    .array()
    .of(PartnerSchema)
    .min(
      1,
      i18n.t(
        'requiredCounterpartiesErrMessage',
        'It is mandatory to either add counterparties or choose the option “No counterparties”',
      ),
    ),
  partnersOutgoing: yup
    .array()
    .of(PartnerSchema)
    .min(
      1,
      i18n.t(
        'requiredCounterpartiesErrMessage',
        'It is mandatory to either add counterparties or choose the option “No counterparties”',
      ),
    ),
})
