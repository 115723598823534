import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
  URL_IS_REQUIRED_MESSAGE,
  URL_WEB_SITE_REGEXP,
} from '../../constants/validations'

export const UrlsInputSchema = yup.object().shape({
  urls: yup
    .mixed()
    .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (sites) => {
      return !sites.some((site: Record<string, string>) => !site.value) && !isEmpty(sites)
    })
    .test('isWebsite', URL_IS_REQUIRED_MESSAGE, (sites) => {
      return sites.every((site: Record<string, string>) => URL_WEB_SITE_REGEXP.test(site.value))
    })
    .test('webSiteMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (sites) => {
      return !sites.some(
        (site: Record<string, string>) =>
          site.value.length > PRE_ASSESSMENT_COMMON_FIELD_MAX && !isEmpty(sites),
      )
    }),
})
