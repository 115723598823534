import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { AuthPersonsSelectType, ControlledTextFieldProps } from '../../../types'
import { filterOptionsAny } from '../../../utils'
import { Box, InputAdornment } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SEARCH_ICON from '../../../assets/images/icons/icon-search.svg?react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(2.5, 0),
    maxWidth: '100%',
  },
  noAnimated: {
    '& .MuiInputLabel-animated': {
      transition: 'none',
    },
  },
  fakeInputWrap: {
    marginTop: 20,
  },
  formFieldDocs: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 2,
    margin: theme.spacing(0, 0, 0),
    maxWidth: '100%',
    minWidth: 222,
    background: '#fff',
    boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
    '& .MuiTextField-root:before': {},
    '& .MuiInput-underline:before': {
      display: 'block',
      border: 0,
      content: '""',
      backgroundPosition: '0 50%',
      backgroundRepeat: 'no-repeat',
      width: 18,
      height: 18,
      top: 0,
      bottom: 0,
      margin: 'auto',
      left: theme.spacing(3),
      right: 'unset',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
      width: '100%',
      paddingRight: 0,
    },
    '& .MuiInputBase-input': {
      width: '100%',
      height: '48px',
      boxSizing: 'border-box',
      border: '1px solid',
      borderColor: '#ccc',
      padding: `${theme.spacing(1, 1, 1, 5.75)} !important`,
      margin: theme.spacing(1),
      '&:hover': {
        borderColor: '#000',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
    '& + .MuiAutocomplete-popperDisablePortal': {
      minWidth: 222,
      boxShadow: '0px 14px 14px 0 rgba(0, 0, 0, 0.2)',
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
        position: 'absolute',
        left: 0,
        top: 64,
      },
    },
    '& + .MuiAutocomplete-popperDisablePortal .MuiAutocomplete-option': {
      width: '100%',
      height: 48,
      color: '#000',
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: theme.typography.subtitle2,
      lineHeight: '1.5rem',
      '&:hover': {
        color: '#434343',
      },
    },
  },
  input: {
    width: '100%',
  },
  inputDocs: {
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.spacing(1.5, 0, 1.5, 1.5),
      lineHeight: '1.1876em',
      display: 'block',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -3px) scale(0.857)',
    },
  },
  autocompleteListWrap: {
    marginTop: -4,
    // marginLeft: 1,
    background: '#fff',
    boxShadow: '0 10px 8px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: 0,
    '& .MuiAutocomplete-option': {
      padding: 12,
      ...theme.typography.body1,
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      position: 'relative',
      paddingRight: 40,
      backgroundColor: 'transparent',
      '&::after': {
        position: 'absolute',
        top: 16,
        right: 16,
        content: '""',
        width: 14,
        height: 8,
        borderLeft: '2px solid',
        borderBottom: '2px solid',
        transform: 'rotate(-45deg)',
      },
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      color: '#434343',
      backgroundColor: theme.palette.action.hover,
    },
  },
  adornment: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    right: 12,
    '& svg': {
      fill: '#000',
    },
  },
  searchIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    left: theme.spacing(3),
    '& svg': {
      fill: '#000',
    },
  },
}))

interface FormAutocompleteSelectProps {
  name: string
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  data: Array<any> | AuthPersonsSelectType[]
  disableClearable?: boolean
  readOnly?: boolean
  isDisabled?: boolean
  defaultVal?: string | number
  searchLabel?: string | undefined
}
const defaultRules = {} //{ required: true }

const FormAutocompleteSelectDocsComponent: React.FC<
  FormAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  disableClearable,
  data,
  shouldValidateParam,
  readOnly = false,
  isDisabled = false,
  defaultVal,
  rules,
  searchLabel,
  ...rest
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const classes = useStyles()
  const { t } = useTranslation()
  const { errors, register, setValue, watch } = useFormContext()
  const error = errors ? errors[name || 'FormAutocompleteSelect'] : null
  const value = watch(name)

  useEffect(() => {
    register(name, { ...defaultRules, ...(rules ?? {}) })
    if (defaultVal) {
      setValue(name, defaultVal)
    }
  }, [register, name, rules, setValue, defaultVal])

  return (
    <>
      {isOpened && (
        <Box className={classes.fakeInputWrap}>
          <TextField
            className={classes.input}
            name={name}
            error={!!error}
            helperText={error ? error.message : null}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end" className={classes.adornment}>
                  <KeyboardArrowUpIcon />
                </InputAdornment>
              ),
            }}
            {...rest}
          />
        </Box>
      )}

      <Autocomplete
        id="autocompleteListWrap"
        disabled={isDisabled}
        key={value}
        className={`${isOpened ? classes.formFieldDocs : classes.formField} ${classes.noAnimated}`}
        classes={{
          paper: classes.autocompleteListWrap,
        }}
        disableClearable={disableClearable || true}
        options={data}
        open={isOpened}
        getOptionLabel={(option) => option.label}
        getOptionDisabled={(option) => !option.verified}
        getOptionSelected={(option, value) => {
          return option?.key === value?.key
        }}
        onClickCapture={() => {
          if (!isOpened && !isDisabled) {
            setIsOpened(true)
          }
        }}
        onAbort={() => {
          setIsOpened(false)
        }}
        onBlur={() => {
          setIsOpened(false)
        }}
        onChange={(_, option) => {
          setIsOpened(false)
          return setValue(name, option ? option.key : null, {
            shouldValidate: shouldValidateParam ?? true,
            shouldDirty: true,
          })
        }}
        value={data.find((v) => v.key === value)}
        renderInput={(params) =>
          isOpened ? (
            <Box data-test="search">
              <InputAdornment position="end" className={classes.searchIcon}>
                <SEARCH_ICON />
              </InputAdornment>
              <TextField
                className={classes.inputDocs}
                autoFocus
                {...params}
                {...rest}
                label={''}
                placeholder={searchLabel || t('search', 'Search')}
              />
            </Box>
          ) : (
            <TextField
              className={classes.input}
              name={name}
              {...params}
              error={!!error}
              helperText={error ? error.message : null}
              {...rest}
              inputProps={{ ...rest.inputProps, ...params.inputProps, readOnly }}
            />
          )
        }
        filterOptions={filterOptionsAny}
        popupIcon={<ExpandMoreIcon />}
        autoHighlight
      />
    </>
  )
}

export const FormAutocompleteSelectDocs = React.memo(FormAutocompleteSelectDocsComponent, isEqual)
