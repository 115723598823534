import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Table,
  TableContainer,
  Theme,
  Typography,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { isEqual } from 'lodash'
import React, { FC, useCallback } from 'react'
import { documentsAccordionsType } from '../../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    flexDirection: 'row-reverse',
    padding: theme.spacing(0, 1.25),
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(3.25),
    },
  },
  accordionDetails: {
    padding: 0,
  },
  container: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    '& th': {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
}))

type PropsType = {
  title: string
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<documentsAccordionsType>>
  resetAccordionTab: () => void
  children: React.ReactNode
}

const CustomAccordionComponent: FC<PropsType> = ({
  title,
  expanded,
  setExpanded,
  resetAccordionTab,
  children,
}) => {
  const classes = useStyles()

  const onChange = useCallback(() => {
    setExpanded((prevState) =>
      prevState === documentsAccordionsType.pinnedDoc
        ? documentsAccordionsType.everythingElse
        : documentsAccordionsType.pinnedDoc,
    )
    resetAccordionTab()
  }, [])

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandLessIcon />} className={classes.summary}>
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <TableContainer className={classes.container}>
          <Table>{children}</Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export const CustomAccordion = React.memo(CustomAccordionComponent, isEqual)
