import React, { FC } from 'react'
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useParams } from 'react-router-dom'
import { LimitedAccessRight } from '../../graphql'
import { useDetermineUserRights } from '../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { CmTabName } from './ContractManagement'

const useStyles = makeStyles(() => ({
  itemControlPanel: {
    width: '100%',
    position: 'relative',
    margin: '0 0 20px 0',
    '& .MuiTab-labelIcon': {
      minHeight: '40px',
    },
  },
  titleTabs: {
    fontSize: '16px',
    textTransform: 'initial',
    fontWeight: 'bold',
    padding: '0',
    margin: '0 32px 0 0',
    maxWidth: 'auto',
    minWidth: 'auto',
    color: '#434343',
    opacity: 1,
    '&.Mui-selected': {
      opacity: 1,
      color: '#000000',
      pointerEvents: 'none',
    },
  },
  badge: {
    '& .MuiBadge-root': {
      paddingRight: 35,
    },
  },
}))

export const ContractManagementTabs: FC<{
  handleChange: (_: React.SyntheticEvent<EventTarget>, newValue: number) => void
}> = ({ handleChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.contractManagementTab]: contractManagementTab,
  } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  return (
    <Box className={classes.itemControlPanel}>
      <Tabs value={contractManagementTab} onChange={handleChange} data-test="tabBar">
        {!userRights?.isIndividualContractOwner && (
          <Tab
            value={CmTabName.BusinesDetails}
            label={t('businessDetails', 'Business Details')}
            className={classes.titleTabs}
            component={Link}
            to={generatePath(APP_PATHS.dashboard.contractManagement.root, {
              applicationId,
              [PATH_PARAMS.contractManagementTab]: CmTabName.BusinesDetails,
            })}
            data-test="businessDetailsTab"
          />
        )}
        {userRights?.isIndividualContractOwner && (
          <Tab
            value={CmTabName.PersonalProfile}
            label={t('personalProfile', 'Personal profile')}
            className={classes.titleTabs}
            component={Link}
            to={generatePath(APP_PATHS.dashboard.contractManagement.root, {
              applicationId,
              [PATH_PARAMS.contractManagementTab]: CmTabName.PersonalProfile,
            })}
          />
        )}
        {!isViewOnly && (
          <Tab
            value={CmTabName.TerminateContract}
            label={t('terminateContract', 'Terminate contract')}
            className={classes.titleTabs}
            component={Link}
            to={generatePath(APP_PATHS.dashboard.contractManagement.root, {
              applicationId,
              [PATH_PARAMS.contractManagementTab]: CmTabName.TerminateContract,
            })}
            data-test="terminateContractTab"
          />
        )}
      </Tabs>
    </Box>
  )
}
