import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, makeStyles, Theme } from '@material-ui/core'
import DeleteIcon from '../../../../assets/images/icons/delete.svg?react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Document, LimitedAccessRight } from '../../../../graphql'
import { useDetermineUserRights } from '../../../../hooks'
import { PATH_PARAMS } from '../../../../routes/paths'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    display: 'flex',
    justifyContent: 'right',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
    },
  },
  markAsRead: {
    whiteSpace: 'nowrap',
    margin: '0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  delete: {
    marginLeft: theme.spacing(2),
    '&.checked': {
      '& .MuiButton-label': {
        opacity: '.3',
      },
    },
    '&.Mui-disabled': {
      '& .MuiButton-label': {
        opacity: '.3',
      },
    },
  },
}))

export const MarkRead: FC<{
  checkedAll: Document[]
  onMark: () => void
  onDelete: () => void
}> = ({ onMark, onDelete, checkedAll }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const check = checkedAll?.find((element) => element.isRead === false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  return (
    <Grid item className={classes.btn}>
      <Button
        className={classes.markAsRead}
        variant="contained"
        disableElevation
        color="default"
        onClick={onMark}
        disabled={!check}
        data-test="markAsRead"
      >
        {t('markAsRead', 'Mark as read')}
      </Button>
      {!isViewOnly && (
        <Button
          className={clsx(classes.delete, `${!checkedAll ? 'checked' : ''}`)}
          variant="contained"
          disableElevation
          color="default"
          onClick={onDelete}
          disabled={!checkedAll.length || !userRights?.isApplicant}
          data-test="delete"
        >
          <DeleteIcon />
        </Button>
      )}
    </Grid>
  )
}

export default MarkRead
