import React, { FC, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  FormHelperText,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { get, take } from 'lodash'
import { useTranslation } from 'react-i18next'
import { FormProvider, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { LinkButton } from '../../Common'
import { useCommonStyles } from './helpers/functions'
import { NewUboResidencyModal } from './RegulatoryInformation/NewUboResidencyModal'
import { UboTableRow } from './RegulatoryInformation/UboTableRow'
import { UboTableRowMobile } from './RegulatoryInformation/UboTableRowMobile'

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    margin: theme.spacing(2, 0),
  },
  table: {
    backgroundColor: theme.palette.background.paper,
  },
  numberColumn: {
    minWidth: 92,
  },
  error: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  addButton: {
    width: 250,
    margin: theme.spacing(1, 0, 1.5, 0),
  },
}))

const UboInformationComponent: FC = () => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const [addingNewUbo, setAddingNewUbo] = useState(false)
  const [editingUbo, setEditingUbo] = useState<{ i: number; countries: string[] } | undefined>()

  const methods = useFormContext()
  const { control, errors, setValue, trigger } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'uboResidencies',
  })
  const ubos: { countries: string[] }[] | undefined = useWatch({
    control,
    name: 'uboResidencies',
  })
  const error = get(errors, 'uboResidencies')

  const handleAdd = () => {
    append({ countries: [] })
    setAddingNewUbo(true)
  }

  const handleCloseNewUboModal = () => {
    addingNewUbo && remove(fields.length - 1)
    setAddingNewUbo(false)
    setEditingUbo(undefined)
    if (!!editingUbo) {
      setValue(`uboResidencies[${editingUbo.i}].countries`, editingUbo.countries)
    }
  }

  const submitNewUboModal = (value: string[]) => {
    if (!!editingUbo) {
      setValue(`uboResidencies[${editingUbo.i}].countries`, value)
    } else {
      const index = Math.max(fields.length - 1, 0)
      setValue(`uboResidencies[${index}].countries`, value)
    }

    setAddingNewUbo(false)
    setEditingUbo(undefined)
    trigger('uboResidencies').then()
  }

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        {t('controllingPersonsInformation', 'Controlling persons information')}
      </Typography>
      <Typography variant="h6" className={clsx(classes.titleSubBox, classes.description)}>
        {t(
          'controllingPersonsDescription',
          'For regulatory reasons we need to collect the country of residence for every controlling person (UBO and managing directors) of your company',
        )}
        :
      </Typography>
      <FormProvider {...methods}>
        <Hidden xsDown>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.numberColumn}>{t('person', 'Person')} #</TableCell>
                  <TableCell>{t('countryOfResidence', 'Country of residence')}</TableCell>
                  <TableCell width={168}>{t('actions', 'Actions')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {take(ubos, ubos?.length ?? 0).map((value, i) => {
                  return (
                    <UboTableRow
                      value={value}
                      index={i}
                      key={`${i}${value.countries[0]}`}
                      setEditingUbo={setEditingUbo}
                      remove={remove}
                    />
                  )
                })}
                <TableRow hover>
                  <TableCell>{fields.length + 1}</TableCell>
                  <TableCell />
                  <TableCell>
                    <LinkButton
                      name="uboResidencies"
                      data-test="autotest-addUboResidencies"
                      onClick={handleAdd}
                    >
                      + {t('add', 'Add')}
                    </LinkButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
        <Hidden smUp>
          <TableContainer>
            <Table className={classes.table}>
              <TableBody>
                {take(ubos, ubos?.length ?? 0).map((value, i) => {
                  return (
                    <UboTableRowMobile
                      value={value}
                      index={i}
                      key={`${i}${value.countries[0]}`}
                      setEditingUbo={setEditingUbo}
                      remove={remove}
                    />
                  )
                })}
                <TableRow>
                  <TableCell>{t('actions', 'Actions')}</TableCell>
                  <TableCell>
                    <LinkButton name="uboResidencies" onClick={handleAdd}>
                      + {t('add', 'Add')}
                    </LinkButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
        {!!error && (
          <Box mt={2} data-test="uboResidenceErr">
            <FormHelperText className={classes.error}>{error.message}</FormHelperText>
          </Box>
        )}
      </FormProvider>
      <NewUboResidencyModal
        editData={editingUbo}
        isOpen={addingNewUbo || !!editingUbo}
        onClose={handleCloseNewUboModal}
        onSubmit={submitNewUboModal}
      />
    </>
  )
}
export const UboInformation = React.memo(UboInformationComponent)
