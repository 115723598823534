import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { get, isEqual, take } from 'lodash'
import { Controller, FormProvider, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import IconInfo from '../../../../assets/images/icons/icon-info2.svg?react'
import { ContractProfileChangeAnswers, Maybe, PreAssessmentPartner } from '../../../../graphql'
import { KeyAndLabelSelectType, Partner, PartnersInformationEnum } from '../../../../types'
import { CommonTipItem, LinkButton, Toggler } from '../../../Common'
import ControlledTooltipWrapped from '../../../Common/Tooltips/ControlledTooltipWrapped'
import { makeTooltipTitleTextPartnersInformation, useCommonStyles } from '../helpers/functions'
import { NewPartnerModal } from '../NewPartnerModal'

const EMPTY_PARTNER: Partner = {
  name: '',
  country: '',
  bankCountry: '',
  businessSector: '',
  paymentPurpose: [],
  website: '',
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    borderTop: '0',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      '& .MuiTableCell-root': {
        padding: theme.spacing(1, 1.5),
      },
    },
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  errorWrap: {
    position: 'relative',
    height: theme.spacing(3),
  },
  error: {
    top: 0,
    color: '#EF2828',
    position: 'absolute',
    marginBottom: theme.spacing(1.5),
  },
  checkboxWrap: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1.5, 1.5),
    },
    marginLeft: 0,
    marginRight: 0,
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 22,
  },
  tooltipKey: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  lightDivider: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255,.3)',
    height: 3,
  },
}))

const EntitiesInfoComponent: FC<{
  type: PartnersInformationEnum
  paymentPurposeData: KeyAndLabelSelectType[]
  readOnly: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ type, paymentPurposeData, readOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const [addingNewPartner, setAddingNewPartner] = useState(false)
  const [defaultPartner, setDefaultPartner] = useState<Partner | undefined>()
  const [editingPartner, setEditingPartner] = useState<
    { i: number; partner: Partner } | undefined
  >()

  const methods = useFormContext()
  const { formState, control, errors, setValue, trigger, getValues, reset, watch } = methods

  const fieldName = `noPartners${type}Required`

  const companyName = watch('companyName')
  const country = watch('country')
  const businessSector = watch('businessSector')
  const website = watch('urls')?.[0]?.value || getValues('businessWebsiteUrl')

  const isNoPartnersChecked = getValues(fieldName)

  const notAllInfoAvailable = !companyName || !country || !businessSector || !website

  const { fields, append, remove } = useFieldArray({
    control,
    name: `partners${type}`,
  })
  const partners: Partner[] | undefined = useWatch({
    control,
    name: `partners${type}`,
  })
  const error = get(errors, `partners${type}`)

  const handleOnlyOnePartnerRequired = useCallback(
    (_, checked) => {
      setValue(fieldName, checked)
      if (checked) {
        const counterParty: Partner = {
          name: companyName,
          country: country,
          bankCountry: '',
          businessSector: businessSector,
          paymentPurpose: [],
          website,
        }
        append(counterParty)
        setDefaultPartner(counterParty)
        setAddingNewPartner(true)
      }
    },
    [
      companyName,
      country,
      businessSector,
      append,
      website,
      setAddingNewPartner,
      getValues,
      setDefaultPartner,
    ],
  )

  const handleClose = () => {
    addingNewPartner && remove(fields.length - 1)
    if (!editingPartner) {
      setValue(fieldName, false)
    }
    setAddingNewPartner(false)
    setEditingPartner(undefined)
    setDefaultPartner(undefined)
    if (!!editingPartner) {
      setValue(`partners${type}[${editingPartner.i}].name`, editingPartner.partner.name)
      setValue(`partners${type}[${editingPartner.i}].country`, editingPartner.partner.country)
      setValue(
        `partners${type}[${editingPartner.i}].bankCountry`,
        editingPartner.partner.bankCountry,
      )
      setValue(
        `partners${type}[${editingPartner.i}].businessSector`,
        editingPartner.partner.businessSector,
      )
      setValue(
        `partners${type}[${editingPartner.i}].paymentPurpose`,
        editingPartner.partner.paymentPurpose,
      )
      setValue(`partners${type}[${editingPartner.i}].website`, editingPartner.partner.website)
      trigger(`partners${type}`).then()
    }
  }

  const handleRemove = (i: number) => {
    remove(i)
    reset({ ...getValues() })
    setValue(fieldName, false)
    setDefaultPartner(undefined)
  }

  const completedPartners = take(
    partners,
    addingNewPartner ? Math.max((partners || []).length - 1, 0) : (partners || []).length,
  ).filter((v) => v)

  const partnerData: Record<
    PartnersInformationEnum,
    Array<Maybe<PreAssessmentPartner>> | null | undefined
  > = {
    Incoming: changedContractProfileData?.preAssessment?.partnersIncoming,
    Outgoing: changedContractProfileData?.preAssessment?.partnersOutgoing,
  }

  const handleAdd = () => {
    append(EMPTY_PARTNER)
    setAddingNewPartner(true)
  }

  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].country`, country)
    }
  }, [country])

  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].name`, companyName)
    }
  }, [companyName])

  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].businessSector`, businessSector)
    }
  }, [businessSector])

  useEffect(() => {
    if (isNoPartnersChecked) {
      setValue(`partners${type}[0].website`, website)
    }
  }, [website])

  return (
    <FormProvider {...methods}>
      <Typography variant="h6" className={classes.descriptionForField}>
        {type === PartnersInformationEnum.Incoming
          ? t(
              'pleaseSpecifyMainEntitiesToWhichYouWillReceivePaymentsToYourAccount',
              "Please specify the main entities from which you will receive payments to your account. If you intend to receive funds exclusively from bank accounts held in the Company's name, please enable the switch below",
            )
          : t(
              'pleaseSpecifyMainEntitiesToWhichYouWillSendPaymentsFromYourAccount',
              "Please specify the main entities to which you will send payments from your account. If you intend to transfer funds exclusively to bank accounts held in the Company's name, please enable the switch below",
            )}
        .
      </Typography>

      <Box className={classes.checkboxWrap}>
        <Controller
          name={fieldName}
          control={control}
          render={() => (
            <Toggler
              key={isNoPartnersChecked}
              name={fieldName}
              checked={isNoPartnersChecked}
              label={
                type === PartnersInformationEnum.Incoming
                  ? t(
                      'accountWillOnlyReceivePaymentsByAccountsRegisteredInCompanyName',
                      'The account will only receive payments by accounts registered in the Company’s name.',
                    )
                  : t(
                      'accountWillOnlySendPaymentsToAccountsRegisteredInCompanyName',
                      'The account will only send payments to accounts registered in the Company’s name.',
                    )
              }
              onChange={handleOnlyOnePartnerRequired}
              disabled={completedPartners.length >= 1 || readOnly || notAllInfoAvailable}
            />
          )}
        />
      </Box>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t('counterpartyName', 'Counterparty name')}</TableCell>
              <TableCell width={168}>{t('actions', 'Actions')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {completedPartners.map((value, i) => {
              const rowError = (error || [])[i]
              return (
                <TableRow hover key={`${i}${Object.keys(value).toString()}`}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    <Typography>{value.name} </Typography>
                    {rowError && (
                      <Typography variant="caption">
                        <CommonTipItem
                          value={t('hasError', 'Input has errors, click edit to fix')}
                          iconComponent={<IconInfo />}
                        />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {!readOnly && (
                      <Box className={classes.buttonsBox}>
                        <Button
                          type="button"
                          onClick={() => setEditingPartner({ i, partner: value })}
                        >
                          {t('edit', 'Edit')}
                        </Button>
                        <Button
                          color="secondary"
                          aria-label="delete"
                          type="button"
                          onClick={() => handleRemove(i)}
                        >
                          {t('delete', 'Delete')}
                        </Button>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow hover>
              <TableCell>{completedPartners.length + 1}</TableCell>
              <TableCell />
              <TableCell>
                <LinkButton
                  name={
                    type === PartnersInformationEnum.Incoming
                      ? 'partnersIncoming'
                      : 'partnersOutgoing'
                  }
                  onClick={handleAdd}
                  disabled={readOnly || isNoPartnersChecked}
                  data-test={`autotest-addPartners${type}Action`}
                >
                  + {t('add', 'Add')}
                </LinkButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {partnerData[type] && (
        <ControlledTooltipWrapped
          wrapperClass={classes.dirtyControl}
          hidden={formState.errors.partnersIncoming}
          title={makeTooltipTitleTextPartnersInformation(
            partnerData[type] as PreAssessmentPartner[],
            paymentPurposeData,
            isNoPartnersChecked,
            {
              tooltipKey: classes.tooltipKey,
              listItem: classes.listItem,
              divider: classes.lightDivider,
            },
          )}
          arrowHorizontalPosition={'left'}
        />
      )}

      {!!error && (
        <Box className={classes.errorWrap}>
          <FormHelperText className={classes.error}>{error.message}</FormHelperText>
        </Box>
      )}
      {(addingNewPartner || !!editingPartner) && (
        <NewPartnerModal
          name={`partners${type}[${
            !!editingPartner ? editingPartner.i : Math.max(fields.length - 1, 0)
          }]`}
          onClose={handleClose}
          setEditingPartner={setEditingPartner}
          paymentPurposeData={paymentPurposeData}
          defaultPartner={!!editingPartner ? editingPartner.partner : defaultPartner}
          onlyOnePartnerRequired={isNoPartnersChecked}
          setAddingNewPartner={setAddingNewPartner}
          type={type}
        />
      )}
    </FormProvider>
  )
}

export const EntitiesInfo = React.memo(EntitiesInfoComponent, isEqual)
