import React, { FC } from 'react'
import { Box, Button, Hidden, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useRepeatPayment } from '../../../components/Common/TransactionStatusCell/hooks'
import { DebitTransaction, Maybe, Transaction } from '../../../graphql'
import { trxStatusNormalize } from '../../../utils'
import { useStyles as globalStyles } from '../../AplicationActions/Tabs/useStyles'
import IconDot from '../../../assets/images/icons/Dot.svg?react'

const useStyles = makeStyles(() => ({
  repeatBtn: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    textDecoration: 'underline',
    minWidth: 'auto',
    padding: 0,
    '&:hover': {
      background: '#ffffff',
    },
  },
}))

const TransactionStatusCellComponent: FC<{
  txData: Maybe<Transaction> | undefined
}> = ({ txData }) => {
  const { t } = useTranslation()
  const classes = { ...globalStyles(), ...useStyles() }

  const { handleRepeat, isRepeatable } = useRepeatPayment(txData as DebitTransaction)

  const txStatus = txData?.status

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gridGap={10}>
      {txStatus && (
        <Box component="span" className={clsx(`roundedPill ${trxStatusNormalize(txStatus)}`)}>
          {t(`${trxStatusNormalize(txStatus)}`)}
        </Box>
      )}
      {isRepeatable && (
        <>
          <Hidden xsDown>
            <IconDot />
          </Hidden>
          <Button
            aria-label="repeat"
            type="button"
            onClick={handleRepeat}
            className={classes.repeatBtn}
          >
            {t('repeatPayment', 'Repeat payment')}
          </Button>
        </>
      )}
    </Box>
  )
}

export const TransactionStatusCell = React.memo(TransactionStatusCellComponent, isEqual)
