import React, { FC, useCallback, CSSProperties } from 'react'
import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import UploadFailed from '../../assets/images/icons/upload_failed.svg?react'

const useStyles = (isFullWidth: boolean, withIcon?: boolean, disabled?: boolean) =>
  makeStyles((theme: Theme) => ({
    button: {
      width: isFullWidth ? '100%' : 'auto',
      alignSelf: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        width: () => '100%',
      },
      minWidth: withIcon ? theme.spacing(13) : 'auto',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'auto',
      },
      '& .MuiButton-label svg': {
        width: theme.spacing(2.5),
        transform: 'rotate(270deg) translateX(3px)',
        '& path': {
          fill: disabled ? '#999' : '#000',
        },
      },
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        lineHeight: 1,
        marginLeft: theme.spacing(0.5),
      },
    },
  }))()

export const FiltersResetBtn: FC<{
  resetPageState: () => void
  isDisabled?: boolean
  fullWidth?: boolean
  style?: CSSProperties
  withIcon?: boolean
}> = ({ resetPageState, isDisabled = false, fullWidth = false, style, withIcon }) => {
  const { t } = useTranslation()
  const classes = useStyles(fullWidth, withIcon, isDisabled)

  const resetHandler = useCallback(() => {
    resetPageState()
  }, [resetPageState])

  return (
    <Button
      type="submit"
      variant={withIcon ? 'text' : 'contained'}
      color="primary"
      disabled={isDisabled}
      disableElevation
      onClick={resetHandler}
      style={style}
      className={classes.button}
      data-test="autotest-resetFilterBtn"
    >
      {withIcon ? (
        <>
          <UploadFailed fontSize="small" />
          <Typography variant="body1">{t('resetFilters', 'Reset filters')}</Typography>
        </>
      ) : (
        t('reset', 'Reset')
      )}
    </Button>
  )
}
