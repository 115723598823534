/* eslint-disable i18next/no-literal-string */
import React, { FC, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { Box, Checkbox, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'

import Checked from '../../../../assets/images/icons/cheked_icon.svg?react'
import { Unchecked } from '../../../../components/Common/Unchecked'
import { Document } from '../../../../graphql'
import { dateFormat, documentTypesArray } from '../../../../utils'
import { useDetermineUserRights } from '../../../../hooks'
import { PATH_PARAMS } from '../../../../routes/paths'

export const DocumentsStyledColumns = {
  '& .MuiTableCell-root:nth-child(1)': {
    maxWidth: '30%',
    width: '100%',
  },
  '& .MuiTableCell-root:nth-child(2)': {
    maxWidth: '15%',
    width: '100%',
  },
  '& .MuiTableCell-root:nth-child(3)': {
    maxWidth: '40%',
    width: '100%',
  },
  '& .MuiTableCell-root:nth-child(4)': {
    maxWidth: '15%',
    width: '100%',
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  backGround: {
    cursor: 'pointer',
    '&.checked': {
      backgroundColor: '#EFF3FE!important',
    },
    '&.isRead': {
      backgroundColor: 'rgb(255, 255, 255)',
    },
    '&:hover': {
      background: '#F5F5F5',
    },
    backgroundColor: 'rgb(245, 245, 245)',
    border: 'none',
    '& .MuiTableCell-root': {
      display: 'inline-flex',
      alignItems: 'center',
      '& div, & a': {
        overflowX: 'hidden',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    '&.MuiTableRow-root': {
      display: 'block',
      width: '100%',
      ...DocumentsStyledColumns,
    },
  },
  name: {
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
  },
  linkStyle: {
    color: 'black',
    marginLeft: theme.spacing(3),
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
}))

export const ManagementListRow: FC<{
  data: Document
  checkedAll: boolean
  onCheckedAny: (
    b: boolean,
    element: {
      id: number
      isRead: boolean
    },
  ) => void
  disabled?: boolean
  checkedIdArray: Document[]
  onMark: (isLoadDocumentId: number) => void
}> = ({ data, checkedAll, onCheckedAny, checkedIdArray, onMark, disabled }) => {
  const { id, fileDescription, createdAt, type, firstSide, isRead, account } = data
  const [checked, setChecked] = useState(false)
  const classes = useStyles()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isDisabled = disabled || ((isRead as boolean) && !userRights?.isApplicant)

  const handleCheck = useCallback(() => {
    setChecked((prevState) => !prevState)
    onCheckedAny(!checked, { id: id, isRead: !!isRead })
  }, [checked, id, onCheckedAny, setChecked])

  const docType = documentTypesArray.find((item) => item.key === type)?.label

  const handleDetails = useCallback(
    (event) => {
      if (event.target.tagName === 'INPUT') {
        return
      }
      onMark(id)
      const win = window.open(data?.firstSide?.url as string, '_blank')
      if (win != null) {
        win.focus()
      }
    },
    [id, data],
  )

  useEffect(() => {
    setChecked(checkedAll)
  }, [checkedAll])

  useEffect(() => {
    if (isEmpty(checkedIdArray)) {
      setChecked(false)
    }
  }, [checkedIdArray])

  return (
    <TableRow
      className={clsx(
        classes.backGround,
        `${checked && !isDisabled && 'checked'}`,
        `${isRead && 'isRead'}`,
      )}
      data-test="itemInfoDoc"
    >
      <TableCell onClick={handleDetails}>
        <Box className={classes.name}>
          <Checkbox
            checked={isDisabled ? false : checked}
            icon={<Unchecked />}
            checkedIcon={<Checked />}
            onChange={handleCheck}
            data-test="itemInfoDocCheck"
            disabled={isDisabled}
            classes={{ disabled: classes.disabledCheckbox }}
          />
          <a
            href={firstSide?.url || '/'}
            target={'_blank'}
            rel={'noopener'}
            className={classes.linkStyle}
            title={fileDescription as string}
          >
            {fileDescription as string}
          </a>
        </Box>
      </TableCell>
      <TableCell onClick={handleDetails}>
        <Box>{account?.alias ?? 'None'}</Box>
      </TableCell>
      <TableCell onClick={handleDetails}>
        <Box title={docType}>{docType}</Box>
      </TableCell>
      <TableCell onClick={handleDetails}>
        <Box data-test="dateDocument">{dateFormat(createdAt)}</Box>
      </TableCell>
    </TableRow>
  )
}

export default ManagementListRow
