import React, { FC, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'

import IconSubmit from '../../assets/images/icons/submit96.svg?react'
import { BatchPaymentInfo } from '../../types'
import { ActionSignature, SignatoryRight } from '../../graphql'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useParams } from 'react-router'
import { useDetermineUserRights } from '../../hooks'
import { generatePath, useHistory } from 'react-router-dom'
import { PaymentContext } from './PaymentContextComponent'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: 32,
    width: '350px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  info: {
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 12,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    width: '590px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  btn: {
    minWidth: 264,
    margin: '20px 12px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      margin: '10px 0',
    },
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))

export const BatchPaymentResult: FC<{
  signNow: boolean
  batchPaymentResultText: BatchPaymentInfo | undefined
  actionData: ActionSignature | undefined
}> = ({ batchPaymentResultText, signNow, actionData }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)

  const handleReviewPayment = useCallback(() => {
    if (signNow && userRights?.signatoryRight === SignatoryRight.Sole) {
      history.push(
        generatePath(APP_PATHS.dashboard.transactions, {
          [PATH_PARAMS.applicationId]: applicationId,
        }),
      )
    } else {
      actionData &&
        history.push(
          generatePath(APP_PATHS.dashboard.actionDetails, {
            [PATH_PARAMS.applicationId]: applicationId,
            [PATH_PARAMS.signatureId]: actionData?.action?.id,
          }),
        )
    }
  }, [actionData, applicationId, signNow, userRights])

  const { resetBatchComponent } = useContext(PaymentContext)

  const handleNewPayment = useCallback(() => {
    resetBatchComponent()
  }, [])

  useEffect(() => {
    return resetBatchComponent
  }, [])

  return (
    <Grid item xs={12} className={classes.container}>
      <Box mt={18}>
        <IconSubmit />
      </Box>

      <Typography variant={'h2'} className={classes.title}>
        {batchPaymentResultText?.title}
      </Typography>

      <Typography variant={'h2'} className={classes.info}>
        {batchPaymentResultText?.text}
      </Typography>

      <Box className={classes.btnGroup}>
        <>
          {signNow && userRights?.signatoryRight === SignatoryRight.Sole ? (
            <Button
              onClick={handleReviewPayment}
              className={classes.btn}
              variant="contained"
              color="default"
              disableElevation
            >
              {t('reviewTransactions', 'Review transactions')}
            </Button>
          ) : (
            <Button
              onClick={handleReviewPayment}
              className={classes.btn}
              variant="contained"
              color="default"
              disableElevation
            >
              {t('reviewBatch', 'Review batch')}
            </Button>
          )}
          <Button
            onClick={handleNewPayment}
            variant="contained"
            disableElevation
            color="primary"
            className={classes.btn}
          >
            {t('sendAnotherPayment', 'Send another payment')}
          </Button>
        </>
      </Box>
    </Grid>
  )
}

export default BatchPaymentResult
