import { find, isEmpty, isNull } from 'lodash'
import * as yup from 'yup'
import {
  ALL_EXCEPT_CYRILLIC_REGEXP,
  COMPANY_NAME_REGEX,
  LATINICA_ONLY_MESSAGE,
  MIN_LENGTH,
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import { UboLegalEntityType } from '../../graphql'
import i18n from '../../i18n'
import { CompanyNameInputSchema } from '../common/CompanyName'
import { RegistryNumberInputSchema, TaxIdSchema } from '../common'
import { RegistrationDateInputSchema } from '../common/RegistrationDate'
import { validCountriesKeyList } from '../../components'
import { UrlsInputSchema } from './UrlsSchema'

export const CompanyInformationInputSchema = yup
  .object()
  .shape({
    entityType: yup
      .string()
      .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
      .test(
        'entityTypeNotUnknown',
        THIS_FIELD_IS_REQUIRED_MESSAGE,
        (val) => !!val && val !== UboLegalEntityType.Unknown,
      ),
    tradingName: yup
      .string()
      .notRequired()
      .nullable()
      .test({
        name: 'tradingNameMin',
        message: i18n.t('theFieldMustHaveLatin', 'Must have at least {{min}} characters', {
          min: MIN_LENGTH,
        }),
        test: (val) => (!!val && val?.length >= MIN_LENGTH) || val === '' || isNull(val),
      })
      .test({
        name: 'tradingNameMax',
        message: PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
        test: (val) =>
          (!!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX) || val === '' || isNull(val),
      })
      .test({
        name: 'tradingNameRegexp',
        message: i18n.t(LATINICA_ONLY_MESSAGE, {
          match: ALL_EXCEPT_CYRILLIC_REGEXP,
        }),
        test: (value) => !!value?.match(COMPANY_NAME_REGEX) || value === '' || isNull(value),
      }),
    country: yup
      .mixed()
      .test('countryNotEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val))
      .test(
        'dontOfferServicesInThisCountry',
        i18n.t(
          'sorryWeDontOfferServicesInThisCountry',
          'Sorry, we don’t offer services in this country',
        ),
        (val) => !!find(validCountriesKeyList, (item) => item === val),
      ),
    countryOfTaxResidence: yup
      .mixed()
      .test('countryNotEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val)),
  })
  .concat(UrlsInputSchema)
  .concat(CompanyNameInputSchema)
  .concat(RegistrationDateInputSchema)
  .concat(RegistryNumberInputSchema)
  .concat(TaxIdSchema)
