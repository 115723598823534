import * as yup from 'yup'
import {
  MAX_LENGTH,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_ONLY_MESSAGE,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import { isEmpty } from 'lodash'

export const StreetAddressInputSchema = yup.object().shape({
  streetAddress: yup
    .string()
    .nullable()
    .test('required', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val?.trim()))
    .test('length', NUMBER_AND_LATINICA_ONLY_MESSAGE, (val) => {
      const trimmedValue = val?.trim() || ''
      return trimmedValue.length >= MIN_LENGTH && trimmedValue.length <= MAX_LENGTH
    }),
  // .matches(LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD, NUMBER_AND_LATINICA_ONLY_MESSAGE)
})

export type StreetAddressInput = yup.Asserts<typeof StreetAddressInputSchema>
