import { isEmpty } from 'lodash'
import * as yup from 'yup'
import i18n from '../../i18n'

export const UBOResidencyInputSchema = yup.object().shape({
  uboResidencies: yup
    .array()
    .of(
      yup.object().shape({
        countries: yup
          .mixed()
          .test(
            'notEmpty',
            i18n.t('thisFieldIsRequired', 'This field is required'),
            (val) => !isEmpty(val),
          ),
      }),
    )
    .min(
      1,
      i18n.t('atLeastOneUbo', 'Providing controlling persons countries of residence is mandatory'),
    ),
})
