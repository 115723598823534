import React, { ChangeEvent } from 'react'
import { IFileWithMeta, IUploadParams, MethodValue } from 'react-dropzone-uploader'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { toast } from 'react-toastify'
import {
  GetBatchFileUploadUrlMutation,
  GetBatchFileUploadUrlMutationFn,
  GetSignedUrlMutation,
  GetSignedUrlMutationFn,
  GetSignedUrlType,
} from '../../graphql'
import i18n from '../../i18n'
import { reportAppError } from './functions'

export const extractAmzFields = (urlObj: URL) => ({
  'X-Amz-Algorithm': urlObj.searchParams.get('X-Amz-Algorithm') as string,
  'X-Amz-Credential': urlObj.searchParams.get('X-Amz-Credential') as string,
  'X-Amz-Date': urlObj.searchParams.get('X-Amz-Date') as string,
  'X-Amz-Expires': urlObj.searchParams.get('X-Amz-Expires') as string,
  'X-Amz-Signature': urlObj.searchParams.get('X-Amz-Signature') as string,
  'X-Amz-SignedHeaders': urlObj.searchParams.get('X-Amz-SignedHeaders') as string,
  'x-amz-acl': urlObj.searchParams.get('x-amz-acl') as string,
})

export const getBatchUploadParamsFactory = (
  batchFileCallback: () => Promise<GetSignedUrlType | undefined | null>,
) => async ({ file }: IFileWithMeta) => {
  const batchFile = await batchFileCallback()
  if (!batchFile) {
    return { url: '#' } as IUploadParams
  }
  const url = batchFile.signedUrl as string
  const urlObj = new URL(url)
  const fields = extractAmzFields(urlObj)

  return {
    fields,
    meta: { fileUrl: batchFile.plainUrl },
    url,
    method: 'PUT' as MethodValue,
    body: file,
  }
}

export const getFilesFromEvent = (
  event: React.DragEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
): File[] | Promise<File[]> => {
  return new Promise((resolve) => {
    getDroppedOrSelectedFiles(event).then(
      (
        chosenFiles: {
          fileObject: File
          fullPath: string
          lastModified: string
          lastModifiedDate: string
          name: string
          size: number
          type: string
          webkitRelativePath: string
        }[],
      ) => {
        resolve(chosenFiles.slice(0, 1).map((f) => f.fileObject))
      },
    )
  })
}

export const getUploadParams = (
  mutation: GetSignedUrlMutationFn | GetBatchFileUploadUrlMutationFn,
  isBatch?: boolean,
): (({ meta, file }: IFileWithMeta) => IUploadParams | Promise<IUploadParams>) => ({
  file,
}: IFileWithMeta) => {
  return new Promise((resolve) => {
    mutation({
      variables: {},
    })
      .then((r) => {
        if (r.errors) throw new Error(JSON.stringify(r.errors))

        const url = !isBatch
          ? ((r.data as GetSignedUrlMutation)?.getSignedUrl?.signedUrl as string)
          : ((r.data as GetBatchFileUploadUrlMutation)?.getBatchPaymentFileSignedUrl
              ?.signedUrl as string)

        const fileUrl = !isBatch
          ? (r.data as GetSignedUrlMutation)?.getSignedUrl?.plainUrl
          : (r.data as GetBatchFileUploadUrlMutation)?.getBatchPaymentFileSignedUrl?.plainUrl

        const urlObj = new URL(url)
        const fields = extractAmzFields(urlObj)
        resolve({
          fields,
          headers: {
            'Content-Type': file.type,
            'Content-Disposition': `filename=${encodeURI(file.name)}`,
          },
          meta: { fileUrl },
          url,
          method: 'PUT',
          body: file,
        })
      })
      .catch((error) => {
        toast.error(
          i18n.t(
            'errorDocumentUpload',
            'Unable to upload document at the moment, please try again later',
          ),
        )
        reportAppError(error)
      })
  })
}
