import React, { FC } from 'react'
import { Button, ButtonProps, makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  addButtonBox: {
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
    '& .MuiButton-root': {
      minWidth: 16,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.5rem',
    },
  },
}))

export const LinkButton: FC<ButtonProps> = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Box className={classes.addButtonBox}>
      <Button type="button" {...rest}>
        {children}
      </Button>
    </Box>
  )
}
