import { Box, Button } from '@material-ui/core'
import { isEqual, isNull } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ButtonWithTooltip } from '../../../components'
import { useRepeatPayment } from '../../../components/Common/TransactionStatusCell/hooks'
import { DebitTransaction, FundsType, LimitedAccessRight, Transaction } from '../../../graphql'
import { useDetermineUserRights, useIsLimitedContract } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { useStyles } from '../../AplicationActions/Tabs/useStyles'
import { TransferProps } from '../TrxTransferPage'
import { useDownloadTrxStatement } from './hooks/useDownloadTrxStatement'

const OutboundButtonsBlockComponent: FC<{
  handleBack?: () => void
  handleDispute?: () => void
  transfer: TransferProps
  confirmationAvailable: boolean
}> = ({ handleBack, handleDispute, transfer, confirmationAvailable }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const accId = (transfer.rawData as Transaction)?.account?.id as string
  const trxId = transfer.rawData?.id as string
  const isDepositGuaranteed =
    (transfer.rawData as Transaction)?.account?.fundsType === FundsType.DepositGuaranteed

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  const { handleRepeat, isRepeatable } = useRepeatPayment(transfer.rawData as DebitTransaction)
  const { isLimitedContract } = useIsLimitedContract()
  const canDispute =
    transfer.txData?.directDebitExecution?.canBeDisputed &&
    !transfer.txData?.directDebitExecution.dispute

  const handleDownloadStatement = useDownloadTrxStatement(accId, trxId, confirmationAvailable)
  const downLoadText = confirmationAvailable
    ? t('downloadConfirmation', 'Download Confirmation')
    : t('downloadStatement', 'Download Statement')

  return (
    <Box className={classes.modalFooter}>
      <Button
        variant="contained"
        className={classes.selectBut}
        onClick={handleDownloadStatement}
        disableElevation
        color={
          isViewOnly ||
          isDepositGuaranteed ||
          !isNull((transfer.rawData as DebitTransaction).standingOrder)
            ? 'primary'
            : undefined
        }
      >
        {downLoadText}
      </Button>
      {canDispute && (
        <Button
          className={classes.selectBut}
          variant="contained"
          disabled={isViewOnly}
          color="primary"
          onClick={handleDispute}
        >
          {t('disputeDirectDebit', 'Dispute')}
        </Button>
      )}
      {!isRepeatable ? (
        <Button
          className={classes.cancelBut}
          variant="contained"
          onClick={handleBack}
          disableElevation
        >
          {t('close', 'Close')}
        </Button>
      ) : (
        <ButtonWithTooltip
          className={classes.selectBut}
          disabledOperationName="Repeat payment"
          disabled={isLimitedContract}
          variant="contained"
          color="primary"
          onClick={handleRepeat}
        >
          {t('repeatPayment', 'Repeat payment')}
        </ButtonWithTooltip>
      )}
    </Box>
  )
}

export const OutboundButtonsBlock = React.memo(OutboundButtonsBlockComponent, isEqual)
