import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressStep } from '../../Onboarding/Progress'

export const ProductInfoStepPoint: FC<{
  filledFields: string[]
  errorsList: string[]
}> = ({ filledFields, errorsList }) => {
  const { t } = useTranslation()

  const isProductInfoCompleted =
    filledFields.includes('products') && !errorsList.includes('products')

  return (
    <ProgressStep
      stage={isProductInfoCompleted ? 'done' : 'future'}
      label={t('productInformation', 'Product information')}
      error={errorsList.includes('products')}
    />
  )
}
