import { businessCategoryQuestions } from '@klarpay/enums'
import { find, isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  ALL_EXCEPT_CYRILLIC_REGEXP,
  MAX_LENGTH_MESSAGE,
  MAX_SOURCE_OF_FUNDS_NAME_LENGTH,
  NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
  PRE_ASSESSMENT_LONG_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import i18n from '../../i18n'

export const GeneralQuestionsInputSchema = yup.object().shape({
  businessActivitiesAndServices: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS)
    .test(
      'businessActivitiesAndServicesMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_LONG_FIELD_MAX
      },
    ),
  businessSector: yup
    .mixed()
    .test(
      'businessSectorNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  businessSectorConductingKYC: yup
    .mixed()
    .test(
      'businessSectorConductingKYCNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      function () {
        const question = find(
          businessCategoryQuestions.ConductingKYC.businessTypes,
          (t) => t.question.key === this.parent.businessSector && t.required,
        )
        return !question || (question && !isEmpty(this.parent.businessSectorConductingKYC))
      },
    ),
  businessSectorConductingClientKYC: yup
    .mixed()
    .test(
      'businessSectorConductingClientKYCNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      function () {
        const question = find(
          businessCategoryQuestions.ConductingClientKYC.businessTypes,
          (t) => t.question.key === this.parent.businessSector && t.required,
        )
        return !question || (question && !isEmpty(this.parent.businessSectorConductingClientKYC))
      },
    ),
  businessSectorConductPublisherContentMonitoring: yup
    .mixed()
    .test(
      'businessSectorConductPublisherContentMonitoringNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      function () {
        const question = find(
          businessCategoryQuestions.ConductPublisherContentMonitoring.businessTypes,
          (t) => t.question.key === this.parent.businessSector && t.required,
        )
        return (
          !question ||
          (question && !isEmpty(this.parent.businessSectorConductPublisherContentMonitoring))
        )
      },
    ),
  businessSectorC2BPayments: yup
    .mixed()
    .test(
      'businessSectorC2BPaymentsNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      function () {
        const question = find(
          businessCategoryQuestions.C2BPayments.businessTypes,
          (t) => t.question.key === this.parent.businessSector && t.required,
        )
        return !question || (question && !isEmpty(this.parent.businessSectorC2BPayments))
      },
    ),
  businessSectorClientsYouWorkWith: yup
    .mixed()
    .test(
      'businessSectorClientsYouWorkWithNotEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      function () {
        const question = find(
          businessCategoryQuestions.ClientsYouWorkWith.businessTypes,
          (t) => t.question.key === this.parent.businessSector && t.required,
        )
        return !question || (question && !isEmpty(this.parent.businessSectorClientsYouWorkWith))
      },
    ),
  anticipatedAccountActivity: yup
    .mixed()
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  products: yup
    .mixed()
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  sourceOfFunds: yup
    .mixed()
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  sourceOfFundsCustomVal: yup.string().max(MAX_SOURCE_OF_FUNDS_NAME_LENGTH, MAX_LENGTH_MESSAGE),
  paymentProviders: yup
    .string()
    .nullable()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS)
    .test('paymentProvidersMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_LONG_FIELD_MAX
    }),
  businessRelationshipWithCompany: yup.mixed().nullable().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  businessRelationshipWithCompanyExplanation: yup.mixed().when('businessRelationshipWithCompany', {
    is: 'Yes',
    then: yup
      .string()
      .nullable()
      .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
      .matches(ALL_EXCEPT_CYRILLIC_REGEXP, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS)
      .test(
        'businessRelationshipWithCompanyExplanationMaxLength',
        PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG,
        (val) => {
          return !!val && val?.length <= PRE_ASSESSMENT_LONG_FIELD_MAX
        },
      ),
  }),
})
