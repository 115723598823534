import React, { FC, memo, useEffect } from 'react'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, Hidden, List, Toolbar } from '@material-ui/core'

import {
  ActionSignatureStatus,
  LimitedAccessRight,
  useActionSignatureRequestsCountQuery,
  useCardsCountQuery,
  Individual,
} from '../../graphql'
import { FacilitationTabsEnum } from '../../types'
import { MainMenuItem } from './MainMenuItem'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { Footer } from '../Footer'
import { useMenuStyles } from './menuStyles'
import { CompanySelect } from '../Common'
import config from '../../config'
import { useTotalUnreadDocCount } from '../../hooks'

import IconHome from '../../assets/images/icons/home_2.svg?react'
import AccountsIcon from '../../assets/images/icons/accounts-icon.svg?react'
import IconDevelopers from '../../assets/images/icons/developers.svg?react'
import IconPencil from '../../assets/images/icons/icon_pencil.svg?react'
import CompareArrows from '../../assets/images/icons/compare_arrows.svg?react'
import DirectDebitIcon from '../../assets/images/icons/direct-debit.svg?react'
import Payment from '../../assets/images/icons/payment.svg?react'
import IconClients from '../../assets/images/icons/people_alt.svg?react'
import PaymentFacilitationIcon from '../../assets/images/icons/payment-facilitation.svg?react'

const useStyles = makeStyles((theme) => ({
  logoMenu: {
    top: 0,
    padding: theme.spacing(2, 4, 2, 2),
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    backgroundColor: 'white',
    zIndex: 999,
    width: 248,
    cursor: 'pointer',
  },
  list: {
    position: 'relative',
    '& > span': {
      width: '100%',
    },
    '& li': {
      margin: 0,
      padding: theme.spacing(1.75, 2, 1.75, 2.25),
      '&::after': {
        top: '50%',
        bottom: 'auto',
        height: 'calc(100% - 5px)',
        transform: 'translateY(-50%)',
      },
    },
  },
  backWrapp: {
    width: 'auto',
    margin: theme.spacing(3, 0, 3, 0),
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  divider: {
    height: theme.spacing(0.125),
    backgroundColor: '#E5E7EB',
    margin: theme.spacing(0.5, 0),
  },
}))

const DashboardDrawerComponent: FC<{
  contractId: number | string | undefined
  setMobileOpen: (arg: boolean) => void
  user: Individual
  limitMode: boolean
  limitedAccessRight: LimitedAccessRight | null | undefined
  isIntroducerContractOwner: boolean
  hasCardPayment: boolean
  resetPages: () => void
  onLogoClick: () => void
}> = ({
  contractId,
  setMobileOpen,
  user,
  limitMode,
  limitedAccessRight,
  isIntroducerContractOwner,
  hasCardPayment,
  resetPages,
  onLogoClick,
}) => {
  const { t } = useTranslation()
  const classes = { ...useMenuStyles(), ...useStyles() }
  const location = useLocation()

  const { data: signaturesCount, refetch } = useActionSignatureRequestsCountQuery({
    variables: { contractId: contractId, statuses: [ActionSignatureStatus.Pending] },
    fetchPolicy: 'network-only',
  })

  const { refetchDocsCount } = useTotalUnreadDocCount()

  const { data: cardsCount } = useCardsCountQuery({
    ...(contractId ? { variables: { contractId: +contractId } } : {}),
  })

  const contract = contractId ? user.contracts?.find((c) => c?.id === +contractId) : undefined
  const isIntroducingPartner = !!contract?.isIntroducingPartner

  const paymentFacilitatorEnabled =
    config.isPaymentFacilitatorEnabled ||
    (import.meta.env.REACT_APP_PF_TEST_CONTRACTS || '').split(',').includes(String(contractId))

  useEffect(() => {
    void refetch()
    void refetchDocsCount()
  }, [location, refetch, refetchDocsCount])

  return (
    <Box
      key={contractId}
      className={classes.drawerInner}
      onClick={() => {
        setMobileOpen(false)
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Hidden smDown>
          <Toolbar className={classes.logoMenu}>
            <img src={config.logo} alt={'company_logo'} onClick={onLogoClick} />
          </Toolbar>
        </Hidden>

        <Box className={classes.backWrapp} onClick={resetPages}>
          <CompanySelect contractId={contractId} />
        </Box>

        <List className={classes.list} onClick={resetPages}>
          {![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) &&
            !isIntroducerContractOwner && (
              <>
                <MainMenuItem
                  icon={<IconHome />}
                  primary={t('home', 'Home')}
                  path={generatePath(APP_PATHS.dashboard.home, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  disabled={limitMode}
                  data-test="homePageNavItem"
                />

                <MainMenuItem
                  icon={<AccountsIcon />}
                  primary={t('accounts', 'Accounts')}
                  path={generatePath(APP_PATHS.dashboard.accounts, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  disabled={limitMode}
                  data-test="autotest-accountsPageNavItem"
                />
              </>
            )}

          {isIntroducerContractOwner && (
            <MainMenuItem
              icon={<IconClients />}
              primary={t('home')}
              path={generatePath(APP_PATHS.dashboard.partnerManagementOverview, {
                [PATH_PARAMS.applicationId]: contractId,
              })}
              disabled={limitMode}
            />
          )}

          {!isIntroducerContractOwner &&
            limitedAccessRight !== LimitedAccessRight.PaymentFacilitationView && (
              <MainMenuItem
                icon={<CompareArrows />}
                primary={t('transactions', 'Transactions')}
                path={generatePath(APP_PATHS.dashboard.transactions, {
                  [PATH_PARAMS.applicationId]: contractId,
                })}
                disabled={limitMode}
                detailsPath={[APP_PATHS.dashboard.trxDetails, APP_PATHS.dashboard.cardTrxs]}
                data-test="drawer-transactions"
              />
            )}

          {!isIntroducerContractOwner &&
            ![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
              limitedAccessRight as LimitedAccessRight,
            ) && (
              <MainMenuItem
                icon={<DirectDebitIcon />}
                primary={t('directDebit', 'Direct Debit')}
                path={generatePath(APP_PATHS.dashboard.directDebitMandatesOutbound, {
                  [PATH_PARAMS.applicationId]: contractId,
                  [PATH_PARAMS.accountId]: 'allAccounts',
                })}
                disabled={limitMode}
                detailsPath={[APP_PATHS.dashboard.directDebitMandatesOutbound]}
                data-test="direct-debits"
              />
            )}

          {(![LimitedAccessRight.ViewOnly, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) ||
            (!!cardsCount?.contractCardsCount &&
              limitedAccessRight === LimitedAccessRight?.ViewOnly)) &&
            !isIntroducerContractOwner && (
              <MainMenuItem
                icon={<Payment />}
                primary={t('cards', 'Cards')}
                path={generatePath(APP_PATHS.dashboard.cards, {
                  [PATH_PARAMS.applicationId]: contractId,
                  [PATH_PARAMS.cardsPageNum]: 1,
                })}
                disabled={limitMode}
                detailsPath={[APP_PATHS.dashboard.activateCard, APP_PATHS.dashboard.createCard]}
                data-test="cardPageNavItem"
              />
            )}

          {![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) &&
            !isIntroducerContractOwner && (
              <MainMenuItem
                icon={<IconClients />}
                primary={t('beneficiaries', 'Beneficiaries')}
                path={generatePath(APP_PATHS.dashboard.clients, {
                  [PATH_PARAMS.applicationId]: contractId,
                })}
                disabled={limitMode}
                detailsPath={APP_PATHS.dashboard.clientOverview}
              />
            )}

          {paymentFacilitatorEnabled && hasCardPayment && (
            <MainMenuItem
              icon={<PaymentFacilitationIcon />}
              primary={t('paymentFacilitator', 'Payment facilitation')}
              path={generatePath(APP_PATHS.dashboard.paymentFacilitator, {
                [PATH_PARAMS.applicationId]: contractId,
                [PATH_PARAMS.facilitationTab]: FacilitationTabsEnum.overview,
              })}
              detailsPath={[
                APP_PATHS.dashboard.paymentFacilitator,
                APP_PATHS.dashboard.paymentDetails,
              ]}
            />
          )}

          {isIntroducingPartner &&
            !isIntroducerContractOwner &&
            limitedAccessRight !== LimitedAccessRight.PaymentFacilitationView && (
              <MainMenuItem
                icon={<IconClients />}
                primary={t('partnerManagement', 'Partner management')}
                path={generatePath(APP_PATHS.dashboard.partnerManagementOverview, {
                  [PATH_PARAMS.applicationId]: contractId,
                })}
                disabled={limitMode}
              />
            )}

          {![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
            limitedAccessRight as LimitedAccessRight,
          ) &&
            !isIntroducerContractOwner && (
              <>
                <MainMenuItem
                  icon={<IconDevelopers />}
                  primary={t('developers', 'Developers')}
                  path={generatePath(APP_PATHS.dashboard.developers, {
                    [PATH_PARAMS.applicationId]: contractId,
                  })}
                  disabled={limitMode}
                  data-test="developersPageNavItem"
                />
              </>
            )}

          {![
            LimitedAccessRight.CardHolder,
            LimitedAccessRight.ViewOnly,
            LimitedAccessRight.PaymentFacilitationView,
          ].includes(limitedAccessRight as LimitedAccessRight) && (
            <>
              <Divider flexItem orientation="horizontal" className={classes.divider} />

              <MainMenuItem
                icon={<IconPencil />}
                countNum={signaturesCount?.actionSignatureRequestsCount || 0}
                primary={t('actions', 'Actions')}
                path={generatePath(APP_PATHS.dashboard.actions, {
                  [PATH_PARAMS.applicationId]: contractId,
                })}
                detailsPath={APP_PATHS.dashboard.actionDetails}
              />
            </>
          )}
        </List>
      </Box>

      <Box mt={2}>
        <Footer isMenu={true} />
      </Box>
    </Box>
  )
}

export const DashboardDrawer = memo(DashboardDrawerComponent, isEqual)
