import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useParams } from 'react-router-dom'
import { LimitedAccessRight } from '../../graphql'
import { useDetermineUserRights, useIsLimitedContract, useTotalUnreadDocCount } from '../../hooks'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import PersonProfile from '../PersonPage/PersonProfile'
import { ContractManagementTabs } from './ContractManagementTabs'
import { BusinessDetailsForm, TerminateContractForm } from './forms'

import { isModalDialogOpen, isUnsaveFormData } from '../../graphql/local'

import { TabContext, TabPanel } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: '80px 8px 8px 8px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
      paddingBottom: '32px',
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '36px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '24px',
    },
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '32px',
      fontSize: 24,
      marginBottom: 0,
      marginTop: 0,
    },
  },
  wrapContract: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  noPadding: {
    padding: 0,
  },
}))

export enum CmTabName {
  BusinesDetails = 'details',
  TerminateContract = 'terminate',
  PersonalProfile = 'profile',
}

const ContractManagement: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.contractManagementTab]: contractManagementTab,
  } = useParams() as Record<string, string>

  const { isLimitedContract } = useIsLimitedContract()
  const { refetchDocsCount } = useTotalUnreadDocCount()
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly
  const [showTabsMenu, setShowTabsMenu] = useState(true)

  const tabsRefs = useRef<Array<Link<unknown> | null>>([])

  const handleChange = useCallback((_: React.SyntheticEvent<EventTarget>, newValue: number) => {
    if (isUnsaveFormData()) {
      isModalDialogOpen(true)
      return
    }

    const currentTab = (tabsRefs.current[newValue] as unknown) as HTMLElement
    const scroller = document.querySelector('.MuiTabs-scroller') as HTMLDivElement
    if (currentTab && scroller && window.matchMedia('(max-width: 600px)').matches) {
      setTimeout(
        () =>
          scroller.scrollTo({
            behavior: 'smooth',
            top: 0,
            left: currentTab.offsetLeft,
          }),
        500,
      )
    }
  }, [])

  useEffect(() => {
    void refetchDocsCount()
  }, [])

  if (
    userRights?.limitedAccessRight &&
    [LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
      userRights.limitedAccessRight,
    )
  )
    return <Redirect to={APP_PATHS.errors._404} />

  return (
    <Grid container className={classes.wrap}>
      {showTabsMenu && (
        <Grid item xs={12} container className={classes.head}>
          <Grid item>
            <Typography component="h1" variant="h1" className={classes.title}>
              {t('contractManagementTitle', 'Contract Management')}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} container className={classes.wrapContract}>
        <TabContext value={contractManagementTab}>
          {showTabsMenu && <ContractManagementTabs handleChange={handleChange} />}

          <Box width="100%">
            {!userRights?.isIndividualContractOwner && (
              <TabPanel
                className={classes.noPadding}
                value={CmTabName.BusinesDetails}
                data-test="businessDetailsTabPage"
              >
                <BusinessDetailsForm isViewOnly={isViewOnly || isLimitedContract} />
              </TabPanel>
            )}
            {userRights?.isIndividualContractOwner && (
              <TabPanel
                className={classes.noPadding}
                value={CmTabName.PersonalProfile}
                data-test="businessDetailsTabPage"
              >
                <PersonProfile hideBack taxIdRequired />
              </TabPanel>
            )}
            <TabPanel
              className={classes.noPadding}
              value={CmTabName.TerminateContract}
              data-test="terminateContractTabPage"
            >
              <TerminateContractForm
                setShowTabsMenu={setShowTabsMenu}
                showTabsMenu={showTabsMenu}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
    </Grid>
  )
}

export default ContractManagement
