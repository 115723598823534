import React, { FunctionComponent, SVGProps } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography, makeStyles, Theme } from '@material-ui/core'
import { TFunction } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 120,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 50,
    },
  },
  info: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 12,
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '28px',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
}))

export const EmptyDataPage: React.FC<{
  messageKey: TFunction<string>
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
}> = ({ messageKey, icon }) => {
  const classes = useStyles()

  const Icon = icon

  return (
    <Grid item xs={12} className={classes.container} data-test="emptyPage">
      <Icon />
      <Typography variant={'h2'} className={classes.info}>
        {`${messageKey}`}
      </Typography>
    </Grid>
  )
}

export default EmptyDataPage
