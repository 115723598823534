import { Button, Grid } from '@material-ui/core'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { LoadingButton } from '../../components'
import { ConfirmSignatureModal } from '../../components/Common/ActionsSign2FAModal'
import {
  ActionSignature,
  ActionType,
  DebitTransaction,
  TransactionExecutionType,
  TransactionStatus,
} from '../../graphql'
import { useCurrentUser, useLimitedPerson } from '../../hooks'
import { OperationNames } from '../../types'
import { CancelScheduledTrxModal } from '../TransactionsPage/CancelScheduledTrxModal'
import { ActionSignatureProps, canSignAction, canSignOrReject } from './contract'
import { useDetailsStyles } from './useDetailsStyles'

export const DetailsFooter: FC<{
  actionSignatureProps: ActionSignatureProps
  handleBack: () => void
  type?: ActionType
}> = ({ actionSignatureProps, handleBack, type }) => {
  const { t } = useTranslation()
  const classes = useDetailsStyles()
  const currentUser = useCurrentUser()
  const history = useHistory()
  const [actionModal, setActionModal] = useState<OperationNames | undefined>()
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const { isLimitedPerson } = useLimitedPerson()

  const { rawData } = actionSignatureProps

  const signature =
    rawData?.__typename === 'DebitTransaction'
      ? (rawData as DebitTransaction)?.executionAction?.signatures?.find((s) => s?.isMine)
      : (rawData as ActionSignature)

  const canSign = useMemo(
    () =>
      rawData?.__typename === 'ActionSignature'
        ? canSignAction((rawData as ActionSignature).action)
        : canSignOrReject(currentUser?.id, actionSignatureProps.signatures),
    [currentUser, actionSignatureProps],
  )

  const isShowCloseBtn = useMemo(() => {
    const validTypes = [
      'ActionSignature',
      'DebitTransaction',
      'AccountAction',
      'AddContractAuthorizedPersonRequestAction',
      'ContractAuthorizedPersonDeactivationAction',
      'ContractChangeAction',
      'DocumentAction',
    ]

    return !canSign && validTypes.includes(rawData?.__typename as string)
  }, [canSign, rawData?.__typename])

  const handleCloseModal = useCallback(
    (success?: boolean) => {
      setActionModal(undefined)

      if (success && rawData?.__typename === 'DocumentAction') {
        history.push(history.location.pathname)
      }
    },
    [rawData?.__typename, history],
  )

  const handleCancel = async () => {
    setIsCancelModalOpen(true)
  }

  const isCanBeCanceled =
    (rawData as DebitTransaction)?.executionType === TransactionExecutionType.Delayed &&
    !canSign &&
    rawData?.id &&
    rawData.status !== TransactionStatus.Cancelled &&
    !isLimitedPerson

  return (
    <>
      {/* 2FA Modal */}
      {!!actionModal && (
        <ConfirmSignatureModal
          openAction={actionModal}
          handleClose={handleCloseModal}
          actionData={signature as ActionSignature}
          multipleActionsData={[]}
          type={type}
        />
      )}

      <CancelScheduledTrxModal
        open={isCancelModalOpen}
        setOpen={setIsCancelModalOpen}
        trxId={rawData?.id as string}
      />

      {canSign && (
        <Grid item container spacing={3} className={classes.control} data-test="groupBtn">
          <Grid item className={classes.innerControl}>
            <LoadingButton
              onClick={() => setActionModal(OperationNames.sign)}
              className={classes.btn}
              loading={false}
              variant="contained"
              color="primary"
              disableElevation
              data-test="sign"
            >
              {t('Sign', 'Sign')}
            </LoadingButton>
          </Grid>
          <Grid item className={classes.innerControl}>
            <Button
              onClick={() => setActionModal(OperationNames.reject)}
              variant="contained"
              disableElevation
              color="default"
              className={classes.btn}
              data-test="reject"
            >
              {t('Reject', 'Reject')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={3} className={classes.control}>
        {isShowCloseBtn && (
          <Grid item className={classes.innerControl}>
            <Button onClick={handleBack} variant="contained" disableElevation color="default">
              {t('close', 'Close')}
            </Button>
          </Grid>
        )}
        {isCanBeCanceled && (
          <Grid item className={classes.innerControl}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleCancel}
              className={classes.btn}
              disabled={(rawData as DebitTransaction)?.status === TransactionStatus.Completed}
            >
              {t('CancelPayment', 'Cancel payment')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}
