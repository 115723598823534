import { intersection } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressStep } from '../../Onboarding/Progress'
import { getPercentage } from '../helpers/functions'

export const CompanyProfileStepPoint: FC<{
  filledFields: string[]
  correspondenceRequired: boolean
  ErrorList: string[]
  isLimited: boolean | undefined
}> = ({ filledFields, correspondenceRequired, ErrorList, isLimited }) => {
  const { t } = useTranslation()

  const requiredCompanyProfileFields = [
    'entityType',
    'companyName',
    'registryNumber',
    'registrationDate',
    'country',
    'countryOfTaxResidence',
    'taxId',
    'streetAddress',
    'postalCode',
    'city',
    'phoneNumber',
    'urls',
    ...(isLimited ? [] : ['fullName', 'email', 'contactPersonPhoneNumber']),
    ...(correspondenceRequired
      ? [
          'countryCorrespondence',
          'streetAddressCorrespondence',
          'cityCorrespondence',
          'postalCodeCorrespondence',
        ]
      : []),
  ]

  const optionalCompanyProfileFields = [
    'tradingName',
    'additionalDetailsOptional',
    'additionalDetailsOptionalCorrespondence',
  ]

  const hasErrors = useMemo(
    () =>
      !!intersection([...requiredCompanyProfileFields, ...optionalCompanyProfileFields], ErrorList)
        .length,
    [optionalCompanyProfileFields, requiredCompanyProfileFields, ErrorList],
  )

  const isCompanyProfileCompleted = useMemo(
    () => requiredCompanyProfileFields.every((field) => filledFields.includes(field)) && !hasErrors,
    [requiredCompanyProfileFields, hasErrors],
  )

  return (
    <ProgressStep
      stage={
        isCompanyProfileCompleted
          ? 'done'
          : getPercentage(requiredCompanyProfileFields, filledFields)
      }
      error={hasErrors}
      label={t('companyProfile', 'Company profile')}
    />
  )
}
