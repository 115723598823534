import { isEmpty } from 'lodash'
import { ContractUbo, LegalEntity, UboOwner, UboStakeType } from '../../graphql'
import { ControllingPersonsTypeEnum, LegalList } from '../../types'
import { personsNestedOwners, transData } from '../../utils'

export function processLegalsList(
  owner: LegalEntity,
  ubos: ContractUbo[],
  setLegalsList: (
    value: ((prevState: Array<LegalList>) => Array<LegalList>) | Array<LegalList>,
  ) => void,
): void {
  if (!isEmpty(owner)) {
    const persons = ubos ?? []

    personsNestedOwners(persons as [ContractUbo]).then((result) => {
      const formatData = transData(result)
      setLegalsList([
        {
          key: `0`,
          label: (owner as LegalEntity).companyName,
          legalEntityType: (owner as LegalEntity).legalEntityType,
          ownersTotalShareSize:
            (ubos as ContractUbo[])?.reduce(
              (acc, val) =>
                acc +
                (val.ownershipType === UboStakeType.ManagingDirector ? 100 : val?.shareSize ?? 0),
              0,
            ) || 0,
        },
        ...formatData,
      ])
    })
  } else {
    setLegalsList([])
  }
}

export function getActiveFormType(uboData: ContractUbo | UboOwner) {
  if (uboData?.ubo?.entity?.__typename === 'LegalEntity') {
    return ControllingPersonsTypeEnum.legalOwner
  } else {
    if (uboData.ownershipType === UboStakeType.Percent25OrMore) {
      return ControllingPersonsTypeEnum.personOwner
    } else if (uboData.ownershipType === UboStakeType.Percent25OrMoreViaPoA) {
      return ControllingPersonsTypeEnum.attorneyPower
    } else {
      return ControllingPersonsTypeEnum.managingDirector
    }
  }
}
