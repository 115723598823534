import { map, filter, includes } from 'lodash'
import { countries as countriesIban } from 'iban'
import i18n from '../i18n'

import {
  countries,
  regions,
  companyTypes,
  businessCategoriesTypes,
  uboLegalEntityTypes,
  documentTypes,
  economicProfileAverageSizeTypes,
  economicProfileSourceFundsTypes,
  anticipatedAccountActivities,
  economicProfileTransactionSourceDestinationTypes,
  economicProfileProducts,
  economicProfileEmploymentStatus,
  economicProfileAccountBalance,
} from '@klarpay/enums'

import {
  AccountStatus,
  ContractStatusType,
  Currency,
  DssCompliance,
  IntegrationTypeRequired,
  RelevantCredentialOnFilesBillingSalesMethod,
  TransactionStatus,
} from '../graphql'
import { economicProfileLabels } from '../pages/AplicationActions/contract'
import {
  CardPaymentsSelectOptions,
  ContractProfileChangesType,
  DocUploadingType,
  KeyLabelType,
} from '../types'
import { AccountFilterOptions, TransactionsMethodsList } from '../types'
import { BaseEnumWithKey } from '../components'

export function addCategoriesToShortLabel(value: string, label: string): string {
  switch (value) {
    case 'doc_other_pof':
      return 'Other POF'
    case 'doc_bank_statement_por':
      return 'Bank Statement POR'
    case 'doc_bank_statement_pof':
      return 'Bank Statement POF'
    default:
      return label
  }
}

export const CompanyTypeListCheck = Object.keys(companyTypes)
export const CompanyTypeList = Object.values(companyTypes)

export const BusinessCategoriesCheck = Object.keys(businessCategoriesTypes)
export const BusinessCategoriesList = Object.values(businessCategoriesTypes)

export const uboLegalEntityTypesList = Object.values(uboLegalEntityTypes)

export const uboLegalEntityTypesListFiltered = Object.values(
  filter(uboLegalEntityTypes, (uboLegalEntityType) =>
    includes(
      [
        'operatingNotQuotedStockExchange',
        'operatingQuotedStockExchange',
        'domiciliaryHolding',
        'activeHolding',
        'trust',
        'foundation',
      ],
      uboLegalEntityType.key,
    ),
  ),
).sort((a, b) => -b.label.localeCompare(a.label))

export const CountriesCheck = Object.keys(countries)
export const CountriesList = Object.values<BaseEnumWithKey>(countries).sort(
  (a, b) => -b.label.localeCompare(a.label),
)

const UNKNOWN_OPTION = {
  key: 'unknown',
  value: 'unknown',
  label: 'Unknown',
}
export const CountriesUnknownList = [UNKNOWN_OPTION, ...CountriesList]

export const countriesIbanList = Object.keys(countriesIban)

export const OpenReasonList = ['company', 'private']

export const PartnerAccountList = ['yes', 'no', 'later']

const redundantOptionsForDocList = ['id', 'drivingLicense']

export const DocumentTypesList = map(
  filter(
    documentTypes,
    (itemDoc) => !itemDoc?.disabled && !redundantOptionsForDocList.includes(itemDoc.key),
  ),
  (docType) => ({
    key: docType.key,
    value: docType.value,
    label: addCategoriesToShortLabel(docType.value, docType.label),
  }),
)

export const UploadDocumentTypesList = map(
  filter(documentTypes, (itemDoc) => !redundantOptionsForDocList.includes(itemDoc.key)),
  (docType) => ({
    key: docType.key,
    value: docType.value,
    label: addCategoriesToShortLabel(docType.value, docType.label),
  }),
)

export const dateRequiredFieldsList = [
  'idFront',
  'idTranslation',
  'passport',
  'thirdPartyIdPassport',
  'drivingLicenseFront',
]

export const ProofOfIdentityList: {
  key: string
  label: string
}[] = [
  {
    key: 'idFront',
    label: 'ID Front',
  },
  {
    key: 'idBack',
    label: 'ID Back',
  },
  {
    key: 'drivingLicenseFront',
    label: 'Driving licence front',
  },
  {
    key: 'drivingLicenseBack',
    label: 'Driving licence back',
  },
  { key: 'passport', label: 'Passport' },
]

export const ProofOfIdentityListControlPerson: DocUploadingType[] = [
  {
    key: 'id',
    label: 'ID',
    frontSideLabel: 'ID front',
    frontSideType: 'idFront',
    backSideLabel: 'ID back',
    backSideType: 'idBack',
  },
  {
    key: 'drivingLicense',
    label: 'Driving license',
    frontSideLabel: 'Driving license front',
    frontSideType: 'drivingLicenseFront',
    backSideLabel: 'Driving license back',
    backSideType: 'drivingLicenseBack',
  },

  {
    key: 'passport',
    label: 'Passport',
    frontSideLabel: 'Passport',
    frontSideType: 'passport',
    backSideLabel: '',
    backSideType: '',
  },
]

export const ProofOfIdentityListPOA: DocUploadingType[] = [
  {
    key: 'proofOfResidence',
    label: 'Proof of address',
    frontSideLabel: 'Proof of address',
    frontSideType: 'proofOfResidence',
    backSideLabel: '',
    backSideType: '',
  },
]

export const DocsListCorrespondenceAddress: DocUploadingType[] = [
  {
    key: 'address',
    label: 'Proof of correspondence address',
    frontSideLabel: 'Proof of correspondence address',
    frontSideType: 'address',
    backSideLabel: '',
    backSideType: '',
  },
]

export const DocsListLegalNameAndTradingName: DocUploadingType[] = [
  {
    key: 'certificateOfIncorporation',
    label: 'Certificate of incorporation',
    frontSideLabel: 'Certificate of incorporation',
    frontSideType: 'certificateOfIncorporation',
    backSideLabel: '',
    backSideType: '',
  },
]
export const DocsListTaxId: DocUploadingType[] = [
  {
    key: 'certificateOfIncorporation',
    label: 'Certificate of incorporation',
    frontSideLabel: 'Certificate of incorporation',
    frontSideType: 'certificateOfIncorporation',
    backSideLabel: '',
    backSideType: '',
  },
]

export const ProofOfAddressList: {
  key: string
  label: string
}[] = [
  {
    key: 'proofOfResidence',
    label: 'Proof of address',
  },
]

export const TransactionDocumentListPerson: {
  key: string
  label: string
}[] = [
  {
    key: 'bankStatement',
    label: 'Bank Statement',
  },
  {
    key: 'proofOfSalary',
    label:
      'Proof of Salary – payslip or employee contract, tax return, employer confirmation letter',
  },
  {
    key: 'investmentCertificates',
    label: 'Investment certificates, contract notes, or statements',
  },
  {
    key: 'proofOfInheritance',
    label:
      'Proof of Inheritance – A copy of the will, a grant of probate, a letter from the solicitor',
  },
  {
    key: 'proofOfPropertySale',
    label:
      'Proof of Property Sale – A solicitor letter, a copy of the signed sales contract, extracts from the property registry',
  },
  {
    key: 'other',
    label: 'Other',
  },
]

export const TransactionDocumentListBusiness: {
  key: string
  label: string
}[] = [
  {
    key: 'bankAccountStatements',
    label: 'Bank account statements',
  },
  {
    key: 'auditedFinancialStatements',
    label: 'Audited financial statements',
  },
  {
    key: 'invoicesIssuedToCustomers',
    label: 'Invoices issued to customers',
  },
  {
    key: 'invoicesIssuedToTheClient',
    label: 'Invoices issued to the client',
  },
  {
    key: 'aggreementsContracts',
    label: 'Agreements / Contracts',
  },
  {
    key: 'loanAgreements',
    label: 'Loan Agreements',
  },
  {
    key: 'investmentStatementProofOfDividedPayment',
    label: 'Investment statement / Proof of dividend payment',
  },
  // {
  //   key: 'invoicesIssuedToClient',
  //   label: 'Invoices issued to the client',
  // },
  {
    key: 'other',
    label: 'Other',
  },
]

export const additionalDocumentOptions: {
  key: string
  label: string
}[] = [
  {
    key: 'certificateOfIncorporation',
    label: 'Certificate of incorporation',
  },
  {
    key: 'passport',
    label: 'Passport',
  },
  {
    key: 'address',
    label: 'Address',
  },
  {
    key: 'proofOfResidence',
    label: 'Proof of address',
  },
  {
    key: 'preassessmentHistoryStatementOfCurrentFormerProcessor',
    label: 'History Statement of Current/Former Processor',
  },
]

export const CreditTransactionDocumentOptions: {
  key: string
  label: string
}[] = [
  {
    key: 'contract',
    label: 'Contract',
  },
  {
    key: 'corporateDocument',
    label: 'Corporate document',
  },
  {
    key: 'invoice',
    label: 'Invoice',
  },
  {
    key: 'order',
    label: 'Order',
  },
  {
    key: 'other',
    label: 'Other',
  },
]
export const DebitTransactionDocumentOptions: {
  key: string
  label: string
}[] = [
  {
    key: 'contract',
    label: 'Contract',
  },
  {
    key: 'corporateDocument',
    label: 'Corporate document',
  },
  {
    key: 'invoice',
    label: 'Invoice',
  },
  {
    key: 'order',
    label: 'Order',
  },
  {
    key: 'payroll',
    label: 'Payroll',
  },
  {
    key: 'other',
    label: 'Other',
  },
]

export const PoiDocTypes = [
  'idFront',
  'idBack',
  'drivingLicenseFront',
  'drivingLicenseBack',
  'passport',
]
export const PoiDocTypesMandatory = ['idFront', 'drivingLicenseFront', 'passport']
export const PoiDocTypesMandatoryTwoSided = ['id', 'drivingLicense', 'passport']
export const PoiDocTypesNotMandatory = ['idBack', 'drivingLicenseBack']
export const AddressDocTypesMandatory = ['proofOfResidence']
export const TransactionDocTypesMandatory = [
  'bankStatement',
  'proofOfSalary',
  'investmentCertificatesContractNotesOrStatements',
  'proofOfInheritance',
  'proofOfPropertySale',
  'bankAccountStatements',
  'auditedFinancialStatements',
  'invoicesIssuedToCustomers',
  'invoicesIssuedToTheClient',
  'agreementsContracts',
  'loanAgreements',
  'investmentStatementProofOfDividendPayment',
  'investmentStatementProofOfDividedPayment',
  'aggreementsContracts',
  // 'invoicesIssuedToCustomers',
  'other',
  ...DebitTransactionDocumentOptions.map(({ key }) => key),
]

export const dashboardPassStatuses = [
  ContractStatusType.FirstLinePending,
  ContractStatusType.Active,
  ContractStatusType.AmloPending,
  ContractStatusType.SignaturesPending,
  ContractStatusType.ManagementPending,
]

export const dashboardClosedStatuses = [
  ContractStatusType.Rejected,
  ContractStatusType.OnboardingClosed,
  ContractStatusType.Terminated,
]

export const emptyKeyInListOfValues = '#0'

export const defaultCurrency = Currency.Chf

export const TerminateCardReasonList = [
  {
    key: 'reason1',
    label: i18n.t('reason1', 'Some reason 1'),
  },
  {
    key: 'reason2',
    label: i18n.t('reason2', 'Some reason 2'),
  },
]

export const AddressFieldsList = [
  'city',
  'streetAddress',
  'postalCode',
  'additionalDetailsOptional',
  'cityCorrespondence',
  'streetAddressCorrespondence',
  'postalCodeCorrespondence',
  'additionalDetailsOptionalCorrespondence',
]

export const economicProfileCompanySourceFundsTypesList = map(
  filter(economicProfileSourceFundsTypes, (s) => s.type.includes('legalEntity')),
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key),
  }),
).filter((itemList) => itemList.key !== economicProfileSourceFundsTypes.other.key)

export const economicProfileIndividualSourceFundsTypesList = map(
  filter(economicProfileSourceFundsTypes, (s) => s.type.includes('individual')),
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key),
  }),
)

export const EconomicProfileCurrencies = [
  {
    key: 'eur',
    label: 'EUR',
  },
  {
    key: 'chf',
    label: 'CHF',
  },
  {
    key: 'usd',
    label: 'USD',
  },
  {
    key: 'gbp',
    label: 'GBP',
  },
]

export const economicProfileAverageSizeTypesList = map(
  economicProfileAverageSizeTypes,
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key as string),
  }),
).filter(
  (item) =>
    ![
      economicProfileAverageSizeTypes.upToHundred.key,
      economicProfileAverageSizeTypes.upToFiveHundred.key,
      economicProfileAverageSizeTypes.upToMillion.key,
      economicProfileAverageSizeTypes.aboveMillion.key,
      economicProfileAverageSizeTypes.upToThreeThousand.key,
      economicProfileAverageSizeTypes.threeToTenThousand.key,
      economicProfileAverageSizeTypes.moreThanTenThousand.key,
    ].includes(item.key),
)

export const economicProfileAverageTransactionSizeTypesList = map(
  economicProfileAverageSizeTypes,
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key as string),
  }),
).filter((item) =>
  [
    economicProfileAverageSizeTypes.upToHundred.key,
    economicProfileAverageSizeTypes.upToFiveHundred.key,
    economicProfileAverageSizeTypes.upToMillion.key,
    economicProfileAverageSizeTypes.aboveMillion.key,
  ].includes(item.key),
)

export const economicProfileIndividualMonthlyTurnoverList = map(
  economicProfileAverageSizeTypes,
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key as string),
  }),
).filter((item) =>
  [
    economicProfileAverageSizeTypes.upToThreeThousand.key,
    economicProfileAverageSizeTypes.threeToTenThousand.key,
    economicProfileAverageSizeTypes.moreThanTenThousand.key,
  ].includes(item.key),
)

export const economicProfileTransferDescriptionTypesList = map(
  economicProfileTransactionSourceDestinationTypes,
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key),
  }),
).filter(
  (itemList) =>
    itemList.key !== economicProfileTransactionSourceDestinationTypes.other.key &&
    itemList.key !== economicProfileTransactionSourceDestinationTypes.payroll.key,
)

export const economicProfileTransferPayrollTypesList = map(
  economicProfileTransactionSourceDestinationTypes,
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key),
  }),
).filter((itemList) => itemList.key !== economicProfileTransactionSourceDestinationTypes.other.key)

export const selectOptions: TransactionsMethodsList[] = [
  {
    key: AccountFilterOptions.allMethods,
    label: i18n.t('allMethods', 'All methods'),
  },
  {
    key: AccountFilterOptions.bankTransfer,
    label: i18n.t('bankTransfer', 'Bank transfer'),
  },
]

export const selectOptionsForCardHolder: TransactionsMethodsList[] = [
  {
    key: AccountFilterOptions.allMethods,
    label: i18n.t('method', 'Method'),
  },
]

export const accountStatusesForTransactionsShown = [
  AccountStatus.Active,
  AccountStatus.Closed,
  AccountStatus.Suspended,
  AccountStatus.PendingClosureSignatures,
]

export function isEnabledTransactions(status: AccountStatus | null | undefined) {
  return !!status && accountStatusesForTransactionsShown.includes(status)
}

export const notProcessedPayment = [
  TransactionStatus.Cancelled,
  TransactionStatus.Declined,
  TransactionStatus.Reverted,
]

export const changesForTypes = Object.keys(ContractProfileChangesType)

export const INTERNATIONAL_CURRENCIES = [
  'AUD',
  'CAD',
  'CHF',
  'EUR',
  'GBP',
  'JPY',
  'NOK',
  'NZD',
  'SEK',
  'USD',
]

export const INTL_FX_COUNTRY_SUPPORTED_CURRENCIES: Record<string, string[]> = {
  AM: ['AMD'],
  BW: ['BWP'],
  IS: ['ISK'],
  MV: ['MVR'],
  MZ: ['MZN'],
  ZM: ['ZMW'],
  CN: ['CNY'],
  PH: ['PHP'],
  MY: ['MYR'],
  DZ: ['DZD'],
  AO: ['AOA'],
  AR: ['ARS'],
  AU: ['AUD'],
  AT: ['EUR'],
  AZ: ['AZN'],
  BS: ['BSD'],
  BH: ['BHD'],
  BD: ['BDT'],
  BY: ['BYN'],
  BE: ['EUR'],
  BO: ['BOB'],
  BR: ['BRL'],
  BG: ['EUR'],
  KH: ['KHR'],
  CA: ['CAD'],
  KY: ['KYD'],
  CL: ['CLP'],
  CO: ['COP'],
  CR: ['CRC'],
  CI: ['XOF'],
  HR: ['HRK'],
  CW: ['ANG'],
  CZ: ['CZK'],
  DK: ['DKK'],
  ID: ['IDR'],
  DO: ['DOP'],
  EC: ['USD'],
  EG: ['EGP'],
  SV: ['USD'],
  EE: ['EUR'],
  FI: ['EUR'],
  FR: ['EUR'],
  GE: ['GEL'],
  DE: ['EUR'],
  GH: ['GHS'],
  GR: ['EUR'],
  GT: ['GTQ'],
  HT: ['HTG'],
  HN: ['HNL'],
  HK: ['HKD'],
  HU: ['EUR', 'HUF'],
  IE: ['EUR'],
  IL: ['ILS'],
  IT: ['EUR'],
  JP: ['JPY'],
  JO: ['JOD'],
  KZ: ['KZT'],
  KE: ['KES'],
  KW: ['KWD'],
  LA: ['LAK'],
  LV: ['EUR'],
  LB: ['LBP'],
  LT: ['EUR'],
  LU: ['EUR'],
  MT: ['EUR'],
  MU: ['MUR'],
  MX: ['MXN'],
  MN: ['MNT'],
  MA: ['MAD'],
  NL: ['EUR'],
  NZ: ['NZD'],
  NI: ['NIO'],
  NG: ['NGN'],
  NO: ['NOK'],
  OM: ['OMR'],
  PA: ['PAB'],
  PY: ['PYG'],
  PE: ['PEN'],
  PL: ['PLN'],
  PT: ['EUR'],
  PR: ['USD'],
  QA: ['QAR'],
  RO: ['RON'],
  SA: ['SAR'],
  SN: ['XOF'],
  RS: ['RSD'],
  SG: ['SGD'],
  SI: ['EUR'],
  ZA: ['ZAR'],
  KR: ['KRW'],
  ES: ['EUR'],
  LK: ['LKR'],
  SE: ['SEK'],
  CH: ['CHF'],
  TZ: ['TZS'],
  TH: ['THB'],
  TT: ['TTD'],
  TN: ['TND'],
  TR: ['TRY'],
  UG: ['UGX'],
  UA: ['UAH'],
  AE: ['AED'],
  GB: ['GBP'],
  US: ['USD'],
  UY: ['UYU'],
  AL: ['ALL'],
  AG: ['XCD'],
  BM: ['BMD'],
  BA: ['BAM'],
  VG: ['USD'],
  CY: ['EUR'],
  GY: ['GYD'],
  JM: ['JMD'],
  SK: ['EUR'],
}

export const CURRENCY_TO_COUNTRY: Record<string, string> = Object.freeze({
  AMD: 'AM',
  BWP: 'BW',
  ISK: 'IS',
  MVR: 'MV',
  MZN: 'MZ',
  ZMW: 'ZM',
  CNY: 'CN',
  PHP: 'PH',
  MYR: 'MY',
  DZD: 'DZ',
  AOA: 'AO',
  ARS: 'AR',
  AUD: 'AU',
  EUR: 'EU',
  AZN: 'AZ',
  BSD: 'BS',
  BHD: 'BH',
  BDT: 'BD',
  BYN: 'BY',
  BOB: 'BO',
  BRL: 'BR',
  KHR: 'KH',
  CAD: 'CA',
  KYD: 'KY',
  CLP: 'CL',
  COP: 'CO',
  CRC: 'CR',
  XOF: 'SN',
  HRK: 'HR',
  ANG: 'CW',
  CZK: 'CZ',
  DKK: 'DK',
  IDR: 'ID',
  DOP: 'DO',
  USD: 'US',
  EGP: 'EG',
  GEL: 'GE',
  GHS: 'GH',
  GTQ: 'GT',
  HTG: 'HT',
  HNL: 'HN',
  HKD: 'HK',
  HUF: 'HU',
  ILS: 'IL',
  JPY: 'JP',
  JOD: 'JO',
  KZT: 'KZ',
  KES: 'KE',
  KWD: 'KW',
  LAK: 'LA',
  LBP: 'LB',
  MUR: 'MU',
  MXN: 'MX',
  MNT: 'MN',
  MAD: 'MA',
  NZD: 'NZ',
  NIO: 'NI',
  NGN: 'NG',
  NOK: 'NO',
  OMR: 'OM',
  PAB: 'PA',
  PYG: 'PY',
  PEN: 'PE',
  PLN: 'PL',
  QAR: 'QA',
  RON: 'RO',
  SAR: 'SA',
  RSD: 'RS',
  SGD: 'SG',
  ZAR: 'ZA',
  KRW: 'KR',
  LKR: 'LK',
  SEK: 'SE',
  CHF: 'CH',
  TZS: 'TZ',
  THB: 'TH',
  TTD: 'TT',
  TND: 'TN',
  TRY: 'TR',
  UGX: 'UG',
  UAH: 'UA',
  AED: 'AE',
  GBP: 'GB',
  UYU: 'UY',
  ALL: 'AL',
  XCD: 'AG',
  BMD: 'BM',
  BAM: 'BA',
  GYD: 'GY',
  JMD: 'JM',
  UZS: 'UZ',
})

export const anticipatedAccountActivitiesListKeys = Object.keys(
  filter(anticipatedAccountActivities, (a) => !a.disabled),
)
export const anticipatedAccountActivitiesListValues = Object.values(
  filter(anticipatedAccountActivities, (a) => !a.disabled),
)

export const countriesAndRegions = [...Object.values(regions), ...Object.values(countries)]

export const productsListValues = Object.values(filter(economicProfileProducts, (a) => !a.disabled))

export const YesNoOptions: KeyLabelType[] = [
  {
    key: 'Yes',
    label: i18n.t('yes', 'Yes'),
  },
  {
    key: 'No',
    label: i18n.t('no', 'No'),
  },
]
export const selectCardPaymentOptions: TransactionsMethodsList[] = [
  {
    key: CardPaymentsSelectOptions.notRequired,
    label: i18n.t('notRequired', 'Not required'),
  },
  {
    key: CardPaymentsSelectOptions.required,
    label: i18n.t('required', 'Required'),
  },
]

export const credentialDetailsList = [
  {
    key: RelevantCredentialOnFilesBillingSalesMethod.Recurring,
    label: i18n.t('recurring', 'Recurring'),
    value: RelevantCredentialOnFilesBillingSalesMethod.Recurring,
    disabled: false,
  },
  {
    key: RelevantCredentialOnFilesBillingSalesMethod.Unscheduled,
    label: i18n.t('unscheduled', 'Unscheduled'),
    value: RelevantCredentialOnFilesBillingSalesMethod.Unscheduled,
    disabled: false,
  },
  {
    key: RelevantCredentialOnFilesBillingSalesMethod.CardHolderInitiated,
    label: i18n.t('cardholderInitiated', 'Cardholder Initiated (CIT)'),
    value: RelevantCredentialOnFilesBillingSalesMethod.CardHolderInitiated,
    disabled: false,
  },
]

export const IntegrationTypeOptions: KeyLabelType[] = [
  {
    key: IntegrationTypeRequired.Payabl,
    label: i18n.t('paymentPageMsg', 'Payment page / iFrame (no further information required)'),
  },
  {
    key: IntegrationTypeRequired.Gateway,
    label: i18n.t('gatewayMsg', 'Gateway / server-to-server'),
  },
]

export const PciComplianceOptions: KeyLabelType[] = [
  {
    key: DssCompliance.Own,
    label: i18n.t('own', 'Own'),
  },
  {
    key: DssCompliance.From3rdParty,
    label: i18n.t('from3rdParty', 'From 3rd Party'),
  },
]

export const economicProfileEmploymentStatusList = map(
  economicProfileEmploymentStatus,
  (itemList) => ({
    key: itemList.key,
    label: economicProfileLabels(itemList.key),
  }),
)

export const economicProfileAccountBalanceList = map(economicProfileAccountBalance, (itemList) => ({
  key: itemList.key,
  label: economicProfileLabels(itemList.key),
}))
