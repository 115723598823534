import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Fab,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SuccessIcon from '../../../assets/images/icons/confirmed.svg?react'

import { AlertTipItem, BlackRadio, Loader } from '../../../components'
import { InputCodeField } from '../../../components/Common/InputCode'
import { EMPTY_IMAGE } from '../../../constants'
import {
  Individual,
  TwoFaMethod,
  useGetEmailAndTwoFaCategoryPreferencesQuery,
  useStartSetupTwoFaForIndividualMutation,
} from '../../../graphql'
import { AlertDataType, StepsAuth } from '../../../types'
import { copyToClipboard, formatTwoFactorSetUpCode, showError } from '../../../utils'
import { useHandlePrimary2FA } from './hooks/useHandlePrimary2FA'
import { TwoFactorAuthentication2FAModal } from './TwoFactorAuthentication2FAModal'

const useStyles = makeStyles((theme) => ({
  option: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    padding: theme.spacing(1.75, 2.5, 1.75, 2.5),
  },
  smsMethodIsNotActive: {
    border: '1px solid #ADDEC9',
    padding: theme.spacing(1, 1, 1, 1),
    borderRadius: 0,
    gap: theme.spacing(2),
    width: 496,
    height: 40,
  },
  smsMethodIsNotActiveLabel: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  optionLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  caption: {
    marginTop: theme.spacing(2),
  },
  phoneDigitsSpan: {
    fontWeight: 700,
  },
  form: {
    maxWidth: 478,
    marginBottom: '62px',
  },
  imgBox: {
    width: 240,
    height: 240,
  },
  imgCode: {
    width: '100%',
    height: '100%',
  },
  codeItem: {
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'revert',
      alignItems: 'center',
    },
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
    flexWrap: 'wrap',
  },
  label: {
    marginBottom: 0,
    marginRight: theme.spacing(0.5),
  },
  text: {
    color: '#434343',
    fontWeight: 'bold',
  },
  btnCopy: {
    minWidth: 15,
    minHeight: 15,
    width: 15,
    height: 15,
    margin: theme.spacing(0, 0, 0, 0.65),
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,
      transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .MuiSvgIcon-colorPrimary': {
      color: '#EF2828',
    },
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '496px',
    position: 'fixed',
    top: 8,
    zIndex: 9999,
    left: 0,
    right: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    '&>div': {
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  paper: {
    minWidth: 328,
    maxWidth: 552,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 4.5),
    },
    '&:focus': {
      outline: 'none',
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(2.5, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
        left: '0',
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
        left: '0',
      },
    },
  },
  buttonsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column-reverse',
    padding: theme.spacing(5, 0, 1, 0),
  },
  secondaryButton: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginRight: 0,
      maxWidth: '100%',
    },
  },
  proceedAuth: {
    width: '240px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  goBack: {
    width: '240px',
    marginRight: '24px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: '24px',
    },
  },
  verificationCaption: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  linkName: {
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 700,
  },
}))

const initialValue = { d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }

export const TwoFactorAuthenticationForm: FC<{ fromModal?: boolean }> = ({ fromModal = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [optionMode, setOptionMode] = useState<TwoFaMethod>(TwoFaMethod.Sms)
  const [authStep, setAuthStep] = useState<StepsAuth>(StepsAuth.LoadingState)
  const [alertDataCopy, setAlertDataCopy] = useState<AlertDataType>()
  const [alertData, setAlertData] = useState<AlertDataType>()
  const startInput = useRef<HTMLInputElement>(null)
  const [code, setCode] = useState(initialValue)
  const [validMessage, setValidMessage] = useState<string>('')
  const [isError, setIsError] = useState(false)
  const [actionModal, setActionModal] = useState<string | undefined>('')
  const [isFinish, setIsFinish] = useState(false)
  const [enteredCodeFinish, setEnteredCodeFinish] = useState<string>('')

  const {
    data: profileSettingsData,
    loading: profileSettingsLoading,
    error: profileSettingsError,
  } = useGetEmailAndTwoFaCategoryPreferencesQuery({})

  const [
    startSetupTwoFaForIndividualMutation,
    { data: setupTwoFaData, loading: setupTwoFaLoading },
  ] = useStartSetupTwoFaForIndividualMutation()

  const startTwoFa = async (code: string, challengeId: string) =>
    await startSetupTwoFaForIndividualMutation({
      variables: {
        code,
        challengeId,
      },
    })

  const handleClose = useCallback(
    (success: boolean) => {
      setActionModal('')
      if (!success && actionModal !== 'reset') {
        setOptionMode((prevState) =>
          prevState === TwoFaMethod.Sms ? TwoFaMethod.Totp : TwoFaMethod.Sms,
        )
      }
    },
    [setOptionMode, setActionModal, actionModal],
  )

  const handlePrimary2FA = useHandlePrimary2FA(
    setIsError,
    setValidMessage,
    setAlertData,
    setCode,
    setOptionMode,
    setAuthStep,
    handleClose,
  )

  const { imageBytes, twoFactorSetUpCode } = useMemo(() => {
    return !!setupTwoFaData
      ? {
          imageBytes: setupTwoFaData.startSetupTwoFaForIndividual?.qrCode ?? '',
          twoFactorSetUpCode: setupTwoFaData.startSetupTwoFaForIndividual?.alternativeCode ?? '',
        }
      : {
          imageBytes: EMPTY_IMAGE,
          twoFactorSetUpCode: '',
        }
  }, [setupTwoFaData])

  const isEnteredCode = useMemo(() => {
    return Object.values(code).join('').length === 6
  }, [code])

  const smsMethodIsNotActive = useMemo(() => {
    const settingsData = profileSettingsData?.viewer as Individual
    return (
      !!settingsData?.availableTwoFAMethods?.find(
        (item) => item?.method === TwoFaMethod?.Sms && !item?.isEnabled,
      ) && settingsData?.primaryTwoFAMethod === TwoFaMethod.Totp
    )
  }, [profileSettingsData])

  const endingPhoneNum = useMemo(() => {
    return (profileSettingsData?.viewer as Individual)?.phone?.slice(-2)
  }, [profileSettingsData])

  const onProceedVerificationCode = useCallback(() => {
    setAuthStep(StepsAuth.TypeVerificationCode)
  }, [])

  const handleBack = useCallback(() => {
    setCode(initialValue)
    setIsError(false)
    setValidMessage('')
    setAuthStep(StepsAuth.StartAuthenticatorApp)
  }, [])

  const onProceed = useCallback(() => {
    setIsFinish(true)
    setEnteredCodeFinish(Object.values(code).join(''))
    handlePrimary2FA(Object.values(code).join(''))
  }, [code, handlePrimary2FA])

  const renderStartAuth = useMemo(() => {
    if (setupTwoFaLoading) {
      return <Loader />
    } else if (
      authStep === StepsAuth.StartAuthenticatorApp &&
      !!twoFactorSetUpCode &&
      !!imageBytes
    ) {
      return (
        <>
          <Typography className={classes.caption} paragraph={true}>
            {t(
              'pleaseDownloadAuthenticatorAppOfYourChoice',
              'Please download an Authenticator app of your choice and install it to your iOS or Android mobile device and scan the QR code shown below.',
            )}
          </Typography>
          <Box className={classes.imgBox}>
            <img className={classes.imgCode} src={imageBytes} alt={t('qrCode', 'QR Code')} />
          </Box>
          <Box className={classes.codeItem}>
            <Typography className={classes.label}>
              {t(
                'alternativelyTypeInTheFollowingCode',
                'Alternatively, type in the following code',
              )}
              :
            </Typography>
            <Box className={classes.inner}>
              <Typography variant="body1" component="p" className={classes.text}>
                {formatTwoFactorSetUpCode(twoFactorSetUpCode)}
              </Typography>
              <Fab className={classes.btnCopy} onClick={() => handleCopy()}>
                <FileCopyOutlinedIcon color={'primary'} />
              </Fab>
            </Box>
          </Box>
          <Box display="flex">
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={!fromModal ? classes.proceedAuth : ''}
              fullWidth
              disableElevation
              onClick={onProceedVerificationCode}
            >
              {t('proceed', 'Proceed')}
            </Button>
          </Box>
        </>
      )
    } else {
      return null
    }
  }, [authStep, twoFactorSetUpCode, imageBytes, setupTwoFaLoading])

  useEffect(() => {
    if (!profileSettingsLoading) {
      if ((profileSettingsData?.viewer as Individual)?.primaryTwoFAMethod === TwoFaMethod.Totp) {
        setOptionMode(TwoFaMethod.Totp)
        setAuthStep(StepsAuth.AuthenticatorApp)
      } else {
        setOptionMode(TwoFaMethod.Sms)
        setAuthStep(StepsAuth.SMSOrCallToPhone)
      }
    }
  }, [profileSettingsLoading, profileSettingsData])

  useEffect(() => {
    if (profileSettingsError) {
      showError(profileSettingsError)
    }
  }, [profileSettingsError])

  const handleFormMode = useCallback(
    (event) => {
      const value = event.target.value
      setOptionMode(value)
      if (value === TwoFaMethod.Totp && authStep === StepsAuth.SMSOrCallToPhone) {
        setActionModal('sign')
        if (
          (profileSettingsData?.viewer as Individual)?.availableTwoFAMethods?.find(
            (option) => option?.isEnabled && option?.method === TwoFaMethod.Totp,
          )
        ) {
          setIsFinish(false)
        } else {
          setAuthStep(StepsAuth.StartAuthenticatorApp)
        }
      } else if (
        value === TwoFaMethod.Sms &&
        (authStep === StepsAuth.AuthenticatorApp || authStep === StepsAuth.StartAuthenticatorApp)
      ) {
        if ((profileSettingsData?.viewer as Individual)?.primaryTwoFAMethod === TwoFaMethod.Totp) {
          setIsFinish(false)
          setActionModal('sign')
        }

        if (!smsMethodIsNotActive) {
          setAuthStep(StepsAuth.SMSOrCallToPhone)
        }
        setAlertData(undefined)
      }
    },
    [
      setOptionMode,
      authStep,
      setAuthStep,
      setAlertData,
      profileSettingsData,
      setIsFinish,
      smsMethodIsNotActive,
    ],
  )

  const handleCopy = useCallback(() => {
    copyToClipboard(twoFactorSetUpCode)

    setAlertDataCopy({
      text: t('codeCopied', 'Code copied!'),
      type: 'success',
      icon: <SuccessIcon />,
    })

    setTimeout(() => {
      setAlertDataCopy(undefined)
    }, 3000)
  }, [twoFactorSetUpCode])

  const handleSetCode = (id: string, value: string) => {
    setIsError(false)
    setValidMessage('')
    setCode((prevState) => {
      return {
        ...prevState,
        [id]: value,
      }
    })
  }

  const handleReset = useCallback(async () => {
    try {
      setAlertData(undefined)
      setAuthStep(StepsAuth.StartAuthenticatorApp)
      setActionModal('reset')
    } catch (e) {
      toast.error((e as Error).message)
    }
  }, [])

  if (authStep === StepsAuth.LoadingState) return <Loader />

  return (
    <>
      {alertData && (
        <Box className={classes.alertContainer}>
          <AlertTipItem
            valueBold={alertData.textBold}
            value={alertData.text}
            iconComponent={alertData.icon}
            type={alertData?.type}
            isCloseButton={true}
            setIsOpenAlert={() => setAlertData(undefined)}
            isOpenAlert={!!alertData}
          />
        </Box>
      )}
      {alertDataCopy && (
        <Box className={classes.alertContainer}>
          <AlertTipItem
            value={alertDataCopy.text}
            iconComponent={alertDataCopy.icon}
            type={alertDataCopy?.type}
            isCloseButton={false}
            isOpenAlert={!!alertDataCopy}
          />
        </Box>
      )}

      {[
        StepsAuth.AuthenticatorApp,
        StepsAuth.StartAuthenticatorApp,
        StepsAuth.SMSOrCallToPhone,
      ].includes(authStep) && (
        <Box className={!fromModal ? classes.form : ''}>
          {smsMethodIsNotActive && authStep === StepsAuth.AuthenticatorApp && (
            <>
              <Box
                className={clsx(classes.option, classes.optionLink, classes.smsMethodIsNotActive)}
                mb={1.75}
              >
                <span>
                  <SuccessIcon />
                </span>
                <span className={classes.smsMethodIsNotActiveLabel}>
                  {t('smsMethodIsNotActive', 'Two-factor authentication is enabled successfully.')}
                </span>
                <Link
                  component={'span'}
                  className={classes.linkName}
                  onClick={handleReset}
                  underline="always"
                  title={t('reset', 'Reset')}
                >
                  {t('reset', 'Reset')}
                </Link>
              </Box>
            </>
          )}
          {!smsMethodIsNotActive && (
            <>
              <Typography className={classes.caption} paragraph={true}>
                {t(
                  'selectMethodTwoFactorAuthentication',
                  'Select a method for two-factor authentication:',
                )}
              </Typography>
              <RadioGroup name="formMode" value={optionMode} onChange={handleFormMode}>
                <Box className={classes.option} mb={1.75}>
                  <FormControlLabel
                    value={TwoFaMethod.Sms}
                    control={<BlackRadio size="small" disableRipple />}
                    label={
                      <>
                        <Box component="span">
                          {t('smsOrCallEnding', 'SMS or call to your phone number ending in ')}
                        </Box>
                        <Box component="span" className={classes.phoneDigitsSpan}>
                          {endingPhoneNum}
                        </Box>
                      </>
                    }
                  />
                </Box>
                <Box className={clsx(classes.option, classes.optionLink)} mb={1.75}>
                  <FormControlLabel
                    value={TwoFaMethod.Totp}
                    control={<BlackRadio size="small" disableRipple />}
                    label={t('authenticatorApp', 'Authenticator app')}
                  />
                  {authStep === StepsAuth.AuthenticatorApp && (
                    <Link
                      component={'span'}
                      className={classes.linkName}
                      onClick={handleReset}
                      underline="always"
                      title={t('reset', 'Reset')}
                    >
                      {t('reset', 'Reset')}
                    </Link>
                  )}
                </Box>
              </RadioGroup>
            </>
          )}
          {renderStartAuth}
        </Box>
      )}
      {authStep === StepsAuth.TypeVerificationCode && (
        <Box>
          <Typography className={classes.verificationCaption} paragraph={false}>
            {t(
              'typeInVerificationCodeGeneratedAuthenticator',
              'Type in the verification code generated by the Authenticator app below:',
            )}
          </Typography>
          <div
            className={clsx(
              classes.paper,
              isError && 'error',
              validMessage && !isError && 'success',
            )}
          >
            <InputCodeField
              startInput={startInput}
              currencyValue={code}
              setCode={handleSetCode}
              loading={false}
            />
            <Grid container justifyContent="center" className="infoText">
              {!!validMessage && <span>{validMessage}</span>}
            </Grid>
          </div>
          <Grid container spacing={1} className={classes.buttonsBox}>
            <Grid item className={classes.secondaryButton}>
              <Button
                type="button"
                variant="contained"
                className={classes.goBack}
                fullWidth
                disableElevation
                onClick={handleBack}
              >
                {t('back', 'Back')}
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.proceedAuth}
                fullWidth
                disableElevation
                disabled={!isEnteredCode}
                onClick={onProceed}
              >
                {t('proceed', 'Proceed')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {/* 2FA Modal */}
      {!!actionModal && (
        <TwoFactorAuthentication2FAModal
          openAction={actionModal}
          handleClose={handleClose}
          setOptionMode={setOptionMode}
          setAuthStep={setAuthStep}
          isFinish={isFinish}
          enteredCodeFinish={enteredCodeFinish}
          setAlertDataFinish={setAlertData}
          setCodeFinish={setCode}
          setIsErrorFinish={setIsError}
          setValidMessageFinish={setValidMessage}
          startTwoFa={startTwoFa}
          authStep={authStep}
        />
      )}
    </>
  )
}
