import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

import AttentionIcon from '../../assets/images/icons/icon-info2.svg?react'
import { BatchPaymentFileValidation, BatchPaymentRecord, Maybe } from '../../graphql'
import { BeneficiariesListBatch } from './BeneficiariesListBatch'
import { isEmpty } from 'lodash'
import { validPaymentsCount } from '../../graphql/local/dashboard'
import { currencyFormat } from '../../utils'
import { PaymentContext } from './PaymentContextComponent'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    margin: theme.spacing(2, 0, 3),
    fontSize: '16px',
    border: '1px solid #FFD8B5',
    width: '100%',
    // height: 90,
    padding: 10,
  },
  info: {
    paddingLeft: 18,
  },
  summary: {
    fontSize: '14px',
    fontWeight: 'normal',
  },
  uploadNewFile: {
    cursor: 'pointer',
  },
}))

export const BatchPreviewInvalid: FC<{
  data: BatchPaymentFileValidation
  removeFileHandler: () => void
}> = ({ data, removeFileHandler }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { setBatchListPage } = useContext(PaymentContext)

  const errCount = useMemo(() => {
    return data?.paymentRecords?.filter(
      (record: Maybe<BatchPaymentRecord>) => !isEmpty(record?.errors),
    ).length
  }, [data])

  const handleUploadNewFile = useCallback(() => {
    removeFileHandler()
    setBatchListPage(1)
  }, [removeFileHandler, setBatchListPage])

  useEffect(() => {
    if (data?.count && errCount && errCount > 0) {
      const liquidPayments = data?.count - errCount
      liquidPayments && validPaymentsCount(liquidPayments)
    }
  }, [data])

  return (
    <>
      <Box className={classes.container}>
        <Grid>
          <AttentionIcon />
        </Grid>
        <Grid className={classes.info}>
          <Typography>
            <b>{t('batchUploadedErr')}</b>
          </Typography>
          <Typography className={classes.summary}>
            {t('paymentInBatch') + ': '}
            <b>{data?.count}</b>
          </Typography>
          <Typography className={classes.summary}>
            {t('paymentWithErr') + ': '}
            <b>{errCount}</b>
          </Typography>
          <Typography className={classes.summary}>
            {t('totalAmountPay', 'Total amount to pay') + ': '}
            <b>{`${currencyFormat(
              data?.calculatedTotal ?? 0,
            )} ${data?.currency?.toUpperCase()}`}</b>
          </Typography>
          <Typography className={classes.uploadNewFile} onClick={handleUploadNewFile}>
            <b>
              <u>{t('uploadNewFile')}</u>
            </b>
          </Typography>
        </Grid>
      </Box>

      <Grid item xs={12}>
        {data?.paymentRecords && (
          <BeneficiariesListBatch data={data.paymentRecords} isFrontPaginated />
        )}
      </Grid>
    </>
  )
}
