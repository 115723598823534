import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import clsx from 'clsx'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ControlledIconTooltip from '../../Common/Tooltips/ControlledIconTooltip'

import { useCommonStyles } from './helpers/functions'
import { IntegrationTypeOptions, PciComplianceOptions } from '../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField, GridRow } from '../../Common'
import { IntegrationTypeRequired, PciDssCompliance } from '../../../graphql'
import { getLabelByKey } from '../../../utils'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  dirtyControl: {
    position: 'relative',
  },
  icon: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(5),
    '& .MuiSvgIcon-root': {
      color: '#999999',
      '&:hover': {
        color: '#000000',
      },
    },
  },
  tooltipOptions: {
    paddingLeft: theme.spacing(2),
  },
}))
const PciComplianceComponent: FC<{
  isViewOnly?: boolean
  changedContractProfileData?: PciDssCompliance | null
  isOnboarding?: boolean
}> = ({ isViewOnly, changedContractProfileData, isOnboarding }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const { setValue, formState, control, getValues } = useFormContext()

  const isNotIframeValueCheck =
    getValues('integrationTypeSelect') === IntegrationTypeRequired.Gateway
  const isFromPartyValueCheck = getValues('pciCompliance') !== PciComplianceOptions[0].key

  useEffect(() => {
    if (!isNotIframeValueCheck) {
      setValue('pciCompliance', PciComplianceOptions[0].key)
      setValue('pciProvidersName', '')
    }
  }, [isNotIframeValueCheck])

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5">{t('pciCompliance', 'PCI DSS Compliance')}</Typography>
      </Box>
      <GridRow ignore={isOnboarding}>
        <Controller
          as={
            <Box position="relative">
              <FormAutocompleteSelect
                name="integrationTypeSelect"
                label={t('integrationType', 'Integration type required')}
                className={clsx(classes.boxInput, classes.whiteBackground)}
                data={IntegrationTypeOptions}
                readOnly={isViewOnly}
                disableClearable
              />
              <Box className={classes.icon}>
                <ControlledIconTooltip
                  Icon={HelpOutlineIcon}
                  placement="top"
                  title={
                    <Box component="div" className={classes.textTooltip}>
                      <Typography className={classes.titleTooltip} color="inherit">
                        <b>{t('Integration Method', 'Integration Method')}:</b>
                      </Typography>
                      <Box component="div" className={classes.textTooltip}>
                        {t(
                          'pleaseSelectYourPreferredMethodForIntegratingOurSolution',
                          'Please select your preferred method for integrating our solution into your system',
                        )}
                        :
                      </Box>
                      <Box component="div" className={classes.textTooltip}>
                        <ul className={classes.tooltipOptions}>
                          <li>
                            {t(
                              'gatewayServerToServerIntegration',
                              'Gateway / Server to Server Integration: This method provides a direct connection between your server and ours, offering high flexibility and control over the integration. Important: If you choose this option, your company must be PCI DSS Compliant to ensure secure handling of payment card information in accordance with industry standards',
                            )}
                            .
                          </li>
                          <li>
                            {t(
                              'paymentPageIframeIntegration',
                              'Payment Page / I-Frame Integration: Embed our solution directly into your website using an I-Frame. This method is simpler and does not require your system to be PCI DSS Compliant, as all payment card information is handled directly by our secure platform',
                            )}
                            .
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            </Box>
          }
          name="integrationTypeSelect"
          control={control}
        />
        {changedContractProfileData?.integrationTypeRequired && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.integrationTypeSelect}
            title={getLabelByKey(
              changedContractProfileData.integrationTypeRequired[0] as string,
              IntegrationTypeOptions,
            )}
            arrowHorizontalPosition={'left'}
          />
        )}
      </GridRow>

      {isNotIframeValueCheck && (
        <GridRow ignore={isOnboarding}>
          <Controller
            as={
              <FormAutocompleteSelect
                name="pciCompliance"
                label={t('pciCompliance', 'PCI DSS Compliance')}
                className={clsx(classes.boxInput, classes.whiteBackground)}
                defaultValue={getValues('pciCompliance') ?? (PciComplianceOptions[0].key as string)}
                data={PciComplianceOptions}
                readOnly={isViewOnly}
              />
            }
            name="pciCompliance"
            control={control}
          />
          {changedContractProfileData?.dssCompliance && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.pciCompliance}
              title={getLabelByKey(
                changedContractProfileData.dssCompliance[0] as string,
                PciComplianceOptions,
              )}
              arrowHorizontalPosition={'left'}
            />
          )}
        </GridRow>
      )}

      {isFromPartyValueCheck && (
        <GridRow ignore={isOnboarding}>
          <FormControlledTextField
            label={t('providerName', 'Your PCI provider`s name')}
            name="pciProvidersName"
            type="text"
            fullWidth={true}
            multiline
            required={false}
            disabled={isViewOnly}
            data-test="pciProvidersName"
            className={classes.whiteBackground}
          />
          {changedContractProfileData?.pciProviderName && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.pciProvidersName}
              title={changedContractProfileData.pciProviderName}
              arrowHorizontalPosition={'left'}
            />
          )}
        </GridRow>
      )}
    </>
  )
}
export const PciCompliance = React.memo(PciComplianceComponent)
