// import * as Apollo from '@apollo/client'
import { FetchResult } from '@apollo/client'
// import { ApolloCache, DefaultContext, MutationFunctionOptions } from '@apollo/client'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { isEmpty, isNull } from 'lodash'
import clsx from 'clsx'
import { Box, Grid, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import IconX from '../../../assets/images/icons/icon-x.svg?react'
import IconDevice from '../../../assets/images/icons/2faIcon.svg?react'
import IconDeviceWarning from '../../../assets/images/icons/iconDeviceWarning.svg?react'

import {
  ActionSigningCodeResponse,
  GetEmailAndTwoFaCategoryPreferencesDocument,
  GetUserDataDocument,
  StartSetupTwoFaForIndividualMutation,
  TwoFaCodeDispatchMode,
  TwoFaMethod,
  useChangeIndividualPrimary2FaMethodMutation,
  useFinishSetupTwoFaForIndividualMutation,
  useGetActionSigningCodeMutation,
} from '../../../graphql'
import { resendDelay } from '../../../graphql/local'
import { checkFullCode } from '../../../utils'
import { useCurrentUser } from '../../../hooks'
import { TOTP_STRING_PARAM } from '../../../constants'
import { TwoFAModalRenderButton } from '../../../components'
import Loader from '../../../components/Common/Loader'
import { InputCodeField } from '../../../components/Common/InputCode'
import { AlertDataType, StepsAuth } from '../../../types'
import { InputCodes } from '../../../components/Common/InputCode/type'
import SuccessIcon from '../../../assets/images/icons/confirmed.svg?react'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    minWidth: 328,
    maxWidth: 552,
    padding: theme.spacing(8, 6, 9, 6),
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      minWidth: 328,
      width: 'calc(100% - 32px)',
      padding: theme.spacing(4.5, 3, 9, 3),
    },
    '&:focus': {
      outline: 'none',
    },
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(6, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
      position: 'absolute',
      left: '0',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  label: {
    textAlign: 'center',
  },
  btnClose: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('xs')]: {
      top: 5,
      right: 5,
    },
  },
}))

export const TwoFactorAuthentication2FAModal: FC<{
  openAction: string | undefined
  handleClose: (success: boolean) => void
  setOptionMode: (value: TwoFaMethod) => void
  setAuthStep: (value: StepsAuth) => void
  isFinish: boolean
  enteredCodeFinish: string
  setIsErrorFinish: (value: boolean) => void
  setValidMessageFinish: (value: string) => void
  setAlertDataFinish: (value: AlertDataType) => void
  setCodeFinish: (value: InputCodes) => void
  startTwoFa: (
    code: string,
    challengeId: string,
  ) => Promise<FetchResult<StartSetupTwoFaForIndividualMutation, Record<string, string>>>
  authStep: StepsAuth
}> = ({
  openAction,
  handleClose,
  setOptionMode,
  setAuthStep,
  isFinish,
  enteredCodeFinish,
  setIsErrorFinish,
  setValidMessageFinish,
  setAlertDataFinish,
  setCodeFinish,
  startTwoFa,
  authStep,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const initialValue = useMemo(() => ({ d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }), [])
  const [code, setCode] = useState(initialValue)
  const [isError, setIsError] = useState(false)
  const [attemptsLeft, setAttemptsLeft] = useState(0)
  const [btnDisable, setBtnDisable] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMassage, setValidMassage] = useState<string>('')
  const [successClose, setSuccessClose] = useState(false)
  const [showButton, setShowButton] = useState<boolean | null>(null)

  const startInput = useRef<HTMLInputElement>(null)
  const resultCheckFullCode = checkFullCode(code)
  const currentUser = useCurrentUser()

  const [
    getActionSigningCodeMutation,
    { data: actionServiceData, loading: actionDataLoading },
  ] = useGetActionSigningCodeMutation()

  useEffect(() => {
    if (currentUser?.primaryTwoFAMethod && isNull(showButton)) {
      setShowButton(currentUser.primaryTwoFAMethod === TwoFaMethod.Sms || openAction === 'reset')
    }
  }, [currentUser, showButton, openAction])

  const [changeIndividualPrimary2FaMethod] = useChangeIndividualPrimary2FaMethodMutation()

  const [finishSetupTwoFaForIndividualMutation] = useFinishSetupTwoFaForIndividualMutation()

  const currentAuthStep =
    currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms
      ? StepsAuth.SMSOrCallToPhone
      : StepsAuth.AuthenticatorApp

  const handleActionServiseData = useCallback(
    async (dispatchMode?: TwoFaCodeDispatchMode) => {
      try {
        const { data } = await getActionSigningCodeMutation({
          variables: { mode: dispatchMode || TwoFaCodeDispatchMode.Sms },
        })
        if (data) {
          const {
            resendTimeout,
            attemptsLeft,
          } = data?.getActionSigningCode as ActionSigningCodeResponse
          resendDelay((resendTimeout as number) * 1000)
          setAttemptsLeft(attemptsLeft as number)
        }
      } catch (e) {
        toast.error((e as Error).message)
        setAuthStep(currentAuthStep)
        handleClose(false)
      }
    },
    [getActionSigningCodeMutation, handleClose, currentAuthStep],
  )

  const onReSend = useCallback(
    async (dispatchMode?: TwoFaCodeDispatchMode) => {
      if (openAction) {
        setCode(initialValue)
        setIsError(false)
        setBtnDisable(true)
        setSecondsLeft(millisecondsToSeconds(resendDelay()))
        setValidMassage('')
        await handleActionServiseData(dispatchMode)
      }
    },
    [openAction, initialValue, handleActionServiseData],
  )

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  const closeModal = useCallback(() => {
    !successClose && setAuthStep(currentAuthStep)
    handleClose(successClose)
  }, [setAuthStep, handleClose, currentAuthStep, successClose])

  const onSendCode = useCallback(
    async (code) => {
      try {
        const challengeId =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms || openAction === 'reset'
            ? actionServiceData
              ? actionServiceData?.getActionSigningCode?.challengeId
              : ''
            : TOTP_STRING_PARAM
        const neededOption =
          currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms ? TwoFaMethod.Totp : TwoFaMethod.Sms
        if (challengeId && authStep === StepsAuth.StartAuthenticatorApp) {
          await startTwoFa(code, challengeId)
        }
        if (isFinish) {
          const { data } = await finishSetupTwoFaForIndividualMutation({
            variables: {
              token: enteredCodeFinish,
              // challengeId: challengeId ?? '',
              // code: code,
            },
            refetchQueries: [
              {
                query: GetEmailAndTwoFaCategoryPreferencesDocument,
              },
              {
                query: GetUserDataDocument,
              },
            ],
            awaitRefetchQueries: true,
          })

          if (data?.finishSetupTwoFaForIndividual) {
            setIsErrorFinish(false)
            setValidMessageFinish('')
            setAlertDataFinish({
              text: `${t(
                'pleaseUseYourAuthenticatorForLogInAndSign',
                'Please use your Authenticator app for log in and signable actions.',
              )}`,
              textBold: `${t(
                'twoFactorSuccessfullyAlert',
                'Two-factor authentication set up successfully',
              )}`,
              type: 'primary',
              icon: <SuccessIcon />,
            })
            setCodeFinish(initialValue)
            setAuthStep(StepsAuth.AuthenticatorApp)
          } else {
            setIsErrorFinish(true)
            setValidMessageFinish(t('codeIncorrect', 'Code is incorrect'))
          }
        } else {
          if (openAction !== 'reset' && authStep !== StepsAuth.StartAuthenticatorApp) {
            await changeIndividualPrimary2FaMethod({
              variables: {
                method: neededOption,
                challengeId: challengeId ?? '',
                code: code,
              },
              refetchQueries: [
                {
                  query: GetEmailAndTwoFaCategoryPreferencesDocument,
                },
                {
                  query: GetUserDataDocument,
                },
              ],
              awaitRefetchQueries: true,
            })
            setOptionMode(neededOption)
            setAuthStep(
              neededOption === TwoFaMethod.Sms
                ? StepsAuth.SMSOrCallToPhone
                : StepsAuth.AuthenticatorApp,
            )
          }
        }
        setValidMassage(t('codeCorrect', 'Code is correct'))
        setIsError(false)
        setSuccessClose(true)
      } catch (e) {
        setIsError(true)
        setValidMassage(t('codeIncorrect', 'Code is incorrect'))
      }
    },
    [
      actionServiceData,
      currentUser?.primaryTwoFAMethod,
      authStep,
      changeIndividualPrimary2FaMethod,
    ],
  )

  useEffect(() => {
    if (
      (openAction && currentUser?.primaryTwoFAMethod === TwoFaMethod.Sms) ||
      openAction === 'reset'
    ) {
      handleActionServiseData()
    }
  }, [])

  useEffect(() => {
    if (successClose) {
      const timeoutClose = setTimeout(() => {
        setCode(initialValue)
        setCodeFinish(initialValue)
        setIsError(false)
        handleClose(true)
        setSuccessClose(false)
      }, 2500)
      return () => {
        clearTimeout(timeoutClose)
        // refreshPage()
      }
    }
  }, [handleClose, successClose])

  useEffect(() => {
    const enteredCode = Object.values(code).join('')
    if (
      enteredCode.length === 6 &&
      (!isEmpty(actionServiceData) || currentUser?.primaryTwoFAMethod === TwoFaMethod.Totp)
    ) {
      if (openAction) {
        onSendCode(enteredCode)
      }
    }
  }, [code, openAction])

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>
    let oneTimeAction: ReturnType<typeof setTimeout>
    if (showButton && !resultCheckFullCode) {
      const millisecondToSecond = millisecondsToSeconds(resendDelay())
      setSecondsLeft(millisecondToSecond)
      oneTimeAction = setTimeout(() => {
        setBtnDisable(false)
      }, resendDelay())

      timer = setInterval(() => {
        setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
      }, 1000)
    }

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable, showButton, resultCheckFullCode])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMassage('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  const confirmSignatureMessage = useMemo(() => {
    return showButton ? (
      <Typography>
        {t(
          'verificationCodeInAnSMS',
          'We have sent a verification code in an SMS to your phone number ending in',
        )}{' '}
        <b>
          {!isEmpty(actionServiceData)
            ? `${actionServiceData?.getActionSigningCode?.phoneLastFourDigits?.slice(2)}`
            : ''}
        </b>
        . {t('pleaseTypeItBelow.', 'Please type it below.')}
      </Typography>
    ) : (
      <Typography className={classes.label}>{t('checkYourAuthenticatorApp')}</Typography>
    )
  }, [actionServiceData, showButton])

  return (
    <div>
      <Modal
        open={!!openAction}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {actionDataLoading ? (
          <div className={classes.paper}>
            <Loader />
          </div>
        ) : (
          <div
            id="2faModal"
            className={clsx(
              classes.paper,
              resultCheckFullCode && isError && 'error',
              validMassage && !isError && 'success',
            )}
          >
            <IconButton
              className={classes.btnClose}
              color="primary"
              aria-label="close modal"
              component="span"
              onClick={closeModal}
            >
              <IconX />
            </IconButton>

            <Grid className="iconContainer">
              {!isError ? <IconDevice /> : <IconDeviceWarning />}
            </Grid>

            <Box textAlign="center" mb={1}>
              <Typography variant={'h2'}>{t('confirmAction')}</Typography>
            </Box>

            <Box textAlign="left">{confirmSignatureMessage}</Box>

            <InputCodeField
              startInput={startInput}
              currencyValue={code}
              setCode={handleSetCode}
              loading={false}
              modalId={'2faModal'}
            />

            <Grid container justifyContent="center" className="infoText">
              {!!validMassage && resultCheckFullCode && <span>{validMassage}</span>}
            </Grid>

            <TwoFAModalRenderButton
              isNotShow={!showButton}
              secondsLeft={secondsLeft}
              btnDisable={btnDisable}
              onReSend={onReSend}
              isShowContactSupportLink={attemptsLeft < 1}
            />
          </div>
        )}
      </Modal>
    </div>
  )
}
