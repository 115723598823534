import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Fab, makeStyles, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ContractAuthorizedPersonsQuery } from '../../../graphql'
import { useIsLimitedContract } from '../../../hooks'

import { InviteFormGeneral } from '../forms/InviteFormGeneral'
import {
  AlertTipItem,
  ButtonWithTooltip,
  isModalDialogOpen as isModalDialogOpenVar,
  isModalPromptOpen,
} from '../../../components'
import { AlertDataType } from '../../../types'
import { useTeamTableUpdate } from '../hooks'
import { CONTRACT_MANAGEMENT_ALERT_TIMEOUT } from '../../../constants/alerts'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    // marginTop: '50px',
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    margin: 0,
  },
  btnWrap: {
    display: 'flex',
    maxWidth: theme.spacing(23),
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
    },
  },
  btn: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.h3,
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  modal: {
    maxWidth: 512,
    maxHeight: '90vh',
    minHeight: 300,
    overflow: 'hidden',
    outline: 'none',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      marginTop: '50px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
  },
  bodyModal: {
    padding: theme.spacing(0, 3),
    overflowY: 'auto',
    maxHeight: 'calc(90vh - 100px)',
    margin: '10px 0 24px 0',
    '& .MuiGrid-grid-sm-8': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  modalStyle1: {
    '& [tabindex]': {
      outline: 'none',
    },
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '496px',
    position: 'fixed',
    top: 8,
    zIndex: 9999,
    left: 0,
    right: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}))

export const InviteTeammate: FC<{
  personsData: ContractAuthorizedPersonsQuery | undefined
  refetch: () => void
}> = ({ personsData, refetch }) => {
  const [modalStyle] = useState(getModalStyle)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [alert, setAlert] = useState<AlertDataType>()

  useTeamTableUpdate(personsData, refetch, isOpen)

  const { t } = useTranslation()
  const classes = useStyles()

  const { isLimitedContract } = useIsLimitedContract()

  const handleOpen = useCallback(() => {
    setIsOpen(true)
    setAlert(undefined)
  }, [])

  const handleClose = useCallback(() => {
    if (isDirty) {
      isModalPromptOpen(false)
      isModalDialogOpenVar(true)
      return
    }
    setIsOpen(false)
  }, [isDirty])

  useEffect(() => {
    if (alert && !isOpen) {
      const alertTimeout = setTimeout(() => {
        setAlert(undefined)
        clearTimeout(alertTimeout)
      }, CONTRACT_MANAGEMENT_ALERT_TIMEOUT)
    }
  }, [alert, isOpen])

  return (
    <Grid item xs={12} md="auto" className={classes.wrap}>
      <Grid item className={classes.btnWrap}>
        <ButtonWithTooltip
          disabledOperationName="Inviting teammates"
          disabled={isLimitedContract}
          onClick={handleOpen}
          variant="contained"
          className={classes.btn}
          disableFocusRipple
          data-test="inviteTeammate"
          fullWidth={true}
        >
          {t('inviteTeammate', 'Invite teammate')}
        </ButtonWithTooltip>

        <Box className={classes.alertContainer} data-test="alertInvite">
          {alert && !isOpen && (
            <AlertTipItem
              value={alert?.text}
              iconComponent={alert.icon}
              type={alert.type}
              isCloseButton={true}
            />
          )}
        </Box>
        <Modal
          open={isOpen}
          onClose={handleClose}
          className={classes.modalStyle1}
          aria-labelledby="account-modal-title"
          aria-describedby="account-modal-description"
        >
          <Grid
            container
            item
            style={modalStyle}
            className={classes.modal}
            data-test="inviteTeammateModal"
          >
            <Grid item className={classes.headerModal}>
              <Typography variant={'h5'}>{t('inviteAteammate', 'Invite a teammate')}</Typography>
              <Fab
                color="default"
                aria-label="close"
                className={classes.btnCloseModal}
                onClick={handleClose}
                data-test="closeModal"
              >
                <CloseIcon />
              </Fab>
            </Grid>
            <Grid item xs={12} className={classes.bodyModal}>
              <InviteFormGeneral
                setIsOpen={setIsOpen}
                setAlert={setAlert}
                setIsDirty={setIsDirty}
              />
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </Grid>
  )
}

export default InviteTeammate
