import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

import SuccessIcon from '../../assets/images/icons/confirmed.svg?react'
import { BatchPaymentFileValidation } from '../../graphql'
import { BeneficiariesListBatch } from './BeneficiariesListBatch'
import { currencyFormat } from '../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    margin: theme.spacing(2, 0, 3),
    fontSize: '16px',
    border: '1px solid #ADDEC9',
    width: '100%',
    height: 90,
    padding: 10,
  },
  info: {
    paddingLeft: 18,
  },
  summary: {
    fontSize: '14px',
    fontWeight: 'normal',
  },
}))

export const BatchPreview = (data: BatchPaymentFileValidation): ReactElement => {
  const { t } = useTranslation()
  const classes = useStyles()

  const PaymentRecords = useMemo(() => {
    return <BeneficiariesListBatch data={data?.paymentRecords || []} isFrontPaginated />
  }, [data?.paymentRecords?.length])

  return (
    <>
      <Box className={classes.container}>
        <Grid>
          <SuccessIcon />
        </Grid>
        <Grid className={classes.info}>
          <Typography>
            <b>{t('batchUploaded')}</b>
          </Typography>
          <Typography className={classes.summary}>
            {t('paymentInBatch') + ': '}
            <b>{data?.count}</b>
          </Typography>
          <Typography className={classes.summary}>
            {t('totalAmountPay', 'Total amount to pay') + ': '}
            <b>{`${currencyFormat(
              data?.calculatedTotal ?? 0,
            )} ${data?.currency?.toUpperCase()}`}</b>
          </Typography>
        </Grid>
      </Box>
      <Grid item xs={12}>
        {PaymentRecords}
      </Grid>
    </>
  )
}
