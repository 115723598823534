import * as yup from 'yup'
import { isEmpty } from 'lodash'
import i18n from '../../i18n'
import {
  NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
  NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS,
} from '../../constants/validations'

export const DocLegalNameInputSchema = yup.object().shape({
  requiredLegalName: yup.mixed().notRequired(),
  filesForUploadLegalName: yup.mixed().notRequired(),
  documentTypeLegalName: yup
    .string()
    .test(
      'documentTypeLegalNameCheck',
      i18n.t('pleaseSelectTheDocumentType', 'Please select the document type'),
      function () {
        return !this.parent.requiredLegalName || !isEmpty(this.parent.documentTypeLegalName)
      },
    )
    .test(
      'mandatoryLegalName',
      i18n.t('proofLegalNameMandatory', 'Proof of Legal name is mandatory'),
      function () {
        return !this.parent.requiredLegalName || !isEmpty(this?.parent?.filesForUploadLegalName)
      },
    ),
  documentTypeLegalNameCustomVal: yup
    .string()
    .test(
      'documentTypeLegalNameCustomVal',
      NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
      (val) => isEmpty(val) || !!val?.match(NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS),
    ),
  documentTypeTradingNameCustomVal: yup
    .string()
    .test(
      'documentTypeTradingNameCustomVal',
      NUMBER_AND_LATINICA_ONLY_MESSAGE_2_50_SYMBOLS,
      (val) => isEmpty(val) || !!val?.match(NUMBER_AND_LATINICA_ONLY_REGEXP_FROM_2_TO_50_SYMBOLS),
    ),
})

export type DocLegalNameInput = yup.Asserts<typeof DocLegalNameInputSchema>
