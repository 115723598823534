import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { useBeforeUnload, useDebounce } from 'react-use'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { initialTeamPageState } from '../../../constants'

import { AddNewNaturalPersonInputSchema, LimitedAccessOnlyInputSchema } from '../../../schemes'
import SuccessIcon from '../../../assets/images/icons/confirmed.svg?react'
import { EmailInputSchema } from '../../../schemes/common'
import { capitalizeString, focusKeyPressNext, selectRoleInviteLabelsArray } from '../../../utils'
import {
  ConfirmationModal,
  contractsTeamPageStateVar,
  FormAutocompleteSelect,
  FormControlledTextField,
  isModalDialogOpen as isModalDialogOpenVar,
  isModalPromptOpen,
  Loader,
} from '../../../components'
import { useGetAlertText } from '../hooks'
import { InviteSignatoryForm } from './InviteSignatoryForm'
import { LimitedViewOnlyForm } from './LimitedViewOnlyForm'
import { newSelectRoleInvite } from '../../../stubs'
import { PATH_PARAMS } from '../../../routes/paths'
import { AlertDataType } from '../../../types'
import {
  ActionSignature,
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  AddContractAuthorizedPersonRequestAction,
  ContractAuthorizedPersonsCountDocument,
  ContractAuthorizedPersonsDocument,
  GetAuthorizedPersonsDocument,
  SignatoryRight,
  useAddTeamMateMutation,
  useContractAuthorizedPersonsLazyQuery,
} from '../../../graphql'
import { filter, head, isEmpty } from 'lodash'
import { ConfirmSignatureModal } from '../../../components/Common/ActionsSign2FAModal'
import Unchecked from '../../../assets/images/icons/unchecked_icon.svg?react'
import Checked from '../../../assets/images/icons/cheked_icon.svg?react'
import { useDetermineUserRights, usePageFiltersSorting } from '../../../hooks'
import { EMAIL_REGEXP } from '../../../constants/validations'

const useStyles = makeStyles((theme) => ({
  footerModal: {
    paddingTop: theme.spacing(3),
  },
  controlForm: {
    width: '100%',
    '& .MuiFormControl-root': {
      margin: 0,
    },
    '& .MuiBox-root ': {
      marginTop: 0,
    },
    '& .MuiGrid-root ': {
      padding: 0,
    },
  },
  formControl: {
    width: '100%',
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  btnControl: {
    padding: theme.spacing(1.25, 2.5),
  },
  signatory: {
    marginTop: theme.spacing(1.5),
    maxWidth: '100%',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
  },
  '@global': {
    body: {
      // Google Autocomplete +++
      '& .pac-container': {
        zIndex: 1300,
      },
    },
  },
}))

export const InviteFormGeneral: FC<{
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAlert: React.Dispatch<React.SetStateAction<AlertDataType | undefined>>
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setIsOpen, setAlert, setIsDirty }) => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId)
  const cannotSignAction = !!userRights?.limitedAccessRight || userRights?.cannotSignOwnActions

  const [signatoryRights, setSignatoryRights] = useState<string>()
  const [signNow, setSignNow] = useState<boolean>(false)
  const [actionData, setActionData] = useState<ActionSignature | undefined>()
  const [selectedUserData, setSelectedUserData] = useState<{
    firstName: string
    lastName: string
    role: string
  }>()
  const [isPersonExist, setIsPersonExist] = useState<boolean>()

  const { pageFilters, setPageFilters } = usePageFiltersSorting(contractsTeamPageStateVar)

  const { t } = useTranslation()
  const classes = useStyles()
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      isPersonExist
        ? EmailInputSchema
        : signatoryRights === newSelectRoleInvite.signatory.key
        ? AddNewNaturalPersonInputSchema
        : LimitedAccessOnlyInputSchema,
    ),
  })

  const [getPersonsData, { loading }] = useContractAuthorizedPersonsLazyQuery()

  const [addTeamMateMutation] = useAddTeamMateMutation()
  const itemsPerPage = 10

  const {
    watch,
    reset,
    formState: { isDirty, errors },
    trigger,
    clearErrors,
  } = methods
  const role = watch('role')
  const emailValue = watch('email')

  useBeforeUnload(
    isDirty,
    t('youHaveUnsavedChangesAreYouSure', 'You have unsaved changes, are you sure?'),
  )

  const getAlertText = useGetAlertText()

  const handleSignNow = useCallback(() => {
    setSignNow((prevState) => !prevState)
  }, [setSignNow])

  const handleClose = useCallback(() => {
    if (isDirty) {
      isModalPromptOpen(false)
      isModalDialogOpenVar(true)
      return
    }
    setIsOpen(false)
  }, [isDirty, setIsOpen])

  useDebounce(
    async () => {
      if (!emailValue || !EMAIL_REGEXP.test(emailValue)) return

      if (!isEmpty(errors)) {
        const fieldErrNames = Object.keys(errors)
        clearErrors(fieldErrNames)
      }

      const personsData = await getPersonsData({
        variables: {
          search: emailValue,
        },
      })

      setIsPersonExist(
        head(personsData.data?.contractAuthorizedPersons)?.person?.email === emailValue,
      )
      trigger('email').then()
    },
    700,
    [emailValue, trigger],
  )

  const handleClose2FA = (success?: boolean) => {
    if (!success && selectedUserData) {
      const { firstName, lastName, role } = selectedUserData

      const alert: AlertDataType = {
        text: getAlertText(firstName, lastName, role, false) as string,
        type: 'success',
        icon: <SuccessIcon />,
      }

      setAlert(alert)
    }

    handleClose()
  }

  const onSubmit = useCallback(
    async (formData) => {
      const {
        firstName,
        lastName,
        role,
        signatoryRight,
        groupSignature,
        email,
        birthday,
        nationality,
        phoneNumber,
        country,
        streetAddress,
        additionalDetails,
        postalCode,
        city,
      } = formData

      const capitalizedFirstName = capitalizeString(firstName)
      const capitalizedLastName = capitalizeString(lastName)
      const isAuthorized = role === newSelectRoleInvite.signatory.key

      const input = {
        person: {
          firstName: capitalizedFirstName,
          lastName: capitalizedLastName,
          email,
          birthday,
          nationality,
          phone: phoneNumber,
          address: {
            line1: streetAddress,
            city,
            zip: postalCode,
            country,
            additionalDetails,
          },
        },
        authorizationSetting: {
          isAuthorizedSignatory: isAuthorized,
          isLimitedAccess: !isAuthorized,
          ...(!isAuthorized ? { limitedAccessRight: role } : {}),
          ...(isAuthorized ? { signatoryRight } : {}),
          ...(isAuthorized && signatoryRight !== SignatoryRight.Sole
            ? { groupSize: +groupSignature || 2 }
            : {}),
        },
      }
      try {
        const { data } = await addTeamMateMutation({
          variables: {
            contractId: applicationId,
            input,
          },
          fetchPolicy: 'network-only',
          refetchQueries: [
            {
              query: ActionSignatureRequestsCountDocument,
              variables: {
                contractId: applicationId,
                statuses: [ActionSignatureStatus.Pending],
              },
            },
            {
              query: ContractAuthorizedPersonsDocument,
              variables: {
                contractId: +applicationId,
                limit: itemsPerPage,
                offset: 0,
                orderBy: initialTeamPageState.sortBy,
                orderDirection: initialTeamPageState.direction,
              },
            },
            {
              query: ContractAuthorizedPersonsCountDocument,
              variables: {
                contractId: +applicationId,
              },
            },
            {
              query: GetAuthorizedPersonsDocument,
              variables: {
                id: +applicationId,
              },
            },
            {
              query: ContractAuthorizedPersonsDocument,
              variables: pageFilters,
            },
          ],
        })

        if (!isEmpty(data?.addContractAuthorizedPersonRequest?.executionAction)) {
          const signature = (data?.addContractAuthorizedPersonRequest
            ?.executionAction as AddContractAuthorizedPersonRequestAction).signatures?.find(
            (actionSignature) => actionSignature?.isMine === true,
          )
          signature && setActionData(signature)

          setSelectedUserData({
            firstName,
            lastName,
            role,
          })
          reset()
        }

        const firstNameFromDB =
          data?.addContractAuthorizedPersonRequest?.contractAuthPerson?.person?.firstName
        const LastNameFromDB =
          data?.addContractAuthorizedPersonRequest?.contractAuthPerson?.person?.lastName

        const alert: AlertDataType = {
          text: getAlertText(
            firstNameFromDB || firstName,
            LastNameFromDB || lastName,
            role,
            signNow,
          ) as string,
          type: 'success',
          icon: <SuccessIcon />,
        }

        reset()
        setAlert(alert)
        setPageFilters({ page: 1 })
        !signNow && setIsOpen(false)
      } catch (e) {
        toast.error((e as Error).message)
      }
    },
    [
      addTeamMateMutation,
      applicationId,
      setAlert,
      setIsOpen,
      t,
      userRights,
      signNow,
      role,
      pageFilters,
    ],
  )

  useEffect(() => {
    !!role && setSignatoryRights(role)
  }, [role, setSignatoryRights])

  useEffect(() => {
    setIsDirty(isDirty)
  }, [isDirty, setIsDirty])

  useEffect(() => {
    setSignNow(!cannotSignAction)
  }, [cannotSignAction])

  const roles = filter(selectRoleInviteLabelsArray, (r) => r.show(!!userRights?.hasCardPayment))

  return (
    <Box overflow="hidden">
      <FormProvider {...methods}>
        <ConfirmationModal setOpen={setIsOpen} isGoBack={false} />
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          id="forNextFocus"
          onKeyDown={focusKeyPressNext}
        >
          <Box className={classes.controlForm} mb={2.5}>
            <FormControl className={classes.formControl}>
              <FormAutocompleteSelect
                label={t('role', 'Role')}
                name="role"
                data={roles}
                data-test="role-dropdown"
              />
            </FormControl>
          </Box>
          <FormControlledTextField
            label={t('emailAddress', 'Email Address')}
            name="email"
            type="text"
            fullWidth
            required={false}
            disabled={loading}
          />

          {loading ? (
            <Box mt={3}>
              <Loader />
            </Box>
          ) : (
            !!signatoryRights &&
            role &&
            (signatoryRights === newSelectRoleInvite.signatory.key ? (
              <Box className={clsx(classes.signatory, 'signatoryScroll')}>
                <InviteSignatoryForm isPersonExist={isPersonExist} />
              </Box>
            ) : (
              <Box>
                <LimitedViewOnlyForm isPersonExist={isPersonExist} />
              </Box>
            ))
          )}

          {role && !cannotSignAction && (
            <Box ml={'4px'} data-test="checkBox">
              <FormControlLabel
                control={
                  <Checkbox
                    name="signPayment"
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                    defaultChecked
                  />
                }
                onChange={handleSignNow}
                label={t('signActionNow')}
              />
            </Box>
          )}

          <Grid item xs={12} className={classes.footerModal}>
            <Grid container item spacing={3} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className={classes.btnControl}
                  data-test="cancel"
                >
                  {t('cancel', 'Cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!role}
                  color="primary"
                  className={classes.btnControl}
                  data-test="proceed"
                >
                  {t('proceed', 'Proceed')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      {/* 2FA Modal */}
      {!isEmpty(actionData) && signNow && (
        <ConfirmSignatureModal
          openAction={!isEmpty(actionData) ? 'sign' : undefined}
          handleClose={handleClose2FA}
          actionData={actionData}
          multipleActionsData={[]}
        />
      )}
    </Box>
  )
}
