import { Box, Chip, Link, makeStyles } from '@material-ui/core'
import React from 'react'
import { find } from 'lodash'
import { sizeInKB } from '../../utils'
import { Document, DocumentSide } from '../../graphql'
import JpgIcon from '../../assets/images/icons/jpg.svg?react'
import PdfIcon from '../../assets/images/icons/pdf.svg?react'
import PngIcon from '../../assets/images/icons/png.svg?react'
import { UploadDocumentTypesList } from '../../utils/Data'

const useStyles = makeStyles((theme) => ({
  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0),
    fontSize: '12px',
    width: '100%',
    paddingLeft: 14,
    '& .MuiAutocomplete-listbox': {
      maxHeight: '120px',
    },
    '& .MuiChip-root': {
      margin: 0,
    },
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(1),
  },
  fileLeftInfo: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 2,
    overflow: 'auto',
  },
  fileIcon: {
    padding: theme.spacing(0.5, 0, 0, 0),
  },
  fileBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileInfoName: {
    padding: theme.spacing(0, 1, 0, 1.5),
    color: '#000000',
  },
  fileInfoSize: {
    padding: theme.spacing(0, 1, 0, 1.5),
    color: '#999',
  },
  boAndDocType: {
    alignSelf: 'flex-start',
    '& .MuiChip-root': {
      width: 'auto',
    },
    flexShrink: 0,
  },

  docTypeLabel: {
    color: '#000000',
    backgroundColor: '#f0f0f0',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    fontSize: '12px',
    marginRight: 2,
  },
}))

const FileDetails: React.FC<{ side: Document | DocumentSide }> = ({ side }) => {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.fileIcon}>
        {side.mimeType === 'application/pdf' && <PdfIcon />}
        {side.mimeType === 'image/png' && <PngIcon />}
        {side.mimeType === 'image/jpeg' && <JpgIcon />}
      </Box>
      <Box className={classes.fileBlock}>
        <Box component="span" className={classes.fileInfoName}>
          {side.fileName}
        </Box>
        <Box component="span" className={classes.fileInfoSize}>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {sizeInKB(side.size as number)}&nbsp;KB
        </Box>
      </Box>
    </>
  )
}

const FileList: React.FC<{ documents: Document[] | undefined; link?: boolean }> = ({
  documents,
  link,
}) => {
  const classes = useStyles()
  if (!documents?.length) {
    return null
  }

  return (
    <>
      {documents.map((document: Document, i) => {
        const side = document.firstSide ?? document
        return (
          <Box className={classes.fileInfo} key={i}>
            <Box className={classes.infoBlock}>
              {side.url ? (
                <Link className={classes.fileLeftInfo} href={side.url} target="_blank">
                  <FileDetails side={side} />
                </Link>
              ) : (
                <Box className={classes.fileLeftInfo}>
                  <FileDetails side={side} />
                </Box>
              )}

              <Box className={classes.boAndDocType}>
                <Chip
                  className={classes.docTypeLabel}
                  label={find(UploadDocumentTypesList, (o) => o.key === document.type)?.label}
                />
              </Box>
            </Box>
          </Box>
        )
      })}
    </>
  )
}

export default FileList
