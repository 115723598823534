import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { documentTypes } from '@klarpay/enums'
import { CreditTransaction, DebitTransaction, Document } from '../../../../graphql'

import { currencyFormat, dateFormat, getCurrencySign } from '../../../../utils'

const useStyles = makeStyles(() => ({
  tableRow: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    margin: '0 0 8px 0',
  },
  name: {
    textDecoration: 'underline',
    fontSize: '14px',
    color: '#000000',
    paddingRight: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tableDoubleItem: {
    padding: '12px 0 12px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  mobileTableLabel: {
    fontSize: '12px',
    color: '#595959',
  },
  receivedDocuments: {
    fontSize: '14px',
    color: '#000000',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  type: {
    fontSize: '14px',
    color: '#000000',
  },
  added: {
    fontSize: '14px',
    color: '#000000',
  },
  tableFlexItem: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& > .MuiGrid-root': {
      maxWidth: '100%',
    },
  },
}))

export const UploadListMobile: FC<{
  data: Document | undefined
  renderFileName: (data: Document | undefined) => string
}> = ({ data, renderFileName }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleDetails = useCallback((url) => {
    const win = window.open(url, '_blank')
    if (win != null) {
      win.focus()
    }
  }, [])

  return (
    <Box className={classes.tableRow} onClick={() => handleDetails(data?.firstSide?.url)}>
      <Box>
        <Grid container className={classes.tableDoubleItem}>
          <Grid item xs={6} className={classes.tableFlexItem}>
            <Grid>
              <Box className={classes.mobileTableLabel}>{t('name', 'Name')}</Box>
              <Box className={classes.name}>{renderFileName(data)}</Box>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('relatedAccount', 'Related account')}</Box>
            <Box className={classes.receivedDocuments}>
              {data?.account ? `${data?.account?.alias}` : t('none', 'None')}
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.tableDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('relatedPayment', 'Related payment')}</Box>
            <Box className={classes.type}>
              {data?.transaction
                ? `${getCurrencySign(data?.transaction?.currency)}${currencyFormat(
                    Math.abs(data?.transaction?.value as number),
                  )} ${
                    (data?.transaction as DebitTransaction)?.to
                      ? t('toLowerCase', 'to') +
                        ' ' +
                        (data?.transaction as DebitTransaction)?.to?.name
                      : ''
                  }
              ${
                (data?.transaction as CreditTransaction)?.from
                  ? t('fromLowerCase', 'from') +
                    ' ' +
                    (data?.transaction as CreditTransaction)?.from?.name
                  : ''
              }`
                : t('none', 'None')}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('relatedPerson', 'Related person')}</Box>
            <Box className={classes.type}>
              {data?.individual
                ? `${data?.individual?.firstName} ${data?.individual?.lastName}`
                : t('none', 'None')}
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.tableDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('type', 'Type')}</Box>
            <Box className={classes.type}>
              {t(
                data?.type as string,
                find(documentTypes, (doc) => doc.key === (data?.type as string))?.label,
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('added', 'Added')}</Box>
            <Box className={classes.added}>{dateFormat(data?.createdAt)}</Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default UploadListMobile
