import Grow from '@material-ui/core/Grow'
import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form'
import { Box, FormHelperText, IconButton } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useCommonStyles } from '../NewBusinessApp/PreAssessmentQuestionnaire/helpers/functions'
import { LinkButton } from './Buttons'
import { FormControlledTextField, GridRow } from './Fields'
import { isEmpty, isEqual } from 'lodash'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { PRE_ASSESSMENT_COMMON_FIELD_MAX, URL_WEB_SITE_REGEXP } from '../../constants/validations'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flexBasis: '47%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  volume: {
    '& .MuiInputAdornment-positionEnd': {
      paddingTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
  helpText: {
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
}))

const WebsiteInputComponent: FC<{
  name: string
  label: string
  addBtnText: string
  isViewOnly?: boolean
  tooltipTitle?: NonNullable<React.ReactNode>
  helpText?: string
}> = ({ name, label, addBtnText, isViewOnly, tooltipTitle, helpText }) => {
  const classes = { ...useCommonStyles(), ...useStyles() }
  const [activeRow, setActiveRow] = useState<number | null>(null)

  const methods = useFormContext()
  const { clearErrors, formState, errors, control, trigger } = methods
  const error = errors ? errors[name] : null

  const { remove, swap, fields, append } = useFieldArray({
    control,
    name,
  })

  const onAdd = () => {
    append({ value: '' })
  }

  const onRemove = (indexToRemove: number) => {
    remove(indexToRemove)
    clearErrors(name)
  }

  const handleValidationTrigger = (i: number) => () => {
    swap(i, i)
    void trigger(name)
  }

  useEffect(() => {
    if (fields.length !== 0) return
    onAdd()
  }, [onAdd, fields.length])

  return (
    <>
      <FormProvider {...methods}>
        <Box onMouseLeave={() => setActiveRow(null)}>
          <GridRow ignore>
            {!isEmpty(fields) &&
              fields.map((site, i) => {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap={16}
                    key={site?.id}
                    onMouseEnter={setActiveRow.bind(null, i)}
                  >
                    <FormControlledTextField
                      className={clsx(classes.volume, classes.whiteBackground)}
                      label={label}
                      name={`${name}[${i}].value`}
                      fullWidth
                      required={false}
                      type="text"
                      defaultValue={site.value}
                      disabled={isViewOnly}
                      onBlur={handleValidationTrigger(i)}
                      externalError={
                        (!URL_WEB_SITE_REGEXP.test(site.value) ||
                          isEmpty(site) ||
                          site.value.length > PRE_ASSESSMENT_COMMON_FIELD_MAX) &&
                        error
                      }
                    />

                    {activeRow === i && !isViewOnly && (
                      <Grow in timeout={500}>
                        <IconButton
                          color="inherit"
                          aria-label="delete"
                          onClick={onRemove.bind(null, i)}
                          disabled={isViewOnly}
                          data-test={`autotest-removeWebsiteBtn${i}`}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Grow>
                    )}
                  </Box>
                )
              })}

            {tooltipTitle && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors[name]}
                title={tooltipTitle}
                arrowHorizontalPosition={'left'}
              />
            )}

            {helpText && <FormHelperText className={classes.helpText}>{helpText}</FormHelperText>}

            <LinkButton
              onClick={onAdd}
              disabled={isViewOnly || error}
              data-test={`autotest-${name}AddBtn`}
            >
              {addBtnText}
            </LinkButton>
          </GridRow>
        </Box>
      </FormProvider>
    </>
  )
}

export const WebsiteInput = React.memo(WebsiteInputComponent, isEqual)
