import { Box, Grid, Typography } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CardPaymentsAndAmp } from '../../../../graphql'
import { useCommonStyles } from '../helpers/functions'
import { ProcessingHistory } from '../ProcessingHistory'
import { CardsVolumes } from '../Tabs/CardsVolumes'

const useStyles = makeStyles((theme: Theme) => ({
  redError: {
    color: '#EF2828',
    position: 'initial',
  },
  dirtyTextNoUnderline: {
    width: '100%',
    color: theme.palette.warning.main,
    position: 'relative',
  },
}))

const CardsAcceptedComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: CardPaymentsAndAmp | null
}> = ({ readOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const { errors } = useFormContext()
  const totalPredictedMonthlyVolumesErrors = errors['totalPredictedMonthlyVolumes']

  return (
    <Box mt={4}>
      <Typography variant="h5">{t('cardsAccepted', 'Cards accepted')}</Typography>

      <Typography
        variant="h6"
        className={clsx(
          classes.titleSubBox,
          !!totalPredictedMonthlyVolumesErrors ? classes.redError : '',
        )}
      >
        {t('totalVolumesProcessed', 'Total predicted monthly volumes to be processed')}:
      </Typography>
      <Box mt={2}>
        <CardsVolumes isViewOnly={readOnly} isOnboarding />
      </Box>
      {(changedContractProfileData?.totalPredictedMonthlyVolumesVisa ||
        changedContractProfileData?.totalPredictedMonthlyVolumesMasterCard) && (
        <Box hidden={totalPredictedMonthlyVolumesErrors}>
          <FormHelperText className={classes.dirtyTextNoUnderline}>
            {t('changeApproved', 'Change to be approved')}
          </FormHelperText>
        </Box>
      )}
      {!!totalPredictedMonthlyVolumesErrors && (
        <Grid container>
          <FormHelperText className={classes.redError}>
            {totalPredictedMonthlyVolumesErrors.message}
          </FormHelperText>
        </Grid>
      )}

      <ProcessingHistory
        changedContractProfileData={changedContractProfileData?.processingHistory}
        isViewOnly={readOnly}
        isOnboarding
      />
    </Box>
  )
}

export const CardsAccepted = React.memo(CardsAcceptedComponent, isEqual)
