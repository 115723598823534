import { Box, Hidden, IconButton, LinearProgress, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { formatBytes, IPreviewProps } from 'react-dropzone-uploader'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import JpgIcon from '../../../../assets/images/icons/jpg.svg?react'
import PdfIcon from '../../../../assets/images/icons/pdf.svg?react'
import PngIcon from '../../../../assets/images/icons/png.svg?react'
import UploadFailed from '../../../../assets/images/icons/upload_failed.svg?react'
import { ContractInformationRequestType, DocumentType } from '../../../../graphql'
import { dateRequiredFieldsList, DocumentTypesList } from '../../../../utils/Data'
import { FormAutocompleteDatePicker } from '../../../Common/Selectors/FormAutocompleteDatePicker'
import { FormAutocompleteSearch } from '../../../Common/Selectors/FormAutocompleteSearch'
import { DocumentRequestType } from '../types'
import '../../../PersonalProfile/Tabs/styles.css'

const useStyles = (errorUpload?: boolean, descriptionError?: boolean) =>
  makeStyles((theme) => ({
    fileInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1.5, 0),
      fontSize: '12px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        borderBottom: '1px solid rgba(0,0,0,.1)',
      },
      '& .MuiAutocomplete-listbox': {
        maxHeight: '120px',
      },
    },
    fileLeftInfo: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      // width: '40%',
      // minWidth: '23%',
      maxWidth: '40%',
    },
    boAndDocType: {
      [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-start',
      },
      width: '100%',
      '& > div': {
        width: '30%',
      },
    },
    docTypeContainer: {
      display: 'flex',
      gap: 10,
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    infoBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '10px',
      [theme.breakpoints.up('md')]: {
        borderBottom: '1px solid rgba(0,0,0,.1)',
      },
    },
    fileInfoName: {
      wordBreak: 'break-all',
      padding: theme.spacing(0, 1, 0, 1.5),
      color: errorUpload ? '#e11900' : '#000000',
      overflow: 'hidden',
      maxHeight: 50,
    },
    fileInfoSize: {
      padding: theme.spacing(0, 1, 0, 1.5),
      color: '#999',
    },
    fileIcon: {
      padding: theme.spacing(0.5, 0, 0, 0),
    },
    fileBlock: {
      display: 'flex',
      flexDirection: 'column',
    },
    fileInfoProgress: {
      width: 128,
    },
    fileInfoPercent: {
      paddingLeft: theme.spacing(1),
      fontWeight: 700,
      color: '#276EF1',
    },
    controlFileButton: {
      minWidth: 44,
      fontSize: '12px',
      color: '#999',
    },
    docTypeLabel: {
      color: '#000000',
      backgroundColor: '#f0f0f0',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
      fontSize: '12px',
      marginRight: 2,
    },
    tabsBlock: {
      width: '100%',
    },
    tabsSelect: {
      display: 'flex',
      // flexFlow: 'row',
      justifyContent: 'space-between',
      // width: '100%',
      alignContent: 'stretch',
      flexWrap: 'wrap',
      width: '100%',
      // alignContents: 'center',
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
      },
    },
    tab: {
      width: '48%',
    },
    full: {
      width: '100%',
    },
    relatesTo: {
      marginTop: '12px',
      width: '100%',
    },
    comment: {
      width: '100%',
      marginTop: '20px',
      '& .MuiInputLabel-formControl': {
        color: descriptionError ? '#EF2828' : '#f0f0f0',
      },
      '& .MuiFormHelperText-root': {
        color: descriptionError ? '#EF2828' : '#f0f0f0',
      },
    },
    uploadFailed: {
      color: '#e11900',
    },
  }))()

type Props = IPreviewProps & {
  relatesToData: { key: string; label: string }[]
  docRequestType: DocumentRequestType
  defaultDocType?: DocumentType | null
}

export const Preview: React.FC<Props> = ({
  fileWithMeta,
  meta,
  relatesToData,
  defaultDocType,
  docRequestType,
}) => {
  const { id, name, percent, status, size, type } = meta
  const classes = useStyles()
  const { t } = useTranslation()
  const { control, watch, register, unregister, setValue } = useFormContext()
  const docType = watch(`documents.${id}.docType`)
  const requireExpiryDate = docType && dateRequiredFieldsList.includes(docType?.key)
  const defaultDoc = DocumentTypesList.find((value) => value.key === defaultDocType)

  useEffect(() => {
    register({ name: `documents.${id}.file` })
    return () => unregister(`documents.${id}.file`)
  }, [])

  useEffect(() => {
    if (docRequestType === ContractInformationRequestType.DocumentRequest) {
      register({ name: `documents.${id}.relatesTo` })
    }

    return () => unregister(`documents.${id}.relatesTo`)
  }, [docRequestType])

  useEffect(() => {
    if (defaultDoc) setValue(`documents.${id}.docType`, defaultDoc)
  }, [defaultDoc])

  return (
    <Box className={classes.fileInfo} data-test="fileInfo">
      <Box className={classes.infoBlock}>
        <Box className={classes.fileLeftInfo}>
          <Box className={classes.fileIcon}>
            {type === 'application/pdf' && <PdfIcon />}
            {type === 'image/png' && <PngIcon />}
            {type === 'image/jpeg' && <JpgIcon />}
          </Box>
          <Box className={classes.fileBlock}>
            <Box component="span" className={classes.fileInfoName} data-test="fileName">
              {name}
            </Box>
            <Box component="span" className={classes.fileInfoSize}>
              {formatBytes(size)}
            </Box>
          </Box>
        </Box>
        {status === 'error_upload' && (
          <>
            <Box className={classes.uploadFailed}>{t('uploadFailed', 'Upload failed')}</Box>
            <IconButton
              aria-label="reload"
              className={classes.controlFileButton}
              onClick={fileWithMeta.restart}
            >
              <UploadFailed fontSize="small" />
            </IconButton>
          </>
        )}
        {['started', 'restarted', 'uploading'].includes(status) && (
          <Box>
            <Hidden smDown>
              <Box className={classes.fileInfoProgress}>
                <LinearProgress variant="determinate" value={Math.round(percent)} />
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box component="span" className={classes.fileInfoPercent}>
                {Math.round(percent)}%
              </Box>
            </Hidden>
          </Box>
        )}
        <IconButton
          aria-label="reload"
          className={classes.controlFileButton}
          onClick={fileWithMeta.remove}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box className={classes.tabsBlock}>
        <Box className={classes.tabsSelect}>
          <Box className={requireExpiryDate ? classes.tab : classes.full} data-test="documentType">
            <Controller
              control={control}
              name={`documents.${id}.docType`}
              rules={{ required: true }}
              render={(field, { invalid }) => (
                <FormAutocompleteSearch
                  {...field}
                  data={defaultDoc ? [defaultDoc] : DocumentTypesList}
                  defaultValue={defaultDocType || ''}
                  hasError={invalid}
                  label={t(`documentType`, 'Document Type')}
                  disabled={status !== 'ready'}
                />
              )}
            />
          </Box>

          {requireExpiryDate && (
            <Box className={classes.tab} data-test="expiryDate">
              <Controller
                control={control}
                name={`documents.${id}.expiryDate`}
                rules={{
                  required: requireExpiryDate,
                  validate: (value) => requireExpiryDate && value > new Date(),
                }}
                render={(field, { invalid }) => {
                  return (
                    <FormAutocompleteDatePicker
                      {...field}
                      disablePast
                      hasError={invalid}
                      label={t('expiryDate', 'Expiry date')}
                      disabled={!requireExpiryDate || status !== 'ready'}
                    />
                  )
                }}
              />
            </Box>
          )}
        </Box>
        <Box className={classes.relatesTo} data-test="relatesTo">
          <Controller
            control={control}
            name={`documents.${id}.relatesTo`}
            render={(field, { invalid }) => (
              <FormAutocompleteSearch
                {...field}
                data={relatesToData}
                hasError={invalid}
                label={t('relatesTo', 'Relates To')}
                disabled={status !== 'ready'}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}
