import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { ILayoutProps } from 'react-dropzone-uploader'
import { useFormContext } from 'react-hook-form'
import '../../../PersonalProfile/Tabs/styles.css'
import { ErrorPanel } from './ErrorPanel'
import { DocumentRequestType } from '../types'

const useStyles = (hasError: boolean) =>
  makeStyles((theme) => ({
    DzuInput: {
      '& .dzu-dropzone': {
        height: 120,
        border: hasError ? '1px solid #fed7d2' : '1px solid #f0f0f0',
        overflow: 'hidden',
      },
    },
    filesList: {
      marginTop: theme.spacing(1.5),
      maxHeight: 500,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 250,
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        maxHeight: 120,
      },
      maxWidth: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
    },
  }))()

export const Layout: React.FC<ILayoutProps & { docRequestType: DocumentRequestType }> = ({
  files,
  input,
  previews,
  submitButton,
  dropzoneProps,
  docRequestType,
}) => {
  const {
    formState: { isValid },
  } = useFormContext()
  const classes = useStyles(!isValid || files.some((file) => file.meta.status.startsWith('error')))
  return (
    <Box className={classes.DzuInput} flexGrow={1} data-test="uploadFile">
      <div {...dropzoneProps}>{input}</div>
      <ErrorPanel files={files} docRequestType={docRequestType} />
      <Box className={classes.filesList}>{previews}</Box>
      {submitButton}
    </Box>
  )
}
