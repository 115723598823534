import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Hidden, makeStyles, Theme } from '@material-ui/core'
import { isEmpty, isEqual, isNull, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FiltersResetBtn, Loader, receivedPageStateVar } from '../../../../components'
import PagePagination from '../../../../components/Common/PagePagination'
import { initialDocsPageState } from '../../../../constants'
import {
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  BackOfficeNewDocsCountDocument,
  Document,
  DocumentStatus,
  OrderDirection,
  SystemReceivedDocsCountDocument,
  SystemReceivedDocsDocument,
  useMarkDocumentsAsReadMutation,
  useRemoveDocumentMutation,
  useSystemPinnedDocsCountQuery,
  useSystemPinnedDocsQuery,
  useSystemReceivedDocsCountQuery,
  useSystemReceivedDocsQuery,
} from '../../../../graphql'
import {
  useDetermineUserRights,
  usePageFiltersSorting,
  useRowPerPage,
  useTotalUnreadDocCount,
} from '../../../../hooks'
import { PATH_PARAMS } from '../../../../routes/paths'
import { documentsAccordionsType } from '../../../../types'
import { DocumentFilters } from '../../DocumentFilters'
import { updateDocQueryParams } from '../../helpers'
import MarkRead from './MarkRead'
import { MobileReceivedDocTable } from './MobileReceivedDocTable'
import { ReceivedDocumentsTable } from './ReceivedDocumentsTable'
import Grid from '@material-ui/core/Grid'
import { sortByBooleanProperty } from '../../../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  upSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    margin: theme.spacing(2, 0, 3, 0),
  },
  buttonSection: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))

export const ReceivedDocuments: FC = () => {
  const history = useHistory()
  const [checkedAll, setCheckedAll] = useState<boolean>(false)
  const [checkedIdArray, setCheckedIdArray] = useState<Document[]>([])
  const [currentAccordion, setCurrentAccordion] = useState<documentsAccordionsType>(
    documentsAccordionsType.pinnedDoc,
  )
  const { refetchDocsCount } = useTotalUnreadDocCount()
  const { pageFilters, setPageFilters } = usePageFiltersSorting(receivedPageStateVar)
  const isFiltered = useMemo(() => !isEqual(pageFilters, initialDocsPageState), [pageFilters])

  const [sortedReceivedDocs, setSortedReceivedDocs] = useState<Document[]>([])
  const [sortedPinnedDocs, setSortedPinnedDocs] = useState<Document[]>([])
  const { sortBy, direction, period, searchValue, documentType, page } = pageFilters

  const { itemsPerPage } = useRowPerPage()

  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)

  const queryParams = useMemo(
    () => ({
      contractId: +applicationId,
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      orderBy: sortBy,
      orderDirection: direction,
      statuses: [DocumentStatus.Accepted, DocumentStatus.PendingSignatures, DocumentStatus.Signed],
    }),
    [page, direction, sortBy, itemsPerPage],
  )

  const updatedParams = useMemo(() => updateDocQueryParams(pageFilters, queryParams), [
    queryParams,
    period,
    searchValue,
    documentType,
  ])

  const {
    data: receivedDocs,
    error: errorDocs,
    loading,
    refetch: documentsRefetch,
  } = useSystemReceivedDocsQuery({ variables: updatedParams, fetchPolicy: 'network-only' })

  const {
    data: pinnedDocs,
    error: errorPinnedDocs,
    loading: loadingPinnedDocs,
    refetch: pinnedDocsRefetch,
  } = useSystemPinnedDocsQuery({ variables: updatedParams, fetchPolicy: 'network-only' })

  const {
    data: totalDocs,
    refetch: countRefetch,
    loading: docCountLoading,
  } = useSystemReceivedDocsCountQuery({
    variables: {
      ...omit(updatedParams, ['limit', 'offset', 'orderBy', 'orderDirection']),
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: totalPinnedDocs,
    refetch: pinnedCountRefetch,
    loading: pinnedDocCountLoading,
  } = useSystemPinnedDocsCountQuery({
    variables: {
      ...omit(updatedParams, ['limit', 'offset', 'orderBy', 'orderDirection']),
    },
  })

  const isDisabled = useMemo(() => {
    const isAllReceivedDocsRead = sortedReceivedDocs.every((doc) => doc.isRead)

    const isAllPinnedDocsRead = sortedPinnedDocs.every((doc) => doc.isRead)

    const isDisabledForPinnedDocs =
      (!isEmpty(sortedPinnedDocs) || isAllPinnedDocsRead) &&
      currentAccordion === documentsAccordionsType.pinnedDoc

    const isDisabledForReceivedDocs = isAllPinnedDocsRead && isAllReceivedDocsRead

    return !isEmpty(sortedPinnedDocs) ? isDisabledForPinnedDocs : isDisabledForReceivedDocs
  }, [sortedPinnedDocs, currentAccordion, sortedReceivedDocs])

  const [markDocumentsAsReadMutation, { error: errorMark }] = useMarkDocumentsAsReadMutation()
  const [removeDocumentMutation, { error: errorRemove }] = useRemoveDocumentMutation()

  const dataLoading = useMemo(
    () => loading || docCountLoading || loadingPinnedDocs || pinnedDocCountLoading,
    [loading, docCountLoading, loadingPinnedDocs, pinnedDocCountLoading],
  )

  const refetchQueriesArr = useMemo(
    () => [
      {
        query: SystemReceivedDocsDocument,
        variables: updatedParams,
      },
      {
        query: SystemReceivedDocsCountDocument,
        variables: updatedParams,
      },
      {
        query: BackOfficeNewDocsCountDocument,
        variables: {
          contractId: +applicationId,
          status: DocumentStatus.PendingSignatures,
          statuses: [DocumentStatus.Accepted, DocumentStatus.PendingSignatures],
        },
      },
      {
        query: BackOfficeNewDocsCountDocument,
        variables: {
          contractId: +applicationId,
          status: DocumentStatus.Accepted,
          statuses: [DocumentStatus.Accepted, DocumentStatus.PendingSignatures],
        },
      },
      {
        query: ActionSignatureRequestsCountDocument,
        variables: {
          contractId: +applicationId,
          statuses: [ActionSignatureStatus.Pending],
        },
      },
    ],
    [applicationId, itemsPerPage, page, itemsPerPage, updatedParams, direction],
  )

  const canBeCheckedDocs = useMemo(() => {
    let displayingDocs
    if (!isEmpty(sortedPinnedDocs) && isEmpty(sortedReceivedDocs)) {
      displayingDocs = sortedPinnedDocs
    } else if (!isEmpty(sortedReceivedDocs) && isEmpty(sortedPinnedDocs)) {
      displayingDocs = sortedReceivedDocs
    } else {
      displayingDocs =
        currentAccordion === documentsAccordionsType.everythingElse
          ? sortedReceivedDocs
          : sortedPinnedDocs
    }

    return displayingDocs?.filter(
      ({ isRead }) => !isDisabled && (!(isRead as boolean) || userRights?.isApplicant),
    )
  }, [sortedReceivedDocs, sortedPinnedDocs, userRights, currentAccordion])

  const onMark = useCallback(
    async (isLoadDocumentId?: number) => {
      const idArrayDoc = checkedIdArray?.map((item) => item.id)
      const documentIds = typeof isLoadDocumentId === 'number' ? isLoadDocumentId : idArrayDoc
      await markDocumentsAsReadMutation({
        variables: {
          documentIds: documentIds,
        },
        refetchQueries: refetchQueriesArr,
        awaitRefetchQueries: true,
      })
        .catch((error) => {
          toast.error((error as Error).message)
          toast.error(t('savingError', 'Error saving data'))
        })
        .finally(() => {
          setCheckedAll(false)
          setCheckedIdArray([])
          void refetchDocsCount()
        })
    },
    [
      checkedIdArray,
      markDocumentsAsReadMutation,
      refetchQueriesArr,
      setCheckedAll,
      setCheckedIdArray,
      t,
    ],
  )

  const onDelete = useCallback(async () => {
    try {
      for (const idDelete of checkedIdArray) {
        await removeDocumentMutation({
          variables: {
            id: idDelete?.id,
          },
          refetchQueries: refetchQueriesArr,
          awaitRefetchQueries: true,
        })
      }

      // This hack used for updating action signatures count in DashboardDrawer component
      history.push(history.location.pathname)
    } catch (error) {
      toast.error((error as Error).message)
      toast.error(t('savingError', 'Error saving data'))
    } finally {
      setCheckedAll(false)
      setCheckedIdArray([])
      setPageFilters({ direction: OrderDirection.Descending, page: 1 })
      void refetchDocsCount()
    }
  }, [
    checkedIdArray,
    removeDocumentMutation,
    refetchQueriesArr,
    setCheckedAll,
    setCheckedIdArray,
    t,
    setPageFilters,
  ])

  const handleChange = useCallback(
    (_, value) => {
      setPageFilters({ page: value })
      setCheckedAll(false)
      setCheckedIdArray([])
    },
    [setPageFilters, setCheckedAll, setCheckedIdArray],
  )

  const clearCheckboxesData = () => {
    setCheckedAll(false)
    setCheckedIdArray([])
  }

  useEffect(() => {
    if (receivedDocs && receivedDocs.documents) {
      const result = [...(receivedDocs?.documents as Document[])]
      sortByBooleanProperty(result, 'isRead')
      setSortedReceivedDocs(result)
    }
  }, [receivedDocs?.documents])

  useEffect(() => {
    if (pinnedDocs && pinnedDocs.documents) {
      const result = [...(pinnedDocs?.documents as Document[])]
      sortByBooleanProperty(result, 'isRead')
      setSortedPinnedDocs(result)
    }
  }, [pinnedDocs?.documents])

  useEffect(() => {
    clearCheckboxesData()
  }, [pageFilters])

  useEffect(() => {
    ;(async () => {
      if (currentAccordion === documentsAccordionsType.everythingElse) {
        await documentsRefetch(updatedParams)
      } else {
        await pinnedDocsRefetch(updatedParams)
      }
    })()
  }, [documentsRefetch, pinnedDocsRefetch, applicationId, updatedParams, currentAccordion])

  useEffect(() => {
    if (searchValue || documentType || !period.some(isNull)) {
      currentAccordion === documentsAccordionsType.pinnedDoc ? pinnedCountRefetch() : countRefetch()
      setPageFilters({ page: 1 })
    }
  }, [documentType, period, searchValue, currentAccordion])

  useEffect(() => {
    if (checkedAll) {
      setCheckedIdArray(
        canBeCheckedDocs.map((element) => ({
          id: element?.id as number,
          isRead: element?.isRead as boolean,
        })),
      )
    } else {
      setCheckedIdArray([])
    }
  }, [checkedAll])

  useEffect(() => {
    if (errorMark) {
      toast.error(t('errorMarkStatement', 'Error Mark as Read Statement'))
      toast.error(errorMark.message)
    }
  }, [errorMark, t])

  useEffect(() => {
    if (errorRemove) {
      toast.error(t('errorRemovingStatement', 'Error Removing Documents Statement'))
      toast.error(errorRemove.message)
    }
  }, [errorRemove, t])

  useEffect(() => {
    if (errorDocs || errorPinnedDocs) {
      toast.error(t('errorFetchingData', 'Error fetching data'))
      toast.error(errorDocs?.message || errorPinnedDocs?.message)
    }
  }, [errorDocs, errorPinnedDocs, t])

  useEffect(() => {
    return () => {
      setPageFilters({ searchValue: '' })
    }
  }, [])

  return (
    <>
      <Box className={classes.upSection}>
        <DocumentFilters uploadedTab={false} />
        <Box className={classes.buttonSection}>
          <Grid item xs={12}>
            <MarkRead onMark={onMark} onDelete={onDelete} checkedAll={checkedIdArray} />
          </Grid>
          <FiltersResetBtn
            isDisabled={!isFiltered}
            resetPageState={() => setTimeout(() => setPageFilters(initialDocsPageState), 0)}
          />
        </Box>
      </Box>
      {dataLoading ? (
        <Loader height={'60vh'} />
      ) : (
        <>
          <Hidden mdDown>
            <ReceivedDocumentsTable
              receivedDocuments={sortedReceivedDocs}
              pinnedDocuments={sortedPinnedDocs}
              checkedAll={checkedAll}
              setCheckedAll={setCheckedAll}
              onMark={onMark}
              checkedIdArray={checkedIdArray}
              setCheckedIdArray={setCheckedIdArray}
              currentAccordion={currentAccordion}
              setCurrentAccordion={setCurrentAccordion}
              canBeCheckedCount={canBeCheckedDocs.length}
              disabled={isDisabled}
              isFiltered={isFiltered}
            />
          </Hidden>

          {/* tablet view */}
          <Hidden xsDown lgUp>
            <MobileReceivedDocTable
              receivedDocuments={sortedReceivedDocs}
              pinnedDocuments={sortedPinnedDocs}
              checkedAll={checkedAll}
              checkedIdArray={checkedIdArray}
              setCheckedIdArray={setCheckedIdArray}
              onMark={onMark}
              setCheckedAll={setCheckedAll}
              currentAccordion={currentAccordion}
              setCurrentAccordion={setCurrentAccordion}
              canBeCheckedCount={canBeCheckedDocs.length}
              disabled={isDisabled}
              isFiltered={isFiltered}
            />
          </Hidden>

          {/* mobile view */}
          <Hidden smUp>
            <MobileReceivedDocTable
              receivedDocuments={sortedReceivedDocs}
              pinnedDocuments={sortedPinnedDocs}
              checkedAll={checkedAll}
              checkedIdArray={checkedIdArray}
              setCheckedIdArray={setCheckedIdArray}
              onMark={onMark}
              setCheckedAll={setCheckedAll}
              isSmallScreen
              currentAccordion={currentAccordion}
              setCurrentAccordion={setCurrentAccordion}
              canBeCheckedCount={canBeCheckedDocs.length}
              disabled={isDisabled}
              isFiltered={isFiltered}
            />
          </Hidden>

          <PagePagination
            page={page}
            totalItems={
              totalPinnedDocs?.documentsCount &&
              currentAccordion === documentsAccordionsType.pinnedDoc
                ? totalPinnedDocs?.documentsCount
                : totalDocs?.documentsCount
            }
            handleChange={handleChange}
          />
        </>
      )}
    </>
  )
}

export default ReceivedDocuments
