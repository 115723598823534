import React, { FC, useState } from 'react'
import { Box, Button, TableCell, TableRow } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CountriesList } from '../../../../utils/Data'
import { DeleteUboResidencyModal } from './DeleteUboResidencyModal'

const useStyles = makeStyles((theme: Theme) => ({
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  description: {
    fontWeight: 'bold',
  },
  actions: {
    borderBottom: '2px solid rgba(0, 0, 0, .2)',
  },
}))

export const UboTableRowMobile: FC<{
  value: { countries: string[] }
  index: number
  setEditingUbo: React.Dispatch<
    React.SetStateAction<{ i: number; countries: string[] } | undefined>
  >
  remove: (index?: number | number[] | undefined) => void
}> = ({ value, index, setEditingUbo, remove }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleDelete = (i: number) => {
    remove(i)
    setOpenDeleteModal(false)
  }

  return (
    <>
      <TableRow>
        <TableCell className={classes.description}>{t('person', 'Person')} #</TableCell>
        <TableCell>{index + 1}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.description}>
          {t('countryOfResidence', 'Country of residence')}
        </TableCell>
        <TableCell>
          {(value?.countries ?? []).map((c) => find(CountriesList, { key: c })?.label).join(', ')}
        </TableCell>
      </TableRow>
      <TableRow className={classes.actions}>
        <TableCell className={classes.description}>{t('actions', 'Actions')}</TableCell>
        <TableCell>
          <Box className={classes.buttonsBox}>
            <Button
              type="button"
              onClick={() => setEditingUbo({ i: index, countries: value.countries })}
            >
              {t('edit', 'Edit')}
            </Button>
            <Button
              color="secondary"
              aria-label="delete"
              type="button"
              onClick={() => setOpenDeleteModal(true)}
            >
              {t('delete', 'Delete')}
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <DeleteUboResidencyModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={() => handleDelete(index)}
      />
    </>
  )
}
