import React, { FC, MouseEvent, useCallback } from 'react'
import { isEqual } from 'lodash'
import clsx from 'clsx'
import { Box, Checkbox, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Checked from '../../../assets/images/icons/cheked_icon.svg?react'
import { Unchecked } from '../../../components/Common/Unchecked'
import {
  Action,
  ActionSignature,
  ActionSignatureStatus,
  ActionType,
  Individual,
} from '../../../graphql'
import { PATH_PARAMS } from '../../../routes/paths'
import { ActionsPageStateType, OperationNames } from '../../../types'
import { dateFormat } from '../../../utils'
import { actionAmount, convertActionType, docDescriptionOrActionType } from '../contract'
import { SignatureStatus } from '../Tabs/helpers'
import { useDetermineUserRights, useHandleActionDetails, useHandleCheckBoxes } from '../../../hooks'

const useStyles = makeStyles<Theme, { disabled: boolean }>(() => ({
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
  backGround: {
    cursor: 'pointer',
    '&.checked': {
      backgroundColor: '#EFF3FE',
    },
  },
  contentWidth: {
    width: '1.5rem',
  },
  actionName: {
    textDecoration: ({ disabled }) => (disabled ? 'inherit' : 'underline'),
    maxWidth: 300,
    whiteSpace: 'normal',
  },
}))

const ActionsListRowComponent: FC<{
  data: Action
  handleSign: (data: Action, operation: OperationNames | null) => void
  handleActionsForSign: (actionData: Action, check: boolean) => void
  userData: Individual | undefined
  filtersState: ActionsPageStateType
  checkedActions: Action[]
}> = ({ data, handleSign, handleActionsForSign, userData, filtersState, checkedActions }) => {
  const [checked, disableCheck, handleCheck] = useHandleCheckBoxes(
    data,
    checkedActions,
    handleActionsForSign,
  )

  const documentData = 'document' in data && data.document
  const isDocumentDisabled = data.type === ActionType.DocumentSigning && !documentData
  const classes = useStyles({ disabled: isDocumentDisabled })
  const { handleDetails } = useHandleActionDetails(data, filtersState)

  const handleShowDetails = useCallback(() => {
    if (isDocumentDisabled) return
    handleDetails()
  }, [])

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isLimitedAccessUser = !!userRights?.limitedAccessRight

  const signatureStatus = data?.signatures?.[0]?.status
  const disableClickPropagation = useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }, [])

  const { executedAt, type, signatures, createdAt } = data
  const rejectedDate =
    signatures &&
    (signatures?.filter(
      (sign) => sign?.status === ActionSignatureStatus.Reject,
    ) as ActionSignature[])[0]?.signedAt

  const executedDate =
    signatureStatus === ActionSignatureStatus.Accept
      ? executedAt
        ? executedAt
        : signatures?.find((sign) => sign?.signatory?.person?.id === userData?.id)?.signedAt
      : null

  return (
    <TableRow
      hover
      className={clsx(
        classes.backGround,
        `${signatureStatus}`,
        `${checked && !disableCheck && 'checked'}`,
      )}
      onClick={handleShowDetails}
      data-test={'actionItem-' + signatureStatus}
    >
      {!isLimitedAccessUser && (
        <TableCell>
          <Box>
            <Checkbox
              checked={disableCheck ? false : checked}
              icon={<Unchecked />}
              checkedIcon={<Checked />}
              onChange={handleCheck}
              onClick={disableClickPropagation}
              disabled={disableCheck}
              classes={{ disabled: classes.disabledCheckbox }}
            />
          </Box>
        </TableCell>
      )}
      <TableCell className={clsx(classes.actionName)}>{docDescriptionOrActionType(data)}</TableCell>
      <TableCell>{type && convertActionType(type)}</TableCell>
      <TableCell>{actionAmount(data.__typename, data)}</TableCell>
      <TableCell className={classes.contentWidth} data-test="dateCreate">
        {' '}
        {dateFormat(createdAt)}
      </TableCell>
      <TableCell>
        {rejectedDate ? dateFormat(rejectedDate) : executedDate ? dateFormat(executedDate) : '-'}
      </TableCell>
      <TableCell width={'10%'}>
        <SignatureStatus data={data} handleSign={handleSign} />
      </TableCell>
    </TableRow>
  )
}

export const ActionsListRow = React.memo(ActionsListRowComponent, isEqual)
