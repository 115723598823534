import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty, map } from 'lodash'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Hidden,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  ContractAuthorizedPersonType,
  GetAuthorizedPersonsDocument,
  useDeleteContractAuthorizedPersonMutation,
  useGetAuthorizedPersonsQuery,
} from '../../../graphql'
import { Alerts } from './Alerts'
import { Loader, ConfirmationAnyModal } from '../../Common'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { ShowAlertType, SingleOrPlural } from '../../../types'
import { formatRights, isEnoughSignatories } from '../../../utils'
import { accessRights as acessRightsVar, goBackPathVar } from '../../../graphql/local'
import Attention from '../../../assets/images/icons/attention.svg?react'
import IconDot from '../../../assets/images/icons/Dot.svg?react'
import { useStepper } from '../../../hooks'
import { RegistrationSteps, StepperTypes } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 40,
      },
    },
    '& .MuiButton-outlinedSizeSmall': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.75, 2),
      },
    },
  },
  table: {
    borderTop: '0',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      marginBottom: theme.spacing(2),
    },
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      // borderRadius: '25px',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },

  // Mobile table
  mobileTable: {
    borderBottom: '1px solid rgba(0,0,0,.1)',
    '&.bordered': {
      border: '1px solid rgba(0,0,0,.1)',
      borderBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      borderBottom: 0,
    },
  },
  mobileTableRow: {
    marginBottom: theme.spacing(0),
    '&:not(:first-child)': {
      // borderTop: '1px solid rgba(0,0,0,.1)',
      borderTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      '&:not(:first-child)': {
        borderTop: '1px solid rgba(0,0,0,.1)',
      },
    },
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
    // '&:nth-child(n+4)': {
    //   [theme.breakpoints.down('xs')]: {
    //     borderBottom: '0',
    //   },
    // },
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
  btnWrapp: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const PersonsAccessTab: FC<{
  onDelete?: () => void
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setReviewPersonVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setCanProceed?: React.Dispatch<React.SetStateAction<boolean>>
  clickedProceed?: boolean
}> = ({ setOpen, setReviewPersonVisible, setCanProceed, clickedProceed }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [openDelete, setOpenDelete] = useState(0)
  const [persons, setPersons] = useState<ContractAuthorizedPersonType[]>()
  const [alertData, setAlertData] = useState<ShowAlertType[]>([])
  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { currentStep } = useStepper(StepperTypes.registration)

  const [deleteContractAuthorizedPersonMutation] = useDeleteContractAuthorizedPersonMutation()
  const { data, loading } = useGetAuthorizedPersonsQuery({
    variables: { id: +applicationId },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const authorizedPersons = data?.contract?.authorizedPersons as ContractAuthorizedPersonType[]
    if (!loading && authorizedPersons) {
      setPersons(authorizedPersons)
      setReviewPersonVisible && setReviewPersonVisible(!isEmpty(authorizedPersons))
      const [canProcess, alerts] = isEnoughSignatories(authorizedPersons)
      if (setCanProceed) {
        setCanProceed(canProcess)
        if (!canProcess && clickedProceed) {
          setAlertData(
            map(
              alerts,
              (alertItem) =>
                ({
                  key: alertItem.key,
                  value: alertItem.value,
                  messageParam1: alertItem.messageParam1,
                  messageParam2:
                    alertItem.messageParam2 === SingleOrPlural.Plural
                      ? t(SingleOrPlural.Plural, 's have')
                      : t(SingleOrPlural.Single, ' has'),
                  messageParam3: alertItem.messageParam3,
                } as ShowAlertType),
            ),
          )
        } else {
          setAlertData([])
        }
      }
    }
  }, [data, loading, clickedProceed])

  const handleModalDelete = useCallback((id) => {
    setOpenDelete(id)
  }, [])

  const onConfirmDelete = useCallback((id) => {
    deleteContractAuthorizedPersonMutation({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: GetAuthorizedPersonsDocument,
          variables: { id: +applicationId },
        },
      ],
      awaitRefetchQueries: true,
    })
    setOpenDelete(0)
  }, [])

  const handleClose = useCallback(
    (modalName: string) => {
      if (modalName === 'delete') {
        setOpenDelete(0)
      }
    },
    [setOpenDelete],
  )

  const onEdit = useCallback(
    (id, authorised) => {
      acessRightsVar(authorised)
      goBackPathVar(history.location.pathname)
      history.push(
        generatePath(APP_PATHS.application.authorizedPersons.edit, {
          [PATH_PARAMS.applicationId]: +applicationId,
          [PATH_PARAMS.authPersonId]: +id,
        }),
      )
    },
    [setOpen, setReviewPersonVisible],
  )

  const onAdd = useCallback(() => {
    acessRightsVar('full')
    history.push(
      generatePath(APP_PATHS.application.authorizedPersons.add, {
        [PATH_PARAMS.applicationId]: +applicationId,
      }),
      {
        from: 'review',
      },
    )
  }, [])

  useEffect(() => {
    if (persons && !persons.length) {
      goBackPathVar('')
      history.push(
        generatePath(APP_PATHS.application.authorizedPersons.add, {
          [PATH_PARAMS.applicationId]: applicationId,
        }),
      )
    }
  }, [persons])

  return (
    <>
      <Box className={classes.head}>
        <Typography
          variant={'h2'}
          className={classes.title}
          data-test="personsWhoWillHaveAccessToYourAccounts"
        >
          {t(
            'personsWhoWillHaveAccessToYourAccounts',
            'Persons who will have access to your accounts',
          )}
        </Typography>
        {currentStep !== RegistrationSteps.authorizedPersons && (
          <Box className={classes.btnWrapp}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={onAdd}
              disableElevation
              size="small"
              data-test="addNewAccount-btn"
            >
              {t('addNew', 'Add new')}
            </Button>
          </Box>
        )}
      </Box>
      {loading ? (
        <Loader height={100} />
      ) : (
        <Hidden xsDown>
          <Alerts alertData={alertData} />
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('name', 'Name')}</TableCell>
                  <TableCell width={168}>{t('role', 'Role')}</TableCell>
                  <TableCell width={168}>{t('rights', 'Rights')}</TableCell>
                  <TableCell width={168}>{t('actions', 'Actions')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {persons &&
                  persons.map((value) => {
                    const {
                      id,
                      isAuthorizedSignatory,
                      signatoryRight,
                      limitedAccessRight,
                      person,
                      groupSize,
                    } = value
                    return (
                      <TableRow hover key={id} data-test="personAccount">
                        <TableCell>
                          {person?.firstName} {person?.lastName}
                        </TableCell>
                        <TableCell>
                          {isAuthorizedSignatory
                            ? t('signatory', 'Signatory')
                            : t('limited', 'Limited')}
                        </TableCell>
                        <TableCell>
                          {isAuthorizedSignatory
                            ? formatRights(signatoryRight as string, groupSize ?? 0)
                            : formatRights(limitedAccessRight as string)}
                        </TableCell>
                        <TableCell>
                          <Box className={classes.buttonsBox}>
                            <Button
                              aria-label="Edit"
                              type="button"
                              onClick={() => onEdit(id, isAuthorizedSignatory ? 'full' : 'limited')}
                              data-test="personsAccountsEdit-btn"
                            >
                              {t('edit', 'Edit')}
                            </Button>
                            <IconDot />
                            <Button
                              color="secondary"
                              aria-label="delete"
                              type="button"
                              onClick={() => handleModalDelete(id)}
                              data-test="personsAccountsDelete-btn"
                            >
                              {t('delete', 'Delete')}
                            </Button>

                            <ConfirmationAnyModal
                              title={t('delete', 'Delete')}
                              color={'error'}
                              icon={<Attention />}
                              name="delete"
                              handleClose={() => handleClose('delete')}
                              handleConfirm={() => onConfirmDelete(id)}
                              isOpen={openDelete === ((id as unknown) as number)}
                              labelCancel={t('cancel', 'Cancel')}
                              labelConfirm={t('delete', 'Delete')}
                            >
                              <Typography>
                                {t(
                                  'deleteUboConfirm',
                                  'Are you sure you want to delete this Authorised person',
                                )}
                                ?
                              </Typography>
                            </ConfirmationAnyModal>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
      )}
      {/* mobile table */}
      <Hidden smUp>
        <Alerts alertData={alertData} />
        <Box className={classes.mobileTable}>
          {persons &&
            persons.map((value, i) => {
              const {
                id,
                isAuthorizedSignatory,
                signatoryRight,
                limitedAccessRight,
                person,
              } = value
              return (
                <Box key={i} className={classes.mobileTableRow}>
                  <Box className={classes.mobileTableItem}>
                    <Box className={classes.mobileTableLabel}>{t('name', 'Name')}</Box>
                    <Box>
                      {person?.firstName} {person?.lastName}
                    </Box>
                  </Box>

                  <Box className={classes.mobileTableItem}>
                    <Box className={classes.mobileTableLabel}>{t('role', 'Role')}</Box>
                    <Box>
                      {isAuthorizedSignatory
                        ? t('signatory', 'Signatory')
                        : t('limited', 'Limited')}
                    </Box>
                  </Box>

                  <Box className={classes.mobileTableItem}>
                    <Box className={classes.mobileTableLabel}>{t('rights', 'Rights')}</Box>
                    <Box>
                      {isAuthorizedSignatory
                        ? formatRights(signatoryRight as string)
                        : formatRights(limitedAccessRight as string)}
                    </Box>
                  </Box>

                  <Box className={classes.mobileTableItem}>
                    <Box className={classes.buttonsBox}>
                      <Button
                        aria-label="Edit"
                        type="button"
                        onClick={() => onEdit(id, isAuthorizedSignatory)}
                      >
                        {t('edit', 'Edit')}
                      </Button>
                      <IconDot />
                      <Button
                        color="secondary"
                        aria-label="delete"
                        type="button"
                        onClick={() => onConfirmDelete(id)}
                      >
                        {t('delete', 'Delete')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            })}
        </Box>
      </Hidden>
    </>
  )
}
