import * as yup from 'yup'
import i18n from '../i18n'
import { hasInvalidIps, prepareIpsArray } from '../utils'
import { isEmpty } from 'lodash'

export const ipInputSchema = yup.object().shape({
  ipUser: yup.string(),
})

export const apiUserSchema = yup.object().shape({
  apiUserName: yup
    .string()
    .trim()
    .required(i18n.t('apiUserNameIsRequiredField', 'API User Name is a required field')),
  apiAllowedIps: yup
    .string()
    .trim()
    .test(
      'isAllowedIps',
      i18n.t('oneIPsIsInvalid', 'One of the supplied IPs is not valid'),
      (val) => {
        const arrOfIps = prepareIpsArray(val || '')
        return !hasInvalidIps(arrOfIps) && !isEmpty(val)
      },
    ),
})

export const developersTechnicalInputSchema = yup.object().shape({
  paymentWebhook: yup
    .string()
    .url(i18n.t('paymentWebhookIsNotValid', 'Payment Webhook is not valid')),
  webhookSignatureKey: yup
    .string()
    .min(32, 'Webhook signature key must be at least 32 chars long')
    .required('Webhook signature key is required'),
})
