import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload } from 'react-use'
import { isEmpty } from 'lodash'

import { Box, Button, Collapse, Typography } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { useReactiveVar } from '@apollo/client'

import { goBackPathVar, goNextPathVar, poiNeeded, totalShare } from '../../../graphql/local'
import {
  AddressAutocomplete,
  AlertTipItem,
  CommonTipItem,
  CustomPrompt,
  FormAutocompleteSelect,
  FormControlledTextField,
  FormDatePickerField,
  GridRow,
  Loader,
  LoadingButton,
  nationalitiesArray,
} from '../../Common'
import {
  BeneficialOwnerValidationSchema,
  DomBeneficialOwnerValidationSchema,
} from '../../../schemes'
import {
  AddContractUboInputType,
  Document,
  DocumentType,
  GetUbosListDocument,
  Individual,
  UboOwner,
  UboStakeType,
  UboType,
  useAddContractUboMutation,
  useAddUboOwnerMutation,
  useContractIndividualOwnersQuery,
  useCreateOwnDocumentMutation,
  useCreateOwnDocumentTwoSidedMutation,
  useGetUboDetailsLazyQuery,
  useGetUboOwnersDetailsLazyQuery,
  useRemoveDocumentMutation,
  useUpdateContractUboMutation,
  useUpdateUboOwnerMutation,
} from '../../../graphql'
import InformationIcon from '../../../assets/images/icons/info_icon.svg?react'
import {
  AuthPersonsSelectType,
  ControllingPersonDefaultValues,
  ControllingPersonsTypeEnum,
  CreateOwnDocMutationType,
  OwnerTypeFormPropsType,
} from '../../../types'
import {
  createOwnDoc,
  extractDateValue,
  focusKeyPressNext,
  isEditingPoiNeeded,
  scrollToInputElement,
} from '../../../utils'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import { useCurrentUser } from '../../../hooks'
import { registeredAddressEmptyProps } from '../../../stubs'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ProofOfIdentityControllingPerson } from './ProofOfIdentityControllingPerson'
import { PoiDocTypesMandatoryTwoSided, YesNoOptions } from '../../../utils/Data'
import NavigationPrompt from 'react-router-navigation-prompt'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(2, 0),
  },
  info: {
    marginTop: 20,
    border: '1px solid #D4E2FC',
    fontSize: 14,
    '&>div': {
      padding: '5px 10px',
    },
  },
}))

const defaultValues: ControllingPersonDefaultValues = {
  additionalDetailsOptional: '',
  authorizedPerson: undefined,
  birthday: undefined,
  city: '',
  controllingPerson: undefined,
  country: undefined,
  documentsAlreadyUploaded: undefined,
  documentType: undefined,
  filesForUpload: [],
  firstName: '',
  lastName: '',
  nationality: undefined,
  ownershipPercent: '',
  politicallyExposedPerson: undefined,
  postalCode: '',
  streetAddress: ' ',
  taxId: '',
  usReportablePerson: undefined,
}

export const ControllingPersonDataForm: FC<OwnerTypeFormPropsType> = ({
  isDomiciliary,
  isNewRecord,
  applicationId,
  uboId,
  ownedUboId,
  type,
  formMode,
  ownersList,
  children,
  contractData,
}) => {
  const user = useCurrentUser()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const goBackPath = useReactiveVar(goBackPathVar)
  const goNextPath = useReactiveVar(goNextPathVar)
  const poiNeededVar = useReactiveVar(poiNeeded)

  const [allowNavigation, setAllowNavigation] = useState(false)

  const schema = isDomiciliary
    ? DomBeneficialOwnerValidationSchema
    : BeneficialOwnerValidationSchema

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const { formState, watch, setValue, register, getValues, trigger } = methods

  const authorizedPerson = watch('authorizedPerson')
  const addAnother = watch('addAnotherPerson')
  const filesForUpload = watch(`filesForUpload`)
  const usReportablePerson = watch('usReportablePerson')

  const [addContractUBO] = useAddContractUboMutation()
  const [addUboOwnerMutation] = useAddUboOwnerMutation()
  const [updateContractUboMutation] = useUpdateContractUboMutation()
  const [updateUboOwnerMutation] = useUpdateUboOwnerMutation()
  const [createOwnDocument] = useCreateOwnDocumentTwoSidedMutation()
  const [createOwnDocumentOneSide] = useCreateOwnDocumentMutation()
  const [removeDocument] = useRemoveDocumentMutation()

  const [GetUboDetailsQuery, { data: contractUboData }] = useGetUboDetailsLazyQuery()

  const [
    GetUboOwnersDetailsQuery,
    { data: uboOwnersDetails, loading: uboOwnerDetailsLoading },
  ] = useGetUboOwnersDetailsLazyQuery({ fetchPolicy: 'network-only' })

  const { loading: ownerDataLoading } = useContractIndividualOwnersQuery({
    variables: {
      id: +applicationId,
    },
    skip: !applicationId,
  })

  const shareSizeSummary = useMemo(() => {
    return (
      contractData?.contract?.ubos?.reduce(
        (acc, val) => acc + (val?.id !== uboId ? val?.shareSize ?? 0 : 0),
        0,
      ) || 0
    )
  }, [contractData])

  const isDataLoading = uboOwnerDetailsLoading || ownerDataLoading

  const uboDocs = useMemo(() => {
    return ((contractUboData?.getContractUbo?.ubo?.entity as Individual)?.documents as Document[])
      ?.filter((doc) => PoiDocTypesMandatoryTwoSided.includes(doc?.type as DocumentType))
      .sort((a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0))[0]
  }, [contractUboData?.getContractUbo?.ubo?.entity])

  const uboOwnersDocs = useMemo(() => {
    return (((uboOwnersDetails?.getUboOwners?.find((data) => data?.id === uboId) as UboOwner)?.ubo
      ?.entity as Individual)?.documents as Document[])
      ?.filter((doc) => PoiDocTypesMandatoryTwoSided.includes(doc?.type as DocumentType))
      .sort((a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0))[0]
  }, [uboOwnersDetails?.getUboOwners])

  const handleBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (goBackPath) {
        history.push(goBackPath)
        goBackPathVar('')
      } else {
        history.goBack()
      }
    },
    [formState.isDirty, goBackPath],
  )

  const onSubmit = useCallback(
    async (formData) => {
      setAllowNavigation(true)
      const preparedInput: AddContractUboInputType = {
        ubo: {
          uboType: UboType.NaturalPerson,
          ownershipType: type,
          shareSize: formData.ownershipPercent,
          naturalPerson: {
            ...(authorizedPerson ? { id: authorizedPerson } : {}),
            firstName: formData.firstName,
            lastName: formData.lastName,
            birthday: extractDateValue(formData.birthday),
            nationality: formData.nationality,
            taxId: formData.taxId,
            isPep: formData.politicallyExposedPerson === 'Yes',
            usReportablePerson: formData.usReportablePerson === 'Yes',
            address: {
              line1: formData.streetAddress,
              city: formData.city,
              zip: formData.postalCode,
              country: formData.country,
              additionalDetails: formData.additionalDetailsOptional,
            },
          },
        },
      }

      if (uboId) {
        await addUboOwnerMutation({
          variables: {
            contractId: applicationId,
            uboId,
            ubo: preparedInput.ubo,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        })
          .then((addUboOwnerData) => {
            createOwnDoc(
              uboId,
              ownedUboId,
              CreateOwnDocMutationType.addUboOwner,
              formData,
              createOwnDocument,
              createOwnDocumentOneSide,
              +applicationId,
              addUboOwnerData?.data?.addUboOwner?.ubo?.entity as Individual,
              removeDocument,
            )
          })
          .catch(() => {
            setAllowNavigation(false)
          })
      } else {
        await addContractUBO({
          variables: {
            id: applicationId,
            input: preparedInput,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        })
          .then((addContractUBOData) => {
            createOwnDoc(
              uboId ? uboId : '',
              ownedUboId,
              CreateOwnDocMutationType.addContractUbo,
              formData,
              createOwnDocument,
              createOwnDocumentOneSide,
              +applicationId,
              addContractUBOData?.data?.addContractUbo?.ubo?.entity as Individual,
              removeDocument,
            )
          })
          .catch(() => {
            setAllowNavigation(false)
          })
      }

      if (addAnother) {
        history.push(
          generatePath(APP_PATHS.application.beneficialOwners.add, {
            applicationId,
            [PATH_PARAMS.controllingPerson]: ControllingPersonsTypeEnum.personOwner,
          }),
        )
      } else if (goNextPath) {
        history.push(goNextPath)
      } else {
        goBackPathVar('')
        history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
      }
    },
    [addAnother, authorizedPerson],
  )

  const onEditSubmit = useCallback(
    async (formData) => {
      setAllowNavigation(true)
      const id = uboId
      const preparedInput: AddContractUboInputType = {
        ubo: {
          uboType: UboType.NaturalPerson,
          ownershipType: type,
          shareSize: formData.ownershipPercent,
          naturalPerson: {
            ...(authorizedPerson ? { id: authorizedPerson } : {}),
            firstName: formData.firstName,
            lastName: formData.lastName,
            birthday: extractDateValue(formData.birthday),
            taxId: formData.taxId,
            nationality: formData.nationality,
            isPep: formData.politicallyExposedPerson === 'Yes',
            usReportablePerson: formData.usReportablePerson === 'Yes',
            address: {
              line1: formData.streetAddress,
              city: formData.city,
              zip: formData.postalCode,
              country: formData.country,
              additionalDetails: formData.additionalDetailsOptional,
            },
          },
        },
      }

      if (uboId && ownedUboId && id) {
        await updateUboOwnerMutation({
          variables: {
            contractId: applicationId,
            ownedUboId: ownedUboId,
            uboOwnerId: uboId,
            ubo: preparedInput.ubo,
          },
          refetchQueries: [
            {
              query: GetUbosListDocument,
              variables: { id: +applicationId },
            },
          ],
          awaitRefetchQueries: true,
        })
          .then((updateUboOwnerData) => {
            createOwnDoc(
              uboId,
              ownedUboId,
              CreateOwnDocMutationType.updateUboOwner,
              formData,
              createOwnDocument,
              createOwnDocumentOneSide,
              +applicationId,
              updateUboOwnerData?.data?.updateUboOwner?.ubo?.entity as Individual,
              removeDocument,
            )
          })
          .catch(() => {
            setAllowNavigation(false)
          })
      } else {
        id &&
          (await updateContractUboMutation({
            variables: {
              contractUboId: id,
              input: preparedInput,
            },
            refetchQueries: [
              {
                query: GetUbosListDocument,
                variables: { id: +applicationId },
              },
            ],
            awaitRefetchQueries: true,
          })
            .then((updateContractUboData) => {
              createOwnDoc(
                uboId,
                ownedUboId,
                CreateOwnDocMutationType.updateContractUbo,
                formData,
                createOwnDocument,
                createOwnDocumentOneSide,
                +applicationId,
                updateContractUboData?.data?.updateContractUbo?.ubo?.entity as Individual,
                removeDocument,
              )
            })
            .catch(() => {
              setAllowNavigation(false)
            }))
      }

      if (goNextPath) {
        history.push(goNextPath)
      } else {
        goBackPathVar('')
        history.push(generatePath(APP_PATHS.application.beneficialOwners.list, { applicationId }))
      }
    },
    [authorizedPerson],
  )

  useBeforeUnload(
    methods.formState.isDirty,
    t('youHaveUnsavedChangesAreYouSure', 'You have unsaved changes, are you sure?'),
  )

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  useEffect(() => {
    register(`filesForUpload`)
    if (getValues(`filesForUpload`) === undefined) {
      setValue(`filesForUpload`, [])
    }
    register(`documentAlreadyUploaded`)
  }, [getValues, register, setValue])

  useEffect(() => {
    if (formMode === 'myself' && user) {
      const { firstName, lastName, address, birthday, nationality, taxId } = user as Individual

      setValue('taxId', taxId)
      setValue('authorizedPerson', (user as Individual).id)
      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('country', address?.country)
      setValue('birthday', birthday)
      setValue('streetAddress', address?.line1)
      setValue(
        'additionalDetailsOptional',
        address?.additionalDetails ? address?.additionalDetails : '',
      )
      setValue('postalCode', address?.zip)
      setValue('city', address?.city)
      setValue('nationality', nationality)
    }
  }, [formMode, setValue, user, register, formState.isDirty]) // it's necessary to put formState.isDirty into the dependency for correct updating street address

  //mounts total Share already Contract have when new ubo add
  useEffect(() => {
    if (isNewRecord && !uboId) {
      totalShare(shareSizeSummary)
    }
  }, [contractData])

  useEffect(() => {
    isEditingPoiNeeded(
      authorizedPerson as number,
      ownersList as AuthPersonsSelectType[],
      filesForUpload,
      formMode || '',
      trigger,
    )
  }, [authorizedPerson, filesForUpload, ownersList, formMode, trigger])

  // mounts data when edit existing contract Ubo
  useEffect(() => {
    if (!isNewRecord && !contractUboData && uboId && !ownedUboId) {
      ;(async () => {
        await GetUboDetailsQuery({
          variables: { contractUboId: uboId },
        })
      })()
    }

    if (contractUboData?.getContractUbo && !isNewRecord && contractData) {
      totalShare(shareSizeSummary)
      const { shareSize, ubo } = contractUboData.getContractUbo
      const individualUbo = ubo?.entity as Individual

      setValue('firstName', individualUbo.firstName)
      setValue('lastName', individualUbo.lastName)
      setValue('country', individualUbo.address?.country)
      setValue('birthday', individualUbo.birthday)
      setValue('streetAddress', individualUbo.address?.line1)
      setValue('additionalDetailsOptional', individualUbo.address?.additionalDetails)
      setValue('postalCode', individualUbo.address?.zip)
      setValue('city', individualUbo.address?.city)
      setValue('nationality', individualUbo.nationality)
      setValue('ownershipPercent', shareSize)
      setValue(`documentAlreadyUploaded`, uboDocs)
      setValue(`documentType`, uboDocs?.type)
      setValue(
        'politicallyExposedPerson',
        individualUbo?.isPep ? YesNoOptions[0].key : YesNoOptions[1].key,
      )
      setValue(
        'usReportablePerson',
        individualUbo?.usReportablePerson ? YesNoOptions[0].key : YesNoOptions[1].key,
      )
      setValue('taxId', individualUbo.taxId)
    }
  }, [
    contractUboData,
    uboId,
    contractData,
    isNewRecord,
    ownedUboId,
    GetUboDetailsQuery,
    setValue,
    authorizedPerson,
    PoiDocTypesMandatoryTwoSided,
    formState.isDirty,
  ])

  // mounts data when edit existing contract Ubo Owner
  useEffect(() => {
    if (isDataLoading) return

    if (!uboOwnersDetails && uboId && ownedUboId) {
      ;(async () => {
        await GetUboOwnersDetailsQuery({
          variables: {
            id: ownedUboId,
          },
        })
      })()
    }

    if (uboOwnersDetails?.getUboOwners && !isEmpty(uboOwnersDetails?.getUboOwners)) {
      const uboData = uboOwnersDetails?.getUboOwners.find((data) => data?.id === uboId)
      const shareSizeSummary =
        uboOwnersDetails?.getUboOwners?.reduce(
          (acc, val) =>
            acc +
            (val?.ownedUboId === uboData?.ownedUboId && val?.id !== uboId
              ? val?.shareSize ?? 0
              : 0),
          0,
        ) || 0
      totalShare(shareSizeSummary)
      const individualUbo = uboData?.ubo?.entity as Individual

      setTimeout(() => {
        setValue('firstName', individualUbo.firstName, { shouldDirty: true })
        setValue('lastName', individualUbo.lastName)
        setValue('country', individualUbo.address?.country)
        setValue('birthday', individualUbo.birthday)
        setValue('streetAddress', individualUbo.address?.line1)
        setValue('additionalDetailsOptional', individualUbo.address?.additionalDetails)
        setValue('postalCode', individualUbo.address?.zip)
        setValue('city', individualUbo.address?.city)
        setValue('nationality', individualUbo.nationality)
        setValue('ownershipPercent', uboData?.shareSize)
        setValue(`documentAlreadyUploaded`, uboOwnersDocs)
        setValue(`documentType`, uboOwnersDocs?.type)
        setValue(
          'politicallyExposedPerson',
          individualUbo?.isPep ? YesNoOptions[0].key : YesNoOptions[1].key,
        )
        setValue(
          'usReportablePerson',
          individualUbo?.usReportablePerson ? YesNoOptions[0].key : YesNoOptions[1].key,
        )
        setValue('taxId', individualUbo.taxId)
      })
    }
  }, [ownedUboId, uboId, uboOwnersDetails, isDataLoading])

  if (isDataLoading || !user || !type) return <Loader />

  return (
    <>
      <NavigationPrompt when={formState.isDirty && !allowNavigation}>
        {({ onCancel, onConfirm }) => (
          <CustomPrompt open={true} onCancel={onCancel} onConfirm={onConfirm} />
        )}
      </NavigationPrompt>
      <FormProvider {...methods}>
        {children}
        {poiNeededVar && (
          <GridRow>
            <AlertTipItem
              value={t(
                'NeedPersonsProofIdentity',
                'You will need to add this person’s Proof of identity. Make sure you have the necessary documents: Passport, ID or Driver’s license.',
              )}
              iconComponent={<InformationIcon />}
              type={'primary'}
            />
          </GridRow>
        )}
        <Box className={'form'}>
          <form
            onSubmit={methods.handleSubmit(!isNewRecord ? onEditSubmit : onSubmit)}
            id="forNextFocus"
            onKeyDown={focusKeyPressNext}
          >
            <Box className={'group'}>
              <Box>
                <GridRow>
                  <FormControlledTextField
                    label={t('firstNames', 'First Name(s)')}
                    name="firstName"
                    type="text"
                    fullWidth
                    required={false}
                    data-test="firstNames"
                  />
                </GridRow>

                <GridRow>
                  <FormControlledTextField
                    label={t('lastName', 'Last Name')}
                    name="lastName"
                    type="text"
                    fullWidth
                    required={false}
                    data-test="lastName"
                  />
                </GridRow>

                <GridRow>
                  <FormDatePickerField
                    name="birthday"
                    label={t('dateOfBirth', 'Date of Birth')}
                    isShiftTimezone={false}
                  />
                </GridRow>

                {isDomiciliary && (
                  <GridRow>
                    <FormControlledTextField
                      label={t('tinTaxID', 'TIN/Tax ID')}
                      name="taxId"
                      type="text"
                      fullWidth
                      required={false}
                    />
                  </GridRow>
                )}

                <AddressAutocomplete
                  customCountryLabel={t('countryOfResidence', 'Country of Residence')}
                  props={registeredAddressEmptyProps}
                />

                <GridRow>
                  <FormAutocompleteSelect
                    className={classes.formField}
                    name="nationality"
                    label={t('nationality', 'Nationality')}
                    data={nationalitiesArray}
                  />
                </GridRow>

                <GridRow>
                  <FormAutocompleteSelect
                    className={classes.formField}
                    name="usReportablePerson"
                    label={t('USReportablePerson', 'US reportable person')}
                    data={YesNoOptions}
                  />
                </GridRow>

                <Collapse in={usReportablePerson === YesNoOptions[0].key}>
                  <GridRow>
                    <FormControlledTextField
                      label={t('tinTaxID', 'TIN/Tax ID')}
                      name="taxId"
                      type="text"
                      fullWidth
                      multiline
                    />
                  </GridRow>
                </Collapse>

                <GridRow>
                  <FormAutocompleteSelect
                    className={classes.formField}
                    name="politicallyExposedPerson"
                    label={t('politicallyExposedPerson', 'Politically exposed person')}
                    data={YesNoOptions}
                  />
                </GridRow>
              </Box>

              <Box mt={4}>
                <Typography variant={'h3'} className={'title'}>
                  {t('ownershipDetails', 'Ownership details')}
                </Typography>
              </Box>
              <GridRow>
                <FormControlledTextField
                  label={t('percentageOfOwnership', 'Percentage of ownership')}
                  name="ownershipPercent"
                  type="text"
                  fullWidth
                  required={false}
                  className={'inputWithSymbol'}
                  data-test="ownershipPercent"
                />
              </GridRow>

              {type === UboStakeType.Percent25OrMoreViaPoA && (
                <GridRow>
                  <Box className={classes.info}>
                    <CommonTipItem
                      value={t(
                        'weWillSendYouAStandardForm',
                        'After the application is completed we will send you a standard form to specify the details of the Trust or Power of attorney.',
                      )}
                      iconComponent={<InformationIcon />}
                    />
                  </Box>
                </GridRow>
              )}

              <GridRow>
                <ProofOfIdentityControllingPerson
                  infoLabel={t(
                    'ownerIdentityLabel',
                    'Please upload a copy of a document to prove the Beneficial Owner’s identity.',
                  )}
                  nameLabel={t('proofOfIdentity', 'Proof of identity')}
                />
              </GridRow>
            </Box>
            {!isDomiciliary && type === UboStakeType.Percent25OrMore && totalShare() > 75.01 && (
              <CommonTipItem
                value={t(
                  'controlShareMessage',
                  'You have added beneficial owners of 75% of the shares. You don`t need to add more owners',
                )}
                iconComponent={<InformationIcon />}
              />
            )}
            <Box className={'buttonsBox'}>
              <Box className={'secondaryButton'}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={handleBack}
                >
                  {t('back', 'Back')}
                </Button>
              </Box>

              <LoadingButton
                className={'button'}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                data-test={isNewRecord ? 'submitAndProceed' : 'saveChanges'}
              >
                {isNewRecord
                  ? t('submitAndProceed', 'Submit and proceed')
                  : t('saveChanges', 'Save changes')}
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </FormProvider>
    </>
  )
}
