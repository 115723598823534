import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Hidden, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core'
import clsx from 'clsx'
import { isEmpty, isEqual, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import {
  AlertTipItem,
  contractsTeamPageStateVar,
  FiltersResetBtn,
  Loader,
} from '../../../components'
import EmptyDataPage from '../../../components/Common/EmptyDataPage'
import PagePagination from '../../../components/Common/PagePagination'
import {
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  AuthorizedPersonStatuses,
  ContractAuthorizedPersonsDocument,
  ContractAuthorizedPersonsQueryVariables,
  ContractAuthorizedPersonType,
  LimitedAccessRight,
  Maybe,
  useContractAuthorizedPersonsCountQuery,
  useContractAuthorizedPersonsQuery,
  useDeactivateUserMutation,
} from '../../../graphql'
import { useDetermineUserRights, usePageFiltersSorting, useRowPerPage } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { StatusSelectorType, TeamMenuOptions } from '../../../types'
import { showError } from '../../../utils'
import InviteTeammate from './InviteTeammate'
import { TeamFilters } from './TeamFilters'
import { TeamTableHeader } from './TeamTableHeader'
import { TeamTableListRow } from './TeamTableListRow'
import { TeamTableListRowMobile } from './TeamTableListRowMobile'
import { TeamTableListRowTablet } from './TeamTableListRowTablet'
import { initialTeamPageState } from '../../../constants'

import IconConfirmed from '../../../assets/images/icons/confirmed.svg?react'
import IconTeamMembers from '../../../assets/images/icons/persons_round.svg?react'

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    '& th': {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    marginBottom: theme.spacing(3),
  },
  sectionItem: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  filters: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  buttonSection: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  alertContainer: {
    position: 'fixed',
    zIndex: 9999,
    top: theme.spacing(1),
    left: '50%',
    transform: 'translateX(-50%)',
    margin: 0,
  },
}))

const TeamTableComponent = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { pageFilters, setPageFilters } = usePageFiltersSorting(contractsTeamPageStateVar)
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const [deactivateUserMutation] = useDeactivateUserMutation()
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly
  const isFiltered = useMemo(() => !isEqual(pageFilters, initialTeamPageState), [pageFilters])
  const { searchValue, status, role, page } = pageFilters
  const [deactivatedPerson, setDeactivatedPerson] = useState('')

  const { itemsPerPage } = useRowPerPage()

  const queryParams: ContractAuthorizedPersonsQueryVariables = {
    contractId: +applicationId,
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    orderBy: pageFilters.sortBy,
    orderDirection: pageFilters.direction,
  }

  const updatedQueryParams = { ...queryParams }

  if (searchValue) {
    updatedQueryParams.search = searchValue
  } else {
    delete updatedQueryParams.search
  }

  if (status !== StatusSelectorType.AllStatuses) {
    updatedQueryParams.statuses = status as Maybe<AuthorizedPersonStatuses>
  } else {
    delete updatedQueryParams.statuses
  }

  if (role !== StatusSelectorType.AllRoles) {
    updatedQueryParams.role = role
  } else {
    delete updatedQueryParams.role
  }

  const {
    data: personsData,
    error: errorContractAuthorizedPersons,
    loading,
    refetch,
  } = useContractAuthorizedPersonsQuery({
    variables: updatedQueryParams,
    fetchPolicy: 'network-only',
  })

  const {
    data: count,
    loading: countLoading,
    error: errorCountPersons,
  } = useContractAuthorizedPersonsCountQuery({
    variables: {
      ...omit(updatedQueryParams, ['limit', 'offset', 'orderBy', 'orderDirection']),
    },
    fetchPolicy: 'network-only',
  })

  const persons = personsData?.contractAuthorizedPersons
  const personsCount = count?.contractAuthorizedPersonsCount

  const statusesForDisable = useMemo(
    () => [
      AuthorizedPersonStatuses.Deactivated,
      AuthorizedPersonStatuses.PendingSignature,
      AuthorizedPersonStatuses.PendingVerification,
      AuthorizedPersonStatuses.PendingInvitation,
      AuthorizedPersonStatuses.Invited,
    ],
    [],
  )

  const checkForDisabled = useCallback(
    (person: ContractAuthorizedPersonType) =>
      person?.isPendingDeactivated ||
      (!!person?.status && statusesForDisable.includes(person.status)),
    [statusesForDisable],
  )

  const handleChange = useCallback(
    (_, value) => {
      setPageFilters({ page: value })
    },
    [setPageFilters],
  )

  const handleDeactivate = useCallback(
    async (teamId?: number) => {
      if (!teamId) return

      const { data } = await deactivateUserMutation({
        variables: {
          authorizedPersonId: teamId,
        },
        onError: (e) => toast.error(e.message),
        refetchQueries: [
          {
            query: ActionSignatureRequestsCountDocument,
            variables: {
              contractId: applicationId,
              statuses: [ActionSignatureStatus.Pending],
            },
          },
          {
            query: ContractAuthorizedPersonsDocument,
            variables: {
              contractId: +applicationId,
            },
          },
        ],
      })

      if (data?.deactivateAuthorizedPersonRequest) {
        const filteredPerson = personsData?.contractAuthorizedPersons?.find(
          (person) => person?.id === teamId,
        )

        if (filteredPerson) {
          const person = filteredPerson?.person
          setDeactivatedPerson(
            `${person?.firstName} ${person?.lastName && person?.lastName}`.trim(),
          )
        }
      }
    },
    [applicationId, personsData],
  )

  const teamMenuOptions: TeamMenuOptions = useMemo(
    () => ({
      [AuthorizedPersonStatuses.Deactivated]: {
        label: t('deactivate', 'Deactivate'),
        handler: () => null,
      },
      [AuthorizedPersonStatuses.Activated]: {
        label: t('activate', 'Activate'),
        handler: async (teamId) => {
          await handleDeactivate(teamId)
        },
      },
    }),
    [handleDeactivate],
  )

  const handleCloseAlert = useCallback((isClose: boolean) => {
    if (!isClose) {
      setDeactivatedPerson('')
    }
  }, [])

  useEffect(() => {
    if (errorContractAuthorizedPersons) {
      showError(errorContractAuthorizedPersons)
    } else if (errorCountPersons) {
      showError(errorCountPersons)
    }
  }, [errorContractAuthorizedPersons, errorCountPersons])

  useEffect(() => {
    return () => {
      setPageFilters({ searchValue: '', page: 1 })
    }
  }, [])

  return (
    <>
      {!!deactivatedPerson && (
        <Box mt={3} className={classes.alertContainer}>
          <AlertTipItem
            title={t(
              'deactivationPersonRequestCreated',
              'Deactivation request for {{person}} created successfully!',
              { person: deactivatedPerson },
            )}
            value={t('pleaseConfirmWithSignature', 'Please confirm this with your signature')}
            iconComponent={<IconConfirmed />}
            type={'success'}
            isOpenAlert={!!deactivatedPerson}
            setIsOpenAlert={handleCloseAlert}
            isCloseButton
          />
        </Box>
      )}

      <Box className={classes.head}>
        <Box className={clsx(classes.sectionItem, classes.filters)}>
          <TeamFilters />
        </Box>
        <Box className={classes.buttonSection}>
          {!isViewOnly && (
            <Box className={classes.sectionItem}>
              <InviteTeammate personsData={personsData} refetch={refetch} />
            </Box>
          )}
          <FiltersResetBtn
            isDisabled={!isFiltered}
            resetPageState={() => setPageFilters(initialTeamPageState)}
          />
        </Box>
      </Box>

      {loading || countLoading ? (
        <Loader height={'60vh'} />
      ) : (
        <>
          <Hidden mdDown>
            {!isEmpty(persons) ? (
              <TableContainer>
                <Table className={classes.tableHeader}>
                  <TeamTableHeader />

                  <TableBody>
                    {persons?.map((value, index) => {
                      return (
                        <TeamTableListRow
                          key={`${index}-desktop`}
                          isShouldDisabled={checkForDisabled(value as ContractAuthorizedPersonType)}
                          teammate={value as ContractAuthorizedPersonType}
                          teamMenuOptions={teamMenuOptions}
                          isViewOnly={isViewOnly}
                          isMyself={value?.person?.id === userRights?.individualId}
                        />
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyDataPage
                messageKey={t('noTeamMembersFound', 'No team members found')}
                icon={IconTeamMembers}
              />
            )}
          </Hidden>

          {/* tablet view */}
          <Hidden xsDown lgUp>
            <Grid container>
              {!isEmpty(persons) ? (
                <Grid item xs={12}>
                  {persons?.map((value, index: number) => {
                    return (
                      <TeamTableListRowTablet
                        key={`${index}-tablet`}
                        isShouldDisabled={checkForDisabled(value as ContractAuthorizedPersonType)}
                        teammate={value as ContractAuthorizedPersonType}
                        teamMenuOptions={teamMenuOptions}
                        isViewOnly={isViewOnly}
                        isMyself={value?.person?.id === userRights?.individualId}
                      />
                    )
                  })}
                </Grid>
              ) : (
                <EmptyDataPage messageKey={t('noTeamMembersFound')} icon={IconTeamMembers} />
              )}
            </Grid>
          </Hidden>

          {/* mobile view */}
          <Hidden smUp>
            <Grid container>
              {!isEmpty(persons) ? (
                <Grid item xs={12}>
                  {persons?.map((value, index: number) => {
                    return (
                      <TeamTableListRowMobile
                        key={`${index}-mobile`}
                        isShouldDisabled={checkForDisabled(value as ContractAuthorizedPersonType)}
                        teammate={value as ContractAuthorizedPersonType}
                        teamMenuOptions={teamMenuOptions}
                        isViewOnly={isViewOnly}
                        isMyself={value?.person?.id === userRights?.individualId}
                      />
                    )
                  })}
                </Grid>
              ) : (
                <EmptyDataPage messageKey={t('noTeamMembersFound')} icon={IconTeamMembers} />
              )}
            </Grid>
          </Hidden>

          <PagePagination page={page} totalItems={personsCount} handleChange={handleChange} />
        </>
      )}
    </>
  )
}

export const TeamTable = React.memo(TeamTableComponent)
