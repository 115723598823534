import * as yup from 'yup'

import {
  DocumentTypeInputSchema,
  NameInputSchema,
  AddressInputSchema,
  AdditionalDetailsInputSchema,
  StreetAddressInputSchema,
  NationalityInputSchema,
  BirthdayInputSchema,
  PostalCodeInputSchema,
} from './common'
import { PersonAdditionalDataWithIdSchema } from './PersonAdditionalDataWithIdSchema'

export const ManagingDirectorValidationSchema = NameInputSchema.concat(AddressInputSchema)
  .concat(PostalCodeInputSchema)
  .concat(StreetAddressInputSchema)
  .concat(NationalityInputSchema)
  .concat(BirthdayInputSchema)
  .concat(AdditionalDetailsInputSchema)
  .concat(DocumentTypeInputSchema)
  .concat(PersonAdditionalDataWithIdSchema)

export type ManagingDirectorInput = yup.Asserts<typeof ManagingDirectorValidationSchema>
