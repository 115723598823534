import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { isEmpty, isEqual } from 'lodash'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  useTheme,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { useMenuStyles } from './menuStyles'
import { DashboardDrawer } from './DasboardDrawer'
import { DashboardNavMenu } from './DashboardNavMenu'
import { ContractStatusType, Individual, TransactionOrderBy } from '../../graphql'
import { Loader } from '../Common'
import { useCurrentUser, useDetermineUserRights, usePageFiltersSorting } from '../../hooks'
import { CreateButton } from './CreateButton'
import config from '../../config'
import { MENU_WRAPPER_ZINDEX } from '../../constants'
import { useTableSort } from '../../hooks/useCurrentTableSort'
import { actionsPageStateValue, trxPageStateVar } from '../../graphql/local'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      zIndex: 1200,
    },
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  },
  toolBar: {
    minHeight: '64px',
    marginLeft: 'auto',
    paddingRight: 8,
  },
  buttonMenu: {
    fontWeight: 400,
    fontSize: 14,
    '& .MuiSvgIcon-root': {
      fill: '#ccc',
      marginLeft: theme.spacing(0.5),
      transform: 'rotate(-90deg)',
      transition: '0.25s transform ease',
    },
  },
  backWrappAppBar: {
    position: 'relative',
    padding: theme.spacing(0, 0, 0, 1.5),
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      borderBottom: 0,
    },
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  userIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(3.125),
    height: theme.spacing(3.125),
    borderRadius: '50%',
    backgroundColor: '#ef2828',
    color: '#fff',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '25px',
    verticalAlign: 'baseline',
    marginRight: theme.spacing(1.25),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  menuOpened: {
    '& .MuiSvgIcon-root': {
      transform: 'rotate(-180deg)',
    },
  },
  profileButtonMobile: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(-0.5),
  },
  divider: {
    backgroundColor: '#f0f0f0',
    height: theme.spacing(3),
    width: theme.spacing(0.25),
    alignSelf: 'center',
    margin: theme.spacing(0, 0.5, 0, 2),
  },
  spanAlt: {
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    color: '#fff',
    fontSize: 16,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 700,
  },
}))

const useBrowserSpecificStyles = () => {
  const userAgent = navigator.userAgent
  if (/WebKit/.test(userAgent) && !/Edge/.test(userAgent)) {
    return { WebkitFontSmoothing: 'antialiased' }
  }
  if (/Firefox/.test(userAgent)) {
    return { MozOsxFontSmoothing: 'grayscale' }
  }
  return {}
}

const DashboardMenuComponent: React.FC = () => {
  const theme = useTheme()
  const classes = { ...useMenuStyles(), ...useStyles() }
  const browserCustomStyles = useBrowserSpecificStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [isContractLimitedMode, setIsContractLimitedMode] = useState<boolean>(false)
  const [user, setUser] = useState<Individual>({} as Individual)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const currentUser = useCurrentUser()

  const { userRights } = useDetermineUserRights(applicationId as string | number)

  const handleDrawerToggle = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      event.stopPropagation()
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setMobileOpen(!mobileOpen)
    },
    [mobileOpen],
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl((prevState) => (prevState ? null : event.currentTarget))
    },
    [setAnchorEl],
  )

  const { clearTablesSort } = useTableSort()
  const { resetSearchAndPageValues } = usePageFiltersSorting()
  const { setPageFilters } = usePageFiltersSorting(trxPageStateVar)

  const resetPages = useCallback(() => {
    resetSearchAndPageValues()
    clearTablesSort()
    setPageFilters({
      sortBy: TransactionOrderBy.CreatedAt,
      notStatus: '',
      page: 1,
    })
  }, [actionsPageStateValue, clearTablesSort, setPageFilters])

  const onLogoClick = useCallback(() => {
    if (applicationId) {
      history.push(
        generatePath(APP_PATHS.dashboard.home, {
          [PATH_PARAMS.applicationId]: applicationId,
        }),
      )
    } else {
      resetPages()
    }
  }, [applicationId, resetPages])

  useEffect(() => {
    const statuses = [
      ContractStatusType.AmloPending,
      ContractStatusType.SignaturesPending,
      ContractStatusType.ManagementPending,
    ]

    if (applicationId && currentUser) {
      // eslint-disable-next-line array-callback-return
      const currentContract = (currentUser as Individual).contracts?.find((contract) => {
        if (contract?.id) {
          return contract.id === +applicationId
        }
      })
      const appStatusLimited = statuses.includes(currentContract?.status as ContractStatusType)

      setIsContractLimitedMode(appStatusLimited)
    }
    if (!isEmpty(currentUser)) {
      setUser(currentUser as Individual)
    }
  }, [currentUser, applicationId])

  const isLoading = useMemo(() => !applicationId || isEmpty(currentUser), [
    applicationId,
    currentUser,
  ])

  return (
    <Box className={classes.container}>
      {isLoading && (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      )}
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBarShift, classes.appBar)}>
          <Hidden mdUp>
            <Toolbar onClick={() => setMobileOpen(false)}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                {mobileOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <Box display="flex" flexGrow={1} justifyContent="center">
                <Box className={classes.logo}>
                  <img src={config.logo} alt={'company_logo'} onClick={onLogoClick} />
                </Box>
              </Box>

              <CreateButton resetPages={resetPages} />

              <IconButton
                color="inherit"
                disableRipple
                className={classes.profileButtonMobile}
                onClick={handleClick}
              >
                <Box className={classes.userIcon} component={'span'}>
                  {user.firstName?.charAt(0)}
                </Box>
              </IconButton>
            </Toolbar>
          </Hidden>
        </AppBar>

        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              style={{
                zIndex: MENU_WRAPPER_ZINDEX,
              }}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <DashboardNavMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                user={user}
                contractId={applicationId}
                limitMode={isContractLimitedMode}
              />
              <DashboardDrawer
                contractId={applicationId}
                setMobileOpen={setMobileOpen}
                user={user}
                limitMode={isContractLimitedMode}
                limitedAccessRight={userRights?.limitedAccessRight}
                isIntroducerContractOwner={!!userRights?.isIntroducerContractOwner}
                hasCardPayment={!!userRights?.hasCardPayment}
                resetPages={resetPages}
                onLogoClick={onLogoClick}
              />
            </Drawer>
          </Hidden>

          {/*Full screen view*/}
          <Hidden smDown>
            <AppBar position="fixed" className={clsx(classes.appBarShift, classes.appBar)}>
              <Toolbar className={classes.toolBar}>
                <CreateButton resetPages={resetPages} />
                <Divider className={classes.divider} orientation="vertical" flexItem />
                <Box className={classes.backWrappAppBar}>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={clsx(classes.buttonMenu, anchorEl && classes.menuOpened)}
                    data-test="userPersonalMenu"
                  >
                    <Box
                      style={browserCustomStyles}
                      className={classes.userIcon}
                      component={'span'}
                    >
                      {user.firstName?.charAt(0)}
                    </Box>
                    <span>
                      {user.firstName} {user.lastName}
                    </span>{' '}
                    <ExpandMoreIcon />
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>

            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <DashboardNavMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                user={user}
                contractId={applicationId}
                limitMode={isContractLimitedMode}
              />
              <DashboardDrawer
                contractId={applicationId}
                setMobileOpen={setMobileOpen}
                user={user}
                limitMode={isContractLimitedMode}
                limitedAccessRight={userRights?.limitedAccessRight}
                isIntroducerContractOwner={!!userRights?.isIntroducerContractOwner}
                hasCardPayment={!!userRights?.hasCardPayment}
                resetPages={resetPages}
                onLogoClick={onLogoClick}
              />
            </Drawer>
          </Hidden>
        </nav>
      </div>
    </Box>
  )
}

export const DashboardMenu = memo(DashboardMenuComponent, isEqual)
