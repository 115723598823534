import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import PreAssessmentExpectedVolumes from '../../../pages/LegacyPreAssessmentPage/PreAssessmentExpectedVolumes'
import { getLabelByKey, selectCurrenciesLabelsArray } from '../../../utils'
import {
  economicProfileAverageSizeTypesList,
  economicProfileTransferDescriptionTypesList,
  economicProfileTransferPayrollTypesList,
} from '../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField, GridRow } from '../../Common'
import { useCommonStyles } from './helpers/functions'
import PartnersInformation from './PartnersInformation'
import { ContractProfileChangeAnswers } from '../../../graphql'
import { Controller, useFormContext } from 'react-hook-form'
import { PartnersInformationEnum } from '../../../types'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  rowWithCurrency: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiInputBase-input': {
      transform: 'translate(0, 8px)',
    },
  },
  currency: {
    // marginBottom: theme.spacing(0.1),
    flexBasis: '200px',
    marginLeft: theme.spacing(2.5),
    // '& .MuiFormHelperText-root': {
    //   width: '100%',
    // },
  },
  dirtyControl: {
    position: 'relative',
  },
}))

const TransactionalQuestionsComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
}> = ({ readOnly = false, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const methods = useFormContext()
  const { formState, control } = methods

  return (
    <>
      <Box mt={4}>
        <Typography variant="h3" className={clsx(classes.title, classes.moreBottomSpace)}>
          {t('transactionalQuestions', 'Transactional Questions')}
        </Typography>
      </Box>

      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t('companyAnnualIncomeAsPerTheLastAvailableAuditedFinancialStatements')}
          </Typography>
        </Box>
      </GridRow>
      <GridRow>
        <Box className={classes.rowWithCurrency}>
          <FormControlledTextField
            label={t('companyAnnualIncome')}
            name="companyAnnualIncome"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            fullWidth
            required={false}
            disabled={readOnly}
            data-test="companyAnnualIncome"
          />
          <Box className={classes.currency}>
            <Controller
              as={
                <FormAutocompleteSelect
                  label={t('currency', 'Currency')}
                  name="companyAnnualIncomeCurrency"
                  data={selectCurrenciesLabelsArray}
                  disabled={readOnly}
                  data-test="currencyForm"
                />
              }
              name={'companyAnnualIncomeCurrency'}
              control={control}
            />
          </Box>
        </Box>
        {changedContractProfileData?.preAssessment?.companyAnnualIncome && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.companyAnnualIncome}
            title={changedContractProfileData.preAssessment.companyAnnualIncome}
          />
        )}
      </GridRow>

      <Box mt={4}>
        <Typography variant="h5">{t('incoming', 'Incoming')}</Typography>
      </Box>
      <GridRow>
        <Controller
          as={
            <FormAutocompleteSelect
              className={classes.boxInput}
              name="estimatedIncomingTransactions"
              label={t(
                'incomingMonthlyTurnover',
                'Estimated Monthly Turnover for Incoming Transactions',
              )}
              data={economicProfileAverageSizeTypesList}
              disabled={readOnly}
              data-test="incomingMonthlyTurnover"
            />
          }
          name={'estimatedIncomingTransactions'}
          control={control}
        />

        {changedContractProfileData?.preAssessment?.incomingMonthlyTurnover && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.estimatedIncomingTransactions}
            title={getLabelByKey(
              changedContractProfileData.preAssessment.incomingMonthlyTurnover,
              economicProfileAverageSizeTypesList,
            )}
          />
        )}
      </GridRow>
      <GridRow>
        <FormControlledTextField
          className={classes.boxInput}
          label={t(
            'numberInboundTransactions',
            'Estimated number of inbound transactions per month',
          )}
          name="estimatedIncomingTransactionsAmount"
          type="text"
          fullWidth
          required={false}
          multiline
          disabled={readOnly}
          data-test="estimatedIncomingTransactionsAmount"
        />
        {changedContractProfileData?.economicProfile?.estimateInbound && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.estimatedIncomingTransactionsAmount}
            title={changedContractProfileData.economicProfile.estimateInbound}
          />
        )}
      </GridRow>
      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t(
              'pleaseListTheClientsCompanies',
              'Please add the main clients/companies that will be sending incoming payments to your account',
            )}
          </Typography>
        </Box>
      </GridRow>
      <PartnersInformation
        type={PartnersInformationEnum.Incoming}
        paymentPurposeData={economicProfileTransferDescriptionTypesList}
        readOnly={readOnly}
        changedContractProfileData={changedContractProfileData}
      />
      <Box mt={4}>
        <Typography variant="h5">{t('outgoing', 'Outgoing')}</Typography>
      </Box>
      <GridRow>
        <Controller
          as={
            <FormAutocompleteSelect
              className={classes.boxInput}
              name="estimatedOutgoingTransactions"
              label={t(
                'outgoingMonthlyTurnover',
                'Estimated Monthly Turnover for Outgoing Transactions',
              )}
              data={economicProfileAverageSizeTypesList}
              readOnly={readOnly}
              onlyLatinLetters={false}
              data-test="estimatedOutgoingTransactions"
            />
          }
          name={'estimatedOutgoingTransactions'}
          control={control}
        />

        {changedContractProfileData?.preAssessment?.outgoingMonthlyTurnover && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.estimatedOutgoingTransactions}
            title={getLabelByKey(
              changedContractProfileData.preAssessment.outgoingMonthlyTurnover,
              economicProfileAverageSizeTypesList,
            )}
          />
        )}
      </GridRow>
      <GridRow>
        <FormControlledTextField
          className={classes.boxInput}
          label={t(
            'numberOutboundTransactions',
            'Estimated number of outbound transactions per month',
          )}
          name="estimatedOutgoingTransactionsAmount"
          type="text"
          fullWidth
          required={false}
          multiline
          disabled={readOnly}
          data-test="autotest-estimatedOutboundTransactions"
        />
        {changedContractProfileData?.economicProfile?.estimateOutbound && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.estimatedOutgoingTransactionsAmount}
            title={changedContractProfileData.economicProfile.estimateOutbound}
          />
        )}
      </GridRow>
      <GridRow>
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t(
              'pleaseListSendingPayments',
              'Please add the main partners that you will be sending payments to',
            )}
          </Typography>
        </Box>
      </GridRow>
      <PartnersInformation
        type={PartnersInformationEnum.Outgoing}
        paymentPurposeData={economicProfileTransferPayrollTypesList}
        readOnly={readOnly}
        changedContractProfileData={changedContractProfileData}
      />
      <Box mt={4}>
        <Typography variant="h5">
          {t('currencyAndVolume', 'Required Currencies and Expected Volume')}
        </Typography>
      </Box>
      <PreAssessmentExpectedVolumes
        changedContractProfileData={changedContractProfileData}
        readOnly={readOnly}
      />
    </>
  )
}
export const TransactionalQuestions = React.memo(TransactionalQuestionsComponent)
