import * as yup from 'yup'
import { isFuture, parse } from 'date-fns'
import {
  INVALID_DATE_FORMAT_MESSAGE,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import i18n from '../../i18n'
import { DEFAULT_OUTPUT_DATE_FORMAT, isDateStringValid } from '../../utils/dateUtils'

export const RegistrationDateInputSchema = yup.object().shape({
  registrationDate: yup
    .mixed()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test({
      name: 'invalidDateFormat',
      message: INVALID_DATE_FORMAT_MESSAGE,
      test: (value) => value && isDateStringValid(value),
    })
    .test({
      name: 'minDate',
      message: i18n.t('dateRegistrationMin', "Date of Registration can't be in the future"),
      test: (value) => {
        const dateWithoutUtc = parse(value, DEFAULT_OUTPUT_DATE_FORMAT, new Date())
        return !isFuture(dateWithoutUtc || new Date(value))
      },
    }),
})
