import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import Unchecked from '../../../assets/images/icons/unchecked_icon.svg?react'
import Checked from '../../../assets/images/icons/cheked_icon.svg?react'
import { PATH_PARAMS } from '../../../routes/paths'
import { useDetermineUserRights } from '../../../hooks'
import { ConfirmSignatureModal } from '../../../components/Common/ActionsSign2FAModal'
import {
  ActionSignature,
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  ContractTerminationAction,
  ContractTerminationRequestMutationVariables,
  useContractTerminationRequestMutation,
  useGetActionSignatureLazyQuery,
} from '../../../graphql'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 512,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
    '& > div > form': {
      width: '100%',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
    borderBottom: '1px solid #ccc',
  },
  bodyModal: {
    padding: '24px 0 0 24px',
    maxHeight: 'calc(100vh - 180px)',
  },
  footerModal: {
    paddingBottom: 24,
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiGrid-spacing-xs-3': {
      width: 'auto',
      margin: theme.spacing(-1.5, 0, -1.5, -1.5),
    },
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  controlConf: {
    marginLeft: theme.spacing(3.5),
  },
  btnControl: {
    padding: theme.spacing(1.25, 2.5),
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
    '& .MuiTypography-h6': {
      fontWeight: 400,
    },
  },
}))

type TerminateContractModalProps = {
  isOpen: boolean
  closeModal: () => void
  setShowTabsMenu: React.Dispatch<React.SetStateAction<boolean>>
  setSignatureIdToSign: React.Dispatch<React.SetStateAction<string | number | undefined>>
}

export const TerminateContractModal: FC<TerminateContractModalProps> = ({
  isOpen,
  closeModal,
  setShowTabsMenu,
  setSignatureIdToSign,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [signNow, setSignNow] = useState(true)
  const [actionModal, setActionModal] = useState<string | undefined>('')
  const { watch } = useFormContext()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId)
  const [contractTerminationRequest] = useContractTerminationRequestMutation()
  const [GetActionSignatureQuery, { data: dataAction }] = useGetActionSignatureLazyQuery()
  const terminationReason = watch('terminationReasonOptional')

  useEffect(() => {
    if (!!userRights?.limitedAccessRight) {
      setSignNow(false)
    }
  }, [userRights])

  const handleSignNow = useCallback(() => {
    setSignNow(!signNow)
  }, [signNow, setSignNow])

  const closeWindow = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleCloseModal = useCallback((success?: boolean) => {
    success && setSignatureIdToSign(undefined)
    setActionModal(undefined)
    setShowTabsMenu(false)
  }, [])

  const handleSave = useCallback(async () => {
    try {
      const mutationParams: ContractTerminationRequestMutationVariables = {
        contractId: applicationId,
      }
      if (!!terminationReason) {
        mutationParams.reason = terminationReason
      }
      const { data } = await contractTerminationRequest({
        variables: mutationParams,
        refetchQueries: [
          {
            query: ActionSignatureRequestsCountDocument,
            variables: {
              contractId: applicationId,
              statuses: [ActionSignatureStatus.Pending],
            },
          },
        ],
      })

      const actionId = (data?.contractTerminationRequest as ContractTerminationAction).id
      const signatureId = ((data?.contractTerminationRequest as ContractTerminationAction)
        ?.signatures as ActionSignature[]).find((signaturesItem) => signaturesItem?.isMine)?.id
      setSignatureIdToSign(actionId)

      if (signNow && !!signatureId) {
        await GetActionSignatureQuery({
          variables: {
            id: signatureId as string,
          },
        })
        setActionModal('sign')
      } else {
        setShowTabsMenu(false)
      }
      setSignNow(false)
      closeModal()
    } catch (e) {
      toast.error((e as Error).message)
    }
  }, [
    contractTerminationRequest,
    applicationId,
    closeModal,
    GetActionSignatureQuery,
    signNow,
    terminationReason,
    setActionModal,
    setSignNow,
    setShowTabsMenu,
  ])

  const cannotSignAction = !!userRights?.limitedAccessRight || userRights?.cannotSignOwnActions

  return (
    <>
      <Modal
        open={isOpen}
        onClose={closeWindow}
        aria-labelledby="account-modal-title"
        aria-describedby="account-modal-description"
      >
        <Grid container item style={modalStyle} className={classes.modal}>
          <Grid item className={classes.headerModal}>
            <Typography variant={'h5'}>{t('terminateContract', 'Terminate contract')}</Typography>
            <Fab
              color="default"
              aria-label="close"
              className={classes.btnCloseModal}
              onClick={closeWindow}
            >
              <CloseIcon />
            </Fab>
          </Grid>
          <Grid item xs={12} container>
            <Box className={classes.modalHeader}>
              <Typography variant={'h6'}>
                {t('areYouSureContractTerminationSignatoryConfirmation')}
              </Typography>
            </Box>
            {!cannotSignAction && (
              <Grid item xs={12}>
                <Box className={classes.controlConf}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="signRequest"
                        icon={<Unchecked />}
                        checkedIcon={<Checked />}
                        checked={signNow}
                      />
                    }
                    onChange={handleSignNow}
                    label={t('signActionNow')}
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={12} className={classes.bodyModal}>
              <Grid item xs={12} className={classes.footerModal}>
                <Grid container item spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={closeWindow}
                      variant="contained"
                      className={classes.btnControl}
                    >
                      {t('cancel', 'Cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      variant="contained"
                      disableElevation
                      color="primary"
                      onClick={handleSave}
                      className={classes.btnControl}
                    >
                      {t('proceed', 'Proceed')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {!!actionModal && !!dataAction && (
        <ConfirmSignatureModal
          openAction={actionModal}
          handleClose={handleCloseModal}
          actionData={dataAction?.actionSignatureRequest as ActionSignature}
          multipleActionsData={[]}
        />
      )}
    </>
  )
}
