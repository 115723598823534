import { filter, find, toLower } from 'lodash'
import { DEFAULT_COUNTRY_CALLING_CODE } from '../../../constants'
import { countries, BaseEnum, nationalities } from '@klarpay/enums'
import { BankableEntityContractType } from '../../../graphql'

export type BaseEnumWithKey = BaseEnum & { key: string }

export type CountryKey = keyof typeof countries

export const toCountryKey: (key: string) => CountryKey = (key) =>
  (toLower(key) as unknown) as CountryKey

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validCountries = () => {
  return filter(countries, (country) => !country.forbidden)
  // return filter(countries, (country) => country.forbidden === true)
}

export const countriesList = validCountries()

// is valid countries key for validation
export const validCountriesKeyList = countriesList.map((country) => country.key) as string[]

// export const countriesArray = Object.values<ICountryEnum>(countriesList)
export const countriesArray = Object.values<BaseEnumWithKey>(countries).sort(
  (a, b) => -b.label.localeCompare(a.label),
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const prefixCode = (phoneCountryCode = DEFAULT_COUNTRY_CALLING_CODE) => {
  const countryCode =
    find(countries, (c) => c.value.toLowerCase() === phoneCountryCode?.toLowerCase())
      ?.callingCode || ''
  return `+${countryCode}`
}

export type Countries = typeof countries

export const nationalitiesArray = Object.values(nationalities).sort(
  (a, b) => -b.label.localeCompare(a.label),
)

export type PhonePrefixStyles = {
  phonePrefixCount: number
}

export type CompanySelectAccordionSubgroupProps = {
  title: string
  details: {
    name: string
    href: string
    haveAlertPoint?: boolean
  }[]
}

export type ContractListItemProps = {
  contract: BankableEntityContractType
  isSelected: boolean
  handleChangeContract: (contract: BankableEntityContractType) => Promise<void>
}
