import { useLocation } from 'react-router'
import { matchPath } from 'react-router-dom'

export const useMatchCurrentLocation = (comparisonPath: string, exact = false) => {
  const location = useLocation()

  const math = matchPath(location.pathname, { path: comparisonPath, exact })

  return Boolean(math)
}
