import React, { FC, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, FormHelperText, Input, InputLabel } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { MODAL_WRAPPER_ZINDEX } from '../../../constants'
import { Loader } from '@googlemaps/js-api-loader'

import { ControlledTextFieldProps } from '../../../types'

const useStyles = makeStyles<Theme, { value: string }>((theme) => ({
  icon: {
    color: '#999',
    position: 'absolute',
    right: theme.spacing(1.5),
    bottom: -15,
    margin: 'auto 0 24px',
    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.5rem',
    },
  },
  fieldAutocomplete: {
    position: 'relative',
    maxWidth: '100%',
    '& .pac-container': {
      top: '45px !important',
      left: '0px !important',
    },
  },
  inputAutocomplete: {
    '& .MuiInputBase-input': {
      paddingRight: theme.spacing(4.75),
      // marginTop: '15px',
      marginTop: 0,
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  redError: {
    color: '#EF2828',
  },
  '@global': {
    body: {
      '& .pac-container': {
        zIndex: MODAL_WRAPPER_ZINDEX + 1,
      },
    },
  },
  label: {
    transform: ({ value }) =>
      value?.trim()?.length ? 'translate(0, 12px) scale(0.875)' : 'translate(0, 24px) scale(1)',
    '&.Mui-focused': {
      transform: 'translate(0, 12px) scale(0.875)',
    },
  },
}))

export interface PlaceResult {
  country?: string
  postalCode?: string
  city?: string
}

type AddressAutocompleteSelectProps = {
  name: string
  label: string
  handleChange: (place: PlaceResult) => void
  countryCode: string | null
  correspondence?: boolean
}

const loader = new Loader({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  apiKey: import.meta.env.REACT_APP_GOOGLE_MAPS_API!,
  version: 'weekly',
  libraries: ['places'],
  language: 'en',
})

function findAddressComponent(
  addressComponents: google.maps.GeocoderAddressComponent[],
  searchComponents: [type: string, field: string][],
): string | undefined {
  for (const [type, field] of searchComponents) {
    const result = addressComponents.find((component) => component.types.includes(type))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (result) return result?.[field]
  }
}

const autoCompleteDisabled =
  !import.meta.env.REACT_APP_GOOGLE_MAPS_API ||
  import.meta.env.REACT_APP_GOOGLE_MAPS_API === 'NOT_PROVIDED'

export const AddressAutocompleteSelect: FC<
  AddressAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = 'AddressAutocompleteSelect',
  label = 'Address',
  handleChange,
  countryCode,
  disabled = false,
}) => {
  const { errors, setValue, watch, control } = useFormContext()
  const error = errors ? errors[name] : null
  const id = `google-autocomplete-${name}`
  const value = watch(name)
  const classes = useStyles({ value })

  const notDefinedByGoogleList = ['kv']
  const isShouldBeSkipped =
    (countryCode && notDefinedByGoogleList.includes(countryCode)) || autoCompleteDisabled

  const autocompleteRef = useRef<google.maps.places.Autocomplete | undefined>(undefined)

  const anchorRef = useRef<HTMLButtonElement>(null)

  const [currentVal, setCurrentVal] = useState('')
  const [isSkipped, setIsSkipped] = useState(false)

  const handlePlaceChanged = (): void => {
    if (isShouldBeSkipped) {
      return
    }
    const place = autocompleteRef.current?.getPlace()
    if (place?.address_components) {
      const streetName =
        findAddressComponent(place.address_components, [['route', 'long_name']]) ?? ''
      const streetNumber =
        findAddressComponent(place.address_components, [['street_number', 'short_name']]) ?? ''
      const street = streetName && streetNumber ? `${streetName} ${streetNumber}` : streetName || ''

      const country = findAddressComponent(place.address_components, [['country', 'short_name']])
      const postalCode = findAddressComponent(place.address_components, [
        ['postal_code', 'long_name'],
      ])
      const city = findAddressComponent(place.address_components, [
        ['locality', 'long_name'],
        ['postal_town', 'long_name'],
        ['administrative_area_level_1', 'long_name'],
      ])

      setTimeout(() => {
        if (!!street) {
          setValue(name, street, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setCurrentVal(street as string)
        }
      }, 0)

      handleChange({ country, postalCode, city })
    }
  }

  function initAutocomplete() {
    const input = document.getElementById(id)
    const options: google.maps.places.AutocompleteOptions | null = {
      fields: ['address_component', 'formatted_address'],
      types: ['address'],
    }
    if (countryCode) {
      options.componentRestrictions = { country: countryCode }
    }
    if (input && !isShouldBeSkipped) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        input as HTMLInputElement,
        options,
      )
      autocompleteRef.current.addListener('place_changed', handlePlaceChanged)
    }
  }

  const resetField = () =>
    setTimeout(() => {
      setValue(name, '')
    }, 0)

  const handleLabelClick = () => anchorRef.current?.focus()

  useEffect(() => {
    setCurrentVal(value as string)
  }, [value])

  useEffect(() => {
    loader.importLibrary('places').then(() => {
      initAutocomplete()
    })
    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current)
      }
    }
  }, [])

  useEffect(() => {
    !value && setValue(name, ' ')
    countryCode &&
      autocompleteRef.current?.setComponentRestrictions({
        country: countryCode.toLowerCase(),
      })

    if (isShouldBeSkipped) {
      setIsSkipped(true)
      // resetField()
    } else {
      setIsSkipped((prevState) => {
        if (prevState) {
          loader.importLibrary('places').then(() => {
            initAutocomplete()
            autocompleteRef.current?.setComponentRestrictions({
              country: countryCode?.toLowerCase() as string,
            })
          })
          resetField()
        }
        return false
      })
    }
  }, [countryCode])

  // fixing google autoselect to address input
  function pacReplace() {
    const pacContainers = document.body.querySelectorAll('.pac-container')
    const mainContainer = document.querySelector(`#${id}Box`)

    if (pacContainers.length && mainContainer) {
      pacContainers.forEach((pacContainer) => {
        if (mainContainer.contains(pacContainer)) {
          return
        }

        if (pacContainer.parentNode) {
          pacContainer.parentNode.removeChild(pacContainer)
        }

        mainContainer.appendChild(pacContainer)
      })
    }
  }

  const normalize = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(name, e.target.value.trim())
    pacReplace()
  }

  let pacTimer: NodeJS.Timeout

  useEffect(() => {
    pacTimer = setTimeout(pacReplace, 1000)
  }, [autocompleteRef.current])

  useEffect(() => {
    return () => {
      clearTimeout(pacTimer)
      autocompleteRef.current = undefined
    }
  }, [])

  return (
    <Box id={`${id}Box`} className={classes.fieldAutocomplete}>
      <InputLabel onClick={handleLabelClick} className={classes.label} error={!!error}>
        {label}
      </InputLabel>
      <Controller
        as={
          <Input
            value={currentVal}
            type="text"
            className={classes.inputAutocomplete}
            fullWidth
            error={!!error}
            placeholder={''}
            inputRef={anchorRef}
            inputProps={{
              'data-test': `autotest-${name}`,
            }}
            disabled={disabled}
            onFocus={normalize}
          />
        }
        id={id}
        control={control}
        name={name}
        rules={{ required: false }}
        defaultValue=""
        key={isSkipped.toString()}
      />
      <SearchIcon fontSize="small" className={classes.icon} />
      <FormHelperText className={classes.redError}>{error ? error.message : null}</FormHelperText>
    </Box>
  )
}
