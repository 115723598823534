import React, { FC } from 'react'
import { Box, Grid, Hidden, makeStyles, MenuItem, SvgIcon } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import NOTEBOOK_IMAGE from '../../assets/images/img/notebook.png'
import NOTEBOOK_TABLET_IMAGE from '../../assets/images/img/notebookTablet.png'
import NOTEBOOK_MOB_IMAGE from '../../assets/images/img/notebookMob.png'
import config from '../../config'
import { APP_PATHS } from '../../routes/paths'
import { Footer } from '../Footer'
import { useCurrentUser, useLogOut, useMatchCurrentLocation } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& .MuiGrid-item': {
        display: 'flex',
        '&:first-child': {
          alignItems: 'center',
        },
        '&:last-child': {
          alignItems: 'flex-end',
        },
      },
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiGrid-item': {
        '&:first-child': {
          alignItems: 'flex-end',
        },
      },
    },
  },
  startText: {
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.only('sm')]: {
      width: '65%',
      margin: '0 auto',
      height: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(9, 2),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      padding: theme.spacing(4, 2),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      height: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(31),
    },
  },
  imageWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: 0,
      // bottom: 0,
      // marginBottom: '-60px',
      width: '100%',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      width: '100%',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  notebook: {
    backgroundImage: `url(${NOTEBOOK_IMAGE})`,
    height: '480px',
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    width: '100%',
    backgroundRepeat: 'no-repeat',
  },
  notebookTablet: {
    backgroundImage: `url(${NOTEBOOK_TABLET_IMAGE})`,
    height: '432px',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
    width: '100%',
    backgroundRepeat: 'no-repeat',
  },
  notebookMob: {
    backgroundImage: `url(${NOTEBOOK_MOB_IMAGE})`,
    height: '235px',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom right',
    width: '100%',
    backgroundRepeat: 'no-repeat',
  },
  sideBar: {
    position: 'fixed',
    height: '100%',
    overflow: 'auto',
    width: theme.spacing(31),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 0),
  },
  menuItem: {
    width: '100%',
    height: '48px',
    paddingLeft: theme.spacing(4),
    lineHeight: 1.5,
    transition: '0.3s',
    color: '#000000',
    '& > a:hover': {
      color: '#434343',
    },
    '&:hover': {
      color: '#434343',
      backgroundColor: 'transparent',
      transition: '0.3s',
      '& .MuiSvgIcon-root': {
        opacity: '.4',
        transition: '0.3s',
      },
    },
    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '&:nth-child(2) .MuiSvgIcon-root': {
      marginLeft: theme.spacing(-0.25),
      marginRight: theme.spacing(1.75),
    },
  },
  footerSection: {
    '& .MuiGrid-root': {
      paddingTop: 0,
    },
  },
  contractsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    justifyContent: 'center',
  },
}))

export const StartApp: FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles()
  const user = useCurrentUser()
  const { t } = useTranslation()
  const history = useHistory()
  const handleLogOut = useLogOut()
  const handleChangeMail = () => history.push(APP_PATHS.auth.changeEmail)

  const isOnboardingStartPage = useMatchCurrentLocation(APP_PATHS.application.onboarding.start)

  return (
    <Grid container className={classes.mainContainer}>
      <Hidden mdDown>
        <Grid item md="auto" className={classes.sideBar}>
          <Box width={136} ml={3.5}>
            <img src={config.logo} alt={'company_logo'} />
          </Box>
          <Box className={classes.footerSection}>
            {isOnboardingStartPage && (
              <MenuItem onClick={handleLogOut} className={classes.menuItem}>
                <SvgIcon>
                  <path
                    fill="#000"
                    d="M14 5l-1.41 1.41L14.17 8H6v2h8.17l-1.58 1.58L14 13l4-4-4-4zM2 2h7V0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h7v-2H2V2z"
                  />
                </SvgIcon>
                {t('logOut', 'Log out')}
              </MenuItem>
            )}
            <MenuItem
              onClick={handleChangeMail}
              className={classes.menuItem}
              disabled={!user?.isPhoneVerified}
            >
              <SvgIcon>
                <path
                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z"
                  fill="black"
                />
              </SvgIcon>
              {t('changeEmail', 'Change email')}
            </MenuItem>
            <Footer isMenu={true} />
          </Box>
        </Grid>
      </Hidden>

      <Grid item xs={12} md={7} lg={5} className={classes.startText}>
        <Box className={classes.contractsSection}>
          <Box maxWidth={456} width={'100%'}>
            <Hidden lgUp>
              <Box mb={4.25}>
                <img src={config.logo} alt={'company_logo'} />
              </Box>
            </Hidden>
            <Box>{children}</Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={5} xl={6}>
        <Box className={classes.imageWrap}>
          <Hidden xsDown smDown>
            <Box className={classes.notebook}></Box>
          </Hidden>
          <Hidden xsDown mdUp>
            <Box className={classes.notebookTablet}></Box>
          </Hidden>
          <Hidden smUp>
            <Box className={classes.notebookMob}></Box>
          </Hidden>
        </Box>
      </Grid>
    </Grid>
  )
}
