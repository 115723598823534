import Grow from '@material-ui/core/Grow'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { isEmpty, isEqual } from 'lodash'
import Box from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useCommonStyles } from '../NewBusinessApp/PreAssessmentQuestionnaire/helpers/functions'
import { LinkButton } from './Buttons'
import { FormControlledTextField, GridRow } from './Fields'
import FormAutocompleteSelectNew from './Selectors/FormAutocompleteSelectNew'
import { Button, FormControl, Grid, IconButton, InputAdornment } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import FormHelperText from '@material-ui/core/FormHelperText'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'
import { regionsForSelect } from '../../utils'
import { BusinessRegionsEnumErrorTypes } from '../../types'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(5),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      '& label': {
        top: '-5px',
      },
      gap: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  volume: {
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
      paddingRight: 15,
      paddingTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      paddingRight: 5,
      '-moz-appearance': 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputLabel-root': {
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
      },
    },
  },
  addButton: {
    width: 132,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  buttonsBox: {
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      color: '#EF2828',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 20,
  },
  errorItem: {
    paddingLeft: 15,
  },
  volumeContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

type Region = { key: string; label: string }
type TransactionDataRegionsProps = {
  regionsData: Region[]
  readOnly?: boolean
  tooltipTitle?: NonNullable<React.ReactNode>
  isOnboarding?: boolean
}
type TransactionDataRegionsArray = Record<string, string>[]

const EMPTY_TRANSACTION_ITEM = { businessCategoryRegion: '', businessCategoryPercentage: '' }

const BusinessRegionsComponent: FC<TransactionDataRegionsProps> = ({
  readOnly,
  regionsData,
  tooltipTitle,
  isOnboarding,
}) => {
  const fieldName = 'businessRegions'

  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const methods = useFormContext()
  const { setValue, errors, control, formState, trigger, clearErrors, register } = methods
  const businessRegionsRef = useRef<HTMLDivElement>(null)

  const [activeRow, setActiveRow] = useState<number | null>(null)

  const regions = useWatch({
    name: fieldName,
    control,
  }) as TransactionDataRegionsArray

  const { fields, append, remove } = useFieldArray({ control, name: fieldName })

  const transactionDataPercentSum = useMemo(() => {
    return regions
      ? regions.reduce((acc, field) => acc + Number(field.businessCategoryPercentage), 0)
      : 0
  }, [regions])

  const error = errors ? errors[fieldName] : null

  const onAdd = useCallback(() => {
    append(EMPTY_TRANSACTION_ITEM)
  }, [setValue, regions])

  const onRemove = async (indexToRemove: number) => {
    const updatedRegions = [...regions]
    updatedRegions.splice(indexToRemove, 1)

    remove(indexToRemove)

    setValue(fieldName, updatedRegions)
    clearErrors(fieldName)
    await trigger(fieldName)
  }

  const isNoOptions = useMemo(() => Object.keys(regionsData).length - fields?.length <= 0, [
    fields,
    regionsData,
  ])
  const isEmptyRegions = useMemo(() => regions?.some((region) => !region.businessCategoryRegion), [
    regions,
  ])

  const disabledAdd =
    transactionDataPercentSum >= 100 || !!error || isNoOptions || isEmptyRegions || readOnly

  useEffect(() => {
    register(fieldName)
    if (isEmpty(fields)) {
      onAdd()
    }
  }, [register, fields])

  return (
    <FormProvider {...methods}>
      <Box ref={businessRegionsRef}>
        <GridRow ignore={isOnboarding}>
          {!isEmpty(fields) &&
            fields.map((field, index) => {
              return (
                <Box
                  key={field.id}
                  className={classes.container}
                  onMouseEnter={() => setActiveRow(index)}
                  onMouseLeave={() => setActiveRow(null)}
                >
                  <Box className={classes.twoRows}>
                    <FormControl className={classes.formControl}>
                      <FormAutocompleteSelectNew
                        label={t('businessRegion', 'Business region')}
                        name={`businessRegions[${index}].businessCategoryRegion`}
                        data={regionsForSelect(regionsData, regions, index)}
                        defaultValue={field?.businessCategoryRegion}
                        readOnly={readOnly}
                        onInputChange={() => trigger(fieldName)}
                        data-test="businessRegion"
                        className={classes.whiteBackground}
                      />
                    </FormControl>
                    <Box className={classes.volumeContainer}>
                      <FormControlledTextField
                        className={clsx(classes.volume, classes.whiteBackground)}
                        label={t('percentageOfVolume', 'Percentage of volume')}
                        name={`businessRegions[${index}].businessCategoryPercentage`}
                        fullWidth={true}
                        required={false}
                        type="number"
                        inputProps={{
                          step: '1',
                          style: { textAlign: 'right' },
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>,
                        }}
                        defaultValue={field?.businessCategoryPercentage || ''}
                        disabled={readOnly}
                        onInputChange={() => trigger(fieldName)}
                        data-test="percentageOfVolume"
                      />
                      {activeRow === index && !readOnly && (
                        <Grow in timeout={500}>
                          <Box className={classes.buttonsBox}>
                            <IconButton
                              color="inherit"
                              aria-label="delete"
                              onClick={() => onRemove(index)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Box>
                        </Grow>
                      )}
                    </Box>
                  </Box>
                </Box>
              )
            })}
          {!!error && (
            <Grid className={classes.errorMessage}>
              <Box xs={6}>
                {error.type === BusinessRegionsEnumErrorTypes.valueIsNotEmptyRegion && (
                  <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                )}
              </Box>

              <Box xs={6} className={classes.errorItem}>
                {error.type !== BusinessRegionsEnumErrorTypes.valueIsNotEmptyRegion && (
                  <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                )}
              </Box>
            </Grid>
          )}
          {tooltipTitle && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.businessRegions}
              title={tooltipTitle}
            />
          )}

          {isOnboarding ? (
            <LinkButton onClick={onAdd} disabled={disabledAdd}>
              + {t('addRegion', 'Add region')}
            </LinkButton>
          ) : (
            <Grid item className={classes.addButton}>
              <Button
                type="button"
                variant="contained"
                fullWidth={true}
                disableElevation
                onClick={onAdd}
                disabled={disabledAdd}
                data-test="addButtonBusinessRegion"
              >
                {t('add', 'Add')}
              </Button>
            </Grid>
          )}
        </GridRow>
      </Box>
    </FormProvider>
  )
}

export const BusinessRegions = React.memo(BusinessRegionsComponent, isEqual)
