import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core'
import { CreditTransaction, DebitTransaction, Document } from '../../../../graphql'

import {
  currencyFormat,
  dateFormat,
  getCurrencySign,
  getDocumentLabelByType,
} from '../../../../utils'

const useStyles = makeStyles(() => ({
  backGround: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&.checked': {
      backgroundColor: '#ffffff',
    },
  },
  tableRow: {
    border: 'none',
    '&:hover': {
      background: 'rgb(245, 245, 245)',
    },
  },
  name: {
    textDecoration: 'underline',
  },
}))

const MAX_COL_NAME_LENGTH = 50

export const UploadListRow: FC<{
  data: Document | undefined
  renderFileName: (data: Document | undefined) => string
}> = ({ data, renderFileName }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const fullRowName = renderFileName(data)
  const rowName =
    fullRowName.length > MAX_COL_NAME_LENGTH
      ? fullRowName.slice(0, MAX_COL_NAME_LENGTH)
      : fullRowName
  const rowTitle = fullRowName.length > MAX_COL_NAME_LENGTH ? { title: fullRowName } : {}

  const handleDetails = useCallback((url) => {
    const win = window.open(url, '_blank')
    if (win != null) {
      win.focus()
    }
  }, [])

  return (
    <TableRow
      hover
      className={clsx(classes.backGround, classes.tableRow)}
      onClick={() => handleDetails(data?.firstSide?.url)}
      data-url={`${data?.firstSide?.url}`}
      data-test="uploadedDocumentItem"
    >
      <TableCell data-test="autotest-uploadedDocumentName">
        <Box className={classes.name} {...rowTitle}>
          {rowName}
          {rowTitle?.title ? '...' : ''}
        </Box>
      </TableCell>
      <TableCell>
        <Box>{data?.account ? `${data?.account?.alias}` : t('none', 'None')}</Box>
      </TableCell>
      <TableCell>
        <Box>
          {data?.transaction
            ? `${getCurrencySign(data?.transaction?.currency)}${currencyFormat(
                Math.abs(data?.transaction?.value as number),
              )} ${
                (data?.transaction as DebitTransaction)?.to
                  ? t('toLowerCase', 'to') + ' ' + (data?.transaction as DebitTransaction)?.to?.name
                  : ''
              }
              ${
                (data?.transaction as CreditTransaction)?.from
                  ? t('fromLowerCase', 'from') +
                    ' ' +
                    (data?.transaction as CreditTransaction)?.from?.name
                  : ''
              }`
            : t('none', 'None')}
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          {data?.individual
            ? `${data?.individual?.firstName} ${data?.individual?.lastName}`
            : t('none', 'None')}
        </Box>
      </TableCell>
      <TableCell data-test="autotest-uploadDocumentType">
        <Box>{t(data?.type as string, getDocumentLabelByType(data?.type as string))}</Box>
      </TableCell>
      <TableCell>
        <Box data-test="dateUploaded">{dateFormat(data?.createdAt)}</Box>
      </TableCell>
    </TableRow>
  )
}

export default UploadListRow
