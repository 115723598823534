import { useEffect, useRef } from 'react'
import { delaysForRefetch } from '../../../constants'
import { AuthorizedPersonStatuses, ContractAuthorizedPersonsQuery } from '../../../graphql'

export const useTeamTableUpdate = (
  persons: ContractAuthorizedPersonsQuery | undefined,
  refetch: () => void,
  isModalOpen: boolean,
) => {
  const intervalEvolvingRef = useRef<ReturnType<typeof setInterval>>()
  const delayBeforeChecking = useRef<ReturnType<typeof setTimeout> | null>(null)

  const countToRefetch = 10

  const stopUpdate = () => {
    clearInterval(intervalEvolvingRef.current as ReturnType<typeof setInterval>)
    clearTimeout(delayBeforeChecking.current as ReturnType<typeof setTimeout>)
  }

  useEffect(() => {
    const isInProgress = persons?.contractAuthorizedPersons?.find(
      (person) =>
        person?.status === AuthorizedPersonStatuses.PendingInvitation ||
        person?.status === AuthorizedPersonStatuses.PendingSignature ||
        person?.status === AuthorizedPersonStatuses.PendingVerification,
    )
    if (isInProgress && !isModalOpen) {
      let currentRefetch = 0
      delayBeforeChecking.current = setTimeout(() => {
        intervalEvolvingRef.current = setInterval(() => {
          refetch()

          currentRefetch += 1
          if (countToRefetch < currentRefetch) {
            stopUpdate()
          }
        }, delaysForRefetch.interval)
      }, delaysForRefetch.delay)
    } else {
      stopUpdate()
    }

    return stopUpdate
  }, [persons?.contractAuthorizedPersons, isModalOpen])
}
