import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import InfoIcon from '../../../assets/images/icons/info_icon.svg?react'
import { useStyles } from '../../AplicationActions/Tabs/useStyles'

export const NeededToApprovedAlert: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.modalErrorMessage} data-test="wrongDocumentFormat">
      <Box component="span" className={classes.iconErrorMessage}>
        <InfoIcon />
      </Box>
      <Typography>
        {t(
          'YouHaveSignedActionButOtherNo',
          'You have signed this action but additional signatures are needed to approve it.',
        )}
      </Typography>
    </Box>
  )
}
