import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import { useHistory, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Hidden,
  makeStyles,
} from '@material-ui/core'
import {
  LegalEntity,
  useContractContactsQuery,
  ContractContact,
  useDeleteContractContactMutation,
} from '../../../graphql'
import { ConfirmationAnyModal, Loader } from '../../Common'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import IconDot from '../../../assets/images/icons/Dot.svg?react'
import Attention from '../../../assets/images/icons/attention.svg?react'
import { YesNoOptions } from '../../../utils/Data'

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 40,
      },
    },
    '& .MuiButton-outlinedSizeSmall': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.75, 2),
      },
    },
  },
  table: {
    borderTop: '0',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      marginBottom: theme.spacing(2),
    },
  },
  btnWrapp: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mobileTable: {
    '&.bordered': {
      border: '1px solid rgba(0,0,0,.1)',
      borderBottom: 0,
    },
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  mobileTableRow: {
    marginBottom: theme.spacing(0),
    '&:not(:first-child)': {
      borderTop: 0,
    },
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
    '&:nth-child(n+5)': {
      [theme.breakpoints.down('xs')]: {
        borderBottom: '0',
      },
    },
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
}))

export const ContactsTabs: FC<{
  fromReview?: boolean
}> = ({ fromReview = false }) => {
  const [contacts, setContacts] = useState<ContractContact[]>([])
  const [openDelete, setOpenDelete] = useState<number | string>(0)

  const history = useHistory()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { t } = useTranslation()
  const classes = useStyles()
  const [deleteContractContactMutation] = useDeleteContractContactMutation()
  const { data, loading, refetch: refetchContacts } = useContractContactsQuery({
    variables: {
      id: +applicationId,
    },
    skip: !applicationId,
  })

  useEffect(() => {
    setContacts(data?.contract?.contacts as ContractContact[])
  }, [data, setContacts])

  const locationCheck = history.location.pathname.includes('contacts/list')

  const handleModalDelete = useCallback((id) => {
    setOpenDelete(id)
  }, [])

  const handleClose = useCallback(() => {
    setOpenDelete(0)
  }, [setOpenDelete])

  const onConfirmDelete = useCallback(
    async (contactId) => {
      await deleteContractContactMutation({
        variables: {
          id: contactId,
        },
      })
      await refetchContacts({ id: applicationId })
      setOpenDelete(0)
      // isCPChangedAfterReviewVar(true)
    },
    [refetchContacts, applicationId],
  )

  const onEdit = useCallback((contact) => {
    // isCPChangedAfterReviewVar(true)
    history.push(
      generatePath(APP_PATHS.application.contacts.edit, {
        applicationId,
        contactId: contact.id,
      }),
    )
  }, [])

  const onAdd = useCallback(() => {
    history.push(generatePath(APP_PATHS.application.contacts.new, { applicationId }))
  }, [])

  if (loading) {
    return <Loader />
  }

  if (contacts && contacts.length === 0 && !fromReview) {
    return <></>
  }

  return (
    <>
      <Box className={classes.head}>
        <Typography variant="h2" className={classes.title}>
          {t('contactsOf', 'Contacts of', {
            companyName: (data?.contract?.owner as LegalEntity)?.companyName,
          })}
        </Typography>

        <Box className={classes.btnWrapp} hidden={locationCheck}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={onAdd}
            disableElevation
            size="small"
            data-test="addNew-btn"
          >
            {t('addNew', 'Add new')}
          </Button>
        </Box>
      </Box>
      <Hidden xsDown>
        <Box mb={6}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('name', 'Name')}</TableCell>
                  <TableCell>{t('email', 'Email')}</TableCell>
                  <TableCell>{t('phoneNumber', 'Phone Number')}</TableCell>
                  <TableCell>{t('primaryContact', 'Primary Contact')}</TableCell>
                  <TableCell>{t('actions', 'Actions')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {contacts &&
                  contacts.map((contact, index) => {
                    return (
                      contact && (
                        <React.Fragment key={`${contact.id}_${index}`}>
                          <TableRow hover>
                            <TableCell>
                              <Box>{contact.contactInfo?.name}</Box>
                            </TableCell>
                            <TableCell>{contact.contactInfo?.email}</TableCell>
                            <TableCell>{contact.contactInfo?.phone}</TableCell>
                            <TableCell>
                              {
                                find(
                                  YesNoOptions,
                                  (o) => o.key === (contact.isPrimary ? 'Yes' : 'No'),
                                )?.label
                              }
                            </TableCell>
                            <TableCell>
                              <Box className={classes.buttonsBox}>
                                <Button
                                  aria-label="Edit"
                                  type="button"
                                  onClick={() => onEdit(contact)}
                                >
                                  {t('edit', 'Edit')}
                                </Button>
                                <IconDot />
                                <Button
                                  color="secondary"
                                  aria-label="delete"
                                  type="button"
                                  onClick={() => handleModalDelete(contact.id)}
                                >
                                  {t('delete', 'Delete')}
                                </Button>

                                <ConfirmationAnyModal
                                  title={t('delete', 'Delete')}
                                  color={'error'}
                                  icon={<Attention />}
                                  name="delete"
                                  handleClose={() => handleClose()}
                                  handleConfirm={() => onConfirmDelete(contact.id)}
                                  isOpen={openDelete === contact.id}
                                  labelCancel={t('cancel', 'Cancel')}
                                  labelConfirm={t('delete', 'Delete')}
                                >
                                  <Typography>
                                    {t(
                                      'deleteContactConfirm',
                                      'Are you sure you want to delete this contact',
                                    )}
                                    ?
                                  </Typography>
                                </ConfirmationAnyModal>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable} mb={6}>
          {contacts &&
            contacts?.map((contact, index) => {
              return (
                contact && (
                  <React.Fragment key={`${contact.id}_${index}`}>
                    <Box key={contact.id} className={classes.mobileTableRow}>
                      <Box className={classes.mobileTableItem}>
                        <Box className={classes.mobileTableLabel}>{t('name', 'Name')}</Box>
                        <Box>{contact.contactInfo?.name}</Box>
                      </Box>

                      <Box className={classes.mobileTableItem}>
                        <Box className={classes.mobileTableLabel}>{t('email', 'Email')}</Box>
                        <Box>{contact.contactInfo?.email}</Box>
                      </Box>

                      <Box className={classes.mobileTableItem}>
                        <Box className={classes.mobileTableLabel}>
                          {t('phoneNumber', 'Phone Number')}
                        </Box>
                        <Box>{contact.contactInfo?.phone}</Box>
                      </Box>

                      <Box className={classes.mobileTableItem}>
                        <Box className={classes.mobileTableLabel}>
                          {t('primaryContact', 'Primary Contact')}
                        </Box>
                        <Box>
                          {
                            find(YesNoOptions, (o) => o.key === (contact.isPrimary ? 'Yes' : 'No'))
                              ?.label
                          }
                        </Box>
                      </Box>

                      <Box className={classes.mobileTableItem}>
                        <Box>
                          <Box className={classes.buttonsBox}>
                            <Button aria-label="Edit" type="button" onClick={() => onEdit(contact)}>
                              {t('edit', 'Edit')}
                            </Button>
                            <IconDot />
                            <Button
                              color="secondary"
                              aria-label="delete"
                              type="button"
                              onClick={() => handleModalDelete(contact.id)}
                            >
                              {t('delete', 'Delete')}
                            </Button>

                            <ConfirmationAnyModal
                              title={t('delete', 'Delete')}
                              color={'error'}
                              icon={<Attention />}
                              name="delete"
                              handleClose={() => handleClose()}
                              handleConfirm={() => onConfirmDelete(contact.id)}
                              isOpen={openDelete === contact.id}
                              labelCancel={t('cancel', 'Cancel')}
                              labelConfirm={t('delete', 'Delete')}
                            >
                              <Typography>
                                {t(
                                  'deleteContactConfirm',
                                  'Are you sure you want to delete this contact',
                                )}
                                ?
                              </Typography>
                            </ConfirmationAnyModal>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </React.Fragment>
                )
              )
            })}
        </Box>
      </Hidden>
    </>
  )
}
