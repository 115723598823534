import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useCopyBtnStyles = makeStyles((theme: Theme) => ({
  btnCopy: {
    minWidth: 15,
    minHeight: 15,
    width: 15,
    height: 15,
    marginRight: 12,
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,
      transition: `${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .MuiSvgIcon-colorPrimary': {
      color: '#276EF1',
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#999',
    },
  },
}))
