import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { cardPaymentsRegions } from '../../../../constants'
import { CardPaymentsAndAmp } from '../../../../graphql'
import { getLabelsSeparatedByCommas, newCurrenciesLabelsArray } from '../../../../utils'
import { BusinessRegions, FormAutocompleteMultiSelect } from '../../../Common'
import { WebsiteInput } from '../../../Common/WebsiteInput'
import ControlledTooltipWrapped from '../../../Common/Tooltips/ControlledTooltipWrapped'
import {
  makeTooltipTitleTextDoubleInputBusinessRegions,
  makeTooltipTitleTextWebsites,
  useCommonStyles,
} from '../helpers/functions'
import { PciCompliance } from '../PciCompilance'
import { AdditionalRegulatoryQuestions } from './AdditionalRegulatoryQuestions'
import { AlternativePaymentMethods } from './AlternativePaymentMethods'
import { CardsAccepted } from './CardsAccepted'

const useStyles = makeStyles((theme) => ({
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 20,
  },
  titleMargin: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
}))

const PaymentProcessingComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: CardPaymentsAndAmp | null
}> = ({ readOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const { formState, watch } = useFormContext()
  const trxCurrencies = watch('trxCurrencies')
  const settlementCurrencies = watch('settlementCurrencies')

  return (
    <>
      <Typography className={classes.titleMargin} variant="h5">
        {t('transactionData', 'Transaction data')}
      </Typography>

      <BusinessRegions
        readOnly={readOnly}
        regionsData={cardPaymentsRegions}
        tooltipTitle={makeTooltipTitleTextDoubleInputBusinessRegions(
          changedContractProfileData?.cardPaymentsTransactionalDataRegion,
          { divider: classes.lightDivider, listItem: classes.listItem },
        )}
        isOnboarding
      />
      <Box>
        <FormAutocompleteMultiSelect
          className={clsx(classes.boxMultiSelect, classes.whiteBackground)}
          name="trxCurrencies"
          label={t(`transactionCurrencies`, 'Transaction currencies')}
          data={newCurrenciesLabelsArray()}
          value={trxCurrencies}
          readOnly={readOnly}
          data-test="transactionCurrencies"
        />
        {changedContractProfileData?.transactionalCurrencies && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.trxCurrencies}
            title={getLabelsSeparatedByCommas(
              changedContractProfileData?.transactionalCurrencies,
              newCurrenciesLabelsArray(),
            )}
          />
        )}
      </Box>
      <Box>
        <FormAutocompleteMultiSelect
          className={clsx(classes.boxMultiSelect, classes.whiteBackground)}
          name="settlementCurrencies"
          label={t(`requiredSettlementCurrencies`, 'Required settlement currencies')}
          data={newCurrenciesLabelsArray()}
          value={settlementCurrencies}
          readOnly={readOnly}
          data-test="settlementCurrencies"
        />
        {changedContractProfileData?.requiredSettlementCurrencies && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.settlementCurrencies}
            title={getLabelsSeparatedByCommas(
              changedContractProfileData?.requiredSettlementCurrencies,
              newCurrenciesLabelsArray(),
            )}
          />
        )}
      </Box>

      <CardsAccepted changedContractProfileData={changedContractProfileData} readOnly={readOnly} />

      <AlternativePaymentMethods
        changedContractProfileData={changedContractProfileData}
        readOnly={readOnly}
      />

      <AdditionalRegulatoryQuestions
        changedContractProfileData={changedContractProfileData?.riskManagement}
        isViewOnly={readOnly}
      />

      <Box mt={2}>
        <Typography variant="h5">{t('merchantWebsites', 'Merchant websites')}</Typography>
        <Typography variant="h6" className={classes.titleSubBox}>
          {t(
            'pleaseListTheWebsiteWhereYouOfferYourProductsOrServicesOnline',
            'Please list the website(s) where you offer your products and/or services online',
          )}
        </Typography>
        <WebsiteInput
          name="merchantWebsites"
          label={t('website', 'Website')}
          addBtnText={`+ ${t('addWebsite', 'Add website')}`}
          isViewOnly={readOnly}
          tooltipTitle={makeTooltipTitleTextWebsites(changedContractProfileData?.merchantWebsites, {
            divider: classes.lightDivider,
            listItem: classes.listItem,
          })}
        />
      </Box>

      <PciCompliance
        changedContractProfileData={changedContractProfileData?.pciDssCompliance}
        isViewOnly={readOnly}
        isOnboarding
      />
    </>
  )
}

export const PaymentProcessing = React.memo(PaymentProcessingComponent)
