import React, { FC } from 'react'
import {
  Box,
  Hidden,
  makeStyles,
  Theme,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'

import './styles.css'
import UploadFailed from './../../../assets/images/icons/upload_failed.svg?react'
import PdfIcon from './../../../assets/images/icons/pdf.svg?react'
import JpgIcon from './../../../assets/images/icons/jpg.svg?react'
import PngIcon from './../../../assets/images/icons/png.svg?react'
import { ProofOfIdentityList } from '../../../utils/Data'

const useStyles = (errorUpload: boolean) =>
  makeStyles((theme: Theme) => ({
    fileInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      fontSize: '12px',
      width: '100%',
      '& .MuiAutocomplete-listbox': {
        maxHeight: '120px',
      },
    },
    fileLeftInfo: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      maxWidth: '100%',
      // whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    infoBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '10px',
    },
    fileInfoName: {
      padding: theme.spacing(0, 1, 0, 1.5),
      color: errorUpload ? '#e11900' : '#000000',
    },
    fileInfoSize: {
      padding: theme.spacing(0, 1, 0, 1.5),
      color: '#999',
    },
    fileIcon: {
      padding: theme.spacing(0.5, 0, 0, 0),
    },
    fileBlock: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    fileInfoProgress: {
      width: 128,
    },
    fileInfoPercent: {
      paddingLeft: theme.spacing(1),
      fontWeight: 700,
      color: '#276EF1',
    },
    removeFileButton: {
      minWidth: 44,
      fontSize: '12px',
      color: '#999',
      justifyContent: 'flex-end',
    },
    reloadFileButton: {
      minWidth: 44,
      fontSize: '12px',
      color: '#999',
    },
    uploadFailed: {
      color: '#e11900',
    },
    dropZoneLabel: {
      margin: theme.spacing(2, 0, 0),
      fontSize: '12px',
      color: '#ccc',
    },
  }))()

type FilesListUploadProps = {
  name: string
  size: string
  percent: number
  removeFileHandler?: () => void
  status: string
  errorUpload?: boolean
  type: string
  docType: string
  optionalIdentityList?: { key: string; label: string }[]
  forCp?: boolean
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
}

export const FilesListUpload: FC<FilesListUploadProps> = ({
  name,
  size,
  percent,
  removeFileHandler,
  status,
  errorUpload,
  type,
  docType,
  optionalIdentityList,
  forCp = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles(errorUpload ?? false)

  return (
    <Box className={classes.fileInfo}>
      {!forCp && (
        <Typography className={classes.dropZoneLabel}>
          {t(
            find(optionalIdentityList ? optionalIdentityList : ProofOfIdentityList, {
              key: docType,
            })?.label as string,
          )}
        </Typography>
      )}
      <Box className={classes.infoBlock}>
        <Box className={classes.fileLeftInfo}>
          <Box className={classes.fileIcon}>
            {type === 'application/pdf' && <PdfIcon />}
            {type === 'image/png' && <PngIcon />}
            {type === 'image/jpeg' && <JpgIcon />}
          </Box>
          <Box className={classes.fileBlock}>
            <Box component={forCp ? 'div' : 'span'} className={classes.fileInfoName}>
              {name}
            </Box>
            <Box component={forCp ? 'div' : 'span'} className={classes.fileInfoSize}>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {size}&nbsp;KB
            </Box>
          </Box>
        </Box>
        {status !== 'done' &&
          (errorUpload ? (
            <Box className={classes.uploadFailed}>{t('uploadFailed', 'Upload failed')}</Box>
          ) : (
            <Box>
              <Hidden smDown>
                <Box className={classes.fileInfoProgress}>
                  <LinearProgress variant="determinate" value={Math.round(percent)} />
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box component="span" className={classes.fileInfoPercent}>
                  {Math.round(percent)}%
                </Box>
              </Hidden>
            </Box>
          ))}
        {errorUpload && (
          <IconButton aria-label="reload" className={classes.reloadFileButton}>
            <UploadFailed fontSize="small" />
          </IconButton>
        )}
        {removeFileHandler && (
          <IconButton
            aria-label="remove"
            className={classes.removeFileButton}
            onClick={() => (removeFileHandler ? removeFileHandler() : () => true)}
            data-test="removeFileButton"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
