import { createConfig, configureChains, WagmiConfig, Chain } from 'wagmi'
import { mainnet, sepolia } from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { ConnectKitProvider, getDefaultConfig } from 'connectkit'
import config from '../../config'
import React from 'react'

const isProduction = import.meta.env.MODE === 'production'
const chains: Chain[] = isProduction ? [mainnet] : [sepolia]
const { publicClient } = configureChains(chains, [
  infuraProvider({ apiKey: config.crypto.infuraApiKey }),
])

export const wagmiConfig = createConfig(
  getDefaultConfig({
    autoConnect: true,
    publicClient,
    chains: chains,
    walletConnectProjectId: config.crypto.walletConnectId,
    appName: config.name,
  }),
)

export const WagmiProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <ConnectKitProvider theme="minimal" options={{ hideNoWalletCTA: true }}>
        {children}
      </ConnectKitProvider>
    </WagmiConfig>
  )
}
