import { find, isEmpty } from 'lodash'
import * as yup from 'yup'
import { validCountriesKeyList } from '../components'

import i18n from '../i18n'
import {
  LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD,
  INVALID_DATE_FORMAT_MESSAGE,
  NUMBER_AND_LATINICA_ONLY_MESSAGE,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
  COMPANY_NAME_REGEX,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
  MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE,
  NUMBER_AND_LATINICA_ONLY_REGEXP,
  POSTAL_CODE_MATCH,
  POSTAL_CODE_REQUIRED,
  MIN_ZIPCODE_LENGTH,
  MAX_ZIPCODE_LENGTH,
  CYRILIC_REGEXP,
  ALL_ARABIC_REGEXP,
  SPECIAL_SYMBOLS_REGEXP,
} from '../constants/validations'
import { isFuture } from 'date-fns'
import {
  AdditionalDetailsOptionalCorrespondenceInputSchema,
  AdditionalDetailsOptionalInputSchema,
  CountriesOfOperationSchema,
  PhoneNumberInputSchema,
  PostalCodeInputSchema,
  RegistryNumberInputSchema,
  StreetAddressInputSchema,
  TaxIdSchema,
} from './common'
import { isDateStringValid } from '../utils/dateUtils'
import { CompanyNameInputSchema } from './common/CompanyName'
import {
  GeneralQuestionsInputSchema,
  PartnersInputSchema,
  RegulatorInformationImputSchema,
  TransactionalQuestionsInputSchema,
  UrlsInputSchema,
} from './PreAssessmentOnboarding'
import { CardPaymentAndApmSchema } from './PreAssessmentOnboarding/CardPaymentAndApmSchema'
import { ExpectedVolumesInputSchema } from './PreAssessmentOnboarding/ExpectedVolumesSchema'

export const ManagementBusinessDetailsInputSchema = yup.lazy((virtualFormFields) =>
  yup
    .object()
    .shape({
      correspondenceRequired: yup.boolean().notRequired(),
      entityType: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
      tradingName: yup
        .string()
        .test({
          name: 'tradingNameLength',
          message: MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE(MIN_LENGTH),
          test: (value) => (!!value && value?.length >= MIN_LENGTH) || value === '',
        })
        .test({
          name: 'tradingName',
          message: NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
          test: (value) => !!value?.match(COMPANY_NAME_REGEX) || value === '',
        }),
      registrationDate: yup
        .mixed()
        .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
        .test({
          name: 'minDate',
          message: i18n.t('dateRegistrationMin', "Date of Registration can't be in the future"),
          test: (value) => !isFuture(new Date(value)),
        })
        .test({
          name: 'invalidDateFormat',
          message: INVALID_DATE_FORMAT_MESSAGE,
          test: (value) => value && isDateStringValid(value),
        }),
      city: yup
        .string()
        .required(i18n.t('cityIsRequired', 'City is required'))
        .test(
          'minSymbols',
          MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE(MIN_LENGTH),
          (valueInput) => !!valueInput && valueInput?.length > 1,
        )
        .test('cityInvalid', i18n.t('invalidCity', 'Invalid city'), (inputValue) => {
          const cyrillicValidationRegex = RegExp(CYRILIC_REGEXP)
          const allArabicValidationRegexp = RegExp(ALL_ARABIC_REGEXP)
          const specialSymbols = RegExp(SPECIAL_SYMBOLS_REGEXP)

          let testResult = true
          if (typeof inputValue === 'string') {
            testResult =
              !cyrillicValidationRegex.test(inputValue) &&
              !allArabicValidationRegexp.test(inputValue) &&
              !specialSymbols.test(inputValue)
          }
          return testResult
        }),
      country: yup
        .mixed()
        .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val))
        .test(
          'dontOfferServicesInThisCountry',
          i18n.t(
            'sorryWeDontOfferServicesInThisCountry',
            'Sorry, we don’t offer services in this country',
          ),
          (val) => !!find(validCountriesKeyList, (item) => item === val),
        ),
      countryCorrespondence: yup.mixed().when('correspondenceRequired', {
        is: true,
        then: yup
          .mixed()
          .test(
            'countryCorrespondenceNotEmpty',
            THIS_FIELD_IS_REQUIRED_MESSAGE,
            (val) => !isEmpty(val),
          ),
      }),
      streetAddressCorrespondence: yup.string().when('correspondenceRequired', {
        is: true,
        then: yup
          .string()
          .matches(LATINICA_ONLY_REGEXP_NOT_REQUIRED_FIELD, NUMBER_AND_LATINICA_ONLY_MESSAGE)
          .required(i18n.t('streetAddressIsRequired', 'Street Address is required')),
      }),
      cityCorrespondence: yup.string().when('correspondenceRequired', {
        is: true,
        then: yup
          .string()
          .required(i18n.t('cityIsRequired', 'City is required'))
          .test(
            'minSymbols',
            MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE(MIN_LENGTH),
            (valueInput) => !!valueInput && valueInput?.length > 1,
          )
          .test('cityInvalid', i18n.t('invalidCity', 'Invalid city'), (inputValue) => {
            const cyrillicValidationRegex = RegExp(CYRILIC_REGEXP)
            const allArabicValidationRegexp = RegExp(ALL_ARABIC_REGEXP)
            const specialSymbols = RegExp(SPECIAL_SYMBOLS_REGEXP)

            let testResult = true
            if (typeof inputValue === 'string') {
              testResult =
                !cyrillicValidationRegex.test(inputValue) &&
                !allArabicValidationRegexp.test(inputValue) &&
                !specialSymbols.test(inputValue)
            }
            return testResult
          }),
      }),
      postalCodeCorrespondence: yup.string().when('correspondenceRequired', {
        is: true,
        then: yup
          .string()
          .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
          .matches(NUMBER_AND_LATINICA_ONLY_REGEXP, POSTAL_CODE_MATCH)
          .test(
            'minLength',
            POSTAL_CODE_REQUIRED,
            (val) => !!val && val?.length >= MIN_ZIPCODE_LENGTH,
          )
          .test('maxLength', POSTAL_CODE_REQUIRED, (val) => {
            return !!val && val?.length <= MAX_ZIPCODE_LENGTH
          }),
      }),
      countryOfTaxResidence: yup
        .mixed()
        .test('countryNotEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val)),
    })
    .concat(UrlsInputSchema)
    .concat(PostalCodeInputSchema)
    .concat(RegistryNumberInputSchema)
    .concat(TaxIdSchema)
    .concat(CompanyNameInputSchema)
    .concat(RegulatorInformationImputSchema)
    .concat(GeneralQuestionsInputSchema)
    .concat(PhoneNumberInputSchema)
    .concat(ExpectedVolumesInputSchema)
    .concat(TransactionalQuestionsInputSchema)
    .concat(PartnersInputSchema)
    .concat(CardPaymentAndApmSchema(virtualFormFields))
    .concat(StreetAddressInputSchema)
    .concat(AdditionalDetailsOptionalInputSchema)
    .concat(AdditionalDetailsOptionalCorrespondenceInputSchema)
    .concat(CountriesOfOperationSchema),
)

export type ManagementBusinessDetailsInput = yup.Asserts<
  typeof ManagementBusinessDetailsInputSchema
>
