import { isNil } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

export const useUpdateUrlParams = (key: string) => {
  const history = useHistory()
  const location = useLocation()

  return (value?: string | number | boolean) => {
    const params = new URLSearchParams(location.search)
    if (!isNil(value)) {
      params.set(key, value.toString())
    } else {
      params.delete(key)
    }
    history.replace({ ...history.location, search: params.toString() })
  }
}
