import { businessCategoryQuestions } from '@klarpay/enums'
import { Box, FormControl, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { filter, isEmpty, isEqual, map, orderBy } from 'lodash'
import React, { FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AnticipatedAccountActivities,
  ContractProfileChangeAnswers,
  EconomicProfile,
  Maybe,
  PreAssessment,
} from '../../../graphql'
import { NoYes } from '../../../types'
import { getLabelByKey, getLabelsSeparatedByCommas } from '../../../utils'
import {
  anticipatedAccountActivitiesListValues,
  BusinessCategoriesList,
  countriesAndRegions,
  economicProfileCompanySourceFundsTypesList,
  YesNoOptions,
} from '../../../utils/Data'
import {
  BaseEnumWithKey,
  FormAutocompleteMultiSelect,
  FormAutocompleteSelect,
  FormAutocompleteSelectMultiCustomValue,
  FormControlledTextField,
} from '../../Common'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'
import { useCommonStyles } from './helpers/functions'
import { RegulatorInfoSection } from './RegulatorInfoSection'
import { YesNoSelector } from './YesNoSelector'

const useStyles = makeStyles((theme) => ({
  longLabel: {
    [theme.breakpoints.down('md')]: {
      '& .MuiInputBase-input': {
        transform: `translate(0, 8px)`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: theme.spacing(11),
      '& .MuiInput-root': {
        minHeight: theme.spacing(11),
      },
      '& label': {
        transform: 'translate(0, 14px) scale(1)',
      },
      '& label.MuiFormLabel-filled': {
        transform: 'translate(0, 0px) scale(0.857)',
      },
      '& label.Mui-focused': {
        transform: 'translate(0, 0px) scale(0.857)',
      },
    },
    [theme.breakpoints.up(1450)]: {
      '& label.MuiFormLabel-filled': {
        maxWidth: '100%',
        transform: 'translate(0, 0) scale(0.857)',
      },
      '& label.Mui-focused': {
        maxWidth: '100%',
        transform: 'translate(0, 0px) scale(0.857)',
      },
    },
  },
  minHeight: {
    '& .MuiInputBase-root': {
      minHeight: theme.spacing(7.5),
    },
  },
  multiselectHeight: {
    '& .MuiInputBase-root': {
      minHeight: theme.spacing(6.4),
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 4,
    },
  },
  dirtyControl: {
    position: 'relative',
    marginTop: theme.spacing(1.5),
  },
  extraMargin: {
    marginTop: theme.spacing(3.5),
  },
  dirtySpaceTop: {
    '& .MuiFormHelperText-root': {
      top: -3,
    },
  },
  fundsTypeLabel: {
    '& .MuiFormHelperText-root': {
      top: 6,
    },
  },
  nonMargin: {
    margin: 0,
  },
  moveDown: {
    transform: 'translate(0, 18px)',
  },
  marginBottom: {
    marginBottom: theme.spacing(2.5),
  },
}))

export const RegulatoryQuestionsComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
  isFromBusinessDetails?: boolean
}> = ({ readOnly = false, changedContractProfileData, isFromBusinessDetails }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const methods = useFormContext()
  const { watch, formState, control, setValue } = methods
  const businessSector = watch('businessSector')
  const businessRelationshipWithCompany = watch('businessRelationshipWithCompany')
  const sourceOfFunds = watch('sourceOfFunds')
  const isBusinessSectorFieldDirty = formState.dirtyFields['businessSector']

  const businessSectorQuestions = useMemo(() => {
    return filter(businessCategoryQuestions, (question) => {
      const isBusinessSectorIncluded = map(question.businessTypes, 'question.key').includes(
        businessSector,
      )
      if (isBusinessSectorIncluded && question.type !== 'dropdown' && isBusinessSectorFieldDirty) {
        setValue(`businessSector${question.key}`, '')
      }

      return isBusinessSectorIncluded
    })
  }, [businessSector, businessCategoryQuestions, setValue, isBusinessSectorFieldDirty])

  const isBusinessSectorHidden = (value: keyof Maybe<PreAssessment>, key: string): boolean => {
    return (
      !changedContractProfileData?.preAssessment?.[value] ||
      watch(`businessSector`) === changedContractProfileData?.businessSector ||
      formState.errors[`businessSector${key}`]
    )
  }

  const getSourceOfFundsLabel = (changes: EconomicProfile | null | undefined) => {
    if (!changes) return ''

    if (changes.sourceOfFundsDescription && !changes.sourceOfFunds) {
      return `${getLabelsSeparatedByCommas(
        sourceOfFunds,
        economicProfileCompanySourceFundsTypesList as BaseEnumWithKey[],
      )}, ${changes.sourceOfFundsDescription}`
    }

    return `${
      changes.sourceOfFunds
        ? getLabelsSeparatedByCommas(
            changes.sourceOfFunds,
            economicProfileCompanySourceFundsTypesList as BaseEnumWithKey[],
          )
        : ''
    }${changes.sourceOfFunds && changes.sourceOfFundsDescription ? ', ' : ''} ${
      changes.sourceOfFundsDescription ? changes.sourceOfFundsDescription : ''
    }`
  }

  return (
    <>
      <Box mt={isFromBusinessDetails ? 0 : 2} mb={2}>
        <Typography variant="h4" className={classes.title}>
          {t('regulatoryQuestions', 'Regulatory questions')}
        </Typography>
      </Box>

      <Box mb={isFromBusinessDetails ? 0 : 4}>
        <Controller
          name="businessSector"
          control={control}
          defaultValue={''}
          render={() => (
            <FormAutocompleteSelect
              name="businessSector"
              className={`${classes.whiteBackground} ${classes.marginBottom}`}
              label={t('businessCategory', 'Business Category')}
              data={orderBy(
                BusinessCategoriesList.filter((c) => c.available),
                'label',
              )}
              readOnly={readOnly}
              data-test="businessCategoryParent"
              filterOptionPosition="any"
            />
          )}
        />

        {changedContractProfileData?.businessSector && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={!changedContractProfileData.businessSector || formState.errors.businessSector}
            title={getLabelByKey(changedContractProfileData.businessSector, BusinessCategoriesList)}
            arrowHorizontalPosition={'left'}
          />
        )}
      </Box>

      <Typography variant="h6" className={classes.titleSubBox}>
        {t(
          'describeWhatYouSellSellTo',
          'Please describe what you sell, whom you sell to, and when you charge your customers',
        )}
      </Typography>
      <Box>
        <FormControlledTextField
          label={t('businessDescription', 'Business Description')}
          name="businessActivitiesAndServices"
          type="text"
          fullWidth
          multiline
          required={false}
          data-test="businessActivitiesAndServices"
          className={classes.whiteBackground}
        />
        {changedContractProfileData?.businessDescription && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              !changedContractProfileData.businessDescription ||
              formState.errors.businessActivitiesAndServices
            }
            title={changedContractProfileData?.businessDescription ?? ''}
            arrowHorizontalPosition={'left'}
          />
        )}
      </Box>

      <Box mt={2}>
        <FormControl
          className={clsx(
            classes.boxInput,
            classes.multiselectHeight,
            classes.nonMargin,
            classes.whiteBackground,
            classes.boxMultiSelect,
          )}
        >
          <FormAutocompleteMultiSelect
            name="countriesOfOperatingAndConductingBusiness"
            label={t('countriesOfOperation', 'Countries of operation')}
            data={countriesAndRegions}
            readOnly={readOnly}
          />
        </FormControl>
        {changedContractProfileData?.countriesOfOperatingAndConductingBusiness && (
          <ControlledTooltipWrapped
            wrapperClass={clsx(classes.dirtyControl, classes.moveDown)}
            hidden={
              !changedContractProfileData.countriesOfOperatingAndConductingBusiness ||
              formState.errors.countriesOfOperatingAndConductingBusiness
            }
            title={getLabelsSeparatedByCommas(
              changedContractProfileData?.countriesOfOperatingAndConductingBusiness,
              countriesAndRegions,
            )}
            arrowHorizontalPosition={'left'}
          />
        )}
      </Box>

      {!isFromBusinessDetails && (
        <Typography variant="h6" className={classes.titleSubBox}>
          {t(
            'pleaseChooseOneOrMoreAnticipatedActivitiesCompanyAccounts',
            'Please choose one or more anticipated activities of the company accounts',
          )}
        </Typography>
      )}
      <Box mt={isFromBusinessDetails ? 4 : 0}>
        <FormControl
          className={clsx(
            classes.boxInput,
            classes.multiselectHeight,
            classes.nonMargin,
            classes.whiteBackground,
          )}
        >
          <FormAutocompleteMultiSelect
            className={classes.whiteBackground}
            name="anticipatedAccountActivity"
            label={t('anticipatedActivities', 'Anticipated activities')}
            data={anticipatedAccountActivitiesListValues}
            readOnly={readOnly}
            data-test="anticipatedAccountActivity"
          />
        </FormControl>

        {!isEmpty(changedContractProfileData?.economicProfile?.anticipatedAccountActivity) && (
          <ControlledTooltipWrapped
            wrapperClass={`${classes.dirtyControl} ${classes.dirtySpaceTop}`}
            hidden={formState.errors.anticipatedAccountActivity}
            title={getLabelsSeparatedByCommas(
              changedContractProfileData?.economicProfile
                ?.anticipatedAccountActivity as AnticipatedAccountActivities[],
              anticipatedAccountActivitiesListValues,
            )}
            arrowHorizontalPosition={'left'}
          />
        )}
      </Box>

      <Box mt={5.5}>
        <Typography variant="h6" className={classes.titleSubBox}>
          {t(
            'pleaseProvidePaymentProviders',
            'Please list banks, electronic money institutions and payment providers you work with',
          )}
        </Typography>
        <FormControlledTextField
          className={clsx(classes.boxInput, classes.whiteBackground)}
          label={t('banksEMIsAndPaymentProviders', 'Banks, EMIs and payment providers')}
          name="paymentProviders"
          type="text"
          fullWidth
          multiline
          required={false}
          data-test="paymentProviders"
        />
        {changedContractProfileData?.preAssessment?.paymentProviders && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={formState.errors.paymentProviders}
            title={changedContractProfileData.preAssessment.paymentProviders}
            arrowHorizontalPosition={'left'}
          />
        )}
      </Box>

      <Box mb={changedContractProfileData?.preAssessment?.businessRelationshipWithCompany ? 5 : 0}>
        <YesNoSelector
          name="businessRelationshipWithCompany"
          disabled={readOnly}
          label={t(
            'doYouHoldOtherBusinessRelationsWithKlarpay',
            'Do you hold other business relations with Bivial?',
          )}
          yesOption={NoYes.yes}
          noOption={NoYes.no}
          mt={5.5}
        />
        {changedContractProfileData?.preAssessment?.businessRelationshipWithCompany && (
          <ControlledTooltipWrapped
            wrapperClass={clsx(classes.dirtyControl, classes.extraMargin)}
            hidden={formState.errors.businessRelationshipWithCompany}
            title={getLabelByKey(
              changedContractProfileData.preAssessment.businessRelationshipWithCompany,
              YesNoOptions,
            )}
            arrowHorizontalPosition={'left'}
          />
        )}
      </Box>

      {businessRelationshipWithCompany === 'Yes' && (
        <Box>
          <FormControlledTextField
            className={clsx(classes.boxInput, classes.whiteBackground)}
            label={t('pleaseSpecify', 'Please specify')}
            name="businessRelationshipWithCompanyExplanation"
            type="text"
            fullWidth
            multiline
            required={false}
            data-test="businessRelationshipWithCompanyExplanation"
            disabled={readOnly}
          />
          {changedContractProfileData?.preAssessment
            ?.businessRelationshipWithCompanyExplanation && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.businessRelationshipWithCompanyExplanation}
              title={
                changedContractProfileData.preAssessment.businessRelationshipWithCompanyExplanation
              }
              arrowHorizontalPosition={'left'}
            />
          )}
        </Box>
      )}

      {businessSectorQuestions.map((question) => {
        const changedPreAssessmentValue =
          changedContractProfileData?.preAssessment?.[question.value as keyof Maybe<PreAssessment>]

        return (
          <Box key={question.key}>
            {question.type === 'dropdown' ? (
              <>
                <YesNoSelector
                  name={`businessSector${question.key}`}
                  disabled={readOnly}
                  label={question.label}
                  mt={2}
                  yesOption={NoYes.yes}
                  noOption={NoYes.no}
                  mb={changedPreAssessmentValue ? 2 : 0}
                />
                {changedPreAssessmentValue && (
                  <ControlledTooltipWrapped
                    wrapperClass={classes.dirtyControl}
                    hidden={!changedPreAssessmentValue}
                    title={getLabelByKey(changedPreAssessmentValue, YesNoOptions)}
                    arrowHorizontalPosition={'left'}
                  />
                )}
              </>
            ) : (
              <div>
                <FormControlledTextField
                  label={question.label}
                  name={`businessSector${question.key}`}
                  type="text"
                  fullWidth
                  multiline
                  className={clsx(
                    classes.boxInput,
                    classes.whiteBackground,
                    question.label.length > 60 && classes.longLabel,
                  )}
                  required={false}
                />
                {changedPreAssessmentValue && (
                  <ControlledTooltipWrapped
                    wrapperClass={classes.dirtyControl}
                    hidden={isBusinessSectorHidden(
                      question.value as keyof Maybe<PreAssessment>,
                      question.key,
                    )}
                    title={changedPreAssessmentValue}
                    arrowHorizontalPosition={'left'}
                  />
                )}
              </div>
            )}
          </Box>
        )
      })}

      <RegulatorInfoSection
        readOnly={readOnly}
        changedContractProfileData={changedContractProfileData?.preAssessment}
      />

      <Box>
        <Typography variant="h6" className={classes.titleSubBox}>
          {t(
            'pleaseChooseOneOrMoreSourcesOfFunds',
            'Please choose one or more sources of funds for your company',
          )}
        </Typography>
        <FormControl
          className={clsx(
            classes.boxInput,
            classes.minHeight,
            classes.nonMargin,
            classes.whiteBackground,
          )}
        >
          <FormAutocompleteSelectMultiCustomValue
            name="sourceOfFunds"
            label={t('sourceOfFunds', 'Source of Funds')}
            data={economicProfileCompanySourceFundsTypesList}
            disabled={readOnly}
            data-test="sourceOfFunds"
          />
        </FormControl>
        {(changedContractProfileData?.economicProfile?.sourceOfFunds ||
          changedContractProfileData?.economicProfile?.sourceOfFundsDescription) && (
          <ControlledTooltipWrapped
            wrapperClass={`${classes.dirtyControl} ${classes.fundsTypeLabel}`}
            hidden={formState.errors.sourceOfFunds}
            title={getSourceOfFundsLabel(changedContractProfileData.economicProfile)}
            arrowHorizontalPosition={'left'}
          />
        )}
      </Box>
    </>
  )
}

export const RegulatoryQuestions = React.memo(RegulatoryQuestionsComponent, isEqual)
