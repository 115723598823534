import React, { FC, useCallback, useState, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, makeStyles, Box, Button, FormControl } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import PencilIcon from '../../assets/images/icons/icon_pencil.svg?react'
import CheckIcon from '../../assets/images/icons/icon-checkmark.svg?react'

import { cardsPageStateVar, FormTextField } from '../index'
import { CardNameInputSchema } from '../../schemes'
import { AlertDataType } from '../../types'
import { CardsListDocument, CardType, useRenameCardMutation } from '../../graphql'
import SuccessIcon from '../../assets/images/icons/confirmed.svg?react'
import { useParams } from 'react-router-dom'
import { PATH_PARAMS } from '../../routes/paths'
import { usePageFiltersSorting, useRowPerPage } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  wrapRename: {
    maxWidth: 340,
    outline: 'none',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 5,
  },
  bodyRename: {
    margin: theme.spacing(0),
  },
  form: {
    position: 'relative',
  },
  formControl: {
    width: '100%',
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    '& .Mui-disabled': {
      backgroundColor: '#ffffff',
    },
    '& .MuiInputLabel-marginDense': {
      transform: 'translate(0, 12px) scale(0.857)',
    },
  },
  iconGroupCardName: {
    display: 'flex',
    alignItems: 'center',
  },
  iconCardName: {
    position: 'absolute',
    cursor: 'pointer',
    bottom: 30,
    right: 0,
    padding: 0,
    minWidth: 18,
    minHeight: 18,
    '&.iconPencil': {
      right: 15,
      bottom: 35,
      opacity: 0.4,
    },
    '&.iconCheck': {
      bottom: 34,
      right: 51,
    },
    '&.iconClose': {
      right: 15,
      '& .MuiSvgIcon-root': {
        color: '#000000',
      },
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}))

type RenameCardModalProps = {
  cardId: string | number
  alias: string
  setIsOpenAlert: (value: boolean) => void
  setAlertData: (value: AlertDataType) => void
  closeModal?: () => void
  setSelectedCardData: React.Dispatch<React.SetStateAction<CardType | undefined>>
}

export const RenameCard: FC<RenameCardModalProps> = ({
  cardId,
  alias,
  setIsOpenAlert,
  setAlertData,
  setSelectedCardData,
}) => {
  const [renameFieldEnable, setRenameFieldEnable] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(CardNameInputSchema),
  })
  const { setValue, watch, clearErrors } = methods
  const filedCardName = watch('cardName')

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const [renameCardMutation] = useRenameCardMutation()

  const { pageFilters } = usePageFiltersSorting(cardsPageStateVar)
  const { page, searchValue } = pageFilters
  const { itemsPerPage } = useRowPerPage()

  const handlerOnClose = useCallback(
    (isRevertName: boolean) => {
      setRenameFieldEnable(false)
      isRevertName && setValue('cardName', alias)

      clearErrors('cardName')
    },
    [alias],
  )

  const onSave = useCallback(
    async (formData) => {
      try {
        const newName: string = formData.cardName
        const { data } = await renameCardMutation({
          variables: {
            cardId,
            ...(!newName ? {} : { alias: newName.trim() }),
          },
          refetchQueries: [
            {
              query: CardsListDocument,
              variables: {
                contractId: applicationId,
                search: searchValue,
                limit: itemsPerPage,
                orderBy: pageFilters.sortBy,
                orderDirection: pageFilters.direction,
                offset: (Number(page) - 1) * itemsPerPage,
              },
            },
          ],
        })

        if (data?.renameCard) {
          setSelectedCardData((prevState) => ({ ...prevState, alias: newName } as CardType))
          setAlertData({
            text: 'Card has been renamed',
            type: 'success',
            icon: <SuccessIcon />,
          })
          setIsOpenAlert(true)
          setValue('cardName', newName)
          handlerOnClose(false)
        }
      } catch (e) {
        return
      }
    },
    [cardId, setAlertData, handlerOnClose],
  )

  const handlerOnFocus = useCallback(() => {
    setRenameFieldEnable(true)
  }, [renameFieldEnable])

  const checkNotChanged = useMemo(() => {
    return filedCardName === alias
  }, [filedCardName, alias])

  return (
    <Grid container item className={classes.wrapRename}>
      <Grid item xs={12} className={classes.bodyRename}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSave)} className={classes.form}>
            <FormControl className={classes.formControl}>
              <FormTextField
                label={t('cardName', 'Card name')}
                name="cardName"
                type="text"
                defaultValue={alias}
                minLength={2}
                required={false}
                disabled={!renameFieldEnable}
                inputProps={{
                  style: { paddingRight: '83px', textOverflow: 'ellipsis' },
                }}
              />
            </FormControl>
            {renameFieldEnable ? (
              <Box className={classes.iconGroupCardName}>
                <Button
                  className={clsx(classes.iconCardName, 'iconCheck')}
                  type="submit"
                  color="primary"
                  disabled={checkNotChanged}
                >
                  {<CheckIcon />}
                </Button>
                <Button
                  onClick={() => handlerOnClose(true)}
                  className={clsx(classes.iconCardName, 'iconClose')}
                >
                  {<CloseIcon />}
                </Button>
              </Box>
            ) : (
              <Button onClick={handlerOnFocus} className={clsx(classes.iconCardName, 'iconPencil')}>
                {<PencilIcon />}
              </Button>
            )}
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  )
}
