import React, { FC, useCallback, useState } from 'react'
import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ClosedInfoBlock } from './ClosedInfoBlock'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      margin: '32px 0 56px 0',
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 16px 10px 16px',
        borderRadius: 4,
        background: '#F0F0F0',
        cursor: 'pointer',
        '&.open': {
          borderRadius: '4px 4px 0 0',
        },
        '& .MuiTypography-paragraph': {
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          fontWeight: '700',
          color: '#434343',
          margin: 0,
        },
      },
    },
    root: {
      border: '1px solid #e5e5e5',
      borderBottom: '1px solid #e5e5e5',
      '&::-webkit-scrollbar': {
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
      '&.signatoryScroll': {
        overflowY: 'auto',
      },
    },
    table: {
      border: 'none',
    },
    tableBody: {
      '& .MuiTableRow-root': {
        height: '48px',
      },
      '& .MuiTableRow-root:last-child': {
        '& .MuiTableCell-root': { border: 'none' },
      },
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    mobileRow: {
      borderTop: '1px solid rgba(0,0,0,.1)',
      '& .MuiTableCell-root': { border: 'none' },
    },
  }),
)

const TableColumnExplainedComponent: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isExpand, setIsExpand] = useState<boolean>(false)

  const handleToggle = useCallback(() => setIsExpand((prevState) => !prevState), [setIsExpand])

  const information: {
    column: string
    description: string
    example: string
    isRequired?: boolean
  }[] = [
    {
      column: t('amount', 'Amount'),
      description: t(
        'batchFileAmountDescription',
        'Any positive number to two decimal places with a period decimal separator.',
      ),
      example: '59.99',
      isRequired: true,
    },
    {
      column: t('currency', 'Currency'),
      description: t(
        'batchFileCurrencyDescription',
        "The currency code of the account you're making batch payment from.",
      ),
      example: t('chfOrEur', 'CHF or EUR'),
      isRequired: true,
    },
    {
      column: t('beneficiaryType', 'Beneficiary type'),
      description: t(
        'batchFileBeneficiaryTypeDescription',
        'The Beneficiary Type is the category of the recipient as to whether they are an Individual or a Company.',
      ),
      example: t('companyOrIndividual', 'Company or Individual'),
      isRequired: true,
    },
    {
      column: t('beneficiaryName', 'Beneficiary Name'),
      description: t(
        'batchFileBeneficiaryNameDescription',
        "The Beneficiary's name is the given name on the Bank Account of the recipient. In the majority of cases, this will be the legal name of the recipient unless specified differently by the recipient.",
      ),
      example: t('SomeCompanyLimited', 'Some Company Limited'),
      isRequired: true,
    },
    {
      column: t('beneficiaryAccountIdType', 'Beneficiary Account ID Type'),
      description: t(
        'batchFileBeneficiaryAccountIdTypeDescription',
        'The Beneficiary Account Identifier Type is whether the account is identified with an IBAN (International Bank Account Number) or a regular Account Number. If the Beneficiary Account ID Type is unknown then "Account number" can be used, but the user should note that the entry will not go through the IBAN validation process which may result in an error in the payment should the identifier end up actually being an IBAN and it is input incorrectly.',
      ),
      example: 'IBAN or Account number',
      isRequired: true,
    },
    {
      column: t('beneficiaryAccountId', 'Beneficiary account ID'),
      description: t(
        'batchFileBeneficiaryAccountIdDescription',
        "The Beneficiary's IBAN (International Bank Account Number) or Account Number.",
      ),
      example: 'CH5604835012345678009',
      isRequired: true,
    },
    {
      column: t('bankBic', 'bank BIC'),
      description: t(
        'batchFileBankBicDescription',
        "The Beneficiary's bank's BIC (Business Identifier Code)/SWIFT.",
      ),
      example: 'ABCDCH22',
      isRequired: true,
    },
    {
      column: t('street1', 'street 1'),
      description: t(
        'batchFileStreet1Description',
        "The Beneficiary's street address registered on their Bank Account. In the majority of cases, this will be their registered address unless specified differently by the recipient.",
      ),
      example: '1 Hauptstrasse',
      isRequired: true,
    },
    {
      column: t('street2', 'street 2"'),
      description: t(
        'batchFileStreet2Description',
        "If the Beneficiary's street address requires an additional line this optional field can be used.",
      ),
      example: 'Office 101, Floor 1',
      isRequired: false,
    },
    {
      column: t('city', 'City'),
      description: t(
        'batchFileBeneficiaryCity',
        "Beneficiary's address city. See Address Line 1 for additional information.",
      ),
      example: 'Zug',
      isRequired: true,
    },
    {
      column: t('postalCode', 'Postal code'),
      description: t(
        'batchFileBeneficiaryPostalCodeDescription',
        "Beneficiary's Postal Code. See Address Line 1 for additional information.",
      ),
      example: '1234',
      isRequired: false,
    },
    {
      column: t('country', 'Country'),
      description: t(
        'batchFileBeneficiaryCountryDescription',
        "Beneficiary's Country address. See Address Line 1 for additional information. Note: The Country in the generated bulk file will be displayed as the ISO 3166-1 alpha-2.",
      ),
      example: t('countryExample'),
      isRequired: true,
    },
    {
      column: t('reference', 'Reference'),
      description: t('paymentReferenceDescription', 'Payment Reference/Description'),
      example: 'Invoice 1111/11',
      isRequired: true,
    },
    {
      column: t('priority', 'Priority'),
      description: t(
        'batchFilePriorityDescription',
        'The priority of the payment. Currently, the only accepted value is standard.',
      ),
      example: t('standard', 'Standard'),
      isRequired: true,
    },
    {
      column: t('payoutCurrency', 'Payout Currency'),
      description: t(
        'batchFilePayoutCurrencyDescription',
        'Required for cross-currency payments.\nThe currency code for the amount to payout for cross-currency payments.\nNot used when doing same currency payment.',
      ),
      example: 'ILS',
      isRequired: false,
    },
    {
      column: t('payoutAmount', 'Payout amount'),
      description: t(
        'batchFilePayoutAmountDescription',
        'Required for cross-currency payments.\nThe amount in the payout currency for cross-currency payments. \n Note: This value and the current quoted x-rate would be used to recalculate the `amount` field. \n Not used when doing same currency payment.',
      ),
      example: t('payoutAmountExample', '200.00'),
      isRequired: false,
    },
    {
      column: t('useLocalAchPayout', 'Use Local ACH Payout'),
      description: t(
        'batchFileUseLocalAchPayout',
        '\nYes/No Flag to use Local ACH Payout for supported cross-currency payment, defaults to `no`. \nIgnored when `payoutCurrency` is not supported or account is not authorized to use local ACH payouts.',
      ),
      example: 'yes or no',
      isRequired: false,
    },
    {
      column: t('fixedPayoutAmount', 'Fixed Payout Amount'),
      description: t(
        'batchFileFixedPayoutAmount',
        'Yes/No For cross currency transfers, if set to `yes` this would ensure the payout value remains the same in the event of a change in quoted fx rate. Defaults to `no`',
      ),
      example: 'yes or no',
      isRequired: false,
    },
    {
      column: t('bankClearingMemberId', 'Bank Clearing Member ID'),
      description: t(
        'batchFileBankClearingMemberId',
        'The bank local clearing code (Sort / BSB / Routing Code), required for some countries',
      ),
      example: '123002',
      isRequired: false,
    },
    {
      column: t('bankBranchId', 'Bank branch ID'),
      description: t('batchFileBankBranchId', 'The ID of the local bank branch'),
      example: '0141',
      isRequired: false,
    },
    {
      column: t('bankBranchName', 'Bank branch name'),
      description: t('batchFileBankBranchName', 'The name of the local Bank Branch'),
      example: 'ABC Bank Main',
      isRequired: false,
    },
    {
      column: t('purposeCode', 'Payment Purpose Code'),
      description: t(
        'batchFilePurposeCode',
        'The payment purpose code for payments to country that requires a purpose code e.g payments to banks in UAE',
      ),
      example: 'POP',
      isRequired: false,
    },
    {
      column: t('beneficiaryPhone', 'Phone Number of Beneficiary'),
      description: t(
        'batchFileBeneficiaryPhone',
        'Phone number of beneficiary internationally formatted with leading +',
      ),
      example: '+27842547398',
      isRequired: false,
    },
    {
      column: t('beneficiaryEmail', 'Email of Beneficiary'),
      description: t('batchFileBeneficiaryEmail', 'Valid email address of beneficiary'),
      example: 'beneficiary@example.com',
      isRequired: false,
    },
    {
      column: t('chargeBearer', 'Charge Bearer'),
      description: t(
        'chargeBearerDescription',
        'Party to pay transaction charges.\nValid values are (our, shared), defaults to shared. \n Limited Support*',
      ),
      example: 'shared',
      isRequired: false,
    },
  ]

  return (
    <Box className={classes.wrapper}>
      <ClosedInfoBlock handleToggle={handleToggle} tableOpen={isExpand}>
        <Typography paragraph={true}>
          {t('tableColumnsExplained', 'Table columns explained')}
        </Typography>
      </ClosedInfoBlock>

      <Box hidden={!isExpand}>
        <Hidden mdDown>
          <TableContainer className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('column', 'Column')}</TableCell>
                  <TableCell>{t('description', 'Description')}</TableCell>
                  <TableCell>{t('example', 'Example')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {information.map(({ column, description, example, isRequired }, i) => (
                  <TableRow key={i}>
                    <TableCell width="180" className={classes.capitalize}>
                      {column}
                      {isRequired ? '*' : ''}
                    </TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{example}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2}>
                    <b>{t('batchFileDescriptionLegend', '* Required Fields')}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>

        <Hidden lgUp>
          <TableContainer className={classes.root}>
            <Table className={classes.table}>
              <TableBody>
                {information.map(({ column, description, example, isRequired }, index) => (
                  <TableRow key={index} className={classes.mobileRow}>
                    <TableRow>
                      <TableCell>
                        <b>{t('column', 'Column')}</b>
                      </TableCell>
                      <TableCell className={classes.capitalize}>
                        {column}
                        {isRequired ? '*' : ''}
                      </TableCell>
                    </TableRow>

                    {description && (
                      <TableRow>
                        <TableCell>
                          <b>{t('description', 'Description')}</b>
                        </TableCell>
                        <TableCell>{description}</TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell>
                        <b>{t('example', 'Example')}</b>
                      </TableCell>
                      <TableCell>{example}</TableCell>
                    </TableRow>
                  </TableRow>
                ))}
                <TableRow className={classes.mobileRow}>
                  <TableCell colSpan={2}>
                    <b>{t('batchFileDescriptionLegend', '* Required Fields')}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
      </Box>
    </Box>
  )
}

export const TableColumnExplained = React.memo(TableColumnExplainedComponent)
