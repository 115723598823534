import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { generatePath, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'

import IconSubmit from '../../../assets/images/icons/submit96.svg?react'
import { SignatoryRight } from '../../../graphql'
import { useDetermineUserRights } from '../../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'
import config from '../../../config'

const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: 264,
    margin: '20px 12px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      margin: '10px 0',
    },
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: 32,
    // width: '350px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  info: {
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 12,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    width: '590px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const TerminateContractResult: FC<{
  signatureIdToSign: string | number | undefined
  setShowTabsMenu: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ signatureIdToSign, setShowTabsMenu }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)

  const title = useMemo(() => {
    return !signatureIdToSign && userRights?.signatoryRight === SignatoryRight.Sole
      ? t('contractTerminationRequested', 'Contract termination requested!')
      : t('contractTerminationRequestCreated', 'Contract termination request created!')
  }, [signatureIdToSign, userRights])

  const text = useMemo(() => {
    if (!signatureIdToSign && userRights?.signatoryRight === SignatoryRight.Sole) {
      return t(
        'itWillBeExecutedManually',
        'It will be executed manually by the {{val1}} back office.',
        {
          val1: config.name,
        },
      )
    } else if (!!signatureIdToSign && userRights?.signatoryRight === SignatoryRight.Sole) {
      return t(
        'itWillBeExecutedManuallyAfterYouSign',
        'It will be executed manually by the {{val1}} back office after you sign it.',
        {
          val1: config.name,
        },
      )
    } else {
      return t(
        'itWillBeExecutedManuallyAfterAllSignatoriesSign',
        'It will be executed manually by the {{val1}} back office after all Signatories sign it.',
        {
          val1: config.name,
        },
      )
    }
  }, [signatureIdToSign, userRights])

  const proceedToSigning = useCallback(() => {
    !!signatureIdToSign &&
      history.push(
        generatePath(APP_PATHS.dashboard.actionDetails, {
          [PATH_PARAMS.applicationId]: applicationId,
          [PATH_PARAMS.signatureId]: signatureIdToSign,
        }),
      )
  }, [signatureIdToSign])

  useEffect(() => {
    return () => setShowTabsMenu(true)
  }, [])

  return (
    <Grid item xs={12} className={classes.container}>
      <Box mt={18}>
        <IconSubmit />
      </Box>

      <Typography variant={'h2'} className={classes.title}>
        {title}
      </Typography>

      <Typography variant={'h2'} className={classes.info}>
        {text}
      </Typography>

      {!!signatureIdToSign && !userRights?.limitedAccessRight && (
        <Box className={classes.btnGroup}>
          <>
            <Button
              onClick={proceedToSigning}
              variant="contained"
              disableElevation
              color="primary"
              className={classes.btn}
            >
              {t('proceedToSigning', 'Proceed to signing')}
            </Button>
          </>
        </Box>
      )}
    </Grid>
  )
}

export default TerminateContractResult
