import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormContext, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { find, isEmpty, map, mapValues, toNumber } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {
  AlertDataType,
  ChangesType,
  ContractProfileChangesType,
  ExpectedVolumes,
  FilesForSavingAndShowType,
} from '../../types'
import { PATH_PARAMS } from '../../routes/paths'
import {
  extractDateValue,
  parseWebsitesFormData,
  scrollToInputElement,
  searchDifference,
  showError,
} from '../../utils'
import {
  ActionSignature,
  ActionSignatureRequestsCountDocument,
  ActionSignatureStatus,
  BankableEntityContractInputType,
  ContractAuthorizedPersonType,
  CreateOwnDocumentMutationVariables,
  DocumentType,
  GetContractProfileChangesDocument,
  Products,
  ProfileChange,
  ProfileChangeStatus,
  SignatoryRight,
  useContractDetailsChangeMutation,
  useCreateOwnDocumentMutation,
  useGetActionSignatureLazyQuery,
} from '../../graphql'
import InfoIcon from '../../assets/images/icons/info_icon.svg?react'
import { useDetermineUserRights } from '../../hooks'
import { AlertTipItem } from './Tips'
import InformationIcon from '../../assets/images/icons/info_icon.svg?react'
import Unchecked from '../../assets/images/icons/unchecked_icon.svg?react'
import Checked from '../../assets/images/icons/cheked_icon.svg?react'
import { ConfirmSignatureModal } from './ActionsSign2FAModal'
import { ChangesConfirmationSubList } from './ChangesConfirmationSubList'
import { ContractManagementProfileDocsInputSchema } from '../../schemes'
import {
  changesForTypes,
  economicProfileCompanySourceFundsTypesList,
  economicProfileTransferDescriptionTypesList,
  economicProfileTransferPayrollTypesList,
  YesNoOptions,
} from '../../utils/Data'
import config from '../../config'
import { ManagementBusinessDetailsInput } from '../../schemes/ManagementBusinessDetails'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 512,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
    '& > div > form': {
      width: '100%',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
    borderBottom: '1px solid #ccc',
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  scrollable: {
    maxHeight: 'calc(100vh - 350px)',
    overflowY: 'auto',
  },
  footerModal: {
    margin: theme.spacing(5, 3, 3, 3),
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  submitBtn: {
    [theme.breakpoints.up('sm')]: {
      width: '215px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '215px',
    },
  },
  canselBtn: {
    [theme.breakpoints.up('sm')]: {
      width: '92px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '215px',
    },
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  tipContainer: {
    margin: theme.spacing(3, 3, 0, 3),
  },
  controlEditConf: {
    marginLeft: theme.spacing(3.5),
  },
}))

type EditRequestModalProps = {
  isOpen: boolean
  closeModal: () => void
  formData: Record<string, unknown>
  correspondenceNeeded: boolean
  signatures?: ContractAuthorizedPersonType[] | undefined | null
  setAlertData?: React.Dispatch<React.SetStateAction<AlertDataType | undefined>>
  missingInfoId: string
}

export const ChangesConfirmationModal: FC<EditRequestModalProps> = ({
  isOpen,
  closeModal,
  formData,
  correspondenceNeeded,
  signatures,
  setAlertData,
  missingInfoId,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [changes, setChanges] = useState<ChangesType[]>()
  const [modalStyle] = useState(getModalStyle)
  const { watch } = useFormContext()
  const changeFormData = watch() as ManagementBusinessDetailsInput
  const [signNow, setSignNow] = useState(false)
  const [actionModal, setActionModal] = useState<string | undefined>('')

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(ContractManagementProfileDocsInputSchema),
  })

  const { watch: watchModal, setValue, clearErrors, handleSubmit } = methods
  const [
    contractDetailsChangeMutation,
    { error: errorDataSaving },
  ] = useContractDetailsChangeMutation()
  const [createOwnDocument, { error: errorUpload }] = useCreateOwnDocumentMutation({})

  const [GetActionSignatureQuery, { data: dataAction }] = useGetActionSignatureLazyQuery()

  useEffect(() => {
    const changedData = searchDifference(formData, changeFormData)
    setChanges(changedData)
  }, [formData, isOpen])

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId)
  const isLimitedAccessRight = !!userRights?.limitedAccessRight
  const isJointRight = userRights?.signatoryRight === SignatoryRight.Joint
  const renderInfoAlert = useMemo(() => {
    const alertText =
      userRights?.signatoryRight === SignatoryRight.Sole
        ? t(
            'signatorySoleAlert',
            'This edit request needs to be signed by you and then approved by the {{val1}} team.',
            {
              val1: config.name,
            },
          )
        : t(
            'notSolePresentedAlert',
            'This edit request needs to be signed by the Signatories and then approved by the {{val1}} team.',
            {
              val1: config.name,
            },
          )

    return (
      <Grid item xs={12}>
        <Box className={classes.tipContainer}>
          <AlertTipItem value={alertText} iconComponent={<InformationIcon />} type={'primary'} />
        </Box>
      </Grid>
    )
  }, [signatures, userRights])

  useEffect(() => {
    isLimitedAccessRight && setSignNow(false)
  }, [isLimitedAccessRight, setSignNow])

  const handleSignNow = useCallback(() => {
    setSignNow(!signNow)
  }, [signNow, setSignNow])

  const makeAlertMessage = () => {
    if (setAlertData) {
      if (isJointRight) {
        setAlertData({
          text: `${t(
            'BOJointApproveAndSignMsg',
            'Your business details will be updated after Signatories sign and {{val1}} team approves it',
            {
              val1: config.name,
            },
          )}`,
          type: 'warning',
          icon: <InfoIcon />,
        })
        return
      }

      if (!signNow) {
        setAlertData({
          text: `${t(
            'BOApproveAndSignMsg',
            'Your business details will be updated after you sign and {{val1}} team approves it.',
            {
              val1: config.name,
            },
          )}`,
          type: 'warning',
          icon: <InfoIcon />,
        })
        return
      }

      if (signNow && !isJointRight) {
        setAlertData({
          text: `${t(
            'BOApproveMsg',
            'Your business details will be updated after {{val1}} team approves them',
            {
              val1: config.name,
            },
          )}`,
          type: 'warning',
          icon: <InfoIcon />,
        })
        return
      }
    }
  }

  const handleChange = useCallback(async () => {
    const currencyAndVolumeArray: string[] = []
    const currenciesArray: string[] = []

    const expectedVolumesValues = watch('expectedVolumes')

    expectedVolumesValues.forEach((item: ExpectedVolumes) => {
      if (!!item.currency) {
        currencyAndVolumeArray.push(`${item.expectedVolume ?? '0'}% ${item.currency}`)
        currenciesArray.push(item.currency.toLowerCase())
      }
    })

    const cardPayAmpArr = isEmpty(changeFormData.businessRegions)
      ? null
      : map(changeFormData.businessRegions, (r) => ({
          businessCategoryRegion: r.businessCategoryRegion,
          businessCategoryPercentage: Number(r.businessCategoryPercentage),
        }))

    const apmCurrArr = []
    const apmCurr: [] = changeFormData.apmCurrently ?? []
    for (let i = 0; i < apmCurr.length; i++) {
      if (!changeFormData['apmCurrentlyInp1' + i]) {
        break
      }
      apmCurrArr.push({
        ampInUseTypeCurrentlyInUse: changeFormData['apmCurrentlyInp1' + i],
        monthlyVolumeInEuro: String(changeFormData['apmCurrentlyInp2' + i]),
      })
    }

    const apmReqArr = []
    const apmReq: [] = changeFormData.apmRequired ?? []
    for (let i = 0; i < apmReq.length; i++) {
      if (!changeFormData['apmRequiredInp1' + i]) {
        break
      }
      apmReqArr.push({
        ampInUseTypeCurrentlyInUse: changeFormData['apmRequiredInp1' + i],
        monthlyVolumeInEuro: String(changeFormData['apmRequiredInp2' + i]),
      })
    }

    const merchantWebsites = parseWebsitesFormData(changeFormData?.merchantWebsites) ?? []

    const fileInfo: FilesForSavingAndShowType = changeFormData?.historyStatement?.[0]
    const preassessmentDocument = {
      type: DocumentType.PreassessmentHistoryStatementOfCurrentFormerProcessor,
      id: fileInfo?.id,
      firstSide: {
        fileName: fileInfo?.fileName,
        plainUrl: fileInfo?.plainUrl ?? '',
        size: fileInfo?.size,
      },
    }

    const formSourceOfFunds = Array.isArray(changeFormData.sourceOfFunds)
      ? changeFormData.sourceOfFunds
      : []
    const formPartnersIncoming = Array.isArray(changeFormData.partnersIncoming)
      ? changeFormData.partnersIncoming
      : []
    const formPartnersOutgoing = Array.isArray(changeFormData.partnersOutgoing)
      ? changeFormData.partnersOutgoing
      : []

    const updates: BankableEntityContractInputType = {
      companyName: changeFormData.companyName,
      tradingName: !!changeFormData.tradingName
        ? changeFormData.tradingName
        : changeFormData.companyName,
      taxId: changeFormData.taxId,
      address: {
        line1: changeFormData.streetAddress,
        additionalDetails: changeFormData.additionalDetailsOptional,
        zip: changeFormData.postalCode,
        city: changeFormData.city,
      },
      phone: changeFormData.phoneNumber,
      website: parseWebsitesFormData(changeFormData.urls),
      businessSector: changeFormData.businessSector,
      businessDescription: changeFormData.businessActivitiesAndServices,
      countriesOfOperatingAndConductingBusiness:
        changeFormData.countriesOfOperatingAndConductingBusiness,
      ...(!correspondenceNeeded
        ? {
            correspondenceAddress: {
              line1: changeFormData.streetAddress,
              additionalDetails: changeFormData.additionalDetailsOptional,
              zip: changeFormData.postalCode,
              city: changeFormData.city,
              country: changeFormData.country,
            },
          }
        : {
            correspondenceAddress: {
              line1: changeFormData.streetAddressCorrespondence,
              additionalDetails: changeFormData.additionalDetailsOptionalCorrespondence,
              zip: changeFormData.postalCodeCorrespondence,
              city: changeFormData.cityCorrespondence,
              country: changeFormData.countryCorrespondence,
            },
          }),
      economicProfile: {
        products: changeFormData.products ?? [],
        sourceOfFunds: formSourceOfFunds.filter((itemData: string) =>
          find(
            economicProfileCompanySourceFundsTypesList,
            (itemCurrentValue) => itemCurrentValue.key === itemData,
          ),
        ),
        sourceOfFundsDescription:
          formSourceOfFunds.filter(
            (itemData: string) =>
              !find(
                economicProfileCompanySourceFundsTypesList,
                (itemCurrentValue) => itemCurrentValue.key === itemData,
              ),
          )[0] ?? 'N/A',
        anticipatedAccountActivity: changeFormData.anticipatedAccountActivity,
        currencies: currenciesArray,
        estimateInbound: +changeFormData.estimatedIncomingTransactionsAmount,
        estimateOutbound: +changeFormData.estimatedOutgoingTransactionsAmount,
      },
      preAssessment: {
        ...(changeFormData.products.includes(Products.PaymentProcessing)
          ? {
              cardPayment: {
                isCardPayment: true,
                cardPaymentsTransactionalDataRegion: isEmpty(cardPayAmpArr) ? null : cardPayAmpArr,
                transactionalCurrencies: changeFormData?.trxCurrencies || [],
                requiredSettlementCurrencies: changeFormData.settlementCurrencies ?? null,
                totalPredictedMonthlyVolumesVisa: changeFormData?.volumesVisa
                  ? mapValues(changeFormData.volumesVisa, toNumber)
                  : null,
                totalPredictedMonthlyVolumesMasterCard: changeFormData?.volumesMaster
                  ? mapValues(changeFormData?.volumesMaster, toNumber)
                  : {},
                ampCurrentlyInUse: isEmpty(apmCurrArr) ? undefined : apmCurrArr,
                ampRequiredCurrentlyInUse: isEmpty(apmReqArr) ? undefined : apmReqArr,
                riskManagement: {
                  useAffiliateForGeneratingTraffic:
                    changeFormData.selAffiliatesTraffic === YesNoOptions[0].key,
                  affiliatesUsed: changeFormData.affiliatesList ?? null,
                  useCredentialOnFilesBillingSalesMethod:
                    changeFormData.selCredentialBillingMethod === YesNoOptions[0].key,
                  relevantCredentialOnFilesBillingSalesMethod:
                    changeFormData.credentialDetails ?? null,
                  credentialDetailsExplanation: changeFormData.credentialDetailsExplanation ?? null,
                },
                pciDssCompliance: {
                  integrationTypeRequired: changeFormData.integrationTypeSelect ?? null,
                  dssCompliance: changeFormData.pciCompliance ?? null,
                  pciProviderName: changeFormData.pciProvidersName ?? null,
                },
                processingHistory: {
                  processPaymentCardsBefore:
                    changeFormData.processedHistory === YesNoOptions[0].key,
                  nameOfCurrentFormerProcessor: changeFormData.currentProcessor ?? null,
                  reasonForLeavingCurrentProcessor: changeFormData.reasonLeavingProcessor ?? null,
                  periodOfTimeWithCurrentFormerProcessor:
                    changeFormData.periodWithProcessor ?? null,
                  processingHistoryStatement: !isEmpty(changeFormData?.historyStatement)
                    ? preassessmentDocument
                    : undefined,
                },
                merchantWebsites,
              },
            }
          : { cardPayment: { isCardPayment: false } }),
        licenseRequired: changeFormData.licenseRequired,
        // licenseCountry: changeFormData.licenseRequired ? changeFormData.country : undefined,
        regulatoryInfoName: changeFormData.licenseRequired
          ? changeFormData.regulatorNameAndWebsite
          : '',
        regulatoryInfoLicenseNumber: changeFormData.licenseRequired
          ? changeFormData.licenseNumber
          : '',
        paymentProviders: changeFormData.paymentProviders,
        conductingKYC: changeFormData.businessSectorConductingKYC || undefined,
        conductingClientKYC: changeFormData.businessSectorConductingClientKYC || undefined,
        conductPublisherContentMonitoring:
          changeFormData.businessSectorConductPublisherContentMonitoring || undefined,
        clientsYouWorkWith: changeFormData.businessSectorClientsYouWorkWith || undefined,
        companyAnnualIncome: `${changeFormData.companyAnnualIncome} ${changeFormData.companyAnnualIncomeCurrency}`,
        incomingMonthlyTurnover: changeFormData.estimatedIncomingTransactions,
        outgoingMonthlyTurnover: changeFormData.estimatedOutgoingTransactions,
        currencyAndVolume: currencyAndVolumeArray,
        countryOfTaxResidence: changeFormData?.countryOfTaxResidence,
        partnersIncoming: formPartnersIncoming?.filter(Boolean)?.map((p) => ({
          name: p.name,
          country: p.country,
          bankCountry: p.bankCountry,
          businessSector: p.businessSector,
          paymentPurpose: p.paymentPurpose?.filter((itemData: string) =>
            find(
              economicProfileTransferDescriptionTypesList,
              (itemCurrentValue) => itemCurrentValue.key === itemData,
            ),
          ),
          paymentPurposeOther:
            p.paymentPurpose?.filter(
              (itemData: string) =>
                !find(
                  economicProfileTransferDescriptionTypesList,
                  (itemCurrentValue) => itemCurrentValue.key === itemData,
                ),
            )[0] ?? 'N/A',
          website: p.website,
        })),
        partnersOutgoing: formPartnersOutgoing?.filter(Boolean)?.map((p) => ({
          name: p.name,
          country: p.country,
          bankCountry: p.bankCountry,
          businessSector: p.businessSector,
          paymentPurpose: p.paymentPurpose?.filter((itemData: string) =>
            find(
              economicProfileTransferPayrollTypesList,
              (itemCurrentValue) => itemCurrentValue.key === itemData,
            ),
          ),
          paymentPurposeOther:
            p.paymentPurpose?.filter(
              (itemData: string) =>
                !find(
                  economicProfileTransferPayrollTypesList,
                  (itemCurrentValue) => itemCurrentValue.key === itemData,
                ),
            )[0] ?? 'N/A',
          website: p.website,
        })),
        noPartnersIncomingRequired: changeFormData.noPartnersIncomingRequired,
        noPartnersOutgoingRequired: changeFormData.noPartnersOutgoingRequired,
        businessRelationshipWithCompany:
          changeFormData.businessRelationshipWithCompany || undefined,
        businessRelationshipWithCompanyExplanation:
          changeFormData.businessRelationshipWithCompanyExplanation || undefined,
      },
    }

    try {
      const docIds: number[] = []

      async function processUploadedDocs(
        filesForUpload: FilesForSavingAndShowType[],
        sectionName: string,
      ) {
        if (filesForUpload && filesForUpload.length > 0) {
          for (const fileForSaving of filesForUpload) {
            const mutationParams: CreateOwnDocumentMutationVariables = {
              type: fileForSaving.docType as DocumentType,
              fileName: fileForSaving.fileName,
              size: fileForSaving.size,
              plainUrl: fileForSaving.plainUrl ?? '',
              contractId: +applicationId,
              expiration: extractDateValue(fileForSaving.expiration),
            }
            if (
              (fileForSaving.docType as DocumentType) === 'other' &&
              !!watchModal(`documentType` + sectionName + 'CustomVal')
            ) {
              mutationParams.fileDescription = watchModal(
                `documentType` + sectionName + 'CustomVal',
              )
            }
            await createOwnDocument({
              variables: mutationParams,
            })
              .then((result) => {
                result.data?.createOwnDocument?.id &&
                  docIds.push(result.data?.createOwnDocument?.id)
              })
              .catch((error) => {
                toast.error(t('uploadError', 'Upload error'))
                throw error
              })
          }
        }
      }

      for (const value of changesForTypes) {
        await processUploadedDocs(watchModal(`filesForUpload` + value), value)
        setValue(`filesForUpload` + value, [])
        setValue(`required` + value, false)
        setValue(`documentType` + value, undefined)
      }

      await contractDetailsChangeMutation({
        variables: {
          id: applicationId,
          updates,
          supportingDocumentIds: docIds,
          missingInfoId,
        },
        refetchQueries: [
          {
            query: ActionSignatureRequestsCountDocument,
            variables: {
              contractId: applicationId,
              statuses: [ActionSignatureStatus.Pending],
            },
          },
          {
            query: GetContractProfileChangesDocument,
            variables: {
              contractId: +applicationId,
              status: [ProfileChangeStatus.PendingExecutionSignature, ProfileChangeStatus.Pending],
            },
          },
        ],
      }).then((result) => {
        const signatureId = ((result.data?.contractChangeRequest as ProfileChange).executionAction
          ?.signatures as ActionSignature[]).find((signaturesItem) => signaturesItem?.isMine)?.id
        if (signNow && !!signatureId) {
          GetActionSignatureQuery({
            variables: {
              id: signatureId as string,
            },
          })
          setActionModal('sign')
        }
      })
      closeModal()

      !signNow && makeAlertMessage()
    } catch (e) {
      toast.error((e as Error).message)
    }
  }, [
    applicationId,
    closeModal,
    correspondenceNeeded,
    changeFormData,
    setValue,
    t,
    createOwnDocument,
    contractDetailsChangeMutation,
    GetActionSignatureQuery,
    signNow,
    missingInfoId,
  ])

  useEffect(() => {
    if (errorDataSaving) {
      showError(errorDataSaving)
    } else if (errorUpload) {
      showError(errorUpload)
    }
  }, [errorDataSaving, errorUpload])

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  const closeWindow = useCallback(() => {
    changesForTypes.forEach((value) => {
      setValue(`filesForUpload` + value, [])
      setValue(`required` + value, false)
      setValue(`documentType` + value, undefined)
      clearErrors('documentType' + value)
    })
    closeModal()
  }, [setValue, closeModal, clearErrors])

  const handleCloseModal = useCallback(() => {
    setActionModal(undefined)
  }, [])

  return (
    <>
      {!isEmpty(changes) && (
        <Modal
          open={isOpen}
          onClose={closeWindow}
          aria-labelledby="account-modal-title"
          aria-describedby="account-modal-description"
        >
          <Grid container item style={modalStyle} className={classes.modal}>
            <Grid item className={classes.headerModal}>
              <Typography variant={'h5'}>{t('editRequest')}</Typography>
              <Fab
                color="default"
                aria-label="close"
                className={classes.btnCloseModal}
                onClick={closeWindow}
              >
                <CloseIcon />
              </Fab>
            </Grid>

            <Grid item xs={12}>
              {renderInfoAlert}
            </Grid>

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleChange)} className={classes.form}>
                <Grid item xs={12} container className={classes.scrollable}>
                  {changesForTypes?.map((change, i) => (
                    <ChangesConfirmationSubList
                      key={i}
                      changes={changes}
                      contractProfileChangesType={change as ContractProfileChangesType}
                    />
                  ))}
                </Grid>

                {!isLimitedAccessRight && (
                  <Grid item xs={12} className={classes.controlEditConf}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="signEditRequest"
                            icon={<Unchecked />}
                            checkedIcon={<Checked />}
                          />
                        }
                        onChange={handleSignNow}
                        label={t('signEditRequestActionNow', 'Sign the edit request action now')}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12} className={classes.footerModal}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submitBtn}
                    fullWidth
                  >
                    {t('sendChangeRequest', 'Send change request')}
                  </Button>
                  <Button
                    onClick={closeWindow}
                    variant="contained"
                    className={classes.canselBtn}
                    fullWidth
                  >
                    {t('cancel', 'Cancel')}
                  </Button>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Modal>
      )}
      {!!actionModal && !!dataAction && (
        <ConfirmSignatureModal
          openAction={actionModal}
          handleClose={handleCloseModal}
          actionData={dataAction?.actionSignatureRequest as ActionSignature}
          multipleActionsData={[]}
          makeAlertMessage={makeAlertMessage}
        />
      )}
    </>
  )
}
