import { Grid, makeStyles, TableCell } from '@material-ui/core'
import { isEqual } from 'lodash'
import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { getSortStateVarName } from '../../utils'
import { SortButton } from './Buttons'
import { useColumnSorting, usePageFiltersSorting } from '../../hooks'
import { ContractManagementTablesEnum, TableSortPagesEnum } from '../../types'
import { OrderDirection } from '../../graphql'

const useStyles = makeStyles(() => ({
  sortTransfer: {
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    columnGap: 10,
    '&:hover': {
      cursor: 'pointer',
      '& svg path': {
        fill: '#EF2828',
      },
    },
  },
}))

const ColumnNameWithSortComponent: FC<{
  name: string
  handleRefetch: (sortedBy: string) => void
  contractManagementType?: ContractManagementTablesEnum
  invertLabel?: boolean
  width?: string
  children: React.ReactNode
}> = ({ name, handleRefetch, contractManagementType, invertLabel, width, children, ...props }) => {
  const classes = useStyles()
  const location = useLocation()

  const activePage = location.pathname
  const pageVariable = getSortStateVarName(activePage, contractManagementType)
  const { pageFilters } = usePageFiltersSorting(pageVariable)

  const { sortData } = useColumnSorting(name, handleRefetch, contractManagementType)

  const isSortedBy = pageFilters.sortBy === name

  const isSorted = activePage.includes(TableSortPagesEnum.transactions)
    ? pageFilters.sortDirection === OrderDirection.Descending
    : pageFilters.direction === OrderDirection.Descending

  return (
    <TableCell onClick={sortData} {...props} width={width}>
      <Grid container className={classes.sortTransfer}>
        <Grid item>{children}</Grid>
        {isSortedBy && (
          <Grid item>
            <SortButton isSorted={invertLabel ? !isSorted : isSorted} />
          </Grid>
        )}
      </Grid>
    </TableCell>
  )
}

export const ColumnNameWithSort = React.memo(ColumnNameWithSortComponent, isEqual)
