import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import config from '../../../config'
import { LimitedAccessRight, SignatoryRight } from '../../../graphql'
import { useDetermineUserRights } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { newSelectRoleInvite } from '../../../stubs'
import { capitalizeString } from '../../../utils'

export const useGetAlertText = () => {
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId)

  return (firstName: string, lastName: string, role: string, signNow: boolean) => {
    const capitalizedFirstName = capitalizeString(firstName)
    const capitalizedLastName = capitalizeString(lastName)

    switch (role) {
      case newSelectRoleInvite.signatory.key:
        if (userRights?.signatoryRight === SignatoryRight.Sole) {
          return signNow
            ? t('inviteSignatorySigned', {
                val1: config.name,
                firstName: capitalizedFirstName,
                lastName: capitalizedLastName,
              })
            : t('inviteSignatoryDidntSign', {
                val1: config.name,
                firstName: capitalizedFirstName,
                lastName: capitalizedLastName,
              })
        } else if (
          userRights?.signatoryRight === SignatoryRight.Joint ||
          userRights?.signatoryRight === SignatoryRight.Group
        ) {
          return t('inviteSignatoriesAreNeeded', {
            firstName: capitalizedFirstName,
            lastName: capitalizedLastName,
          })
        } else if (userRights?.limitedAccessRight === LimitedAccessRight.ViewDataEntry) {
          return t('inviteLimitedAlert', {
            firstName: capitalizedFirstName,
            lastName: capitalizedLastName,
          })
        }
        break
      case LimitedAccessRight.ViewDataEntry:
      case LimitedAccessRight.ViewOnly:
      case LimitedAccessRight.CardHolder:
      case LimitedAccessRight.PaymentFacilitationView:
        if (userRights?.signatoryRight === SignatoryRight.Sole) {
          return signNow
            ? t('inviteLaSignatorySigned', {
                firstName: capitalizedFirstName,
                lastName: capitalizedLastName,
              })
            : t('inviteLaSignatoryDidntSign', {
                firstName: capitalizedFirstName,
                lastName: capitalizedLastName,
              })
        } else if (
          userRights?.signatoryRight === SignatoryRight.Joint ||
          userRights?.signatoryRight === SignatoryRight.Group
        ) {
          return t('inviteLaSignatoriesAreNeeded', {
            firstName: capitalizedFirstName,
            lastName: capitalizedLastName,
          })
        } else if (userRights?.limitedAccessRight === LimitedAccessRight.ViewDataEntry) {
          return t('inviteLimitedAlert', {
            firstName: capitalizedFirstName,
            lastName: capitalizedLastName,
          })
        }
        break
      default:
        return
    }
  }
}
