import { Box, Button, TableCell, TableRow, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import IconDot from '../../../assets/images/icons/Dot.svg?react'
import { ConfirmationAnyModal } from '../../Common'
import Attention from '../../../assets/images/icons/attention.svg?react'
import { ContractUbo, UboOwner, Individual, LegalEntity, UboStakeType } from '../../../graphql'

const useStyles = makeStyles((theme) => ({
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      // borderRadius: '25px',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
}))

export const BeneficialOwnerInfoRow: FC<{
  person: ContractUbo | UboOwner
  handleModalDelete: (id: number | string) => void
  openDelete: number | string
  setOpenDelete: (val: number | string) => void
  handleClose: (val: string) => void
  onEdit: (person: ContractUbo | UboOwner, ownerLevel: number) => void
  onConfirmDelete: (
    id: string | number,
    ownerLevel: string | number,
    person: ContractUbo | UboOwner,
  ) => void
}> = ({ person, handleModalDelete, openDelete, handleClose, onEdit, onConfirmDelete }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { firstName, lastName } = person.ubo?.entity as Individual
  const { companyName } = person?.ubo?.entity as LegalEntity
  const { shareSize, id } = person
  const ownerLevel = (((person as UboOwner).level as number) + 1) * 3
  const shareInfo =
    person.ownershipType === UboStakeType.Nominee
      ? 'Nominee'
      : shareSize === 0 && !companyName
      ? 'Managing director'
      : shareSize + '%'

  return (
    <TableRow hover key={id} data-test="controllingPerson">
      <TableCell>
        <Box ml={ownerLevel}>{companyName ? `${companyName}` : `${firstName} ${lastName}`}</Box>
      </TableCell>
      <TableCell>
        {ownerLevel ? <Box style={{ color: 'grey' }}>{shareInfo}</Box> : <b>{shareInfo}</b>}
      </TableCell>
      <TableCell>
        <Box className={classes.buttonsBox}>
          <>
            <Button
              aria-label="Edit"
              type="button"
              onClick={() => onEdit(person, ownerLevel)}
              data-test="edit"
            >
              {t('edit', 'Edit')}
            </Button>
            <IconDot />
          </>
          {!ownerLevel && (
            <Button
              color="secondary"
              aria-label="delete"
              type="button"
              onClick={() => handleModalDelete(id as number | string)}
              data-test="delete"
            >
              {t('delete', 'Delete')}
            </Button>
          )}

          <ConfirmationAnyModal
            title={t('delete', 'Delete')}
            color={'error'}
            icon={<Attention />}
            name="delete"
            handleClose={() => handleClose('delete')}
            handleConfirm={() => onConfirmDelete(id as string | number, ownerLevel, person)}
            isOpen={+openDelete === +(id as number)}
            labelCancel={t('cancel', 'Cancel')}
            labelConfirm={t('delete', 'Delete')}
          >
            <Typography>
              {t(
                'deleteUboConfirmControlling',
                'Are you sure you want to delete this Controlling person',
              )}
              ?
            </Typography>
          </ConfirmationAnyModal>
        </Box>
      </TableCell>
    </TableRow>
  )
}
