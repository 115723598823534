import React, { FC, useEffect, useMemo, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Grid, Box, Link, Typography, SvgIcon } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useTranslation } from 'react-i18next'
import { filter, orderBy } from 'lodash'
import {
  Individual,
  IndividualAppointment,
  IndividualAppointmentStatus,
  useGetAppointmentsLazyQuery,
  useGetUserDataQuery,
} from '../../graphql'
import Loader from '../Common/Loader'
import { ControlVideoVerification } from '../StartPersonalOnboarding/Tips/ControlVideoVerification'
import config from '../../config'

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    lineHeight: '1.5rem',
  },
  mb: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  link: {
    color: '#000',
    cursor: 'pointer',
    textDecoration: 'none',
    borderBottom: '1px solid #000',
    transition: '0.1s ease',
    margin: theme.spacing(0, 0.5),
    '&:hover': {
      color: theme.palette.error.main,
      borderBottomColor: theme.palette.error.main,
      textDecoration: 'none',
    },
  },
  panel: {
    border: '1px solid #19B661',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
    width: '100%',
    maxWidth: 700,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  error: {
    borderColor: '#EF2828',
  },
  iconWrapp: {
    paddingRight: theme.spacing(2.25),
  },
  icon: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '& svg': {
      fill: '#19B661',
    },
  },
  iconThings: {
    width: 28,
    height: 28,
    marginRight: theme.spacing(3),
  },
  red: {
    '& svg': {
      fill: '#EF2828',
    },
  },
  wrapp: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  disabledWrapp: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  calendly: {
    position: 'relative',
    minWidth: 320,
    height: '100%',
    width: '100%',
  },
}))

type Day = {
  day?: 'numeric' | '2-digit'
}
type Month = {
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'
}
type Year = {
  year?: 'numeric' | '2-digit'
}
type Hour = {
  hour?: 'numeric' | '2-digit'
}
type Minute = {
  minute?: 'numeric' | '2-digit'
}
type Hour12 = {
  hour12?: boolean
}

type ScheduledTimeProps = {
  initCalendlyPopUp?: () => void
  isLink?: boolean
  showControll?: boolean | undefined
  initCalendly?: (...args: string[]) => void
  isInitWidget?: boolean
  handleNext?: () => void
  pendingWhileVerification?: boolean | undefined
}

export const ScheduledTime: FC<ScheduledTimeProps> = ({
  initCalendlyPopUp,
  isLink,
  showControll = false,
  initCalendly,
  isInitWidget = false,
  handleNext,
  pendingWhileVerification,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const Calendly = window.Calendly
  const [GetAppointmentsQuery] = useGetAppointmentsLazyQuery()
  const { data } = useGetUserDataQuery()
  const user = data?.viewer as Individual
  const [appointments, setAppointments] = useState<Array<IndividualAppointment> | null>([])
  const [icon, setIcon] = useState<boolean>(true)
  const [label, setLabel] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [textSecond, setTextSecond] = useState<string>('')
  const [isRefresh, setRefresh] = useState<boolean>(true)
  const [isDisabledButton, setDisabledButton] = useState<boolean>(true)

  const verificationTime = 600000 // 10 min
  const bookingTime = 1800000 // 30 min

  /* eslint-disable @typescript-eslint/no-explicit-any*/
  const isCalendlyEvent = (e: any) => {
    return e.data.event && e.data.event.indexOf('calendly') === 0
  }

  const checkScheduledTime = (
    parseScheduledDate: number,
    verificationTime: number,
    interval: NodeJS.Timeout | null,
  ) => {
    if (Date.now() >= parseScheduledDate - verificationTime && Date.now() < parseScheduledDate) {
      setLabel(t('appointmentStartsSoon', 'Appointment starts soon!'))
      setText(
        t('verificationSpecialistAt', 'You will meet with a {{val1}} verification specialist at', {
          val1: config.name,
        }),
      )
      setTextSecond('')
      setDisabledButton(false)
      setIcon(true)
      return
    } else {
      if (Date.now() < parseScheduledDate - verificationTime) {
        setLabel(t('appointmentScheduled', 'Appointment scheduled!'))
        setText(
          t(
            'verificationSpecialistAt',
            'You will meet with a {{val1}} verification specialist at',
            {
              val1: config.name,
            },
          ),
        )
        setTextSecond(
          t('open10Minutes', 'Verification portal will open 10 minutes before the appointment'),
        )
        // setDisabledButton(false)
        setDisabledButton(true)
        setIcon(true)
        return
      }
      if (Date.now() >= parseScheduledDate + bookingTime) {
        setLabel(t('appointmentMissed', 'Appointment missed!'))
        setText(t('verificationPortalAt', 'You haven’t showed up on the Verification portal at'))
        setTextSecond('')
        setDisabledButton(true)
        setIcon(false)
        return (interval && clearInterval(interval)) || false
      } else {
        setLabel(t('appointmentScheduled', 'Appointment scheduled!'))
        setText(
          t(
            'verificationSpecialistAt',
            'You will meet with a {{val1}} verification specialist at',
            {
              val1: config.name,
            },
          ),
        )
        setTextSecond(
          t('open10Minutes', 'Verification portal will open 10 minutes before the appointment'),
        )
        setDisabledButton(false)
        setIcon(true)
        return
      }
    }
  }

  const renderControl = useMemo(() => {
    return (
      <ControlVideoVerification
        initCalendly={initCalendly}
        handleNext={handleNext}
        isDisabled={isDisabledButton}
        text={textSecond ? 'open10Minutes' : undefined}
      />
    )
  }, [isDisabledButton, setDisabledButton, textSecond])

  const renderThings = useMemo(() => {
    return (
      <Grid container alignItems="center" wrap="nowrap" className={classes.mb}>
        <Grid item>
          <SvgIcon className={classes.iconThings} viewBox="0 0 24 27">
            <path
              d="M21.3333 2.99999H20V0.333328H17.3333V2.99999H6.66667V0.333328H4V2.99999H2.66667C1.2 2.99999 0 4.19999 0 5.66666V24.3333C0 25.8 1.2 27 2.66667 27H21.3333C22.8 27 24 25.8 24 24.3333V5.66666C24 4.19999 22.8 2.99999 21.3333 2.99999ZM21.3333 24.3333H2.66667V11H21.3333V24.3333ZM2.66667 8.33333V5.66666H21.3333V8.33333H2.66667ZM10.08 22.28L17.9867 14.3733L16.5733 12.96L10.08 19.4533L7.26667 16.64L5.85333 18.0533L10.08 22.28Z"
              fill="#434343"
            />
          </SvgIcon>
        </Grid>
        <Grid item>
          <Typography variant={'h4'} className={classes.subtitle}>
            {t('haveVerificationAppointment', 'Make sure you have a verification appointment')}
          </Typography>
          <Typography variant={'body1'} className={classes.text}>
            {t('makeOrCheckYourBooking', 'Make or check your booking in')}
            <Link onClick={initCalendlyPopUp} className={classes.link}>
              <b>{t('calendly', 'Calendly')}</b>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    )
  }, [isDisabledButton, setDisabledButton])

  const renderScheduledTime = useMemo(() => {
    if (appointments && appointments.length) {
      const joinDate = (
        t: Date,
        a: Array<Day | Month | Year | Hour | Minute | Hour12>,
        s: string,
      ) => {
        function format(m: Day | Month | Year | Hour | Minute | Hour12 | undefined) {
          const f = new Intl.DateTimeFormat('en-US', m)
          return f.format(t)
        }

        return a.map(format).join(s)
      }
      const date = [{ day: 'numeric' }, { month: 'long' }, { year: 'numeric' }]
      const lastScheduled = orderBy(appointments, 'startTime', 'desc')[0]

      const currentScheduledDate = new Date(lastScheduled.startTime)
      const generateDateFormat = joinDate(currentScheduledDate, date, ' ')
      const generateTimeFormat = currentScheduledDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })

      const parseScheduledDate = Date.parse(lastScheduled.startTime)
      checkScheduledTime(parseScheduledDate, verificationTime, null)
      const interval = setInterval(() => {
        checkScheduledTime(parseScheduledDate, verificationTime, interval)
      }, 3000)

      return (
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            {label && text ? (
              <Box className={`${classes.panel} ${!icon ? classes.error : ''}`}>
                <Grid container wrap="nowrap">
                  <Grid item className={classes.iconWrapp}>
                    <Box className={`${classes.icon} ${!icon ? classes.red : ''}`}>
                      {icon ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant={'h4'} className={classes.subtitle}>
                      {label}
                    </Typography>
                    <Typography variant={'body1'} className={classes.text}>
                      {text} {generateTimeFormat} {t('on', 'on')} {generateDateFormat}.
                      {isLink && (
                        <Link onClick={initCalendlyPopUp} className={classes.link}>
                          <b>{t('reschedule', 'Reschedule')}</b>
                        </Link>
                      )}
                    </Typography>
                    <Typography variant={'body1'} className={classes.text}>
                      {textSecond}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box className={classes.mb}>
                <Loader />
              </Box>
            )}
          </Grid>
        </Grid>
      )
    }
    if (!appointments || (appointments && appointments.length === 0)) {
      setDisabledButton(true)
    }
  }, [appointments, label, text, textSecond, icon])

  useEffect(() => {
    window.addEventListener('message', function (e) {
      if (isCalendlyEvent(e)) {
        if (e.data.payload?.invitee?.uri) {
          setRefresh(true)
        }
      }
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (isRefresh) {
        try {
          const { data } = await GetAppointmentsQuery()
          setAppointments(
            filter((data?.viewer as Individual)?.appointments as IndividualAppointment[], (a) =>
              [IndividualAppointmentStatus.Missed, IndividualAppointmentStatus.Scheduled].includes(
                a.status as IndividualAppointmentStatus,
              ),
            ),
          )
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e)
        }
      }
    })()
  }, [user, isRefresh])

  useEffect(() => {
    !!data &&
      isInitWidget &&
      Calendly.initInlineWidget({
        url: `${import.meta.env.REACT_APP_CALENDLY_URL}/${
          import.meta.env.REACT_APP_CALENDLY_NAME
        }/${import.meta.env.REACT_APP_CALENDLY_EVENT}`,
        parentElement: document.getElementById('calendly-date'),
        prefill: {
          name: user?.firstName,
          email: user?.email,
          customAnswers: {
            a1: '',
            a2: '',
          },
        },
        utm: {},
      })
  }, [data])
  return (
    <>
      {showControll && renderThings}
      {appointments && renderScheduledTime}
      {showControll && renderControl}
      {isInitWidget && (
        <Box className={`${classes.wrapp} ${isDisabledButton ? classes.disabledWrapp : ''}`}>
          <div id="calendly-date" className={classes.calendly} />
        </Box>
      )}
    </>
  )
}
