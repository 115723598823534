import React, { FC, useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual, sortBy, uniqBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  DateRangeFilterPicker,
  receivedPageStateVar,
  SearchField,
  uploadDocsPageStateVar,
} from '../../components'
import { FilterSelector } from '../../components/Common/Selectors/FilterSelector'
import { useDetermineUserRights, usePageFiltersSorting } from '../../hooks'
import { SearchName, StatusSelectorType } from '../../types'
import { documentTypesArray } from '../../utils'
import {
  additionalDocumentOptions,
  CreditTransactionDocumentOptions,
  DebitTransactionDocumentOptions,
  TransactionDocumentListBusiness,
} from '../../utils/Data'
import { LimitedAccessRight } from '../../graphql'
import { PATH_PARAMS } from '../../routes/paths'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  filterBox: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: theme.spacing(108),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1.5),
    },
  },
  searchField: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  searchWidth: {
    flex: 1,
    // width: '100%',
  },
  filterButton: {
    flex: 1,
    height: '48px',
    minWidth: '188px',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      paddingRight: 0,
      '&.docType': {
        margin: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&.docType': {
        width: '100%',
        paddingRight: 0,
        margin: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      flex: 'unset',
      width: '100%',
    },
  },
  picker: {
    minWidth: '250px',
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
}))

const DocumentFiltersComponent: FC<{
  uploadedTab: boolean
}> = ({ uploadedTab }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { pageFilters, setPageFilters } = usePageFiltersSorting(
    uploadedTab ? uploadDocsPageStateVar : receivedPageStateVar,
  )
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const defaultOption = { key: StatusSelectorType.AllTypes, label: t('allTypes', 'All types') }
  const { userRights } = useDetermineUserRights(applicationId)

  const isViewOnly =
    userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly ||
    userRights?.limitedAccessRight === LimitedAccessRight.ViewDataEntry

  const uploadedDocOptions = useMemo(() => {
    const options = [
      ...DebitTransactionDocumentOptions,
      ...CreditTransactionDocumentOptions,
      ...TransactionDocumentListBusiness,
      ...additionalDocumentOptions,
    ]

    const docOptions = uniqBy(options, 'key')

    return [defaultOption, ...sortBy(docOptions, 'label')]
  }, [])

  const receivedDocOptions = useMemo(() => {
    const optionsUploadedDoc = documentTypesArray.map(({ key, label }) => ({ key, label: label }))

    return [defaultOption, ...sortBy(optionsUploadedDoc, 'label')]
  }, [])

  return (
    <Box className={classes.filterBox}>
      <Box className={clsx(classes.searchField, isViewOnly && classes.searchWidth)}>
        <SearchField
          name={uploadedTab ? SearchName.SearchUploadDocuments : SearchName.SearchReceivedDocuments}
          setValue={(value) => setPageFilters({ searchValue: value, page: 1 })}
          currentValue={pageFilters.searchValue || ''}
          placeHolder={t('searchDocumentByName', 'Search document by name')}
        />
      </Box>

      {!isViewOnly && (
        <Box className={clsx(classes.filterButton, `docType`)}>
          <FilterSelector
            optionsList={uploadedTab ? uploadedDocOptions : receivedDocOptions}
            setValue={(value) => setPageFilters({ documentType: value, page: 1 })}
            currentValue={pageFilters.documentType}
          />
        </Box>
      )}

      <Box className={clsx(classes.filterButton, classes.picker)}>
        <DateRangeFilterPicker
          dateRange={pageFilters.period}
          setDateRange={(value) => setPageFilters({ period: value, page: 1 })}
        />
      </Box>
    </Box>
  )
}

export const DocumentFilters = React.memo(DocumentFiltersComponent, isEqual)
