import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Collapse, Hidden, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  IInputProps,
  ILayoutProps,
  IMeta,
  StatusValue,
} from 'react-dropzone-uploader'

import { remove, find, forEach, clone } from 'lodash'

import './styles.css'
import { MAX_FILE_SIZE_BYTES } from '../../../constants'
import {
  getFilesFromEvent,
  getUploadParams,
} from '../../../utils/HelpFunctions/uploadHelpFunctions'
import UploadIcon from './../../../assets/images/icons/upload.svg?react'
import Attention from '../../../assets/images/icons/attention.svg?react'
import { reportAppError, sizeInKB } from '../../../utils'
import { FilesForSavingAndShowType } from '../../../types'
import { FilesListUpload } from './FilesListUpload'
import { DocumentType, useGetSignedUrlMutation } from '../../../graphql'
import { useFormContext } from 'react-hook-form'
import { FormAutocompleteCustom, FormAutocompleteSelect, FormDatePickerField } from '../../Common'
import { ProofOfIdentityList } from '../../../utils/Data'
import { toast } from 'react-toastify'
import { poiNeeded } from '../../../graphql/local'
import { useReactiveVar } from '@apollo/client'

interface IMetaWithFileUrl extends IMeta {
  fileUrl: string
}

interface IFileWithWidenedMeta extends IFileWithMeta {
  file: File
  meta: IMetaWithFileUrl
  cancel: () => void
  restart: () => void
  remove: () => void
  xhr?: XMLHttpRequest
}

const useStyles = (errorFileSize: boolean, rejectedFileType: boolean) =>
  makeStyles((theme: Theme) => ({
    dzuInputLabel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      top: 0,
      bottom: 0,
      left: 50,
      right: 0,
      fontFamily: 'Helvetica, sans-serif',
      fontSize: '20px',
      fontWeight: 600,
      color: '#ccc',
      cursor: 'pointer',
    },
    DzuInput: {
      '& .dzu-dropzone': {
        height: 120,
        border: errorFileSize || rejectedFileType ? '1px solid #F0F0F0' : '1px solid #f0f0f0',
        overflow: 'hidden',
      },
    },
    dropzoneInner: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',

      '& input': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: 0,
        '&:hover': {
          backgroundColor: 'red',
          boxShadow: 'inset -1px -3px 0px -1px #000',
          cursor: 'pointer',
        },
      },
    },
    dropzoneInnerText: {
      marginTop: theme.spacing(1.5),
      fontSize: '0.75rem',
    },
    filesList: {
      maxHeight: 250,
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    iconErrorMessage: {
      padding: theme.spacing(0, 1.5, 0, 0),
    },
    modal: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    modalErrorMessage: {
      display: 'flex',
      padding: 8,
      alignItems: 'center',
      '& > svg': {
        marginRight: 15,
      },
      border: '1px solid #fed7d2',
      marginTop: 30,
    },
    wrapFileStuff: {
      '& > div': {
        marginTop: '0px',
      },
    },
  }))()

interface MetaPreview {
  meta: IMeta
  fileWithMeta: IFileWithMeta
}

export const DocumentUpload: FC<{
  filesForUploadRegName?: string
  documentTypeRegName?: string
  optionalIdentityList?: { key: string; label: string }[]
  shoudlValidateDocumentType?: boolean
  isProfile?: boolean
  isTransaction: boolean
  showTypeErr?: boolean
  setShowTypeErr?: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
}> = ({
  filesForUploadRegName,
  documentTypeRegName,
  optionalIdentityList,
  shoudlValidateDocumentType = true,
  isProfile = true,
  isTransaction,
  showTypeErr = false,
  setShowTypeErr,
  disabled,
}) => {
  const { t } = useTranslation()
  const { setValue, watch, formState, register } = useFormContext()
  const filesForUploadName = filesForUploadRegName ? filesForUploadRegName : `filesForUpload`
  const documentTypeName = documentTypeRegName ? documentTypeRegName : `documentType`
  const [errorFileSize, setErrorFileSize] = useState(false)
  const [rejectedFileType, setRejectedFileType] = useState(false)
  const classes = useStyles(errorFileSize, rejectedFileType)
  const [getSignedUrlMutation, { error }] = useGetSignedUrlMutation({})
  const filesForUpload = watch(filesForUploadName)
  const documentType = watch(documentTypeName)
  const documentName = watch('documentName')
  const isPoiNeededVar = useReactiveVar(poiNeeded)
  const idFrontExpiryDate: Date = watch(`idFrontExpiryDate`)
  const idBackExpiryDate = watch(`idBackExpiryDate`)
  const drivingLicenseFrontExpiryDate = watch(`drivingLicenseFrontExpiryDate`)
  const drivingLicenseBackExpiryDate = watch(`drivingLicenseBackExpiryDate`)
  const passportExpiryDate = watch(`passportExpiryDate`)
  const preassessmentFileTypeCheck =
    documentTypeRegName === DocumentType.PreassessmentHistoryStatementOfCurrentFormerProcessor
  const filesToUploadInfo = useRef<{
    [id: string]: { documentName: string; documentType: string }
  }>({})

  useEffect(() => {
    register(filesForUploadName)
  }, [filesForUploadRegName])

  useEffect(() => {
    if (error) {
      toast.error(
        t('errorDocumentUpload', 'Unable to upload document at the moment, please try again later'),
      )
      reportAppError(error)
    }
  }, [error, t])

  useEffect(() => {
    const tempFilesForUpload: FilesForSavingAndShowType[] = []
    forEach(filesForUpload, (itemFile) => {
      const tempDocument: FilesForSavingAndShowType = itemFile
      switch (itemFile?.docType) {
        case 'idFront':
          tempDocument.expiration = idFrontExpiryDate
          break
        case 'idBack':
          tempDocument.expiration = idBackExpiryDate
          break
        case 'drivingLicenseFront':
          tempDocument.expiration = drivingLicenseFrontExpiryDate
          break
        case 'drivingLicenseBack':
          tempDocument.expiration = drivingLicenseBackExpiryDate
          break
        case 'passport':
          tempDocument.expiration = passportExpiryDate
          break
      }
      tempFilesForUpload.push(tempDocument)
    })
    setValue(filesForUploadName, tempFilesForUpload)
  }, [
    idFrontExpiryDate,
    idBackExpiryDate,
    drivingLicenseFrontExpiryDate,
    drivingLicenseBackExpiryDate,
    passportExpiryDate,
  ])

  const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (
    file: IFileWithWidenedMeta,
    status: StatusValue,
    allFiles: IFileWithWidenedMeta[],
  ) => {
    status === 'rejected_file_type' ? setRejectedFileType(true) : setRejectedFileType(false)
    if (status === 'error_file_size') {
      file.remove()
      setErrorFileSize(true)
    } else {
      setErrorFileSize(false)
    }

    if (status === 'preparing') {
      filesToUploadInfo.current[file.meta.id] = { documentName, documentType }
    }

    if (status === 'done') {
      const tempArray: FilesForSavingAndShowType[] = clone(filesForUpload)
      forEach(allFiles, (itemFile, index) => {
        if (
          !find(
            filesForUpload as FilesForSavingAndShowType[],
            (o) => o.plainUrl === itemFile.meta.fileUrl,
          )
        ) {
          const tempDocument: FilesForSavingAndShowType = {
            id: index,
            fileName: itemFile.meta.name,
            size: itemFile.meta.size,
            docType: find(optionalIdentityList ? optionalIdentityList : ProofOfIdentityList, {
              key: filesToUploadInfo.current[itemFile.meta.id].documentType,
            })?.key as string,
            plainUrl: itemFile.meta?.fileUrl,
            metaType: itemFile.meta?.type,
            documentName: filesToUploadInfo.current[itemFile.meta.id].documentName,
          }
          tempArray.push(tempDocument)
        }
      })
      setValue(filesForUploadName, tempArray)
    } else if (status === 'removed') {
    } else if (status === 'error_upload') {
      toast.error(t('uploadError', 'Upload error'))
    }
  }

  const Preview = ({ meta, fileWithMeta }: MetaPreview) => {
    const tempFilesList = clone(filesForUpload)
    const currentFile = tempFilesList.find(
      (file: FilesForSavingAndShowType) =>
        file.plainUrl === (meta as IMeta & { fileUrl: string }).fileUrl,
    )
    const { name, percent, status, size, type } = meta
    const docType = find(
      filesForUpload as FilesForSavingAndShowType[],
      (o) => o.plainUrl === (meta as IMetaWithFileUrl).fileUrl,
    )?.docType

    function removeFileHandler() {
      const tempArray: FilesForSavingAndShowType[] = []
      forEach(filesForUpload, (fileItem) => tempArray.push(fileItem))
      remove(tempArray as FilesForSavingAndShowType[], function (removedItem) {
        return removedItem.fileName === meta.name && removedItem.size === meta.size
      })
      setValue(filesForUploadName, tempArray)
      fileWithMeta.remove()
      setErrorFileSize(false)
      setRejectedFileType(false)
    }

    useEffect(() => {
      if (currentFile && preassessmentFileTypeCheck) {
        currentFile.docType = DocumentType.PreassessmentHistoryStatementOfCurrentFormerProcessor
      }
    }, [currentFile])

    return (
      <Box>
        <FilesListUpload
          name={name}
          size={sizeInKB(size)}
          percent={percent}
          status={status}
          removeFileHandler={() => removeFileHandler()}
          type={type}
          docType={docType ?? ''}
          optionalIdentityList={optionalIdentityList}
        />
        {!isProfile && (
          <Box className={classes.wrapFileStuff}>
            <FormDatePickerField
              name={docType + 'ExpiryDate'}
              label={t('expiryDate', 'Expiry date')}
            />
          </Box>
        )}
        {isTransaction && !preassessmentFileTypeCheck && !currentFile?.docType && (
          <FormAutocompleteCustom
            data={optionalIdentityList ? optionalIdentityList : ProofOfIdentityList}
            docTypeError={showTypeErr}
            docType={currentFile?.docType}
            label={t('type', 'Type')}
            chooseDocTypeHandler={(option) => {
              if (currentFile) currentFile.docType = option.key
              if (setShowTypeErr) {
                setShowTypeErr(false)
              }
              setValue(filesForUploadName, tempFilesList, {
                shouldDirty: true,
              })
            }}
            disabled={status !== 'done'}
          />
        )}
      </Box>
    )
  }

  const Layout = ({ input, previews, dropzoneProps }: ILayoutProps) => {
    return (
      <Box className={classes.DzuInput} flexGrow={1} data-test="uploadFile">
        <Box className={classes.filesList}>{previews}</Box>
        <FormAutocompleteSelect
          name={documentTypeName}
          label={
            previews?.length
              ? t('typeAdditionalDocument', 'Document type of an additional document')
              : t('documentTypeSmall', 'Document type')
          }
          data={optionalIdentityList ? optionalIdentityList : ProofOfIdentityList}
          shouldValidateParam={shoudlValidateDocumentType}
        />
        {documentType && <div {...dropzoneProps}>{input}</div>}
        {errorFileSize && (
          <Box className={classes.modalErrorMessage} data-test="fileExceedsMaximumSize">
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>
              {t('fileExceedsMaximumSize', 'File exceeds maximum size of 15 MB')}
            </Typography>
          </Box>
        )}
        {rejectedFileType && (
          <Box className={classes.modalErrorMessage} data-test="wrongDocumentFormat">
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>
              {t('wrongDocumentFormat', 'Wrong document format. PDF, JPG and PNG are supported')}
            </Typography>
          </Box>
        )}
        {isPoiNeededVar && formState.isDirty && (
          <Box className={classes.modalErrorMessage}>
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>{t('poiRequired', 'Proof of identity is required')}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  const LayoutForTransactions = ({ input, previews, dropzoneProps }: ILayoutProps) => {
    return (
      <Box className={classes.DzuInput} flexGrow={1} data-test="uploadFile">
        <div {...dropzoneProps}>{input}</div>
        {errorFileSize && (
          <Box className={classes.modalErrorMessage} data-test="fileExceedsMaximumSize">
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>
              {t('fileExceedsMaximumSize', 'File exceeds maximum size of 15 MB')}
            </Typography>
          </Box>
        )}
        {rejectedFileType && (
          <Box className={classes.modalErrorMessage} data-test="wrongDocumentFormat">
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>
              {t('wrongDocumentFormat', 'Wrong document format. PDF, JPG and PNG are supported')}
            </Typography>
          </Box>
        )}
        {isPoiNeededVar && formState.isDirty && (
          <Box className={classes.modalErrorMessage}>
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>{t('poiRequired', 'Proof of identity is required')}</Typography>
          </Box>
        )}
        <Collapse in={!!previews?.length}>
          <Box className={classes.filesList} id="uploadedFilesContainerId">
            {previews}
          </Box>
        </Collapse>
      </Box>
    )
  }

  const Input = ({ accept, onFiles, getFilesFromEvent }: IInputProps) => {
    return (
      <Box className={classes.dropzoneInner}>
        <input
          type="file"
          accept={accept}
          multiple={false}
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles)
            })
          }}
        />
        <UploadIcon />
        <Hidden smDown>
          <Box className={classes.dropzoneInnerText}>
            <strong>
              {t('drag', 'Drag')}&apos;{t('nDrop', 'n drop')}
            </strong>
            {t('orWithSpaces', ' or ')}
            <strong>{t('click', 'click')}</strong>
            {t('toUploadFiles', ' to upload files')}
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.dropzoneInnerText}>
            <strong>{t('tap', 'Tap')}</strong>
            {t('toUploadFiles', ' to upload files')}
          </Box>
        </Hidden>
      </Box>
    )
  }

  return (
    <Box className={classes.modal}>
      <Box
        onClick={(e) => {
          if (disabled || (preassessmentFileTypeCheck && filesForUpload?.length > 0)) {
            e.preventDefault()
          }
        }}
      >
        <Dropzone
          getUploadParams={getUploadParams(getSignedUrlMutation)}
          getFilesFromEvent={getFilesFromEvent}
          maxFiles={10}
          maxSizeBytes={MAX_FILE_SIZE_BYTES}
          classNames={{ inputLabel: classes.dzuInputLabel }}
          onChangeStatus={handleChangeStatus}
          PreviewComponent={Preview}
          LayoutComponent={isTransaction ? LayoutForTransactions : Layout}
          disabled={(files) =>
            disabled ||
            files.some((f) =>
              ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status),
            ) ||
            (preassessmentFileTypeCheck && filesForUpload && filesForUpload.length > 0)
          }
          accept=".jpg,.jpeg,.png,.pdf"
          InputComponent={Input}
          autoUpload={true}
          multiple={false}
        />
      </Box>
    </Box>
  )
}
