import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StandingOrder } from '../../graphql'
import { StandingOrderStatus } from './StandingOrderStatus'
import { getAmmount, getNextDate } from './utils'
import { OperationNames } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
  mobileTableRow: {
    fontFamily: 'Arial',
    fontSize: 14,
    marginBottom: theme.spacing(1),
    '&:not(:first-child)': {
      borderTop: '1px solid rgba(0,0,0,.1)',
    },
  },
  mobileDoubleItem: {
    display: 'flex',
    flexDirection: 'row',
    '&>div:first-child': {
      paddingRight: theme.spacing(1),
    },
  },
  mobileTableItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 57,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
  BeneficiaryName: {
    textDecoration: 'underline',
    textOverflow: 'ellipsis',
  },
}))

export const StandingOrdersMobile: FC<{
  handleDetails: (id: string | number) => void
  orderData: StandingOrder
  accountCurrency: string
  handleSign: (data: StandingOrder, action: OperationNames) => void
}> = ({ handleDetails, orderData, handleSign, accountCurrency }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.mobileTableRow} onClick={() => handleDetails(orderData.id)}>
      <Grid container className={classes.mobileTableItem}>
        <Grid item xs={12}>
          <Box className={classes.mobileTableLabel}>{t('beneficiary', 'Beneficiary')}</Box>
          <Box className={clsx(classes.BeneficiaryName)}>
            {orderData.template?.beneficiary.name}
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={4}>
            <Box className={classes.mobileTableLabel}>{t('nextPayment', 'Next payment')}</Box>
            <Box>{orderData.deletedAt ? null : getNextDate(orderData)}</Box>
          </Grid>
          <Grid item xs={8}>
            <Box className={classes.mobileTableLabel}>{t('frequency', 'Frequency')}</Box>
            <Box>{orderData.cronDescription}</Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={4} sm={6}>
            <Box className={classes.mobileTableLabel}>{t('amount', 'Amount')}</Box>
            <Box>{getAmmount(orderData, accountCurrency)}</Box>
          </Grid>
          <Grid item xs={8} sm={6}>
            <Box className={classes.mobileTableLabel}>{t('status', 'Status')}</Box>
            <StandingOrderStatus orderData={orderData} handleSign={handleSign} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
