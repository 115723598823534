import React from 'react'
import clsx from 'clsx'
import { Drawer, Toolbar, Box, CssBaseline, AppBar, Hidden } from '@material-ui/core'

import { Footer } from '../Footer'
import { useMenuStyles } from './menuStyles'
import config from '../../config'

export const PersonOnboardMenu: React.FC = () => {
  const classes = useMenuStyles()

  const drawer = (
    <div className={classes.drawerInner}>
      <Box>
        <Toolbar className={classes.logoMenu}>
          <img src={config.logo} alt={'company_logo'} />
        </Toolbar>
      </Box>
      <Box className={classes.footerWrap}>
        <Footer isMenu={true} />
      </Box>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBarShift, classes.appBar)}>
        <Hidden mdUp>
          <Toolbar>
            <Box display="flex" flexGrow={1} justifyContent="center">
              <Box className={classes.logo}>
                <img src={config.logo} alt={'company_logo'} />
              </Box>
            </Box>
          </Toolbar>
        </Hidden>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}
