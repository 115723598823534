import React, { FC, useCallback } from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ColumnNameWithSort, contractsTeamPageStateVar } from '../../../components'
import { ContractAuthPersonOrderBy, OrderDirection } from '../../../graphql'
import { usePageFiltersSorting } from '../../../hooks'
import { ContractManagementTablesEnum } from '../../../types'

const TeamTableHeaderComponent: FC = () => {
  const { t } = useTranslation()

  const { pageFilters, setPageFilters } = usePageFiltersSorting(contractsTeamPageStateVar)

  const handleRefetch = useCallback(
    (sortedBy: string) => {
      if (pageFilters.sortBy !== sortedBy) {
        setPageFilters({
          sortBy: sortedBy as ContractAuthPersonOrderBy,
          direction: OrderDirection.Ascending,
          page: 1,
        })
      } else {
        const direction =
          pageFilters.direction === OrderDirection.Descending
            ? OrderDirection.Ascending
            : OrderDirection.Descending
        setPageFilters({ direction, page: 1 })
      }
    },
    [setPageFilters, pageFilters.direction, pageFilters.sortBy],
  )

  return (
    <TableHead>
      <TableRow>
        <ColumnNameWithSort
          name={ContractAuthPersonOrderBy.FirstName}
          data-test="name"
          handleRefetch={handleRefetch}
          contractManagementType={ContractManagementTablesEnum.Team}
        >
          {t('name', 'Name')}
        </ColumnNameWithSort>

        <ColumnNameWithSort
          name={ContractAuthPersonOrderBy.SignatoryRight}
          data-test="role"
          handleRefetch={handleRefetch}
          contractManagementType={ContractManagementTablesEnum.Team}
        >
          {t('role', 'Role')}
        </ColumnNameWithSort>

        <ColumnNameWithSort
          name={ContractAuthPersonOrderBy.InvitedAt}
          data-test="invited"
          handleRefetch={handleRefetch}
          contractManagementType={ContractManagementTablesEnum.Team}
        >
          {t('invited', 'Invited')}
        </ColumnNameWithSort>

        <ColumnNameWithSort
          data-test="lastSeen"
          name={ContractAuthPersonOrderBy.LastSeen}
          handleRefetch={handleRefetch}
          contractManagementType={ContractManagementTablesEnum.Team}
        >
          {t('lastSeen', 'Last seen')}
        </ColumnNameWithSort>

        <ColumnNameWithSort
          data-test="status"
          name={ContractAuthPersonOrderBy.Status}
          handleRefetch={handleRefetch}
          contractManagementType={ContractManagementTablesEnum.Team}
        >
          {t('status', 'Status')}
        </ColumnNameWithSort>

        <TableCell />
      </TableRow>
    </TableHead>
  )
}

export const TeamTableHeader = React.memo(TeamTableHeaderComponent, isEqual)
