import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { Box, ListItem, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import isEqual from 'lodash/isEqual'

import { ContractAlertDot } from './ContractAlertDot'
import { useCompanySelectUnionStyles } from './CompanySelect'
import { displayStatusKeys } from '../../../constants'
import { CurrentUserContext, useLimitedPerson, useTotalUnreadDocCount } from '../../../hooks'
import {
  ActionSignatureStatus,
  ContractStatusType,
  Individual,
  LegalEntity,
  UboType,
  useActionSignatureRequestsCountQuery,
} from '../../../graphql'
import { ContractListItemProps } from './types'
import { isActiveContract } from '../../../utils'
import { dashboardClosedStatuses } from '../../../utils/Data'

const ContractsListItemComponent: FC<ContractListItemProps> = ({
  contract,
  isSelected,
  handleChangeContract,
}) => {
  const { id, owner, status, ownerType } = contract
  const { t } = useTranslation()
  const user = useContext(CurrentUserContext)
  const classes = useCompanySelectUnionStyles()

  const { docsCount, refetchDocsCount } = useTotalUnreadDocCount(id)
  const { data: signaturesCount } = useActionSignatureRequestsCountQuery({
    variables: { contractId: id, statuses: [ActionSignatureStatus.Pending] },
  })

  const name =
    ownerType === UboType.NaturalPerson ? t('personal') : (owner as LegalEntity).companyName

  const activeContract = isActiveContract(contract, user as Individual)
  const { isLimitedPerson } = useLimitedPerson(contract)

  const isHiddenDot = useMemo(() => {
    if (!user?.isVerified || isLimitedPerson || !activeContract) {
      return true
    }

    return !isSelected ? !(docsCount || signaturesCount?.actionSignatureRequestsCount) : !docsCount
  }, [isLimitedPerson, activeContract, isSelected, docsCount, signaturesCount, user])

  const handleContractChange = useCallback(() => {
    handleChangeContract(contract).then()
  }, [handleChangeContract, contract])

  useEffect(() => {
    refetchDocsCount().then()
  }, [])

  return (
    <ListItem
      button
      onClick={handleContractChange}
      disabled={dashboardClosedStatuses.includes(contract.status as ContractStatusType)}
      className={clsx(classes.item, isSelected && classes.activeContractItem)}
    >
      <Typography className={clsx(classes.companyName, !activeContract && classes.noPadding)}>
        <ContractAlertDot size={8} top={3} hidden={isHiddenDot} />
        {name}
      </Typography>

      {!activeContract && status && (
        <Box component="span" className={clsx(`roundedPill ${displayStatusKeys[status]}`)}>
          {t(displayStatusKeys[status])}
        </Box>
      )}
    </ListItem>
  )
}

export const ContractsListItem = React.memo(ContractsListItemComponent, isEqual)
