import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography, makeStyles, Modal, Fab, Box, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { CardOperationTypes } from '../../types'
import { focusKeyPressNext } from '../../utils'
import { FormPasswordField } from './Fields'
import { CardActions2FAModal } from './CardsActions2FAModal'
import { CardPasswordInputSchema, RepeatPasswordInputSchema } from '../../schemes/common'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  // modal
  modal: {
    maxWidth: 512,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
    marginBottom: 24,
  },
  bodyModal: {
    padding: theme.spacing(0, 3),
    margin: theme.spacing(0),
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  container: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      '& .MuiFormControl-root:first-child': {
        marginBottom: 50,
      },
    },
  },
  btnCancel: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(3),
    gap: 10,
    width: 92,
    height: 48,
  },
  btnControl: {
    padding: theme.spacing(1, 2),
    gap: 10,
    width: 83,
    height: 48,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'right',
    margin: theme.spacing(3, 0, 3, 3),
  },
}))

type ChangeCardPasswordModalProps = {
  cardId: string | number
  isOpenModal: boolean
  closeModal: () => void
}

export const ChangeCardPassword: FC<ChangeCardPasswordModalProps> = ({
  cardId,
  isOpenModal,
  closeModal,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(CardPasswordInputSchema.concat(RepeatPasswordInputSchema)),
  })
  const { handleSubmit, formState, watch, trigger } = methods
  const password = watch('password')

  const handleActivateCard = useCallback(async () => {
    setOpen(true)
  }, [])

  useMemo(() => {
    if (passwordChanged) {
      closeModal()
      setPasswordChanged(false)
    }
  }, [passwordChanged])

  const handlePassword = useCallback(() => {
    if (password && methods.formState.touched.password) {
      trigger(['password', 'repeat_password']).then()
    }
  }, [password, trigger])

  return (
    <>
      <Modal
        style={{ zIndex: 1401 }}
        open={isOpenModal}
        onClose={closeModal}
        aria-labelledby="account-modal-title"
        aria-describedby="account-modal-description"
      >
        <Grid container item style={modalStyle} className={classes.modal}>
          <Grid item className={classes.headerModal}>
            <Typography variant={'h5'}>{t('changePassword', 'Change password')}</Typography>
            <Fab
              color="default"
              aria-label="close"
              className={classes.btnCloseModal}
              onClick={closeModal}
            >
              <CloseIcon />
            </Fab>
          </Grid>

          <Grid item xs={12} className={classes.bodyModal}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(handleActivateCard)}
                id="forNextFocus"
                onKeyDown={focusKeyPressNext}
              >
                <Grid item className={classes.container}>
                  <FormPasswordField
                    label={t('newPassword', 'New password')}
                    name="password"
                    fullWidth
                    helpText={t(
                      'cardPasswordHelpText',
                      'Password must be at least 8 characters and only the special characters "! # ; : ?  * ( ) + = / & , . [ ] { }"  are allowed',
                    )}
                    onChange={handlePassword}
                  />
                  <FormPasswordField
                    label={t('repeatNewPassword', 'Repeat new password')}
                    name="repeat_password"
                    fullWidth
                    onChange={() => {
                      trigger(`repeat_password`).then()
                    }}
                  />
                </Grid>
                <Box className={classes.modalFooter}>
                  <Button
                    variant="contained"
                    onClick={closeModal}
                    disableElevation
                    className={classes.btnCancel}
                  >
                    {t('cancel', 'Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!formState?.isValid || !formState?.touched.password}
                    disableElevation
                    className={classes.btnControl}
                  >
                    {t('apply', 'Apply')}
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Modal>
      <CardActions2FAModal
        open={open}
        handleClose={setOpen}
        cardId={cardId}
        cardName={'cardName'}
        type={CardOperationTypes.passwordChange}
        password={password}
        setCardActivated={setPasswordChanged}
      />
    </>
  )
}
