import React, { FC } from 'react'
import { Box, Container, Grid, Theme, makeStyles, SvgIcon, Typography } from '@material-ui/core'
import IconInfo from '../../../assets/images/icons/icon-exclamation-mark.svg?react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '23px 0',
  },
  infoIcon: {
    color: theme.palette.warning.main,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: '5px 0px 5px 0px',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
  },
  tip: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontSize: '14px',
    marginTop: '2px',
  },
}))

export const BusinessTipItem: FC<{ value: string }> = ({ value }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container component="div" maxWidth={false} disableGutters={true}>
        <Grid container className={classes.container}>
          <Grid item className={classes.iconContainer}>
            <SvgIcon className={classes.infoIcon}>
              <IconInfo />
            </SvgIcon>
          </Grid>
          <Grid item>
            <Box className={classes.tip} ml={1}>
              <Typography variant={'h2'}>{value}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
