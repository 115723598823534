import React, { useCallback, FC } from 'react'
import { Box, FormControlLabel, makeStyles, RadioGroup, Theme, Typography } from '@material-ui/core'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'

import { BlackRadio } from '../../Common'
import { ControllingPersonsTypeEnum } from '../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
  },
  description: {
    paddingLeft: theme.spacing(2),
  },
  radioGroup: {
    paddingLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(4.5),
    '&:last-child': {
      marginBottom: theme.spacing(1.5),
    },
  },
}))

export const Domiciliary: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.controllingPerson]: controllingPerson,
  } = useParams() as Record<string, string>

  const handleRadioChange = useCallback(
    (event): void => {
      const name = (event?.target as HTMLInputElement).value
      history.replace(
        generatePath(APP_PATHS.application.beneficialOwners.add, {
          applicationId,
          [PATH_PARAMS.controllingPerson]: name,
        }),
      )
    },
    [history],
  )

  return (
    <RadioGroup
      aria-label="gender"
      name="owner"
      value={controllingPerson ?? 'domiciliaryPersonOwner'}
      onChange={handleRadioChange}
    >
      <Box className={classes.radioGroup}>
        <FormControlLabel
          value={ControllingPersonsTypeEnum.personOwner}
          control={<BlackRadio size="small" />}
          label={
            <Box>
              <Typography variant={'h4'} className={classes.title}>
                {t('domiciliaryPerson', 'Person who owns shares')}
              </Typography>
              <Typography variant={'body1'} className={classes.description}>
                {t(
                  'requirePersonalDescription',
                  'We’ll require personal details and the percentage of ownership (capital shares or voting rights)',
                )}
              </Typography>
            </Box>
          }
        />
      </Box>
      <Box className={classes.radioGroup}>
        <FormControlLabel
          value={ControllingPersonsTypeEnum.legalOwner}
          control={<BlackRadio size="small" />}
          label={
            <Box>
              <Typography variant={'h4'} className={classes.title}>
                {t('domiciliaryLegal', 'Legal entity which owns shares')}
              </Typography>
              <Typography variant={'body1'} className={classes.description}>
                {t(
                  'requireEntityDescription',
                  'We’ll require the entity details and the percentage of ownership',
                )}
              </Typography>
            </Box>
          }
        />
      </Box>
      <Box className={classes.radioGroup}>
        <FormControlLabel
          value={ControllingPersonsTypeEnum.attorneyPower}
          control={<BlackRadio size="small" />}
          label={
            <Box>
              <Typography variant={'h4'} className={classes.title}>
                {t(
                  'personAttorneyText',
                  'Person who holds shares through trust or power of attorney',
                )}
              </Typography>
              <Typography variant={'body1'} className={classes.description}>
                {t(
                  'requirePercentageDescription',
                  'We’ll require details and the percentage of ownership',
                )}
              </Typography>
            </Box>
          }
        />
      </Box>
    </RadioGroup>
  )
}
