import React, { FC, useMemo } from 'react'
import { intersection } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ProgressStep } from '../../Onboarding/Progress'
import { getPercentage } from '../helpers/functions'

export const EconomicProfilePoint: FC<{
  filledFields: string[]
  ErrorList: string[]
}> = ({ filledFields, ErrorList }) => {
  const { t } = useTranslation()

  const requiredEconomicProfileFields = [
    'companyAnnualIncome',
    'companyAnnualIncomeCurrency',
    'expectedVolumes',
    'estimatedIncomingTransactions',
    'estimatedIncomingTransactionsAmount',
    'partnersIncoming',
    'estimatedOutgoingTransactions',
    'estimatedOutgoingTransactionsAmount',
    'partnersOutgoing',
  ]

  const hasErrors = useMemo(() => !!intersection(requiredEconomicProfileFields, ErrorList).length, [
    requiredEconomicProfileFields,
    ErrorList,
  ])

  const isEconomicProfileCompleted = useMemo(
    () =>
      requiredEconomicProfileFields.every((field) => filledFields.includes(field)) && !hasErrors,
    [requiredEconomicProfileFields, hasErrors],
  )

  return (
    <ProgressStep
      stage={
        isEconomicProfileCompleted
          ? 'done'
          : getPercentage(requiredEconomicProfileFields, filledFields)
      }
      error={hasErrors}
      label={t('economicProfile', 'Economic profile')}
    />
  )
}
