import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../../hooks'
import { CountryPhoneSelect, FormControlledTextField, Toggler } from '../../Common'
import { useCommonStyles } from './helpers/functions'

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1.5, 1.5),
    },
  },
}))

const ContactPersonComponent: FC = () => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const currentUser = useCurrentUser()

  const [currentUserIsContactPerson, setCurrentUserIsContactPerson] = useState<boolean>(false)

  const methods = useFormContext()
  const { setValue, clearErrors, watch } = methods
  const email = watch('email')

  useEffect(() => {
    const { fullName, email, phone } = currentUser || {}
    if (currentUserIsContactPerson) {
      setValue('fullName', fullName)
      setValue('email', email)
      setValue('contactPersonPhoneNumber', phone)
    } else {
      setValue('fullName', '')
      setValue('email', '')
      setValue('contactPersonPhoneNumber', '')
    }
  }, [currentUserIsContactPerson, setValue, currentUser])

  const handleAddMyself = useCallback(
    (_, checked) => {
      clearErrors(['fullName', 'email', 'contactPersonPhoneNumber'])
      setCurrentUserIsContactPerson(checked)
    },
    [setCurrentUserIsContactPerson],
  )

  useEffect(() => {
    if (email && currentUser?.email === email) {
      setCurrentUserIsContactPerson(true)
    }
  }, [email])

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h4" className={classes.title}>
          {t('contactPersonInformation', 'Contact person information')}
        </Typography>
      </Box>

      <Toggler
        name="iAmAddingMyself"
        checked={currentUserIsContactPerson}
        label={t('iAmAddingMyself', 'I am adding myself')}
        onChange={handleAddMyself}
      />

      <FormControlledTextField
        label={t('contactPersonFullName', 'Contact person full name')}
        name="fullName"
        type="text"
        fullWidth
        required={false}
        disabled={currentUserIsContactPerson}
        multiline
        data-test="fullName"
        className={classes.whiteBackground}
      />

      <FormControlledTextField
        label={t('contactPersonEmail', 'Contact person email')}
        name="email"
        type="text"
        fullWidth
        required={false}
        disabled={currentUserIsContactPerson}
        data-test="email"
        className={classes.whiteBackground}
      />

      <CountryPhoneSelect
        name="contactPersonPhoneNumber"
        label={t('contactPersonPhone', 'Contact person phone')}
        description={t(
          'pleaseInsertPhoneNumberWhenWeCanReachPerson',
          'Please insert the phone number when we can reach the person responsible for the application',
        )}
        data-test="contactPersonPhoneNumber"
      />
    </>
  )
}

export const ContactPerson = React.memo(ContactPersonComponent)
