import React, { FC } from 'react'

import PdfIcon from '../../assets/images/icons/pdf.svg?react'
import PngIcon from '../../assets/images/icons/png.svg?react'
import JpgIcon from '../../assets/images/icons/jpg.svg?react'
import CssIcon from '../../assets/images/icons/css.svg?react'
import TxtIcon from '../../assets/images/icons/txt.svg?react'
import DocIcon from '../../assets/images/icons/doc.svg?react'
import XlsIcon from '../../assets/images/icons/xls.svg?react'

export const FileIcon: FC<{ mimeType: string | undefined }> = ({ mimeType }) => {
  switch (mimeType) {
    case 'application/pdf':
    case 'pdf':
      return <PdfIcon width={'100%'} height={'100%'} />
    case 'image/png':
    case 'png':
      return <PngIcon width={'100%'} height={'100%'} />
    case 'image/jpeg':
    case 'jpeg':
    case 'jpg':
      return <JpgIcon width={'100%'} height={'100%'} />
    case 'css':
      return <CssIcon width={'100%'} height={'100%'} />
    case 'txt':
      return <TxtIcon width={'100%'} height={'100%'} />
    case 'doc':
    case 'docx':
      return <DocIcon width={'100%'} height={'100%'} />
    case 'xls':
    case 'xlsx':
      return <XlsIcon width={'100%'} height={'100%'} />
    default:
      return null
  }
}
