import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, makeStyles, MenuItem, Tooltip } from '@material-ui/core'
import { ThreeDotMenu } from '../../../components'
import { ContractAuthorizedPersonType } from '../../../graphql'
import { authorizedPersonStatus } from '../../../constants'
import { dateFormat, rightsTeamRole } from '../../../utils'
import { TeamTableRowProps } from '../../../types'
import { isEqual } from 'lodash'

const useStyles = makeStyles(() => ({
  tableRow: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    margin: '0 0 8px 0',
  },
  name: {
    textDecoration: 'underline',
    fontSize: '14px',
    color: '#000000',
  },
  tableDoubleItem: {
    padding: '10px 0 10px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  mobileTableLabel: {
    fontSize: '12px',
    color: '#595959',
  },
  role: {
    fontSize: '14px',
    color: '#000000',
  },
  date: {
    fontSize: '14px',
    color: '#000000',
  },
  tableFlexItem: {
    // display: 'flex',
    alignItems: 'center',
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btn: {
    height: 36,
    width: 36,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledMenu: {
    opacity: 0.4,
  },
}))

const TeamTableListRowMobileComponent: FC<TeamTableRowProps> = ({
  isShouldDisabled,
  teammate,
  teamMenuOptions,
  isMyself,
  isViewOnly,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    id,
    status,
    isAuthorizedSignatory,
    limitedAccessRight,
    signatoryRight,
    groupSize,
    person,
    invitationDate,
  } = teammate as ContractAuthorizedPersonType
  const menuOptions = [t('deactivate', 'Deactivate')]

  const handleMenu = useCallback(() => {
    if (!status || !(status in teamMenuOptions)) return
    const option = teamMenuOptions[status]

    if (option && id) {
      option.handler(id)
    }
  }, [status, teamMenuOptions, id])

  return (
    <Box className={classes.tableRow}>
      <Box>
        <Grid container className={classes.tableDoubleItem}>
          <Grid item xs={isViewOnly ? 12 : 9} className={classes.tableFlexItem}>
            <Grid>
              <Box className={classes.mobileTableLabel}>{t('name', 'Name')}</Box>
              <Box className={classes.name}>
                {person?.firstName}
                {person?.lastName && ` ${person?.lastName}`}
              </Box>
            </Grid>
          </Grid>
          {!isViewOnly && (
            <Grid item xs={3}>
              {!isViewOnly && (
                <ThreeDotMenu disabled={isShouldDisabled}>
                  {menuOptions.map((option, index) =>
                    isMyself && option === 'Deactivate' ? (
                      <Tooltip
                        key={index}
                        title={t('cantDeactYourslf', 'You cannot deactivate yourself')}
                      >
                        <Box>
                          <MenuItem key={index} onClick={handleMenu} disabled>
                            {t(option)}
                          </MenuItem>
                        </Box>
                      </Tooltip>
                    ) : (
                      <MenuItem key={index} onClick={handleMenu}>
                        {t(option)}
                      </MenuItem>
                    ),
                  )}
                </ThreeDotMenu>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.tableDoubleItem}>
          <Grid item xs={6} className={classes.tableFlexItem}>
            <Box className={classes.mobileTableLabel}>{t('role', 'Role')}</Box>
            <Box className={classes.role}>
              {isAuthorizedSignatory
                ? rightsTeamRole(signatoryRight as string, groupSize ?? 0)
                : rightsTeamRole(limitedAccessRight as string)}
            </Box>
          </Grid>
          <Grid item xs={6} className={classes.tableFlexItem}>
            <Box className={classes.mobileTableLabel}>{t('invited', 'Invited')}</Box>
            <Box className={classes.date}>
              {invitationDate ? dateFormat(invitationDate) : t('never', 'Never')}
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.tableDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.mobileTableLabel}>{t('lastSeen', 'Last seen')}</Box>
            <Box className={classes.date}>
              {person?.lastSeenAt ? dateFormat(person?.lastSeenAt) : t('never', 'Never')}
            </Box>
          </Grid>
          <Grid item xs={6} className={classes.center}>
            <Box
              component="span"
              className={`roundedPill ${authorizedPersonStatus[status as string]}`}
            >
              {t(authorizedPersonStatus[status as string])}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export const TeamTableListRowMobile = React.memo(TeamTableListRowMobileComponent, isEqual)
