import { isUndefined } from 'lodash'
import React, { FC, useEffect, useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { Authorised, Loader } from '../../../components'

import {
  Individual,
  useContractIndividualOwnersQuery,
  useGetContractDetailsQuery,
  useGetUserDataQuery,
} from '../../../graphql'

import { NaturalPersonForm } from '../../../components/NewPerson/Forms/NaturalPersonForm'
import { PATH_PARAMS } from '../../../routes/paths'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: '0 15px 0 5px',
    '& .MuiCheckbox-root': {
      padding: theme.spacing(0.5, 1, 0.5, 1.5),
    },
  },
  group: {
    margin: theme.spacing(0, 0, 5, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 4, 0),
    },
  },
}))

export const InviteSignatoryForm: FC<{ isPersonExist: boolean | undefined }> = ({
  isPersonExist,
}) => {
  const classes = useStyles()
  const [user, setUser] = useState<Individual | null>()
  const [formPerson] = useState<Individual | null>()
  const [formMode] = useState('new')

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: userData } = useGetUserDataQuery()
  const { data: contractData } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })
  const { data: ownersData } = useContractIndividualOwnersQuery({
    variables: {
      id: +applicationId,
    },
  })

  useEffect(() => {
    setUser(userData?.viewer as Individual)
  }, [userData])

  if (!userData || !contractData || !ownersData) return <Loader />
  return (
    <Box className={classes.form}>
      <Box>
        <Box className={classes.group}>
          <Authorised />
          {/* {access === 'limited' && <Limited />} */}

          {!isUndefined(isPersonExist) && !isPersonExist && (
            <NaturalPersonForm
              personData={formMode === 'myself' ? user : formPerson}
              key={`${formMode}:${formMode === 'myself' ? user?.id : formPerson?.id}`}
              shouldEmailBeHidden
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
