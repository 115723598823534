import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Chip, ClickAwayListener } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import LIST_CLOSED_ICON from '../../../assets/images/icons/list_closed.svg?react'
import LIST_CLOSED_ICON_ERROR from '../../../assets/images/icons/list_closed_error.svg?react'
import LIST_OPENED_ICON from '../../../assets/images/icons/list_opened.svg?react'
import { shortFormatDate } from '../../../utils'
import FormHelperText from '@material-ui/core/FormHelperText'
import { isPast, isValid, setHours } from 'date-fns'

const useStyles = (
  docTypeError: boolean,
  openSearch: boolean,
  showErr: boolean,
  error: string | undefined,
) =>
  makeStyles((theme: Theme) => ({
    label: {
      display: 'flex',
      justifyContent: 'flex-start',
      color: '#000000',
      backgroundColor: docTypeError ? '#FFEFED' : '#f0f0f0',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
      marginRight: '10px',
      fontSize: '12px',
      width: '100%',
      '& :before': {
        position: 'absolute',
        display: 'block',
        border: 0,
        content: '""',
        backgroundImage: openSearch
          ? `url(${LIST_OPENED_ICON})`
          : docTypeError
          ? `url(${LIST_CLOSED_ICON_ERROR})`
          : `url(${LIST_CLOSED_ICON})`,
        backgroundPosition: '0 50%',
        backgroundRepeat: 'no-repeat',
        width: 18,
        height: 18,
        top: '0px',
        bottom: 0,
        margin: 'auto',
        left: 'unset',
        right: '0px',
      },
    },
    pickerWrap: {
      position: 'relative',
      zIndex: 1,
      marginTop: theme.spacing(-2.7),
      margin: showErr && error ? 0 : theme.spacing(-2.7, 0, -2),
      '& :before': {
        bottom: 10,
      },
      '& :after': {
        bottom: 10,
      },
    },
    picker: {
      width: '100%',
      margin: theme.spacing(0),
      '& #executionDate-helper-text': {
        color: theme.palette.error.main,
      },
      '& label': {
        marginTop: theme.spacing(0.4),
      },
    },
    redError: {
      left: 0,
      color: '#EF2828',
      bottom: '-11px',
      background: 'transparent',
    },
  }))()

type FormAutocompleteDatePickerProps = {
  onChange?: (docType: Date) => void
  value?: Date
  hasError?: boolean
  label: string
  disabled?: boolean
} & KeyboardDatePickerProps

export const FormAutocompleteDatePicker: React.FC<FormAutocompleteDatePickerProps> = ({
  onChange,
  value: docType,
  hasError: docTypeError,
  label,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation()
  const [openDate, setOpenDate] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [showErr, setShowErr] = useState<boolean>(false)

  const classes = useStyles(docTypeError ?? false, openDate, showErr, error)

  const _onChange = (date: MaterialUiPickersDate): void => {
    setShowErr(false)
    if (date) {
      isValid(date) && onChange?.(date)

      if (isNaN(date.getTime())) {
        setError('invalidDateFormat')
      } else if (isPast(new Date(setHours(date, 24)))) {
        setError('dateExpiryPast')
        setSelectedDate(date)
      } else if (date.getTime() > new Date().getTime()) {
        setError(undefined)
        setOpenDate(!openDate)
        setSelectedDate(date)
      } else {
        setSelectedDate(date)
      }
    }
  }

  const onBlur = () => {
    setShowErr(true)
    setOpenDate(false)
  }

  return (
    <>
      {openDate && !disabled ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ClickAwayListener onClickAway={onBlur}>
            <Grid container justifyContent="space-around" className={classes.pickerWrap}>
              <KeyboardDatePicker
                variant="dialog"
                className={`${classes.picker}`}
                format="dd/MM/yyyy"
                margin="normal"
                label={label}
                value={selectedDate}
                invalidDateMessage={''}
                error={docTypeError}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDateMessage={''}
                maxDateMessage={''}
                okLabel="Select"
                onChange={_onChange}
                onAccept={() => setShowErr(true)}
                minDate={new Date()}
                inputProps={{ autoFocus: true }}
                {...rest}
              />
              {showErr && error && (
                <FormHelperText className={classes.redError}>{t(`${error}`)}</FormHelperText>
              )}
            </Grid>
          </ClickAwayListener>
        </MuiPickersUtilsProvider>
      ) : (
        <Chip
          className={classes.label}
          label={
            docType && docType.getTime() !== 0 && !disabled
              ? shortFormatDate(docType, {
                  year: 'numeric',
                  month: '2-digit',
                  day: 'numeric',
                })
              : label
          }
          onClick={() => {
            setOpenDate(true)
          }}
          disabled={disabled}
        />
      )}
    </>
  )
}
