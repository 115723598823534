import React, { FC, ReactElement, useMemo, useState } from 'react'
import { isUndefined } from 'lodash'
import { Collapse, Fab, makeStyles } from '@material-ui/core'
import { CommonTipItem } from './CommonTipItem'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  tipWrap: {
    maxWidth: '100%',
    border: '1px solid',
    position: 'relative',
    backgroundColor: '#fff',
    '& div': {
      padding: 0,
    },
    '& > div': {
      padding: theme.spacing(0.5, 4.3, 1, 1),
    },
  },
  paddingDefault: {
    '& > div': {
      padding: theme.spacing(0.5, 1),
    },
    '& .MuiGrid-container': {
      alignItems: 'flex-start',
    },
  },
  primary: {
    borderColor: 'rgba(39, 110, 241, 0.2)',
  },
  warning: {
    borderColor: 'rgba(239, 40, 40, 0.2)',
  },
  danger: {
    borderColor: 'rgba(239, 128, 25, 0.2)',
  },
  success: {
    borderColor: 'rgba(25, 182, 97, 0.2)',
  },
  btnClose: {
    position: 'absolute',
    top: 3,
    right: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: 32,
    width: 32,
    minHeight: 32,
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    '& .MuiSvgIcon-root': {
      height: 16,
      width: 16,
    },
  },
}))

export const AlertTipItem: FC<{
  value: string | ReactElement
  iconComponent: ReactElement<HTMLElement, string> | null
  type?: 'warning' | 'primary' | 'success' | 'danger' | undefined
  isCloseButton?: boolean
  setIsOpenAlert?: (value: boolean) => void
  isOpenAlert?: boolean
  valueBold?: string
  valueWordUnderline?: string
  title?: string
}> = ({
  type,
  value,
  iconComponent,
  isCloseButton = false,
  setIsOpenAlert,
  isOpenAlert,
  valueBold,
  valueWordUnderline,
  title,
}) => {
  const classes = useStyles()
  const classesNew: string[] = []
  const [open, setOpen] = useState<boolean>(true)

  const setClasses = useMemo(() => {
    const typeAlert = !!type && type?.toLowerCase()
    switch (typeAlert) {
      case 'primary':
        classesNew.push(classes.primary)
        break
      case 'warning':
        classesNew.push(classes.warning)
        break
      case 'danger':
        classesNew.push(classes.danger)
        break
      case 'success':
        classesNew.push(classes.success)
        break
      default:
        classesNew.push(classes.primary)
        break
    }
    return classesNew.join(' ')
  }, [classes.danger, classes.primary, classes.success, classes.warning, classesNew, type])

  return (
    <Collapse
      in={setIsOpenAlert || !isUndefined(isOpenAlert) ? isOpenAlert : open}
      className={`${classes.tipWrap} ${setClasses} ${!isCloseButton ? classes.paddingDefault : ''}`}
      data-test="alert"
    >
      <CommonTipItem
        value={value}
        iconComponent={iconComponent}
        valueBold={valueBold}
        valueWordUnderline={valueWordUnderline}
        title={title}
      />
      {isCloseButton && (
        <Fab
          className={classes.btnClose}
          onClick={() => (setIsOpenAlert ? setIsOpenAlert(false) : setOpen(false))}
          data-test="closeAlert"
        >
          <CloseIcon />
        </Fab>
      )}
    </Collapse>
  )
}
