import React from 'react'
import { Box, Grid, Hidden, makeStyles } from '@material-ui/core'
import { IInputProps } from 'react-dropzone-uploader'
import { useTranslation } from 'react-i18next'
import '../../../PersonalProfile/Tabs/styles.css'
import UploadIcon from '../../../../assets/images/icons/upload.svg?react'

const useStyles = makeStyles((theme) => ({
  dropzoneInner: {
    flexGrow: 1,

    '& input': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      opacity: 0,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  dropzoneInnerText: {
    marginTop: theme.spacing(1.5),
    fontSize: '0.75rem',
  },
}))

export const Input: React.FC<IInputProps> = ({ accept, onFiles, getFilesFromEvent }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      className={classes.dropzoneInner}
    >
      <input
        type="file"
        accept={accept}
        multiple={false}
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles)
          })
        }}
      />
      <UploadIcon />
      <Hidden smDown>
        <Box className={classes.dropzoneInnerText}>
          <strong>
            {t('drag', 'Drag')}&apos;{t('nDrop', 'n drop')}
          </strong>
          {t('orWithSpaces', ' or ')}
          <strong>{t('click', 'click')}</strong>
          {t('toUploadFiles', ' to upload files')}
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className={classes.dropzoneInnerText}>
          <strong>{t('tap', 'Tap')}</strong>
          {t('toUploadFiles', ' to upload files')}
        </Box>
      </Hidden>
    </Grid>
  )
}
