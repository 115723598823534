import React, { FC, useCallback, useEffect, useMemo } from 'react'
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import { isEmpty, isEqual, isNull, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import IconUploadedDocuments from '../../../../assets/images/icons/receivedDocuments.svg?react'
import { ColumnNameWithSort, FiltersResetBtn, uploadDocsPageStateVar } from '../../../../components'
import EmptyDataPage from '../../../../components/Common/EmptyDataPage'
import { Loader } from '../../../../components/Common/Loader'
import PagePagination from '../../../../components/Common/PagePagination'
import { initialDocsPageState } from '../../../../constants'
import {
  Document,
  DocumentOrderBy,
  DocumentUserFilter,
  GetUploadedDocsQueryVariables,
  LimitedAccessRight,
  OrderDirection,
  useGetGetUploadedDocsCountQuery,
  useGetUploadedDocsQuery,
} from '../../../../graphql'
import { useDetermineUserRights, usePageFiltersSorting, useRowPerPage } from '../../../../hooks'
import { PATH_PARAMS } from '../../../../routes/paths'
import { ContractManagementTablesEnum } from '../../../../types'
import { getDocumentLabelByType } from '../../../../utils'
import { UploadDocument } from '../../../AplicationActions'
import { DocumentFilters } from '../../DocumentFilters'
import { updateDocQueryParams } from '../../helpers'
import UploadListMobile from './UploadListMobile'

import UploadListRow from './UploadListRow'
import UploadListTablet from './UploadListTablet'

const useStyles = makeStyles((theme: Theme) => ({
  tableHeader: {
    '& th': {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  uploaded: {
    width: '185px',
    '& button': {
      marginRight: '8px',
    },
  },
  upSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    marginBottom: theme.spacing(3),
  },
  sectionItem: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonSection: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))

export const UploadedDocumentsTable: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly
  const { pageFilters, setPageFilters } = usePageFiltersSorting(uploadDocsPageStateVar)

  const isFiltered = useMemo(() => !isEqual(pageFilters, initialDocsPageState), [pageFilters])

  const { sortBy, direction, searchValue, documentType, period, page } = pageFilters

  const { itemsPerPage } = useRowPerPage()

  const queryParams: GetUploadedDocsQueryVariables = {
    contractId: +applicationId,
    userFilter: DocumentUserFilter.UploadedByIndividual,
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    orderBy: sortBy,
    orderDirection: direction,
  }

  const updatedParams = updateDocQueryParams(pageFilters, queryParams)

  const { data: docsData, loading, refetch } = useGetUploadedDocsQuery({
    variables: updatedParams,
    fetchPolicy: 'network-only',
  })

  const { data: totalDocs, refetch: countRefetch } = useGetGetUploadedDocsCountQuery({
    variables: {
      ...omit(updatedParams, ['limit', 'offset', 'orderBy', 'orderDirection']),
    },
    fetchPolicy: 'network-only',
  })

  const handleRefetch = useCallback(
    (sortedBy: string) => {
      if (sortBy !== sortedBy) {
        setPageFilters({
          direction: OrderDirection.Ascending,
          sortBy: sortedBy as DocumentOrderBy,
          page: 1,
        })
      } else {
        const sortDirection =
          direction === OrderDirection.Descending
            ? OrderDirection.Ascending
            : OrderDirection.Descending
        setPageFilters({ direction: sortDirection, page: 1 })
      }
    },
    [setPageFilters, direction, sortBy],
  )

  const handleChange = useCallback(
    (_, value) => {
      setPageFilters({ page: value })
    },
    [setPageFilters],
  )

  useEffect(() => {
    setTimeout(refetch, 200)
  }, [refetch])

  const refetchData = () => {
    refetch().then()
    countRefetch().then()
  }

  useEffect(() => {
    if (searchValue || documentType || !period.some(isNull)) {
      refetchData()
      setPageFilters({ page: 1 })
    }
  }, [documentType, period, searchValue])

  const renderFileName = useCallback((data: Document | undefined): string => {
    if (data?.type !== 'other') {
      return t(data?.type as string, getDocumentLabelByType(data?.type as string))
    }

    if (data?.type === 'other' && data?.fileDescription) {
      return data.fileDescription
    }

    return t('none', 'None')
  }, [])

  useEffect(() => {
    return () => {
      setPageFilters({ searchValue: '', page: 1 })
    }
  }, [])

  return (
    <>
      <Box className={classes.upSection}>
        <DocumentFilters uploadedTab />

        <Box className={classes.buttonSection}>
          {!isViewOnly && (
            <Box className={classes.sectionItem}>
              <UploadDocument relatedIndividualIncluded={true} refetchDataCallback={refetchData} />
            </Box>
          )}
          <FiltersResetBtn
            isDisabled={!isFiltered}
            resetPageState={() => setTimeout(() => setPageFilters(initialDocsPageState), 0)}
          />
        </Box>
      </Box>

      {loading || (!docsData && !totalDocs) ? (
        <Loader height={'60vh'} />
      ) : (
        <>
          <Hidden mdDown>
            {!isEmpty(docsData?.documents) ? (
              <TableContainer>
                <Table className={classes.tableHeader} data-test="autotest-uploadedDocumentsTable">
                  <TableHead>
                    <TableRow>
                      <ColumnNameWithSort
                        data-test="name"
                        name={DocumentOrderBy.FileName}
                        handleRefetch={handleRefetch}
                        contractManagementType={ContractManagementTablesEnum.UploadedDocs}
                      >
                        {t('name', 'Name')}
                      </ColumnNameWithSort>

                      <TableCell data-test="relatedAccount">
                        {t('relatedAccount', 'Related account')}
                      </TableCell>
                      <TableCell data-test="relatedPayment">
                        {t('relatedPayment', 'Related payment')}
                      </TableCell>
                      <TableCell data-test="relatedPerson">
                        {t('relatedPerson', 'Related person')}
                      </TableCell>

                      <ColumnNameWithSort
                        data-test="type"
                        name={DocumentOrderBy.Type}
                        handleRefetch={handleRefetch}
                        contractManagementType={ContractManagementTablesEnum.UploadedDocs}
                      >
                        {t('type', 'Type')}
                      </ColumnNameWithSort>

                      <ColumnNameWithSort
                        data-test="uploaded"
                        name={DocumentOrderBy.CreatedAt}
                        handleRefetch={handleRefetch}
                        contractManagementType={ContractManagementTablesEnum.UploadedDocs}
                      >
                        {t('uploaded')}
                      </ColumnNameWithSort>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {docsData?.documents &&
                      docsData?.documents.map((value) => (
                        <UploadListRow
                          data={(value as unknown) as Document}
                          renderFileName={renderFileName}
                          key={value?.id.toString()}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyDataPage
                messageKey={
                  isFiltered
                    ? t('noUploadedDocumentsFound', 'No Uploaded documents found')
                    : t('noUploadedDocumentsToDisplay', 'No Uploaded documents to display')
                }
                icon={IconUploadedDocuments}
              />
            )}
          </Hidden>

          {/* tablet view */}
          <Hidden xsDown lgUp>
            <Grid container>
              {!isEmpty(docsData?.documents) ? (
                <Grid item xs={12}>
                  {docsData?.documents &&
                    docsData?.documents.map((value) => (
                      <UploadListTablet
                        data={(value as unknown) as Document}
                        renderFileName={renderFileName}
                        key={value?.id.toString()}
                      />
                    ))}
                </Grid>
              ) : (
                <EmptyDataPage
                  messageKey={
                    isFiltered
                      ? t('noUploadedDocumentsFound', 'No Uploaded documents found')
                      : t('noUploadedDocumentsToDisplay', 'No Uploaded documents to display')
                  }
                  icon={IconUploadedDocuments}
                />
              )}
            </Grid>
          </Hidden>

          {/* mobile view */}
          <Hidden smUp>
            <Grid container>
              {!isEmpty(docsData?.documents) ? (
                <Grid item xs={12}>
                  {docsData?.documents &&
                    docsData?.documents.map((value) => (
                      <UploadListMobile
                        data={(value as unknown) as Document}
                        renderFileName={renderFileName}
                        key={value?.id.toString()}
                      />
                    ))}
                </Grid>
              ) : (
                <EmptyDataPage
                  messageKey={
                    isFiltered
                      ? t('noUploadedDocumentsFound', 'No Uploaded documents found')
                      : t('noUploadedDocumentsToDisplay', 'No Uploaded documents to display')
                  }
                  icon={IconUploadedDocuments}
                />
              )}
            </Grid>
          </Hidden>

          <PagePagination
            page={page}
            totalItems={totalDocs?.documentsCount}
            handleChange={handleChange}
          />
        </>
      )}
    </>
  )
}
