import ListItemIcon from '@material-ui/core/ListItemIcon'
import { Badge, SvgIcon, withStyles } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import React, { FC, ReactNode, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import {
  continueRoute,
  isModalDialogOpen,
  isNavMenuRoute,
  isUnsaveFormData,
} from '../../graphql/local'
import { useReactiveVar } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
  menuList: {
    padding: theme.spacing(1.5, 2, 1.5, 2.25),
    marginBottom: 5,
    transition: 'font-weight 0.2s',
    '&::after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      content: '""',
      width: 4,
      transition: 'background-color ease 0.5s',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiListItemIcon-root': {
        opacity: '.4',
      },
      '& .MuiListItemText-root': {
        opacity: '.8',
      },
      '& .MuiTypography-body1': {
        opacity: '1',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&::after': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiListItemIcon-root': {
        opacity: '1',
        color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {
        fontWeight: 'bold',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& .MuiListItemIcon-root': {
          opacity: '1',
        },
        '& .MuiListItemText-root': {
          opacity: '1',
        },
      },
    },
  },
  menuListIcon: {
    minWidth: 'auto',
    opacity: '1',
    transition: 'opacity 0.2s',
    color: theme.palette.common.black,
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1.5),
    },
  },
  menuListText: {
    margin: 0,
    color: theme.palette.common.black,
    paddingRight: theme.spacing(1.875),
  },
}))

const StyledBadge = withStyles(() => ({
  badge: {
    right: 0,
    width: 22,
    height: 22,
    borderRadius: 15,
    top: '50%',
    padding: '0 2px',
  },
}))(Badge)

export const MainMenuItem: FC<{
  icon: ReactNode
  primary: ReactNode
  path?: string | string[]
  disabled?: boolean
  countNum?: number | undefined
  detailsPath?: string | string[]
}> = ({ icon, primary, countNum, path, disabled, detailsPath, ...props }) => {
  const match = useRouteMatch({ path: path || 'unknownPath', exact: false })
  const matchDetails = useRouteMatch(detailsPath || 'unknownPath')
  const history = useHistory()
  const classes = useStyles()
  const isUnsavedFormData = useReactiveVar(isUnsaveFormData)

  const redirect = useCallback(() => {
    if (path) {
      if (isUnsavedFormData) {
        isModalDialogOpen(true)
        isNavMenuRoute(true)
        continueRoute(`${path}`)
      } else {
        history.push(typeof path === 'string' ? path : path[0])
      }
    }
  }, [history, path, isUnsavedFormData])
  return (
    <ListItem
      button
      selected={!!match || !!matchDetails}
      onClick={redirect}
      className={classes.menuList}
      disabled={disabled}
      component={'li'}
      {...props}
    >
      <ListItemIcon className={classes.menuListIcon}>
        <SvgIcon>{icon}</SvgIcon>
      </ListItemIcon>
      <ListItemText primary={primary} className={classes.menuListText} />
      <StyledBadge
        badgeContent={countNum}
        color="secondary"
        overlap={'rectangular'}
        data-test="redBage"
      />
    </ListItem>
  )
}
