import { yupResolver } from '@hookform/resolvers/yup'

import {
  Button,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Document,
  DocumentType,
  GetContractProfileChangesDocument,
  ProfileChange,
  ProfileChangeStatus,
  useContractDetailsChangeMutation,
  useCreateOwnDocumentMutation,
  useGetContractProfileChangesQuery,
} from '../../graphql'
import { PATH_PARAMS } from '../../routes/paths'
import { PoiProfileInputSchema } from '../../schemes/common'

import {
  AddressTypeOptions,
  ChangeValuesType,
  ContractChanges,
  FilesForSavingAndShowType,
} from '../../types'
import { extractDateValue, scrollToInputElement, showError } from '../../utils'
import { ProofOfIdentityListPOA } from '../../utils/Data'
import { ProofOfIdentityControllingPerson } from '../NewPerson/Forms/ProofOfIdentityControllingPerson'
import { ChangesValue } from './ChangesValue'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    flexWrap: 'wrap',
    padding: 0,
    '& .MuiListItemText-primary': {
      ...theme.typography.subtitle2,
      color: '#595959',
    },
    '& .MuiListItemText-secondary': {
      ...theme.typography.body1,
      color: '#000',
    },
  },
  listLabel: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  modal: {
    maxWidth: 512,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
    '& > div > form': {
      width: '100%',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
    borderBottom: '1px solid #ccc',
  },
  scrollable: {
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'auto',
    overflowX: 'clip',
  },
  bodyModal: {
    padding: '24px 0 0 24px',
    maxHeight: 'calc(100vh - 180px)',
  },
  uploadDoc: {
    padding: theme.spacing(0, 3),
    margin: '16px 0 24px 0',
    maxHeight: 'calc(100vh - 180px)',
    '& > div > div > div > div': {
      marginTop: '0px',
    },
  },
  footerModal: {
    paddingBottom: 24,
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}))

type UploadConfirmationModalProps = {
  isOpen: boolean
  closeModal: () => void
  contractChanges?: ContractChanges[]
  contractChangesId?: number
  setSupportingDocuments: React.Dispatch<React.SetStateAction<Document[] | undefined>>
}

export const UploadConfirmationModal: FC<UploadConfirmationModalProps> = ({
  isOpen,
  closeModal,
  contractChanges,
  contractChangesId,
  setSupportingDocuments,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(PoiProfileInputSchema),
  })

  const {
    watch: watchModal,
    register,
    setValue,
    clearErrors,
    getValues: modalFormGetValues,
    handleSubmit,
  } = methods
  const filesForUploadAddress = watchModal(`filesForUploadAddress`)
  const [
    contractDetailsChangeMutation,
    { error: errorDataSaving },
  ] = useContractDetailsChangeMutation()
  const [createOwnDocument, { error: errorUpload }] = useCreateOwnDocumentMutation({})

  const {
    data: contractDataChanges,
    error: contractProfileChangesError,
  } = useGetContractProfileChangesQuery({
    variables: {
      contractId: +applicationId,
      status: [
        ProfileChangeStatus.PendingExecutionSignature,
        ProfileChangeStatus.Pending,
        ProfileChangeStatus.Approved,
      ],
    },
    skip: !applicationId,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setSupportingDocuments(
      ((contractDataChanges?.contractProfileChanges as ProfileChange[])?.find((profileChange) => {
        return profileChange?.id === contractChangesId
      }) as ProfileChange)?.supportingDocuments as Document[],
    )
  }, [contractDataChanges])

  const handleChange = useCallback(async () => {
    try {
      async function processUploadedDocs(filesForUpload: FilesForSavingAndShowType[]) {
        if (filesForUpload && filesForUpload.length > 0) {
          for (const fileForSaving of filesForUpload) {
            await createOwnDocument({
              variables: {
                type: fileForSaving.docType as DocumentType,
                fileName: fileForSaving.fileName,
                size: fileForSaving.size,
                plainUrl: fileForSaving.plainUrl ?? '',
                contractId: +applicationId,
                profileChangeId: contractChangesId,
                expiration: extractDateValue(fileForSaving.expiration),
              },
              refetchQueries: [
                {
                  query: GetContractProfileChangesDocument,
                  variables: {
                    contractId: +applicationId,
                    status: [
                      ProfileChangeStatus.PendingExecutionSignature,
                      ProfileChangeStatus.Pending,
                    ],
                  },
                },
              ],
            }).catch((error) => {
              toast.error(t('uploadError', 'Upload error'))
              throw error
            })
          }
        }
      }
      await processUploadedDocs(filesForUploadAddress)
      closeModal()
    } catch (e) {
      toast.error((e as Error).message)
    } finally {
      setValue(`filesForUploadAddress`, [])
      setValue(`addressRequired`, false)
    }
  }, [
    applicationId,
    closeModal,
    filesForUploadAddress,
    setValue,
    t,
    createOwnDocument,
    contractDetailsChangeMutation,
    contractChangesId,
  ])

  useEffect(() => {
    register(`filesForUploadAddress`)
    if (modalFormGetValues(`filesForUploadAddress`) === undefined) {
      setValue(`filesForUploadAddress`, [])
    }
    register(`addressRequired`)
    if (modalFormGetValues(`addressRequired`) === undefined) {
      setValue(`addressRequired`, false)
    }
  }, [modalFormGetValues, register, setValue])

  useEffect(() => {
    if (errorDataSaving) {
      showError(errorDataSaving)
    } else if (errorUpload) {
      showError(errorUpload)
    } else if (contractProfileChangesError) {
      showError(contractProfileChangesError)
    }
  }, [errorDataSaving, errorUpload, contractProfileChangesError])

  useEffect(() => {
    if (methods.errors) {
      return scrollToInputElement(methods.errors)
    }
  }, [methods.errors])

  const closeWindow = useCallback(() => {
    setValue(`filesForUploadAddress`, [])
    setValue(`addressRequired`, false)
    clearErrors('documentTypeAddress')
    closeModal()
  }, [setValue, closeModal, clearErrors])

  return (
    <>
      <Modal
        open={isOpen}
        onClose={closeWindow}
        aria-labelledby="account-modal-title"
        aria-describedby="account-modal-description"
      >
        <Grid container item style={modalStyle} className={classes.modal}>
          <Grid item className={classes.headerModal}>
            <Typography variant={'h5'}>{t('editRequest')}</Typography>
            <Fab
              color="default"
              aria-label="close"
              className={classes.btnCloseModal}
              onClick={closeWindow}
            >
              <CloseIcon />
            </Fab>
          </Grid>

          <Grid item xs={12} container className={classes.scrollable}>
            <Grid item xs={12} container>
              {contractChanges
                ?.sort((a) => {
                  if (a?.changedProperty === 'companyName') {
                    return -4
                  } else if (a?.changedProperty === 'tradingName') {
                    return -3
                  } else if (a?.changedProperty === 'phone') {
                    return -2
                  } else if (a?.changedProperty === 'website') {
                    return -1
                  } else return 0
                })
                ?.map((change, i) => (
                  <Grid
                    key={`${i}+${
                      change.addressType === AddressTypeOptions.correspondenceAddress
                        ? change.changedProperty + 'Correspondence'
                        : change.changedProperty
                    }`}
                    item
                    xs={12}
                    className={classes.bodyModal}
                  >
                    <List dense={false} className={classes.list}>
                      <ListItem className={classes.listItem}>
                        <Typography variant="h3" className={classes.listLabel}>
                          {t(
                            `${
                              change.addressType === AddressTypeOptions.correspondenceAddress
                                ? change.changedProperty + 'Correspondence'
                                : change.changedProperty
                            }`,
                          )}
                        </Typography>
                        <ListItemText
                          primary={`${t('currentValue')}:`}
                          secondary={<ChangesValue diffData={change} type={ChangeValuesType.old} />}
                        />
                      </ListItem>
                      <ListItem className={`${classes.listItem} ${classes.mb2}`}>
                        <ListItemText
                          primary={`${t('newValue', 'New value')}:`}
                          secondary={<ChangesValue diffData={change} type={ChangeValuesType.new} />}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                ))}
            </Grid>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleChange)}>
                <Grid item xs={12} className={classes.uploadDoc}>
                  <ProofOfIdentityControllingPerson
                    nameLabel={t('supportingDocument', 'Supporting document')}
                    infoLabel={t('spaceSymbol', ' ')}
                    filesForUploadRegName={'filesForUploadAddress'}
                    documentTypeRegName={'documentTypeAddress'}
                    expiryDateRegName={'expiryDateAddress'}
                    optionalIdentityList={ProofOfIdentityListPOA}
                    isModal={true}
                  />
                </Grid>

                <Grid item xs={12} className={classes.bodyModal}>
                  <Grid item xs={12} className={classes.footerModal}>
                    <Grid container item spacing={2} justifyContent={'flex-start'}>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={filesForUploadAddress?.length === 0}
                          color="primary"
                        >
                          {t('upload', 'Upload')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={closeWindow} variant="contained">
                          {t('cancel', 'Cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}
