import { makeStyles } from '@material-ui/core/styles'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ProfileChangeAnswer, ProfileChangeStatus } from '../../../graphql'

import { useStyles } from './useStyles'
import { SignaturesTable, SignaturesBox } from './helpers'
import { contractChangesNew, contractChangeTypes } from '../../../utils'
import { AddressTypeOptions, ChangeValuesType, ContractChangeProps } from '../../../types'
import { UploadDocument } from '../UploadDocument'
import { ChangesValue } from '../../../components/Common/ChangesValue'

const useCurrentStyles = makeStyles(() => ({
  wrapText: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
}))

export const ContractChangeTab: FC<{ action: ContractChangeProps; signedCount: number }> = ({
  action,
  signedCount,
}) => {
  const { t } = useTranslation()
  const classes = { ...useStyles(), ...useCurrentStyles() }
  const differs = useMemo(
    () =>
      contractChangesNew(
        (action?.contractChangeData?.contractChange?.oldMeta ?? {}) as ProfileChangeAnswer,
        (action?.contractChangeData?.contractChange?.changeAnswers ?? {}) as ProfileChangeAnswer,
      ),
    [action, contractChangesNew],
  )

  const contractChangesId = action?.contractChangeData?.contractChange?.id as number
  const isApproved =
    action?.contractChangeData?.contractChange?.status === ProfileChangeStatus.Approved

  const renderChangedValuesTab = () => {
    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={'40%'}>{t('field', 'Field')}</TableCell>
              <TableCell>{t('oldValue', 'Old value')}</TableCell>
              <TableCell>{t('newValue', 'New value')}</TableCell>
            </TableRow>
          </TableHead>

          {differs.filter((diffItem) => diffItem.addressType === undefined) &&
            differs
              .filter((diffItem) => diffItem.addressType === undefined)
              .map((diffItem, i) => {
                return (
                  <TableRow key={`${diffItem}+${i}`}>
                    <TableCell>{contractChangeTypes(t(`${diffItem.changedProperty}`))}</TableCell>
                    <TableCell>
                      <Typography className={classes.wrapText}>
                        <ChangesValue diffData={diffItem} type={ChangeValuesType.old} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.wrapText}>
                        <ChangesValue diffData={diffItem} type={ChangeValuesType.new} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
          <TableBody>
            {differs.filter((diffItem) => diffItem.addressType === AddressTypeOptions.address) && (
              <>
                {differs
                  .filter((diffItem) => diffItem.addressType === AddressTypeOptions.address)
                  .map((diffItem, i) => {
                    return (
                      <TableRow key={`${diffItem}+${i}`}>
                        <TableCell>{contractChangeTypes(diffItem.changedProperty)}</TableCell>
                        <TableCell>
                          <ChangesValue diffData={diffItem} type={ChangeValuesType.old} />
                        </TableCell>
                        <TableCell>
                          <ChangesValue diffData={diffItem} type={ChangeValuesType.new} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </>
            )}

            {differs.filter(
              (diffItem) => diffItem.addressType === AddressTypeOptions.correspondenceAddress,
            ) && (
              <>
                {differs
                  .filter(
                    (diffItem) => diffItem.addressType === AddressTypeOptions.correspondenceAddress,
                  )
                  .map((diffItem, i) => {
                    return (
                      <TableRow key={`${diffItem}+${i}`}>
                        <TableCell>
                          {contractChangeTypes(diffItem.changedProperty) + ' Correspondence'}
                        </TableCell>
                        <TableCell>
                          <ChangesValue diffData={diffItem} type={ChangeValuesType.old} />
                        </TableCell>
                        <TableCell>
                          <ChangesValue diffData={diffItem} type={ChangeValuesType.new} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <Hidden xsDown>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell width="200" colSpan={2} className={classes.cell}>
                <Typography variant="h4" className={classes.label}>
                  {t('changesBusinessDetails', 'What has changed')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {renderChangedValuesTab()}
        <UploadDocument
          contractChanges={differs}
          contractChangesId={contractChangesId}
          isApproved={isApproved}
        />
        <SignaturesTable signatures={action.signatures} signedCount={signedCount} />
      </Hidden>

      {/* mobile table */}
      <Hidden smUp>
        <Box className={classes.mobileTable}>
          <Box className={classes.mobileTableRow}>
            <Box className={classes.mobileTableItem}>
              <Box className={classes.mobileTableLabel}>
                <Typography variant="h4" className={`${classes.label} ${classes.black}`}>
                  {t('changesBusinessDetails', 'What has changed')}
                </Typography>
              </Box>
            </Box>

            {differs.filter((diffItem) => diffItem.addressType === undefined) &&
              differs
                .filter((diffItem) => diffItem.addressType === undefined)
                .map((diffItem, i) => {
                  return (
                    <React.Fragment key={diffItem.changedProperty + i}>
                      <Box className={classes.mobileTableItem}>
                        <Box className={classes.mobileTableLabel}>{t('field', 'Field')}</Box>
                        <Box className={classes.mobileTableName}>
                          {contractChangeTypes(t(`${diffItem.changedProperty}`))}
                        </Box>
                      </Box>
                      <Box className={classes.mobileTableItem}>
                        <Box className={classes.mobileTableLabel}>{t('oldValue', 'Old value')}</Box>
                        <Box className={classes.mobileTableName}>
                          <ChangesValue diffData={diffItem} type={ChangeValuesType.old} />
                        </Box>
                      </Box>
                      <Box className={classes.mobileTableItem}>
                        <Box className={classes.mobileTableLabel}>{t('newValue', 'New value')}</Box>
                        <Box>
                          <ChangesValue diffData={diffItem} type={ChangeValuesType.new} />
                        </Box>
                      </Box>
                    </React.Fragment>
                  )
                })}

            {differs.filter((diffItem) => diffItem.addressType === AddressTypeOptions.address) && (
              <>
                {differs
                  .filter((diffItem) => diffItem.addressType === AddressTypeOptions.address)
                  .map((diffItem, i) => {
                    return (
                      <React.Fragment key={diffItem.changedProperty + i}>
                        <Box className={classes.mobileTableItem}>
                          <Box className={classes.mobileTableLabel}>{t('field', 'Field')}</Box>
                          <Box className={classes.mobileTableName}>
                            {contractChangeTypes(diffItem.changedProperty)}
                          </Box>
                        </Box>
                        <Box className={classes.mobileTableItem}>
                          <Box className={classes.mobileTableLabel}>
                            {t('oldValue', 'Old value')}
                          </Box>
                          <Box className={classes.mobileTableName}>
                            <ChangesValue diffData={diffItem} type={ChangeValuesType.old} />
                          </Box>
                        </Box>
                        <Box className={classes.mobileTableItem}>
                          <Box className={classes.mobileTableLabel}>
                            {t('newValue', 'New value')}
                          </Box>
                          <Box>
                            <ChangesValue diffData={diffItem} type={ChangeValuesType.new} />
                          </Box>
                        </Box>
                      </React.Fragment>
                    )
                  })}
              </>
            )}

            {differs.filter(
              (diffItem) => diffItem.addressType === AddressTypeOptions.correspondenceAddress,
            ) && (
              <>
                {differs
                  .filter(
                    (diffItem) => diffItem.addressType === AddressTypeOptions.correspondenceAddress,
                  )
                  .map((diffItem, i) => {
                    return (
                      <React.Fragment key={diffItem.changedProperty + i}>
                        <Box className={classes.mobileTableItem}>
                          <Box className={classes.mobileTableLabel}>{t('field', 'Field')}</Box>
                          <Box className={classes.mobileTableName}>
                            {contractChangeTypes(diffItem.changedProperty) + ' Correspondence'}
                          </Box>
                        </Box>
                        <Box className={classes.mobileTableItem}>
                          <Box className={classes.mobileTableLabel}>
                            {t('oldValue', 'Old value')}
                          </Box>
                          <Box className={classes.mobileTableName}>
                            <ChangesValue diffData={diffItem} type={ChangeValuesType.old} />
                          </Box>
                        </Box>
                        <Box className={classes.mobileTableItem}>
                          <Box className={classes.mobileTableLabel}>
                            {t('newValue', 'New value')}
                          </Box>
                          <Box>
                            <ChangesValue diffData={diffItem} type={ChangeValuesType.new} />
                          </Box>
                        </Box>
                      </React.Fragment>
                    )
                  })}
              </>
            )}
            <UploadDocument
              contractChanges={differs}
              contractChangesId={contractChangesId}
              isApproved={isApproved}
            />
            <SignaturesBox signatures={action.signatures} signedCount={signedCount} />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
