import React, { useEffect, useState } from 'react'
import { Box, Chip, Typography } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import LIST_CLOSED_ICON from '../../../assets/images/icons/list_closed.svg?react'
import LIST_CLOSED_ICON_ERROR from '../../../assets/images/icons/list_closed_error.svg?react'
import LIST_OPENED_ICON from '../../../assets/images/icons/list_opened.svg?react'
import { Maybe } from '../../../graphql'
import { filterOptionsStart } from '../../../utils'

const useStyles = (docTypeError: boolean, openSearch: boolean) =>
  makeStyles((theme: Theme) => ({
    formField: {
      position: 'relative',
      zIndex: 1,
      margin: theme.spacing(-4, 0, 0),
      maxWidth: '100%',
      minWidth: 222,
      background: '#FFFFFF',
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
      },
      '& .MuiTextField-root:before': {},
      '& .MuiInput-underline:after': {
        display: 'none',
      },
      '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
        width: '100%',
        paddingRight: 0,
      },
    },
    docTypeLabel: {
      display: 'flex',
      justifyContent: 'flex-start',
      color: '#000000',
      backgroundColor: docTypeError ? '#FFEFED' : '#fff',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
      marginRight: '10px',
      fontSize: '12px',
      width: '100%',
      '& :before': {
        position: 'absolute',
        display: 'block',
        border: 0,
        content: '""',
        backgroundImage: openSearch
          ? `url(${LIST_OPENED_ICON})`
          : docTypeError
          ? `url(${LIST_CLOSED_ICON_ERROR})`
          : `url(${LIST_CLOSED_ICON})`,
        backgroundPosition: '0 50%',
        backgroundRepeat: 'no-repeat',
        width: 18,
        height: 18,
        top: '0px',
        bottom: 0,
        margin: 'auto',
        left: 'unset',
        right: '0px',
      },
    },
    warning: {
      fontSize: '12px',
      color: 'red',
    },
  }))()

type FormAutocompleteSearchProps = {
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  data: any[]
  defaultValue?: Maybe<string> | undefined
  chooseDocTypeHandler: (option: { key: string; label: string }) => void
  docType?: string
  docTypeError: boolean
  label: string
}

export const FormAutocompleteCustom: React.FC<
  FormAutocompleteSearchProps & Partial<TextFieldProps>
> = ({ chooseDocTypeHandler, data, defaultValue = '', docType, docTypeError, label, disabled }) => {
  const { t } = useTranslation()
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const classes = useStyles(docTypeError, openSearch)

  const parentEl = document.getElementById('uploadedFilesContainerId')

  useEffect(() => {
    const closeSearchField = () => setOpenSearch(false)
    parentEl?.addEventListener('scroll', closeSearchField)

    return () => parentEl?.removeEventListener('scroll', closeSearchField)
  }, [parentEl])

  return (
    <>
      <Chip
        className={classes.docTypeLabel}
        label={docType ? find(data, (o) => o.key === docType)?.label : label}
        onClick={() => {
          setOpenSearch(!openSearch)
        }}
        disabled={disabled}
      />
      {docTypeError && (
        <Typography className={classes.warning}>
          {t('fieldIsMandatory', 'This field is mandatory')}
        </Typography>
      )}
      {openSearch && (
        <ClickAwayListener
          onClickAway={() => {
            setOpenSearch(false)
          }}
        >
          <Box>
            <Autocomplete
              open={true}
              closeIcon={null}
              className={classes.formField}
              options={data}
              getOptionLabel={(option) => option.label}
              onChange={(event, option) => {
                chooseDocTypeHandler(option)
                setOpenSearch(!openSearch)
              }}
              defaultValue={data.find((v) => v.key === defaultValue)}
              renderInput={(params) => (
                <Box>
                  <TextField {...params} label="Type" onClick={() => setOpenSearch(false)} />
                </Box>
              )}
              filterOptions={filterOptionsStart}
            />
          </Box>
        </ClickAwayListener>
      )}
    </>
  )
}
