import * as yup from 'yup'
import {
  NUMBERS_ONLY_MESSAGE,
  OVERALL_PREDICTED_AMOUNT_CANT_BE_0,
  OVERALL_VALUE_SHOULDNT_BE_GREATER_THAN_100,
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
  PRE_ASSESSMENT_SUM_OF_PERCENTS0,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
  URL_IS_REQUIRED_MESSAGE,
  URL_WEB_SITE_REGEXP,
} from '../../constants/validations'
import { isEmpty, isUndefined } from 'lodash'
import { findNumberedObjectKeys } from '../../utils'
import { BusinessRegionsEnumErrorTypes, CardPaymentsSelectOptions } from '../../types'

interface VirtualFormFields {
  [key: string]: unknown
}

export const CardPaymentAndApmSchema = (virtualFormFields: VirtualFormFields) => {
  const dynamicApmCurrentlyFields: string[] =
    findNumberedObjectKeys(virtualFormFields, 'apmCurrentlyInp') || []
  const dynamicApmRequiredFields: string[] =
    findNumberedObjectKeys(virtualFormFields, 'apmRequiredInp') || []
  const dynamicMerchantWebsitesFields: string[] =
    ('merchantWebsites' in virtualFormFields
      ? (virtualFormFields['merchantWebsites'] as string[])
      : []) || []
  const dynamicBusinessCategoryRegionFields =
    ('businessRegions' in virtualFormFields
      ? (virtualFormFields['businessRegions'] as {
          businessCategoryRegion: string
        }[])?.map(({ businessCategoryRegion }) => businessCategoryRegion)
      : []) || []

  return yup.object().shape({
    cardPaymentsApm: yup.string(),
    businessRegions: yup
      .mixed()
      .notRequired()
      .when('cardPaymentsApm', {
        is: CardPaymentsSelectOptions.required,
        then: yup
          .mixed()
          .test(
            BusinessRegionsEnumErrorTypes.businessRegions100,
            OVERALL_VALUE_SHOULDNT_BE_GREATER_THAN_100,
            function (value) {
              const percentSum = (value as Record<string, string>[])?.reduce(
                (acc, field) => acc + Number(field.businessCategoryPercentage),
                0,
              )
              return percentSum <= 100
            },
          )
          .test(
            BusinessRegionsEnumErrorTypes.businessRegions0,
            PRE_ASSESSMENT_SUM_OF_PERCENTS0,
            function (value) {
              const percentSum = (value as Record<string, string>[])?.reduce(
                (acc, field) => acc + Number(field.businessCategoryPercentage),
                0,
              )
              return percentSum > 0
            },
          )
          .test(
            BusinessRegionsEnumErrorTypes.valueIsNotEmptyPercentage,
            THIS_FIELD_IS_REQUIRED_MESSAGE,
            function (value) {
              let valid = true
              ;(value as Record<string, string>[])?.forEach((itemValue) => {
                if (!itemValue.businessCategoryPercentage) {
                  valid = false
                }
              })
              return valid
            },
          )
          .test(
            BusinessRegionsEnumErrorTypes.onlyPositiveIsAllowed,
            NUMBERS_ONLY_MESSAGE,
            function (value) {
              let valid = true
              ;(value as Record<string, string>[])?.forEach((itemValue) => {
                if (Number(itemValue.businessCategoryPercentage) <= 0) {
                  valid = false
                }
              })
              return valid
            },
          ),
      }),
    merchantWebsites: yup
      .mixed()
      .notRequired()
      .when('cardPaymentsApm', {
        is: CardPaymentsSelectOptions.required,
        then: yup
          .mixed()
          .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (sites) => {
            return !sites.some((site: Record<string, string>) => !site.value) && !isEmpty(sites)
          })
          .test('isWebsite', URL_IS_REQUIRED_MESSAGE, (sites) => {
            return sites.every((site: Record<string, string>) =>
              URL_WEB_SITE_REGEXP.test(site.value),
            )
          })
          .test('siteMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (sites) => {
            return !sites.some(
              (site: Record<string, string>) =>
                site.value.length > PRE_ASSESSMENT_COMMON_FIELD_MAX && !isEmpty(sites),
            )
          }),
      }),
    trxCurrencies: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup
        .mixed()
        .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
        .test('required', THIS_FIELD_IS_REQUIRED_MESSAGE, (value) => !isEmpty(value)),
    }),
    settlementCurrencies: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup
        .mixed()
        .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
        .test('required', THIS_FIELD_IS_REQUIRED_MESSAGE, (value) => !isEmpty(value)),
    }),
    totalPredictedMonthlyVolumes: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup
        .mixed()
        .test(
          'totalPredictedMonthlyVolumes0',
          OVERALL_PREDICTED_AMOUNT_CANT_BE_0,
          (val) => val > 0,
        ),
    }),
    ...dynamicBusinessCategoryRegionFields.reduce((schema, value, currentIndex) => {
      return {
        ...schema,
        [`businessRegions[${currentIndex}].businessCategoryRegion`]: yup
          .string()
          .nullable()
          .when('cardPaymentsApm', {
            is: CardPaymentsSelectOptions.required,
            then: yup
              .string()
              .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, () => !isEmpty(value)),
          }),
      }
    }, {}),
    apmCurrentlyInp10: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    apmCurrentlyInp20: yup
      .mixed()
      .test('is-required-based-on-other-fields', THIS_FIELD_IS_REQUIRED_MESSAGE, function (value) {
        if (!!this.parent.apmCurrentlyInp10) {
          return !!value
        }
        return true
      }),
    apmRequiredInp10: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    apmRequiredInp20: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup
        .mixed()
        .test(
          'is-required-based-on-other-fields',
          THIS_FIELD_IS_REQUIRED_MESSAGE,
          function (value) {
            if (!!this.parent.apmRequiredInp10) {
              return !!value
            }
            return true
          },
        ),
    }),
    ...dynamicApmCurrentlyFields.reduce((schema, fieldName) => {
      if (isUndefined(virtualFormFields[fieldName])) return schema

      return {
        ...schema,
        [fieldName]: yup
          .string()
          .nullable()
          .when('cardPaymentsApm', {
            is: CardPaymentsSelectOptions.required,
            then: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
            // .test(
            //   'is-required-based-on-other-fields',
            //   THIS_FIELD_IS_REQUIRED_MESSAGE,
            //   function (value) {
            //     console.log('value:', value)
            //     const fieldType = fieldName.slice(-2)
            //     const relatedField = `${fieldName.slice(0, -2)}${
            //       startsWith(fieldType, '1')
            //         ? replace(fieldType, /^./, '2')
            //         : replace(fieldType, /^./, '1')
            //     }`
            //     if (!!this.parent[relatedField]) {
            //       return !!value
            //     }
            //     return true
            //   },
            // ),
          }),
      }
    }, {}),
    ...dynamicApmRequiredFields.reduce((schema, fieldName) => {
      if (isUndefined(virtualFormFields[fieldName])) return schema

      return {
        ...schema,
        [fieldName]: yup
          .string()
          .nullable()
          .when('cardPaymentsApm', {
            is: CardPaymentsSelectOptions.required,
            then: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
            // .test(
            //   'is-required-based-on-other-fields',
            //   THIS_FIELD_IS_REQUIRED_MESSAGE,
            //   function (value) {
            //     const fieldType = fieldName.slice(-2)
            //     const relatedField = `${fieldName.slice(0, -2)}${
            //       startsWith(fieldType, '1')
            //         ? replace(fieldType, /^./, '2')
            //         : replace(fieldType, /^./, '1')
            //     }`
            //     if (!!this.parent[relatedField]) {
            //       return !!value
            //     }
            //     return true
            //   },
            // ),
          }),
      }
    }, {}),
    selAffiliatesTraffic: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    affiliatesList: yup.mixed().when('selAffiliatesTraffic', {
      is: 'Yes',
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    selCredentialBillingMethod: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    credentialDetailsExplanation: yup
      .string()
      .nullable()
      .when('selCredentialBillingMethod', {
        is: 'Yes',
        then: yup.string().nullable().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
      }),
    integrationTypeSelect: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    pciProvidersName: yup.mixed().when('pciCompliance', {
      is: 'from3rdParty',
      then: yup.string().nullable().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    processedHistory: yup.mixed().when('cardPaymentsApm', {
      is: CardPaymentsSelectOptions.required,
      then: yup.mixed().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    currentProcessor: yup.mixed().when('processedHistory', {
      is: 'Yes',
      then: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    periodWithProcessor: yup.mixed().when('processedHistory', {
      is: 'Yes',
      then: yup.string().required(THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    historyStatement: yup.array().when('processedHistory', {
      is: 'Yes',
      then: yup
        .array()
        .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
        .min(1, THIS_FIELD_IS_REQUIRED_MESSAGE),
    }),
    ...dynamicMerchantWebsitesFields.reduce((schema, value, currentIndex) => {
      return {
        ...schema,
        [`merchantWebsites[${currentIndex}]`]: yup.string().when('cardPaymentsApm', {
          is: CardPaymentsSelectOptions.required,
          then: yup
            .string()
            .matches(URL_WEB_SITE_REGEXP, URL_IS_REQUIRED_MESSAGE)
            .test('notEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, () => !isEmpty(value)),
        }),
      }
    }, {}),
  })
}
