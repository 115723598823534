import * as yup from 'yup'

import i18n from '../../i18n'
import {
  ALL_ARABIC_REGEXP,
  CYRILIC_REGEXP,
  MIN_LENGTH,
  MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE,
  SPECIAL_SYMBOLS_REGEXP,
} from '../../constants/validations'
import { isEmpty } from 'lodash'

export const AddressInputSchema = yup.object().shape({
  country: yup
    .mixed()
    .test(
      'notEmpty',
      i18n.t('thisFieldIsRequired', 'This field is required'),
      (val) => !isEmpty(val),
    ),
  city: yup
    .string()
    .nullable()
    .required(i18n.t('cityIsRequired', 'City is required'))
    .test(
      'minSymbols',
      MUST_HAVE_AT_LEAST_CHARACTERS_MESSAGE(MIN_LENGTH),
      (valueInput) => !!valueInput && valueInput?.length > 1,
    )
    .test('cityInvalid', i18n.t('invalidCity', 'Invalid city'), (inputValue) => {
      const cyrillicValidationRegex = RegExp(CYRILIC_REGEXP)
      const allArabicValidationRegexp = RegExp(ALL_ARABIC_REGEXP)
      const specialSymbols = RegExp(SPECIAL_SYMBOLS_REGEXP)

      let testResult = true
      if (typeof inputValue === 'string') {
        testResult =
          !cyrillicValidationRegex.test(inputValue) &&
          !allArabicValidationRegexp.test(inputValue) &&
          !specialSymbols.test(inputValue)
      }
      return testResult
    }),
})

export type AddressInput = yup.Asserts<typeof AddressInputSchema>
