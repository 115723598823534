import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { GridRow } from '../../Common'
import { useCommonStyles } from './helpers/functions'

export const ParagraphStyling: FC<{
  title: string
  description?: string
  mt?: number
  mb?: number
  id: string
}> = ({ title, description, mt = 0, mb = 0, id, children }) => {
  const classes = useCommonStyles()

  return (
    <Box position={'relative'}>
      <Box id={id} position={'absolute'} top={description ? 0 : -80} />
      <Box mb={mb} mt={mt}>
        <Typography variant="h3">{title}</Typography>
        {description && (
          <GridRow fullWidth>
            <Typography variant="h6" className={classes.titleSubBox}>
              {description}.
            </Typography>
          </GridRow>
        )}
        <GridRow fullWidth>
          <Box bgcolor="#FBFBFB" p={2} my={3}>
            {children}
          </Box>
        </GridRow>
      </Box>
    </Box>
  )
}
