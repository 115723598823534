import * as yup from 'yup'
import {
  ALL_EXCEPT_CYRILLIC_REGEXP,
  LATINICA_ONLY_MESSAGE,
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../../constants/validations'
import i18n from '../../i18n'

export const RegulatorInformationImputSchema = yup.object().shape({
  licenseRequired: yup.boolean().default(false),
  regulatorNameAndWebsite: yup
    .string()
    .nullable()
    .when('licenseRequired', {
      is: true,
      then: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
        .test(
          'regulatorNameAndWebsite',
          LATINICA_ONLY_MESSAGE,
          (val) => !!val?.match(ALL_EXCEPT_CYRILLIC_REGEXP),
        )
        .max(PRE_ASSESSMENT_COMMON_FIELD_MAX, i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON)),
    }),
  licenseNumber: yup
    .string()
    .nullable()
    .when('licenseRequired', {
      is: true,
      then: yup
        .string()
        .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
        .test(
          'licenseNumber',
          LATINICA_ONLY_MESSAGE,
          (val) => !val || !!val?.match(ALL_EXCEPT_CYRILLIC_REGEXP),
        )
        .max(PRE_ASSESSMENT_COMMON_FIELD_MAX, i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON)),
    }),
})
