import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, { useEffect } from 'react'
import config from './config'
import { useRowPerPage } from './hooks'
import Error500Page from './pages/Error500Page'

import { AppRoutesSettings, renderRoutes } from './routes'
import { LogOutByTime } from './components/LogOutByTime'

Bugsnag.start({
  apiKey: import.meta.env.REACT_APP_BUGSNAG_APIKEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['staging', 'production', 'uat'],
  releaseStage: import.meta.env.MODE,
  appVersion: import.meta.env.REACT_APP_VERSION,
})
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

const vitePreloadErrorHandler = (event: Event & { payload?: object }) => {
  // eslint-disable-next-line no-console
  console.log('vitePreloadErrorHandler => ', event.payload)
  if ('caches' in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName)
      })
    })
  }
  window.location.reload()
  event.preventDefault()
}

export const App: React.FC = () => {
  const favicon = document.getElementById('favicon') as HTMLAnchorElement
  const pageTitle = document.getElementById('page-title')

  const { itemPerPageInit } = useRowPerPage()

  if (pageTitle) pageTitle.innerText = config.name
  if (favicon && config.images.favicon) {
    favicon.href = `/${config.images.favicon}`
  }

  useEffect(() => {
    window.addEventListener('vite:preloadError', vitePreloadErrorHandler)
    itemPerPageInit()

    return () => {
      window.removeEventListener('vite:preloadError', vitePreloadErrorHandler)
    }
  }, [])

  return ErrorBoundary ? (
    <ErrorBoundary FallbackComponent={Error500Page}>
      <LogOutByTime>{renderRoutes(AppRoutesSettings)}</LogOutByTime>
    </ErrorBoundary>
  ) : (
    <LogOutByTime>{renderRoutes(AppRoutesSettings)}</LogOutByTime>
  )
}
