import React, { FC } from 'react'
import { Box, Grid, makeStyles, createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { BatchPaymentRecord, Maybe } from '../../graphql'
import { BeneficialCellType } from '../../types'
import { BeneficiariesListCell } from './BeneficiariesListCell'

const useStyles = makeStyles((theme) =>
  createStyles({
    tableRow: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 57,
      padding: theme.spacing(1, 2),
      borderBottom: '1px solid rgba(0,0,0,.1)',
      borderTop: '1px solid rgba(0,0,0,.1)',
      lineHeight: '1.5rem',
      margin: '0 0 10px 0',
      '&:last-child': { borderBottom: 'none' },
    },
    mobileTableItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    mobileDoubleItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    nameItem: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      color: '#595959',
      fontWeight: 700,
    },
  }),
)

export const BeneficiariesListTablet: FC<{
  data: Maybe<BatchPaymentRecord>
}> = ({ data }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.tableRow}>
      <Box className={classes.mobileTableItem}>
        <Grid container className={classes.mobileDoubleItem}>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('name', 'Name')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.name} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('amount', 'Amount')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.amount} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('reference', 'Reference')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.reference} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('iban', 'IBAN')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.ibanAccountNumber} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('swift', 'SWIFT')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.swiftBic} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('processing', 'Processing')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.processing} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('payoutAmount', 'Payout amount')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.payoutAmount} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('usingLocalAch', 'Using Local ACH')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.usingLocalAch} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('fixedPayoutAmount', 'Fixed Payout Amount')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.fixedPayoutAmount} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>
              {t('bankClearingMemberId', 'Bank Clearing Member ID')}
            </Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.bankClearingMemberId} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('bankBranchId', 'Bank branch ID')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.bankBranchId} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('bankBranchName', 'Bank branch name')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.bankBranchName} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('purposeCode', 'Purpose Code')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.purposeCode} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('beneficiaryPhone', 'Bene. Phone')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.beneficiaryPhone} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('beneficiaryEmail', 'Bene. Email')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.beneficiaryEmail} />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.nameItem}>{t('chargeBearer', 'Charge Bearer')}</Box>
            <BeneficiariesListCell data={data} cellType={BeneficialCellType.chargeBearer} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
