/* eslint-disable i18next/no-literal-string */
import 'date-fns'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker, { CalendarContainer } from 'react-datepicker'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, useMediaQuery, useTheme } from '@material-ui/core'
import IconCalendar from '../../../assets/images/icons/calendar-dates.svg?react'
import { currentDateStatement } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    background: '#FFFFFF',
    color: '#fff',
    filter: 'drop-shadow(0px 3.5px 14px rgba(0, 0, 0, 0.2))',
    '& > div > div > div > div > div > div': {
      '&.react-datepicker__day--today, &.react-datepicker__day--keyboard-selected': {
        color: '#000000',
        background: 'none',
      },
      '&.react-datepicker__day--in-range, &.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range)': {
        borderRadius: '0.3rem',
        backgroundColor: '#216ba5',
        color: '#fff',
      },
    },
  },
  labelWrap: {
    display: 'block',
    marginBottom: 8,
  },
  label: {
    paddingLeft: 12,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#999999',
  },
  picker: {
    width: '100%',
    padding: '0 12px 11px 12px',
    border: 'none',
    borderBottom: '1px solid #CCCCCC',
    outline: 'none',
  },
  iconCalendar: {
    position: 'absolute',
    right: '38px',
    zIndex: 1,
    cursor: 'pointer',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    '& .MuiButton-root': {
      minWidth: 50,
    },
  },
  cancelBut: {
    padding: '8px 16px',
  },
  selectBut: {
    padding: '8px 16px',
    marginRight: 10,
  },
}))

export const DateRangePicker: FC<{
  dateRange?: Date | [Date | null, Date | null] | null
  setDateRange: (value: Date | [Date | null, Date | null] | null) => void
}> = ({ dateRange, setDateRange }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()

  const [startDate, endDate]: [Date | null, Date | null] = Array.isArray(dateRange)
    ? dateRange
    : [null, null]

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(425))

  const handleCancel = () => {
    setDateRange(currentDateStatement())
    setOpenDatePicker(false)
  }

  const handleSelect = () => {
    if (!JSON.stringify(dateRange).includes('null')) {
      setOpenDatePicker(false)
    }
  }

  useEffect(() => {
    setDateRange(currentDateStatement())
  }, [setDateRange])

  const CustomContainer: FC<{ className: string | undefined; children: ReactNode[] }> = ({
    className,
    children,
  }) => {
    return (
      <div className={classes.container}>
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>

        <Box className={classes.modalFooter}>
          <Button
            variant="contained"
            className={classes.selectBut}
            color="primary"
            onClick={handleSelect}
            disableElevation
            disabled={!endDate}
          >
            {t('select', 'Select')}
          </Button>
          <Button
            className={classes.cancelBut}
            variant="contained"
            onClick={handleCancel}
            disableElevation
          >
            {t('cancel', 'Cancel')}
          </Button>
        </Box>
      </div>
    )
  }

  return (
    <>
      <label htmlFor="rangeDateDownload" className={classes.labelWrap}>
        <span className={classes.label}>{t('SelectPeriod', 'Select Period')}</span>
        <span className={classes.iconCalendar}>
          <IconCalendar />
        </span>
      </label>
      <DatePicker
        onFocus={() => {
          setOpenDatePicker(true)
        }}
        className={classes.picker}
        open={openDatePicker}
        monthsShown={2}
        id={'rangeDateDownload'}
        selectsRange={true}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update)
        }}
        dateFormat={isSmallScreen ? 'MMM d, yyyy' : 'MMMM d, yyyy'}
        shouldCloseOnSelect={false}
        calendarContainer={CustomContainer}
        maxDate={new Date()}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [-18, -350],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'document',
              tether: false,
              altAxis: true,
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
            },
          },
        ]}
      />
    </>
  )
}
