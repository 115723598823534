import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { Typography, makeStyles } from '@material-ui/core'

import IconListRound from '../../../assets/images/icons/Icon_list_round.svg?react'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  info: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 12,
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '28px',
  },
  text: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '24px',
  },
}))

export const ActionsEmptyState: React.FC<{ isFiltered: boolean }> = ({ isFiltered }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.container}>
      <IconListRound />
      <Typography variant={'h2'} className={classes.info}>
        {isFiltered
          ? t('noActionsFound', 'No actions found')
          : t('noActionsRequired', 'No actions are required so far')}
      </Typography>
      {!isFiltered && (
        <Typography variant={'h2'} className={classes.text}>
          {t(
            'emailNotificationForAttention',
            'You will get an email notification once there is something for your attention.',
          )}
        </Typography>
      )}
    </Grid>
  )
}

export default ActionsEmptyState
