import React, { FC, useCallback, useState } from 'react'
import { IconButton, InputAdornment } from '@material-ui/core'
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { FormInputFieldEntity } from '../../../types'
import { useFormContext } from 'react-hook-form'
import { CommonTextField } from './CommonTextField'

export const FormPasswordField: FC<FormInputFieldEntity> = ({
  fieldIsDirty,
  name,
  label,
  helpText,
  placeholder,
  validations,
  ...rest
}) => {
  const { t } = useTranslation()
  const { register, errors } = useFormContext()
  const error = errors ? errors[name] : null
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const toggleShowPassword = useCallback(() => setShowPassword((prevState) => !prevState), [
    setShowPassword,
  ])

  return (
    <CommonTextField
      type={showPassword ? 'text' : 'password'}
      name={name}
      label={label}
      id={name}
      placeholder={placeholder}
      error={!!error}
      helperText={(error && t(error.message)) || helpText}
      inputProps={{
        'data-test': `autotest-${name}`,
        autoComplete: 'new-password',
      }}
      size="medium"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className={`${fieldIsDirty ? 'fade' : ''}`}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              // onMouseDown={toggleShowPassword}
              tabIndex={-1}
              data-test="autotest-showPassword"
            >
              {showPassword ? (
                <VisibilityOutlined color="action" />
              ) : (
                <VisibilityOffOutlined color="action" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputRef={register(validations)}
      {...rest}
    />
  )
}
