import { isUndefined } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { GridRow, FormControlledTextField } from '../../../components'

export const LimitedViewOnlyForm: FC<{ isPersonExist: boolean | undefined }> = ({
  isPersonExist,
}) => {
  const { t } = useTranslation()

  if (isUndefined(isPersonExist) || isPersonExist) return null

  return (
    <>
      <GridRow>
        <FormControlledTextField
          label={t('firstNames', 'First Name(s)')}
          name="firstName"
          type="text"
          fullWidth
          required={false}
          data-test="firstName"
        />
      </GridRow>
      <GridRow>
        <FormControlledTextField
          label={t('lastName', 'Last Name')}
          name="lastName"
          type="text"
          fullWidth
          required={false}
          data-test="lastName"
        />
      </GridRow>
    </>
  )
}
