import React, { FC, useCallback } from 'react'
import { Box, Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core'
import { isEmpty, isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Unchecked } from '../../../../components/Common/Unchecked'
import { Document } from '../../../../graphql'

import Checked from '../../../../assets/images/icons/cheked_icon.svg?react'
import EmptyDataPage from '../../../../components/Common/EmptyDataPage'
import IconReceivedDocuments from '../../../../assets/images/icons/receivedDocuments.svg?react'
import { receivedPageStateVar } from '../../../../graphql/local'
import { usePageFiltersSorting } from '../../../../hooks'
import { documentsAccordionsType } from '../../../../types'

import { CustomAccordionMobile } from './CustomAccordionMobile'
import ManagementListMobile from './ManagementListMobile'
import ManagementListTablet from './ManagementListTablet'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'

const useStyles = makeStyles(() => ({
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0 20px 0',
  },
  mobileCheckAll: {
    paddingLeft: 11,
    position: 'relative',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
      position: 'absolute',
      top: '50%',
      left: '50px',
      width: 200,
      transform: 'translateY(-50%)',
    },
    '& .MuiCheckbox-indeterminate svg': {
      marginLeft: -2,
      marginTop: -4,
    },
    '& .MuiCheckbox-root:not(.Mui-checked) .MuiIconButton-label svg > path': {
      fill: '#000',
    },
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
}))

const MobileReceivedDocTableComponent: FC<{
  receivedDocuments: Document[] | undefined
  pinnedDocuments: Document[] | undefined
  checkedAll: boolean
  setCheckedAll: React.Dispatch<React.SetStateAction<boolean>>
  checkedIdArray: Document[]
  setCheckedIdArray: React.Dispatch<React.SetStateAction<Document[]>>
  onMark: (isLoadDocumentId?: number) => void
  isSmallScreen?: boolean
  currentAccordion: documentsAccordionsType
  setCurrentAccordion: React.Dispatch<React.SetStateAction<documentsAccordionsType>>
  isFiltered: boolean
  disabled?: boolean
  canBeCheckedCount?: number
}> = ({
  receivedDocuments,
  pinnedDocuments,
  checkedAll,
  setCheckedAll,
  checkedIdArray,
  setCheckedIdArray,
  onMark,
  isSmallScreen,
  currentAccordion,
  setCurrentAccordion,
  canBeCheckedCount = 0,
  disabled,
  isFiltered,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { setPageFilters } = usePageFiltersSorting(receivedPageStateVar)

  const onCheckedAny = useCallback(
    (value, element) => {
      if (value) {
        setCheckedIdArray((prevState) => [...prevState, element])
      } else {
        setCheckedIdArray((prevState) => prevState.filter((el) => el.id !== element.id))
      }
    },
    [setCheckedIdArray],
  )

  const onChecked = useCallback(() => {
    setCheckedAll((prevState) => !prevState)
  }, [setCheckedAll])

  const resetAccordionTab = useCallback(() => {
    setPageFilters({ page: 1 })
    if (currentAccordion === documentsAccordionsType.pinnedDoc) {
      setCheckedAll(false)
    }
  }, [setCheckedAll, setPageFilters, currentAccordion])

  const docList = (value: Document) =>
    isSmallScreen ? (
      <ManagementListMobile
        data={value}
        key={value.id}
        checkedAll={checkedAll}
        onCheckedAny={onCheckedAny}
        checkedIdArray={checkedIdArray}
        onMark={onMark}
        disabled={disabled}
      />
    ) : (
      <ManagementListTablet
        data={value}
        key={value.id}
        checkedAll={checkedAll}
        onCheckedAny={onCheckedAny}
        checkedIdArray={checkedIdArray}
        onMark={onMark}
        disabled={disabled}
      />
    )

  return (
    <>
      <Box hidden={isEmpty(receivedDocuments)}>
        <Grid className={classes.selectAll}>
          <FormControlLabel
            className={classes.mobileCheckAll}
            control={
              <Checkbox
                checked={!!checkedIdArray.length}
                value={checkedAll}
                icon={<Unchecked />}
                checkedIcon={<Checked />}
                indeterminate={
                  !!checkedIdArray.length && canBeCheckedCount !== checkedIdArray.length
                }
                indeterminateIcon={<IndeterminateCheckBoxIcon color="primary" fontSize="small" />}
                onChange={onChecked}
                disabled={disabled}
                className={disabled ? classes.disabledCheckbox : ''}
              />
            }
            label={t('selectAllDocuments', 'Select all documents')}
          />
        </Grid>
      </Box>
      <Grid container>
        {!(isEmpty(receivedDocuments) && isEmpty(pinnedDocuments)) ? (
          <Grid item xs={12}>
            {!isEmpty(pinnedDocuments) && !isEmpty(receivedDocuments) && (
              <>
                <CustomAccordionMobile
                  title={t('PinnedDocuments', 'Pinned documents')}
                  expanded={currentAccordion === documentsAccordionsType.pinnedDoc}
                  setExpanded={setCurrentAccordion}
                  resetAccordionTab={resetAccordionTab}
                >
                  {pinnedDocuments?.map(docList)}
                </CustomAccordionMobile>

                <CustomAccordionMobile
                  title={t('everythingElse', 'Everything else')}
                  expanded={currentAccordion === documentsAccordionsType.everythingElse}
                  setExpanded={setCurrentAccordion}
                  resetAccordionTab={resetAccordionTab}
                >
                  {receivedDocuments?.map(docList)}
                </CustomAccordionMobile>
              </>
            )}

            {!isEmpty(pinnedDocuments) &&
              isEmpty(receivedDocuments) &&
              pinnedDocuments?.map(docList)}

            {!isEmpty(receivedDocuments) &&
              isEmpty(pinnedDocuments) &&
              receivedDocuments?.map(docList)}
          </Grid>
        ) : (
          <EmptyDataPage
            messageKey={
              isFiltered
                ? t('noReceivedDocumentsFound', 'No Received documents found')
                : t('noReceivedDocuments', 'No Received documents to display')
            }
            icon={IconReceivedDocuments}
          />
        )}
      </Grid>
    </>
  )
}

export const MobileReceivedDocTable = React.memo(MobileReceivedDocTableComponent, isEqual)
