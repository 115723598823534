import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { AmpInUse, CardPaymentsAndAmp, Maybe } from '../../../../graphql'
import { DoubleTextInput } from '../../../Common'
import { makeTooltipTitleTextDoubleTextInputApm, useCommonStyles } from '../helpers/functions'

const AlternativePaymentMethodsComponent: FC<{
  readOnly?: boolean
  changedContractProfileData?: CardPaymentsAndAmp | null
}> = ({ readOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = useCommonStyles()

  const tooltipDataMap: Record<string, Array<Maybe<AmpInUse>> | null | undefined> = {
    apmCurrently: changedContractProfileData?.ampCurrentlyInUse,
    apmRequired: changedContractProfileData?.ampRequiredCurrentlyInUse,
  }

  return (
    <Box mt={4}>
      <Typography variant="h5">
        {t('alternativePaymentMethodsAPM', 'Alternative payment methods (APM)')}
      </Typography>

      <Typography variant="h6" className={classes.titleSubBox}>
        {t('apmCurrentlyInUse', 'APM(s) currently in use')}:
      </Typography>

      <DoubleTextInput
        name={'apmCurrently'}
        labels={[t('apm', 'APM'), t('monthlyVolumeEur', 'Monthly volume in EUR')]}
        addBtnText={t('addApm', 'Add APM')}
        isViewOnly={readOnly}
        tooltipTitle={makeTooltipTitleTextDoubleTextInputApm('apmCurrently', tooltipDataMap, {
          divider: classes.lightDivider,
          listItem: classes.listItem,
        })}
        isOnboarding
      />

      <Typography variant="h6" className={classes.titleSubBox}>
        {t('requiredApm', 'Required APM(s)')}:
      </Typography>
      <DoubleTextInput
        name={'apmRequired'}
        labels={[t('apm', 'APM'), t('monthlyVolumeEur', 'Monthly volume in EUR')]}
        addBtnText={t('addApm', 'Add APM')}
        isViewOnly={readOnly}
        tooltipTitle={makeTooltipTitleTextDoubleTextInputApm('apmRequired', tooltipDataMap, {
          divider: classes.lightDivider,
          listItem: classes.listItem,
        })}
        isOnboarding
      />
    </Box>
  )
}

export const AlternativePaymentMethods = React.memo(AlternativePaymentMethodsComponent, isEqual)
