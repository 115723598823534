import React, { useCallback } from 'react'
import { isNull, toNumber } from 'lodash'
import { useParams } from 'react-router'
import { generatePath, useHistory } from 'react-router-dom'
import {
  ContractPaymentRecipient,
  Currency,
  DebitTransaction,
  FundsType,
  LimitedAccessRight,
  Maybe,
  TransactionEntryType,
  TransactionStatus,
  TransactionType,
  useGetTransactionDetailsLazyQuery,
} from '../../../../graphql'
import { currentStepVarPay } from '../../../../graphql/local/dashboard'
import { useDetermineUserRights, useIsLimitedContract } from '../../../../hooks'
import { APP_PATHS, PATH_PARAMS } from '../../../../routes/paths'
import { historyPaymentType } from '../../../../types'
import { SELECTED_ACCOUNT_ID } from '../../../../constants'

export const useRepeatPayment = (transaction: DebitTransaction) => {
  const history = useHistory()
  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.vbanId]: vbanId,
  } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  const [GetTransactionDetailsQuery] = useGetTransactionDetailsLazyQuery()

  const { isLimitedContract, loading } = useIsLimitedContract()

  const isOutgoingTrx = transaction?.entryType === TransactionEntryType.Debit
  const isCompleted = transaction?.status === TransactionStatus.Completed
  const isDepositGuaranteed = transaction?.account?.fundsType === FundsType.DepositGuaranteed
  const isCrypto = transaction?.type === TransactionType.TransferCrypto
  const isRepeatable =
    !loading &&
    !isViewOnly &&
    !vbanId &&
    isOutgoingTrx &&
    isCompleted &&
    !isLimitedContract &&
    !transaction.directDebitExecution &&
    !isDepositGuaranteed &&
    transaction?.type !== TransactionType.Fee &&
    transaction?.type !== TransactionType.NegativeBalanceCover &&
    (transaction?.type !== TransactionType.TransferInternal ||
      (transaction.isInterContract && transaction?.type === TransactionType.TransferInternal)) &&
    isNull(transaction.standingOrder) &&
    !isCrypto

  const handleRepeat = useCallback(
    async (e: React.SyntheticEvent) => {
      e.stopPropagation()
      if (!transaction) {
        return
      }

      const recipientIban = transaction.to?.iban

      const recipient: ContractPaymentRecipient = {
        __typename: 'ContractPaymentRecipient',
        id: 'repeatPayment',
        type: transaction.to?.type,
        firstName: transaction.to?.name,
        lastName: '',
        companyName: transaction.to?.name,
        iban: !recipientIban ? null : recipientIban,
        accountNo: recipientIban ? null : transaction.to?.accountNo,
        currency: transaction.payoutCurrency as Maybe<Currency> | undefined,
        bankBic: transaction.to?.bankBic,
        address: {
          __typename: 'Address',
          country: transaction.to?.address?.country,
          city: transaction.to?.address?.city,
          zip: transaction.to?.address?.zip,
          line1: transaction.to?.address?.line1,
        },
      }
      currentStepVarPay(0)

      const amountFromData = Math.abs(toNumber(transaction.value)).toFixed(2)?.toString()
      const payoutAmountFromData = transaction.payoutValue
        ? Math.abs(toNumber(transaction.payoutValue)).toFixed(2)?.toString()
        : transaction.payoutValue || undefined

      const locationState: historyPaymentType = {
        account: transaction.account?.id,
        amount: amountFromData,
        payoutAmount: payoutAmountFromData,
        clientReference: transaction.clientReference,
        reference: transaction.comment,
        recipient,
        chargeBearer: transaction.chargeBearer,
      }

      localStorage.setItem(SELECTED_ACCOUNT_ID, String(transaction.account?.id))

      applicationId &&
        history.push(generatePath(APP_PATHS.dashboard.newPayment, { applicationId }), locationState)
    },
    [history, applicationId, GetTransactionDetailsQuery],
  )

  return { handleRepeat, isRepeatable }
}
