import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { isEqual } from 'lodash'
import React, { FC, useCallback } from 'react'
import { documentsAccordionsType } from '../../../../types'

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  },
}))

type PropsType = {
  title: string
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<documentsAccordionsType>>
  resetAccordionTab: () => void
  children: React.ReactNode
}

const CustomAccordionMobileComponent: FC<PropsType> = ({
  title,
  expanded,
  setExpanded,
  resetAccordionTab,
  children,
}) => {
  const classes = useStyles()

  const onChange = useCallback(() => {
    setExpanded((prevState) =>
      prevState === documentsAccordionsType.pinnedDoc
        ? documentsAccordionsType.everythingElse
        : documentsAccordionsType.pinnedDoc,
    )
    resetAccordionTab()
  }, [])

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandLessIcon />} className={classes.summary}>
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export const CustomAccordionMobile = React.memo(CustomAccordionMobileComponent, isEqual)
