import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCommonStyles } from './helpers/functions'
import { credentialDetailsList, YesNoOptions } from '../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField, GridRow } from '../../Common'
import { CardProcessingRiskManagement } from '../../../graphql'
import { getLabelByKey } from '../../../utils'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 20,
  },
  dirtyControlText: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
}))
const RiskManagementComponent: FC<{
  isViewOnly?: boolean
  changedContractProfileData?: CardProcessingRiskManagement | null
}> = ({ isViewOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const { watch, formState, control } = useFormContext()

  const isAffliates = watch('selAffiliatesTraffic') === YesNoOptions[0].key
  const isUseCredentials = watch('selCredentialBillingMethod') === YesNoOptions[0].key

  const useAffiliateForGeneratingTrafficValue =
    changedContractProfileData?.useAffiliateForGeneratingTraffic?.toString() || undefined
  const useCredentialOnFilesBillingSalesMethodValue =
    changedContractProfileData?.useCredentialOnFilesBillingSalesMethod?.toString() || undefined

  const riskManagementDataMap: Record<string, typeof YesNoOptions[number]['key']> = {
    true: YesNoOptions[0].key,
    false: YesNoOptions[1].key,
  }

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5">{t('riskManagement', 'Risk Management')}</Typography>
      </Box>
      <GridRow>
        <Controller
          as={
            <FormAutocompleteSelect
              name="selAffiliatesTraffic"
              label={t(
                'useAffiliatesGeneratingTraf',
                'Do you use Affiliates for generating traffic?',
              )}
              className={classes.boxInput}
              data={YesNoOptions}
              readOnly={isViewOnly}
            />
          }
          name="selAffiliatesTraffic"
          control={control}
        />
        {useAffiliateForGeneratingTrafficValue && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              formState.errors.selAffiliatesTraffic ||
              watch('selAffiliatesTraffic') ===
                riskManagementDataMap[useAffiliateForGeneratingTrafficValue]
            }
            title={riskManagementDataMap[useAffiliateForGeneratingTrafficValue]}
          />
        )}
      </GridRow>
      {isAffliates && (
        <GridRow>
          <FormControlledTextField
            label={t('listAffiliates', 'Please list the Affiliates you use')}
            name="affiliatesList"
            type="text"
            fullWidth={true}
            multiline
            required={false}
            disabled={isViewOnly}
          />
          {changedContractProfileData?.affiliatesUsed && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControlText}
              hidden={formState.errors.affiliatesList}
              title={changedContractProfileData?.affiliatesUsed ?? ''}
              arrowHorizontalPosition={'left'}
            />
          )}
        </GridRow>
      )}

      <GridRow>
        <Controller
          as={
            <FormAutocompleteSelect
              name="selCredentialBillingMethod"
              label={t(
                'useCredentialBillingMethod',
                'Do you use a "Credential on File" (COF) billing sales method?',
              )}
              className={classes.boxInput}
              data={YesNoOptions}
              readOnly={isViewOnly}
            />
          }
          name="selCredentialBillingMethod"
          control={control}
        />
        {useCredentialOnFilesBillingSalesMethodValue && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              formState.errors.selCredentialBillingMethod ||
              watch('selCredentialBillingMethod') ===
                riskManagementDataMap[useCredentialOnFilesBillingSalesMethodValue]
            }
            title={riskManagementDataMap[useCredentialOnFilesBillingSalesMethodValue]}
          />
        )}
      </GridRow>
      {isUseCredentials && (
        <GridRow>
          <FormAutocompleteSelect
            name="credentialDetails"
            label={t('tickRelevant', 'Tick the relevant and supply the further details')}
            className={classes.boxInput}
            defaultValue={watch('credentialDetails') || (credentialDetailsList[0].key as string)}
            data={credentialDetailsList}
            readOnly={isViewOnly}
          />
          {changedContractProfileData?.relevantCredentialOnFilesBillingSalesMethod && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.credentialDetails}
              title={getLabelByKey(
                changedContractProfileData?.relevantCredentialOnFilesBillingSalesMethod,
                credentialDetailsList,
              )}
            />
          )}
        </GridRow>
      )}
      {isUseCredentials && (
        <GridRow>
          <FormControlledTextField
            label={t(
              'cofDetails',
              'Please provide details on how you use the COF billing sales method',
            )}
            name="credentialDetailsExplanation"
            type="text"
            fullWidth={true}
            multiline
            required={false}
            disabled={isViewOnly}
            data-test="cofDetails"
          />
          {changedContractProfileData?.credentialDetailsExplanation && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControlText}
              hidden={formState.errors.credentialDetailsExplanation}
              title={changedContractProfileData?.credentialDetailsExplanation ?? ''}
              arrowHorizontalPosition={'left'}
            />
          )}
        </GridRow>
      )}
    </>
  )
}
export const RiskManagement = React.memo(RiskManagementComponent)
