import React, { FC } from 'react'

export const Unchecked: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ color: 'white' }}
  >
    <rect x="0.5" y="0.5" width="15" height="15" fill="currentColor" stroke="currentColor" />
  </svg>
)
