import React, { FC } from 'react'
import { Box, Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import BatchIcon from '../../assets/images/icons/upload_up.svg?react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(4, 0, 3, 0),
    },
    btn: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 2, 0, 2),
      minHeight: 32,
      width: 105,
      fontSize: 14,
      margin: theme.spacing(0, 0, 0, 4.5),
      border: '1px solid rgba(0, 0, 0, 1)',
    },
    btnIcon: {
      width: 10,
      marginLeft: theme.spacing(0),
    },
    wrapLabel: {
      alignSelf: 'center',
      padding: theme.spacing(0, 2),
    },
    docsPanel: {
      display: 'flex',
    },
  }),
)

export const UploadDocBatch: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Grid container spacing={0} className={classes.docsPanel}>
        <Grid className={classes.wrapLabel}>
          <Typography variant="h4">{t('relatedDocuments', 'Related documents')}</Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.btn}
            variant="outlined"
            disableElevation
            color="default"
            //onClick={}
          >
            <BatchIcon className={classes.btnIcon} />
            {t('upload', 'Upload')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
