import React, { FC, useCallback, useState } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { isCPChangedAfterReviewVar } from '../../graphql/local'

const useStyles = makeStyles((theme: Theme) => ({
  // Modal
  modal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 32px)',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3),
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
  },
  modalBody: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .MuiButton-root': {
      minWidth: 50,
      '&:first-child': {
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
  },
  btnClose: {
    minWidth: 'auto',
    minHeight: 'auto',
  },
  bodyText: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
  },
  title: {
    fontWeight: 'bold',
  },
}))

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

type ModalPropsType = {
  setOpen: (value: boolean) => void
  isOpen: boolean
}

export const ControllingPersonsConfirmModal: FC<ModalPropsType> = ({ setOpen, isOpen }) => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const [modalStyle] = useState(getModalStyle)
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleConfirm = useCallback(async () => {
    setOpen(false)
    isCPChangedAfterReviewVar(false)
    history.push(generatePath(APP_PATHS.application.contacts.list, { applicationId }))
  }, [])

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={`cp-modal-title`}
      aria-describedby={`cp-modal-description`}
    >
      <Box style={modalStyle} className={classes.modal}>
        <Box className={classes.modalHeader}>
          <Typography variant={'h4'} className={classes.title}>
            {t('areYouSureYouWantToProceed', 'Are you sure you want to proceed?')}
          </Typography>
          <Button onClick={handleClose} className={classes.btnClose}>
            <CloseIcon fontSize={'small'} />
          </Button>
        </Box>
        <Box className={classes.modalBody}>
          <Typography className={classes.bodyText}>
            {t(
              'controllingPersonsConfirm',
              'You have added persons controlling less than 75% of the shares of an operating company. Please ensure you have added all the persons or legal entities that hold more than 25%.',
            )}
          </Typography>
        </Box>
        <Box className={classes.modalFooter}>
          <Button variant="contained" disableElevation color="default" onClick={handleClose}>
            {t('cancel', 'Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm} disableElevation>
            {t('addedAllNecessaryPersons', 'I have added all necessary persons')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
