import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Button, Grid } from '@material-ui/core'
import { millisecondsToSeconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import clsx from 'clsx'

import { ContactSupportLink, ResendButton } from '.'
import {
  isModalPromptOpen,
  resendDelay,
  isModalDialogOpen as isModalDialogOpenVar,
} from '../../graphql/local'
import {
  GetProfileChangesDocument,
  useConfirmOwnEmailMutation,
  useUpdateOwnEmailMutation,
} from '../../graphql'
import { InputCodeField } from './InputCode'
import { InputCodes } from './InputCode/type'
import { checkFullCode } from '../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    '& .iconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 26,
      '& > svg': {
        color: '#999',
      },
    },
    '& .digits': {
      width: 56,
      margin: 0,
      padding: theme.spacing(1, 0, 1),
      [theme.breakpoints.down('xs')]: {
        width: 40,
        padding: theme.spacing(4, 0, 1),
      },
      '& .MuiInputBase-root:hover:before': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-focused:after': {
        borderWidth: 1,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
      '& .MuiInputBase-input': {
        height: 80,
        padding: theme.spacing(2, 0),
        fontSize: '2.25rem',
        lineHeight: 1.13,
        textAlign: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
          height: 56,
          fontSize: '1.5rem',
        },
      },
    },
    '& .infoText': {
      fontSize: '0.75rem',
    },
    '&.success': {
      '& .iconContainer > svg': {
        color: theme.palette.success.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.success.main,
        },
      },
      '& .infoText': {
        color: theme.palette.success.main,
      },
    },
    '&.error': {
      '& .iconContainer > svg': {
        color: theme.palette.error.main,
      },
      '& .digits': {
        '& .MuiInputBase-root:before': {
          borderColor: theme.palette.error.main,
        },
      },
      '& .infoText': {
        color: theme.palette.error.main,
      },
    },
  },
  mrgTop: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  btn_resend: {
    fontSize: theme.spacing(2.25),
    height: 48,
    maxWidth: '100%',
    backgroundColor: 'black',
    color: 'white',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
}))

export const AuthorizeField: FC<{
  onSubmit?: React.Dispatch<React.SetStateAction<void>> | undefined
  email: string
}> = ({ onSubmit, email }) => {
  const [confirmOwnEmailMutation, { error: errorConfirmOwnEmail }] = useConfirmOwnEmailMutation()
  const [updateOwnEmailMutation, { error: updateOwnEmailError }] = useUpdateOwnEmailMutation()
  const classes = useStyles()
  const { t } = useTranslation()

  const initialValue = { d1: '', d2: '', d3: '', d4: '', d5: '', d6: '' }
  const [code, setCode] = useState<InputCodes>(initialValue)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [attemptsSend, setAttemptsSend] = useState(0)
  const [btnDisable, setBtnDisable] = useState(true)
  const [progress, setProgress] = useState(0)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [validMessage, setValidMessage] = useState<string>('')

  const startInput = useRef<HTMLInputElement>(null)
  const enteredCode = Object.values(code).join('')
  const [successClose, setSuccessClose] = useState(false)
  const resultCheckFullCode = checkFullCode(code)

  const onReSend = useCallback(async () => {
    setBtnDisable(true)
    setProgress(0)
    setSecondsLeft(millisecondsToSeconds(resendDelay()))
    await updateOwnEmailMutation({
      variables: { email },
      refetchQueries: [
        {
          query: GetProfileChangesDocument,
        },
      ],
      awaitRefetchQueries: true,
    }).catch((e) => {
      toast.error((e as Error).message)
    })
  }, [setBtnDisable, setProgress, updateOwnEmailMutation, email])

  const sendCode = useCallback(async () => {
    if (enteredCode.length === 6) {
      setLoading(true)
      try {
        const response = await confirmOwnEmailMutation({
          variables: { token: enteredCode },
          refetchQueries: [
            {
              query: GetProfileChangesDocument,
            },
          ],
          awaitRefetchQueries: true,
        })

        if (response.data?.confirmOwnEmail) {
          setIsError(false)
          setValidMessage(t('codeCorrect', 'Code is correct'))
          setLoading(false)
          setSuccessClose(true)
        }
      } catch (e) {
        setIsError(true)
        if (e.response?.data?.tokenExpired || e.response?.data?.code === 'EXPIRED_2FA') {
          setValidMessage(t('codeHasExpired', 'Code has expired'))
        } else {
          setValidMessage(t('codeIncorrect', 'Code is incorrect'))
        }
        setLoading(false)
        if (null !== startInput.current) {
          startInput.current.focus()
        }
      }
    }
  }, [code])

  const renderButton = useMemo(() => {
    if (validMessage && !isError) {
      return null
    } else {
      return attemptsSend > 5 ? (
        <ContactSupportLink homeToLink={false}>
          <Button
            className={classes.btn_resend}
            size="large"
            variant="contained"
            disableElevation
            color="primary"
            fullWidth
            data-test="resendBtn"
          >
            {t('contactSupport', 'Contact support')}
          </Button>
        </ContactSupportLink>
      ) : (
        <ResendButton
          className={classes.btn_resend}
          fullWidth
          size="large"
          variant="contained"
          disableElevation={true}
          disabled={btnDisable}
          onClick={onReSend}
          progress={progress}
          secondsLeft={secondsLeft}
        >
          {t('resendCode', 'Resend code')}
        </ResendButton>
      )
    }
  }, [validMessage, isError, progress, secondsLeft, attemptsSend, btnDisable, onReSend])

  useEffect(() => {
    if (code.d6) {
      sendCode()
      setAttemptsSend((prev) => prev + 1)
    }
  }, [code, setAttemptsSend])

  useEffect(() => {
    const millisecondToSecond = millisecondsToSeconds(resendDelay())
    setSecondsLeft(millisecondToSecond)
    const oneTimeAction = setTimeout(() => {
      setBtnDisable(false)
    }, resendDelay())

    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1))
      setSecondsLeft((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () => {
      clearInterval(timer)
      clearTimeout(oneTimeAction)
    }
  }, [btnDisable])

  useEffect(() => {
    if (successClose) {
      const timerId = setTimeout(() => {
        onSubmit && onSubmit()
      }, 1500)
      return () => {
        isModalPromptOpen(false)
        isModalDialogOpenVar(false)
        clearTimeout(timerId)
        // refreshPage()
      }
    }
  }, [successClose])

  const handleSetCode = (id: string, value: string) => {
    setCode((prevState) => {
      return {
        ...prevState,
        [id]: value,
      }
    })
  }

  useEffect(() => {
    const errCode = errorConfirmOwnEmail?.graphQLErrors[0]?.extensions?.code

    if (errorConfirmOwnEmail && errCode !== 'invalidToken') {
      toast.error(t('errorSavingData', 'Error saving data'))
      toast.error(errorConfirmOwnEmail.message)
    } else if (updateOwnEmailError) {
      toast.error(t('errorSavingData', 'Error saving data'))
      toast.error(updateOwnEmailError.message)
    }
  }, [errorConfirmOwnEmail, updateOwnEmailError, t])

  useEffect(() => {
    if (!resultCheckFullCode) {
      setValidMessage('')
      setIsError(false)
    }
  }, [resultCheckFullCode])

  return (
    <Box
      className={clsx(
        classes.paper,
        resultCheckFullCode && isError && 'error',
        validMessage && !isError && 'success',
      )}
    >
      <Grid container item justifyContent="center" className="infoText">
        {!!validMessage && resultCheckFullCode && <span>{validMessage}</span>}
      </Grid>
      <InputCodeField
        startInput={startInput}
        currencyValue={code}
        setCode={handleSetCode}
        loading={loading}
      />
      <Grid item xs={12} className={classes.mrgTop}>
        {renderButton}
      </Grid>
    </Box>
  )
}
