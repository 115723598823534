import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Box, Typography } from '@material-ui/core'
import { isEqual } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CardProcessingRiskManagement } from '../../../../graphql'
import { NoYes } from '../../../../types'
import { getLabelByKey } from '../../../../utils'
import { credentialDetailsList, YesNoOptions } from '../../../../utils/Data'
import { FormAutocompleteSelect, FormControlledTextField } from '../../../Common'
import ControlledTooltipWrapped from '../../../Common/Tooltips/ControlledTooltipWrapped'
import { useCommonStyles } from '../helpers/functions'
import { YesNoSelector } from '../YesNoSelector'

const useStyles = makeStyles((theme: Theme) => ({
  moreBottomSpace: {
    marginBottom: theme.spacing(1.5),
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 20,
  },
  dirtyControlText: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
}))

const AdditionalRegulatoryQuestionsComponent: FC<{
  isViewOnly?: boolean
  changedContractProfileData?: CardProcessingRiskManagement | null
}> = ({ isViewOnly, changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }
  const { watch, formState, control } = useFormContext()

  const isAffliates = watch('selAffiliatesTraffic') === YesNoOptions[0].key
  const isUseCredentials = watch('selCredentialBillingMethod') === YesNoOptions[0].key

  const useAffiliateForGeneratingTrafficValue =
    changedContractProfileData?.useAffiliateForGeneratingTraffic?.toString() || undefined
  const useCredentialOnFilesBillingSalesMethodValue =
    changedContractProfileData?.useCredentialOnFilesBillingSalesMethod?.toString() || undefined

  const riskManagementDataMap: Record<string, typeof YesNoOptions[number]['key']> = {
    true: YesNoOptions[0].key,
    false: YesNoOptions[1].key,
  }

  return (
    <Box mt={4}>
      <Typography variant="h5">
        {t('additionalRegulatoryQuestions', 'Additional Regulatory Questions')}
      </Typography>

      <Box>
        <YesNoSelector
          name="selAffiliatesTraffic"
          disabled={isViewOnly}
          label={t('useAffiliatesGeneratingTraf', 'Do you use Affiliates for generating traffic?')}
          yesOption={NoYes.yes}
          noOption={NoYes.no}
          mt={3}
        />
        {useAffiliateForGeneratingTrafficValue && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              formState.errors.selAffiliatesTraffic ||
              watch('selAffiliatesTraffic') ===
                riskManagementDataMap[useAffiliateForGeneratingTrafficValue]
            }
            title={riskManagementDataMap[useAffiliateForGeneratingTrafficValue]}
          />
        )}
      </Box>
      {isAffliates && (
        <Box>
          <FormControlledTextField
            label={t('listAffiliates', 'Please list the Affiliates you use')}
            name="affiliatesList"
            type="text"
            fullWidth={true}
            multiline
            required={false}
            disabled={isViewOnly}
            className={classes.whiteBackground}
          />
          {changedContractProfileData?.affiliatesUsed && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControlText}
              hidden={formState.errors.affiliatesList}
              title={changedContractProfileData?.affiliatesUsed ?? ''}
              arrowHorizontalPosition={'left'}
            />
          )}
        </Box>
      )}

      <Box>
        <YesNoSelector
          name="selCredentialBillingMethod"
          disabled={isViewOnly}
          label={t(
            'useCredentialBillingMethod',
            'Do you use a "Credential on File" (COF) billing sales method?',
          )}
          yesOption={NoYes.yes}
          noOption={NoYes.no}
          mt={2}
        />
        {useCredentialOnFilesBillingSalesMethodValue && (
          <ControlledTooltipWrapped
            wrapperClass={classes.dirtyControl}
            hidden={
              formState.errors.selCredentialBillingMethod ||
              watch('selCredentialBillingMethod') ===
                riskManagementDataMap[useCredentialOnFilesBillingSalesMethodValue]
            }
            title={riskManagementDataMap[useCredentialOnFilesBillingSalesMethodValue]}
          />
        )}
      </Box>
      {isUseCredentials && (
        <Box>
          <Controller
            as={
              <FormAutocompleteSelect
                name="credentialDetails"
                label={t('tickRelevant', 'Tick the relevant and supply the further details')}
                className={clsx(classes.boxInput, classes.whiteBackground)}
                defaultValue={
                  watch('credentialDetails') || (credentialDetailsList[0].key as string)
                }
                data={credentialDetailsList}
                readOnly={isViewOnly}
              />
            }
            name="credentialDetails"
            control={control}
          />
          {changedContractProfileData?.relevantCredentialOnFilesBillingSalesMethod && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControl}
              hidden={formState.errors.credentialDetails}
              title={getLabelByKey(
                changedContractProfileData?.relevantCredentialOnFilesBillingSalesMethod,
                credentialDetailsList,
              )}
            />
          )}
        </Box>
      )}
      {isUseCredentials && (
        <Box>
          <Typography variant="h6" className={classes.titleSubBox}>
            {t(
              'pleaseProvideDetailsOnHowDoYouUseCofBillingMethod',
              'Please provide details on how do you use COF billing method',
            )}
          </Typography>
          <FormControlledTextField
            label={t('details', 'Details')}
            name="credentialDetailsExplanation"
            type="text"
            fullWidth={true}
            multiline
            required={false}
            disabled={isViewOnly}
            data-test="cofDetails"
            className={classes.whiteBackground}
          />
          {changedContractProfileData?.credentialDetailsExplanation && (
            <ControlledTooltipWrapped
              wrapperClass={classes.dirtyControlText}
              hidden={formState.errors.credentialDetailsExplanation}
              title={changedContractProfileData?.credentialDetailsExplanation ?? ''}
              arrowHorizontalPosition={'left'}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export const AdditionalRegulatoryQuestions = React.memo(
  AdditionalRegulatoryQuestionsComponent,
  isEqual,
)
