import { Box, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import DoneIcon from '@material-ui/icons/Done'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'

const useStyles = makeStyles((theme) => ({
  root: {
    gap: 10,
    width: 'max-content',
  },
  progressRoot: {
    position: 'relative',
    backgroundColor: '#fff',
  },
  shadow: {
    color: '#ddd',
    position: 'absolute',
    left: 0,
    zIndex: 1,
    // strokeLinecap: 'round',
  },
  futureLabel: {
    color: '#aaa',
  },
  top: {
    position: 'relative',
    zIndex: 2,
    transform: 'rotate(90deg) scale(-1, 1) !important',
    // strokeLinecap: 'round',
  },
  done: {
    '& circle': {
      fill: 'red',
    },
  },
  doneIcon: {
    color: 'white',
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3,
    fontSize: 14,
    stroke: 'white',
    '& path': {
      'stroke-width': 2,
    },
  },
  exclamation: {
    color: 'red',
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -45%)',
    zIndex: 3,
    fontSize: 14,
  },
  vLine: {
    position: 'relative',
    gap: 20,
    '&::before': {
      position: 'absolute',
      content: '""',
      height: '100%',
      left: '10px',
      borderLeft: `2px dashed ${theme.palette.secondary.main}`,
    },
  },
}))

type ProgressStepProps = {
  label: string
  stage: 'done' | 'future' | number
  error?: boolean
}

export const ProgressStep: React.FC<ProgressStepProps> = ({ label, stage, error }) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" className={classes.root}>
      <Box className={classes.progressRoot}>
        {(typeof stage === 'number' || stage === 'future') && (
          <CircularProgress
            variant="determinate"
            value={100}
            size={22}
            className={classes.shadow}
            thickness={6}
          />
        )}
        <CircularProgress
          variant="determinate"
          value={typeof stage === 'number' ? stage * 100 : stage === 'done' ? 100 : 0}
          size={22}
          className={clsx(classes.top, stage === 'done' ? classes.done : '')}
          color="secondary"
          thickness={6}
        />
        {stage === 'done' && <DoneIcon className={classes.doneIcon} />}
        {error && <PriorityHighIcon className={classes.exclamation} />}
      </Box>
      <Typography className={stage === 'future' ? classes.futureLabel : undefined}>
        {label}
      </Typography>
    </Grid>
  )
}

export const Progress: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Grid container direction="column" className={classes.vLine}>
      {children}
    </Grid>
  )
}
