import React, { FC, useCallback, useMemo } from 'react'
import { Box, makeStyles, MenuItem, TableCell, TableRow, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ThreeDotMenu } from '../../../components'
import { authorizedPersonStatus } from '../../../constants'
import { ContractAuthorizedPersonType } from '../../../graphql'
import { dateFormat, rightsTeamRole } from '../../../utils'
import { TeamMenuOption, TeamTableRowProps } from '../../../types'

const useStyles = makeStyles(() => ({
  backGround: {
    backgroundColor: 'transparent',
    '&.checked': {
      backgroundColor: '#ffffff',
    },
  },
  tableRow: {
    border: 'none',
  },
  name: {
    width: 250,
    // textDecoration: 'underline',
  },
  role: {
    overflow: 'hidden',
    whiteSpace: 'normal',
    width: 180,
  },
  date: {
    width: 160,
  },
  status: {
    width: 140,
  },
  contentWidth: {
    minWidth: '180px',
    width: '1.5rem',
  },
}))

const TeamTableListRowComponent: FC<TeamTableRowProps> = ({
  isShouldDisabled,
  teammate,
  teamMenuOptions,
  isViewOnly,
  isMyself,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    id,
    status,
    isAuthorizedSignatory,
    limitedAccessRight,
    signatoryRight,
    groupSize,
    person,
    invitationDate,
  } = teammate as ContractAuthorizedPersonType

  const menuOptions = useMemo(() => {
    const filteredArr = Object.values(omit(teamMenuOptions, status as string)) as TeamMenuOption[]
    return filteredArr.map((option: TeamMenuOption) => option.label)
  }, [teamMenuOptions])

  const handleMenu = useCallback(() => {
    if (!status || !(status in teamMenuOptions)) return
    const option = teamMenuOptions[status]

    if (option && id) {
      option.handler(id)
    }
  }, [status, teamMenuOptions, id])

  return (
    <TableRow className={clsx(classes.backGround, classes.tableRow)} data-test="teammateItem">
      <TableCell>
        <Box className={classes.name}>
          {person?.firstName}
          {person?.lastName && ` ${person?.lastName}`}
        </Box>
      </TableCell>
      <TableCell>
        <Box className={classes.role}>
          {isAuthorizedSignatory
            ? rightsTeamRole(signatoryRight as string, groupSize ?? 0)
            : rightsTeamRole(limitedAccessRight as string)}
        </Box>
      </TableCell>
      <TableCell className={classes.contentWidth} data-test="invitedDate">
        <Box className={classes.date}>
          {invitationDate ? dateFormat(invitationDate) : t('never', 'Never')}
        </Box>
      </TableCell>
      <TableCell className={classes.contentWidth}>
        <Box className={classes.date}>
          {person?.lastSeenAt ? dateFormat(person?.lastSeenAt) : t('never', 'Never')}
        </Box>
      </TableCell>
      <TableCell>
        <Box className={classes.status}>
          <Box
            component="span"
            className={clsx(`roundedPill ${authorizedPersonStatus[status as string]}`)}
          >
            {t(authorizedPersonStatus[status as string])}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        {!isViewOnly && (
          <ThreeDotMenu disabled={isShouldDisabled}>
            {menuOptions.map((option, index) =>
              isMyself && option === 'Deactivate' ? (
                <Tooltip
                  key={index}
                  title={t('cantDeactYourslf', 'You cannot deactivate yourself')}
                >
                  <Box>
                    <MenuItem key={index} onClick={handleMenu} disabled>
                      {t(option)}
                    </MenuItem>
                  </Box>
                </Tooltip>
              ) : (
                <MenuItem key={index} onClick={handleMenu}>
                  {t(option)}
                </MenuItem>
              ),
            )}
          </ThreeDotMenu>
        )}
      </TableCell>
    </TableRow>
  )
}

export const TeamTableListRow = React.memo(TeamTableListRowComponent, isEqual)
