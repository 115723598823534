import React, { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react'
import {
  Box,
  createStyles,
  makeStyles,
  TableContainer,
  Hidden,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
  Collapse,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useRowPerPage } from '../../hooks'
import { BeneficiariesListRow } from '../../pages/AplicationActions'
import { BeneficiariesListTablet } from '../../pages/AplicationActions'
import {
  BatchPaymentRecord,
  Maybe,
  useGetBatchPaymentRecordsQuery,
  useGetBatchRecordsCountQuery,
} from '../../graphql'
import { ClosedInfoBlock } from './ClosedInfoBlock'
import PagePagination from '../Common/PagePagination'
import { PaymentContext } from './PaymentContextComponent'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      margin: '32px 0 32px 0',
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 16px 10px 16px',
        borderRadius: 4,
        background: '#F0F0F0',
        cursor: 'pointer',
        '&.open': {
          borderRadius: '4px 4px 0 0',
        },
        '& .MuiTypography-paragraph': {
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          fontWeight: '700',
          color: '#434343',
          margin: 0,
        },
      },
    },
    root: {
      border: '1px solid #e5e5e5',
      borderBottom: '1px solid #e5e5e5',
      '&::-webkit-scrollbar': {
        width: '0.5em',
        height: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
      '&.signatoryScroll': {
        overflowY: 'auto',
      },
    },
    table: {
      border: 'none',
    },
    tableBody: {
      '& .MuiTableRow-root': {
        height: '48px',
      },
      '& .MuiTableRow-root:last-child': {
        '& .MuiTableCell-root': { border: 'none' },
      },
    },
  }),
)

export const BeneficiariesListBatch: FC<{
  data?: Maybe<BatchPaymentRecord>[]
  batchId?: Maybe<string | number> | undefined
  isFrontPaginated?: boolean
}> = ({ data, batchId, isFrontPaginated }) => {
  const { batchListPage, setBatchListPage, tableStatus, setTableStatus } = useContext(
    PaymentContext,
  )

  const { t } = useTranslation()
  const classes = useStyles()

  const { itemsPerPage } = useRowPerPage()

  const { data: RecordsCount } = useGetBatchRecordsCountQuery({
    variables: {
      batchPaymentId: batchId ?? '',
    },
    skip: !batchId,
  })
  const { data: paymentRecords, loading } = useGetBatchPaymentRecordsQuery({
    variables: {
      batchPaymentId: batchId ?? '',
      limit: itemsPerPage,
      offset: (batchListPage - 1) * itemsPerPage,
    },
    skip: !batchId,
  })

  const toggleList = useCallback(() => {
    setTableStatus((prevState) => !prevState)
  }, [tableStatus])

  const slicedData = useMemo(() => {
    return isFrontPaginated
      ? data?.slice((batchListPage - 1) * itemsPerPage, batchListPage * itemsPerPage)
      : paymentRecords?.batchPaymentRecords
  }, [batchListPage, isFrontPaginated, paymentRecords])

  const onPaginationChange = (_: ChangeEvent<unknown>, page: number) => {
    setBatchListPage(page)
  }

  return (
    <Box className={classes.wrapper}>
      <ClosedInfoBlock handleToggle={toggleList} tableOpen={tableStatus}>
        <Typography paragraph>{t('payments', 'Payments')}</Typography>
      </ClosedInfoBlock>

      {!loading && (
        <Collapse in={tableStatus}>
          <Hidden mdDown>
            <TableContainer className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('amount', 'Amount')}</TableCell>
                    <TableCell>{t('name', 'Name')}</TableCell>
                    <TableCell>{t('ibanAccountNumber', 'IBAN / Acc. No.')}</TableCell>
                    <TableCell>{t('swiftBic', 'SWIFT / BIC')}</TableCell>
                    <TableCell>{t('processing', 'Processing')}</TableCell>
                    <TableCell>{t('reference', 'Reference')}</TableCell>
                    <TableCell>{t('payoutAmount', 'Payout amount')}</TableCell>
                    <TableCell>{t('usingLocalAch', 'Using Local ACH')}</TableCell>
                    <TableCell>{t('fixedPayoutAmount', 'Fixed Payout Amount')}</TableCell>
                    <TableCell>{t('bankClearingMemberId', 'Bank Clearing Member ID')}</TableCell>
                    <TableCell>{t('bankBranchId', 'Bank branch ID')}</TableCell>
                    <TableCell>{t('bankBranchName', 'Bank branch name')}</TableCell>
                    <TableCell>{t('purposeCode', 'Purpose Code')}</TableCell>
                    <TableCell>{t('beneficiaryPhone', 'Bene. Phone')}</TableCell>
                    <TableCell>{t('beneficiaryEmail', 'Bene. Email')}</TableCell>
                    <TableCell>{t('chargeBearer', 'Charge Bearer')}</TableCell>
                    {/*<TableCell>{t('status', 'Status ')}</TableCell>*/}
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                  {slicedData &&
                    slicedData.map((value, i) => {
                      return (
                        <BeneficiariesListRow
                          data={value}
                          key={`${value?.beneficiaryAccountId}${value?.amount}${value?.id}${i}`}
                        />
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>

          <Hidden lgUp>
            <Box className={classes.root}>
              {slicedData &&
                slicedData.map((value) => {
                  return (
                    <BeneficiariesListTablet
                      data={value}
                      key={`${value?.beneficiaryAccountId}${value?.amount}${value?.id}`}
                    />
                  )
                })}
            </Box>
          </Hidden>

          <PagePagination
            page={batchListPage}
            totalItems={isFrontPaginated ? data?.length : RecordsCount?.batchPaymentRecordsCount}
            handleChange={onPaginationChange}
          />
        </Collapse>
      )}
    </Box>
  )
}
