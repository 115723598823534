import React from 'react'
import { generatePath, Redirect } from 'react-router-dom'
import { CURRENT_USER_ID } from '../../constants'

import { useGetUserDataQuery, Individual, GetUserDataQuery } from '../../graphql'
import { CurrentUserContext } from '../../hooks'
import { SearchName } from '../../types'
import { APP_PATHS, PATH_PARAMS } from '../paths'
import { SplashScreen } from '../../components'

export const AuthGuard: React.FC = ({ children }) => {
  const handleSearchList = (userData: GetUserDataQuery) => {
    const userId = (userData.viewer as Individual).id
    const prevUserId = Number(localStorage.getItem(CURRENT_USER_ID))

    if (prevUserId !== userId) {
      Object.keys(SearchName).forEach((item) =>
        localStorage.removeItem(SearchName[item as keyof typeof SearchName]),
      )
      localStorage.setItem(CURRENT_USER_ID, JSON.stringify(userId))
    }
  }

  const { data, loading, error } = useGetUserDataQuery({
    onCompleted: handleSearchList,
  })

  if (loading) return <SplashScreen />

  // eslint-disable-next-line no-underscore-dangle
  if (error || data?.viewer?.__typename !== 'Individual') {
    return <Redirect to={APP_PATHS.auth.login} />
  }

  return (
    <CurrentUserContext.Provider value={data.viewer as Individual}>
      {!data.viewer.emailConfirmed && data.viewer.email && (
        <Redirect
          to={generatePath(APP_PATHS.confirmEmail, {
            [PATH_PARAMS.token]: encodeURIComponent(data.viewer.email),
          })}
        />
      )}
      {children}
    </CurrentUserContext.Provider>
  )
}

export default AuthGuard
