import { isValid, parse } from 'date-fns'
import { convertToLocalTime } from 'date-fns-timezone'
import * as DateFns from 'date-fns'

export const DEFAULT_OUTPUT_DATE_FORMAT = 'yyyy-MM-dd'

export const DEFAULT_VIEW_DATE_FORMAT = 'dd/MM/yyyy'

export const isDateStringValid = (
  dateString: string,
  format = DEFAULT_OUTPUT_DATE_FORMAT,
): boolean => {
  try {
    return isValid(parse(dateString, format, new Date()))
  } catch {
    return false
  }
}

export const formatDate = (date: Date | null, isShift: boolean): string => {
  try {
    if (!date) return new Date().toDateString()

    if (date instanceof Date === false) return 'Invalid Date'
    let dateToFormat = date

    if (isShift) {
      // Get the timezone from browser using native methods
      let timezone = Intl.DateTimeFormat('kk-KZ').resolvedOptions().timeZone
      //There was an official rename of Kiev to Kyiv in August 2022 in the time zone database,
      // but this change hasn't propagated to old Android versions
      if (timezone === 'Europe/Kyiv') {
        timezone = 'Europe/Kiev'
      }
      const dateTmp = Date.parse(date.toDateString())
      const localDate = convertToLocalTime(dateTmp, {
        timeZone: timezone,
      })
      dateToFormat = localDate
    }
    return DateFns.format(dateToFormat, DEFAULT_OUTPUT_DATE_FORMAT)
  } catch (err) {
    return 'Invalid Date'
  }
}
