import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Collapse, Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { head, isEmpty, isEqual, omit } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import InfoIcon from '../../../assets/images/icons/info_icon.svg?react'
import {
  AddressAutocomplete,
  AddressPropsType,
  AlertTipItem,
  CountryPhoneSelect,
  GridRow,
  HandleAwayFromPage,
  Loader,
  LoadingButton,
  isBatchPaymentFile,
  isDirtyForm,
  Toggler,
} from '../../../components'
import { ChangesConfirmationModal } from '../../../components/Common/ChangesConfirmationModal'
import ControlledTooltipWrapped from '../../../components/Common/Tooltips/ControlledTooltipWrapped'
import { WebsiteInput } from '../../../components/Common/WebsiteInput'
import { CompanyInformation } from '../../../components/NewBusinessApp'
import { BusinessDetailsStepper } from '../../../components/NewBusinessApp/PreAssessmentQuestionnaire/BusinessDetailsStepper/BusinessDetailsStepper'
import { EconomicProfile } from '../../../components/NewBusinessApp/PreAssessmentQuestionnaire/EconomicProfile/EconomicProfile'
import { makeTooltipTitleTextWebsites } from '../../../components/NewBusinessApp/PreAssessmentQuestionnaire/helpers/functions'
import { ParagraphStyling } from '../../../components/NewBusinessApp/PreAssessmentQuestionnaire/ParagraphStyling'
import { PaymentProcessing } from '../../../components/NewBusinessApp/PreAssessmentQuestionnaire/PaymentProcessing/PaymentProcessing'
import { ProductInformation } from '../../../components/NewBusinessApp/PreAssessmentQuestionnaire/ProductInformation'
import { RegulatoryQuestions } from '../../../components/NewBusinessApp/PreAssessmentQuestionnaire/RegulatoryQuestions'
import config from '../../../config'
import {
  ContractAuthorizedPersonType,
  ContractProfileChangeAnswers,
  LegalEntity,
  ProfileChange,
  ProfileChangeStatus,
  useGetContractDetailsQuery,
  useGetContractProfileChangesQuery,
  useGetcontractProfileChangesCountQuery,
} from '../../../graphql'
import { PATH_PARAMS } from '../../../routes/paths'
import { PreAssessmentOnboardingInput } from '../../../schemes'
import { ManagementBusinessDetailsInputSchema } from '../../../schemes/ManagementBusinessDetails'
import { AlertDataType, CardPaymentsSelectOptions, locationStateType } from '../../../types'
import {
  focusKeyPressNext,
  parsePreAssessmentFormData,
  scrollToInputElement,
  showError,
} from '../../../utils'

const useStyles = makeStyles<Theme, { disabled: boolean | undefined }>((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  title: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  formWrap: {
    border: 'none',
  },
  form: {
    maxWidth: theme.spacing(86),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
    },
  },
  button: {
    width: '153px',
    height: 48,
    margin: theme.spacing(3.5, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: 'rgb(245 245 245)',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
  dirtyControl: {
    position: 'relative',
  },
  dirtySpaceTop: {
    '& .MuiFormHelperText-root': {
      bottom: -3,
    },
  },
  checkbox: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    outline: 'none',
  },
  mb2: {
    marginBottom: theme.spacing(2.5),
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '535px',
    position: 'fixed',
    top: 8,
    zIndex: 1100,
    left: 0,
    right: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(0, 60px)',
      maxWidth: '90%',
    },
    '&>div': {
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  webSite: {
    '&>label': {
      [theme.breakpoints.down('xs')]: {
        transform: 'translate(0, 0px) scale(0.857)',
      },
    },
  },
  boxMultiSelect: {
    margin: ({ disabled }) => (disabled ? '16px 0 20px 0' : '10px 0 10px 0'),
    display: 'flex',
    backgroundColor: ({ disabled }) => (disabled ? '#f5f5f5' : 'none'),
    '& .MuiAutocomplete-inputRoot': {
      minHeight: 48,
      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 20px)',
      },
    },
  },
  boxSelect: {
    paddingTop: ({ disabled }) => (disabled ? '12px' : 'initial'),
    backgroundColor: ({ disabled }) => (disabled ? '#f5f5f5' : 'none'),
    margin: '20px 0 20px 0',
    display: 'flex',
  },
  boxOneItemSelect: {
    paddingTop: ({ disabled }) => (disabled ? '12px' : 'initial'),
    backgroundColor: ({ disabled }) => (disabled ? '#f5f5f5' : 'none'),
    margin: ({ disabled }) => (disabled ? '14px 0 14px 0' : '10px 0 10px 0'),
    display: 'flex',
  },
  checkYourBusiness: {
    marginTop: '25px',
  },
  boxNumeric: {
    margin: '14px 0 14px 0',
    [theme.breakpoints.down('xs')]: {
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 0px) scale(0.857)',
      },
    },
  },
  whiteBackground: {
    backgroundColor: theme.palette.background.paper,
  },
}))

export const BusinessDetailsForm: FC<{ isViewOnly: boolean }> = ({ isViewOnly }) => {
  const classes = useStyles({ disabled: isViewOnly })
  const { t } = useTranslation()
  const history = useHistory()
  const missingInfoId = (history.location.state as locationStateType)?.missingInfoId ?? ''
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState<Record<string, unknown>>()
  const [alertData, setAlertData] = useState<AlertDataType>()
  const [contract, setContract] = useState<LegalEntity | undefined>(undefined)
  const [correspondenceNeeded, setCorrespondenceNeeded] = useState(false)
  const [contractDataChangesData, setContractDataChangesData] = useState<ProfileChange>()
  const [isDefaultTradingName, setIsDefaultTradingName] = useState(false)

  const isDirtyField = useReactiveVar(isDirtyForm)
  const batchPaymentModalBack = useReactiveVar(isBatchPaymentFile)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(ManagementBusinessDetailsInputSchema),
    shouldUnregister: false,
  })
  const { formState, watch, reset, setValue, getValues, register, errors } = methods

  const tradingName = watch('tradingName')
  const companyName = watch('companyName')

  const {
    data: contractData,
    loading: contractLoading,
    error: contractDetailsError,
  } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const {
    data: contractDataChanges,
    error: contractProfileChangesError,
  } = useGetContractProfileChangesQuery({
    variables: {
      contractId: +applicationId,
      status: [ProfileChangeStatus.PendingExecutionSignature, ProfileChangeStatus.Pending],
    },
    skip: !applicationId,
  })

  const {
    data: contractChangesCount,
    error: contractProfileChangesCountError,
  } = useGetcontractProfileChangesCountQuery({
    variables: {
      contractId: applicationId,
      status: [ProfileChangeStatus.Pending],
    },
    skip: !applicationId,
    fetchPolicy: 'network-only',
  })

  const correspondenceAddressProps: AddressPropsType = useMemo(() => {
    return {
      streetAddress: {
        name: 'streetAddressCorrespondence',
        initialValue: contract?.correspondenceAddress?.line1,
      },
      additionalDetailsOptional: {
        name: 'additionalDetailsOptionalCorrespondence',
        initialValue: contract?.correspondenceAddress?.additionalDetails,
      },
      postalCode: {
        name: 'postalCodeCorrespondence',
        initialValue: contract?.correspondenceAddress?.zip,
      },
      city: {
        name: 'cityCorrespondence',
        initialValue: contract?.correspondenceAddress?.city,
      },
    }
  }, [contract])

  const registeredAddressProps: AddressPropsType = useMemo(() => {
    return {
      streetAddress: {
        name: 'streetAddress',
        initialValue: contract?.address?.line1,
      },
      additionalDetailsOptional: {
        name: 'additionalDetailsOptional',
        initialValue: contract?.address?.additionalDetails,
      },
      postalCode: {
        name: 'postalCode',
        initialValue: contract?.address?.zip,
      },
      city: {
        name: 'city',
        initialValue: contract?.address?.city,
      },
    }
  }, [contract])

  const isEstimatedOutgoingTransactionsAmountChanged =
    +watch('estimatedOutgoingTransactionsAmount') !==
    +(formData?.estimatedOutgoingTransactionsAmount as string)
  const isEstimatedIncomingTransactionsAmountChanged =
    +watch('estimatedIncomingTransactionsAmount') !==
    +(formData?.estimatedIncomingTransactionsAmount as string)
  const isHistoryStatementFileChanged = !isEqual(
    watch('historyStatement'),
    formData?.historyStatement,
  )
  const isPartnersIncomingChanged = !isEqual(watch('partnersIncoming'), formData?.partnersIncoming)
  const isPartnersOutgoingChanged = !isEqual(watch('partnersOutgoing'), formData?.partnersOutgoing)
  const isOnlyTradingReason =
    isDefaultTradingName &&
    isEmpty(tradingName) &&
    isEqual(formState.dirtyFields, { tradingName: true })

  const isFormChanged =
    !isEmpty(
      omit(formState.dirtyFields, [
        'estimatedOutgoingTransactionsAmount',
        'estimatedIncomingTransactionsAmount',
      ]),
    ) ||
    isEstimatedOutgoingTransactionsAmountChanged ||
    isEstimatedIncomingTransactionsAmountChanged ||
    isHistoryStatementFileChanged ||
    isPartnersIncomingChanged ||
    isPartnersOutgoingChanged

  const cardPaymentsApmRequired = watch('cardPaymentsApm') === CardPaymentsSelectOptions.required

  const onSubmit = useCallback(() => {
    setIsOpen(true)
  }, [correspondenceNeeded])

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleCheckbox = useCallback(() => {
    setValue(`streetAddressCorrespondence`, correspondenceNeeded ? watch(`streetAddress`) : '', {
      shouldDirty: true,
    })
    setValue(
      `additionalDetailsOptionalCorrespondence`,
      correspondenceNeeded ? watch(`additionalDetailsOptional`) : '',
      { shouldDirty: true },
    )
    setValue(`postalCodeCorrespondence`, correspondenceNeeded ? watch(`postalCode`) : '', {
      shouldDirty: true,
    })
    setValue(`cityCorrespondence`, correspondenceNeeded ? watch(`city`) : '', { shouldDirty: true })
    setValue(`countryCorrespondence`, correspondenceNeeded ? watch(`country`) : '', {
      shouldDirty: true,
    })
    correspondenceNeeded && setValue('correspondenceRequired', correspondenceNeeded)
    setCorrespondenceNeeded((currentValue) => !currentValue)
  }, [correspondenceNeeded])

  useEffect(() => {
    const contractProfileChanges = contractDataChanges?.contractProfileChanges as ProfileChange[]
    const filterApproved =
      contractProfileChanges?.length > 0 &&
      contractProfileChanges.reduce(
        (max, character: ProfileChange) =>
          (character?.id as number) > (max?.id as number) ? character : max,
        contractProfileChanges[0],
      )
    setContractDataChangesData(filterApproved as ProfileChange)
  }, [contractDataChanges])

  useEffect(() => {
    if (
      contractChangesCount?.contractProfileChangesCount &&
      contractChangesCount.contractProfileChangesCount > 0
    ) {
      setAlertData({
        text: `${t(
          'BOApproveMsg',
          'Your business details will be updated after {{val1}} team approves them',
          {
            val1: config.name,
          },
        )}`,
        type: 'warning',
        icon: <InfoIcon />,
      })
    }
  }, [contractChangesCount?.contractProfileChangesCount])

  useEffect(() => {
    if (!isEmpty(contractData)) {
      const data =
        contractData && contractData.contract && (contractData.contract.owner as LegalEntity)
      data && setContract(data)

      const [parsedFormData, correspondenceRequired] = parsePreAssessmentFormData(contractData)

      setCorrespondenceNeeded(correspondenceRequired)

      if (batchPaymentModalBack) {
        reset({
          companyName: data?.companyName || '',
          businessPhone: data?.phone || '',
          businessWebsiteUrl: head(data?.websites) || '',
          city: data?.address?.city || '',
          streetAddress: data?.address?.line1 || '',
          postalCode: data?.address?.zip || '',
          additionalDetailsOptional: data?.address?.additionalDetails || '',
        })
      }

      reset(parsedFormData, { isDirty: false })

      setFormData(watch())
    }
  }, [contractData, contractDataChangesData, batchPaymentModalBack])

  useEffect(() => {
    if (getValues(`streetAddressCorrespondence`) === undefined) {
      setValue(
        'streetAddressCorrespondence',
        correspondenceNeeded ? '' : contract?.correspondenceAddress?.line1,
      )
    }
    if (getValues(`additionalDetailsOptionalCorrespondence`) === undefined) {
      setValue(
        'additionalDetailsOptionalCorrespondence',
        correspondenceNeeded ? '' : contract?.correspondenceAddress?.additionalDetails,
      )
    }
    if (getValues(`postalCodeCorrespondence`) === undefined) {
      setValue(
        'postalCodeCorrespondence',
        correspondenceNeeded ? '' : contract?.correspondenceAddress?.zip,
      )
    }
    if (getValues(`cityCorrespondence`) === undefined) {
      setValue(
        'cityCorrespondence',
        correspondenceNeeded ? '' : contract?.correspondenceAddress?.city,
      )
    }
    if (getValues(`countryCorrespondence`) === undefined) {
      setValue(
        'countryCorrespondence',
        correspondenceNeeded ? '' : contract?.correspondenceAddress?.country,
      )
    }
  }, [correspondenceNeeded, contract])

  useEffect(() => {
    if (errors) {
      return scrollToInputElement(errors)
    }
  }, [errors])

  useEffect(() => {
    isEmpty(errors) && window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    register('correspondenceRequired')
    setValue('correspondenceRequired', correspondenceNeeded)
  }, [correspondenceNeeded])

  useEffect(() => {
    if (contractDetailsError) {
      showError(contractDetailsError)
    } else if (contractProfileChangesError) {
      showError(contractProfileChangesError)
    } else if (contractProfileChangesCountError) {
      showError(contractProfileChangesCountError)
    }
  }, [contractDetailsError, contractProfileChangesError, contractProfileChangesCountError])

  useEffect(() => {
    isDirtyForm(formState.isDirty)
  }, [formState.isDirty])

  useEffect(() => {
    if (tradingName && companyName && tradingName === companyName) {
      setIsDefaultTradingName(true)
    }
  }, [tradingName, companyName])

  // TODO: handle introducer contracts

  return (
    <HandleAwayFromPage when={isDirtyField}>
      <Box className={classes.root}>
        <FormProvider {...methods}>
          {!formData || contractLoading ? (
            <Loader height={300} />
          ) : (
            <fieldset className={classes.formWrap} disabled={isViewOnly}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                id="management-business-details"
                onKeyDown={focusKeyPressNext}
                className={classes.form}
              >
                <ParagraphStyling
                  title={t('companyProfile', 'Company profile')}
                  id="companyProfileId"
                >
                  <CompanyInformation
                    isFromBusinessDetails
                    changedContractProfileData={
                      contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers
                    }
                  />

                  <AddressAutocomplete
                    addressLabel={t('registeredAddress', 'Registered address')}
                    changedContractProfileData={
                      contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers
                    }
                    showWarn={false}
                    hideCountry
                    props={registeredAddressProps}
                    fullWidth
                    isParagraphLabelSmall
                  />

                  <Toggler
                    name="correspondenceAddress"
                    checked={!correspondenceNeeded}
                    label={t(
                      'correspondenceAddressCheckBoxLabel',
                      'Correspondence address is the same as registered',
                    )}
                    onChange={handleCheckbox}
                  />
                  <Collapse in={correspondenceNeeded}>
                    <AddressAutocomplete
                      changedContractProfileData={
                        contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers
                      }
                      showWarn={false}
                      addressLabel={t('correspondenceAddressTitle', 'Correspondence address')}
                      correspondence
                      props={correspondenceAddressProps}
                      fullWidth
                      isParagraphLabelSmall
                    />
                  </Collapse>

                  <Typography variant={'h4'} className={classes.title}>
                    {t('companyContactInformation', 'Company contact information')}
                  </Typography>

                  <Box>
                    <CountryPhoneSelect
                      name="phoneNumber"
                      componentDisabled={isViewOnly}
                      label={t('companyPhone', 'Company phone')}
                      defaultValue={
                        contractDataChangesData
                          ? (contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers)
                              ?.phone
                          : (contractData?.contract?.owner as LegalEntity)?.phone || ''
                      }
                    />
                    <ControlledTooltipWrapped
                      wrapperClass={`${classes.dirtyControl} ${classes.dirtySpaceTop}`}
                      hidden={
                        !(contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers)
                          ?.phone || formState.errors.businessPhone
                      }
                      title={
                        (contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers)
                          ?.phone ?? ''
                      }
                    />
                  </Box>
                  <Box>
                    <WebsiteInput
                      name="urls"
                      label={t('website', 'Website')}
                      addBtnText={`+ ${t('addWebsite', 'Add website')}`}
                      tooltipTitle={makeTooltipTitleTextWebsites(
                        (contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers)
                          ?.websites,
                        {
                          divider: classes.lightDivider,
                          listItem: classes.listItem,
                        },
                      )}
                      isViewOnly={isViewOnly}
                    />
                  </Box>
                </ParagraphStyling>

                <ParagraphStyling
                  title={t('regulatoryInformation', 'Regulatory information')}
                  mt={3}
                  id="regulatoryInformationId"
                >
                  <RegulatoryQuestions
                    isFromBusinessDetails
                    changedContractProfileData={
                      contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers
                    }
                    readOnly={isViewOnly}
                  />
                </ParagraphStyling>

                <ParagraphStyling
                  title={t('economicProfile', 'Economic profile')}
                  mt={3}
                  id="economicProfileId"
                >
                  <EconomicProfile
                    readOnly={isViewOnly}
                    changedContractProfileData={
                      contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers
                    }
                  />
                </ParagraphStyling>

                <ParagraphStyling
                  title={t('productInformation', 'Product information')}
                  mt={3}
                  id="productInformationId"
                >
                  <ProductInformation
                    changedContractProfileData={
                      contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers
                    }
                    readOnly={isViewOnly}
                  />
                </ParagraphStyling>

                {cardPaymentsApmRequired && (
                  <ParagraphStyling
                    title={t('paymentProcessing', 'Payment processing')}
                    id="paymentProcessingId"
                    mt={3}
                  >
                    <PaymentProcessing
                      changedContractProfileData={
                        (contractDataChangesData?.changeAnswers as ContractProfileChangeAnswers)
                          ?.preAssessment?.cardPayment
                      }
                      readOnly={isViewOnly}
                    />
                  </ParagraphStyling>
                )}

                <GridRow>
                  <Box hidden={!isFormChanged || isOnlyTradingReason}>
                    <LoadingButton
                      className={classes.button}
                      loading={false}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disableElevation
                      data-test="saveChanges"
                    >
                      {t('saveChanges', 'Save changes')}
                    </LoadingButton>
                  </Box>
                </GridRow>
              </form>
            </fieldset>
          )}
          {formData && (
            <ChangesConfirmationModal
              isOpen={isOpen}
              closeModal={handleCloseModal}
              formData={formData}
              correspondenceNeeded={correspondenceNeeded}
              setAlertData={setAlertData}
              signatures={contractData?.contract?.signatories as ContractAuthorizedPersonType[]}
              missingInfoId={missingInfoId}
            />
          )}
        </FormProvider>

        <BusinessDetailsStepper
          cardPaymentsApmRequired={cardPaymentsApmRequired}
          formValues={watch() as PreAssessmentOnboardingInput}
          errors={errors}
          correspondenceNeeded={correspondenceNeeded}
          isLimited
        />
      </Box>
      <Box className={classes.alertContainer}>
        {alertData && (
          <AlertTipItem
            value={alertData.text}
            iconComponent={alertData.icon}
            type={alertData?.type}
            isCloseButton={true}
            setIsOpenAlert={() => setAlertData(undefined)}
            isOpenAlert={!!alertData}
          />
        )}
      </Box>
    </HandleAwayFromPage>
  )
}
