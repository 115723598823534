import defaultImage from '../assets/images/img/city.jpg'
import errorImage from '../assets/images/img/error-page-foto.jpg'
import signInImage from '../assets/images/img/signin-img-login.jpeg'
import resetPasswordImage from '../assets/images/img/reset_psw_img.jpg'
import emailVerificationImage from '../assets/images/img/signup-img3.jpg'

type Config = {
  isWhitelabel: boolean
  apiUrl: string
  subscriptionsUri: string
  logo: string
  name: string
  brand: string
  requiresInvite: boolean
  signupCodeRegex: RegExp
  isIndividualSignupEnabled: boolean
  isGuaranteedDepositAccountEnabled: boolean
  isPaymentFacilitatorEnabled: boolean
  allowedCardCurrencies: string[]
  clientInformationDocumentLink: string
  tldrSupportedCountriesAndMethods: {
    enabled: boolean
    countries: Record<string, { methods: string[]; currencies: string[] }>
  }
  images: {
    default: string
    error: string
    signIn: string
    resetPassword: string
    emailVerification: string
    restorePassword?: string
    twoFA?: string
    unusual?: string
    invitedSignUp?: string
    signUp?: string
    partnerSignUp?: string
    favicon?: string
  }
  featureTestContracts?: string[]
  rebrandUrl?: string
  crypto: {
    walletConnectId: string
    infuraApiKey: string
    etherscanLink: string
  }
}

function getConfig() {
  const config: Config = {
    featureTestContracts: (import.meta.env.REACT_APP_FEATURE_TEST_CONTRACTS || '')
      .split(',')
      .filter(Boolean),
    isWhitelabel: false,
    apiUrl: import.meta.env.REACT_APP_API_URL || '',
    subscriptionsUri: import.meta.env.REACT_APP_SUBSCRIPTIONS_API_URL || '',
    logo: '/img/logo 1.svg',
    name: 'Bivial',
    brand: 'Bivial AG',
    requiresInvite: false,
    signupCodeRegex: /KP-INVITE-\d{4}/,
    isIndividualSignupEnabled: import.meta.env.REACT_APP_INDIVIDUAL_SIGNUP_ENABLED === 'true',
    isGuaranteedDepositAccountEnabled:
      import.meta.env.REACT_APP_GUARANTEED_DEPOSIT_ACCOUNT_ENABLED === 'true',
    isPaymentFacilitatorEnabled: import.meta.env.REACT_APP_PF_ENABLED === 'true',
    clientInformationDocumentLink: import.meta.env.REACT_APP_CLIENT_INFORMATION_DOCUMENT_LINK || '',
    allowedCardCurrencies: import.meta.env.REACT_APP_ALLOWED_CARD_CURRENCIES
      ? import.meta.env.REACT_APP_ALLOWED_CARD_CURRENCIES.split(',')
      : ['EUR', 'USD'],
    images: {
      default: defaultImage,
      error: errorImage,
      signIn: signInImage,
      resetPassword: resetPasswordImage,
      emailVerification: emailVerificationImage,
    },
    tldrSupportedCountriesAndMethods: {
      enabled: import.meta.env.REACT_APP_SUPPORTED_COUNTRIES_AND_METHODS_ENABLED === 'true',
      countries: JSON.parse(import.meta.env.REACT_APP_SUPPORTED_COUNTRIES_AND_METHODS || '{}'),
    },
    rebrandUrl: import.meta.env.REACT_APP_REBRAND_URL,
    crypto: {
      walletConnectId: import.meta.env.REACT_APP_WALLET_CONNECT_ID || '',
      infuraApiKey: import.meta.env.REACT_APP_INFURA_API_KEY || '',
      etherscanLink: import.meta.env.REACT_APP_ETHERSCAN_LINK || '',
    },
  }
  if (
    import.meta.env.REACT_APP_CXPAY_URL_PATTERN &&
    new RegExp(import.meta.env.REACT_APP_CXPAY_URL_PATTERN).test(window.location.host)
  ) {
    config.isWhitelabel = true
    config.apiUrl = import.meta.env.REACT_APP_CXPAY_API_URL || ''
    config.subscriptionsUri = import.meta.env.REACT_APP_SUBSCRIPTIONS_CXPAY_API_URL || ''
    config.logo = '/img/cxpay_logo.svg'
    config.name = 'Cellxpay'
    config.brand = 'Cellxpay'
    config.requiresInvite = true
    config.signupCodeRegex = /CX-INVITE-\d{4}/
    config.isIndividualSignupEnabled =
      import.meta.env.REACT_APP_CXPAY_INDIVIDUAL_SIGNUP_ENABLED === 'true'
    config.isGuaranteedDepositAccountEnabled =
      import.meta.env.REACT_APP_GUARANTEED_DEPOSIT_ACCOUNT_ENABLED === 'true'
    config.isPaymentFacilitatorEnabled = import.meta.env.REACT_APP_PF_ENABLED === 'true'
    config.clientInformationDocumentLink =
      import.meta.env.REACT_APP_CLIENT_INFORMATION_DOCUMENT_LINK || ''
    config.images.default = require('../assets/images/img/default_cxpay.jpg')
    config.images.error = require('../assets/images/img/error-page-foto-cxpay.jpg')
    config.images.signIn = require('../assets/images/img/signn-img-login-cxpay.jpg')
    config.images.restorePassword = require('../assets/images/img/default_cxpay.jpg')
    config.images.resetPassword = require('../assets/images/img/default_cxpay.jpg')
    config.images.emailVerification = require('../assets/images/img/default_cxpay.jpg')
    config.images.twoFA = require('../assets/images/img/default_cxpay.jpg')
    config.images.unusual = require('../assets/images/img/default_cxpay.jpg')
    config.images.invitedSignUp = require('../assets/images/img/signn-img-login-cxpay.jpg')
    config.images.signUp = require('../assets/images/img/error-page-foto-cxpay.jpg')
    config.images.partnerSignUp = require('../assets/images/img/error-page-foto-cxpay.jpg')
    config.images.favicon = 'cxpay_favicon.png'
    config.crypto = {
      walletConnectId: '',
      infuraApiKey: '',
      etherscanLink: '',
    }
  }

  return config
}

const config = getConfig()
export default config
