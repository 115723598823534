import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'
import { get } from 'lodash'
import clsx from 'clsx'
import {
  ALL_EXCEPT_CYRILLIC_REGEXP,
  EXCLUDE_ALL_NUMBERS_IN_BRACKETS,
} from '../../../constants/validations'
import { FormInputFieldEntity } from '../../../types'

const longErrorTypesArray = ['additionalDetails', 'numberAndLatinOnly', 'numberAndLatinicaField']

const longLabelLengthStyles = {
  transform: 'translate(0, 5px) scale(0.857)',
}

const useStyles = (isError: boolean, isLongError: boolean, labelLength?: number) =>
  makeStyles((theme) => ({
    general: {
      '& .MuiFormHelperText-root': {
        color: isError ? '' : '#262626',
      },
      [theme.breakpoints.only('md')]: {
        '& label': {
          top: '-7px',
        },
      },
    },
    root: {
      [theme.breakpoints.down('xs')]: {
        ...(isLongError ? { marginBottom: theme.spacing(6) } : {}),
      },
      '& .MuiInputLabel-shrink': {
        [theme.breakpoints.only('xs')]: {
          ...(labelLength && labelLength >= 30 ? longLabelLengthStyles : {}),
        },
      },
    },
  }))()

export const FormControlledTextField: React.FC<FormInputFieldEntity> = ({
  type,
  name,
  label,
  placeholder,
  defaultValue = '',
  onInputChange,
  helpText = '',
  disabled,
  externalError,
  ...rest
}) => {
  const { t } = useTranslation()
  const { control, errors, watch } = useFormContext()
  const error = externalError ? externalError : errors ? get(errors, name) : null
  const isLongError = longErrorTypesArray.includes(errors[name]?.type)
  const classes = useStyles(!!error || !!externalError, isLongError, label?.toString()?.length)
  const fieldValue = watch(name)

  return (
    <Controller
      render={({ onChange, ...other }) => (
        <TextField
          {...other}
          className={clsx(classes.general)}
          label={label}
          value={fieldValue || ''}
          type={!!type ? type : 'text'}
          placeholder={placeholder}
          error={!!externalError || !!error}
          onKeyDown={(event) => {
            if (!ALL_EXCEPT_CYRILLIC_REGEXP.test(event.key)) {
              event.preventDefault()
            }
          }}
          onChange={(e) => {
            onChange(e)
            if (onInputChange) {
              onInputChange(e)
            }
          }}
          helperText={
            externalError || error ? t(externalError?.message || error.message) : helpText
          }
          variant="standard"
          size="small"
          margin="normal"
          InputProps={{
            margin: 'none',
          }}
          fullWidth={true}
          data-test={`autotest-${name.replace(EXCLUDE_ALL_NUMBERS_IN_BRACKETS, '$1')}`}
          disabled={disabled}
          classes={{ root: classes.root }}
          {...rest}
        />
      )}
      id={name}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  )
}
