import React, { FC, useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { includes, isEmpty, omit } from 'lodash'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { ApolloError } from '@apollo/client'
import {
  Button,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {
  Address,
  ContractAuthorizedPersonType,
  DocumentRelatedEntityType,
  DocumentType,
  GetProfileChangesDocument,
  Individual,
  Maybe,
  ProfileChangeStatus,
  Scalars,
  TwoFaCodeDispatchMode,
  useCreateOwnDocumentIndividualMutation,
  useCreateOwnDocumentTwoSidedMutation,
  useGetUserDataQuery,
  useSendSmsVerificationMutation,
  useUpdateOwnDetailsMutation,
  useUpdateOwnEmailMutation,
} from '../../../graphql'
import { FilesForSavingAndShowType, VerificationSteps } from '../../../types'
import {
  editedValueViewCorrectly,
  extractDateValue,
  getProperty,
  isSignatoryInContract,
  mainDocTypeForSide,
  reportAppError,
  scrollToInputElement,
} from '../../../utils'
import { useDetermineUserRights } from '../../../hooks'
import {
  AddressDocTypesMandatory,
  CountriesList,
  PoiDocTypesMandatory,
  ProofOfIdentityListPOA,
} from '../../../utils/Data'
import { AuthorizeField } from '../../../components/Common/2FAfield'
import { PoiProfileInputSchema } from '../../../schemes/common'
import { PATH_PARAMS } from '../../../routes/paths'
import { AuthorizeFieldPhone } from '../../../components/Common/2FAfieldPhone'
import { ProofOfIdentityControllingPerson } from '../../../components/NewPerson/Forms/ProofOfIdentityControllingPerson'
import { RenderAddress } from './RenderAddress'
import { BaseEnumWithKey } from '../../../components'
import { MODAL_WRAPPER_ZINDEX } from '../../../constants'
import Box from '@material-ui/core/Grid'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    marginBottom: theme.spacing(3),
  },
  listItem: {
    flexWrap: 'wrap',
    padding: 0,
    '& .MuiListItemText-primary': {
      ...theme.typography.subtitle2,
      color: '#595959',
    },
    '& .MuiListItemText-secondary': {
      ...theme.typography.body1,
      color: '#000',
    },
  },
  listLabel: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  modal: {
    maxWidth: 512,
    outline: 'none',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.up('sm')]: {
      maxWidth: 512,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(328px - 32px)',
    },
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 2, 3),
    borderBottom: '1px solid #ccc',
  },
  bodyModal: {
    padding: theme.spacing(0, 3),
    margin: theme.spacing(3, 0),
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
    '& > .MuiBox-root': {
      width: '100%',
    },
  },
  footerModal: {
    padding: theme.spacing(3, 0, 3, 0),
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  btnCloseModal: {
    width: 24,
    height: 24,
    minHeight: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  fullWidth: {
    '& .MuiGrid-grid-sm-8, & .MuiGrid-grid-lg-6': {
      width: '100%',
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}))

type EditRequestModalProps = {
  isOpen: boolean
  closeModal: () => void
  personProfileFormData?: Individual | null
  formData?: Individual | null
  handleStatus: (newStatus: Maybe<ProfileChangeStatus> | undefined) => void
}

export const EditRequestModal: FC<EditRequestModalProps> = ({
  isOpen,
  closeModal,
  personProfileFormData,
  formData,
  handleStatus,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const { getValues, reset } = useFormContext()
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(PoiProfileInputSchema),
  })
  const {
    watch,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    getValues: modalFormGetValues,
    errors,
  } = methods

  const filesForUploadAddress = watch(`filesForUploadAddress`)
  const filesForUpload = watch(`filesForUpload`)
  const [createOwnDocumentIndividual] = useCreateOwnDocumentIndividualMutation()
  const [createOwnDocumentTwoSided] = useCreateOwnDocumentTwoSidedMutation()
  const [
    updateOwnDetailsMutation,
    { loading: mutationUpdateLoading },
  ] = useUpdateOwnDetailsMutation()
  const [
    updateOwnEmailMutation,
    { loading: mutationUpdateEmailLoading },
  ] = useUpdateOwnEmailMutation()

  const [
    updateOwnPhoneMutation,
    { loading: mutationUpdatePhoneLoading },
  ] = useSendSmsVerificationMutation()

  const { data: individualData, loading: loadingIndividualData } = useGetUserDataQuery()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const [oldCountryValue, setOldCountryValue] = useState<string | undefined>('')

  interface IVerificationAction {
    type: 'setSteps' | 'removeFirstStep'
    payload?: Array<VerificationSteps>
  }

  const VerificationReducer = (
    state: Array<VerificationSteps>,
    action: IVerificationAction,
  ): Array<VerificationSteps> => {
    switch (action.type) {
      case 'setSteps':
        return action.payload ? action.payload : []
      case 'removeFirstStep':
        return state.slice(1)
      default:
        throw new Error('Wrong verification action')
    }
  }

  const [stepsOfVerification, dispatch] = useReducer(VerificationReducer, [VerificationSteps.other])

  const [changedPersonData, setChangedPersonData] = useState(false)
  const [changedAddressData, setChangedAddressData] = useState(false)
  const [personDataOptional, setPersonDataOptional] = useState(false)
  const [addressDataOptional, setAddressDataOptional] = useState(false)
  const [contractId, setContractId] = useState<string | number>(7)
  const [verificationIdPhone, setVerificationIdPhone] = useState(0)
  const [sentCodeAttemptsPhone, setSentCodeAttemptsPhone] = useState(0)
  const { userRights } = useDetermineUserRights(contractId)
  const email = getValues('email')
  const phoneNumber = getValues('phoneNumber')

  const setUpdateEmailDetails = useCallback(async () => {
    if (formData && email) {
      try {
        const response = await updateOwnEmailMutation({
          variables: { email },
          refetchQueries: [
            {
              query: GetProfileChangesDocument,
            },
          ],
          awaitRefetchQueries: true,
        })
        if (response.data?.updateOwnEmail) {
        }
      } catch (e) {
        throw e
      }
    }
  }, [formData, email, updateOwnEmailMutation])

  const setUpdatePhoneDetails = useCallback(async () => {
    if (formData && phoneNumber) {
      await updateOwnPhoneMutation({
        variables: { phone: phoneNumber, type: TwoFaCodeDispatchMode.Sms },
      })
        .then((r) => {
          setVerificationIdPhone(Number(r.data?.sendSmsVerification?.phoneVerificationId))
        })
        .catch((e) => {
          throw e
        })
    }
  }, [
    formData,
    phoneNumber,
    updateOwnPhoneMutation,
    setVerificationIdPhone,
    setSentCodeAttemptsPhone,
  ])

  const setUpdateDetails = useCallback(
    async (docIds: number[]) => {
      const profileValues = getValues()
      if (formData) {
        const personDetails: Individual = {
          id: formData.id,
          firstName: profileValues.firstName,
          lastName: profileValues.lastName,
          nationality: profileValues.nationality,
          address: {
            line1: profileValues.streetAddress,
            city: profileValues.city,
            zip: profileValues.postalCode,
            country: profileValues.country,
            additionalDetails: profileValues.additionalDetailsOptional,
          },
          ...(formData?.taxId ? { taxId: formData.taxId } : {}),
        }
        try {
          await updateOwnDetailsMutation({
            variables: {
              ...omit(personDetails, ['id']),
              ...personDetails.address,
              supportingDocumentIds: docIds,
            },
            refetchQueries: [
              {
                query: GetProfileChangesDocument,
              },
            ],
            awaitRefetchQueries: true,
          })

          if (formData.email && formData.phone) {
            dispatch({
              type: 'setSteps',
              payload: [VerificationSteps.email, VerificationSteps.phone],
            })
            await setUpdateEmailDetails()
            await setUpdatePhoneDetails()
          } else if (formData.email) {
            dispatch({
              type: 'setSteps',
              payload: [VerificationSteps.email],
            })
            await setUpdateEmailDetails()
          } else if (formData.phone) {
            dispatch({
              type: 'setSteps',
              payload: [VerificationSteps.phone],
            })
            await setUpdatePhoneDetails()
          } else {
            reset(profileValues)
            closeModal()
          }
        } catch (e) {
          throw e
        }
      }
    },
    [
      closeModal,
      formData,
      getValues,
      setUpdateEmailDetails,
      setUpdatePhoneDetails,
      updateOwnDetailsMutation,
    ],
  )

  const sendChangeRequest = useCallback(async () => {
    try {
      const docIds: number[] = []

      async function processUploadedDocs(filesForUpload: FilesForSavingAndShowType[]) {
        if (filesForUpload && filesForUpload.length > 0) {
          for (const fileForSaving of filesForUpload) {
            await createOwnDocumentIndividual({
              variables: {
                type: fileForSaving.docType as DocumentType,
                fileName: fileForSaving.fileName,
                size: fileForSaving.size,
                plainUrl: fileForSaving.plainUrl ?? '',
                relatedEntityId: personProfileFormData?.id as Scalars['ID']['input'],
                relatedEntityType: DocumentRelatedEntityType.Individual,
                expiration: extractDateValue(fileForSaving.expiration),
              },
            })
              .then((result) => {
                result.data?.createOwnDocument?.id &&
                  docIds.push(result.data?.createOwnDocument?.id)
              })
              .catch((error) => {
                throw error
              })
          }
        }
      }

      await processUploadedDocs(filesForUploadAddress)
      const firstSide = filesForUpload?.find(
        (docFile: FilesForSavingAndShowType) => docFile.isFirstSide === true && !docFile.isRemoved,
      )
      const secondSide = filesForUpload?.find(
        (docFile: FilesForSavingAndShowType) => docFile.isFirstSide === false && !docFile.isRemoved,
      )
      if (filesForUpload?.length > 0) {
        if (!!secondSide) {
          await createOwnDocumentTwoSided({
            variables: {
              type: mainDocTypeForSide(firstSide?.docType as DocumentType),
              firstFileName: firstSide?.fileName as string,
              secondFileName: secondSide?.fileName as string,
              firstPlainUrl: firstSide?.plainUrl ?? '',
              secondPlainUrl: secondSide?.plainUrl ?? '',
              firstSize: firstSide?.size as number,
              secondSize: secondSide?.size as number,
              relatedEntityId: personProfileFormData?.id as Scalars['ID']['input'],
              relatedEntityType: DocumentRelatedEntityType.Individual,
              expiration: extractDateValue(firstSide?.expiration),
            },
          })
            .then((result) => {
              result.data?.createOwnDocument?.id && docIds.push(result.data?.createOwnDocument?.id)
            })
            .catch((e) => {
              toast.error(
                t(
                  'errorDocumentUpload',
                  'Unable to upload document at the moment, please try again later',
                ),
              )
              reportAppError(e)
            })
        } else if (!!firstSide) {
          await createOwnDocumentIndividual({
            variables: {
              type: mainDocTypeForSide(firstSide?.docType as DocumentType),
              fileName: firstSide?.fileName as string,
              plainUrl: firstSide?.plainUrl ?? '',
              size: firstSide?.size as number,
              relatedEntityId: personProfileFormData?.id as Scalars['ID']['input'],
              relatedEntityType: DocumentRelatedEntityType.Individual,
              expiration: extractDateValue(firstSide?.expiration),
            },
          })
            .then((result) => {
              result.data?.createOwnDocument?.id && docIds.push(result.data?.createOwnDocument?.id)
            })
            .catch((e) => {
              toast.error(
                t(
                  'errorDocumentUpload',
                  'Unable to upload document at the moment, please try again later',
                ),
              )
              reportAppError(e)
            })
        }
      }

      await setUpdateDetails(docIds)
    } catch (e) {
      toast.error((e as Error).message)

      const codeError =
        (e as ApolloError)?.graphQLErrors?.length > 0
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (e as ApolloError)?.graphQLErrors[0]?.extensions?.exception?.code
          : null
      if (codeError === 'phoneIsUsed') {
        closeModal()
      }
    } finally {
      setValue(`filesForUploadAddress`, [])
      setValue(`addressRequired`, true)
      setValue(`filesForUpload`, [])
      setValue(`personRequired`, true)
    }
  }, [
    createOwnDocumentIndividual,
    createOwnDocumentTwoSided,
    filesForUploadAddress,
    filesForUpload,
    personProfileFormData?.id,
    setUpdateDetails,
    setValue,
    t,
  ])

  useEffect(() => {
    if (!loadingIndividualData && individualData) {
      const contractAP =
        (individualData?.viewer as Individual)?.contracts?.find(
          (contract) => contract?.id === contractId,
        )?.authorizedPersons ?? []
      const individualId = (individualData?.viewer as Individual)?.id

      if (filesForUploadAddress && filesForUpload) {
        const filesCheckAddress = filesForUploadAddress.some((element: FilesForSavingAndShowType) =>
          includes(AddressDocTypesMandatory, element?.docType),
        )
        const filesCheckPerson = filesForUpload.some((element: FilesForSavingAndShowType) =>
          includes(PoiDocTypesMandatory, element?.docType),
        )

        if (
          !isEmpty(contractAP) &&
          !isSignatoryInContract(contractAP as ContractAuthorizedPersonType[], individualId)
        ) {
          setPersonDataOptional(true)
          setAddressDataOptional(true)
        }
        if (
          filesCheckAddress ||
          !isSignatoryInContract(contractAP as ContractAuthorizedPersonType[], individualId) ||
          !changedAddressData
        ) {
          setValue(`addressRequired`, false)
          clearErrors('documentTypeAddress')
        } else {
          setValue(`addressRequired`, true)
        }
        if (
          filesCheckPerson ||
          !isSignatoryInContract(contractAP as ContractAuthorizedPersonType[], individualId) ||
          !changedPersonData
        ) {
          setValue(`personRequired`, false)
          clearErrors(`documentType`)
        } else {
          setValue(`personRequired`, true)
        }
      }
    }

    if (applicationId && individualData?.viewer) {
      const currentContract = (individualData?.viewer as Individual).contracts?.find((contract) => {
        if (contract?.id) {
          return contract.id === +applicationId
        } else {
          return false
        }
      })

      currentContract && setContractId(currentContract?.id)
    }
  }, [
    individualData,
    applicationId,
    loadingIndividualData,
    filesForUploadAddress,
    filesForUpload,
    changedPersonData,
    changedAddressData,
    setValue,
    clearErrors,
  ])

  useEffect(() => {
    if (formData && personProfileFormData) {
      setChangedPersonData(
        Object.keys(formData as Individual).filter((key) => {
          if (
            key !== 'id' &&
            key !== 'address' &&
            key !== 'email' &&
            key !== 'phone' &&
            !isEmpty(getProperty(formData, key as keyof Individual))
          ) {
            return key
          } else {
            return false
          }
        }).length !== 0,
      )

      const addressData = formData.address as Address
      setChangedAddressData(
        Object.keys(addressData).filter((key) => {
          if (key !== 'id' && !isEmpty(getProperty(addressData, key as keyof Address))) {
            return key
          } else {
            return false
          }
        }).length !== 0,
      )
    }
  }, [formData, personProfileFormData])

  useEffect(() => {
    register(`filesForUploadAddress`)
    if (modalFormGetValues(`filesForUploadAddress`) === undefined) {
      setValue(`filesForUploadAddress`, [])
    }
    register(`addressRequired`)
    if (modalFormGetValues(`addressRequired`) === undefined) {
      setValue(`addressRequired`, true)
    }
    register(`filesForUpload`)
    if (modalFormGetValues(`filesForUpload`) === undefined) {
      setValue(`filesForUpload`, [])
    }
    register(`personRequired`)
    if (modalFormGetValues(`personRequired`) === undefined) {
      setValue(`personRequired`, true)
    }
  }, [modalFormGetValues, register, setValue])

  useEffect(() => {
    if (isOpen) {
      if (personProfileFormData) {
        const oldCountry = CountriesList?.find(
          (v: BaseEnumWithKey) => v.key === personProfileFormData.address?.country,
        )?.label
        setOldCountryValue(oldCountry)
      }
    }
  }, [isOpen, personProfileFormData])

  useEffect(() => {
    if (errors) {
      return scrollToInputElement(errors)
    }
  }, [errors])

  const renderList = () => {
    if (formData && personProfileFormData) {
      return Object.keys(formData)
        .filter(
          (key) =>
            key !== 'id' && key !== 'address' && getProperty(formData, key as keyof Individual),
        )
        .map((key, i) => (
          <List dense={false} className={classes.list} key={`${i}${key}`}>
            <ListItem className={classes.listItem} key={1}>
              <Typography variant="h3" className={classes.listLabel}>
                {t(key as keyof Individual)}
              </Typography>
              <ListItemText
                primary={`${t('currentValue')}:`}
                secondary={editedValueViewCorrectly(key, personProfileFormData)}
              />
            </ListItem>
            <ListItem className={classes.listItem} key={2}>
              <ListItemText
                primary={`${t('newValue', 'New value')}:`}
                secondary={editedValueViewCorrectly(key, formData)}
              />
            </ListItem>
          </List>
        ))
    }
  }

  const deleteFirstVerificationStepOrCloseModal = () => {
    dispatch({ type: 'removeFirstStep' })
    if (stepsOfVerification.length <= 1) {
      closeModal()
      handleStatus(ProfileChangeStatus.Approved)
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="account-modal-title"
      aria-describedby="account-modal-description"
      style={{ zIndex: MODAL_WRAPPER_ZINDEX }}
    >
      <Grid container item style={modalStyle} className={classes.modal}>
        <Grid item className={classes.headerModal}>
          <Typography variant={'h5'}>{t('editRequest')}</Typography>
          <Fab
            color="default"
            aria-label="close"
            className={classes.btnCloseModal}
            onClick={closeModal}
          >
            <CloseIcon />
          </Fab>
        </Grid>
        {stepsOfVerification[0] === VerificationSteps.email && (
          <Grid item xs={12} className={classes.bodyModal}>
            <List dense={false} className={classes.list}>
              <ListItem className={classes.listItem}>
                <Typography variant="h3" className={classes.listLabel}>
                  {t('email', 'Email')}
                </Typography>
                <ListItemText
                  primary={`${t('currentValue')}:`}
                  secondary={personProfileFormData?.email}
                />
              </ListItem>
              <ListItem className={`${classes.listItem} ${classes.mb2}`}>
                <ListItemText
                  primary={`${t('newValue', 'New value')}:`}
                  secondary={formData?.email}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <Typography variant="h3" className={classes.listLabel}>
                  {t('confirmChange')}
                </Typography>
                <ListItemText
                  primary={''}
                  secondary={t('verificationCodeSentToEmail', { email: formData?.email })}
                />
              </ListItem>
            </List>
            <Grid item xs={12}>
              <AuthorizeField onSubmit={deleteFirstVerificationStepOrCloseModal} email={email} />
            </Grid>
          </Grid>
        )}

        {stepsOfVerification[0] === VerificationSteps.phone && (
          <Grid item xs={12} className={classes.bodyModal}>
            <List dense={false} className={classes.list}>
              <ListItem className={classes.listItem}>
                <Typography variant="h3" className={classes.listLabel}>
                  {t('phone', 'Phone')}
                </Typography>
                <ListItemText
                  primary={`${t('currentValue')}:`}
                  secondary={personProfileFormData?.phone?.replaceAll(' ', '')}
                />
              </ListItem>
              <ListItem className={`${classes.listItem} ${classes.mb2}`}>
                <ListItemText
                  primary={`${t('newValue', 'New value')}:`}
                  secondary={formData?.phone?.replaceAll(' ', '')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <Typography variant="h3" className={classes.listLabel}>
                  {t('confirmChange')}
                </Typography>
                <ListItemText
                  primary={''}
                  secondary={t('verificationCodeSentToPhone', { phone: formData?.phone })}
                />
              </ListItem>
            </List>
            <Grid item xs={12}>
              <AuthorizeFieldPhone
                onSubmit={deleteFirstVerificationStepOrCloseModal}
                setAttemptsSend={setSentCodeAttemptsPhone}
                attemptsSend={sentCodeAttemptsPhone}
                verificationId={verificationIdPhone}
                phoneNumber={phoneNumber}
                setVerificationId={setVerificationIdPhone}
              />
            </Grid>
          </Grid>
        )}

        {stepsOfVerification[0] === VerificationSteps.other && (
          <Grid item xs={12} className={classes.bodyModal}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(sendChangeRequest)}>
                {renderList()}
                {!userRights?.limitedAccessRight && changedPersonData && (
                  <Box className={classes.fullWidth}>
                    <ProofOfIdentityControllingPerson
                      nameLabel={t('supportingDocument', 'Supporting document')}
                      infoLabel={
                        !personDataOptional ? t('spaceSymbol', ' ') : t('optional', 'Optional')
                      }
                      isModal={true}
                    />
                  </Box>
                )}
                {formData && formData.address && personProfileFormData && (
                  <RenderAddress
                    data={formData}
                    formPerson={personProfileFormData}
                    oldCountryValue={oldCountryValue}
                  />
                )}
                {!userRights?.limitedAccessRight && changedAddressData && (
                  <ProofOfIdentityControllingPerson
                    nameLabel={t('supportingDocument', 'Supporting document')}
                    infoLabel={
                      !addressDataOptional ? t('spaceSymbol', ' ') : t('optional', 'Optional')
                    }
                    filesForUploadRegName={'filesForUploadAddress'}
                    documentTypeRegName={'documentTypeAddress'}
                    expiryDateRegName={'expiryDateAddress'}
                    optionalIdentityList={ProofOfIdentityListPOA}
                    isModal={true}
                  />
                )}
                <Grid item xs={12} className={classes.footerModal}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      mutationUpdateLoading ||
                      mutationUpdateEmailLoading ||
                      mutationUpdatePhoneLoading
                    }
                    color="primary"
                  >
                    {t('sendChangeRequest', 'Send change request')}
                  </Button>
                  <Button onClick={closeModal} variant="contained" data-test="cancelButton">
                    {t('cancel', 'Cancel')}
                  </Button>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
