import { intersection } from 'lodash'
import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  AmpInUse,
  CardPaymentsTransactionalDataRegion,
  Maybe,
  PreAssessmentPartner,
} from '../../../../graphql'
import { Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import {
  KeyAndLabelSelectType,
  TooltipPartnersDataType,
  TooltipTitleTextClassesType,
} from '../../../../types'
import { getLabelByKey, getLabelsSeparatedByCommas } from '../../../../utils'
import { cardPaymentsRegions } from '../../../../constants'
import { BusinessCategoriesList, CountriesUnknownList } from '../../../../utils/Data'
import i18n from '../../../../i18n'

export const makeTooltipTitleTextDoubleTextInputApm = (
  fieldName: 'apmCurrently' | 'apmRequired',
  data: Record<string, Array<Maybe<AmpInUse>> | null | undefined>,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> | undefined => {
  if (!data?.[fieldName]) return undefined

  return (
    <List disablePadding>
      {data?.[fieldName]?.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''}>
            <ListItemText
              primary={`${dataItem?.ampInUseTypeCurrentlyInUse} ${dataItem?.monthlyVolumeInEuro}`}
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextWebsites = (
  data?: unknown[] | null | undefined,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> | undefined => {
  if (!data) return undefined

  return (
    <List disablePadding>
      {data.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''} key={index}>
            <ListItemText primary={dataItem} />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextDoubleInputBusinessRegions = (
  data?: Maybe<Maybe<CardPaymentsTransactionalDataRegion>[]>,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> | undefined => {
  if (!data) return undefined

  return (
    <List disablePadding>
      {data?.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''} key={index}>
            <ListItemText
              primary={`${dataItem?.businessCategoryPercentage}% ${getLabelByKey(
                dataItem?.businessCategoryRegion,
                cardPaymentsRegions,
              )}`}
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextPartnersInformation = (
  partners: PreAssessmentPartner[],
  paymentPurposeData: KeyAndLabelSelectType[],
  onlyOnePartnerRequired: boolean,
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> => {
  const tooltipData: TooltipPartnersDataType[] = partners.map((partner) => ({
    name: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('name', 'Name')}:
        </Typography>
        {partner.name}
      </>
    ),
    country: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('country', 'Country')}:
        </Typography>
        {getLabelByKey(partner.country, CountriesUnknownList)}
      </>
    ),
    bankCountry: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t(
            onlyOnePartnerRequired
              ? 'countryOfYourCompanyCounterpartyBank'
              : 'countryOfPartnerBank',
          )}
          :
        </Typography>
        {getLabelByKey(partner.bankCountry, CountriesUnknownList)}
      </>
    ),
    businessSector: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('businessCategory', 'Business Category')}:
        </Typography>
        {getLabelByKey(partner.businessSector, BusinessCategoriesList)}
      </>
    ),
    paymentPurpose: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t('paymentPurpose', 'Payment purpose')}:
        </Typography>
        {getLabelsSeparatedByCommas(partner.paymentPurpose ?? [], paymentPurposeData)}{' '}
        {partner.paymentPurposeOther !== 'N/A' ? partner.paymentPurposeOther : ''}
      </>
    ),
    website: (
      <>
        <Typography component={'span'} className={classes?.tooltipKey ?? ''}>
          {i18n.t(
            'siteLinkNewPartner',
            'Website, app store link or social media profile of the counterparty',
          )}
          :
        </Typography>
        {partner.website}
      </>
    ),
  }))
  return (
    <List disablePadding>
      {tooltipData.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          {Object.keys(dataItem).map((key, index) => (
            <ListItem className={classes?.listItem ?? ''} key={`${key + index}`}>
              <ListItemText primary={dataItem[key as keyof TooltipPartnersDataType]} />
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </List>
  )
}

export const makeTooltipTitleTextExpectedVolumes = (
  data: string[],
  classes?: TooltipTitleTextClassesType,
): NonNullable<React.ReactNode> => {
  return (
    <List disablePadding>
      {data.map((dataItem, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider className={classes?.divider ?? ''} />}
          <ListItem className={classes?.listItem ?? ''}>
            <ListItemText primary={dataItem} />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  )
}

export const useCommonStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  wrapperButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  button: {
    height: 48,
    width: '100%',
    margin: theme.spacing(4, 0, 3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  titleBox: {
    marginBottom: theme.spacing(4),
  },
  titleSubBox: {
    marginTop: theme.spacing(3),
    fontWeight: 400,
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  boxInput: {
    margin: '20px 0 20px 0',
    display: 'flex',
    '& .MuiInputLabel-root': {
      minWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiInputBase-input': {
        transform: `translate(0, 8px)`,
      },
    },
  },
  iconTooltip: {
    position: 'absolute',
    top: 22,
    right: 47,
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      right: 37,
    },
    zIndex: theme.zIndex.drawer + 1,
    '& .MuiSvgIcon-root': {
      color: '#999999',
      '&:hover': {
        color: '#000000',
      },
    },
  },
  tooltipChargeBearer: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '495px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },
  titleTooltip: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: '24px',
  },
  textTooltip: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  inputHelperText: {
    margin: theme.spacing(1.5, 0, 0),
    paddingLeft: 12,
    display: 'flex',
    alignItems: 'center',
    '& a': {
      textDecoration: 'revert',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      margin: '0 0 0 5px',
      '& svg': {
        display: 'none',
      },
    },
  },
  boxMultiSelect: {
    margin: '24px 0 8px 0',
    display: 'flex',
    '& .MuiAutocomplete-inputRoot': {
      minHeight: 48,
      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 20px)',
      },
    },
  },
  formControlMultiSelect: {
    marginTop: 20,
  },
  descriptionForField: {
    fontWeight: 400,
  },
  trash: {
    '&:hover': {
      color: '#EF2828',
    },
  },
  whiteBackground: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  lightDivider: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255,.3)',
    height: 3,
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '580px',
    position: 'fixed',
    top: 8,
    zIndex: 1500,
    left: 0,
    right: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    '&>div': {
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.2)',
    },
  },
}))

// const makeTooltipTitleText = (
//   visaVolumes: TotalPredictedMonthlyVolumes,
//   mastercardVolumes: TotalPredictedMonthlyVolumes,
// ): NonNullable<React.ReactNode> => {
//   return (
//     <List disablePadding>
//       <ListItem className={classes.listItem}>
//         <ListItemText
//           primary={`Visa (${t('domestic', 'Domestic')}: ${visaVolumes.domestic ?? 0},
//           ${t('eea', 'EEA')}: ${visaVolumes.eea ?? 0},
//           ${t('nonEea', 'Non-EEA')}: ${visaVolumes.nonEea ?? 0})`}
//         />
//       </ListItem>
//       <Divider className={classes.lightDivider} />
//       <ListItem className={classes.listItem}>
//         <ListItemText
//           primary={`Mastercard (${t('domestic', 'Domestic')}: ${mastercardVolumes.domestic ?? 0},
//           ${t('eea', 'EEA')}: ${mastercardVolumes.eea ?? 0},
//           ${t('nonEea', 'Non-EEA')}: ${mastercardVolumes.nonEea ?? 0})`}
//         />
//       </ListItem>
//     </List>
//   )
// }

export const getPercentage = (requiredFieldsValue: string[], filledFields: string[]) => {
  const percentage =
    intersection(requiredFieldsValue, filledFields).length / requiredFieldsValue.length
  return percentage || 'future'
}
