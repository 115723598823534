import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  table: {
    borderTop: '0',
    '& td': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
  },
  mt15: {
    marginTop: 15,
  },
  label: {
    fontWeight: 'bold',
  },
  infoLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  addressLabel: {
    fontWeight: 'bold',
  },
  name: {
    textTransform: 'capitalize',
  },
  cell: {
    paddingTop: theme.spacing(3),
    height: 68,
  },
  chip: {
    ...theme.typography.subtitle2,
    fontWeight: 'bold',
    lineHeight: theme.spacing(2),
    color: 'white',
    '& .MuiChip-labelSmall': {
      padding: theme.spacing(0.25, 1.25),
    },
  },
  chipHeight: {
    '& .MuiChip-sizeSmall': {
      height: 21,
    },
  },
  chipPadding: {
    '& .MuiChip-labelSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  warning: {
    color: '#EF2828',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFEFED',
  },
  success: {
    color: '#03703C',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    backgroundColor: '#F1FBF7',
  },
  borderTop: {
    '& .MuiTableCell-root': {
      borderTop: '1px solid rgba(0,0,0,.1)',
    },
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 4px 0 0',
  },
  svg: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
  rowGrey: {
    background: '#F5F5F5',
  },

  // Mobile table
  mobileTable: {
    borderBottom: 0,
    '&.bordered': {
      border: '1px solid rgba(0,0,0,.1)',
    },
  },
  mobileTableRow: {
    marginBottom: theme.spacing(0),
    '&:not(:first-child)': {
      // borderTop: '1px solid rgba(0,0,0,.1)',
      borderTop: 0,
    },
  },
  mobileTableItem: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 56,
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(0,0,0,.1)',
    lineHeight: '1.5rem',
    color: '#000',
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        borderTop: '1px solid rgba(0,0,0,.1)',
      },
    },
  },
  mobileTableLabel: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#595959',
  },
  mobileEnd: {
    justifyContent: 'flex-end',
  },
  mobileTableName: {
    fontSize: '0.85rem',
    lineHeight: '1.5rem',
  },
  mobileDivider: {
    marginTop: theme.spacing(1),
  },
  black: {
    color: '#000',
  },
  mobileTableBorderTop: {
    borderTop: '1px solid rgba(0,0,0,.1)',
  },
  alert: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      // borderRadius: '25px',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  linkStyle: {
    color: 'black',
  },
  cancelBut: {
    padding: '8px 55px',
  },
  mobileBtnSizeModifier: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectBut: {
    padding: '8px 16px',
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '15px',
    },
  },
  modalFooter: {
    display: 'flex',
    marginTop: 32,
    justifyContent: 'flex-start',
    '& .MuiButton-root': {
      minWidth: 50,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  signatory: {
    width: 320,
    [theme.breakpoints.down('xs')]: {
      width: 130,
    },
  },
  status: {
    width: 160,
    [theme.breakpoints.down('xs')]: {
      width: 90,
    },
  },
  modalErrorMessage: {
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    '& > svg': {
      marginRight: 15,
    },
    border: '1px solid #D4E2FC',
    marginTop: 30,
  },
  iconErrorMessage: {
    padding: theme.spacing(0, 1.5, 0, 0),
  },
  comment: {
    borderTop: '0',
  },
  docTypeLabel: {
    color: '#000000',
    backgroundColor: '#f0f0f0',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    fontSize: '12px',
    marginRight: 2,
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))
