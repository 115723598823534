import React, { FC } from 'react'
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useParams } from 'react-router-dom'
import { useTotalUnreadDocCount } from '../../../hooks'

import { StyledBadge } from '../StyledBadge'
import clsx from 'clsx'
import { APP_PATHS, PATH_PARAMS } from '../../../routes/paths'

const useStyles = makeStyles(() => ({
  itemControlPanel: {
    width: '100%',
    position: 'relative',
    margin: '0 0 20px 0',
    '& .MuiTab-labelIcon': {
      minHeight: '40px',
    },
  },
  titleTabs: {
    fontSize: '16px',
    textTransform: 'initial',
    fontWeight: 'bold',
    padding: 0,
    margin: '0 32px 0 0',
    maxWidth: 'auto',
    minWidth: 'auto',
    color: '#434343',
    opacity: 1,
    '&.Mui-selected': {
      opacity: 1,
      color: '#000000',
      pointerEvents: 'none',
    },
  },
  badge: {
    '& .MuiBadge-root': {
      paddingRight: 35,
    },
  },
}))

export enum DocumentsTabName {
  Received = 'received',
  Uploaded = 'uploaded',
}

export const CmDocumentsTabs: FC<{
  handleChange: (_: React.SyntheticEvent<EventTarget>, newValue: number) => void
}> = ({ handleChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.docsTab]: docsTab,
  } = useParams() as Record<string, string>
  const { docsCount } = useTotalUnreadDocCount()

  return (
    <Box className={classes.itemControlPanel}>
      <Tabs value={docsTab} onChange={handleChange} data-test="tabBar">
        <Tab
          value={DocumentsTabName.Received}
          label={
            <StyledBadge badgeContent={docsCount} color="secondary" overlap="rectangular">
              {t('receivedDocuments', 'Received documents')}
            </StyledBadge>
          }
          className={clsx(classes.titleTabs, docsCount ? classes.badge : '', 'withBadge')}
          component={Link}
          to={generatePath(APP_PATHS.dashboard.contractManagement.documents, {
            applicationId,
            [PATH_PARAMS.docsTab]: DocumentsTabName.Received,
          })}
          data-test="receivedDocumentsTab"
        />

        <Tab
          value={DocumentsTabName.Uploaded}
          label={t('uploadedDocuments', 'Uploaded documents')}
          className={classes.titleTabs}
          component={Link}
          to={generatePath(APP_PATHS.dashboard.contractManagement.documents, {
            applicationId,
            [PATH_PARAMS.docsTab]: DocumentsTabName.Uploaded,
          })}
          data-test="uploadedDocumentsTab"
        />
      </Tabs>
    </Box>
  )
}
