import React, { FC, useEffect } from 'react'
import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isBoolean } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Maybe, PreAssessment, useGetContractDetailsQuery } from '../../../graphql'
import { PATH_PARAMS } from '../../../routes/paths'
import { FormControlledTextField } from '../../Common'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'
import { useCommonStyles } from './helpers/functions'
import { YesNoSelector } from './YesNoSelector'

const useStyles = makeStyles((theme: Theme) => ({
  whiteBackground: {
    backgroundColor: theme.palette.background.paper,
  },
  dirtyControl: {
    position: 'relative',
  },
}))

export const RegulatorInfoSection: FC<{
  changedContractProfileData?: Maybe<PreAssessment>
  readOnly?: boolean
}> = ({ changedContractProfileData, readOnly = false }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: contractData } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const methods = useFormContext()
  const { formState, setValue, watch } = methods

  const licenseRequired = watch('licenseRequired')

  useEffect(() => {
    const initialCheckboxValue = contractData?.contract?.preAssessment?.licenseRequired
    const isSelected = isBoolean(initialCheckboxValue)
      ? initialCheckboxValue
      : String(initialCheckboxValue) === 'true'
    setValue('licenseRequired', isSelected)
  }, [contractData])

  useEffect(() => {
    if (!licenseRequired) {
      setValue('regulatorNameAndWebsite', '')
      setValue('licenseNumber', '')
    }
  }, [licenseRequired])

  return (
    <>
      <YesNoSelector
        name="licenseRequired"
        label={t(
          'isYourBusinessRegulatedByAuthority',
          'Is your business regulated by an authority?',
        )}
        disabled={readOnly}
        mt={2}
      />
      <Box hidden={!licenseRequired}>
        <>
          <Box>
            <FormControlledTextField
              className={classes.whiteBackground}
              label={t('nameOfRegulator', 'Name of the regulator')}
              name="regulatorNameAndWebsite"
              type="text"
              fullWidth
              multiline
              required={false}
              data-test="regulatorName"
            />
            {changedContractProfileData?.regulatoryInfoName && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={
                  !changedContractProfileData?.regulatoryInfoName ||
                  formState.errors.regulatorNameAndWebsite
                }
                title={changedContractProfileData?.regulatoryInfoName ?? ''}
              />
            )}
          </Box>
          <Box>
            <FormControlledTextField
              className={classes.whiteBackground}
              label={t('licenseNumber', 'License number')}
              name="licenseNumber"
              type="text"
              fullWidth
              required={false}
              multiline
              data-test="licenseNumber"
            />
            {changedContractProfileData?.regulatoryInfoLicenseNumber && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                data-test={'changeApproved'}
                hidden={
                  !changedContractProfileData?.regulatoryInfoLicenseNumber ||
                  formState.errors.licenseNumber
                }
                title={changedContractProfileData?.regulatoryInfoLicenseNumber ?? ''}
              />
            )}
          </Box>
        </>
      </Box>
    </>
  )
}
