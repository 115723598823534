import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const DeleteUboResidencyModal: FC<{
  open: boolean
  onClose: () => void
  onDelete: () => void
}> = ({ open, onClose, onDelete }) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography>{t('deleteControllingPerson', 'Delete controlling person')}</Typography>
          </Grid>

          <Grid item>
            <IconButton aria-label="close modal" edge="end" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t(
            'areYouSureYouWishToDeleteThisControllingPerson?',
            'Are you sure you wish to delete this controlling person',
          )}
          ?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box mr={2} mb={1} display="flex" flexDirection="row" gridGap={24}>
          <Button onClick={onDelete} variant="contained" color="secondary">
            {t('delete', 'Delete')}
          </Button>
          <Button onClick={onClose} variant="contained">
            {t('cancel', 'Cancel')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
