import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useContractAlertDotStyles = (size: number, top = 0) =>
  makeStyles(() => ({
    alertDot: {
      position: 'absolute',
      top,
      right: 0,
      width: size,
      height: size,
      borderRadius: '50%',
      backgroundColor: '#EF2828',
    },
  }))()

type ContractAlertDotProps = {
  size: number
  hidden: boolean
  top?: number
}

export const ContractAlertDot: FC<ContractAlertDotProps> = ({ size, hidden, top }) => {
  const classes = useContractAlertDotStyles(size, top)
  return <Box component={'span'} className={classes.alertDot} hidden={hidden} />
}
