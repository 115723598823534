import { businessCategoryQuestions } from '@klarpay/enums'
import { filter, intersection, map } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressStep } from '../../Onboarding/Progress'
import { getPercentage } from '../helpers/functions'

export const RegulatoryInformationPoint: FC<{
  filledFields: string[]
  ErrorList: string[]
  isBusinessRelationshipWithCompany: boolean
  businessSector: string
  isLicenseRequired: boolean
  isLimited: boolean | undefined
}> = ({
  filledFields,
  ErrorList,
  isBusinessRelationshipWithCompany,
  businessSector,
  isLicenseRequired,
  isLimited,
}) => {
  const { t } = useTranslation()

  const businessSectorQuestions = useMemo(() => {
    return filter(businessCategoryQuestions, (question) => {
      return map(question.businessTypes, 'question.key').includes(businessSector)
    }).map(({ key }) => `businessSector${key}`)
  }, [businessSector])

  const requiredRegulatoryInformationFields = [
    'businessSector',
    'businessActivitiesAndServices',
    'countriesOfOperatingAndConductingBusiness',
    'anticipatedAccountActivity',
    'paymentProviders',
    'sourceOfFunds',
    'businessRelationshipWithCompany',
    ...(isLimited ? [] : ['uboResidencies']),
    ...(isBusinessRelationshipWithCompany ? ['businessRelationshipWithCompanyExplanation'] : []),
    ...businessSectorQuestions,
    ...(isLicenseRequired ? ['licenseRequired', 'regulatorNameAndWebsite'] : []),
  ]

  const optionalRegulatoryInformationFields = [...(isLicenseRequired ? ['licenseNumber'] : [])]

  const hasErrors = useMemo(
    () =>
      !!intersection(
        [...requiredRegulatoryInformationFields, ...optionalRegulatoryInformationFields],
        ErrorList,
      ).length,
    [optionalRegulatoryInformationFields, requiredRegulatoryInformationFields, ErrorList],
  )

  const isRegulatoryInformationCompleted = useMemo(
    () =>
      requiredRegulatoryInformationFields.every((field) => filledFields.includes(field)) &&
      !hasErrors,
    [requiredRegulatoryInformationFields, hasErrors],
  )

  return (
    <ProgressStep
      stage={
        isRegulatoryInformationCompleted
          ? 'done'
          : getPercentage(requiredRegulatoryInformationFields, filledFields)
      }
      error={hasErrors}
      label={t('regulatoryInformation', 'Regulatory information')}
    />
  )
}
