import React, { FC, ReactNode } from 'react'
import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import InfoIcon from '../../assets/images/icons/info_icon.svg?react'

const useStyles = (fontSize: number) =>
  makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      margin: theme.spacing(2, 0, 3),
      fontSize: '16px',
      border: '1px solid #D4E2FC',
      width: '100%',
      height: 'auto',
      padding: '10px 15px 10px 10px',
    },
    info: {
      paddingLeft: 18,
      fontSize: `${fontSize}px`,
    },
  }))()

const InfoBlockComponent: FC<{ fontSize?: number; children: ReactNode }> = ({
  fontSize = 16,
  children,
}) => {
  const classes = useStyles(fontSize)

  return (
    <Box className={classes.container}>
      <Grid>
        <InfoIcon />
      </Grid>
      <Grid className={classes.info}>
        <Box>{children}</Box>
      </Grid>
    </Box>
  )
}

export const InfoBlock = React.memo(InfoBlockComponent)
