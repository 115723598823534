import * as yup from 'yup'
import { isEmpty, toNumber } from 'lodash'
import i18n from '../i18n'

import {
  THIS_FIELD_IS_REQUIRED_MESSAGE,
  NUMBER_AND_LATINICA_AND_SYMBOLS,
  COMPANY_NAME_REGEX,
  INCORRECT_EMAIL_FORMAT_MESSAGE,
  LATINICA_ONLY_REGEXP,
  ALL_EXCEPT_CYRILLIC_REGEXP,
  NUMBERS_DASHES_COMMAS_FULLSTOPS,
  PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS,
  PRE_ASSESSMENT_PERCENTS_REGEXP,
  PERCENTS_ONLY,
  PRE_ASSESSMENT_SUM_OF_PERCENTS100,
  PRE_ASSESSMENT_SUM_OF_PERCENTS0,
  MIN_LENGTH,
  NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS,
  PRE_ASSESSMENT_COMMON_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
  PRE_ASSESSMENT_LONG_FIELD_MAX,
  PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG,
  LATINICA_ONLY_MESSAGE,
  EMAIL_REGEXP,
  NUMBERS_ONLY_MESSAGE,
} from '../constants/validations'

export const PreAssessmentInputSchema = yup.object().shape({
  registeredName: yup
    .string()
    .test(
      'registeredNameMinLength',
      NUMBER_AND_LATINICA_AND_SYMBOLS,
      (val) => !!val && val?.length >= MIN_LENGTH,
    )
    .matches(COMPANY_NAME_REGEX, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS)
    .test('registeredNameMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  tradingNamesAndUrls: yup
    .string()
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, LATINICA_ONLY_MESSAGE)
    .test('tradingNamesAndUrlsMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  country: yup
    .mixed()
    .test('countryNotEmpty', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !isEmpty(val)),
  regulatorNameAndWebsite: yup
    .string()
    .test(
      'regulatorNameAndWebsite',
      LATINICA_ONLY_MESSAGE,
      (val) => isEmpty(val) || !!val?.match(ALL_EXCEPT_CYRILLIC_REGEXP),
    )
    .test('regulatorNameAndWebsiteMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !val || (!!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX)
    }),
  licenseNumber: yup
    .string()
    .test(
      'licenseNumber',
      LATINICA_ONLY_MESSAGE,
      (val) => isEmpty(val) || !!val?.match(ALL_EXCEPT_CYRILLIC_REGEXP),
    )
    .test('licenseNumberMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !val || (!!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX)
    }),
  mainUsage: yup
    .string()
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, LATINICA_ONLY_MESSAGE)

    .test('mainUsageMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  businessActivitiesAndServices: yup
    .string()
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS)
    .test(
      'businessActivitiesAndServicesMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_LONG_FIELD_MAX
      },
    )
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  paymentProviders: yup
    .string()
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS)
    .test('paymentProvidersMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_LONG_FIELD_MAX
    })
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  countriesOfOperations: yup
    .string()
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, NUMBER_AND_LATINICA_AND_SPECIAL_SYMBOLS)
    .test('countriesOfOperationsMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  companyAnnualIncome: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test(
      'companyAnnualIncome',
      NUMBERS_DASHES_COMMAS_FULLSTOPS,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test('companyAnnualIncomeMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    }),
  companyAnnualIncomeCurrency: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  estimatedIncomingTransactions: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test(
      'estimatedIncomingTransactions',
      NUMBERS_ONLY_MESSAGE,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test(
      'estimatedIncomingTransactionsMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
      },
    ),
  currencyIncomingTransactions: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  averageIncomingTransactionsAmount: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test(
      'averageIncomingTransactionsAmount',
      NUMBERS_DASHES_COMMAS_FULLSTOPS,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test(
      'averageIncomingTransactionsAmountMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
      },
    ),
  currencyIncomingTransactionsAmount: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test(
      'currencyIncomingTransactionsAmountMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
      },
    )
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  clientsCompaniesListIncoming: yup
    .string()
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, LATINICA_ONLY_MESSAGE)
    .test(
      'clientsCompaniesListIncomingMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_LONG_FIELD_MAX
      },
    )
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  estimatedOutgoingTransactions: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test(
      'estimatedOutgoingTransactions',
      NUMBERS_ONLY_MESSAGE,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test(
      'estimatedOutgoingTransactionsMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
      },
    ),
  currencyOutgoingTransactions: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  averageOutgoingTransactionsAmount: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test(
      'averageOutgoingTransactionsAmount',
      NUMBERS_DASHES_COMMAS_FULLSTOPS,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test(
      'averageOutgoingTransactionsAmountMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
      },
    ),
  currencyOutgoingTransactionsAmount: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  estimatedOutgoingTransactionsAmount: yup
    .string()
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE)
    .test(
      'estimatedOutgoingTransactionsAmount',
      NUMBERS_DASHES_COMMAS_FULLSTOPS,
      (val) => isEmpty(val) || !!val?.match(PRE_ASSESSMENT_NUMBERS_DASHES_COMMAS_FULLSTOPS),
    )
    .test(
      'estimatedOutgoingTransactionsAmountMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
      },
    ),
  clientsCompaniesListOutgoing: yup
    .string()
    .matches(ALL_EXCEPT_CYRILLIC_REGEXP, LATINICA_ONLY_MESSAGE)
    .test(
      'clientsCompaniesListOutgoingMaxLength',
      PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_LONG,
      (val) => {
        return !!val && val?.length <= PRE_ASSESSMENT_LONG_FIELD_MAX
      },
    )
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  expectedVolumes: yup
    .mixed()
    .notRequired()
    .test('expectedVolumes100', PRE_ASSESSMENT_SUM_OF_PERCENTS100, function () {
      return (
        toNumber(this.parent.expectedVolume0 ?? '0') +
          toNumber(this.parent.expectedVolume1 ?? '0') +
          toNumber(this.parent.expectedVolume2 ?? '0') +
          toNumber(this.parent.expectedVolume3 ?? '0') <=
        100
      )
    })
    .test('expectedVolumes0', PRE_ASSESSMENT_SUM_OF_PERCENTS0, function () {
      return (
        toNumber(this.parent.expectedVolume0 ?? '0') +
          toNumber(this.parent.expectedVolume1 ?? '0') +
          toNumber(this.parent.expectedVolume2 ?? '0') +
          toNumber(this.parent.expectedVolume3 ?? '0') >
        0
      )
    }),

  expectedCurrency0: yup
    .string()
    .test('expectedCurrency0', THIS_FIELD_IS_REQUIRED_MESSAGE, function () {
      return isEmpty(this.parent.expectedVolume0) || !isEmpty(this.parent.expectedCurrency0)
    }),
  expectedVolume0: yup
    .string()
    .test(
      'expectedVolume0',
      PERCENTS_ONLY,
      (val) =>
        isEmpty(val) || (!!val?.match(PRE_ASSESSMENT_PERCENTS_REGEXP) && toNumber(val) <= 100),
    ),
  expectedCurrency1: yup
    .string()
    .test('expectedCurrency1', THIS_FIELD_IS_REQUIRED_MESSAGE, function () {
      return isEmpty(this.parent.expectedVolume1) || !isEmpty(this.parent.expectedCurrency1)
    }),
  expectedVolume1: yup
    .string()
    .test(
      'expectedVolume1',
      PERCENTS_ONLY,
      (val) =>
        isEmpty(val) || (!!val?.match(PRE_ASSESSMENT_PERCENTS_REGEXP) && toNumber(val) <= 100),
    ),
  expectedCurrency2: yup
    .string()
    .test('expectedCurrency2', THIS_FIELD_IS_REQUIRED_MESSAGE, function () {
      return isEmpty(this.parent.expectedVolume2) || !isEmpty(this.parent.expectedCurrency2)
    }),
  expectedVolume2: yup
    .string()
    .test(
      'expectedVolume2',
      PERCENTS_ONLY,
      (val) =>
        isEmpty(val) || (!!val?.match(PRE_ASSESSMENT_PERCENTS_REGEXP) && toNumber(val) <= 100),
    ),
  expectedCurrency3: yup
    .string()
    .test('expectedCurrency3', THIS_FIELD_IS_REQUIRED_MESSAGE, function () {
      return isEmpty(this.parent.expectedVolume3) || !isEmpty(this.parent.expectedCurrency3)
    }),
  expectedVolume3: yup
    .string()
    .test(
      'expectedVolume3',
      PERCENTS_ONLY,
      (val) =>
        isEmpty(val) || (!!val?.match(PRE_ASSESSMENT_PERCENTS_REGEXP) && toNumber(val) <= 100),
    ),
  fullName: yup
    .string()
    .matches(COMPANY_NAME_REGEX, NUMBER_AND_LATINICA_AND_SYMBOLS)
    .test('fullNameMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .required(THIS_FIELD_IS_REQUIRED_MESSAGE),
  email: yup
    .string()
    .required(i18n.t('emailIsRequired', 'Email is required'))
    .test('emailMaxLength', PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON, (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .matches(EMAIL_REGEXP, INCORRECT_EMAIL_FORMAT_MESSAGE),
  phoneNumber: yup
    .string()
    .nullable()
    .test('phoneNumberMaxLength', i18n.t(PRE_ASSESSMENT_MAX_LENGTH_MESSAGE_COMMON), (val) => {
      return !!val && val?.length <= PRE_ASSESSMENT_COMMON_FIELD_MAX
    })
    .required(i18n.t('phoneNumberRequired', 'Phone number is required')),
})

export type PreAssessmentInput = yup.Asserts<typeof PreAssessmentInputSchema>
