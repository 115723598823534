import { Box, Button, TableCell, TableRow } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { find } from 'lodash'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CountriesList } from '../../../../utils/Data'
import { DeleteUboResidencyModal } from './DeleteUboResidencyModal'

const useStyles = makeStyles((theme: Theme) => ({
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
}))

export const UboTableRow: FC<{
  value: { countries: string[] }
  index: number
  setEditingUbo: React.Dispatch<
    React.SetStateAction<{ i: number; countries: string[] } | undefined>
  >
  remove: (index?: number | number[] | undefined) => void
}> = ({ value, index, setEditingUbo, remove }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleDelete = (i: number) => {
    remove(i)
    setOpenDeleteModal(false)
  }

  return (
    <TableRow data-test="autotest-uboResidencyCountryRow" hover>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {(value?.countries ?? []).map((c) => find(CountriesList, { key: c })?.label).join(', ')}
      </TableCell>
      <TableCell>
        <Box className={classes.buttonsBox}>
          <Button
            type="button"
            onClick={() => setEditingUbo({ i: index, countries: value.countries })}
            data-test="autotest-editUboResidency"
          >
            {t('edit', 'Edit')}
          </Button>
          <Button
            color="secondary"
            aria-label="delete"
            type="button"
            onClick={() => setOpenDeleteModal(true)}
            data-test="autotest-deleteUboResidency"
          >
            {t('delete', 'Delete')}
          </Button>
        </Box>
      </TableCell>
      <DeleteUboResidencyModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={() => handleDelete(index)}
      />
    </TableRow>
  )
}
