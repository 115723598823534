import React, { FC, MouseEvent, useState } from 'react'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = (icon: boolean) =>
  makeStyles((theme: Theme) => ({
    // Modal
    modal: {
      position: 'absolute',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      [theme.breakpoints.up('sm')]: {
        maxWidth: 512,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'calc(100% - 32px)',
      },
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(3, 3, 2),
      alignItems: 'center',
      '& > svg': {
        marginRight: 15,
      },
    },
    modalContent: {
      padding: theme.spacing(0, 3),
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
      '& .MuiButton-root': {
        minWidth: 50,
        '&:last-child': {
          [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
          },
        },
        '&:first-child': {
          [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
          },
        },
      },
    },
    btnClose: {
      minWidth: 'auto',
      minHeight: 'auto',
    },
    title: {
      marginLeft: icon ? theme.spacing(1.5) : 0,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
  }))()

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

type ModalConfirmProps = {
  title?: string
  color?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
    | undefined
  name: string
  icon?: React.ReactNode | undefined
  children?: React.ReactNode
  handleClose?: () => void
  handleConfirm?: (() => void) | ((event: MouseEvent<HTMLElement>) => Promise<void>)
  disableConfirm?: boolean
  isOpen: boolean
  labelCancel?: string | undefined
  labelConfirm?: string | undefined
}

export const ConfirmationAnyModal: FC<ModalConfirmProps> = ({
  title,
  color,
  name = 'modal',
  icon,
  children,
  handleClose,
  handleConfirm,
  isOpen,
  labelCancel,
  labelConfirm,
  disableConfirm = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles(!!icon)
  const [modalStyle] = useState(getModalStyle)
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={`simple-${name}-title`}
      aria-describedby={`simple-${name}-description`}
    >
      <Box style={modalStyle} className={`${classes.modal}`}>
        <Box className={classes.modalHeader}>
          <Box className={classes.flex}>
            {icon}
            <Typography variant={'h4'} color={color} className={classes.title}>
              {`${title}`}
            </Typography>
          </Box>
          <Button onClick={handleClose} className={classes.btnClose}>
            <CloseIcon fontSize={'small'} />
          </Button>
        </Box>

        <Box className={classes.modalContent}>{children}</Box>

        <Box className={classes.modalFooter}>
          <Button
            variant="contained"
            onClick={handleClose}
            disableElevation
            data-test="cancelAuthorisedPerson-btn"
          >
            {labelCancel ? labelCancel : t('cancel', 'Cancel')}
          </Button>
          {!!handleConfirm && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={disableConfirm}
              disableElevation
              data-test={labelConfirm ? labelConfirm : t('confirm', 'Confirm')}
            >
              {labelConfirm ? labelConfirm : t('confirm', 'Confirm')}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
