import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, Theme, Typography } from '@material-ui/core'
import { isBoolean } from 'lodash'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router'
import { Maybe, PreAssessment, useGetContractDetailsQuery } from '../../../graphql'
import { PATH_PARAMS } from '../../../routes/paths'
import { FormControlledTextField, GridRow } from '../../Common'
import { useCommonStyles } from './helpers/functions'
import Checked from '../../../assets/images/icons/cheked_icon.svg?react'
import Unchecked from '../../../assets/images/icons/unchecked_icon.svg?react'
import ControlledTooltipWrapped from '../../Common/Tooltips/ControlledTooltipWrapped'

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1.5, 1.5),
    },
  },
  dirtyControl: {
    position: 'relative',
  },
}))

const RegulatorInformationComponent: FC<{
  changedContractProfileData?: Maybe<PreAssessment>
}> = ({ changedContractProfileData }) => {
  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: contractData } = useGetContractDetailsQuery({
    variables: { id: +applicationId },
    skip: !applicationId,
  })

  const methods = useFormContext()
  const { register, formState, setValue } = methods

  const [licenseRequired, setLicenseRequired] = useState<boolean>(false)

  const handleLicenceRequired = useCallback(
    (_, checked) => {
      if (!checked) {
        setValue('regulatorNameAndWebsite', '')
        setValue('licenseNumber', '')
      }
      setLicenseRequired(checked)
    },
    [setLicenseRequired, setValue],
  )

  useEffect(() => {
    const initialCheckboxValue = contractData?.contract?.preAssessment?.licenseRequired
    const isChecked = isBoolean(initialCheckboxValue)
      ? initialCheckboxValue
      : String(initialCheckboxValue) === 'true'
    setLicenseRequired(isChecked)
  }, [contractData])

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h3" className={classes.title}>
          {t('regulatorInformation', 'Regulator Information')}
        </Typography>
      </Box>
      <GridRow>
        <FormControlLabel
          control={
            <Checkbox checked={licenseRequired} icon={<Unchecked />} checkedIcon={<Checked />} />
          }
          name="licenseRequired"
          inputRef={register}
          onChange={handleLicenceRequired}
          label={t(
            'licenseRequired',
            'My business is licensed / is in the process of obtaining a license from a regulator',
          )}
          className={classes.checkbox}
          data-test="licenseRequiredCheckBox"
        />
      </GridRow>
      <Box hidden={!licenseRequired}>
        <>
          <GridRow>
            <FormControlledTextField
              label={t('regulatoryInfoName', 'Regulator Name and Website')}
              name="regulatorNameAndWebsite"
              type="text"
              fullWidth
              multiline
              required={false}
              data-test="regulatorName"
            />
            {changedContractProfileData?.regulatoryInfoName && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={
                  !changedContractProfileData?.regulatoryInfoName ||
                  formState.errors.regulatorNameAndWebsite
                }
                title={changedContractProfileData?.regulatoryInfoName ?? ''}
              />
            )}
          </GridRow>
          <GridRow>
            <FormControlledTextField
              label={t('regulatoryInfoLicenseNumber', 'License Number (if obtained) (optional)')}
              name="licenseNumber"
              type="text"
              fullWidth
              required={false}
              multiline
              data-test="licenseNumber"
            />
            {changedContractProfileData?.regulatoryInfoLicenseNumber && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                data-test={'changeApproved'}
                hidden={
                  !changedContractProfileData?.regulatoryInfoLicenseNumber ||
                  formState.errors.licenseNumber
                }
                title={changedContractProfileData?.regulatoryInfoLicenseNumber ?? ''}
              />
            )}
          </GridRow>
        </>
      </Box>
    </>
  )
}

export const RegulatorInformation = React.memo(RegulatorInformationComponent)
