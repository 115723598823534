import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  IInputProps,
  ILayoutProps,
  IMeta,
  StatusValue,
} from 'react-dropzone-uploader'
import { remove, find, forEach, isEqual } from 'lodash'
import { toast } from 'react-toastify'
import { useFormContext } from 'react-hook-form'

import { Box, FormHelperText, Hidden, makeStyles, Theme, Typography } from '@material-ui/core'

import './styles.css'
import {
  getFilesFromEvent,
  getUploadParams,
} from '../../../utils/HelpFunctions/uploadHelpFunctions'
import UploadIcon from './../../../assets/images/icons/upload.svg?react'
import Attention from '../../../assets/images/icons/attention.svg?react'
import { reportAppError, sizeInKB } from '../../../utils'
import { FilesForSavingAndShowType } from '../../../types'
import { FilesListUpload } from './FilesListUpload'
import { useGetSignedUrlMutation } from '../../../graphql'
import { FormDatePickerField } from '../../Common'
import { DOCUMENTS_TYPE, MAX_FILE_SIZE_BYTES } from '../../../constants'
import {
  dateRequiredFieldsList,
  ProofOfAddressList,
  ProofOfIdentityList,
} from '../../../utils/Data'

interface IMetaWithFileUrl extends IMeta {
  fileUrl: string
}

interface IFileWithWidenedMeta extends IFileWithMeta {
  file: File
  meta: IMetaWithFileUrl
  cancel: () => void
  restart: () => void
  remove: () => void
  xhr?: XMLHttpRequest
}

const useStyles = (
  errorFileSize: boolean,
  rejectedFileType: boolean,
  isSelectFileWindowOpen: boolean,
) =>
  makeStyles((theme: Theme) => ({
    dzuInputLabel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      top: 0,
      bottom: 0,
      left: 50,
      right: 0,
      fontFamily: 'Helvetica, sans-serif',
      fontSize: '20px',
      fontWeight: 600,
      color: '#ccc',
      cursor: 'pointer',
    },
    DzuInput: {
      '& .dzu-dropzone': {
        height: 120,
        border: errorFileSize || rejectedFileType ? '1px solid #F0F0F0' : '1px solid #f0f0f0',
        overflow: 'hidden',
      },
    },
    dropzoneInner: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',

      '& input': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: 0,
        '&:hover': {
          backgroundColor: 'red',
          boxShadow: 'inset -1px -3px 0px -1px #000',
          cursor: !isSelectFileWindowOpen ? 'pointer' : 'default',
        },
      },
    },
    dropzoneInnerText: {
      marginTop: theme.spacing(1.5),
      fontSize: '0.75rem',
      color: isSelectFileWindowOpen ? 'grey' : 'inherit',
    },
    filesList: {
      // marginTop: '-12px',
    },
    iconErrorMessage: {
      padding: theme.spacing(0, 1.5, 0, 0),
    },
    modal: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      marginTop: 24,
    },
    docLabel: {
      margin: '16px 0 0 0',
      fontSize: '14px',
      lineHeight: '24px',
      color: isSelectFileWindowOpen ? 'grey' : 'inherit',
    },
    modalErrorMessage: {
      display: 'flex',
      padding: 8,
      alignItems: 'center',
      '& > svg': {
        marginRight: 15,
      },
      border: '1px solid #fed7d2',
      marginTop: 30,
    },
    wrapFileStuff: {
      '& > div': {
        marginTop: '0px',
      },
    },
    helperText: {
      position: 'relative',
      color: theme.palette.error.main,
    },
  }))()

interface MetaPreview {
  meta: IMeta
  fileWithMeta: IFileWithMeta
}

const DocumentUploadControllingPersonComponent: FC<{
  typeDoc?: string
  isFirstSide?: boolean
  filesForUploadRegName?: string
  documentTypeRegName?: string
  expiryDateRegName?: string
  isFileUploadingNow: boolean
  setIsFileUploadingNow: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  typeDoc,
  isFirstSide = true,
  filesForUploadRegName,
  documentTypeRegName,
  expiryDateRegName,
  isFileUploadingNow,
  setIsFileUploadingNow,
}) => {
  const { t } = useTranslation()
  const { setValue, watch, errors, trigger } = useFormContext()
  const [errorFileSize, setErrorFileSize] = useState(false)
  const [rejectedFileType, setRejectedFileType] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<IFileWithWidenedMeta>()
  const classes = useStyles(errorFileSize, rejectedFileType, isFileUploadingNow)
  const [getSignedUrlMutation, { error }] = useGetSignedUrlMutation({})
  const filesForUpload = !!filesForUploadRegName
    ? watch(filesForUploadRegName)
    : watch(`filesForUpload`)
  const filesForUploadVar = !!filesForUploadRegName ? filesForUploadRegName : `filesForUpload`
  const documentType = !!documentTypeRegName
    ? watch(documentTypeRegName)
    : typeDoc
    ? typeDoc
    : watch(`documentType`)
  const expiryDate: Date = !!expiryDateRegName ? watch(expiryDateRegName) : watch(`expiryDate`)
  const expiryDateVar = !!expiryDateRegName ? expiryDateRegName : `expiryDate`
  const requireExpiryDate = documentType && dateRequiredFieldsList.includes(documentType)

  const formError = typeDoc ? errors[typeDoc] : null

  useEffect(() => {
    if (error) {
      toast.error(
        t('errorDocumentUpload', 'Unable to upload document at the moment, please try again later'),
      )
      reportAppError(error)
    }
  }, [error, t])

  useEffect(() => {
    const tempFilesForUpload: FilesForSavingAndShowType[] = []
    forEach(filesForUpload, (itemFile) => {
      const tempDocument: FilesForSavingAndShowType = itemFile
      tempDocument.expiration = expiryDate
      tempFilesForUpload.push(tempDocument)
    })
    setValue(filesForUploadVar, tempFilesForUpload)
  }, [expiryDate, setValue, filesForUploadVar])

  const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (
    file: IFileWithWidenedMeta,
    status: StatusValue,
    allFiles: IFileWithWidenedMeta[],
  ) => {
    setIsFileUploadingNow(status === 'uploading')

    status === 'rejected_file_type' ? setRejectedFileType(true) : setRejectedFileType(false)
    if (status === 'error_file_size') {
      file.remove()
      setErrorFileSize(true)
    } else {
      setErrorFileSize(false)
    }
    if (status === 'done') {
      const tempArray: FilesForSavingAndShowType[] = []
      forEach(filesForUpload, (fileItem) => tempArray.push(fileItem))
      forEach(allFiles, (itemFile) => {
        if (
          !find(
            filesForUpload as FilesForSavingAndShowType[],
            (o) =>
              o.fileName === itemFile.meta.name && o.size === itemFile.meta.size && !o.isRemoved,
          )
        ) {
          const tempDocument: FilesForSavingAndShowType = {
            id: 0,
            fileName: itemFile.meta.name,
            size: itemFile.meta.size,
            docType: typeDoc ?? 'other',
            plainUrl: itemFile.meta?.fileUrl,
            metaType: itemFile.meta?.type,
            isFirstSide: isFirstSide,
          }
          tempArray.push(tempDocument)
        }
      })
      setValue(filesForUploadVar, tempArray)

      if (typeDoc) trigger(typeDoc)

      setUploadedFile(file)
    } else if (status === 'removed') {
      setUploadedFile(undefined)
    } else if (status === 'error_upload') {
      toast.error(t('uploadError', 'Upload error'))
    }
  }

  const handleDisable = (e: React.MouseEvent<EventTarget>) => {
    if (isFileUploadingNow) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const Preview = ({ meta, fileWithMeta }: MetaPreview) => {
    const { name, percent, status, size, type } = meta
    const docType = find(
      filesForUpload as FilesForSavingAndShowType[],
      (o) => o.fileName === meta.name && o.size === meta.size && o.docType === typeDoc,
    )?.docType

    function removeFileHandler() {
      const tempArray: FilesForSavingAndShowType[] = []
      forEach(filesForUpload, (fileItem) => tempArray.push(fileItem))
      remove(tempArray as FilesForSavingAndShowType[], function (removedItem) {
        return (
          removedItem.fileName === meta.name &&
          removedItem.size === meta.size &&
          removedItem.docType === typeDoc &&
          !removedItem.isRemoved
        )
      })
      setValue(filesForUploadVar, tempArray)
      fileWithMeta.remove()
      setErrorFileSize(false)
      setRejectedFileType(false)
    }

    return (
      <Box>
        <FilesListUpload
          name={name}
          size={sizeInKB(size)}
          percent={percent}
          status={status}
          removeFileHandler={() => removeFileHandler()}
          type={type}
          docType={docType ?? ''}
          optionalIdentityList={
            filesForUploadRegName === 'filesForUploadAddress'
              ? ProofOfAddressList
              : ProofOfIdentityList
          }
          forCp={true}
        />
      </Box>
    )
  }

  const Layout = ({ input, previews, dropzoneProps, files }: ILayoutProps) => {
    return (
      <Box className={classes.DzuInput} flexGrow={1} data-test="uploadFile">
        <Box className={classes.filesList}>{previews}</Box>
        {documentType && !files?.length && <div {...dropzoneProps}>{input}</div>}
        {errorFileSize && (
          <Box className={classes.modalErrorMessage}>
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>
              {t('fileExceedsMaximumSize', 'File exceeds maximum size of 15 MB')}
            </Typography>
          </Box>
        )}
        {rejectedFileType && (
          <Box className={classes.modalErrorMessage}>
            <Box component="span" className={classes.iconErrorMessage}>
              <Attention />
            </Box>
            <Typography>
              {t('wrongDocumentFormat', 'Wrong document format. PDF, JPG and PNG are supported')}
            </Typography>
          </Box>
        )}
      </Box>
    )
  }

  const Input = ({ accept, onFiles, getFilesFromEvent }: IInputProps) => {
    return (
      <Box className={classes.dropzoneInner}>
        <input
          type="file"
          accept={accept}
          multiple={false}
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles)
            })
          }}
        />
        <UploadIcon />
        <Hidden smDown>
          <Box className={classes.dropzoneInnerText}>
            <strong>
              {t('drag', 'Drag')}&apos;{t('nDrop', 'n drop')}
            </strong>
            {t('orWithSpaces', ' or ')}
            <strong>{t('click', 'click')}</strong>
            {t('toUploadFiles', ' to upload files')}
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.dropzoneInnerText}>
            <strong>{t('tap', 'Tap')}</strong>
            {t('toUploadFiles', ' to upload files')}
          </Box>
        </Hidden>
      </Box>
    )
  }

  useEffect(() => {
    uploadedFile?.remove()
  }, [documentType])

  return typeDoc ? (
    <Box className={classes.modal}>
      <Typography className={classes.docLabel}>
        {find(DOCUMENTS_TYPE, (o) => o.value === typeDoc)?.label}
      </Typography>
      <Box onClick={handleDisable}>
        <Dropzone
          getUploadParams={getUploadParams(getSignedUrlMutation)}
          getFilesFromEvent={getFilesFromEvent}
          maxFiles={10}
          maxSizeBytes={MAX_FILE_SIZE_BYTES}
          classNames={{ inputLabel: classes.dzuInputLabel }}
          onChangeStatus={handleChangeStatus}
          PreviewComponent={Preview}
          LayoutComponent={Layout}
          disabled={(files) =>
            files.some((f) =>
              ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status),
            )
          }
          accept=".jpg,.jpeg,.png,.pdf"
          InputComponent={Input}
          autoUpload={true}
          multiple={false}
        />
      </Box>
      {!errors['undefinedExpiryDate'] && !!formError && (
        <FormHelperText className={classes.helperText}>{formError?.message}</FormHelperText>
      )}
      {isFirstSide && !!uploadedFile && requireExpiryDate && (
        <Box className={classes.wrapFileStuff}>
          <FormDatePickerField
            name={expiryDateVar}
            label={t('expiryDate', 'Expiry date')}
            noPast
            isShiftTimezone={false}
          />
        </Box>
      )}
    </Box>
  ) : null
}

export const DocumentUploadControllingPerson = React.memo(
  DocumentUploadControllingPersonComponent,
  isEqual,
)
