import {
  FundsType,
  LimitedAccessRight,
  SwiftTransferChargeBearer,
  SwiftTransferRecipientType,
} from '../graphql'
import i18n from '../i18n'
import { CurrencyType, RoleInvite } from '../types'
import { AddressPropsType } from '../components'
import { countries, currencies as curr } from '@klarpay/enums'
import { mapValues } from 'lodash'

export const currencies: Record<string, CurrencyType & { key: string }> = {
  ...mapValues(curr, (c) => ({
    ...c,
    allowedNewAccounts: c.allowedNewAccounts as FundsType[],
  })),
}

export const newSelectRoleInvite: Record<
  string,
  RoleInvite & { key: string; show: (hasCardPayment: boolean) => boolean }
> = {
  signatory: {
    key: 'authorizedSignatory',
    label: 'Signatory',
    show: () => true,
  },
  limitedAccessOnly: {
    key: LimitedAccessRight.ViewDataEntry,
    label: 'Limited access, View, and data entry',
    show: () => true,
  },
  limitedAccessView: {
    key: LimitedAccessRight.ViewOnly,
    label: 'Limited access, view-only',
    show: () => true,
  },
  cardholder: {
    key: LimitedAccessRight.CardHolder,
    label: 'Cardholder (no access to company’s account data)',
    show: () => true,
  },
  paymentFacilitation: {
    key: LimitedAccessRight.PaymentFacilitationView,
    label: 'Payment Facilitation view (no access to company’s account data)',
    show: (hasCardPayment) => hasCardPayment,
  },
}

export const paidList = {
  [SwiftTransferChargeBearer.Our]: {
    key: SwiftTransferChargeBearer.Our,
    label: i18n.t('sender', 'Sender'),
    disabled: false,
  },
  [SwiftTransferChargeBearer.Theirs]: {
    key: SwiftTransferChargeBearer.Theirs,
    label: i18n.t('recipient', 'Recipient'),
    disabled: true,
  },
  [SwiftTransferChargeBearer.Shared]: {
    key: SwiftTransferChargeBearer.Shared,
    label: i18n.t('divisionCharges', 'Division of charges'),
    disabled: false,
  },
}

export const recipientTypeList = [
  {
    key: SwiftTransferRecipientType.Individual,
    label: i18n.t('individual', 'Individual'),
  },
  {
    key: SwiftTransferRecipientType.Company,
    label: i18n.t('company', 'Company'),
  },
]

export const rates = { USD: '0.9327', EUR: '1.0809', CHF: '1', GBP: '1.2586' }

export const registeredAddressEmptyProps: AddressPropsType = {
  streetAddress: {
    name: 'streetAddress',
    initialValue: undefined,
  },
  additionalDetailsOptional: {
    name: 'additionalDetailsOptional',
    initialValue: undefined,
  },
  postalCode: {
    name: 'postalCode',
    initialValue: undefined,
  },
  city: {
    name: 'city',
    initialValue: undefined,
  },
}
export const purposeCountries: string[] = [
  countries.bh.key,
  countries.jo.key,
  countries.kz.key,
  countries.ae.key,
  countries.th.key,
]
export const purposeCountries2: string[] = [countries.kz.key]

export const creditorContractPhoneCountries: string[] = [
  countries.dz.key,
  countries.ar.key,
  countries.br.key,
  countries.cn.key,
  countries.co.key,
  countries.cr.key,
  countries.eg.key,
  countries.gt.key,
  countries.za.key,
  countries.kr.key,
]

export const creditorTaxIdCountries: string[] = [
  countries.ar.key,
  countries.az.key,
  countries.by.key,
  countries.br.key,
  countries.cl.key,
  countries.co.key,
  countries.cr.key,
  countries.do.key,
  countries.gt.key,
  countries.kz.key,
  countries.py.key,
  countries.pe.key,
  countries.uy.key,
]

export const clearingMemberIdCountries: string[] = [
  countries.au.key,
  countries.az.key,
  countries.ca.key,
  countries.nz.key,
  countries.gb.key,
  countries.us.key,
]
export const creditorAgentBranchIdCountries: string[] = [countries.bd.key, countries.jm.key]
export const creditorAgentBranchNameCountries: string[] = [
  countries.eg.key,
  countries.ke.key,
  countries.mn.key,
  countries.py.key,
  countries.tz.key,
  countries.uy.key,
]

export const idTypesList = [
  {
    key: 'nationalid',
    label: i18n.t('nationalId', 'National ID'),
  },
  {
    key: 'passport',
    label: i18n.t('passport', 'Passport'),
  },
]
