import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Theme,
  TableHead,
} from '@material-ui/core'
import {
  LegalEntityDirector,
  useRemoveLegalEntityDirectorMutation,
  useGetDirectorsQuery,
  GetDirectorsDocument,
  LimitedAccessRight,
} from '../../graphql'
import { useDetermineUserRights } from '../../hooks'
import { ConfirmationAnyModal, Loader } from '../Common'
import Attention from '../../assets/images/icons/attention.svg?react'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    borderTop: '0',
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
  },
}))

export const DirectorsTable: React.FC<{
  contractId: number | string
  showActions?: boolean
}> = ({ contractId, showActions = true }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [openDelete, setOpenDelete] = useState<string | number | undefined>()
  const [removeLegalEntityDirector] = useRemoveLegalEntityDirectorMutation()

  const { userRights } = useDetermineUserRights(contractId)
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly

  const { data, loading: directorsLoading } = useGetDirectorsQuery({
    variables: { id: contractId },
    fetchPolicy: 'network-only',
    skip: !contractId,
  })

  const directorsData = useMemo(() => {
    if (
      data?.contract?.owner &&
      'legalEntityDirectors' in data.contract.owner &&
      !!data.contract.owner.legalEntityDirectors?.length
    ) {
      return orderBy(
        data.contract.owner.legalEntityDirectors,
        'individual.firstName',
      ) as LegalEntityDirector[]
    }
    return []
  }, [data?.contract?.owner, directorsLoading])

  const onConfirmDelete = useCallback(async (id) => {
    await removeLegalEntityDirector({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: GetDirectorsDocument,
          variables: { id: contractId },
        },
      ],
      awaitRefetchQueries: true,
    })
    setOpenDelete(undefined)
  }, [])

  if (!directorsData) {
    return null
  }

  return (
    <>
      {directorsLoading ? (
        <Loader />
      ) : (
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('name', 'Name')}</TableCell>
                <TableCell width={168}>
                  {!isViewOnly && showActions && t('actions', 'Actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {directorsData.map((director) => {
                return (
                  <TableRow hover key={director.id} data-test="personAccount">
                    <TableCell>
                      {director.individual?.firstName} {director.individual?.lastName}
                    </TableCell>
                    <TableCell width={168}>
                      {!isViewOnly && showActions && (
                        <Box className={classes.buttonsBox}>
                          <Button
                            color="secondary"
                            aria-label="delete"
                            type="button"
                            onClick={() => setOpenDelete(director.id)}
                            data-test="personsAccountsDelete-btn"
                          >
                            {t('delete', 'Delete')}
                          </Button>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ConfirmationAnyModal
        title={t('delete', 'Delete')}
        color={'error'}
        icon={<Attention />}
        name="delete"
        handleClose={() => setOpenDelete(undefined)}
        handleConfirm={() => onConfirmDelete(openDelete)}
        isOpen={!!openDelete}
        labelCancel={t('cancel', 'Cancel')}
        labelConfirm={t('delete', 'Delete')}
      >
        <Typography>{t('deleteDirectorConfirm')}</Typography>
      </ConfirmationAnyModal>
    </>
  )
}
