import * as yup from 'yup'
import { EmailInputSchema } from './common'
import {
  LATINICA_ONLY_REGEXP,
  LATINICA_ONLY_MESSAGE,
  THIS_FIELD_IS_REQUIRED_MESSAGE,
} from '../constants/validations'
import i18n from '../i18n'

export const AddNewContactInputSchema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(i18n.t('nameIsRequired', 'Name is required'))
      .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
    isPrimary: yup.boolean().notRequired(),
    phoneNumber: yup.mixed().when('formMode', {
      is: 'new',
      then: yup
        .string()
        .test('invalidPhone', THIS_FIELD_IS_REQUIRED_MESSAGE, (val) => !!val && val.length > 5),
    }),
  })
  .concat(EmailInputSchema)
